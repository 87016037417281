<template>
  <div class="box" v-if="!isTotal">
    <div class="hand">
      <div class="title">{{title}}</div>
      <div class="handImg">
        <img :src=pulseData.image alt="脉象图" @error="handleError">
      </div>
    </div>
    <div class="part">
      <div class="pulseName"><span>分脉</span></div>
      <div class="detail">
        <p><span>寸</span></p>
        <span>{{getStr('cun')}}</span>
      </div>
      <div class="detail">
        <p><span>关</span></p>
        <span>{{ getStr('guan') }}</span>
      </div>
      <div class="detail">
        <p><span>尺</span></p>
        <span>{{ getStr('chi') }}</span>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="hand">
      <div class="title">总脉</div>
      <div class="total">{{ pulseData.final_pulse }}</div>
      <div class="tips">
        提示: {{ pulseData.desc }}
      </div>
      <h3 class="notice">注：左右脉象医理结果不一致属正常现象。</h3>
    </div>
  </div>
</template>

<script>

  export default {
    name: "hand",
    props: {
      title:{ // 左右手
        type: String,
      },
      pulseData: { // 脉诊具体数据
        type: Object,
        required: true,
        default: {}
      },
      isTotal: Boolean, // 是否是总脉
    },
    methods: {
      handleError(e){
        this.utils.handleError(e);
      },
      getStr(filed){
        const temp = this.pulseData[filed].slice(0,2); // 截取最多两个值展示在页面上
        return temp.join('、')
      }
    }
  }
</script>

<style scoped>
  .box {
    padding-bottom: 12px;
    /*border-bottom: 1px solid #01477d;*/
  }


  .hand .title {
    width: 79%;
    height: 110px;
    margin: 82px auto;
    text-align: center;
    font-size: 48px;
    background-image: url("../../../assets/img/report/pulse/mxbt_jkjx_icon.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    line-height: 110px;
  }

  .hand .handImg {
    background-color: #fff;
    height: 486px;
  }

  .hand img {
    width: auto;
    height: 100%;
    display: block;
    margin: 0 auto;
  }

  .total {
    font-size: 38px;
  }

  .part {
    display: flex;
    align-items: center;
    margin-top: 80px;
  }



  .part>div {
    flex: 1;
    text-align: center;
    font-size: 45px;
  }

  .part>div:nth-child(2){
    background-image: url("../../../assets/img/report/pulse/line_jkjx_icon.png");
    background-size: auto 100%;
    background-repeat: no-repeat;
  }


  .pulseName>span{
    color: #00e4ff;
    background-image: url("../../../assets/img/report/pulse/guang_jkjx_icon.png");
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 8px 18px;
    font-weight: bold;
    font-size: 48px;
  }

  .detail p {
    width: 80px;
    height: 80px;
    border: 1px solid #fff;
    border-radius: 50%;
    position: relative;
    font-size: 42px;
    margin: 0 auto;
  }

  .detail p>span {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(50% - 21px);
  }

  .detail>span {
    display: block;
    padding-top: 10px;
    font-size: 35px;
  }

  .tips {
    margin-top: 22px;
    /*margin-bottom: 1rem;*/
    padding: 25px 50px;
    font-size: 35px;
    color: #00e4ff;
    background-color: #041937;
    line-height: 60px;
  }

  .notice{
    font-size: 30px;
    color: #295b81;
    font-weight: normal;
    margin-top: 20px;
  }

</style>