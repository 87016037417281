<template>
  <div class="analyze">
    <h4>(二)中医辩证分型</h4>
    <div>
      <p>{{conclusion}}</p>
      <p>{{concludeAdd}}</p>
      <p>{{againText}}</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: "analyze",
    props:{
      chronicType: String,
      symptoms: Array,
    },
    data(){
      return {
        conclusion: '', // 结论,
        concludeAdd: '', // 非首次，附加建议
        againText: ''
      }
    },
    beforeMount(){
      const { report } = this.$store.state;
      const isFirst = report.before_symptom_status &&report.before_symptom_status.length === 0;
      const { symptoms } = report;
      const current = symptoms[0].name;

      let nameAdd = '';
      if(this.chronicType === '糖尿病'){
        nameAdd = '2型'
      }

      if(current.includes('健康')) {
        this.conclusion = '根据中医健康评估结果，您非常健康，请继续保持健康的生活方式和乐观的心态。'

      } else {
        this.conclusion = `根据中医健康评估结果，您的${nameAdd}${this.chronicType}属于：${current}型，程度${report.health_status}，建议您咨询专业的中医师调理。`
        this.againText = `建议您1个月后再做${nameAdd}${this.chronicType}中医健康评估。`
      }
      if(!isFirst) { // 首次
        this.concludeAdd = report.print_report_attention;
      }

    },

  }
</script>

<style scoped>
p {
  text-indent: 2em;
  line-height: 25px;
}
</style>