var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.marker === "10.1"
    ? _c(
        "div",
        { staticClass: "indicator", staticStyle: { height: "295px" } },
        [
          _c(
            "div",
            {
              staticClass: "circular",
              staticStyle: {
                "background-size": "86% 100%",
                width: "468px",
                height: "295px",
                "margin-top": "0"
              }
            },
            [
              _c("div", { staticClass: "circular-content" }, [
                _c(
                  "span",
                  {
                    staticClass: "fraction",
                    style: {
                      color:
                        _vm.score > 89
                          ? "#00d73d"
                          : _vm.score > 59
                          ? "#FE822B"
                          : "red",
                      fontSize: "60px",
                      margin: "57px 0 0 43px"
                    }
                  },
                  [_vm._v(_vm._s(Math.round(_vm.score)))]
                ),
                _c(
                  "span",
                  {
                    staticClass: "text",
                    staticStyle: {
                      margin: "12px 0 0 24px",
                      "font-size": "19px"
                    }
                  },
                  [_vm._v("健康指数(分)")]
                )
              ])
            ]
          ),
          _c("div", {
            staticClass: "circular-picture",
            style: {
              background:
                "url(" +
                require("../../../assets/img/report/audio/animation" +
                  (Math.round(this.score / 3) + 1) +
                  ".gif") +
                ") no-repeat",
              backgroundSize: "100% 100%"
            }
          }),
          _c("div", { staticClass: "health10" }, [
            _c(
              "span",
              {
                staticClass: "health-name",
                style: {
                  color:
                    _vm.score > 89
                      ? "#00d73d"
                      : _vm.score > 59
                      ? "#FE822B"
                      : "red"
                }
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.score < 60
                      ? "可能有疾病"
                      : _vm.score < 90
                      ? "亚健康"
                      : "健康"
                  )
                )
              ]
            ),
            _c("span", { staticClass: "health-proposal" }, [
              _vm._v(_vm._s(_vm.score > 89 ? null : "建议找中医专家调治"))
            ]),
            _c(
              "div",
              { staticStyle: { "margin-top": "17px" } },
              _vm._l(_vm.report.symptoms, function(item, index) {
                return _c(
                  "span",
                  {
                    key: index,
                    staticClass: "symptom",
                    on: {
                      click: function($event) {
                        return _vm.ReturnToExplain(item.name)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.report.symptoms ? item.name : "健康"))]
                )
              }),
              0
            ),
            _vm._m(0),
            _vm.myallreport === "1" && _vm.type !== "personal"
              ? _c("div", [
                  _vm.csr === "HUASHI" ||
                  _vm.csr === "SHBAZE" ||
                  _vm.csr === "CHENSHANG" ||
                  _vm.csr === "SHWYSLSWKJ" ||
                  _vm.csr === "SXSZYYY"
                    ? _c(
                        "div",
                        { staticClass: "qrcode" },
                        [
                          _c("qrcode-vue", {
                            attrs: { value: this.url, size: "86" }
                          })
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "qrcode-zy" },
                        [
                          _vm.report.vendor_setting.qr_code_show === 2
                            ? _c("img", {
                                staticStyle: { width: "86px", height: "86px" },
                                attrs: { src: _vm.report.qr_url }
                              })
                            : this.shareUrl &&
                              _vm.report.vendor_setting.qr_code_show === 1
                            ? _c("qrcode-vue", {
                                attrs: {
                                  value: _vm.report.report_url,
                                  size: "86"
                                }
                              })
                            : this.shareUrl &&
                              _vm.report.vendor_setting.qr_code_show === 0
                            ? _c("img", {
                                staticStyle: { width: "86px", height: "86px" },
                                attrs: { src: _vm.code }
                              })
                            : _c("div")
                        ],
                        1
                      )
                ])
              : _vm._e(),
            _vm.myallreport === "1" && _vm.type !== "personal"
              ? _c("div", { staticClass: "scancode" }, [
                  _vm._v(" 微信扫码带走报告 ")
                ])
              : _vm._e()
          ])
        ]
      )
    : _c("div", { staticClass: "indicator" }, [
        _c("div", { staticClass: "circular" }, [
          _c("div", { staticClass: "circular-content" }, [
            _c(
              "span",
              {
                staticClass: "fraction",
                style: {
                  color:
                    _vm.score > 89
                      ? "#00d73d"
                      : _vm.score > 59
                      ? "#FE822B"
                      : "red"
                }
              },
              [_vm._v(_vm._s(Math.round(_vm.score)))]
            ),
            _c("span", { staticClass: "text" }, [_vm._v("健康指数(分)")])
          ])
        ]),
        _c("div", {
          staticClass: "circular-pic",
          style: {
            background:
              "url(" +
              require("../../../assets/img/report/audio/animation" +
                (Math.round(this.score / 3) + 1) +
                ".gif") +
              ") no-repeat"
          }
        }),
        _c("div", { staticClass: "health" }, [
          _c(
            "span",
            {
              staticClass: "health-name",
              style: {
                color:
                  _vm.score > 89
                    ? "#00d73d"
                    : _vm.score > 59
                    ? "#FE822B"
                    : "red"
              }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.score < 60
                    ? "可能有疾病"
                    : _vm.score < 90
                    ? "亚健康"
                    : "健康"
                )
              )
            ]
          ),
          _c("span", { staticClass: "health-proposal" }, [
            _vm._v(_vm._s(_vm.score > 89 ? null : "建议找中医专家调治"))
          ]),
          _c(
            "div",
            { staticStyle: { "margin-top": "17px" } },
            _vm._l(_vm.report.symptoms, function(item, index) {
              return _c(
                "span",
                {
                  key: index,
                  staticClass: "symptom",
                  on: {
                    click: function($event) {
                      return _vm.ReturnToExplain(item.name)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.report.symptoms ? item.name : "健康"))]
              )
            }),
            0
          ),
          _vm._m(1),
          _vm.myallreport === "1" && _vm.type !== "personal"
            ? _c(
                "div",
                { staticClass: "allreport", on: { click: _vm.myreport } },
                [_vm._v(" 历史报告 ")]
              )
            : _vm._e()
        ])
      ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "margin-top": "7px" } }, [
      _c(
        "span",
        { staticClass: "describe", staticStyle: { display: "inline-block" } },
        [_vm._v("健康：90-100分")]
      ),
      _c(
        "span",
        { staticClass: "describe", staticStyle: { display: "inline-block" } },
        [_vm._v("亚健康：60-89分")]
      ),
      _c(
        "span",
        { staticClass: "describe", staticStyle: { display: "inline-block" } },
        [_vm._v("可能有疾病：0-59分")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "margin-top": "7px" } }, [
      _c("span", { staticClass: "describe" }, [_vm._v("健康：90-100分")]),
      _c("span", { staticClass: "describe" }, [_vm._v("亚健康：60-89分")]),
      _c("span", { staticClass: "describe" }, [_vm._v("可能有疾病：0-59分")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }