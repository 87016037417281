var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.symptom_status
    ? _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "bc" }),
          _vm.before_symptom_status && !_vm.lastreport
            ? _c("div", { staticClass: "sc" })
            : _vm._e(),
          _c("div", { staticClass: "explain", on: { click: _vm.showExplain } }),
          _c("div", { attrs: { id: "main" } })
        ]),
        _vm.flag
          ? _c("div", [
              _c("div", { staticClass: "frame" }, [
                _c("div", { staticClass: "spantitle" }, [
                  _c("span", { staticStyle: { "font-size": "42px" } }, [
                    _vm._v("雷达图说明")
                  ]),
                  _vm._m(0),
                  _c("div", {
                    staticClass: "fh",
                    on: { click: _vm.showExplain }
                  })
                ])
              ])
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          "text-align": "left",
          width: "732px",
          "font-size": "32px",
          "line-height": "57px",
          "padding-top": "43px",
          margin: "0 auto"
        }
      },
      [
        _vm._v(
          " 雷达图以中间实心多边形的顶点为0点，分值区间为0-100，由里往外分值逐渐增加。"
        ),
        _c("br"),
        _vm._v(" 越接近外圈，分值越高，对应状态的表现越明显。"),
        _c("br"),
        _vm._v(
          " 若多个状态均在中间实心多边形对应的顶点（即0点）上，则为较健康的状态。 "
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }