var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.report
    ? _c(
        "div",
        { staticStyle: { display: "flex", overflow: "auto", height: "100%" } },
        [
          _c(
            "div",
            {
              staticClass: "healthprogram",
              style:
                _vm.access === "web" ? { height: "1920px" } : { height: "100%" }
            },
            [
              _c("div", { staticStyle: { height: "170px" } }, [
                _c("img", {
                  staticClass: "back",
                  attrs: { src: _vm.fh },
                  on: { click: _vm.returnto }
                }),
                _c("span", { staticClass: "title" }, [_vm._v("健康方案")])
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    height: "206px",
                    "border-bottom": "3px solid #2F7284",
                    overflow: "auto",
                    width: "100%",
                    margin: "0 auto"
                  }
                },
                [
                  _c(
                    "div",
                    { style: { width: this.widval + "px" } },
                    _vm._l(_vm.jkfnsx, function(item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "healthprogram-top",
                          style: {
                            opacity: item === _vm.currentIndex ? "1" : "0.5"
                          },
                          on: {
                            click: function($event) {
                              return _vm.checkType(item)
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticStyle: { height: "70px" },
                            attrs: { src: _vm.buttonimg[item], alt: "logo" }
                          }),
                          _c("br"),
                          _vm._v(" " + _vm._s(_vm.buttontype[item]) + " ")
                        ]
                      )
                    }),
                    0
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    height: "1480px",
                    padding: "0 5%",
                    "border-radius": "5px",
                    width: "90%",
                    "margin-top": "30px"
                  }
                },
                [
                  _vm.currentIndex === 0
                    ? _c("div", [
                        _c("div", { staticStyle: { height: "150px" } }, [
                          _c(
                            "div",
                            {
                              staticClass: "healthprogram-one",
                              staticStyle: {
                                "border-right": "3px solid #2F7284",
                                "padding-right": "4%"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "text-align": "center",
                                    height: "35px"
                                  }
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "35px",
                                      height: "35px",
                                      "margin-right": "10px",
                                      "vertical-align": "top"
                                    },
                                    attrs: { src: _vm.ys, alt: "logo" }
                                  }),
                                  _c("span", { staticClass: "eating" }, [
                                    _vm._v("宜食")
                                  ])
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-top": "20px",
                                    width: "100%"
                                  }
                                },
                                _vm._l(this.goodfood, function(item, index) {
                                  return _c(
                                    "span",
                                    { key: index, staticClass: "foodname" },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                }),
                                0
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "healthprogram-one",
                              staticStyle: { "padding-left": "3%" }
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "35px",
                                      height: "35px",
                                      "margin-right": "10px",
                                      "vertical-align": "top"
                                    },
                                    attrs: { src: _vm.js, alt: "logo" }
                                  }),
                                  _c("span", { staticClass: "eating" }, [
                                    _vm._v("忌食")
                                  ])
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-top": "20px",
                                    width: "100%"
                                  }
                                },
                                _vm._l(this.badfood, function(item, index) {
                                  return _c(
                                    "span",
                                    { key: index, staticClass: "foodname" },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                }),
                                0
                              )
                            ]
                          )
                        ]),
                        _vm._m(0),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              overflow: "auto",
                              "-webkit-overflow-scrolling": "touch"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                style: {
                                  width: this.contentwid + "px",
                                  height: "1200px",
                                  marginTop: "15px"
                                }
                              },
                              _vm._l(this.content, function(item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "dishes" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          height: "98%",
                                          overflow: "auto"
                                        }
                                      },
                                      [
                                        _c("span", { staticClass: "tip" }, [
                                          _vm._v(_vm._s(item.name))
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "medicinal",
                                            staticStyle: { "margin-top": "9px" }
                                          },
                                          [_vm._v("配料：")]
                                        ),
                                        _vm._l(item.asset, function(
                                          items,
                                          index
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticStyle: {
                                                width: "33.3%",
                                                display: "inline-block",
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  width: "221px",
                                                  height: "161px",
                                                  "margin-top": "30px",
                                                  "border-radius": "10px"
                                                },
                                                attrs: {
                                                  src:
                                                    _vm.report.solutions
                                                      .asset_prefix + items[1],
                                                  alt: "加载失败"
                                                }
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "imgtext" },
                                                [_vm._v(_vm._s(items[0]))]
                                              )
                                            ]
                                          )
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "medicinal",
                                            staticStyle: {
                                              "margin-top": "40px"
                                            }
                                          },
                                          [_vm._v("清单：")]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "medicinal-explain" },
                                          [_vm._v(_vm._s(item.content[0]))]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "medicinal",
                                            staticStyle: {
                                              "margin-top": "40px"
                                            }
                                          },
                                          [_vm._v("烹煮方法：")]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "medicinal-explain" },
                                          [_vm._v(_vm._s(item.content[1]))]
                                        )
                                      ],
                                      2
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.currentIndex === 1
                    ? _c("div", { staticStyle: { height: "100%" } }, [
                        _vm.tea
                          ? _c("div", { staticClass: "cyys" }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "98%",
                                    overflow: "auto"
                                  }
                                },
                                [
                                  _c("span", { staticClass: "teasty" }, [
                                    _vm._v(_vm._s(_vm.tea.name))
                                  ]),
                                  _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          height: "481px",
                                          width: "861px",
                                          "margin-top": "50px",
                                          "border-radius": "20px"
                                        },
                                        attrs: {
                                          src:
                                            _vm.report.solutions.asset_prefix +
                                            _vm.tea.asset,
                                          alt: "加载失败"
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "medicinal",
                                      staticStyle: { "margin-top": "30px" }
                                    },
                                    [_vm._v("配料：")]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "medicinal-explain" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.tea.content && _vm.tea.content[0]
                                        )
                                      )
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "medicinal",
                                      staticStyle: { "margin-top": "30px" }
                                    },
                                    [_vm._v("泡煮方法：")]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "medicinal-explain" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.tea.content && _vm.tea.content[1]
                                        )
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.currentIndex === 2
                    ? _c("div", [
                        _c(
                          "div",
                          {
                            style:
                              _vm.marker === "10.1"
                                ? { height: "1350px" }
                                : { height: "1440px" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "cyys",
                                staticStyle: {
                                  "padding-top": "10px",
                                  position: "relative",
                                  height: "89%"
                                }
                              },
                              _vm._l(_vm.jdtf, function(item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    style:
                                      index === _vm.jdtf.length - 1
                                        ? {
                                            height: "30.5%",
                                            paddingTop: "20px"
                                          }
                                        : {
                                            height: "30.5%",
                                            paddingTop: "20px"
                                          }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "teasty",
                                        staticStyle: {
                                          color: "#D67206",
                                          "font-weight": "bold",
                                          "font-size": "36px"
                                        }
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "medicinal",
                                        staticStyle: {
                                          "margin-top": "10px",
                                          "font-size": "30px"
                                        }
                                      },
                                      [_vm._v("药物组成：")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "medicinal-explain",
                                        staticStyle: {
                                          "margin-top": "5px",
                                          "line-height": "40px"
                                        }
                                      },
                                      [_vm._v(_vm._s(item.content[0]))]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "medicinal",
                                        staticStyle: {
                                          "margin-top": "10px",
                                          "font-size": "30px"
                                        }
                                      },
                                      [_vm._v("功效：")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "medicinal-explain",
                                        staticStyle: {
                                          "margin-top": "5px",
                                          "line-height": "40px"
                                        }
                                      },
                                      [_vm._v(_vm._s(item.content[1]))]
                                    ),
                                    _vm.csr === "HUASHI" && item.content[2]
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "medicinal",
                                            staticStyle: {
                                              "margin-top": "10px",
                                              "font-size": "30px"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "推荐用药：" +
                                                _vm._s(
                                                  item.content[2]
                                                    ? item.content[2]
                                                    : ""
                                                ) +
                                                _vm._s(
                                                  item.content[3]
                                                    ? "、" + item.content[3]
                                                    : ""
                                                ) +
                                                _vm._s(
                                                  item.content[4]
                                                    ? "、" + item.content[4]
                                                    : ""
                                                ) +
                                                _vm._s(
                                                  item.content[5]
                                                    ? "、" + item.content[5]
                                                    : ""
                                                )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              }),
                              0
                            ),
                            _c(
                              "div",
                              {
                                style:
                                  _vm.marker === "10.1"
                                    ? {
                                        color: "red",
                                        fontSize: "20px",
                                        textAlign: "right",
                                        marginTop: "-20px"
                                      }
                                    : {
                                        color: "red",
                                        fontSize: "30px",
                                        textAlign: "right",
                                        marginTop: "10px"
                                      }
                              },
                              [_vm._v(" *请在医生、药师指导下购买和服用 ")]
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.currentIndex === 3
                    ? _c("div", { staticStyle: { height: "100%" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "cyys",
                            staticStyle: {
                              height: "85%",
                              "padding-bottom": "100px",
                              overflow: "auto"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  height: "100%",
                                  overflow: "auto"
                                }
                              },
                              [
                                _c("span", { staticClass: "teasty" }, [
                                  _vm._v(_vm._s(_vm.acupoint.name))
                                ]),
                                _c("img", {
                                  staticStyle: {
                                    "margin-top": "50px",
                                    width: "860px",
                                    height: "480px",
                                    "border-radius": "20px"
                                  },
                                  attrs: {
                                    src:
                                      _vm.report.solutions.asset_prefix +
                                      _vm.acupoint.asset,
                                    alt: "加载失败"
                                  }
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "medicinal",
                                    staticStyle: { "margin-top": "11px" }
                                  },
                                  [_vm._v("定位：")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "medicinal-explain",
                                    staticStyle: { "margin-top": "30px" }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.acupoint.content &&
                                          _vm.acupoint.content[0]
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "medicinal",
                                    staticStyle: { "margin-top": "30px" }
                                  },
                                  [_vm._v("按摩方法：")]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "medicinal-explain" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.acupoint.content &&
                                          _vm.acupoint.content[1]
                                      )
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.currentIndex === 4
                    ? _c("div", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "div",
                          { staticClass: "zygf" },
                          [
                            this.poster
                              ? _c("video-player", {
                                  ref: "videoPlayer",
                                  staticClass: "video-player vjs-custom-skin",
                                  attrs: {
                                    playsinline: true,
                                    options: _vm.playerOptions
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm.marker === "10.1"
                          ? _c("div", { staticClass: "divleft" })
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.currentIndex === 5
                    ? _c("div", { staticClass: "music-healthy" }, [
                        _vm.marker === "10.1"
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "musicsty",
                                  style: {
                                    background:
                                      "url(" +
                                      _vm.report.solutions.asset_prefix +
                                      "/养生音乐/脾虚/渔舟唱晚.jpg) no-repeat",
                                    "background-size": "100% 100%"
                                  }
                                },
                                [
                                  _c("span", { staticClass: "yysty" }, [
                                    _vm._v(
                                      "音乐养生:" + _vm._s(_vm.music.title)
                                    )
                                  ]),
                                  _vm.music
                                    ? _c("audio", {
                                        staticClass: "audiosty",
                                        attrs: {
                                          src: _vm.music.url,
                                          controls: "controls"
                                        }
                                      })
                                    : _vm._e()
                                ]
                              )
                            ])
                          : _c("div", [
                              _c("div", { staticClass: "music-icon" }, [
                                _c("img", {
                                  class: _vm.isPlay ? "circle " : "",
                                  attrs: {
                                    src: require("../../assets/img/common/music_jkfa_icon.png"),
                                    alt: ""
                                  }
                                }),
                                _c("img", {
                                  staticClass: "music-btn",
                                  attrs: {
                                    src: !_vm.isPlay
                                      ? require("../../assets/img/common/bf_jkfa_icon.png")
                                      : require("../../assets/img/common/zt_jkfa_icon.png")
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.startPlay()
                                    }
                                  }
                                }),
                                _vm.music
                                  ? _c("audio", {
                                      staticClass: "audio",
                                      attrs: {
                                        src: _vm.music.url,
                                        controls: "controls",
                                        id: "player"
                                      }
                                    })
                                  : _vm._e()
                              ]),
                              _c("div", { staticClass: "music-tip" }, [
                                _c("img", {
                                  class: _vm.isPlay ? "active" : "",
                                  attrs: {
                                    src: require("../../assets/img/common/music1_jkfa_icon.png"),
                                    alt: ""
                                  }
                                })
                              ]),
                              _c("div", { staticClass: "music-name" }, [
                                _vm._v(_vm._s(_vm.music.title))
                              ])
                            ])
                      ])
                    : _vm._e(),
                  _vm.currentIndex === 6
                    ? _c("div", { staticStyle: { height: "100%" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "cyys",
                            staticStyle: { height: "70%" }
                          },
                          _vm._l(_vm.hg.content, function(item, index) {
                            return _c("span", { key: index }, [
                              _vm._v(_vm._s(item))
                            ])
                          }),
                          0
                        )
                      ])
                    : _vm._e(),
                  _vm.currentIndex === 7
                    ? _c("div", { staticStyle: { height: "100%" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "cyys",
                            staticStyle: { height: "88%" }
                          },
                          [
                            _c("div", { staticClass: "bjyztop" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "bjyzspan",
                                  staticStyle: {
                                    position: "relative",
                                    top: "53px",
                                    left: "141px"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.principle.content[1][0]))]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "bjyzspan",
                                  staticStyle: {
                                    position: "relative",
                                    top: "162px",
                                    left: "-34px"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.principle.content[1][1]))]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "bjyzspan",
                                  staticStyle: {
                                    position: "relative",
                                    top: "225px",
                                    left: "28px"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.principle.content[1][2]))]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "bjyzspan",
                                  staticStyle: {
                                    position: "relative",
                                    top: "327px",
                                    left: "-151px"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.principle.content[1][3]))]
                              )
                            ]),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-top": "30px",
                                  padding: "0 150px",
                                  "letter-spacing": "4px"
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.principle.content[0]) + " "
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.currentIndex === 8
                    ? _c("div", { staticStyle: { height: "100%" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "cyys",
                            staticStyle: {
                              height: "82%",
                              "padding-bottom": "100px",
                              overflow: "auto"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  height: "100%",
                                  overflow: "auto"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#fa6060",
                                      "line-height": "100px",
                                      "font-size": "30px",
                                      "border-bottom": "1px solid #2f7284"
                                    }
                                  },
                                  [_vm._v(" 配合特色中药熏蒸方法，效果更佳 ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#ffffff",
                                      "margin-top": "60px",
                                      "line-height": "36px",
                                      "font-size": "36px"
                                    }
                                  },
                                  [
                                    _vm._v(" 取穴："),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#00fefb" } },
                                      [_vm._v(_vm._s(_vm.xwname.join("、")))]
                                    )
                                  ]
                                ),
                                _vm._m(1),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#ffffff",
                                      "margin-top": "60px",
                                      "line-height": "36px",
                                      "font-size": "36px"
                                    }
                                  },
                                  [_vm._v(" 取穴方法： ")]
                                ),
                                _vm._l(_vm.xw, function(item, index) {
                                  return _c("div", { key: index }, [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "margin-top": "40px",
                                          "line-height": "40px",
                                          color: "#00fefb",
                                          "font-size": "30px"
                                        }
                                      },
                                      [
                                        _vm._v(" " + _vm._s(item.name) + ":"),
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#ffffff" } },
                                          [_vm._v(_vm._s(item.content[0]))]
                                        )
                                      ]
                                    ),
                                    _c("div", [
                                      _c("img", {
                                        staticStyle: {
                                          "margin-top": "10px",
                                          width: "860px",
                                          "border-radius": "20px"
                                        },
                                        attrs: {
                                          src:
                                            _vm.report.solutions.asset_prefix +
                                            item.asset[0],
                                          alt: "加载失败"
                                        }
                                      })
                                    ])
                                  ])
                                })
                              ],
                              2
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.currentIndex === 9
                    ? _c("div", { staticStyle: { height: "100%" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "cyys",
                            staticStyle: { height: "89%", padding: "40px 40px" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: { height: "96%", overflow: "auto" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#ffffff",
                                      "line-height": "80px",
                                      "font-size": "36px",
                                      "border-bottom": "1px solid #2f7284"
                                    }
                                  },
                                  [_vm._v("【熏蒸法简介】")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "padding-top": "30px",
                                      width: "100%"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          color: "#ffffff",
                                          "line-height": "50px",
                                          "font-size": "30px",
                                          float: "left",
                                          width: "49%",
                                          "text-indent": "2em"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " 中药熏蒸疗法源远流长，早在《黄帝内经》中就有记载。在唐代孙思邈所著的《千金方》中已经将熏洗疗法广泛地用到内、外、妇、儿、五官、皮肤等各科的疾病治疗和预防。在元代《御药院方》、清代的《慈禧光绪医方选仪》中有大量运用熏蒸药方治疗各类疾病的记录。熏蒸疗法在宫廷中的运用是受到了高度重视。 "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          color: "#ffffff",
                                          "line-height": "50px",
                                          "text-align": "right",
                                          "font-size": "30px",
                                          float: "left",
                                          width: "51%",
                                          "padding-top": "14px"
                                        }
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: { width: "392px" },
                                          attrs: { src: _vm.tu }
                                        })
                                      ]
                                    )
                                  ]
                                ),
                                _c("div", { staticStyle: { clear: "both" } }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#ffffff",
                                      "line-height": "80px",
                                      "font-size": "36px",
                                      "border-bottom": "1px solid #2f7284"
                                    }
                                  },
                                  [_vm._v("【熏蒸的作用】")]
                                ),
                                _vm._m(2),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#ffffff",
                                      "line-height": "80px",
                                      "font-size": "36px",
                                      "border-bottom": "1px solid #2f7284"
                                    }
                                  },
                                  [_vm._v("【调养原则】")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      padding: "30px 0",
                                      width: "100%"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#00fefb",
                                          "line-height": "54px",
                                          "font-size": "30px"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.fumigation.name))]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#fa6060",
                                          "line-height": "54px",
                                          "font-size": "24px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "（配合艾灸，效果更佳，方法见居家艾灸）"
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#ffffff",
                                      "line-height": "80px",
                                      "font-size": "36px",
                                      "border-bottom": "1px solid #2f7284"
                                    }
                                  },
                                  [_vm._v("【五行对应脏腑】")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      padding: "30px 0",
                                      width: "100%"
                                    }
                                  },
                                  [
                                    _vm._l(_vm.fumigation["content"], function(
                                      item,
                                      index
                                    ) {
                                      return _c("div", { key: index }, [
                                        item % 2 !== 0
                                          ? _c("img", {
                                              staticStyle: {
                                                float: "left",
                                                width: "170px",
                                                "margin-right": "10px"
                                              },
                                              attrs: { src: _vm.wximg[item] }
                                            })
                                          : _vm._e()
                                      ])
                                    }),
                                    _c("div", {
                                      staticStyle: { clear: "both" }
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          color: "#ffffff",
                                          "line-height": "40px",
                                          "font-size": "26px"
                                        }
                                      },
                                      _vm._l(
                                        _vm.fumigation["content"],
                                        function(item, index) {
                                          return _c("div", { key: index }, [
                                            index % 2 === 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      float: "left",
                                                      width: "170px",
                                                      "margin-right": "10px",
                                                      "text-align": "center"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.fumigation[
                                                            "content"
                                                          ][index]
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  ],
                                  2
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.currentIndex === 10
                    ? _c(
                        "div",
                        { staticStyle: { height: "100%" } },
                        [
                          _c("ProductTzbs"),
                          _vm.isShowProDetail ? _c("ProductDetail") : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { "margin-top": "28px", height: "60px" } },
      [
        _c(
          "span",
          { staticClass: "medicinal", staticStyle: { "margin-top": "20px" } },
          [_vm._v("推荐药膳")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          color: "#ffffff",
          "margin-top": "60px",
          "line-height": "36px",
          "font-size": "36px"
        }
      },
      [
        _vm._v(" 方法："),
        _c("span", { staticStyle: { "font-size": "30px" } }, [
          _vm._v("将艾炷点燃，粘贴在相应的穴位上，每穴2-3炷。")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: { padding: "30px 0", width: "100%", "font-size": "30px" }
      },
      [
        _c("div", [
          _vm._v(
            "1. 净血排毒：可改善人体新陈代谢，促进血液循环，帮助 排除体内废物及肝肾毒素，对各种水肿有特效。"
          )
        ]),
        _c("div", [
          _vm._v(
            "2. 清毒杀菌：可深入皮下组织，杀菌消毒，清除污垢，帮 助去死皮，使肌肤美白。"
          )
        ]),
        _c("div", [
          _vm._v("3. 清除疲劳：可使全身放松，缓解压力，心情愉快，恢复 活力。")
        ]),
        _c("div", [
          _vm._v("4. 活化细胞：可使全身细胞活跃，有效改善体质，增强免 疫能力。")
        ]),
        _c("div", [
          _vm._v("5. 强化机能：可刺激人体微循环系统，改善人体各种机能。 ")
        ]),
        _c("div", [
          _vm._v(
            "6. 减肥瘦身：可帮助排汗，消除多余热量，燃烧多余脂肪， 使身体苗条，凹凸有形。 "
          )
        ]),
        _c("div", [_vm._v("7. 美容除斑：可调节内分泌，预防妇科病，消除色斑。")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }