<template>
  <div v-if="reportData" class="wx-chronic">
    <!-- 慢病报告头部 -->
    <wxchronicheader
    :type="reportData.type_attention"
    :healthStatus="reportData.health_status"
    :displayId="reportData.display_id"
    :time="reportData.time"
    :symptoms="reportData.symptoms"
    :isInclination="reportData.is_inclination"
    ></wxchronicheader>
    <div class="content">
      <!-- 个人信息 慢病和（更年期卵巢界面字段一样） 此处类型延用type="female" 后台只能这么操作年龄 -->
      <wxinfo type="female" :info="reportData.owner" :age="reportData.user_info.ages"></wxinfo>
      <!-- 中医辩证分型 -->
      <wxchronicchart
      :symptoms="reportData.symptoms"
      :symptomStatus="reportData.symptom_status"
      :beforeSymptomStatus="reportData.before_symptom_status"
      :healthStatus="reportData.health_status"
      :typeAttention="reportData.type_attention"
      ></wxchronicchart>
      <!-- 健康解读 -->
      <wxexplan
      type="chronic"
      :symptoms="reportData.symptoms"
      :symptomDefinition="reportData.solutions.chronic_disease_definition"
      ></wxexplan>
      <!-- 面诊结果 -->
      <wxfaceresult :faceData="reportData.face_result" :facePhotoUrl="reportData.face_photo_url"></wxfaceresult>
      <!-- 舌诊结果 -->
      <wxtongueresult :tongueData="reportData.tongue_result" :tonguePhotoUrl="reportData.tongue_photo_url"></wxtongueresult>
      <!-- 脉诊 -->
      <wxpulseresult v-if="reportData.pulse.length !== 0" :pulse="reportData.pulse"></wxpulseresult>
      <!-- 血压 -->
      <wxbloodpressure v-if="reportData.pulse.length !== 0 && reportData.pulse[0].blood_dia !== 0 && reportData.pulse[0].blood_sys !== 0" :pulse="reportData.pulse"></wxbloodpressure>
      <!-- 养生方案 -->
      <wxchronicscheme :reportData="reportData"></wxchronicscheme>
      <div class="tip">此报告仅作参考，如有不适请及时就医</div>
      <!-- 推荐中医 -->
      <wxdoctorlist v-if="reportData.doctor.length !== 0" :doctorData="reportData.doctor"></wxdoctorlist>
      <!-- 产品推荐 -->
      <wxproduct v-if="reportData.product && reportData.product.length !== 0" :productData="reportData.product"></wxproduct>
    </div>
  </div>
</template>

<script>
import wxchronicheader from './components/wxchronicheader.vue';
import { getChronicReport } from '@/api/report.js';
import Wxinfo from './components/wxinfo.vue';
import Wxchronicchart from './components/wxchronicchart.vue';
import Wxexplan from './components/wxexplan.vue';
import Wxfaceresult from './components/wxfaceresult.vue';
import Wxtongueresult from './components/wxtongueresult.vue';
import Wxpulseresult from './components/wxpulseresult.vue';
import Wxbloodpressure from './components/wxbloodpressure.vue';
import Wxchronicscheme from './components/wxchronicscheme.vue';
import Wxproduct from './components/wxproduct.vue';
import Wxdoctorlist from './components/wxdoctorlist.vue';

export default {
  components: { wxchronicheader, Wxinfo, Wxchronicchart, Wxexplan,
    Wxfaceresult, Wxtongueresult,
    Wxpulseresult,
    Wxbloodpressure,
    Wxchronicscheme,
    Wxproduct,
    Wxdoctorlist },
  data(){
    return {
      reportData: null, 
    }
  },
  created(){
    this.getData();
  },
  methods:{
    getData(){
      getChronicReport({
        id: this.$route.params.id
      }).then(res => {
        if(res && res.status_code === 10000 && res.data) {
          // console.log(res.data)
          this.reportData = res.data;
          document.title = res.data.type_attention + '健康管理'
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.wx-chronic {
  width: 100%;
  background-color: #f5f5f5;
  .content {
    margin: -55px 45px 45px 45px;
  }
  .tip {
    width: 100%;
    text-align: center;
    font-family: MicrosoftYaHei;
	  font-size: 36px;
    color: #cccccc;
    padding: 42px 0;
  }
}
</style>