var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.report
    ? _c(
        "div",
        { staticClass: "menopause_report" },
        [
          _c("menopauseheader", {
            attrs: {
              time: _vm.report.time,
              reportId: _vm.report.display_id,
              type_attention: _vm.report.type_attention,
              count: _vm.report.detect_count
            }
          }),
          _c("menopausechart", {
            attrs: {
              symptoms: _vm.report.symptoms,
              symptom_status: _vm.report.symptom_status,
              before_symptom_status:
                _vm.report.before_symptom_status.before_symptom_status,
              type_attention: _vm.report.type_attention
            }
          }),
          _c("menopauseuserinfo", {
            attrs: { owner: _vm.report.owner, user_info: _vm.report.user_info }
          }),
          _c("menopauseindicator", {
            attrs: {
              health_status: _vm.report.health_status,
              report: _vm.report
            }
          }),
          _c("menopausejbfx", { attrs: { reportData: _vm.report } }),
          _c("menopausejkfa", { attrs: { reportData: _vm.report } })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }