<template>
  <div class="wxinfo">
    <div class="info">
      <span>{{info.gender === 1 ? '男' : '女'}}</span>
      <div class="content">
        <img src="../../../assets/img/wx/wx_xb_jcjg.png" alt="性别图"/>
        <span>性别</span>
      </div>
    </div>
    <!-- 如果是更年期或卵巢 显示年龄 不显示BMI指数-->
    <div v-if="type === 'female'" class="info">
      <span>{{age}}</span>
      <div class="content">
        <img src="../../../assets/img/wx/wx_nl_icon.png" alt="年龄"/>
        <span>年龄</span>
      </div>
    </div>
    <div v-else class="info">
      <span>{{bmi}}</span>
      <div class="content">
        <img class="bmi" src="../../../assets/img/wx/wx_bmi.png" alt="BMI图"/>
        <span>BMI指数</span>
      </div>
    </div>

    <div class="info">
      <span>{{type === 'female' ? info.cellphone.substr(-4) : owner.cellphone.substr(-4)}}</span>
      <div class="content">
        <img class="phone" src="../../../assets/img/wx/wx_sjwh_jcjg.png" alt="手机尾号图"/>
        <span>手机尾号</span>
      </div>
    </div>

  </div>
</template>

<script>
import {calculateBMI} from '../../../../utils/common';


export default {
  props:{
    info: Object,
    owner: Object,
    type: String,
    age: Number,
  },
  data(){
    return {

    }
  },
  computed: {
    bmi(){
      const {bmi, text} = calculateBMI(this.info.height, this.info.weight);
      if(bmi) return `${bmi}(${text})`;
      else return `0(${text})`
    }
  },
}
</script>

<style lang="scss" scoped>
.wxinfo {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0px 0px 21px 0px rgba(204, 204, 204, 0.32);
	border-radius: 20px;
  height: 180px;
  .flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .info {
    @extend .flex;
    span {
      font-size: 48px;
      font-family: PingFangSC-Medium;
      color: #333;
    }
    .content {
      @extend .flex;
      flex-direction: row;
      margin-top: 10px;
      img {
        width: 46px;
        height: 46px;
        margin-right: 5px;
      }
      span {
        color: #999999;
        font-size: 30px;
        font-family: SourceHanSansCN-Regular;
      }
      .bmi {
        height: 21px;
      }
      .phone {
        width: 39px;
      }
    }
  }
}
</style>