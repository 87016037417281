var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wxhealthplan" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("养生方案")]),
      _vm._l(_vm.buttontype, function(item, index) {
        return _c("div", { key: index }, [
          _c("div", { staticClass: "header" }, [
            _c("img", {
              attrs: {
                src: require("../../../assets/img/wx/wx_jkfabt_jcjg.png")
              }
            }),
            _c("span", [_vm._v(_vm._s(item))]),
            _c("img", {
              attrs: {
                src: require("../../../assets/img/wx/wx_jkfabt1_jcjg.png")
              }
            })
          ]),
          item === "饮食调养"
            ? _c("div", [
                _c("div", { staticClass: "goodfood" }, [
                  _vm._m(0, true),
                  _c(
                    "div",
                    { staticClass: "right" },
                    _vm._l(_vm.goodfood, function(e, i) {
                      return _c("span", { key: i }, [
                        _vm._v(_vm._s(e.name) + " ")
                      ])
                    }),
                    0
                  )
                ]),
                _c("div", { staticClass: "goodfood badfood" }, [
                  _vm._m(1, true),
                  _c(
                    "div",
                    { staticClass: "right" },
                    _vm._l(_vm.badfood, function(e, i) {
                      return _c("span", { key: i }, [
                        _vm._v(_vm._s(e.name) + " ")
                      ])
                    }),
                    0
                  )
                ]),
                _vm.reportData.solutions
                  ? _c(
                      "div",
                      { staticClass: "recommond" },
                      [
                        _c("span", { staticClass: "food-title" }, [
                          _vm._v(
                            "推荐药膳：" + _vm._s(_vm.ysty ? _vm.ysty.name : "")
                          )
                        ]),
                        _c("img", {
                          staticClass: "commom-img",
                          attrs: {
                            src:
                              "" +
                              _vm.reportData.solutions.asset_prefix +
                              _vm.ysty.asset[0]
                          }
                        }),
                        _c("span", { staticStyle: { color: "#333333" } }, [
                          _vm._v("烹煮方法：")
                        ]),
                        _vm._l(_vm.ysty.content, function(e, i) {
                          return _c("span", { key: i, staticClass: "title3" }, [
                            _vm._v(_vm._s(e))
                          ])
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          item === "茶饮养生"
            ? _c(
                "div",
                [
                  _c(
                    "van-tabs",
                    {
                      attrs: {
                        ellipsis: false,
                        swipeable: "",
                        "title-active-color": "#30c3fa",
                        color: "#30c3fa",
                        "title-inactive-color": "#333333"
                      },
                      model: {
                        value: _vm.activeTea,
                        callback: function($$v) {
                          _vm.activeTea = $$v
                        },
                        expression: "activeTea"
                      }
                    },
                    _vm._l(_vm.tea, function(item, index) {
                      return _c(
                        "van-tab",
                        { key: index, attrs: { title: item.name } },
                        [
                          _c("img", {
                            staticClass: "commom-img",
                            attrs: {
                              src:
                                _vm.reportData.solutions.asset_prefix +
                                item.asset[0]
                            }
                          }),
                          _vm.reportData.symptoms[0].name !== "平和质"
                            ? _c("div", { staticClass: "tea-tip-title" }, [
                                _vm._v("配料：")
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "common-text" }, [
                            _vm._v(_vm._s(item.content[0]))
                          ]),
                          item.content.length > 1
                            ? _c("div", { staticClass: "tea-tip-title" }, [
                                _vm._v("泡煮方法：")
                              ])
                            : _vm._e(),
                          _c("span", { staticClass: "common-text" }, [
                            _vm._v(_vm._s(item.content[1]))
                          ]),
                          item.content.length > 2
                            ? _c("div", { staticClass: "tea-tip-title" }, [
                                _vm._v("功效：")
                              ])
                            : _vm._e(),
                          _c("span", { staticClass: "common-text" }, [
                            _vm._v(_vm._s(item.content[2]))
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          item === "香囊"
            ? _c(
                "div",
                [
                  _c(
                    "van-tabs",
                    {
                      attrs: {
                        ellipsis: false,
                        swipeable: "",
                        "title-active-color": "#30c3fa",
                        color: "#30c3fa",
                        "title-inactive-color": "#333333"
                      },
                      model: {
                        value: _vm.activeXn,
                        callback: function($$v) {
                          _vm.activeXn = $$v
                        },
                        expression: "activeXn"
                      }
                    },
                    _vm._l(_vm.xnContent, function(item, index) {
                      return _c(
                        "van-tab",
                        { key: index, attrs: { title: item.name } },
                        [
                          _c("img", {
                            staticClass: "commom-img",
                            attrs: {
                              src:
                                "" +
                                (_vm.reportData.solutions.asset_prefix +
                                  item.asset[0])
                            }
                          }),
                          _c("div", { staticClass: "tea-title jdyf" }, [
                            _vm._v("组成：")
                          ]),
                          _c("span", { staticClass: "common-text" }, [
                            _vm._v(_vm._s(item.content[0]))
                          ]),
                          _c("div", { staticClass: "tea-title jdyf" }, [
                            _vm._v("功效：")
                          ]),
                          _c("span", { staticClass: "common-text" }, [
                            _vm._v(_vm._s(item.content[1]))
                          ]),
                          _c("div", { staticClass: "tea-title jdyf" }, [
                            _vm._v("适应症：")
                          ]),
                          _c("span", { staticClass: "common-text" }, [
                            _vm._v(_vm._s(item.content[2]))
                          ]),
                          _c("div", { staticClass: "tea-title jdyf" }, [
                            _vm._v("用法：")
                          ]),
                          _c("span", { staticClass: "common-text" }, [
                            _vm._v(_vm._s(item.content[3]))
                          ]),
                          _c("div", { staticClass: "tea-title jdyf" }, [
                            _vm._v("禁忌：")
                          ]),
                          _c("span", { staticClass: "common-text" }, [
                            _vm._v(_vm._s(item.content[4]))
                          ]),
                          _c("span", { staticClass: "common-text" }, [
                            _vm._v(_vm._s(item.content[5]))
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          item === "经典药方"
            ? _c(
                "div",
                [
                  _c(
                    "van-tabs",
                    {
                      attrs: {
                        ellipsis: false,
                        swipeable: "",
                        "title-active-color": "#30c3fa",
                        color: "#30c3fa",
                        "title-inactive-color": "#333333"
                      },
                      model: {
                        value: _vm.active,
                        callback: function($$v) {
                          _vm.active = $$v
                        },
                        expression: "active"
                      }
                    },
                    _vm._l(_vm.jdyf, function(item, index) {
                      return _c(
                        "van-tab",
                        { key: index, attrs: { title: item.name } },
                        [
                          _c("div", { staticClass: "tea-title jdyf" }, [
                            _vm._v("药物组成：")
                          ]),
                          _c("span", { staticClass: "common-text" }, [
                            _vm._v(_vm._s(item.content[0]))
                          ]),
                          _c("div", { staticClass: "tea-title jdyf" }, [
                            _vm._v("功效：")
                          ]),
                          _c("span", { staticClass: "common-text" }, [
                            _vm._v(_vm._s(item.content[1]))
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          item === "穴位按压"
            ? _c("div", [
                _c("div", { staticClass: "tea-title" }, [
                  _vm._v(_vm._s(_vm.xw.name))
                ]),
                _c(
                  "div",
                  { staticClass: "commom-img" },
                  [
                    _vm.poster
                      ? _c("video-player", {
                          ref: "videoPlayer",
                          refInFor: true,
                          staticClass: "video-player vjs-custom-skin",
                          attrs: {
                            playsinline: true,
                            options: _vm.playerOptions
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm.xw
                  ? _c("span", { staticClass: "tea-title" }, [
                      _vm._v("【位置】：")
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "common-text" }, [
                  _vm._v(_vm._s(_vm.xw.content[0]))
                ]),
                _vm.xw
                  ? _c("span", { staticClass: "tea-title" }, [
                      _vm._v("【操作】：")
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "common-text" }, [
                  _vm._v(_vm._s(_vm.xw.content[1]))
                ]),
                _vm.xw
                  ? _c("span", { staticClass: "tea-title" }, [
                      _vm._v("【功效】：")
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "common-text" }, [
                  _vm._v(_vm._s(_vm.xw.content[2]))
                ]),
                _vm.xw
                  ? _c("span", { staticClass: "tea-title" }, [
                      _vm._v("【主治】：")
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "common-text" }, [
                  _vm._v(_vm._s(_vm.xw.content[3]))
                ])
              ])
            : _vm._e(),
          item === "精神调养"
            ? _c("div", [
                _c("div", { staticClass: "common-text" }, [
                  _vm._v(_vm._s(_vm.factorDetail.name))
                ])
              ])
            : _vm._e(),
          item === "起居调摄"
            ? _c("div", [
                _c("div", { staticClass: "common-text" }, [
                  _vm._v(_vm._s(_vm.dailyDetail.name))
                ])
              ])
            : _vm._e(),
          item === "运动保健"
            ? _c("div", [
                _c("div", { staticClass: "common-text" }, [
                  _vm._v(_vm._s(_vm.sportDetail.name))
                ])
              ])
            : _vm._e()
        ])
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left" }, [
      _c("img", {
        attrs: { src: require("../../../assets/img/wx/wx_ys_jcjg.png") }
      }),
      _c("span", [_vm._v("宜食")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left" }, [
      _c("img", {
        attrs: { src: require("../../../assets/img/wx/wx_js_jcjg.png") }
      }),
      _c("span", { staticStyle: { color: "#f86426" } }, [_vm._v("忌食")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }