var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.explain
    ? _c(
        "div",
        { staticStyle: { display: "flex", overflow: "auto", height: "100%" } },
        [
          _c(
            "div",
            {
              staticClass: "outline",
              style:
                _vm.access === "web" ? { height: "1920px" } : { height: "100%" }
            },
            [
              _c("div", { staticStyle: { height: "314px" } }, [
                _c("img", {
                  staticClass: "back",
                  staticStyle: { left: "-2px" },
                  attrs: { src: _vm.fh },
                  on: { click: _vm.returnto }
                }),
                _c("span", { staticClass: "title" }, [
                  _vm._v("健康状态辨识总提纲解释说明")
                ])
              ]),
              _c(
                "div",
                { staticClass: "explain-content" },
                _vm._l(_vm.explain, function(item, index) {
                  return _c("div", { key: index }, [
                    _vm._v(" " + _vm._s(item) + " ")
                  ])
                }),
                0
              )
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }