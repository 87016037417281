var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "parsing" }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "back-btn", on: { click: _vm.goBack } }, [
        _vm._v("返 回")
      ]),
      _c("div", { staticClass: "header-title" }, [
        _vm._v(_vm._s(_vm.reportData.result))
      ])
    ]),
    _c(
      "div",
      {
        class: _vm.marker === "10.1" ? "container container-ten" : "container"
      },
      [
        _vm._l(_vm.definition.content, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              class:
                _vm.definition.content.length === 1
                  ? "result result-healthy"
                  : "result"
            },
            [
              index % 2 == 0
                ? _c("p", [_vm._v(_vm._s(item))])
                : _c(
                    "div",
                    { staticClass: "btn-content" },
                    _vm._l(item.split("、"), function(e, i) {
                      return _c("div", { key: i, staticClass: "btn" }, [
                        _vm._v(_vm._s(e))
                      ])
                    }),
                    0
                  )
            ]
          )
        }),
        _c(
          "div",
          {
            class: _vm.marker === "10.1" ? "item-btn item-btn-10" : "item-btn"
          },
          [
            _c(
              "div",
              {
                class: _vm.isViscera ? "btn-left btn-select" : "btn-left",
                on: { click: _vm.visceraEvent }
              },
              [_vm._v("脏腑风险")]
            ),
            _c(
              "div",
              {
                class: !_vm.isViscera ? "btn-right btn-select" : "btn-right",
                on: { click: _vm.termsEvent }
              },
              [_vm._v("节气风险")]
            )
          ]
        ),
        _vm.reportData
          ? _c(
              "div",
              { staticClass: "viscera-chart" },
              [
                _vm.isViscera
                  ? _c("viscera-chart", {
                      attrs: {
                        physicalViscera: _vm.reportData.physical_viscera[0],
                        physical: _vm.reportData.result
                      }
                    })
                  : _c("terms-chart", {
                      attrs: {
                        physicalTerm: _vm.reportData.physical_term[0],
                        physical: _vm.reportData.result
                      }
                    })
              ],
              1
            )
          : _vm._e(),
        _vm.isShowBtn
          ? _c(
              "div",
              { staticClass: "healthy-btn", on: { click: _vm.gotoHomePage } },
              [_vm._v("节气提示")]
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }