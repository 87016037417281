var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chronicchart" }, [
    _c("div", { staticClass: "bc" }),
    _vm.beforeSymptomStatus ? _c("div", { staticClass: "sc" }) : _vm._e(),
    _c("div", {
      style: { width: "100%", height: "600px" },
      attrs: { id: "chronicchart" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }