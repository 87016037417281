<template>
  <div style="padding: 0 40px;margin-top: 10px">
    <div class="jkfa">
      <div style="font-size: 30px;color: #feffff;margin-left: 70px">
        根据您当前的健康状态定制了个性化健康方案
      </div>
      <div class="jkfn">
        <div class="container" @click="returnToHealth">
          健康方案
        </div>
      </div>
      <div class="jkfn">
        <div class="container" @click="returnToFacial">
          面象分析结果
        </div>
      </div>
      <div class="jkfn">
        <div class="container" @click="returnToDiagnosis">
          舌象分析结果
        </div>
      </div>
    </div>
    <div class="footer">
      <span style="display: block;font-family: SourceHanSansCN-Normal;color: #ffa536;font-size: 24px;text-align: left">
        此报告仅作参考，如有不适请及时就医
      </span>
      <div class="dzbg" @click="showExplain"></div>
      <!--  底部 -->
      <div v-if="csr !== 'SHBAZE' && homeFootShow != null" :class="marker == 10.1 ? 'bottom bottom-ten' : 'bottom'">
        <img v-if="csr !== 'CHENSHANG'" :class="homeFootShow == 0 || homeFootShow == 2 ? '' : 'tipImg'" :src="csr === 'BJTRTKH' ? trt : csr === 'SHWYSLSWKJ' ? wysl : (homeFootShow == 0 || homeFootShow == 2 ? '' : logo )"/>
        <span class="tipTxt">{{csr === 'BJTRTKH' ? '中国北京同仁堂（集团）有限责任公司' : (csr === 'CHENSHANG' ? '上海辰尚信息科技有限公司' : (csr === 'SHWYSLSWKJ' ? '上海伍月石榴生物科技有限公司' :
        (homeFootShow == 0 ? '' : homeFootShow == 1 ? '上海祉云医疗科技有限公司' : homeFootShow == 2 ? logoName : `上海祉云医疗科技有限公司     |     ${logoName}` ) ))}}</span>
      </div>
    </div>
    <div v-if="flag">
      <div class="frame">
        <div class="spantitle">
          <img :src="reportData.qr_url" v-if="reportData.vendor_setting.qr_code_show === 2" style="width: 450px;height: auto;">
          <qrcode-vue v-else-if="this.shareUrl&&reportData.vendor_setting.qr_code_show === 1" :value='url' size='450'></qrcode-vue>
          <img :src="code" v-else-if="this.shareUrl&&reportData.vendor_setting.qr_code_show === 0" style="width: 450px;height: 450px;">
          <div v-else>暂无数据</div>
          <div class="fh" @click="showExplain"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import QrcodeVue from 'qrcode.vue'
  import { queryObject } from '../../../../utils/common.js';
  import logo from '../../../assets/img/common/company-logo-color.png';
  import trt from '../../../assets/img/common/trt_sy_icon.png';
  import wysl from '../../../assets/img/common/wysllogo.png';
  import code from '../../../assets/img/report/wechatcode.png';

  const { csr = '',  marker = ''} = queryObject();

  export default {
    name: "menopausejkfa",
    props:{
      reportData:Object,
    },
    data(){
      return{
        shareUrl:window.location.href,
        flag: false,
        logo,
        trt,
        wysl,
        code
      }
    },
    computed: {
      csr(){
        return csr
      },
      marker(){
        return marker
      },
      homeFootShow(){
        return this.reportData.vendor_setting.home_foot_show // 0什么都不显示 1 显示祉云logo  2 显示其他公司logo  3 祉云和其他公司logo共存
      },
      logoName(){
        return this.reportData.vendor_setting.logo
      },
      url(){
        if (this.reportData) {
          const url = `https://${window.location.host}/#/menopauseovary/${this.reportData.id}`;
          return url
        } else {
          return null
        }
      },
    },
    methods:{
      returnToHealth(){
        this.$router.push({name:'Physique',params: {report:this.reportData, id:this.$route.params.id}})
      },
      returnToFacial(){
        // 面诊结果
        const { report } = this.$store.state;
        this.$router.push({
          name:'Facereport',
          params: {
            facereport: report.face_result,
            id:report.id,
            report,
            isFromReportHome: true
          }
        })

        // this.$router.push({name:'Facial',params: {facereport:this.reportData.face_result, id:this.$route.params.id}})
      },
      returnToDiagnosis(){
        // 舌诊结果
        // 跳转到舌诊页面
        // this.$router.push({name: 'Tonguetzbs'})
        const { report } = this.$store.state;
        this.$router.push({
          name:'Tonguereport',
          params: {
            tonguereport: report.tongue_result,
            tongue_photo_url:report.tongue_photo_url,
            id:report.id,
            report,
            isFromReportHome: true
          }
        })

        // this.$router.push({name:'Diagnosis',params: {tonguereport:this.reportData.tongue_result, id:this.$route.params.id}})
      },
      showExplain(){
        this.flag=!this.flag
      }
    },
    components: {
      QrcodeVue
    },
  }
</script>

<style scoped>
  .jkfa{
    height: 168px;
    width:1000px;
    background:url("../../../assets/img/menopauseovary/jkfabj_icon.png");
    background-size:100% 100%;
    padding-top:15px;
  }
  .jkfn{
    float: left;
  }
  .container{
    width:273px;
    height:97px;
    background:url("../../../assets/img/menopauseovary/jkfa_icon.png");
    background-size:100% 100%;
    margin:20px 0 0 50px;
    font-family: SourceHanSansCN-Medium;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #ffffff;
    line-height:97px;
    text-align:center;
  }
  .footer{
    margin-top: 3%;
    position: relative;
  }
  .dzbg{
    position:absolute;
    left:800px;
    top:0px;
    display:block;
    width:195px;
    height:77px;
    background:url("../../../assets/img/menopauseovary/dzbg_icon.png") no-repeat;
    font-family: SourceHanSansCN-Medium;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #ffffff;
    text-align:center;
    line-height:54px;
  }
  .login{
    padding: 10px 42px 0 42px;
    font-size: 36px;
    color: white;
    line-height: 36px;
    text-align:center;
  }
  .imgsty{
    position: relative;
    top: 10px;
  }
  .frame{
    width:1080px;
    height:100%;
    position:absolute;
    top:0px;
    left:0px;
    z-index:99;
    background:rgba(0,0,0,0.6);
  }
  .spantitle{
    font-size: 42px;
    color:#ffffff;
    font-family:PingFangSC-Medium;
    width:868px;
    height:626px;
    background:url("../../../assets/img/menopauseovary/smk_icon.png") no-repeat;
    text-align:center;
    line-height:42px;
    background-size:100% 100%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    margin-top:615px;
    padding-top:80px;
  }
  .fh{
    height:67px;
    width: 224px;
    background:url("../../../assets/img/menopauseovary/fh_srsj_Icon.png") no-repeat;
    margin: auto;
    left:0;
    right:0;
    margin-top:28px;
  }

  .bottom {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100px;
  }

  .bottom .tipImg {
    width: 50px;
    height: 50px;
  }
  .bottom .tipTxt {
    height: 42px;
    font-size: 30px;
    font-weight: bold;
    color: #ffffff;
    margin-left: 20px;
  }

</style>
