var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "attentions" },
    [
      _c("h3", [_vm._v("二、" + _vm._s(_vm.type_attention) + "养生方案")]),
      _c("br"),
      _c("header", { staticStyle: { "font-weight": "bold" } }, [
        _vm._v("根据您最具倾向性的体质推荐以下个性化养生方案。")
      ]),
      _c("h4", [_vm._v("（一）饮食调养")]),
      _c("br"),
      _c("p", [
        _vm._v("1、宜食 "),
        _c("span", [_vm._v(_vm._s(_vm.foodList.good.content[0]))])
      ]),
      _vm.foodList.bad.content.length > 0
        ? _c("p", [
            _vm._v("2、忌食"),
            _c("span", [_vm._v(_vm._s(_vm.foodList.bad.content[0] || "暂无"))])
          ])
        : _vm._e(),
      _c("h3", { staticClass: "title" }, [_vm._v("推荐药膳")]),
      _c("br"),
      _vm._l(_vm.dietList, function(item) {
        return _c(
          "div",
          { key: item.name },
          [
            _c("div", { staticClass: "name" }, [
              _vm._v("【" + _vm._s(item.name) + "】")
            ]),
            _c("img", {
              staticClass: "image",
              attrs: { src: "" + _vm.solutions.asset_prefix + item.asset[0] }
            }),
            _c("p", { staticClass: "title2" }, [_vm._v("烹煮方法：")]),
            _vm._l(item.content, function(step, index) {
              return _c("p", { key: index, staticClass: "title3" }, [
                _vm._v(_vm._s(step))
              ])
            })
          ],
          2
        )
      }),
      _vm.teaList && _vm.teaList.length > 0
        ? _c(
            "div",
            { staticClass: "tea" },
            [
              _c("h4", [_vm._v("（二）茶饮推荐")]),
              _c("br"),
              _vm._l(_vm.teaList, function(item) {
                return _c("div", { key: item.name }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v("【" + _vm._s(item.name) + "】")
                  ]),
                  _c("img", {
                    staticClass: "image",
                    attrs: {
                      src: _vm.report.solutions.asset_prefix + item.asset,
                      alt: "茶饮图片"
                    }
                  }),
                  item.content.length > 0
                    ? _c("p", { staticClass: "tip-one" }, [
                        _vm._v("泡煮方法：")
                      ])
                    : _vm._e(),
                  _c("p", { staticClass: "content-text" }, [
                    _vm._v(_vm._s(item.content[0]))
                  ]),
                  item.content.length > 1
                    ? _c("p", { staticClass: "tip-one" }, [_vm._v("功效：")])
                    : _vm._e(),
                  _c("p", { staticClass: "content-text" }, [
                    _vm._v(_vm._s(item.content[1]))
                  ])
                ])
              })
            ],
            2
          )
        : _vm._e(),
      _c("div", { staticClass: "massagePress" }, [
        _c("h4", [_vm._v("（三）穴位按压")]),
        _c("br"),
        _vm.report.type_attention === "糖尿病"
          ? _c("div", [
              _c("p", { staticClass: "content-text" }, [
                _vm._v(_vm._s(_vm.massageList[0].content[0]))
              ]),
              _c("p", { staticClass: "content-text" }, [
                _vm._v(_vm._s(_vm.massageList[0].content[1]))
              ]),
              _c("p", { staticClass: "content-text" }, [
                _vm._v(_vm._s(_vm.massageList[0].content[2]))
              ])
            ])
          : _vm._e(),
        _vm.report.type_attention === "高血压"
          ? _c(
              "div",
              _vm._l(_vm.massageList, function(item, index) {
                return _c("div", { key: index, attrs: { title: item.name } }, [
                  _c("img", {
                    staticClass: "image",
                    attrs: {
                      src: _vm.report.solutions.asset_prefix + item.asset[0],
                      alt: "加载失败"
                    }
                  }),
                  _c("p", { staticClass: "tip-one" }, [_vm._v("定位：")]),
                  _c("p", { staticClass: "content-text" }, [
                    _vm._v(_vm._s(item.content[0]))
                  ]),
                  _c("p", { staticClass: "tip-one" }, [_vm._v("按摩方法：")]),
                  _c("p", { staticClass: "content-text" }, [
                    _vm._v(_vm._s(item.content[1]))
                  ])
                ])
              }),
              0
            )
          : _vm._e()
      ]),
      _vm.moxibustion && _vm.report.type_attention === "糖尿病"
        ? _c(
            "div",
            [
              _c("h4", [_vm._v("（四）艾灸")]),
              _c("br"),
              _vm._l(_vm.moxibustion, function(item, index) {
                return _c(
                  "div",
                  { key: index, style: { marginBottom: "50px" } },
                  [
                    _c("div", { staticClass: "name" }, [
                      _vm._v("【" + _vm._s(item.name) + "】")
                    ]),
                    _c("img", {
                      staticClass: "image",
                      attrs: {
                        src: _vm.report.solutions.asset_prefix + item.asset[0],
                        alt: "加载失败"
                      }
                    }),
                    _c("p", { staticClass: "content-text" }, [
                      _vm._v(_vm._s(item.content[0]))
                    ])
                  ]
                )
              })
            ],
            2
          )
        : _vm._e(),
      _vm._l(_vm.footBath, function(item, index) {
        return _c("div", { key: index }, [
          _c("h4", [_vm._v("（" + _vm._s(_vm.bathNum) + "）足浴疗法")]),
          _c("br"),
          _c("p", { staticClass: "name" }, [
            _vm._v(" 【" + _vm._s(item.name) + "】")
          ]),
          _c("img", {
            staticClass: "image",
            attrs: {
              src: _vm.report.solutions.asset_prefix + item.asset[0],
              alt: "加载失败"
            }
          }),
          _c("p", { staticClass: "tip-one" }, [
            _vm._v(
              _vm._s(
                _vm.report.type_attention === "糖尿病"
                  ? "方药组成："
                  : "足浴配方："
              )
            )
          ]),
          _c("p", { staticClass: "content-text" }, [
            _vm._v(_vm._s(item.content[0]))
          ]),
          _c("p", { staticClass: "tip-one" }, [
            _vm._v(
              _vm._s(
                _vm.report.type_attention === "糖尿病" ? "适应症：" : "操作："
              )
            )
          ]),
          _c("p", { staticClass: "content-text" }, [
            _vm._v(_vm._s(item.content[1]))
          ]),
          _vm.report.type_attention === "糖尿病"
            ? _c("p", { staticClass: "tip-one" }, [_vm._v("用法用量：")])
            : _vm._e(),
          _vm.report.type_attention === "糖尿病"
            ? _c("p", { staticClass: "content-text" }, [
                _vm._v(_vm._s(item.content[2]))
              ])
            : _vm._e()
        ])
      }),
      _c("div", [
        _c("h4", [_vm._v("（" + _vm._s(_vm.mentalNum) + "）精神调养")]),
        _c("p", { staticClass: "content-text" }, [
          _vm._v(_vm._s(_vm.mental.content[0]))
        ])
      ]),
      _vm.sportHealth && _vm.report.type_attention === "高血压"
        ? _c("div", [
            _c("h4", [_vm._v("（六）运动健身")]),
            _c("p", { staticClass: "content-text" }, [
              _vm._v(_vm._s(_vm.sportHealth.content[0]))
            ])
          ])
        : _vm._e(),
      _vm.exercises && _vm.report.type_attention === "糖尿病"
        ? _c(
            "div",
            [
              _c("h4", [_vm._v("（七）预防保健操")]),
              _vm._l(_vm.exercises.content, function(item, index) {
                return _c("p", { key: index, staticClass: "content-text" }, [
                  _vm._v(_vm._s(item))
                ])
              })
            ],
            2
          )
        : _vm._e(),
      _vm.report.classic_disease.length !== 0
        ? _c(
            "div",
            [
              _c("h4", [_vm._v("（八）经典药方")]),
              _vm._l(_vm.PrescriptionContent, function(item, index) {
                return _vm.PrescriptionContent
                  ? _c("div", { key: index, staticClass: "prescription" }, [
                      _c("p", { staticClass: "name" }, [
                        _vm._v("【" + _vm._s(item.name) + "】")
                      ]),
                      _c("p", { staticClass: "medicinal" }, [
                        _vm._v("药物组成：")
                      ]),
                      _c("p", { staticClass: "medicinal-explain" }, [
                        _vm._v(_vm._s(item.form))
                      ]),
                      _c("p", { staticClass: "medicinal" }, [_vm._v("功效：")]),
                      _c("p", { staticClass: "medicinal-explain" }, [
                        _vm._v(_vm._s(item.effect))
                      ])
                    ])
                  : _vm._e()
              }),
              _vm.PrescriptionContent
                ? _c("p", { staticClass: "tip" }, [
                    _vm._v(" *请在医生、药师指导下购买和服用 ")
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }