<template>
  <div class="tab">
    <div class="header_tab" ref="headertab">
      <ul ref="tabitem">
        <li
          v-for="(item, index) in buttonType"
          :key="index"
          :class="item.id === currentIndex ? 'activeheader' : ''"
          @click="getTab(item.id, $event)"
        >
          <div class="tab-content">
            <img :src="item.img" :alt="item.title">
            <span>{{ item.title }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'headertab',
  props: {
    buttonType: Array,
  },
  data(){
    return {
      currentIndex: 0,
    }
  },
  mounted(){
  },
  methods: {
    getTab(index, e) {
      this.currentIndex = index
      this.$emit('changed',index,e)
    },
  },
}
</script>

<style lang="scss" scoped>
.tab {
  width: 100%;
  height: 210px;
  display: flex;
  align-items: center;
  margin-top: -20px;
  border-bottom: 3px solid#2f7284;
}

.header_tab {
  display: flex;
  flex-wrap: nowrap;
  overflow: scroll;
}

.header_tab::-webkit-scrollbar {
  display: none;
}

ul {
  display: inline-block;
  white-space: nowrap;
}

li {
  display: inline-block;
  padding: 0px 10px;
  font-size: 30px;
  color: #fff;
  opacity: 0.6;
}

.tab-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 172px;
  height: 140px;
  margin: 0 5px;
}

img {
  height: 70px;
  width: auto;
  opacity: 0.6;
  padding: 10px 0;
}

.activeheader {
  font-size: 30px;
  color: #fff;
  opacity: 1;
  position: relative;
  img {
    opacity: 1;
  }
}

.activeheader:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  bottom: 0px;
  left: 1px;
  // background-color: #fff;
  border-radius: 50px;
}
</style>
