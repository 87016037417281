<template>
  <div class="product">
    <header>
      <h3 class="logo">特别推荐</h3>
      <p class="angle"></p>
    </header>
    <div>
      <table>
        <tr>
          <td>产品图片</td>
          <td>产品名称</td>
        </tr>
        <tr v-for="item in productList" :key="item.id">
          <td>
            <img :src="item.image" alt="产品图" />
          </td>
          <td>{{ item.name }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
  export default {
    name: "product",
    props: {
      product: Array
    },
    computed: {
      productList(){
        const list = [];
        for(let key of this.product) {
          if(key.products && key.products.length > 0) {
            for(let inner of key.products) {
              list.push(inner);
            }
          }
        }
        return list;
      }
    }
  }
</script>

<style scoped>
  .product {
    margin-top: 80px;
  }


  header .logo {
    font-size: 20px;
    font-weight: bold;
    line-height: 26px;
    letter-spacing: 0px;
    color: rgb(4, 138, 215);
    text-align: center;
    margin: 13px 0 5px 0;
  }

  header .angle {
    width: 0;
    height: 0;
    border-top: 10px solid #048AD7;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid transparent;
    text-align: center;
    margin: 0 auto;
  }

  .product table {
    border-collapse:collapse;
    /*color: rgb(204, 204, 204);*/
    font-size: 16px;
    margin-top: 20px;
    width: 100%;
  }

  table,
  tr,
  td {
    border: 2px solid rgb(204, 204, 204);
    padding: 20px 10px;
    text-align: center;
  }

  tr:nth-child(1) {
    background: #F5F5F5;
  }

  td:nth-child(1) {
    width: 70%;

  }

  table img {
    width: 50%;
    height: auto;
    display: block;
    margin: 0 auto;
  }

</style>