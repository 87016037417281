var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tongue" }, [
    _c("header", [_vm._v("二、舌诊分析结果")]),
    _c("div", { staticClass: "tongueTable" }, [
      _c("table", [
        _c("tr", [
          _c("td", { staticClass: "tongueImg", attrs: { rowspan: "6" } }, [
            _c("img", { attrs: { src: _vm.url, alt: "舌诊图片" } })
          ]),
          _c("td", { staticClass: "tongueTitle", attrs: { rowspan: "2" } }, [
            _vm._v("舌色")
          ]),
          _c("td", [
            _c("div", { staticClass: "circle" }, [
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass: "circleL",
                    style: { backgroundImage: "url(" + _vm.l_bg + ")" }
                  },
                  [_vm._v(_vm._s(_vm.tongueColor.L))]
                ),
                _c("p", { staticClass: "circleName" }, [_vm._v("L值")])
              ]),
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass: "circleA",
                    style: { backgroundImage: "url(" + _vm.a_bg + ")" }
                  },
                  [_vm._v(_vm._s(_vm.tongueColor.A))]
                ),
                _c("p", { staticClass: "circleName" }, [_vm._v("A值")])
              ]),
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass: "circleB",
                    style: { backgroundImage: "url(" + _vm.b_bg + ")" }
                  },
                  [_vm._v(_vm._s(_vm.tongueColor.B))]
                ),
                _c("p", { staticClass: "circleName" }, [_vm._v("B值")])
              ])
            ]),
            _c("p", { staticClass: "guide" }, [
              _vm._v(" L值越大舌色越鲜亮，a值越大越淡，b值越大越紫暗")
            ])
          ])
        ]),
        _c("tr", [
          _c("td", { staticClass: "conclusion" }, [
            _c("p", [
              _vm._v(
                "结果 ：" + _vm._s(_vm.result["tongue_nature_color_info"].name)
              )
            ]),
            _c("p", [
              _vm._v(
                "提示 ：" +
                  _vm._s(
                    _vm.result["tongue_nature_color_info"].content[0] === "正常"
                      ? ""
                      : "常见于"
                  ) +
                  _vm._s(_vm.result["tongue_nature_color_info"].content[0])
              )
            ])
          ])
        ]),
        _c("tr", [
          _c("td", { staticClass: "tongueTitle" }, [_vm._v("舌型")]),
          _c("td", { staticClass: "conclusion" }, [
            _c("h3", [
              _vm._v(
                _vm._s(
                  _vm.result.tongueFatThin === 0
                    ? _vm.result.tongueCrack === 0
                      ? "正常"
                      : "有裂痕"
                    : _vm.result.tongueCrack === 0
                    ? "舌胖"
                    : "舌胖、有裂痕"
                )
              )
            ]),
            _c("p", [
              _vm._v(
                "结果 ：" + _vm._s(_vm.result["tongue_fat_thin_info"].name)
              )
            ]),
            _c("p", [
              _vm._v(
                "提示 ：" +
                  _vm._s(
                    _vm.result["tongue_fat_thin_info"].content[0] === "正常"
                      ? ""
                      : "常见于"
                  ) +
                  _vm._s(_vm.result["tongue_fat_thin_info"].content[0])
              )
            ])
          ])
        ]),
        _c("tr", [
          _c("td", { staticClass: "tongueTitle", attrs: { rowspan: "2" } }, [
            _vm._v("苔色")
          ]),
          _c("td", [
            _c("div", { staticClass: "circle" }, [
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass: "circleL",
                    style: { backgroundImage: "url(" + _vm.l_bg + ")" }
                  },
                  [_vm._v(_vm._s(_vm.tongueColor.lipL))]
                ),
                _c("p", { staticClass: "circleName" }, [_vm._v("L值")])
              ]),
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass: "circleA",
                    style: { backgroundImage: "url(" + _vm.a_bg + ")" }
                  },
                  [_vm._v(_vm._s(_vm.tongueColor.lipA))]
                ),
                _c("p", { staticClass: "circleName" }, [_vm._v("A值")])
              ]),
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass: "circleB",
                    style: { backgroundImage: "url(" + _vm.b_bg + ")" }
                  },
                  [_vm._v(_vm._s(_vm.tongueColor.lipB))]
                ),
                _c("p", { staticClass: "circleName" }, [_vm._v("B值")])
              ])
            ]),
            _c("p", { staticClass: "guide" }, [
              _vm._v("L值越小苔色越暗，a值越大越白，b值越大越黄")
            ])
          ])
        ]),
        _c("tr", [
          _c("td", { staticClass: "conclusion" }, [
            _c("p", [
              _vm._v(
                "结果 ：" +
                  _vm._s(
                    _vm.result["tongue_coat_color_info"]
                      ? _vm.result["tongue_coat_color_info"].name
                      : "未识别到"
                  )
              )
            ]),
            _c("p", [
              _vm._v(
                "提示 ：" +
                  _vm._s(
                    _vm.result["tongue_coat_color_info"]
                      ? _vm.result["tongue_coat_color_info"].content[0] ===
                        "正常"
                        ? ""
                        : "常见于"
                      : ""
                  ) +
                  _vm._s(
                    _vm.result["tongue_coat_color_info"]
                      ? _vm.result["tongue_coat_color_info"].content[0]
                      : ""
                  )
              )
            ])
          ])
        ]),
        _c("tr", [
          _c("td", { staticClass: "tongueTitle" }, [_vm._v("苔质")]),
          _c("td", { staticClass: "conclusion" }, [
            _c("h3", [
              _vm._v(_vm._s(_vm.result.tongue_coat_thickness_info.symptom))
            ]),
            _c("p", [
              _vm._v(
                "结果 ：" +
                  _vm._s(_vm.result["tongue_coat_thickness_info"].name)
              )
            ]),
            _c("p", [
              _vm._v(
                "提示 ：" +
                  _vm._s(
                    _vm.result["tongue_coat_thickness_info"].content[0] ===
                      "正常"
                      ? ""
                      : "常见于"
                  ) +
                  _vm._s(_vm.result["tongue_coat_thickness_info"].content[0])
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }