var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "personal" }, [
    _c("div", { staticClass: "personal-content" }, [
      _c("span", { staticClass: "lable" }, [
        _vm._v("性别：" + _vm._s(_vm.gender))
      ]),
      _c("span", { staticClass: "lable" }, [
        _vm._v("BMI指数:" + _vm._s(_vm.bmi))
      ]),
      _c("span", { staticClass: "lable" }, [
        _vm._v("手机尾号:" + _vm._s(_vm.phone))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }