<template>
  <div v-if="reportData" class="wxhealthy">
    <wxhealthyheader :score="reportData.health_score" :displayId="reportData.display_id" :time="reportData.time"></wxhealthyheader>
    <div class="content">
      <wxinfo :info="reportData.user_info" :owner="reportData.owner"></wxinfo>
      <!-- 健康解读 -->
      <wxexplan :symptoms="reportData.symptoms" :symptomDefinition="reportData.solutions.symptom_definition" :healthRisk="reportData.solutions.health_risk"></wxexplan>
      <!-- 疾病健康风险提示 -->
      <wxhealthytip :risk="reportData.solutions.health_risk"
      :gender="reportData.owner.gender"
      :symptoms="reportData.symptoms"
      :result="reportData.health_status_text"></wxhealthytip>
      <!-- 面诊结果 -->
      <wxfaceresult :faceData="reportData.face_result"
      :facePhotoUrl="reportData.face_photo_url"></wxfaceresult>
      <!-- 舌诊结果 -->
      <wxtongueresult :tongueData="reportData.tongue_result"
      :tonguePhotoUrl="reportData.tongue_photo_url"></wxtongueresult>
      <!-- 脉诊结果  -->
      <wxpulseresult v-if="reportData.pulse.length !== 0" :pulse="reportData.pulse"></wxpulseresult>
      <!-- 血压结果 -->
      <wxbloodpressure v-if="reportData.pulse.length !== 0 && reportData.pulse[0].blood_dia !== 0 && reportData.pulse[0].blood_sys !== 0 " :pulse="reportData.pulse"></wxbloodpressure>
      <!-- 健康方案 -->
      <wxhealthyscheme :reportData="reportData"></wxhealthyscheme>
      <!--  -->
      <div class="tip">此报告仅作参考，如有不适请及时就医</div>
      <!-- 医生推荐 -->
      <wxdoctorlist v-if="reportData.doctor.length !== 0" :doctorData="reportData.doctor"></wxdoctorlist>
      <!-- 产品推荐 -->
      <wxproduct v-if="reportData.product.length !== 0" :productData="reportData.product"></wxproduct>
    </div>
  </div>
</template>

<script>
import { getReport } from '@/api/report.js';
import wxhealthyheader from './components/wxhealthyheader.vue';
import Wxinfo from './components/wxinfo.vue';
import Wxexplan from './components/wxexplan.vue';
import Wxhealthytip from './components/wxhealthytip.vue';
import Wxfaceresult from './components/wxfaceresult.vue';
import Wxtongueresult from './components/wxtongueresult.vue';
import Wxpulseresult from './components/wxpulseresult.vue';
import Wxbloodpressure from './components/wxbloodpressure.vue';
import Wxproduct from './components/wxproduct.vue';
import Wxhealthyscheme from './components/wxhealthyscheme.vue';
import Wxdoctorlist from './components/wxdoctorlist.vue';

export default {
  components: { wxhealthyheader, Wxinfo, Wxexplan, Wxhealthytip ,
    Wxfaceresult,
    Wxtongueresult,
    Wxpulseresult,
    Wxbloodpressure,
    Wxproduct,
    Wxhealthyscheme,
    Wxdoctorlist,
    },
  data(){
    return {
      reportData: null, 
    }
  },
  created(){
    this.getData();
  },
  methods: {
    getData(){
      getReport({
        id: this.$route.params.id
      }).then(res => {
        if(res && res.status_code === 10000 && res.data) {
          // console.log(res.data)
          this.reportData = res.data;
          this.$store.commit('SET_REPORT', res.data)
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.wxhealthy {
  width: 100%;
  background-color: #f5f5f5;
  .content {
    margin: -55px 45px 45px 45px;
  }
  .tip {
    width: 100%;
    text-align: center;
    font-family: MicrosoftYaHei;
	  font-size: 36px;
    color: #cccccc;
    padding: 42px 0;
  }
}
</style>