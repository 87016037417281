var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.reportData
    ? _c("div", { class: { chronicReport: _vm.isChronic } }, [
        _c(
          "div",
          {
            class:
              _vm.marker == 10.1
                ? "content-header content-header-ten"
                : "content-header"
          },
          [
            _c("div", { staticClass: "content-header-content" }, [
              _c("span", { staticClass: "title-one" }, [_vm._v("检测结果")]),
              _c("p", { staticClass: "title-two" }, [
                _vm._v("基于上海中医药大学研究专利开发"),
                _vm.marker !== "10.1"
                  ? _c("span", { on: { click: _vm.checkBooks } }, [_vm._v("?")])
                  : _vm._e()
              ])
            ]),
            _c("span", { staticClass: "content-header-border" }),
            _vm.reportData
              ? _c("div", { staticClass: "content-header-info" }, [
                  _c("span", { staticClass: "info-txt" }, [
                    _vm._v("检测时间：" + _vm._s(_vm.reportData.time))
                  ]),
                  _vm.marker !== "10.1"
                    ? _c("span", { staticClass: "info-txt info-count" }, [
                        _vm._v(
                          "(第" + _vm._s(_vm.reportData.detect_count) + "次)"
                        )
                      ])
                    : _vm._e(),
                  _c("span", { staticClass: "info-txt info-number" }, [
                    _vm._v("报告编号：" + _vm._s(_vm.reportData.display_id))
                  ])
                ])
              : _vm._e()
          ]
        ),
        _vm.reportData
          ? _c("div", { staticClass: "userInfo-content" }, [
              _c("span", { staticClass: "info-left" }, [
                _vm._v(
                  "性别: " +
                    _vm._s(_vm.reportData.user_info.gender === 1 ? "男" : "女")
                )
              ]),
              _vm.isChronic
                ? _c("span", [_vm._v("年龄: " + _vm._s(_vm.age) + "岁")])
                : _c("span", [_vm._v("BMI指数: " + _vm._s(_vm.bmi))]),
              _vm.reportData.owner.cellphone
                ? _c("span", { staticClass: "info-right" }, [
                    _vm._v(
                      "手机尾号:" +
                        _vm._s(_vm.reportData.owner.cellphone.substr(-4))
                    )
                  ])
                : _vm._e()
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }