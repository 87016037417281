<template>
  <div class="productTzbs">
    <!-- header -->
<!--    <div class="header" v-if="marker === '10.1'">-->
<!--      <div class="back-btn" @click="goBack">返&nbsp;回</div>-->
<!--      <div class="header-title">特别推荐</div>-->
<!--    </div>-->
    <!-- tab -->
    <product-tab :buttonType="tabList" @changed="changedIndex"></product-tab>
    <!--  产品列表 -->
    <div class="product" v-if="currentIndex === 0 && productList && productList.length > 0">
      <div v-for="(item, index) in productList" :key="index" class="item" @click="gotoDetail(item)">
        <img :src="item.image"/>
        <div class="title">
          <span class="names">{{item.name}}</span>
          <span v-if="item.price" class="price">¥ {{item.price}}</span>
        </div>
        <div class="desc">{{item.desc}}</div>
      </div>
    </div>
    <!--医生列表    -->
    <div v-if="currentIndex === 1 && doctorList && doctorList.length > 0">
      <doctor :doctorList="doctorList" @gotoDoctor="gotoDoctor"></doctor>
    </div>
  </div>
</template>

<script>
  import { queryObject } from '../../../utils/common.js';
  const { marker } = queryObject();
  import productTab from '@/components/physical/producttab.vue'
  import doctor from "../../components/common/doctorList";
  import { recordClick } from '../../api/report'


export default {
  components: { productTab, doctor },
  data(){
    return {
      active: 0,
      product: [],
      currentIndex: this.$store.state.report.product && this.$store.state.report.product.length > 0 ? 0 : 1,
      marker,
      tabList: [] // tab分类
    }
  },
  mounted(){
    const { report } = this.$store.state;
    this.product = report.product;
    // // 把产品按照id来进行排序
    // this.product.sort(function(a,b){
    //   return a.id-b.id
    // });

    if(report.product && report.product.length > 0) {
      this.tabList.push({
        name: '养生优品',
        index: 0
      });
    }
    if(report.doctor && report.doctor.length > 0) {
      this.tabList.push({
        name: '医生',
        index: 1
      });
    }


  },
  computed: {
    productList(){ // 产品列表
      let proList = [];
      for(let category of this.product) {
        const cateList = category.products;
        if(cateList && cateList.length > 0) {
          proList = proList.concat(cateList)
        }
      }
      return proList
    },
    doctorList(){ // 医生列表
      return this.$store.state.report.doctor
    },

  },
  methods: {
    goBack(){
      this.$router.back()
    },
    changedIndex(index){
      this.currentIndex = index
    },
    gotoDetail(e){
      // 埋点（镜子上，点击进入到产品详情）
      console.log('进入产品详情', e.id)
      const params = {
        product_id: e.id,    //  产品id
        count_type: "mirror",  // 从镜子端过来的就是mirror  微信端过来的是wechat
      };
      recordClick(params); // 记录一下用户的点击事件

      this.$store.commit("SET_CURRENT_PRODUCT", e); // 当前选中要展示的内容
      // if(this.marker === '10.1') {
      //   this.$router.push({name:'Productdetailtzbs'})
      // } else {
      //   this.$store.commit("SET_DETAIL_SHOW", true); // 非10.1寸镜子，养生方案里面全屏展示
      //
      // }
      this.$store.commit("SET_DETAIL_SHOW", true); // 非10.1寸镜子，养生方案里面全屏展示

    },

    gotoDoctor(val){
      if(window.zhiyun) {
        window.zhiyun.goDoctor(val.id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$bgImg: '../../assets/img/common/bj.png';
$backBtn: '../../assets/img/physical/fh_ty_icon.png';
$headerTitle: '../../assets/img/report/btbj_icon.png';

.productTzbs {
  overflow: hidden;
  max-height: 1400px;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    margin-top: 90px;
    margin-left: 5%;
    margin-bottom: 50px;
    .back-btn {
      background:url($backBtn) no-repeat;
      background-size:100% 100%;
      width: 152px;
      height: 82px;
      color:#fff;
      font-size:36px;
      text-align:center;
      line-height:75px;
    }
    .header-title {
      font-size: 60px;
      color:#00fefb;
      font-family:TITLE;
      width:330px;
      height:120px;
      margin-left: 18%;
      background:url($headerTitle) no-repeat;
      text-align:center;
      line-height:120px;
      background-size:100% 100%;
    }
  }

  .product {
    display: flex;
    flex-flow: wrap;
    /*width: 100%;*/
    /*margin-left: 2.5%;*/
    max-height: 1300px;
    overflow: auto;
    .item {
      background-color: #fff;
      padding: 10px 10px 20px 10px;
      width: 42.5%;
      margin: 2.5%;
    }
    img {
      height: 300px;
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }
    .title {
      font-size: 36px;
      display: flex;
      justify-content: space-between;
      margin: 30px 0 20px 0;
    }
    .price {
      color: #00fefb;
      width: 30%;
      text-align: right;
    }
    .desc,
    .names{
      color: #666;
      font-size: 32px;
      line-height: 50px;
      max-height: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .names {
      width: 70%;
    }
  }

}
</style>