<template>
<div class="visceraChart">
  <div v-if="marker == 10.1" class="chart-tip">
    <div class="tip-content"><div class="line"></div><span>平和质</span></div>
    <div v-if="physical.substring(0,3) != '平和质'" class="tip-content"><div class="line line-two"></div><span>{{physical}}</span></div>
  </div>
  <div v-if="marker == 10.1" id="viscera" :style="{width: '830px', height: '430px', marginTop:'-80px'}"></div>
  <div v-else id="viscera" :style="{width: '830px', height: '430px'}"></div>
  <div class="text">提示：{{physicalViscera.viscera_attention}}</div>
</div>
</template>

<script>
import * as echarts from 'echarts'
import { queryObject } from '../../../utils/common.js';
const { marker = '' } = queryObject()

export default {
  name: "viscerachart",
  props: {
    physicalViscera: Object,
    physical: String,
  },
  data(){
    return {
      showLegend: true,
    }
  },
  computed: {
    marker(){
      return marker
    },
  },
  mounted(){
    if(this.marker == 10.1) this.showLegend = false
    else this.showLegend = true
    this.drawChart()
  },
  methods: {
    drawChart(){
      const viscera = this.physicalViscera;
      const myChart = echarts.init(document.getElementById('viscera'));
      myChart.setOption({
        legend: {
          data: this.physical.substring(0,3) == '平和质' ? ['平和质'] : ['平和质', this.physical],
          show: this.showLegend,
          right: "1%",
          width: "auto",
          orient: "vertical",
          itemWidth: 40,
          itemHeight: 4,
          textStyle: {
            color: "#fff",
            fontSize: 20,
          }
        },
        color:this.physical.substring(0,3) == '平和质' ? ['#069eff'] : ['#069eff','#ffad2c'],  
        radar: {
          center: ['50%', '50%'], 
          startAngle: 90,
          splitNumber: 5,
          axisName: {
            formatter(value) {
              return `{a|${value}}`;
            },
            rich: {
              a: {
                color: '#fff',
                fontSize: 24,
                lineHeight: 14.9,
              }
            },
          },
          axisLine: { //坐标轴
            show: false
          },
          splitLine: {
            lineStyle: {
              width: 1,
              color: '#15456a'
            }
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ['rgba(33,107,152,0.48)', 'rgba(33,107,152,0.36)', 'rgba(33,107,152,0.28)', 'rgba(33,107,152,0.2)', 'rgba(33,107,152,0.1)'],
            }
          },
          indicator: [{name: '心', max: 100}, {name: '肾', max: 100}, {name: '肺', max: 100}, {name: '脾', max: 100}, {name: '肝', max: 100}]
        },
        series: [{
          type: 'radar',
          symbol: "none", // 去掉图表中各个图区域的边框线拐点
          data: [
            {
              name: '平和质',
              value: [20, 20, 20, 20, 20],
              areaStyle: {
                color:'rgba(12,153,116,0.1)',
              },
              lineStyle: {
                color: '#069eff',
                width: 2,
                type:'solid'
              },
            },
            {
              name: this.physical.substring(0,3) == '平和质' ? null : this.physical,
              value: this.physical.substring(0,3) == '平和质' ? null : [viscera.heart_percent, viscera.kidney_percent, viscera.lung_percent, viscera.spleen_percent, viscera.liver_percent],
              areaStyle: { // 单项区域填充样式
                color:'rgba(12,153,116,0.1)',
              },
              lineStyle: {
                color: '#ffad2c',
                width: 2,
                type:'solid'
              },
            },
          ]
        }]
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.visceraChart {
  text-align: center;
  margin-top: 20px;
  .chart-tip {
    height: 80px;
    .tip-content {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 20px;
      .line {
        height: 4px;
        width: 40px;
        background-color: #069eff;
        margin-right: 10px;
      }
      .line-two {
        background-color: #ffad2c;
      }
      span {
        width: 120px;
        text-align: left;
      }
    }
  }
  .text {
    font-size: 30px;
    color: #00fefb;
    text-align: center;
    padding-bottom: 30px;
  }
}
</style>