var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.report
    ? _c(
        "div",
        { staticClass: "print_report" },
        [
          _c("Personaltzbs", {
            attrs: {
              reportId: _vm.report.display_id,
              time: _vm.report.time,
              owner: _vm.report.owner,
              user_info: _vm.report.user_info,
              cellphone: _vm.report.owner || _vm.report.agency_info,
              chronicType: _vm.report.type_attention
            }
          }),
          _c("Conclude", {
            attrs: {
              chronicType: _vm.report.type_attention,
              symptoms: _vm.report.symptoms
            }
          }),
          _c("Analysistzbs", { attrs: { report: _vm.report } }),
          _c("div", { staticStyle: { "page-break-after": "always" } }),
          _c("Method", {
            attrs: {
              symptoms: _vm.report.symptoms,
              solutions: _vm.report.solutions
            }
          }),
          _c("Footertzbs")
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }