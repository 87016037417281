var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "basic" }, [
      _c("p", [_vm._v("检测时间："), _c("span", [_vm._v(_vm._s(_vm.date))])]),
      _c("p", [
        _vm._v("报告编号："),
        _c("span", [_vm._v(_vm._s(_vm.reportId))])
      ])
    ]),
    _c("div", { staticClass: "personal" }, [
      _c("p", [_vm._v("性别："), _c("span", [_vm._v(_vm._s(_vm.gender))])]),
      _c("p", [_vm._v("BMI指数："), _c("span", [_vm._v(_vm._s(_vm.bmi))])]),
      _c("p", [_vm._v("手机尾号："), _c("span", [_vm._v(_vm._s(_vm.phone))])])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [
      _c("h1", [_vm._v("祉云中医健康检测报告")]),
      _c("h3", [_vm._v("基于上海中医药大学研究专利开发")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }