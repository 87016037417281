var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header" }, [
    _c("div", { staticClass: "header-top" }, [
      _c("span", { staticClass: "result" }, [_vm._v("检测结果")]),
      _c("p", { staticClass: "title" }, [
        _vm._v("基于上海中医药大学研究专利开发"),
        _vm.marker !== "10.1"
          ? _c("span", { on: { click: _vm.checkBooks } }, [_vm._v("?")])
          : _vm._e()
      ]),
      _c("div", { staticClass: "return", on: { click: _vm.doclose } }),
      _c("div", { staticClass: "border" })
    ]),
    _c("div", { staticClass: "header-bottom" }, [
      _c("span", { staticClass: "time" }, [
        _vm._v("检测时间：" + _vm._s(_vm.date))
      ]),
      _vm.marker !== "10.1"
        ? _c("span", { staticClass: "count" }, [
            _vm._v("(第" + _vm._s(_vm.count) + "次)")
          ])
        : _vm._e(),
      _c("span", { staticClass: "number" }, [
        _vm._v("报告编号：" + _vm._s(_vm.reportId))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }