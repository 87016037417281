var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "analyze" }, [
    _c("h4", [_vm._v("(二)中医辩证分型")]),
    _c("div", [
      _c("p", [_vm._v(_vm._s(_vm.conclusion))]),
      _c("p", [_vm._v(_vm._s(_vm.concludeAdd))]),
      _c("p", [_vm._v(_vm._s(_vm.againText))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }