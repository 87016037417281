var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.facereport
    ? _c("div", { staticClass: "facereport" }, [
        _vm._m(0),
        _c("div", { staticClass: "result" }, [
          _vm._m(1),
          _c("div", { staticClass: "face-content" }, [
            _c("div", { staticStyle: { "padding-top": "29px" } }, [
              _c("span", { staticClass: "title-tip" }, [
                _vm._v(_vm._s(_vm.facereport.face_color_info.symptom))
              ])
            ]),
            _c(
              "div",
              {
                staticStyle: {
                  width: "86%",
                  "margin-left": "14%",
                  "margin-top": "20px"
                }
              },
              [
                _c("div", { staticStyle: { "text-align": "left" } }, [
                  _c("span", { staticClass: "span-tip" }, [
                    _vm._v(
                      "提示：" + _vm._s(_vm.facereport.face_color_info.name)
                    )
                  ]),
                  _c("br"),
                  _c("span", { staticClass: "span-tip" }, [
                    _vm._v(
                      "可能原因：" +
                        _vm._s(_vm.facereport.face_color_info.content.join(";"))
                    )
                  ])
                ])
              ]
            )
          ]),
          _c("div", { staticClass: "face-content" }, [
            _c("div", { staticStyle: { "padding-top": "29px" } }, [
              _c("span", { staticClass: "title-tip" }, [
                _vm._v(_vm._s(_vm.facereport.lip_color_info.symptom))
              ])
            ]),
            _c("div", { staticStyle: { width: "86%", "margin-left": "14%" } }, [
              _c("div", { staticStyle: { "text-align": "left" } }, [
                _c("span", { staticClass: "span-tip" }, [
                  _vm._v("提示：" + _vm._s(_vm.facereport.lip_color_info.name))
                ]),
                _c("br"),
                _c("span", { staticClass: "span-tip" }, [
                  _vm._v(
                    "可能原因：" +
                      _vm._s(_vm.facereport.lip_color_info.content.join(";"))
                  )
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "face-gz" }, [
            _c(
              "div",
              {
                staticStyle: {
                  height: "100%",
                  width: "90%",
                  "margin-left": "10%"
                }
              },
              [
                _c(
                  "div",
                  { staticStyle: { height: "30%", "margin-top": "35px" } },
                  [
                    _c("div", { staticClass: "circular4" }, [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "font-size": "34px",
                            "line-height": "112px"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.facereport.gloss_indicator.toFixed(2))
                          )
                        ]
                      )
                    ])
                  ]
                ),
                _vm._m(2),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-top": "20px",
                      "text-align": "left",
                      "margin-left": "3%"
                    }
                  },
                  [
                    _c("span", { staticClass: "span-tip" }, [
                      _vm._v(
                        "结果：" + _vm._s(_vm.facereport.face_gloss_info.name)
                      )
                    ]),
                    _c("br"),
                    _c("span", { staticClass: "span-tip" }, [
                      _vm._v(
                        "提示：" +
                          _vm._s(_vm.facereport.face_gloss_info.symptom)
                      )
                    ]),
                    _c("br"),
                    _c("span", { staticClass: "span-tip" }, [
                      _vm._v(
                        "可能原因：" +
                          _vm._s(
                            _vm.facereport.face_gloss_info.content.join(";")
                          )
                      )
                    ])
                  ]
                )
              ]
            )
          ]),
          _c("div", { staticClass: "back", on: { click: _vm.returnto } })
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "face-top" }, [
      _c("span", { staticClass: "title" }, [_vm._v("面象结果")]),
      _c("span", { staticClass: "title-border" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgsty" }, [
      _c("img", {
        staticStyle: { height: "375px", "margin-top": "20px" },
        attrs: {
          src: require("../../assets/img/menopauseovary/mxjg.gif"),
          alt: "面诊图片"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "margin-top": "2px" } }, [
      _c("span", { staticClass: "spansty" }, [_vm._v("面部光泽指数")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }