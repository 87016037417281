var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isTzbsReport
      ? _c("div", [
          _c("div", { staticClass: "score" }, [
            _c(
              "div",
              { staticClass: "circleBox" },
              [
                _c(
                  "van-circle",
                  {
                    attrs: {
                      rate: _vm.score,
                      speed: _vm.speed,
                      "stroke-width": _vm.strokeWidth,
                      size: _vm.size,
                      color: _vm.color,
                      "layer-color": _vm.layerColor,
                      "text-font-size": 20
                    },
                    model: {
                      value: _vm.score,
                      callback: function($$v) {
                        _vm.score = $$v
                      },
                      expression: "score"
                    }
                  },
                  [
                    _c("div", { staticClass: "text" }, [
                      _c(
                        "p",
                        {
                          style: {
                            color:
                              _vm.score > 89
                                ? "green"
                                : _vm.score > 59
                                ? "#FE822B"
                                : "red"
                          }
                        },
                        [_vm._v(_vm._s(Math.round(_vm.score)))]
                      ),
                      _c("p", [_vm._v("健康指数(分)")])
                    ])
                  ]
                )
              ],
              1
            ),
            _c("div", { staticClass: "symptom" }, [
              _c(
                "div",
                {
                  staticClass: "conclusion",
                  style: {
                    color:
                      _vm.score > 89
                        ? "green"
                        : _vm.score > 59
                        ? "#FE822B"
                        : "red"
                  }
                },
                [_vm._v(_vm._s(_vm.scoreToStatusText))]
              ),
              _vm.score <= 89
                ? _c("p", { staticClass: "doctor" }, [
                    _vm._v("建议找中医专家调治")
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "detailSymptom" },
                _vm._l(_vm.symptoms, function(item) {
                  return _c("span", { key: item.name }, [
                    _vm._v(" " + _vm._s(item.name) + " ")
                  ])
                }),
                0
              ),
              _vm._m(0)
            ])
          ]),
          _vm._m(1)
        ])
      : _c("div", [
          _c("p", { staticClass: "tzbsResult" }, [
            _vm._v("您的体质："),
            _c("span", [_vm._v(_vm._s(_vm.tzbsResult))])
          ])
        ]),
    _c(
      "div",
      { staticClass: "symptomDesc" },
      _vm._l(_vm.symptoms, function(item) {
        return _c(
          "div",
          {
            key: item.name,
            style: { margin: _vm.isTzbsReport ? "25px 0 25px 0" : "80px 0 0 0" }
          },
          [
            _c("div", { style: _vm.illNameStyle }, [_vm._v(_vm._s(item.name))]),
            _c("h3", [_vm._v("定义解释 ：")]),
            _c("div", [_vm._v(_vm._s(_vm.symptomName(item).definition))]),
            _c("h3", [_vm._v("健康风险提示 ：")]),
            _c("div", [_vm._v(_vm._s(_vm.symptomName(item).notice))]),
            _c("h3", [_vm._v("可能原因 ：")]),
            _vm._l(_vm.symptomName(item).reason, function(inner, index) {
              return _c("p", { key: index }, [
                _vm._v(" " + _vm._s(inner) + " ")
              ])
            })
          ],
          2
        )
      }),
      0
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [
      _c("p", [_vm._v("健康：90-100分")]),
      _c("p", [_vm._v("亚健康：60-89分")]),
      _c("p", [_vm._v("可能有疾病：0-59分")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "explain" }, [
      _c("h3", [_vm._v("健康状态辨识总提纲解释说明")]),
      _c("p", [
        _vm._v(
          "中医在“未病先防，既病防变，瘥后防复”方面有着独特价值和先进性。中医学在人体健康或疾病本质状态的认识上，早已形成了较为完整的 系统认识论和方法学。它是从整体的状态把握人体健康或疾病的本质，从状态上调理与治疗。 "
        )
      ]),
      _c("p", [
        _vm._v(
          "舌、面诊是中医诊病辨证的重要方法。《灵枢》云:“十二经脉，三百六十五络，其血气皆上于面而走空窍。”《辨舌指南•绪言》云： “舌为心之外候，苔乃胃之明征，察舌可占正之盛衰，验苔以识邪之出入。”说明通过观察舌、面诊的外在征象可以了解人体的健康状态和病情变化。 "
        )
      ]),
      _c("p", [
        _vm._v(
          "本系统通过采集您的面象、舌象，结合10余个问题，实时监测您的身体健康状态，并给出健康报告和个性化的起居养生、饮食药膳、 穴位按压、中医功法、音乐养生等健康保健方案。 "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }