var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h4", [_vm._v("(七) 脉诊分析")]),
    _c("br"),
    _c("div", { staticClass: "place" }, [
      _c("img", { attrs: { src: _vm.pulse[0].pulse_image, width: "40%" } })
    ]),
    _c("p", [_vm._v("总脉：" + _vm._s(_vm.pulse[0].summary))]),
    _vm.pulse[0].summary_attention
      ? _c("p", [_vm._v("提示：" + _vm._s(_vm.pulse[0].summary_attention))])
      : _vm._e(),
    _vm.pulse[0].blood_dia || _vm.pulse[0].blood_sys
      ? _c("p", { staticStyle: { "margin-top": "10px" } }, [_vm._v("血压：")])
      : _vm._e(),
    _vm.pulse[0].blood_dia || _vm.pulse[0].blood_sys || _vm.pulse[0].heart_rate
      ? _c("table", [
          _c("tr", [
            _vm.pulse[0].blood_dia ? _c("td", [_vm._v("舒张压")]) : _vm._e(),
            _vm.pulse[0].blood_dia
              ? _c("td", [_vm._v(_vm._s(_vm.pulse[0].blood_dia) + "mmHg")])
              : _vm._e(),
            _vm.pulse[0].blood_sys ? _c("td", [_vm._v("收缩压")]) : _vm._e(),
            _vm.pulse[0].blood_sys
              ? _c("td", [_vm._v(_vm._s(_vm.pulse[0].blood_sys) + "mmHg")])
              : _vm._e(),
            _vm.pulse[0].heart_rate ? _c("td", [_vm._v("心率")]) : _vm._e(),
            _vm.pulse[0].heart_rate
              ? _c("td", [_vm._v(_vm._s(_vm.pulse[0].heart_rate) + "次/分")])
              : _vm._e()
          ])
        ])
      : _vm._e(),
    _vm.pulse[0].blood_dia && _vm.pulse[0].blood_sys
      ? _c("p", [_vm._v(" 提示：" + _vm._s(_vm.tipBlood()) + " ")])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }