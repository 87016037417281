<template>
  <div class="menopause_report" v-if="report">
    <menopauseheader :time="report.time" :reportId="report.display_id" :type_attention="report.type_attention" :count="report.detect_count"></menopauseheader>
    <menopausechart
      :symptoms="report.symptoms"
      :symptom_status="report.symptom_status"
      :before_symptom_status="report.before_symptom_status.before_symptom_status"
      :type_attention="report.type_attention"
    ></menopausechart>
    <menopauseuserinfo
      :owner="report.owner"
      :user_info="report.user_info"
    ></menopauseuserinfo>
    <menopauseindicator
      :health_status="report.health_status"
      :report="report"
    ></menopauseindicator>
    <menopausejbfx
      :reportData="report"
    ></menopausejbfx>
    <menopausejkfa
      :reportData="report"
    ></menopausejkfa>
  </div>

</template>

<script>
  import { getMenopauseOvary } from '@/api/report.js';
  import menopauseheader from './components/menopauseheader.vue';
  import menopausechart from './components/menopausechart.vue';
  import menopauseuserinfo from './components/menopauseuserinfo.vue';
  import menopauseindicator from './components/menopauseindicator.vue';
  import menopausejbfx from './components/menopausejbfx.vue';
  import menopausejkfa from './components/menopausejkfa.vue';

  export default {
    name: "index",
    components: {
      menopauseheader,
      menopausechart,
      menopauseuserinfo,
      menopauseindicator,
      menopausejbfx,
      menopausejkfa,
    },
    data(){
      return{
        report: null,
      }
    },
    created() {
      this.getMenopauseReport()
    },
    methods:{
      getMenopauseReport(){
        getMenopauseOvary({
          id: this.$route.params.id
        }).then(res => {
          if(res && res.status_code === 10000 && res.data) {
            this.report = res.data;
            this.$store.commit('SET_REPORT', res.data);
          }
        })
      }
    }
  }
</script>

<style scoped>
  .menopause_report{
    height:100%;
    width:100%;
    background:url("../../assets/img/report/bj_sznr_icon.png");
    background-size:cover;
    overflow: hidden;
  }

</style>
