var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "content" }, [
      _c("span", { staticClass: "item" }, [
        _vm._v("性别 : " + _vm._s(_vm.user_info.gender === 2 ? "女" : "男"))
      ]),
      _c("span", { staticClass: "item" }, [
        _vm._v("年龄 : " + _vm._s(_vm.user_info.ages) + "岁")
      ]),
      _c("span", { staticClass: "item" }, [
        _vm._v("手机尾号 : " + _vm._s(_vm.owner.cellphone.substr(-4)))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }