<template>
  <div class="illness" :style="marker ==='10.1'?{ height: '27%' } : { height: '29%' }">
    <div style="position: relative;height: 100%">
      <p class="illness-txt" :style="{'color':health_status_text === '健康' ? '#00a10b':'#fe822b'}">
        <img src="../../../assets/img/common/wjkfx_icon.png" v-show="health_status_text === '健康'" style="height: 36px;vertical-align: middle;">
        <img src="../../../assets/img/common/jbfx_jcbg_icon.png" v-show="health_status_text !== '健康'" style="height: 36px;vertical-align: middle;">
        {{health_status_text === '健康'? '无健康风险' : '健康风险提示'}}
      </p>
      <img :style="marker ==='10.1'?{ height: '95%' } : { height: '100%' }" class="illness-pic" :src="picname">
    </div>
  </div>
</template>

<script>
  import jiankang10 from '../../../assets/img/report/illness/healthy.png';
  import pixu10 from '../../../assets/img/report/illness/Spleen.png';
  import qixu from '../../../assets/img/report/illness/qx.png';
  import shenxu110 from '../../../assets/img/report/illness/Kidney-man.png';
  import shenxu210 from '../../../assets/img/report/illness/Kidney-women.png';
  import tanshi10 from '../../../assets/img/report/illness/Phlegm.png';
  import yangxu110 from '../../../assets/img/report/illness/deficiency-man.png';
  import yangxu210 from '../../../assets/img/report/illness/deficiency-woman.png';
  import yinxu from '../../../assets/img/report/illness/yx.png';
  import yuzhi110 from '../../../assets/img/report/illness/Stagnation-man.png';
  import yuzhi210 from '../../../assets/img/report/illness/Stagnation-woman.png';
  import {queryObject} from "../../../../utils/common";
  const {  marker = '' } = queryObject();
  export default {
    name: "illness",
    props: {
      health_status_text: String,
      owner:Object,
      symptoms:Array,
    },
    data(){
      return{
        jbfxnan10:{
          脾虚: pixu10,
          气虚: qixu,
          肾虚: shenxu110,
          痰湿: tanshi10,
          阳虚: yangxu110,
          阴虚: yinxu,
          郁滞: yuzhi110,
          健康: jiankang10,
        },
        jbfxnv10:{
          脾虚: pixu10,
          气虚: qixu,
          肾虚: shenxu210,
          痰湿: tanshi10,
          阳虚: yangxu210,
          阴虚: yinxu,
          郁滞: yuzhi210,
          健康: jiankang10,
        },
        symptomName:'',
        picname:''
      }
    },
    computed:{
      marker(){
        return marker
      }
    },
    mounted() {
      let gender = 1;
      if (this.owner){
        gender = this.owner.gender;
      }else{
        gender = 1;
      }
      this.symptomName = this.symptoms[0] ? this.symptoms[0].name:'健康';
      this.picname = gender === 1 ?this.jbfxnan10[this.symptomName] : this.jbfxnv10[this.symptomName];
    }
  }
</script>

<style scoped>
  .illness{
    width: 100%;
    /*margin: 0 auto;*/
    height: 29%;
    text-align: center;
    margin-top: 0;
    page-break-before: always;
  }
  .illness-txt{
    position:absolute;
    font-size: 36px;
    left: 80px;
    top: 22px;
    margin: 0;
  }
  .illness-pic{
    width: 1002px;
    height: 100%;
    left: 24px;
    top: 40px;
  }

</style>
