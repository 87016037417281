<template>
  <div class="method">
    <header>
      <p class="tips">*此报告仅作参考，如有不适请及时就医</p>
      <h3 class="title">根据您当前的健康状态定制了个性化养生方案</h3>
      <h3 class="logo">养生方案</h3>
      <p class="angle"></p>
    </header>
    <div class="preserveHealth">
      <p v-if="!isTzbsReport">1、保健原则</p>
      <div v-if="!isTzbsReport">{{ principle }}</div>
      <p>{{ isTzbsReport? 1 : 2}}、饮食调养</p><div>
        <div class="good">
          宜食：<span v-for="(item,index) in foodList.good" :key="index">{{ item.name }}{{ index === foodList.good.length - 1 ? '。' : '、' }}</span>
        </div>
        <div class="bad">
          忌食：<span v-for="(item,index) in foodList.bad" :key="index">{{ item.name }}{{ index === foodList.bad.length - 1 ? '。' : '、' }}</span>
        </div>
        <div class="diet" v-if="!isTzbsReport">
          <h3 class="title">推荐药膳</h3>
          <div v-for="(item,index) in dietList" :key="index">
            <h3 class="dietName">{{ item.name }}</h3>
            <div class="imageBox">
              <div class="imageUnit" v-for="(img,imgIndex) in item.asset" :key="imgIndex">
                <img :src="solutions.asset_prefix + img[1]" alt="药膳图片" />
                <p>{{ img[0]}} </p>
              </div>
            </div>
            <div class="dietMenu">
              清单：{{ item.content[0] }}
            </div>
            <div class="dietCook">
              烹煮方法：{{ item.content[1] }}
            </div>
          </div>
        </div>
        <div class="diet" v-else >
          <h3 class="title">推荐药膳</h3>
          <h3 class="dietName">{{ dietList.name }}</h3>
          <div class="imageBoxTzbs">
            <img :src="solutions.asset_prefix + dietList.asset" alt="药膳图片" />
          </div>
          <div class="dietCook">
            烹煮方法：
          </div>
          <div v-for="(item,index) in dietList.content" :key="index">
            {{ item }}
          </div>
        </div>
      </div>
      <p>{{ isTzbsReport? 2 : 3}}、茶饮养生</p>
      <div class="tea">
        <h3 class="teaName">{{ teaList.name }}</h3>
        <div class="imgBox">
          <img :src="solutions.asset_prefix + teaList.asset" alt="茶饮图片" />
        </div>
        <div class="dietMenu">
          {{ isTzbsReport? '配料' : '清单'}}：{{ teaList.content && teaList.content[0] }}
        </div>
        <div class="dietCook">
          {{ isTzbsReport? '泡煮' : '烹煮'}}方法：{{ teaList.content && teaList.content[1] }}
        </div>
      </div>
      <div v-if="!isTzbsReport">
        <p>4、其他养生</p>
        <div class="otherPreserve">
          <table>
            <tr>
              <td>起居指导</td>
              <td class="healthGuide">
                <span v-for="(item,index) in sleepGuide.content" :key="index">{{item}}</span>
              </td>
            </tr>
            <tr>
              <td>穴位按压</td>
              <td class="massage">
                <p>1、定位：{{ massageList.content && massageList.content[0] }}</p>
                <p>2、按摩方法：{{ massageList.content && massageList.content[1] }}</p>
                <img :src="solutions.asset_prefix + massageList.asset" alt="穴位图片">
              </td>
            </tr>
            <tr>
              <td>音乐养生</td>
              <td>《{{ musicList }}》</td>
            </tr>
            <tr>
              <td>经典药方</td>
              <td class="classic">
                <div v-for="item in classicMedicine" :key="item.name">
                  <h3>{{ item.name }}</h3>
                  <p>药物组成：{{ item.content && item.content[0] }}</p>
                  <p>功效：{{ item.content && item.content[1] }}</p>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div v-else>
        <p class="pressTitle">3、穴位按压</p>
        <div class="massagePress">
          <h3 class="massageName">{{ massageList.name }}</h3>
          <div class="imgBox">
            <img :src="solutions.asset_prefix + massageList.asset" alt="穴位按压图片" />
          </div>
          <div class="massageMenu">
            {{ massageList.content && massageList.content[0] }}
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    name: "method",
    props: {
      symptoms: Array, // 病症
      solutions: Object,
      isTzbsReport: Boolean, // 是否是体质辨识报告
    },
    computed: {
      symptomsName(){
        return this.symptoms[0] ? this.symptoms[0].name:'健康';
      },
      principle(){
        if (!this.isTzbsReport) {
          const hg = this.solutions.health_principle && this.solutions.health_principle[this.symptomsName];
          return hg && hg.content && hg.content[0]
        } else {
          return null;
        }

      },
      foodList(){
        const foodList = {};
        const fieldNameGood = this.isTzbsReport? 'tizhi_good_food' : 'good_food';
        const fieldNameBad = this.isTzbsReport? 'tizhi_bad_food' : 'bad_food';
        foodList.good = this.solutions[fieldNameGood] && this.solutions[fieldNameGood][this.symptomsName]; // 宜食
        foodList.bad = this.solutions[fieldNameBad] && this.solutions[fieldNameBad][this.symptomsName]; // 忌食
        return foodList
      },
      dietList(){ // 推荐药膳
        const fieldName = this.isTzbsReport? 'tizhi_diet_therapy' : 'diet_therapy';
        const diet = this.solutions[fieldName];
        return diet && diet[this.symptomsName]
      },
      teaList(){ // 茶饮养生
        const fieldName = this.isTzbsReport? 'tizhi_tea' : 'herb_tea';
        const tea = this.solutions[fieldName];
        return tea && tea[this.symptomsName];
      },
      sleepGuide(){ // 起居指导
        const { health_guidance } = this.solutions;
        return health_guidance && health_guidance[this.symptomsName]
      },
      massageList(){ // 穴位按压
        const fieldName = this.isTzbsReport? 'tizhi_massage' : 'massage';
        const massage = this.solutions[fieldName];
        return massage && massage[this.symptomsName]
      },
      musicList(){ // 音乐养生
        const { music } = this.solutions;
        const musicMenu = music && music[this.symptomsName];
        return musicMenu.name.replace(/\..*$/, '')
      },
      classicMedicine(){ // 经典药方
        const { prescription } = this.solutions;
        return prescription && prescription[this.symptomsName];
      },
    }
  }
</script>

<style scoped>

  header .tips {
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0px;
    color: rgb(253, 49, 49);
    text-align: center;
  }

  header .title {
    font-size: 20px;
    font-weight: bold;
    line-height: 26px;
    letter-spacing: 0px;
    color: rgb(51, 51, 51);
    text-align: center;
  }

  header .logo {
    font-size: 20px;
    font-weight: bold;
    line-height: 26px;
    letter-spacing: 0px;
    color: rgb(4, 138, 215);
    text-align: center;
    margin: 13px 0 5px 0;
  }

  header .angle {
    width: 0;
    height: 0;
    border-top: 10px solid #048AD7;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid transparent;
    text-align: center;
    margin: 0 auto;
  }

  .preserveHealth>p,
  .pressTitle{
    margin-top: 40px;
    font-weight: bold;
    color: rgb(51, 51, 51);
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1px;

  }

  .preserveHealth div {
    margin-top: 3px;
    color: rgb(51, 51, 51);
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1px;
  }

  .preserveHealth .good::before,
  .preserveHealth .bad::before {
    content: '';
    width: 18px;
    height: 18px;
    display: inline-block;
    background-size: 100% 100%;
    vertical-align: middle;
  }

  .preserveHealth .good::before {
    background-image: url("../../../assets/img/print/yishi.png");
  }

  .preserveHealth .bad::before {
    background-image: url("../../../assets/img/print/jishi.png");
  }

  .preserveHealth .diet {
    margin-top: 100px;
  }

  .diet .title::before {
    content: '';
    width: 21px;
    height: 21px;
    display: inline-block;
    background-size: 100% 100%;
    vertical-align: middle;
    background-image: url("../../../assets/img/print/sz.png");
  }

  .diet .dietName,
  .tea .teaName,
  .massagePress .massageName{
    color: rgb(0, 160, 233);
    font-size: 16px;
    border-left: 2px solid rgb(0, 160, 233);
    padding-left: 6px;
    margin-top: 16px;
    line-height: 17px;
    height: 17px;
  }

  .preserveHealth .imageBox,
  .imageBoxTzbs{
    background-color: rgb(245, 245, 245);
    padding: 13px 9% 6px;
    display: flex;
    flex-wrap: wrap;
  }

  .preserveHealth .imageUnit {
    flex: 0 0 25%;
  }

  .imageUnit img
  {
    width: 80px;
    height: 66px;
    border: 1px solid rgb(153, 153, 153);
    border-radius: 3px;
    margin: 0 auto;
    display: block;
  }

  .imageUnit p {
    color: rgb(153, 153, 153);
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    margin-bottom: 5px;
  }

  .preserveHealth .dietMenu,
  .preserveHealth .dietCook {
    font-size: 16px;
    color: rgb(51, 51, 51);
    vertical-align: top;
    line-height: 21px;
    margin-top: 10px;
  }

  .dietMenu::before,
  .dietCook::before {
    content: '';
    width: 18px;
    height: 21px;
    display: inline-block;
    background-size: 100% 100%;
    vertical-align: middle;
  }

  .dietMenu::before {
    background-image: url("../../../assets/img/print/rwqd.png");
  }

  .dietCook::before {
    background-image: url("../../../assets/img/print/pr.png");
  }

  .tea .imgBox,
  .massagePress .imgBox{
    background-color: rgb(245, 245, 245);
    padding: 13px 0;
  }

  .tea img,
  .massagePress img,
  .imageBoxTzbs img{
    width: 263px;
    height: auto;
    border-radius: 10px;
    margin: 0 auto;
    display: block;
  }


  .otherPreserve table {
    border-collapse:collapse;
    /*color: rgb(204, 204, 204);*/
    font-size: 16px;
    margin-top: 20px;
    width: 100%;
  }

  table,
  tr,
  td {
    border: 2px solid rgb(204, 204, 204);
    padding: 20px 10px;
  }

  td:nth-child(1) {
    width: 20%;
    text-align: center;
  }

  tr:nth-child(odd){
    background: #F5F5F5;
  }

  .massage p {
    margin-top: 5px;
  }

  .massage img {
    display: block;
    margin: 0 auto;
    width: auto;
    height: 150px;
  }

  .classic {
    margin: 10px 0;
    font-size: 16px;
    color: rgb(51, 51, 51);
  }

  .classic>div {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .classic h3 {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .classic p {
    margin-top: 5px;
    line-height: 24px;
  }

</style>
