// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/report/pulse/mxbt_jkjx_icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.pulseDetail[data-v-729bcc12] {\n  border-radius: 10px;\n  padding: 1rem 0;\n  margin-top: 80px;\n  margin-bottom: 1rem;\n  box-shadow: 0 0 1.1rem 0 rgba(153, 153, 153, 0.17);\n  background-color: white;\n}\n.title[data-v-729bcc12] {\n  width: 79%;\n  height: 110px;\n  margin: 82px auto;\n  text-align: center;\n  font-size: 48px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: 100% 100%;\n  line-height: 110px;\n}\n\n", ""]);
// Exports
module.exports = exports;
