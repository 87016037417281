var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "visceraChart" }, [
    _vm.marker == 10.1
      ? _c("div", { staticClass: "chart-tip" }, [
          _vm._m(0),
          _vm.physical.substring(0, 3) != "平和质"
            ? _c("div", { staticClass: "tip-content" }, [
                _c("div", { staticClass: "line line-two" }),
                _c("span", [_vm._v(_vm._s(_vm.physical))])
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _c(
      "div",
      {
        staticStyle: {
          width: "100%",
          display: "flex",
          "justify-content": "space-between",
          "font-size": "30px"
        }
      },
      [
        _c("p", [_vm._v("当前节气：" + _vm._s(_vm.physicalTerm.term))]),
        _c("p", { staticClass: "illness" }, [
          _vm._v("患病概率： "),
          _c("span", { style: { color: _vm.textColor } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.physicalTerm.illness_probability > 30
                    ? "较高"
                    : _vm.physicalTerm.illness_probability < 10
                    ? "低"
                    : "中"
                ) +
                " "
            )
          ]),
          _vm.physicalTerm.illness_probability > 30
            ? _c("img", {
                attrs: { src: require("../../assets/img/wx/wx_row_up.png") }
              })
            : _vm._e(),
          _vm.physicalTerm.illness_probability < 10
            ? _c("img", {
                attrs: { src: require("../../assets/img/wx/wx_row_down.png") }
              })
            : _vm._e()
        ])
      ]
    ),
    _c("br"),
    _vm.marker == 10.1
      ? _c("div", {
          style: { width: "850px", height: "320px", marginTop: "-40px" },
          attrs: { id: "viscera" }
        })
      : _c("div", {
          style: { width: "850px", height: "430px" },
          attrs: { id: "viscera" }
        }),
    _c("div", { staticClass: "text" }, [
      _vm._v("提示：" + _vm._s(_vm.physicalTerm.term_attention))
    ]),
    _c("div", { staticClass: "text" }, [
      _vm._v(_vm._s(_vm.physicalTerm.term_add_attention))
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tip-content" }, [
      _c("div", { staticClass: "line" }),
      _c("span", [_vm._v("平和质")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }