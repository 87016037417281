<template>
  <div class="wxhealthyscheme">
    <div class="title">健康方案</div>
    <!--  -->
    <div v-for="(item, index) in buttontype" :key="index">
      <div class="header">
        <img src="../../../assets/img/wx/wx_jkfabt_jcjg.png"/>
        <span>{{item}}</span>
        <img src="../../../assets/img/wx/wx_jkfabt1_jcjg.png"/>
      </div>
      <!-- 饮食调养 -->
      <div v-if="item === '饮食调养'" class="zero">
        <div class="goodfood">
          <div class="left">
            <img src='../../../assets/img/wx/wx_ys_jcjg.png'/>
            <span>宜食</span>
          </div>
          <div class="right">
            <span v-for="(e,i) in goodfood" :key="i">{{e.name}} </span>
          </div>
        </div>
        <div class="goodfood badfood">
          <div class="left">
            <img src='../../../assets/img/wx/wx_js_jcjg.png'/>
            <span style="color:#f86426">忌食</span>
          </div>
          <div class="right">
            <span v-for="(e,i) in badfood" :key="i">{{e.name}} </span>
          </div>
        </div>
        <!--  -->
        <van-tabs v-model="active" :ellipsis="false" swipeable title-active-color="#30c3fa" color="#30c3fa" title-inactive-color="#333333">
          <van-tab v-for="(item, index) in dietTherapy" :key="index" :title="item.name">
            <div class="item-title">配料：</div>
            <div class="food-pics">
              <div :class="(index+1)%4 !== 0 ? 'pics pics-4' : 'pics'" v-for="(items ,index) in item.asset" :key="index">
                <img :src="reportData.solutions.asset_prefix+items[1]" alt="加载失败" />
                <span>{{items[0]}}</span>
              </div>
            </div>
            <div class="item-title">清单：</div>
            <span class="item-span">{{item.content[0]}}</span>
            <div class="item-title">烹煮方法：</div>
            <span class="item-span">{{item.content[1]}}</span>
          </van-tab>
        </van-tabs>
      </div>
      <!-- 茶饮养生 -->
      <div v-if="item === '茶饮养生'">
        <img class="commom-img" :src="reportData.solutions.asset_prefix + tea.asset" alt="加载失败">
        <div class="item-title">配料：</div>
        <span class="item-span">{{tea.content && tea.content[0]}}</span>
        <div class="item-title">泡煮方法：</div>
        <span class="item-span">{{tea.content && tea.content[1]}}</span>
      </div>
      <!-- 经典药方 -->
      <div v-if="item === '经典药方'">
        <van-tabs v-model="active2" :ellipsis="false" swipeable title-active-color="#30c3fa" color="#30c3fa" title-inactive-color="#333333">
          <van-tab v-for="(item, index) in jdyf" :key="index" :title="item.name">
            <div class="item-title">药物组成：</div>
            <span class="item-span">{{item.content[0]}}</span>
            <div class="item-title">功效：</div>
            <span class="item-span">{{item.content[1]}}</span>
            <div v-if="csr=== 'HUASHI'&& item.content[2]" class="item-title">推荐用药：</div>
            <span v-if="csr=== 'HUASHI'&& item.content[2]" class="item-span">{{item.content[2]?item.content[2]:''}}{{item.content[3]?'、'+item.content[3]:''}}{{item.content[4]?'、'+item.content[4]:''}}{{item.content[5]?'、'+item.content[5]:''}}</span>
          </van-tab>
        </van-tabs>
      </div>
      <!-- 穴位按压 -->
      <div v-if="item === '穴位按压'">
        <img class="commom-img" :src="reportData.solutions.asset_prefix + acupoint.asset" alt="加载失败">
        <div class="item-title">定位：</div>
        <span class="item-span">{{acupoint.content[0]}}</span>
        <div class="item-title">按摩方法：</div>
        <span class="item-span">{{acupoint.content[1]}}</span>
      </div>
      <!--  '中医功法' -->
      <div v-if="item === '中医功法'">
        <video-player
          v-if="poster"
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
          >
        </video-player>
      </div>
      <!-- 音乐养生 -->
      <div v-if="item === '音乐养生'" class="musics">
        <span class="item-span" style="margin-top: 30px">{{music.title}}</span>
        <img class="img" :src="`${reportData.solutions.asset_prefix}/养生音乐/脾虚/渔舟唱晚.jpg`"/>
        <audio v-if="music" controls class="audiosty" controlsList="nodownload">
        <source :src="music.url" type="audio/mpeg">
                    您的浏览器不支持 音乐播放 标签。
        </audio>
        <!-- <aplayer class="aplayer" theme="#6bbbff" autoplay=false :music="{
          title: music.title,
          author: ' ',
          url: music.url,
          pic: `${reportData.solutions.asset_prefix}/养生音乐/脾虚/渔舟唱晚.jpg`,
          lrc: '[00:00.00]lrc here\n[00:01.00]'
        }">
        </aplayer> -->
      </div>
      <!-- 起居指导 -->
      <div v-if="item === '起居指导'">
        <span class="item-span" v-for="(item,index) in hg.content" :key="index">{{item}}</span>
      </div>
      <!--  保健原则 -->
      <div v-if="item === '保健原则'">
        <div class="item-title">{{principle.content[1]}}：</div>
        <span class="item-span">{{principle.content[0]}}</span>
      </div>
      <!--  居家艾灸 -->
      <div v-if="item === '居家艾灸'">
        <div class="item-title">配合特色中药熏蒸方法，效果更佳</div>
        <div class="item-title">取穴：{{xwname.join('、')}}</div>
        <div class="item-title">方法：将艾炷点燃，粘贴在相应的穴位上，每穴2-3炷。</div>
        <div class="item-title">取穴方法：</div>
        <div v-for="(item, index) in xw" :key="index">
          <div class="item-title">{{item.name}}:</div>
          <span class="item-span">{{item.content[0]}}</span>
          <div class="moxibustion">
            <img :src="reportData.solutions.asset_prefix + item.asset[0]" alt="加载失败" class="commom-img">
          </div>
        </div>
      </div>
       <!--  五行熏蒸 -->
      <div v-if="item === '五行熏蒸'">
        <div class="item-title">【熏蒸法简介】</div>
        <span class="item-span">中药熏蒸疗法源远流长，早在《黄帝内经》中就有记载。在唐代孙思邈所著的《千金方》中已经将熏洗疗法广泛地用到内、外、妇、儿、五官、皮肤等各科的疾病治疗和预防。在元代《御药院方》、清代的《慈禧光绪医方选仪》中有大量运用熏蒸药方治疗各类疾病的记录。熏蒸疗法在宫廷中的运用是受到了高度重视。</span>
        <div class="moxibustion"><img src="../../../assets/img/report/tu1_jkfa_icon.png" style="width: 392px"></div>
        <div class="item-title">【熏蒸的作用】</div>
        <span class="item-span">1. 净血排毒：可改善人体新陈代谢，促进血液循环，帮助 排除体内废物及肝肾毒素，对各种水肿有特效。<br/>
          2. 清毒杀菌：可深入皮下组织，杀菌消毒，清除污垢，帮 助去死皮，使肌肤美白。<br/>
          3. 清除疲劳：可使全身放松，缓解压力，心情愉快，恢复 活力。<br/>
          4. 活化细胞：可使全身细胞活跃，有效改善体质，增强免 疫能力。<br/>
          5. 强化机能：可刺激人体微循环系统，改善人体各种机能。<br/>
          6. 减肥瘦身：可帮助排汗，消除多余热量，燃烧多余脂肪， 使身体苗条，凹凸有形。<br/>
          7. 美容除斑：可调节内分泌，预防妇科病，消除色斑。</span>
        <div class="item-title">【调养原则】</div>
        <span class="item-span">{{fumigation.name}}（配合艾灸，效果更佳，方法见居家艾灸）</span>
        <div class="item-title">【五行对应脏腑】】</div>
        <div class="fumigation-content">
          <div v-for="(item, index) in fumigation.content" :key="index">
            <img v-if="index%2 !== 0" :src="wximg[item]">
            <span v-if="index%2 === 0" class="item-span">{{fumigation.content[index]}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryObject } from '../../../../utils/common.js';
const { csr = '' } = queryObject();
// import 'video.js/dist/video-js.css';
import 'vue-video-player/src/custom-theme.css';
import 'videojs-contrib-hls';
// import Aplayer from 'vue-aplayer'

import jin from '../../../assets/img/report/jin_jkfa_icon.png';
import mu from '../../../assets/img/report/mu_jkfa_icon.png';
import shui from '../../../assets/img/report/shui_jkfa_icon.png';
import huo from '../../../assets/img/report/huo_jkfa_icon.png';
import tu1 from '../../../assets/img/report/tu_jkfa_icon.png';

export default {
  props: {
    reportData: Object
  },
  data(){
    return {
      buttontype: ['饮食调养', '茶饮养生', '经典药方', '穴位按压', '中医功法','音乐养生', '起居指导', '保健原则', '居家艾灸', '五行熏蒸'],
      goodfood: null,
      badfood: null,
      dietTherapy: null,
      active: 0,
      tea: null,
      active2: 0,
      jdyf: null,
      acupoint: null,

      gf:'',
      image:'',
      csvideo:'',
      sourcessrc:'',
      poster:'',
      music:'',

      hg:'',
      principle:'',
      xw:'',
      xwname:[],
      fumigation:'',

      wximg:{
        金:jin,
        木:mu,
        水:shui,
        火:huo,
        土:tu1
      },

      playerOptions:{
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        autoplay: false,
        muted: false,
        loop: false,
        preload: 'auto',
        language: 'zh-CN',
        aspectRatio: '16:9',
        fluid: true,
        sources: [{
          type: "video/mp4",
          src: '',
        }],
        poster: '',
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true
        }
      }
    }
  },
  // components: {
  //   Aplayer
  // },
  computed: {
    csr(){
      return csr
    },
  },
  beforeMount(){
    if (this.reportData.health_score < 90){
      if (csr === 'SHBAIHUI'){
        this.buttontype = ['饮食调养', '茶饮养生', '经典药方', '穴位按压', '中医功法','音乐养生', '起居指导', '保健原则', '居家艾灸', '五行熏蒸']
      }else {
        this.buttontype = ['饮食调养', '茶饮养生', '经典药方', '穴位按压', '中医功法','音乐养生', '起居指导', '保健原则']
      }
    }else if (csr === 'SHBAIHUI'){
        this.buttontype = ['饮食调养', '茶饮养生', '穴位按压', '中医功法','音乐养生', '起居指导', '保健原则', '居家艾灸', '五行熏蒸']
    }else {
        this.buttontype = ['饮食调养', '茶饮养生', '穴位按压', '中医功法','音乐养生', '起居指导', '保健原则']
    }
    const symptomsName = this.reportData.symptoms[0] ? this.reportData.symptoms[0].name : '健康';
    this.goodfood = this.reportData.solutions.good_food[symptomsName];
    this.badfood = this.reportData.solutions.bad_food[symptomsName];
    this.dietTherapy = this.reportData.solutions.diet_therapy && this.reportData.solutions.diet_therapy[symptomsName];
    this.tea = this.reportData.solutions.herb_tea && this.reportData.solutions.herb_tea[symptomsName];
    this.jdyf = this.reportData.solutions.prescription && this.reportData.solutions.prescription[symptomsName];
    this.acupoint = this.reportData.solutions.massage && this.reportData.solutions.massage[symptomsName];
    
    this.gf = this.reportData.solutions.video && this.reportData.solutions.video[symptomsName];
    this.image = this.gf && this.gf.asset && this.gf.asset[1].split('/')[1];
    this.csvideo = this.gf && this.gf.asset && this.gf.asset[0].split('/')[1];
    this.sourcessrc = csr=== 'CHENSHANG' || csr === 'SHWYSLSWKJ' || csr === 'SXSZYYY'? this.reportData.solutions.asset_prefix + '功法无水印'+ this.image:this.reportData.solutions.asset_prefix + this.gf.asset[1];
    this.poster = csr === 'CHENSHANG' || csr === 'SHWYSLSWKJ' || csr === 'SXSZYYY' ? this.reportData.solutions.asset_prefix + '功法无水印' + this.csvideo:this.reportData.solutions.asset_prefix + this.gf.asset[0];
   
    this.playerOptions.sources[0].src = this.poster;
    this.playerOptions.poster = this.sourcessrc;
    this.music = this.getMusic(this.reportData.solutions, symptomsName)

    this.hg = this.reportData.solutions.health_guidance && this.reportData.solutions.health_guidance[symptomsName];
    this.principle = this.reportData.solutions.health_principle && this.reportData.solutions.health_principle[symptomsName];
    this.xw = this.reportData.solutions.moxibustion && this.reportData.solutions.moxibustion[symptomsName];
    this.xw.forEach((e, i) => (
      this.xwname[i] = e.name
    ));
    this.fumigation = this.reportData.solutions.moxibustion_wuxing && this.reportData.solutions.moxibustion_wuxing[symptomsName];
  },
  methods: {
    getMusic( solutions, symptoms ){
      const symptom = symptoms ? symptoms: '健康';
      const music = solutions && solutions.music && solutions.music[symptom];
      if (!music) return null;
      return {
        title: music.name.replace(/\..*$/, ''),
        url: solutions.asset_prefix + music.asset[0],
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.wxhealthyscheme {
  background-color: #fff;
	border-radius: 30px;
  padding: 57px 46px 30px 51px;
  margin-top: 45px;
  .title {
    color: #333;
    font: {
      size: 48px;
      family: PingFangSC-Medium;
    };
    width: 260px;
    height: 50px;
    border-left: 6px solid #1288eb;
    text-align: center;
    margin-bottom: 30px;
  }
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 62%;
    margin: 75px 0 60px 19%;
    img {
      width: 150px;
      height: 29px;
    }
    span {
      font-family: PingFangSC-Regular;
	    font-size: 45px;
      color: #30c3fa;
      width: 260px;
      text-align: center;
    }
  }
  .common-one {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .commom-img {
    display: block;
    border-radius: 30px;
	  border: solid 1px #dcdcdc;
    margin: 20px 0;
  }
  .common-text {
    font-family: SourceHanSansCN-Regular;
    font-size: 39px;
    color: #666666;
    line-height: 62px;
  }
  .zero {
    .goodfood{
      @extend .common-one;
      border-radius: 30px;
      border: solid 1px #dcdcdc;
      padding: 33px 44px 33px 0;
      .left {
        @extend .common-one;
        flex-direction: column;
        justify-content: center;
        width: 20%;
        img {
          width: 45px;
          height: 45px;
        }
        span {
          font-size: 42px;
          color: #35c8e3;
          font-family: SourceHanSansCN-Regular;
          margin-top: 10px;
        }
      }
      .right {
        width: 80%;
        font-family: PingFangSC-Regular;
        font-size: 36px;
        color: #666666;
        line-height: 52px;
      }
    }
    .badfood {
      margin: 30px 0 20px 0;
    }
    .food-pics {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .pics {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      font-family: SourceHanSansCN-Regular;
      line-height: 72px;
      color: #666666;
      img {
        width: 210px;
      }
    }
    .pics-4 {
      margin-right: 17px;
    }
  }
  .item-title {
    font-family: PingFangSC-Regular;
	  font-size: 42px;
    color: #333333;
    margin-top: 42px;
    margin-bottom: 20px;
  }
  .item-span{
    font-family: SourceHanSansCN-Regular;
	  font-size: 39px;
    color: #666666;
    line-height: 52px;
  }
  
  .musics {
    border: solid 1.5px #dcdcdc;
    padding: 0px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .img {
      width: 100%;
      margin-top: 10px;
    }
    .audiosty{
      width: 100%;
      margin-top: 10px;
      zoom: 2;
    }
  }

  .moxibustion {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fumigation-content {
    width: 90%;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}
</style>

<style>
  .wxhealthyscheme .van-tab {
    font-size: 42px;
    color: #333;
    line-height: 88px;
  }

  .wxhealthyscheme .van-tabs--line .van-tabs__wrap {
    height: 88px;
  }

  .wxhealthyscheme .van-tabs__line {
    width: 140px;
  }

  /*  */
  .wxhealthyscheme .aplayer {
    width: 100%;
    height: 100%;
    box-shadow:0 0 0 0 rgba(204, 204, 204, 0.32);
  }
  
  .wxhealthyscheme .aplayer-pic {
    height: 234px;
    width: 306px;
    background-size: 100% 100%;
    overflow: hidden;
  }
  .wxhealthyscheme .aplayer-pic .aplayer-play {
    width: 70px;
    height: 70px;
    bottom: 40%;
    right: 40%;
  }
  .wxhealthyscheme .aplayer-pic .aplayer-play .aplayer-icon-play{
    width: 70px;
    height: 70px;
  }
  .wxhealthyscheme .aplayer .aplayer-body .aplayer-info{
    height: 186px;
    margin-left: 10px;
  }
  .wxhealthyscheme .aplayer .aplayer-body .aplayer-info .aplayer-music .aplayer-title {
    font-size: 42px;
  }
  .wxhealthyscheme .aplayer-bar-wrap .aplayer-bar {
    height: 12px;
    border-radius: 6px;
    background-color: #dcdcdc;
  }

  .wxhealthyscheme .aplayer-bar-wrap .aplayer-bar .aplayer-played {
    height: 12px;
    border-radius: 6px;
  }
  .wxhealthyscheme .aplayer-bar-wrap .aplayer-bar .aplayer-loaded {
    height: 12px;
    border-radius: 6px;
  }
  .wxhealthyscheme .aplayer-bar-wrap .aplayer-bar .aplayer-played .aplayer-thumb {
    width: 20px;
    height: 20px;
  }
  .wxhealthyscheme .aplayer-controller .aplayer-time {
    font-size: 20px;
  }
  .wxhealthyscheme .aplayer-controller .aplayer-time .aplayer-icon {
    height: 0;
  }
  .wxhealthyscheme .aplayer-controller .aplayer-time .aplayer-icon.inactive {
    height: 0;
  }

</style>