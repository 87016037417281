// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/report/bj1.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/report/jssm_icon.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/img/report/jssmbj_icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "\n.knowledgebase[data-v-3c2c8064]{\n  width:100%;\n  height:100%;\n  padding: 0 0 0 52px;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size:cover;\n  overflow:hidden;\n}\n[data-v-3c2c8064]::-webkit-scrollbar{\n  display: none;\n}\n.back[data-v-3c2c8064]{\n  position:relative;\n  left:49px;\n  top:105px;\n  width: 152px;\n  height: 82px;\n}\n.title[data-v-3c2c8064]{\n  font-size: 48px;\n  color:#00FEFB;\n  font-family:TITLE;\n  width:400px;\n  height:111px;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;\n  text-align:center;\n  line-height:111px;\n  background-size:100% 100%;\n  position: absolute;\n  left: 0;\n  top: 83px;\n  right: 0;\n  margin: 0 auto;\n}\n.knowledgebase-content[data-v-3c2c8064]{\n  position:relative;\n  width:896px;\n  max-height:1400px;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat;\n  background-size:100% 100%;\n  margin-top:50px;\n  padding:60px 10px 60px 69px;\n  color:#ffffff;\n  font-size:30px;\n  line-height:60px;\n  overflow: auto;\n}\n\n", ""]);
// Exports
module.exports = exports;
