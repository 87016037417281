<template>
  <div v-if="reportData" :class="{chronicReport: isChronic}">
    <div :class="marker == 10.1 ? 'content-header content-header-ten' : 'content-header'">
      <div class="content-header-content">
        <span class="title-one">检测结果</span>
        <p class="title-two">基于上海中医药大学研究专利开发<span v-if="marker !== '10.1'" @click="checkBooks">?</span></p>
      </div>
      <span class="content-header-border"></span>
      <div v-if="reportData" class="content-header-info">
        <span class="info-txt">检测时间：{{reportData.time}}</span>
        <span class="info-txt info-count" v-if="marker !== '10.1'">(第{{reportData.detect_count}}次)</span>
        <span class="info-txt info-number">报告编号：{{reportData.display_id}}</span>
      </div>
    </div>
    <div v-if="reportData" class="userInfo-content">
      <span class="info-left">性别:&nbsp;&nbsp;{{reportData.user_info.gender === 1 ? '男' : '女'}}</span>
      <span v-if="isChronic">年龄:&nbsp;&nbsp;{{age}}岁</span>
      <span v-else>BMI指数:&nbsp;&nbsp;{{bmi}}</span>
      <span v-if="reportData.owner.cellphone" class="info-right">手机尾号:{{reportData.owner.cellphone.substr(-4)}}</span>
    </div>
  </div>
</template>

<script>
import {calculateBMI} from '../../../utils/common';
import { queryObject } from '../../../utils/common.js';
const { marker = '' } = queryObject();

export default {
  name: "reportheader",
  props: {
    reportData: Object
  },
  computed: {
    bmi(){
      const {bmi, text} = calculateBMI(this.reportData.user_info.height, this.reportData.user_info.weight);
      if(bmi) return `${bmi}(${text})`;
      else return `0(${text})`
    },
    marker(){
      return marker
    },
    isChronic(){
      // 是否是慢病的报告
      return this.reportData.type_attention === '高血压' || this.reportData.type_attention === '糖尿病'
    },
    age(){
      // 慢病报告对应的年龄
      return this.reportData.user_info && this.reportData.user_info.ages
    }
  },
  data(){
    return {

    }
  },
  methods: {
    checkBooks(){ // 查看知识库依据
      let type = '';  // type代表报告的类型，从而获取不同的知识库依据
      switch (this.reportData.type_attention) {
        case '高血压':
          type = 'hypertension';
          break;
        case '糖尿病':
          type = 'glycuresis';
          break;
        default:
          type = 'physical'
      }

      this.$router.push({
        name:'Knowledgebase',
        params: {
          type
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$contentWidth: 94%;
$leftContent: 3%;
$borderImg: '../../assets/img/report/xian_jcjg.png';
$infoImg: '../../assets/img/physical/k1_jg_icon.png';

/*慢病管理特有的样式*/
.chronicReport {

  .content-header-info {
    width: 94%;
    margin: 20px auto 0 auto;
    .info-txt{
      font-size: 28px;
    }
    .info-number {
      margin-left: 100px;
    }
  }

  .userInfo-content{
    font-size: 28px;
    background: none;

    .info-left {
      margin-left: 0;
    }

    .info-right {
      width: 295px;
      margin-right: 0;
    }
  }


}


.content-header {
  margin-top: 67px;
}

.content-header-ten {
  margin-top: 47px;
}

.content-header-content {
  display: block;
  width: $contentWidth;
  margin-left: $leftContent;
  .title-one {
    font-family: TITLE;
    font-size: 60px;
    color: #00fefb;
  }

  .title-two {
    font-family: MicrosoftYaHei;
    font-size: 30px;
    color: #72c0d6;
    margin-left: 20px;
    display: inline-block;

    span {
      display: inline-block;
      font-size: 25px;
      width: 27px;
      height: 27px;
      border: 1px solid #72c0d6;
      border-radius: 50%;
      text-align: center;
      position: relative;
      top: -20px;
      left: 20px;
    }
  }
}

.content-header-border {
  display:block;
  width: 83.6%;
  height:16px;
  background:url($borderImg) no-repeat;
}

.content-header-info {
  width: 90%;
  margin-left: $leftContent;
  display: flex;
  /*justify-content: space-between;*/
  margin-bottom: 10px;
  .info-txt {
    font-size: 28px;
    color: #ffffff;
    line-height: 40px;
  }
  .info-count {
    margin-left: 10px;
  }

  .info-number {
    margin-left: 40px;
  }


}

.userInfo-content {
  height:81px;
  width: $contentWidth;
  margin-left: $leftContent;
  background:url($infoImg) no-repeat;
  background-size:100% 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
	font-size: 36px;
	color: #ffffff;
  .info-left {
    margin-left: 26px;
  }
  .info-right {
    margin-right: 26px;
  }
}

</style>
