<template>
  <div v-if="reportData" class="reporttzbs">
    <!-- 关闭按钮 -->
    <div v-if="type !== 'personal'" class="close-btn" @click="doclose"></div>
    <!-- 顶部个人信息 -->
    <reportheader :reportData="reportData"></reportheader>
    <!-- 查看解析简易版 -->
    <reportparsing :reportData="reportData"></reportparsing>
    <!-- 简易版 面舌诊 扫码带走报告 -->
    <reporthealthtip :reportData="reportData"></reporthealthtip>
    <div class="tip">此报告仅作参考，如有不适请及时就医</div>
    <!-- 养生方案 -->
    <planbtn :reportData="reportData"></planbtn>
    <!--  底部 -->
    <div v-if="csr !== 'SHBAZE' && homeFootShow != null" :class="marker == 10.1 ? 'bottom bottom-ten' : 'bottom'">
      <img v-if="csr !== 'CHENSHANG'" :class="homeFootShow == 0 || homeFootShow == 2 ? '' : 'tipImg'" :src="csr === 'BJTRTKH' ? trt : csr === 'SHWYSLSWKJ' ? wysl : (homeFootShow == 0 || homeFootShow == 2 ? '' : logo )"/>
      <span class="tipTxt">{{csr === 'BJTRTKH' ? '中国北京同仁堂（集团）有限责任公司' : (csr === 'CHENSHANG' ? '上海辰尚信息科技有限公司' : (csr === 'SHWYSLSWKJ' ? '上海伍月石榴生物科技有限公司' :
        (homeFootShow == 0 ? '' : homeFootShow == 1 ? '上海祉云医疗科技有限公司' : homeFootShow == 2 ? logoName : `上海祉云医疗科技有限公司     |     ${logoName}` ) ))}}</span>
    </div>
  </div>
  <div v-else class="nonetzbs"></div>
</template>

<script>
import { queryObject } from '../../../utils/common.js';
import { getReport } from '@/api/report.js';
import reportheader from '@/components/physical/reportheader.vue';
import planbtn from '@/components/physical/planbtn.vue';
import reporthealthtip from '@/components/physical/reporthealthtip.vue';
import reportparsing from '../../components/physical/reportparsing.vue';

import logo from '../../assets/img/common/company-logo-color.png';
import trt from '../../assets/img/common/trt_sy_icon.png';
import wysl from '../../assets/img/common/wysllogo.png';

const { type = '', csr = '',  marker = '', is_print = '' } = queryObject();

export default {
  name: "index",
  components: {
    reportheader, reportparsing, reporthealthtip, planbtn
  },
  data(){
    return {
      reportData: null, 
      tz_definition: '',
      logo: logo,
      trt: trt,
      wysl: wysl,
      logoName: null,
      homeFootShow: null, // 0什么都不显示 1 显示祉云logo  2 显示其他公司logo  3 祉云和其他公司logo共存
    }
  },
  computed: {
    csr(){
      return csr
    },
    marker(){
      return marker
    },
    type() {
      return type
    }
  },
  created() {
    this.getData();
  },
  watch:{
  //   '$route': 'getData' // 路由一旦切换，就去重新请求一下对应报告的数据
  },
  methods:{
    getData(){
      getReport({
        id: this.$route.params.id
      }).then(res => {
        if(res && res.status_code === 10000 && res.data) {
          // console.log(res.data)
          this.reportData = res.data;
          this.homeFootShow = res.data.vendor_setting.home_foot_show;
          this.logoName = res.data.vendor_setting.logo;
          this.$store.commit('SET_REPORT', res.data) // 将其更新到store里面对应的report字段
          // 您的体质结果
          Object.keys(res.data.solutions.tizhi_definition).forEach((element, index) => {
            if (index === 0) this.tz_definition += element
            else this.tz_definition += '、' + element
          });
        }
      })
    },
    gotoParsingTzbs(){
      this.$router.push({name:'Parsingtzbs'})
    },
    doclose() {
      if (window.zhiyun) {
        window.zhiyun.closereport();
      }
    },
  },
  beforeRouteEnter(to, from, next){ // 组件内导航守卫
    // 路由进来之前，判断是普通报告/打印版本
    const is_print_page =  is_print === '1';
    if (is_print_page) {
      const id = to.path.split('/reporttzbs/')[1]
      sessionStorage.setItem('routerParams','isTzbsReport');
      next({ // 将路由重定向到打印报告页面
        name: 'Print',
        params: { id, category: '2'}
      }); // 将路由重定向到打印报告页面
    } else {
      next();
    }
  },  
}

</script>

<style lang="scss" scoped>
$contentWidth: 94%;
$leftContent: 3%;
$bgImg: '../../assets/img/common/bj.png';
$clostimg: '../../assets/img/report/gb_jcjg_icon.png';

.reporttzbs {
  width:100%;
  min-height: 100%;
  background:url($bgImg);
  background-size:cover;
  overflow: hidden;
  background-color: #00090a;
  .close-btn {
    position: absolute;
    right: 30px;
    top: 30px;
    width: 120px;
    height: 77px;
    background:url($clostimg);
  }

.tip {
  width: $contentWidth;
  margin-left: $leftContent;
  font-size: 24px;
  line-height: 48px;
  color: #678e9f;
  text-align: right;
  margin-bottom: 4px;
}

.bottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100px;
  .tipImg {
    width: 50px;
    height: 50px;
  }
  .tipTxt {
    height: 42px;
    font-size: 30px;
    font-weight: bold;
    color: #ffffff;
    margin-left: 20px;
  }
}
.bottom-ten {
  height: 60px;
}
}

.nonetzbs {
  width:100%;
  min-height: 100%;
  background-color: #00090a;
}
</style>