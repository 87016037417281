// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/wx/wx_bj_tzjg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n.wxtzheader[data-v-5b1042ed] {\n  width: 100%;\n  min-height: 540px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  overflow: hidden;\n  display: flex;\n  flex-direction: row;\n}\n.wxtzheader .left[data-v-5b1042ed] {\n    color: #fff;\n    margin: 30px 0 0 45px;\n    width: 56%;\n    display: flex;\n    flex-direction: column;\n    position: relative;\n}\n.wxtzheader .left h2[data-v-5b1042ed] {\n      font-size: 72px;\n      font-family: PingFangSC-Medium;\n      line-height: 120px;\n}\n.wxtzheader .left span[data-v-5b1042ed] {\n      display: block;\n      font-size: 36px;\n      font-family: SourceHanSansCN-Regular;\n      line-height: 54px;\n}\n.wxtzheader .left .bottom[data-v-5b1042ed] {\n      position: absolute;\n      bottom: 76px;\n}\n.wxtzheader .right[data-v-5b1042ed] {\n    width: 40%;\n    display: flex;\n    align-items: center;\n    margin-left: 80px;\n    margin-top: 20px;\n}\n.wxtzheader .right .circle-score[data-v-5b1042ed] {\n      height: 80%;\n      color: #fff;\n}\n.wxtzheader .right .circle-score .score[data-v-5b1042ed] {\n        margin-top: 76px;\n        font-size: 93px;\n}\n.wxtzheader .right .circle-score .score[data-v-5b1042ed]::after {\n        content: '分';\n        font-size: 36px;\n}\n.wxtzheader .right .circle-score .result[data-v-5b1042ed] {\n        font-size: 36px;\n}\n", ""]);
// Exports
module.exports = exports;
