// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/report/jkfa_jcjg_icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.content[data-v-6a27e718]{\n  width: 422px;\n  height: 140px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 100% 100%;\n  margin: 20px 0 0 19px;\n}\n.content1[data-v-6a27e718]{\n  width:562px;\n  height:149px;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size:100% 100%;\n  margin:8px 0 0 200px;\n}\n\n", ""]);
// Exports
module.exports = exports;
