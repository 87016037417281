<template>
  <div class="agreement">
    <header>
      <span>知情同意书</span>
    </header>
    <p>感谢您受邀参加健康体验活动，下列各项记述了本次体验目的、方法、可能产生的风险或者不便等，请您在参加体验前务必仔细阅读。
      本知情同意书提供给您的信息可以帮助您决定是否参加此项体验，如有任何疑问请向负责该项体验的组织者提问，以确保您充分理解有关的内容。
    </p>
    <p>本智能镜是由上海祉云医疗科技有限公司基于上海中医药大学专利技术和专家团队研发成果所开发的定制平台，受客观技术条件和体验数据采集
      环境影响，报告结果可能不完全准确。本次健康体验活动主要是通过采集健康数据并结合您陈述的健康问题对您的健康状态进行分析评估并提出
      管理建议。本次体验不是医疗诊断行为，不对评估结果进行保证或承担任何责任。如您有任何疾病或感到不适，请您及时前往医院就诊治疗！
    </p>
    <p>体验者声明：我已经仔细阅读了本知情同意书及免责声明内容，我有机会提问而且所有问题均已得到解答。我理解参加本项体验是自愿的，
      我可以选择不参加本项试验，或者在任何时候通知组织者后退出。我自愿同意参加该项体验，我将收到并正确理解使用健康体验报告。我同意，
      上海祉云医疗科技有限公司及其关联公司将收集、使用、存储、管理和保护您的数据。
    </p>
    <footer>
      <div v-if="url" class="footer_box">
        <span>我已同意以上内容，并在下方签名：</span>
        <div>
          <img :src="url" alt="签名图片" />
        </div>
      </div>
      <div v-else>
        <span>我已同意以上内容，并已签名。</span>
        <div :style="{height: '120px'}"></div>
      </div>
    </footer>
  </div>
</template>

<script>
  export default {
    name: "agreement",
    props: {
      url: String
    }
  }
</script>

<style scoped>
  .agreement {
    margin-top: 50px;
  }

  header {
    text-align: center;
    margin-bottom: 16px;
  }

  header>span {
    display: inline-block;
    padding: 10px 100px;
    font-size: 20px;
    background-color: rgb(0, 162, 255);
    border-radius: 20px;
    color: #ffffff;
  }

  p {
    text-indent: 2em;
    line-height: 25px;
    margin: 0;
    font-size: 16px;
    letter-spacing: 3px;
  }

  footer {
    padding-bottom: 53px;
  }

  footer span {
    color: rgb(0, 162, 255);
    padding-top: 47px;
    display: inline-block;
    float: left;
  }

  .footer_box {
    overflow: auto;
  }

  footer img {
    height: 76px;
    width: 250px;
    margin: 20px auto 0 auto;
    /*display: block;*/
    /*padding: 20px 60px;*/
    border: 1px dashed #333333;
    float: right;
  }

</style>