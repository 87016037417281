var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.marker === "10.1"
    ? _c("div", [
        _c(
          "div",
          {
            staticClass: "pulseresult",
            style:
              _vm.printstatus === "1"
                ? { marginLeft: "130px" }
                : { marginLeft: "32px" },
            on: { click: _vm.gotopulse }
          },
          [
            _c("img", {
              staticStyle: { width: "146px", height: "150px" },
              attrs: { src: _vm.mzjg, alt: "脉诊结果" }
            })
          ]
        )
      ])
    : _c("div", [
        _c(
          "div",
          {
            staticClass: "pulseresult",
            style:
              _vm.printstatus === "1"
                ? { marginLeft: "130px" }
                : { marginLeft: "32px" },
            on: { click: _vm.gotopulse }
          },
          [
            _c("img", {
              staticStyle: { width: "146px", height: "150px" },
              attrs: { src: _vm.mzjg, alt: "脉诊结果" }
            })
          ]
        )
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }