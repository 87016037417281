<template>
  <div class="container" v-if="symptom_status">
    <div class="content">
      <div class="bc"></div>
      <div v-if="before_symptom_status&&!lastreport" class="sc"></div>
<!--      <div v-if="before_symptom_status" class="change" @click="changeChart">{{lastreport?'本次报告':'上次报告'}}</div>-->
      <div class="explain" @click="showExplain"></div>
      <div id="main"></div>
    </div>
    <div v-if="flag">
      <div class="frame">
        <div class="spantitle">
          <span style="font-size: 42px">雷达图说明</span>
          <div style="text-align: left;width: 732px;font-size: 32px;line-height: 57px;padding-top: 43px;margin: 0 auto;">
            雷达图以中间实心多边形的顶点为0点，分值区间为0-100，由里往外分值逐渐增加。<br/>
            越接近外圈，分值越高，对应状态的表现越明显。<br/>
            若多个状态均在中间实心多边形对应的顶点（即0点）上，则为较健康的状态。
          </div>
          <div class="fh" @click="showExplain"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as echarts from 'echarts'
  export default {
    name: "menopausechart",
    props:{
      symptom_status:Array,
      before_symptom_status:Array,
      symptoms:Array,
      type_attention:String
    },
    data(){
      return{
        symptomsname:null,
        thisscore:[],
        beforescore:[],
        titleText:[],
        flag: false,
        lastreport:false,
      }
    },
    mounted() {
      this.drawChart(this.symptom_status,this.before_symptom_status)
    },
    methods:{
      drawChart(symptom_status ,before_symptom_status){
        const _this = this;
        if (this.type_attention === '更年期'){
          this.titleText =[
            { name: '肾虚肝郁型', max: 240 },
            { name: '心肾不交型', max: 240 },
            { name: '阴虚火旺型', max: 240 },
            { name: '肾阴虚型', max: 240 },
            { name: '肾阳虚型', max: 240 },
            { name: '肾阴阳俱虚型', max: 240 },
          ]
        }else {
          this.titleText = [
            { name: '肾虚肝郁', max: 350 },
            { name: '肾虚血瘀', max: 350 },
            { name: '肝肾阴虚', max: 350 },
            { name: '痰湿凝滞', max: 350 },
            { name: '脾肾两虚', max: 350 },
            { name: '气滞血瘀', max: 350 },
            { name: '气血两虚', max: 350 },
          ]
        }
        symptom_status.forEach((e, i) => {
          e.score<0?e.score = 0:e.score
          this.thisscore[i] = e.score;
        });
        if (before_symptom_status){
          before_symptom_status.forEach((e, i) => {
            e.score<0?e.score = 0:e.score
            this.beforescore[i] = e.score;
          });
        }else {
          this.beforescore = [0, 0, 0, 0, 0, 0, 0, 0];
        }
        if (this.symptoms.length>0){
          this.symptomsname = this.symptoms[0].name
        }
        const myChart = echarts.init(document.getElementById('main'));
        myChart.setOption({
          legend: {
            data: ['本次', '上次'],
            show: false
          },
          radar: {
            startAngle: 90,
            splitNumber: 5,
            name: {
              formatter(value) {
                return `{a|${value}}`;
              },
              rich: {
                a: {
                  color: '#ffffff',
                  fontSize: '24',
                  lineHeight: 24,
                },
              },
            },
            axisLine: {
              symbol: ['none', 'none'],
              symbolSize: [5, 10],
              symbolOffset: [40, 40],
              lineStyle: {
                color: '#005484',
              }
            },
            splitLine: {
              lineStyle: {
                color: '#005484'
              }
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: ['rgba(0,162,255,0.2)', 'rgba(12,153,116,0)', 'rgba(12,153,116,0)', 'rgba(12,153,116,0)', 'rgba(12,153,116,0)'],
              }
            },
            indicator: this.titleText
          },
          series: [{
            type: 'radar',
            // areaStyle: {normal: {}},
            data: [
              {
                value: this.beforescore,
                name: '上次',
                itemStyle: {
                  color: '#00dbaf',
                  borderWidth: 2,
                  opacity: 1
                },
                lineStyle: {
                  color: '#00dbaf',
                  width: 3,
                  opacity: 1,
                  type:'dotted'
                },
              },
              {
                value: this.thisscore,
                name: '本次',
                itemStyle: {
                  color: '#ff7800',
                  borderWidth: 2,
                  opacity: 1
                },
                lineStyle: {
                  color: '#ff7800',
                  width: 2,
                  opacity: 1,
                  type:'solid'
                },

              },
            ]
          }]
        });
      },
      showExplain(){
        this.flag=!this.flag
      },
      // changeChart(){
      //   this.lastreport=!this.lastreport
      //   if (this.lastreport){
      //     this.drawChart(this.before_symptom_status ,[])
      //   }else {
      //     this.drawChart(this.symptom_status,this.before_symptom_status)
      //   }
      // }
    }
  }
</script>

<style scoped>
  .container{
    width: 100%;
    padding:0 40px;
  }
  .content{
    position: relative;
    width:1000px;
    height:408px;
    background:url("../../../assets/img/menopauseovary/ldtbj.png");
    background-size:100% 100%;
  }
  #main{
    width: 600px;
    height: 408px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .bc{
    position:absolute;
    width:120px;
    height:23px;
    background:url("../../../assets/img/menopauseovary/bc_icon.png");
    background-size:100% 100%;
    top:50px;
    left:43px;
  }
  .sc{
    position:absolute;
    width:120px;
    height:23px;
    background:url("../../../assets/img/menopauseovary/sc_icon.png");
    background-size:100% 100%;
    top:90px;
    left:43px;
  }
  .explain{
    position:absolute;
    width:42px;
    height:42px;
    background:url("../../../assets/img/menopauseovary/wh_icon.png");
    background-size:100% 100%;
    top:23px;
    right:76px;
  }
  .frame{
    width:1080px;
    height:100%;
    position:absolute;
    top:0px;
    left:0px;
    z-index:99;
    background:rgba(0,0,0,0.6);
  }
  .spantitle{
    font-size: 42px;
    color:#ffffff;
    font-family:PingFangSC-Medium;
    width:868px;
    height:626px;
    background:url("../../../assets/img/menopauseovary/smk_icon.png") no-repeat;
    text-align:center;
    line-height:42px;
    background-size:100% 100%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    margin-top:615px;
    padding-top:80px;
  }
  .fh{
    height:67px;
    width: 224px;
    background:url("../../../assets/img/menopauseovary/fh_srsj_Icon.png") no-repeat;
    margin: auto;
    left:0;
    right:0;
    margin-top:28px;
  }
  .change{
    font-size: 30px;
    text-align: center;
    line-height: 67px;
    color: #ffffff;
    float: right;
    padding-bottom: 10px;
    width: 200px;
    height: 50px;
    background: url("../../../assets/img/\menopauseovary/scbg_icon.png");
    background-size: 100% 100%;
    position: relative;
    bottom: 70px;
  }
</style>
