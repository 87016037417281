// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/report/bj_sznr_icon.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/report/ysfabj_jcjg_icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n.main[data-v-a6f3745e]{\n  height:100%;\n  width:100%;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size:cover;\n  overflow: hidden;\n}\n.result-report[data-v-a6f3745e]{\n  width: 100%;\n  /*margin: 0 auto;*/\n  padding: 0 40px;\n  margin-top: 10px;\n  height: 150px;\n  /*border: 3px solid red;*/\n}\n.report-bottom[data-v-a6f3745e]{\n  width: 100%;\n  /*margin: 0 auto;*/\n  padding: 0 40px;\n  margin-top: 3px;\n  height: 248px;\n}\n.health[data-v-a6f3745e]{\n  height: 207px;\n  width: 1000px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;\n  background-size: 100% 100%;\n  padding-top: 40px;\n}\n\n", ""]);
// Exports
module.exports = exports;
