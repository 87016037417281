var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "knowledge" }, [
      _c("h3", [_vm._v("知识库依据")]),
      _c("p", [
        _vm._v("[1]李灿东,吴承玉.中医诊断学 [M].北京:中国中医药出版社,2018.")
      ]),
      _c("p", [_vm._v("[2]万学红,卢雪峰.诊断学[M].北京:人民卫生出版社,2018.")]),
      _c("p", [_vm._v("[3]周仲瑛.中医内科学[M].北京：中国中医药出版社,2017.")]),
      _c("p", [
        _vm._v("[4]葛均波,徐永健,王辰.内科学[M].北京:人民卫生出版社,2018.")
      ]),
      _c("p", [
        _vm._v("[5]谢梦洲,朱天民.中医药膳学[M].北京:中国中医药出版社,2016.")
      ]),
      _c("p", [
        _vm._v("[6]李其中.中医体质养生指南[M].上海:复旦大学出版社,2013.")
      ]),
      _c("p", [
        _vm._v(
          "[7]匡调元. 辨质论治通识读本——中国式个性化诊疗[M].北京:中国中医药出版社,2016."
        )
      ]),
      _c("footer", [
        _vm._v("图像数据库、算法研究来源于国家级、市级等10项课题资助")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }