<template>
  <div v-if="marker === '10.1'">
    <div class="pulseresult" :style="printstatus === '1'?{'marginLeft':'130px'}:{'marginLeft':'32px'}" @click="gotopulse">
      <img :src="mzjg" style="width: 146px;height: 150px" alt="脉诊结果">
    </div>
  </div>
  <div v-else>
    <div class="pulseresult" :style="printstatus === '1'?{'marginLeft':'130px'}:{'marginLeft':'32px'}" @click="gotopulse">
      <img :src="mzjg" style="width: 146px;height: 150px" alt="脉诊结果">
    </div>
  </div>

</template>

<script>
  import {queryObject} from "../../../../utils/common";
  import mzjg from "../../../assets/img/report/mzjg.png"
  const { printstatus = '', marker = '' } = queryObject();
  export default {
    name: "pulseresult",
    props: {
      url: String,
      isSelfSkip:Number,
    },
    data(){
      return{
        mzjg:mzjg
      }
    },
    methods:{
      gotopulse(){
        // 老脉诊报告，就走原先的太医页面，新脉诊就项目内路由自行跳转,判断标准就是有无this.url
        if(this.url) {
          // 太医脉诊页面
          if(this.isSelfSkip === 0 && window.zhiyun){
            window.zhiyun.gotopulse(this.url);
          } else if(this.isSelfSkip === 1) {
            window.location = this.url;
          }
        } else {
          // 祉云脉诊页面
          const { report } = this.$store.state;
          this.$router.push({
            name: 'Pulse',
            params: {
              id: report && report.pulse[0] && report.pulse[0].mai_id
            }
          })
        }


      }
    },
    computed:{
      printstatus(){
        return printstatus
      },
      marker(){
        return marker
      }
    }
  }
</script>

<style scoped>
  .pulseresult{
    width: 146px;
    height: 150px;
    float: left;
  }

</style>
