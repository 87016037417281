// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/menopauseovary/xxbj_jcjg_icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.container[data-v-60b48704]{\n  margin-top:5px;\n  padding: 0px 40px;\n  height:81px;\n}\n.content[data-v-60b48704]{\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n}\n.item[data-v-60b48704]{\n  font-size: 30px;\n  color: #ffffff;\n  font-family:SourceHanSansCN-Regular;\n  line-height: 81px;\n  padding-left: 10px;\n  padding-right: 10px;\n}\n", ""]);
// Exports
module.exports = exports;
