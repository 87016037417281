var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "illness",
      style: _vm.marker === "10.1" ? { height: "27%" } : { height: "29%" }
    },
    [
      _c("div", { staticStyle: { position: "relative", height: "100%" } }, [
        _c(
          "p",
          {
            staticClass: "illness-txt",
            style: {
              color: _vm.health_status_text === "健康" ? "#00a10b" : "#fe822b"
            }
          },
          [
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.health_status_text === "健康",
                  expression: "health_status_text === '健康'"
                }
              ],
              staticStyle: { height: "36px", "vertical-align": "middle" },
              attrs: {
                src: require("../../../assets/img/common/wjkfx_icon.png")
              }
            }),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.health_status_text !== "健康",
                  expression: "health_status_text !== '健康'"
                }
              ],
              staticStyle: { height: "36px", "vertical-align": "middle" },
              attrs: {
                src: require("../../../assets/img/common/jbfx_jcbg_icon.png")
              }
            }),
            _vm._v(
              " " +
                _vm._s(
                  _vm.health_status_text === "健康"
                    ? "无健康风险"
                    : "健康风险提示"
                ) +
                " "
            )
          ]
        ),
        _c("img", {
          staticClass: "illness-pic",
          style: _vm.marker === "10.1" ? { height: "95%" } : { height: "100%" },
          attrs: { src: _vm.picname }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }