import { render, staticRenderFns } from "./knowledg.vue?vue&type=template&id=4ba52d5f&scoped=true&"
import script from "./knowledg.vue?vue&type=script&lang=js&"
export * from "./knowledg.vue?vue&type=script&lang=js&"
import style0 from "./knowledg.vue?vue&type=style&index=0&id=4ba52d5f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ba52d5f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\work\\zy_frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4ba52d5f')) {
      api.createRecord('4ba52d5f', component.options)
    } else {
      api.reload('4ba52d5f', component.options)
    }
    module.hot.accept("./knowledg.vue?vue&type=template&id=4ba52d5f&scoped=true&", function () {
      api.rerender('4ba52d5f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/report/components/knowledg.vue"
export default component.exports