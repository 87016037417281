// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/report/mzjgbj_jcjg_icon.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/img/report/mzjgbj10.1_jcjg_icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n.faceresult[data-v-8a86e05c]{\n  width: 262px;\n  height: 150px;\n  float: left;\n}\n.faceresult-pulse[data-v-8a86e05c]{\n  width: 214px;\n  height: 150px;\n  float: left;\n}\n.face-right[data-v-8a86e05c]{\n  height: 127px;\n  width: 147px;\n  color: #ffffff;\n  font-size: 24px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  display: inline-block;\n  text-align: center;\n  vertical-align: top;\n  padding: 12px 0;\n  background-size: 100% 100%;\n  line-height: 35px;\n}\n.faced-right[data-v-8a86e05c]{\n  height: 108px;\n  width: 172px;\n  color: #ffffff;\n  font-size: 24px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  display: inline-block;\n  text-align: center;\n  vertical-align: top;\n  padding: 21px 0;\n  background-size: 100% 100%;\n  line-height: 35px;\n}\n.face-right-pulse[data-v-8a86e05c]{\n  height: 108px;\n  width: 102px;\n  color: #ffffff;\n  font-size: 22px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  display: inline-block;\n  text-align: center;\n  vertical-align:top;\n  padding: 21px 0;\n  background-size: 100% 100%;\n  line-height: 35px;\n}\n.faced-right-pulse[data-v-8a86e05c]{\n  height: 127px;\n  width: 129px;\n  color: #ffffff;\n  font-size: 22px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  display: inline-block;\n  text-align: center;\n  vertical-align: top;\n  padding: 12px 0;\n  background-size: 100% 100%;\n  line-height: 35px;\n}\n\n", ""]);
// Exports
module.exports = exports;
