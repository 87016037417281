var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.reportData
    ? _c(
        "div",
        { staticClass: "wxhealthy" },
        [
          _c("wxhealthyheader", {
            attrs: {
              score: _vm.reportData.health_score,
              displayId: _vm.reportData.display_id,
              time: _vm.reportData.time
            }
          }),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("wxinfo", {
                attrs: {
                  info: _vm.reportData.user_info,
                  owner: _vm.reportData.owner
                }
              }),
              _c("wxexplan", {
                attrs: {
                  symptoms: _vm.reportData.symptoms,
                  symptomDefinition:
                    _vm.reportData.solutions.symptom_definition,
                  healthRisk: _vm.reportData.solutions.health_risk
                }
              }),
              _c("wxhealthytip", {
                attrs: {
                  risk: _vm.reportData.solutions.health_risk,
                  gender: _vm.reportData.owner.gender,
                  symptoms: _vm.reportData.symptoms,
                  result: _vm.reportData.health_status_text
                }
              }),
              _c("wxfaceresult", {
                attrs: {
                  faceData: _vm.reportData.face_result,
                  facePhotoUrl: _vm.reportData.face_photo_url
                }
              }),
              _c("wxtongueresult", {
                attrs: {
                  tongueData: _vm.reportData.tongue_result,
                  tonguePhotoUrl: _vm.reportData.tongue_photo_url
                }
              }),
              _vm.reportData.pulse.length !== 0
                ? _c("wxpulseresult", {
                    attrs: { pulse: _vm.reportData.pulse }
                  })
                : _vm._e(),
              _vm.reportData.pulse.length !== 0 &&
              _vm.reportData.pulse[0].blood_dia !== 0 &&
              _vm.reportData.pulse[0].blood_sys !== 0
                ? _c("wxbloodpressure", {
                    attrs: { pulse: _vm.reportData.pulse }
                  })
                : _vm._e(),
              _c("wxhealthyscheme", { attrs: { reportData: _vm.reportData } }),
              _c("div", { staticClass: "tip" }, [
                _vm._v("此报告仅作参考，如有不适请及时就医")
              ]),
              _vm.reportData.doctor.length !== 0
                ? _c("wxdoctorlist", {
                    attrs: { doctorData: _vm.reportData.doctor }
                  })
                : _vm._e(),
              _vm.reportData.product.length !== 0
                ? _c("wxproduct", {
                    attrs: { productData: _vm.reportData.product }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }