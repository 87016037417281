<template>
  <div class="container">
    <div class="content">
      <span class="item">性别&nbsp;&nbsp;:&nbsp;&nbsp;{{user_info.gender === 2 ? '女' : '男'}}</span>
      <span class="item">年龄&nbsp;&nbsp;:&nbsp;&nbsp;{{user_info.ages}}岁</span>
      <span class="item">手机尾号&nbsp;&nbsp;:&nbsp;&nbsp;{{owner.cellphone.substr(-4)}}</span>
    </div>
  </div>

</template>

<script>
  export default {
    name: "menopauseuserinfo",
    props:{
      owner:Object,
      user_info: Object,
    }
  }
</script>

<style scoped>
  .container{
    margin-top:5px;
    padding: 0px 40px;
    height:81px;
  }
  .content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background:url("../../../assets/img/menopauseovary/xxbj_jcjg_icon.png") no-repeat;
  }
  .item{
    font-size: 30px;
    color: #ffffff;
    font-family:SourceHanSansCN-Regular;
    line-height: 81px;
    padding-left: 10px;
    padding-right: 10px;
  }
</style>
