var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wxtermschart" }, [
    _c("div", { staticClass: "title" }, [_vm._v("节气风险")]),
    _c("div", { staticClass: "current" }, [
      _c("div", { staticClass: "illness" }, [
        _vm._v("当前节气：" + _vm._s(_vm.terms.term))
      ]),
      _c("div", { staticClass: "probability" }, [
        _c("span", { staticClass: "illness" }, [
          _vm._v(
            "患病概率：" +
              _vm._s(
                _vm.terms.illness_probability > 30
                  ? "较高"
                  : _vm.terms.illness_probability < 10
                  ? "低"
                  : "中"
              )
          )
        ]),
        _vm.terms.illness_probability > 30
          ? _c("img", {
              attrs: { src: require("../../../assets/img/wx/wx_row_up.png") }
            })
          : _vm._e(),
        _vm.terms.illness_probability < 10
          ? _c("img", {
              attrs: { src: require("../../../assets/img/wx/wx_row_down.png") }
            })
          : _vm._e()
      ])
    ]),
    _c("div", {
      style: { width: "100%", height: "606px", backgroundColor: "#f5f5f5" },
      attrs: { id: "terms" }
    }),
    _c("div", { staticClass: "text" }, [
      _vm._v(
        "提示：" +
          _vm._s(_vm.terms.term_attention) +
          _vm._s(_vm.terms.term_add_attention)
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }