<template>
  <div class="tongue">
    <header>二、舌诊分析结果</header>
    <div class="tongueTable">
      <table>
        <tr>
          <td rowspan="6" class="tongueImg">
            <img :src="url" alt="舌诊图片" />
          </td>
          <td rowspan="2" class="tongueTitle">舌色</td>
          <td>
            <div class="circle">
              <div>
                <p class="circleL" :style="{backgroundImage: `url(${l_bg})`}">{{tongueColor.L}}</p>
                <p class="circleName">L值</p>
              </div>
              <div>
                <p class="circleA" :style="{backgroundImage: `url(${a_bg})`}">{{tongueColor.A}}</p>
                <p class="circleName">A值</p>
              </div>
              <div>
                <p class="circleB" :style="{backgroundImage: `url(${b_bg})`}">{{tongueColor.B}}</p>
                <p class="circleName">B值</p>
              </div>
            </div>
            <p class="guide"> L值越大舌色越鲜亮，a值越大越淡，b值越大越紫暗</p>
          </td>
        </tr>
        <tr>
          <td class="conclusion">
            <p>结果 ：{{result['tongue_nature_color_info'].name}}</p>
            <p>提示 ：{{result['tongue_nature_color_info'].content[0] === '正常' ? '' : '常见于'}}{{result['tongue_nature_color_info'].content[0]}}</p>
          </td>
        </tr>
        <tr>
          <td class="tongueTitle">舌型</td>
          <td class="conclusion">
            <h3>{{result.tongueFatThin === 0 ? (result.tongueCrack === 0 ? '正常' : '有裂痕') : (result.tongueCrack === 0 ? '舌胖' : '舌胖、有裂痕')}}</h3>
            <p>结果 ：{{result['tongue_fat_thin_info'].name}}</p>
            <p>提示 ：{{result['tongue_fat_thin_info'].content[0] === '正常' ? '' : '常见于'}}{{result['tongue_fat_thin_info'].content[0]}}</p>
          </td>
        </tr>
        <tr>
          <td rowspan="2" class="tongueTitle">苔色</td>
          <td>
            <div class="circle">
              <div>
                <p class="circleL" :style="{backgroundImage: `url(${l_bg})`}">{{tongueColor.lipL}}</p>
                <p class="circleName">L值</p>
              </div>
              <div>
                <p class="circleA" :style="{backgroundImage: `url(${a_bg})`}">{{tongueColor.lipA}}</p>
                <p class="circleName">A值</p>
              </div>
              <div>
                <p class="circleB" :style="{backgroundImage: `url(${b_bg})`}">{{tongueColor.lipB}}</p>
                <p class="circleName">B值</p>
              </div>
            </div>
            <p class="guide">L值越小苔色越暗，a值越大越白，b值越大越黄</p>
          </td>
        </tr>
        <tr>
          <td class="conclusion">
            <p>结果 ：{{result['tongue_coat_color_info'] ? result['tongue_coat_color_info'].name : '未识别到'}}</p>
            <p>提示 ：{{result['tongue_coat_color_info'] ? result['tongue_coat_color_info'].content[0] === '正常' ? '' : '常见于' : ''}}{{result['tongue_coat_color_info'] ? result['tongue_coat_color_info'].content[0] : ''}}</p>
          </td>
        </tr>
        <tr>
          <td class="tongueTitle">苔质</td>
          <td class="conclusion">
            <h3>{{result.tongue_coat_thickness_info.symptom}}</h3>
            <p>结果 ：{{result['tongue_coat_thickness_info'].name}}</p>
            <p>提示 ：{{result['tongue_coat_thickness_info'].content[0] === '正常' ? '' : '常见于'}}{{result['tongue_coat_thickness_info'].content[0]}}</p>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
  import l_bg from '@/assets/img/print/L.png';
  import a_bg from '@/assets/img/print/A.png';
  import b_bg from '@/assets/img/print/B.png';
  import g_bg from '@/assets/img/print/mbgz.png';

  export default {
    name: "tongueResult",
    props: {
      result: Object,
      url: String
    },
    data(){
      return {
        l_bg,
        a_bg,
        b_bg,
        g_bg
      }
    },
    computed:{
      tongueColor(){
        const tongueObj = {};
        tongueObj.L = this.result.nature_L;
        tongueObj.A = this.result.nature_a;
        tongueObj.B = this.result.nature_b;
        tongueObj.lipL = this.result.coat_L;
        tongueObj.lipA = this.result.coat_a;
        tongueObj.lipB = this.result.coat_b;
        // tongueObj.Gzzs = this.result['gloss_indicator'].toFixed(2);
        return tongueObj;
      }
    }
  }
</script>

<style scoped>

  header {
    font-size: 16px;
    line-height: 33px;
    color: #048AD7;
    font-weight: bold;
    margin: 33px 0;
  }

  .tongueTable {
    padding-bottom: 50px;
  }

  table {
    border-collapse:collapse;
    /*color: rgb(204, 204, 204);*/
    font-size: 20px;
    margin-bottom: 50px;
  }

  table,
  tr,
  td {
    border: 2px solid rgb(204, 204, 204);
  }

  .tongueImg {
    width: 32%;
    text-align: center;
  }

  .tongueTitle {
    width: 18%;
    text-align: center;
  }

  .tongueImg img {
    display: block;
    width: 70%;
    margin: 0 auto;
  }

  .circle {
    display: flex;
    justify-content: space-evenly;
    margin-top: 3px;
  }

  .circle>div {
    width: 100%;
  }

  .circle p {
    text-align: center;
  }

  .guide {
    text-align:center;
    color:#4BB9FF;
    font-size:10px;
    line-height:10px;
    padding: 10px 0 20px 0;
  }

  .circleL,
  .circleA,
  .circleB,
  .circleG {
    height: 46px;
    width: 100%;
    font-size: 12px;
    color: #666666;
    line-height: 46px;
    background-size: auto 46px;
    background-repeat: no-repeat;
    background-position-x: center;
  }

  .circleName {
    color:#666666;
    font-size:8px;
    line-height: 40px;
    height: 40px;
  }

  .conclusion {
    color: rgb(51, 51, 51);
    font-size: 12px;
    line-height: 15px;
  }

  .conclusion>p {
    padding: 10px 0 10px 10px;
  }

  .conclusion>h3{
    font-weight: normal;
    text-align: center;
    margin-top: 10px;
    color: rgb(51, 51, 51);
    font-size: 16px;
  }


</style>