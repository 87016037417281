var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wxchronicchart" }, [
    _vm._m(0),
    _c("div", {
      style: { width: "100%", height: "590px" },
      attrs: { id: "chronicchart" }
    }),
    _c("div", { staticClass: "subtitle" }, [
      _vm._v("症状程度： "),
      _c("span", [_vm._v(_vm._s(_vm.healthStatus))]),
      _vm.healthStatus.includes("轻")
        ? _c("img", {
            attrs: { src: require("../../../assets/img/wx/wx_row_down.png") }
          })
        : _vm._e(),
      _vm.healthStatus.includes("重")
        ? _c("img", {
            attrs: { src: require("../../../assets/img/wx/wx_row_up.png") }
          })
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("中医辩证分型")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }