<template>
  <div>
    <h4>(七) 脉诊分析</h4><br/>
    <div class="place">
      <img :src="pulse[0].pulse_image" width="40%">
    </div>
    <p>总脉：{{pulse[0].summary}}</p>
    <p v-if="pulse[0].summary_attention">提示：{{pulse[0].summary_attention}}</p>
    <p v-if="pulse[0].blood_dia ||pulse[0].blood_sys" style="margin-top: 10px">血压：</p>
    <table v-if="pulse[0].blood_dia ||pulse[0].blood_sys ||pulse[0].heart_rate">
      <tr>
        <td v-if="pulse[0].blood_dia">舒张压</td>
        <td v-if="pulse[0].blood_dia">{{pulse[0].blood_dia}}mmHg</td>
        <td v-if="pulse[0].blood_sys">收缩压</td>
        <td v-if="pulse[0].blood_sys">{{pulse[0].blood_sys}}mmHg</td>
        <td v-if="pulse[0].heart_rate">心率</td>
        <td v-if="pulse[0].heart_rate">{{pulse[0].heart_rate}}次/分</td>
      </tr>
    </table>
    <p v-if="pulse[0].blood_dia&&pulse[0].blood_sys">
      提示：{{tipBlood()}}
    </p>
  </div>

</template>

<script>
  export default {
    name: "pulsetzbs",
    props:{
      pulse:Array
    },
    data(){
      return{

      }
    },
    methods:{
      tipBlood(){
        if(this.pulse[0].blood_dia<60 || this.pulse[0].blood_sys<90){
          return '有低血压风险'
        }else if ((80>this.pulse[0].blood_dia&&this.pulse[0].blood_dia>=60)&&(120>this.pulse[0].blood_sys&&this.pulse[0].blood_sys>=90)){
          return '正常血压'
        }else if ((90>this.pulse[0].blood_dia&&this.pulse[0].blood_dia>=80)&&(140>this.pulse[0].blood_sys&&this.pulse[0].blood_sys>=90)){
          return '正常高值'
        }else if ((90>this.pulse[0].blood_dia&&this.pulse[0].blood_dia>=60)&&(140>this.pulse[0].blood_sys&&this.pulse[0].blood_sys>=120)){
          return '正常高值'
        }else if (this.pulse[0].blood_dia>=90 || this.pulse[0].blood_sys>=140){
          return '有高血压风险'
        }else {
          return ''
        }
      }
    }
  }
</script>

<style scoped>
  p{
    font-weight: bold;
    font-family: '宋体';
    line-height: 30px;
  }
  .place{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  table {
    width: 100%;
    border-collapse:collapse;
    /*color: rgb(204, 204, 204);*/
    /*font-size: 20px;*/
    margin-bottom: 10px;
    margin-top: 10px;
  }
  table,
  tr,
  td {
    border: 2px solid rgb(204, 204, 204);
    text-align: center;
    padding: 10px;
  }

</style>
