<template>
  <div :class="marker === '10.1' ? 'healthtip-ten-one' : 'healthtip'">
    <!-- 健康风险提示 -->
    <div class="tip-content">
      <div class="tip-top">
        <img v-if="reportData.result.includes('平和质')" class="tip-img-health" src="../../assets/img/common/wjkfx_icon.png"/>
        <img v-else class="tip-img" src="../../assets/img/common/jbfx_jcbg_icon.png"/>
        <span :class="reportData.result.includes('平和质') ? 'tip-title tip-title-health' : 'tip-title'">{{reportData.result.includes('平和质') ? '无疾病风险' : '未来疾病风险'}}</span>
      </div>
      <div class="body">
        <div class="line">
          <div class="line-left">
            <div v-for="(item, index) in leftRisk" :key="index"
            :class="item.content[0].includes('腹部') ? 'leftc fu' :
            item.content[0].includes('头') ? 'leftc head' :
            item.content[0].includes('脸') ? 'leftc face' :
            item.content[0].includes('口腔') ? 'leftc oral' :
            item.content[0].includes('胸') ? 'leftc chest' :
            item.content[0].includes('肺') ? 'leftc lung' :
            item.content[0].includes('心') ? 'leftc darling' :
            item.content[0].includes('上臂') ? 'leftc upperarm' :
            item.content[0].includes('肾脏') ? 'leftc kidney' :
            item.content[0].includes('骨骼') ? 'leftc bone' :
            item.content[0].includes('手掌') ? 'leftc hand' : 'leftc' " >
              <span>{{item.name}}</span>
              <img :src="item.content[0].includes('口腔') ? lineOral : lineLeft" alt="人体线图"/>
            </div>
          </div>
          <div class="line-right">
            <div v-for="(item, index) in rightRisk" :key="index"
            :class="item.content[0].includes('腹部') ? 'rightc fu' :
            item.content[0].includes('头部') ? 'rightc head' :
            item.content[0].includes('心脏') ? 'rightc heart' :
            item.content[0].includes('鼻子') ? 'rightc nose' :
            item.content[0].includes('脸') ? 'rightc face' :
            item.content[0].includes('口腔') ? 'rightc oral' :
            item.content[0].includes('咽') ? 'rightc throat' :
            item.content[0].includes('胸') ? 'rightc chest' :
            item.content[0].includes('上臂') ? 'rightc upperarm' :
            item.content[0].includes('肘关节') ? 'rightc elbow' :
            item.content[0].includes('全身') ? 'rightc wholebody' :
            item.content[0].includes('下半部') ? 'rightc bottomhalf' : 'rightc'">
              <img :src="item.content[0].includes('鼻子') ? lineNose : item.content[0].includes('口腔') || item.content[0].includes('咽') ? lineRightOral : lineRight" alt="人体线图"/>
              <span>{{item.name}}</span>
            </div>
          </div>
        </div>
        <div class="imgdiv">
          <img v-if="reportData.result.includes('平和质')" class="health-body" src="../../assets/img/physical/health_body.png" alt="人体图" />
          <img v-else class="health-ill" src="../../assets/img/physical/ill_body.png" alt="人体图" />
          <div v-if="rightarr">
            <div v-for="(item, index) in rightarr" :key="index"
                 :class="item.includes('腹部') ? 'abdomen-img' :
            item.includes('头') ? 'head-img' :
            item.includes('脸部') ? 'face-img' :
            item.includes('口腔') ? 'nose-img' :
            item.includes('胸') ? 'thoracic-img' :
            item.includes('肺') ? 'lung-img' :
            item.includes('心') ? 'heart-img' :
            item.includes('上臂') ? 'uparm-img' :
            item.includes('肾脏') ? 'kidney-img' :
            item.includes('骨骼') ? '' :
            item.includes('手掌') ? 'lpalm-img' :
            item.includes('鼻子') ? 'nose-img' :
            item.includes('脸') ? 'rface-img' :
            item.includes('咽') ? 'pharynx-img' :
            item.includes('上臂') ? 'uparmr-img' :
            item.includes('肘关节') ? 'elbow-img' :
            item.includes('全身') ? '' :
            item.includes('下半部') ? 'bladder-img' : ''">
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--  -->
    <div :class="reportData.has_pulse ? 'result-btn has_pulse' : 'result-btn' ">
      <div class="btn" @click="lookFaceResult">面诊{{isSpecialVersion? '图片':'结果'}}</div>
      <div class="btn" @click="lookTongueResult">舌诊{{isSpecialVersion? '图片':'结果'}}</div>
      <div class="btn" v-if="reportData.has_pulse" @click="lookPulseResult">脉诊结果</div>
    </div>
  </div>
</template>

<script>
import { queryObject } from '../../../utils/common.js';
import lineLeft from '../../assets/img/physical/body_line_left.png';
import lineRight from '../../assets/img/physical/body_line_right.png';
import lineOral from '../../assets/img/physical/body_line_left_oral.png';
import lineNose from '../../assets/img/physical/body_line_nose.png';
import lineRightOral from '../../assets/img/physical/body_line_right_oral.png';
const { csr = '', marker = '' } = queryObject()

export default {
  name: "reporthealthtip",
  props: {
    reportData: Object
  },
  data(){
    return {
      leftRisk: [],
      rightRisk: [],
      lineLeft: lineLeft,
      lineRight: lineRight,
      lineOral: lineOral,
      lineNose: lineNose,
      lineRightOral: lineRightOral,
      leftarr:[],
      rightarr:[],
      timer: null
    }
  },
  computed: {
    marker(){
      return marker
    },
    isSpecialVersion(){
      const { report } = this.$store.state;
      const isTzbsReport = window.location.search && window.location.search.indexOf('category=2') !== -1;
      return report.attention_display && isTzbsReport; // 是否是医疗器械版本
    }
  },
  created() {
    this.riskData()
  },
  mounted(){
    this.$on('hook:activated', () => {
      this.riskData()
    })
    this.$on('hook:deactivated', () => {
      clearInterval(this.timer)
      this.timer = null
    })
  },
  methods: {
    riskData(){
      this.rightarr = []
      const _this = this;
      let risk = null;
      this.reportData.result.includes('(')?risk = this.reportData.result.substring(0,this.reportData.result.indexOf('(')):risk =this.reportData.result;
      if (this.reportData.result !== '平和质'){
        this.leftRisk = (this.reportData.solutions.tizhi_health_risk[risk])
        this.rightRisk = (this.reportData.solutions.tizhi_health_risk[risk])
        let symarr = this.reportData.physical_disease
        this.leftRisk = this.leftRisk.filter(item => item.content[0].includes('左'))
        this.rightRisk = this.rightRisk.filter(item => item.content[0].includes('右'))
        for(let i = 0; i < symarr.length;i++) {
          this.leftarr.push(symarr[i].location.substring(0,symarr[i].location.indexOf('（')))
        }
        for(let i = 0;i<this.leftarr.length;i++){
          for (let j=i+1; j<this.leftarr.length; j++){
            if(this.leftarr[i]===this.leftarr[j]){
              this.leftarr.splice(j,1);
              j--;
            }
          }
        }
        // this.$on('hook:activated', () => {
          for (let i=0;i< _this.leftarr.reverse().length;i++){
            this.timer = setTimeout(()=>{
              _this.rightarr.push(_this.leftarr[i])
            },1000*i)
          }
        // })
        console.log(_this.rightarr)
      }
    },
    lookFaceResult() {
      // 跳转到面诊
      // this.$router.push({name: 'Facetzbs'})

      const { report } = this.$store.state;
      this.$router.push({
        name:'Facereport',
        params: {
          facereport: report.face_result,
          id:report.id,
          report,
          isFromReportHome: true
        }
      })
    },
    lookTongueResult() {
      // 跳转到舌诊页面
      // this.$router.push({name: 'Tonguetzbs'})
      const { report } = this.$store.state;
      this.$router.push({
        name:'Tonguereport',
        params: {
          tonguereport: report.tongue_result,
          tongue_photo_url:report.tongue_photo_url,
          id:report.id,
          report,
          isFromReportHome: true
        }
      })
    },
    lookPulseResult() {
      const { report } = this.$store.state;
      const url = report.pulse_url;
      if(url) {
        // 太医脉诊页面
        if(window.zhiyun){
          window.zhiyun.gotopulse(url);
        } else {
          window.location = url;
        }
      } else {
        // 祉云脉诊页面
        this.$router.push({
          name: 'Pulse',
          params: {
            id: report && report.pulse[0] && report.pulse[0].mai_id
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$contentWidth: 94%;
$leftContent: 3%;
$commonHeight: 556px;
$tenHeight: 470px;
$tzbg: '../../assets/img/physical/jbfxbj_jcjg_icon.png';
$tzbgTen: '../../assets/img/physical/jbfxbj_jcjg_10_icon.png';
$btnbg: '../../assets/img/physical/mzjg_jcjg_icon.png';
$brain: '../../assets/img/physical/brain.png';
$rightface: '../../assets/img/physical/rightface.png';
$leftface: '../../assets/img/physical/leftface.png';
$nose: '../../assets/img/physical/nose.png';
$thoracic: '../../assets/img/physical/thoracic.png';
$lung: '../../assets/img/physical/lung.png';
$heart: '../../assets/img/physical/heart.png';
$uparm: '../../assets/img/physical/uparm.png';
$kidney: '../../assets/img/physical/kidney.png';
$lpalm: '../../assets/img/physical/lpalm.png';
$abdomen: '../../assets/img/physical/abdomen.png';
$pharynx: '../../assets/img/physical/pharynx.png';
$uparmr: '../../assets/img/physical/uparmr.png';
$elbow: '../../assets/img/physical/elbow.png';
$bladder: '../../assets/img/physical/bladder.png';
// 非10.1适配
.healthtip {
  margin-top:20px;
  .tip-content {
    margin-left: $leftContent;
    width: $contentWidth;
    background:url($tzbg);
    background-size:100% 100%;
    height: $commonHeight;
    .tip-top {
      padding: 14px 0 0 31px;
      display: flex;
      align-items: center;
      .tip-img {
        width: 23px;
        height: 36px;
      }
      .tip-img-health {
        width: 26px;
        height: 32px;
      }
      .tip-title {
        font-size: 36px;
        color: #f96542;
        margin-left: 16px;
      }
      .tip-title-health {
        color: #02ca92;
      }
    }
    .body {
      display: flex;
      position: relative;
      .imgdiv {
        width: 100%;
        text-align: center;
        .health-body {
          height: $commonHeight;
          width: auto;
          margin-top: -60px;
        }
        .health-ill {
          opacity: 0.6;
          /*height: auto;*/
          /*width: 378px;*/
        }
        .head-img{
          position: absolute;
          background:url($brain);
          background-size: 100% 100%;
          width: 70px;
          height: 54px;
          top:0px;
          left: 46.8%;
          opacity: 1;
          animation-name: head-img-example;
          animation-duration: 5s;
          animation-iteration-count: infinite;
        }
        @keyframes head-img-example {
          from {opacity: 1;}
          to {opacity: 0;}
        }
        .face-img{
          position: absolute;
          background:url($leftface);
          background-size: 100% 100%;
          width: 50px;
          height: 50px;
          top:60px;
          left: 46.5%;
          opacity: 1;
          animation-name: face-img-example;
          animation-duration: 5s;
          animation-iteration-count: infinite;
        }
        @keyframes face-img-example {
          from {opacity: 1;}
          to {opacity: 0;}
        }
        .rface-img{
          position: absolute;
          background:url($rightface);
          background-size: 100% 100%;
          width: 50px;
          height: 50px;
          top:60px;
          left: 49.2%;
          animation-name: rface-img-example;
          animation-duration: 5s;
          animation-iteration-count: infinite;
        }
        @keyframes rface-img-example {
          from {opacity: 1;}
          to {opacity: 0;}
        }
        .nose-img{
          position: absolute;
          background:url($nose);
          background-size: 100% 100%;
          width: 50px;
          height: 50px;
          top:40px;
          left: 47.9%;
          animation-name: nose-img-example;
          animation-duration: 5s;
          animation-iteration-count: infinite;
        }
        @keyframes nose-img-example {
          from {opacity: 1;}
          to {opacity: 0;}
        }
        .thoracic-img{
          position: absolute;
          background:url($thoracic);
          background-size: 100% 100%;
          width: 190px;
          height: 260px;
          top:90px;
          left: 41.5%;
          animation-name: thoracic-img-example;
          animation-duration: 5s;
          animation-iteration-count: infinite;
        }
        @keyframes thoracic-img-example {
          from {opacity: 1;}
          to {opacity: 0;}
        }
        .lung-img{
          position: absolute;
          background:url($lung);
          background-size: 100% 100%;
          width: 150px;
          height: 145px;
          top:130px;
          left: 43%;
          animation-name: lung-img-example;
          animation-duration: 5s;
          animation-iteration-count: infinite;
        }
        @keyframes lung-img-example {
          from {opacity: 1;}
          to {opacity: 0;}
        }
        .heart-img{
          position: absolute;
          background:url($heart);
          background-size: 100% 100%;
          width: 120px;
          height: 115px;
          top:150px;
          left: 44%;
          opacity: 1;
          animation-name: heart-img-example;
          animation-duration: 5s;
          animation-iteration-count: infinite;
        }
        @keyframes heart-img-example {
          from {opacity: 1;}
          to {opacity: 0;}
        }
        .uparmr-img{
          position: absolute;
          background:url($uparmr);
          background-size: 100% 100%;
          width: 120px;
          height: 195px;
          top:140px;
          left: 54%;
          opacity: 1;
          animation-name: uparmr-img-example;
          animation-duration: 5s;
          animation-iteration-count: infinite;
        }
        @keyframes uparmr-img-example {
          from {opacity: 1;}
          to {opacity: 0;}
        }
        .uparm-img{
          position: absolute;
          background:url($uparm);
          background-size: 100% 100%;
          width: 120px;
          height: 195px;
          top:140px;
          left: 33.2%;
          opacity: 1;
          animation-name: uparm-img-example;
          animation-duration: 5s;
          animation-iteration-count: infinite;
        }
        @keyframes uparm-img-example {
          from {opacity: 1;}
          to {opacity: 0;}
        }
        .kidney-img{
          position: absolute;
          background:url($kidney);
          background-size: 100% 100%;
          width: 120px;
          height: 80px;
          top:280px;
          left: 43.8%;
          opacity: 1;
          animation-name: kidney-img-example;
          animation-duration: 5s;
          animation-iteration-count: infinite;
        }
        @keyframes kidney-img-example {
          from {opacity: 1;}
          to {opacity: 0;}
        }
        .lpalm-img{
          position: absolute;
          background:url($lpalm);
          background-size: 100% 100%;
          width: 80px;
          height: 80px;
          bottom: -10px;
          left: 29%;
          opacity: 1;
          animation-name: lpalm-img-example;
          animation-duration: 5s;
          animation-iteration-count: infinite;
        }
        @keyframes lpalm-img-example {
          from {opacity: 1;}
          to {opacity: 0;}
        }
        .abdomen-img{
          position: absolute;
          background:url($abdomen);
          background-size: 100% 100%;
          width: 130px;
          height: 100px;
          top:290px;
          left: 43.8%;
          opacity: 1;
          animation-name: abdomen-img-example;
          animation-duration: 5s;
          animation-iteration-count: infinite;
        }
        @keyframes abdomen-img-example {
          from {opacity: 1;}
          to {opacity: 0;}
        }
        .pharynx-img{
          position: absolute;
          background:url($pharynx);
          background-size: 100% 100%;
          width: 60px;
          height: 70px;
          top:80px;
          left: 47%;
          opacity: 1;
          animation-name: pharynx-img-example;
          animation-duration: 5s;
          animation-iteration-count: infinite;
        }
        @keyframes pharynx-img-example {
          from {opacity: 1;}
          to {opacity: 0;}
        }
        .elbow-img{
          position: absolute;
          background:url($elbow);
          background-size: 100% 100%;
          width: 120px;
          height: 195px;
          top:199px;
          left: 57.5%;
          opacity: 1;
          animation-name: elbow-img-example;
          animation-duration: 5s;
          animation-iteration-count: infinite;
        }
        @keyframes elbow-img-example {
          from {opacity: 1;}
          to {opacity: 0;}
        }
        .bladder-img{
          position: absolute;
          background:url($bladder);
          background-size: 100% 100%;
          width: 80px;
          height: 80px;
          bottom: 0;
          left: 46.3%;
          opacity: 1;
          animation-name: bladder-img-example;
          animation-duration: 5s;
          animation-iteration-count: infinite;
        }
        @keyframes bladder-img-example {
          from {opacity: 1;}
          to {opacity: 0;}
        }
      }
      .line {
        display: flex;
        flex-direction: row;
        position: absolute;
        margin-top: 0;;
        width: 100%;
        height: 100%;
        .common-line {
          width: 50%;
          display: flex;
          flex-direction: column;
          position: relative;
          // background-color: #02ca92;
        }
        .line-left {
          @extend .common-line;
          .leftc {
            display: flex;
            position: absolute;
            align-items: center;
            right: 0;
            color: #ffffff;
            font-size: 26px;
            z-index: 99;
            img {
              height: 7px;
              width: 280px;
              margin-left: 18px
            }
          }
          .head {
            margin-top: 32px;
            right: 30px;
            img {
              width: 250px;
            }
          }
          .fu {
            margin-top: 320px;
            right: 60px;
            img {
              width: 220px;
            }
          }
          .face {
            margin-top: 53px;
            right: 20px;
            img {
              width: 260px;
            }
          }
          .oral {
            margin-top: 76px;
            right: 10px;
            img {
              width: 270px;
              height: 35px;
            }
          }
          .chest {
            margin-top: 143px;
            right: 50px;
            img {
              width: 230px;
            }
          }
          .lung {
            margin-top: 173px;
            right: 30px;
            img {
              width: 250px;
            }
          }
          .darling {
            margin-top: 203px;
            right: 0px;
            img {
              width: 280px;
            }
          }
          .upperarm {
            margin-top: 233px;
            right: 110px;
            img {
              width: 170px;
            }
          }
          .kidney {
            margin-top: 263px;
            right: 30px;
            img {
              width: 250px;
            }
          }
          .bone {
            margin-top: 403px;
            right: 60px;
            img {
              width: 220px;
            }
          }
          .hand {
            margin-top: 423px;
            right: 180px;
            img {
              width: 100px;
            }
          }
        }
        .line-right {
          @extend .common-line;
          // background-color: #2f84ce;
          .rightc {
            color: #ffffff;
            font-size: 26px;
            display: flex;
            align-items: center;
            position: absolute;
            z-index: 99;
            img {
              height: 7px;
              width: 280px;
              margin-right: 18px;
            }
          }
          .fu {
            margin-top: 332px;
            left: 50px;
            img {
              width: 230px;
            }
          }
          .head {
            margin-top: 0px;
            left: 30px;
            img {
              width: 250px;
            }
          }
          .heart {
            margin-top: 202px;
            left: 0px;
            img {
              width: 280px;
            }
          }
          .nose {
            margin-top: 32px;
            left: 0px;
            img {
              height: 33px;
              width: 280px;
            }
          }
          .face {
            margin-top: 62px;
            left: 25px;
            img {
              width: 255px;
            }
          }
          .oral {
            margin-top: 78px;
            left: 0px;
            img {
              height: 22px;
              width: 280px;
            }
          }
          .throat {
            margin-top: 108px;
            left: 0px;
            img {
              height: 22px;
              width: 280px;
            }
          }
          .chest {
            margin-top: 152px;
            left: 60px;
            img {
              width: 220px;
            }
          }
          .upperarm {
            margin-top: 232px;
            left: 110px;
            img {
              width: 170px;
            }
          }
          .elbow {
            margin-top: 292px;
            left: 130px;
            img {
              width: 150px;
            }
          }
          .wholebody {
            margin-top: 382px;
            left: 170px;
            img {
              width: 110px;
              max-height: 5px;
            }
          }
          .bottomhalf {
            margin-top: 422px;
            left: 85px;
            img {
              width: 195px;
            }
          }
        }
      }
    }

  }
  // 面舌诊按钮
  .result-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 30px;
    width: 80%;
    margin-left: 10%;
    .btn {
      width: 300px;
      height: 72px;
      line-height: 66px;
      background:url($btnbg) no-repeat;
      font-size: 28.6px;
      color: #fefefe;
      text-align: center;
    }
  }
  .has_pulse {
    width: 99%;
    margin-left: 0.5%;
  }
}
// 10.1适配
.healthtip-ten-one {
  margin-top:15px;
  .tip-content {
    margin-left: $leftContent;
    width: $contentWidth;
    background:url($tzbgTen);
    background-size:100% 100%;
    height: $tenHeight;
    .tip-top {
      padding: 14px 0 0 31px;
      display: flex;
      align-items: center;
      .tip-img {
        width: 23px;
        height: 36px;
      }
      .tip-img-health {
        width: 26px;
        height: 32px;
      }
      .tip-title {
        font-size: 36px;
        color: #f96542;
        margin-left: 16px;
      }
      .tip-title-health {
        color: #02ca92;
      }
    }
    .body {
      display: flex;
      position: relative;
      .imgdiv {
        width: 100%;
        text-align: center;
        .health-body {
          height: $tenHeight;
          width: auto;
          margin-top: -60px;
        }
        .health-ill {
          height: auto;
          width: 378px;
        }
      }
      .line {
        display: flex;
        flex-direction: row;
        position: absolute;
        margin-top: 0;;
        width: 100%;
        height: 100%;
        .common-line {
          width: 50%;
          display: flex;
          flex-direction: column;
          position: relative;
          // background-color: #02ca92;
        }
        .line-left {
          @extend .common-line;
          .leftc {
            display: flex;
            position: absolute;
            align-items: center;
            right: 0;
            color: #ffffff;
            font-size: 26px;
            z-index: 99;
            img {
              height: 7px;
              width: 280px;
              margin-left: 18px
            }
          }
          .head {
            margin-top: 14px;
            right: 26px;
            img {
              width: 254px;
            }
          }
          .fu {
            margin-top: 284px;
            right: 60px;
            img {
              width: 220px;
            }
          }
          .face {
            margin-top: 50px;
            right: 20px;
            img {
              width: 260px;
            }
          }
          .oral {
            margin-top: 70px;
            right: 10px;
            img {
              width: 270px;
              height: 35px;
            }
          }
          .chest {
            margin-top: 123px;
            right: 50px;
            img {
              width: 230px;
            }
          }
          .lung {
            margin-top: 150px;
            right: 30px;
            img {
              width: 250px;
            }
          }
          .darling {
            margin-top: 183px;
            right: 0px;
            img {
              width: 280px;
            }
          }
          .upperarm {
            margin-top: 218px;
            right: 110px;
            img {
              width: 170px;
            }
          }
          .kidney {
            margin-top: 243px;
            right: 30px;
            img {
              width: 250px;
            }
          }
          .bone {
            margin-top: 333px;
            right: 60px;
            img {
              width: 220px;
            }
          }
          .hand {
            margin-top: 360px;
            right: 180px;
            img {
              width: 100px;
            }
          }
        }
        .line-right {
          @extend .common-line;
          // background-color: #2f84ce;
          .rightc {
            color: #ffffff;
            font-size: 26px;
            display: flex;
            align-items: center;
            position: absolute;
            z-index: 99;
            img {
              height: 7px;
              width: 280px;
              margin-right: 18px;
            }
          }
          .fu {
            margin-top: 282px;
            left: 60px;
            img {
              width: 220px;
            }
          }
          .head {
            margin-top: -6px;
            left: 20px;
            img {
              width: 260px;
            }
          }
          .heart {
            margin-top: 172px;
            left: 0px;
            img {
              width: 280px;
            }
          }
          .nose {
            margin-top: 26px;
            left: 0px;
            img {
              height: 33px;
              width: 280px;
            }
          }
          .face {
            margin-top: 56px;
            left: 25px;
            img {
              width: 255px;
            }
          }
          .oral {
            margin-top: 74px;
            left: 0px;
            img {
              height: 22px;
              width: 280px;
            }
          }
          .throat {
            margin-top: 102px;
            left: 0px;
            img {
              height: 22px;
              width: 280px;
            }
          }
          .chest {
            margin-top: 132px;
            left: 60px;
            img {
              width: 220px;
            }
          }
          .upperarm {
            margin-top: 212px;
            left: 110px;
            img {
              width: 170px;
            }
          }
          .elbow {
            margin-top: 238px;
            left: 130px;
            img {
              width: 150px;
            }
          }
          .wholebody {
            margin-top: 312px;
            left: 150px;
            img {
              width: 130px;
              max-height: 5px;
            }
          }
          .bottomhalf {
            margin-top: 356px;
            left: 78px;
            img {
              width: 202px;
            }
          }
        }
      }
    }

  }
  // 面舌诊按钮
  .result-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 10px;
    width: 80%;
    margin-left: 10%;
    .btn {
      width: 300px;
      height: 72px;
      line-height: 66px;
      background:url($btnbg) no-repeat;
      font-size: 28.6px;
      color: #fefefe;
      text-align: center;
    }
  }
  .has_pulse {
    width: 99%;
    margin-left: 0.5%;
  }
}
</style>
