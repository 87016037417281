<template>
  <div v-if="doctorList" class="doctor">
    <div v-for="(item, index) in doctorList" :key="index" class="doctorBox" @click="handleClick(item)">
      <div class="imgBox">
        <img v-if="item.head_image" :src="item.head_image"/>
        <img v-else :src="logo" />
      </div>
      <div class="title">
        <p class="name">{{ item.name }}</p>
        <p class="department">
          <span v-if="item.title">{{ item.title }} | </span>{{item.department}}</p>
      </div>
      <div class="hospital">{{ item.medical_institution}}</div>
      <div class="good">
        <p v-for="(good,index) in item.skill" :key="index" v-if="good.name">{{ good.name}}</p>
      </div>
    </div>
  </div>

</template>

<script>
  import logo from '@/assets/img/report/company-logo-color2.png';

  export default {
    name: "doctorList",
    props: {
      doctorList: Array,
    },
    data(){
      return {
        logo
      }
    },
    methods: {
      handleClick(item){
        this.$emit("gotoDoctor", item)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .doctor {
    display: flex;
    flex-flow: wrap;
    width: 100%;
    /*margin-left: 2.5%;*/
    max-height: 1300px;
    overflow: auto;
    color: #999999;

    .doctorBox {
      background-color: #fff;
      padding: 10px 30px 20px 30px;
      width: calc(45% - 60px);
      margin: 2.5%;

      .imgBox {
        width: 180px;
        height: 180px;
        overflow: hidden;
        border: 1px solid #cccccc;
        margin: 0 auto;
        border-radius: 50%;
        position: relative;
        top: 20px;

        img {
          width: 180px;
          height: auto;
          display: block;
          margin: 0 auto;
        }
      }

      .title {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        color: #333333;
        line-height: 32px;

        .name {
          font-size: 32px;
          color: #333333;
          font-weight: bold;
        }

        .department {
          font-size: 20px;
          color: #999999;
        }
      }

      .hospital {
        font-size: 24px;
        line-height: 24px;
        margin-top: 16px;
        margin-bottom: 30px;
      }

      .good {
        margin-top: 10px;
        line-height: 50px;
        height: 150px;
        overflow: hidden;

        p{
          font-size: 20px;
          color: #999999;
          padding: 0 10px;
          background-color: #e7e7e7;
          /*background-color: red;*/
          border: 1px solid #999999;
          border-radius: 10px;
          margin-right: 20px;
          /*margin-bottom: 20px;*/
          display: inline-block;
          max-width: 90%;
          overflow: hidden;
          line-height: 35px;
        }

      }

    }
  }

</style>