var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.doctorList
    ? _c(
        "div",
        { staticClass: "doctor" },
        _vm._l(_vm.doctorList, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "doctorBox",
              on: {
                click: function($event) {
                  return _vm.handleClick(item)
                }
              }
            },
            [
              _c("div", { staticClass: "imgBox" }, [
                item.head_image
                  ? _c("img", { attrs: { src: item.head_image } })
                  : _c("img", { attrs: { src: _vm.logo } })
              ]),
              _c("div", { staticClass: "title" }, [
                _c("p", { staticClass: "name" }, [_vm._v(_vm._s(item.name))]),
                _c("p", { staticClass: "department" }, [
                  item.title
                    ? _c("span", [_vm._v(_vm._s(item.title) + " | ")])
                    : _vm._e(),
                  _vm._v(_vm._s(item.department))
                ])
              ]),
              _c("div", { staticClass: "hospital" }, [
                _vm._v(_vm._s(item.medical_institution))
              ]),
              _c(
                "div",
                { staticClass: "good" },
                _vm._l(item.skill, function(good, index) {
                  return good.name
                    ? _c("p", { key: index }, [_vm._v(_vm._s(good.name))])
                    : _vm._e()
                }),
                0
              )
            ]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }