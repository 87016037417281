var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "face" }, [
    _c("header", [_vm._v("一、面诊分析结果")]),
    _c("div", { staticClass: "faceTable" }, [
      _c("table", [
        _c("tr", [
          _c("td", { staticClass: "faceImg", attrs: { rowspan: "6" } }, [
            _c("img", { attrs: { src: _vm.url, alt: "面诊图片" } })
          ]),
          _c("td", { staticClass: "faceTitle", attrs: { rowspan: "2" } }, [
            _vm._v("面色")
          ]),
          _c("td", [
            _c("div", { staticClass: "circle" }, [
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass: "circleL",
                    style: { backgroundImage: "url(" + _vm.l_bg + ")" }
                  },
                  [_vm._v(_vm._s(_vm.faceColor.L))]
                ),
                _c("p", { staticClass: "circleName" }, [_vm._v("L值")])
              ]),
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass: "circleA",
                    style: { backgroundImage: "url(" + _vm.a_bg + ")" }
                  },
                  [_vm._v(_vm._s(_vm.faceColor.A))]
                ),
                _c("p", { staticClass: "circleName" }, [_vm._v("A值")])
              ]),
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass: "circleB",
                    style: { backgroundImage: "url(" + _vm.b_bg + ")" }
                  },
                  [_vm._v(_vm._s(_vm.faceColor.B))]
                ),
                _c("p", { staticClass: "circleName" }, [_vm._v("B值")])
              ])
            ]),
            _c("p", { staticClass: "guide" }, [
              _vm._v(" L值越大面色越亮、有光泽，a值越大越红，b值越大越青黑")
            ])
          ])
        ]),
        _c("tr", [
          _c("td", { staticClass: "conclusion" }, [
            _c("p", [
              _vm._v("结果 ：" + _vm._s(_vm.result["face_color_info"].name))
            ]),
            _c("p", [
              _vm._v(
                "提示 ：" +
                  _vm._s(
                    _vm.result["face_color_info"].content[0] === "正常"
                      ? ""
                      : "常见于"
                  ) +
                  _vm._s(_vm.result["face_color_info"].content[0])
              )
            ])
          ])
        ]),
        _c("tr", [
          _c("td", { staticClass: "faceTitle", attrs: { rowspan: "2" } }, [
            _vm._v("唇色")
          ]),
          _c("td", [
            _c("div", { staticClass: "circle" }, [
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass: "circleL",
                    style: { backgroundImage: "url(" + _vm.l_bg + ")" }
                  },
                  [_vm._v(_vm._s(_vm.faceColor.lipL))]
                ),
                _c("p", { staticClass: "circleName" }, [_vm._v("L值")])
              ]),
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass: "circleA",
                    style: { backgroundImage: "url(" + _vm.a_bg + ")" }
                  },
                  [_vm._v(_vm._s(_vm.faceColor.lipA))]
                ),
                _c("p", { staticClass: "circleName" }, [_vm._v("A值")])
              ]),
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass: "circleB",
                    style: { backgroundImage: "url(" + _vm.b_bg + ")" }
                  },
                  [_vm._v(_vm._s(_vm.faceColor.lipB))]
                ),
                _c("p", { staticClass: "circleName" }, [_vm._v("B值")])
              ])
            ]),
            _c("p", { staticClass: "guide" }, [
              _vm._v("L值越大面色越亮、有光泽，a值越大越淡，b值越大越紫暗")
            ])
          ])
        ]),
        _c("tr", [
          _c("td", { staticClass: "conclusion" }, [
            _c("p", [
              _vm._v(
                "结果 ：" +
                  _vm._s(
                    _vm.result["lip_color_info"]
                      ? _vm.result["lip_color_info"].name
                      : "未识别到唇"
                  )
              )
            ]),
            _c("p", [
              _vm._v(
                "提示 ：" +
                  _vm._s(
                    _vm.result["lip_color_info"]
                      ? _vm.result["lip_color_info"].content[0] === "正常"
                        ? ""
                        : "常见于"
                      : ""
                  ) +
                  _vm._s(
                    _vm.result["lip_color_info"]
                      ? _vm.result["lip_color_info"].content[0]
                      : ""
                  )
              )
            ])
          ])
        ]),
        _c("tr", [
          _c("td", { staticClass: "faceTitle", attrs: { rowspan: "2" } }, [
            _vm._v("面部光泽")
          ]),
          _c("td", [
            _c("div", { staticClass: "circle" }, [
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass: "circleG",
                    style: { backgroundImage: "url(" + _vm.g_bg + ")" }
                  },
                  [_vm._v(_vm._s(_vm.faceColor.Gzzs))]
                ),
                _c("p", { staticClass: "circleName" }, [_vm._v("面部光泽指数")])
              ])
            ])
          ])
        ]),
        _c("tr", [
          _c("td", { staticClass: "conclusion" }, [
            _c("p", [
              _vm._v("结果 ：" + _vm._s(_vm.result["face_gloss_info"].name))
            ]),
            _c("p", [
              _vm._v(
                "提示 ：" +
                  _vm._s(
                    _vm.result["face_gloss_info"].content[0] === "正常"
                      ? ""
                      : "常见于"
                  ) +
                  _vm._s(_vm.result["face_gloss_info"].content[0])
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }