<template>
  <div class="container">
    <div class="jkwt">
      <div v-if="health_status" class="cir">
        <img style="width: 245px;margin-top: -10px"
          :src="healthy_status()"
          alt="健康状态"
        >
      </div>
      <div class="HealthInfo">
        <span class="tip">{{this.health_status === '亚健康'?'建议咨询专业人士进行健康调理':'恭喜，您的评分无异常，建议合理饮食，适度运动'}}</span>
      </div>
      <span class="symptom" @click="ReturnToExplain(item.name)" v-for="(item, index) in report.symptoms" :key="index">{{item.name}}</span>
    </div>
  </div>

</template>

<script>
  export default {
    name: "menopauseindicator",
    props:{
      health_status:String,
      report:Object,
    },
    mounted() {
      this.healthy_status()
    },
    methods:{
      healthy_status(){
        if (this.health_status === '健康'){
          return require(`../../../assets/img/menopauseovary/group28_32.gif`);
        }else if (this.health_status === '亚健康'){
          return require(`../../../assets/img/menopauseovary/group28_17.gif`);
        }else {
          return require(`../../../assets/img/menopauseovary/group28_3.gif`);
        }
      },
      ReturnToExplain( val ){
        this.$router.push({name:'Femaleexplain', params: {reportData: this.report,definition:val,id:this.$route.params.id}})
      }
    },
  }
</script>

<style scoped>
  .container{
    margin-top:10px;
    width: 100%;
  }
  .jkwt{
    height: 250px;
    margin-left:40px;
    width:1000px;
    background:url("../../../assets/img/menopauseovary/jktlbj_cpjg_icon.png");
    background-size:100% 100%;
    padding-top:25px;
    overflow:hidden;
  }
  .cir{
    width:30%;
    height:100%;
    float:left;
    text-align: right;
  }
  .HealthInfo{
    position:relative;
    display:inline-block;
    width:660px;
    margin:15px  0 0 0;
    padding:30px 0 0 25px;
  }
  .tip{
    font-size: 30px;
    color:#00c5fe;
    font-family:SourceHanSansCN-Medium;
    line-height: 42px;
  }
  .symptom{
    display:inline-block;
    width:238px;
    height:75px;
    background:url("../../../assets/img/menopauseovary/zzc_cpjg_icon.png") no-repeat;
    background-size:100% 100%;
    /*margin-right:20px;*/
    color:#ffffff;
    font-size:30px;
    line-height:71px;
    text-align:center;
    margin:20px 0 0 33px;
  }

</style>
