var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "attentions" },
    [
      _c("h3", [_vm._v("二、个性化养生方案")]),
      _c("br"),
      _c("header", { staticStyle: { "font-weight": "bold" } }, [
        _vm._v("根据您最具倾向性的体质推荐以下个性化养生方案。")
      ]),
      _c("h4", [_vm._v("（一）饮食调养")]),
      _c("br"),
      _c(
        "p",
        [
          _vm._v("1、宜食"),
          _vm._l(_vm.foodList.good, function(item, index) {
            return _c("span", { key: index }, [
              _vm._v(
                _vm._s(item.name) +
                  _vm._s(index === _vm.foodList.good.length - 1 ? "。" : "、")
              )
            ])
          })
        ],
        2
      ),
      _c(
        "p",
        [
          _vm._v("2、忌食"),
          _vm._l(_vm.foodList.bad, function(item, index) {
            return _c("span", { key: index }, [
              _vm._v(
                _vm._s(item.name) +
                  _vm._s(index === _vm.foodList.bad.length - 1 ? "。" : "、")
              )
            ])
          })
        ],
        2
      ),
      _c("h3", { staticClass: "title" }, [_vm._v("推荐药膳")]),
      _c("br"),
      _c("h3", { staticClass: "dietName" }, [
        _vm._v(_vm._s(_vm.dietList.name))
      ]),
      _c("br"),
      _c("div", { staticClass: "tea" }, [
        _c("div", { staticClass: "imgBox" }, [
          _c("img", {
            attrs: {
              src: _vm.solutions.asset_prefix + _vm.dietList.asset,
              alt: "药膳图片"
            }
          })
        ])
      ]),
      _c("div", { staticClass: "dietCook" }, [_vm._v(" 烹煮方法： ")]),
      _vm._l(_vm.dietList.content, function(item, index) {
        return _c("div", { key: index, staticClass: "psty" }, [
          _vm._v(" " + _vm._s(item) + " ")
        ])
      }),
      _c(
        "div",
        { staticClass: "tea" },
        [
          _c("h4", [_vm._v("（二）茶饮推荐")]),
          _c("br"),
          _vm._l(_vm.teaList, function(item, index) {
            return _c("div", { key: index }, [
              _c("div", { staticClass: "imgBox" }, [
                _c("img", {
                  attrs: {
                    src: _vm.solutions.asset_prefix + item.asset,
                    alt: "茶饮图片"
                  }
                })
              ]),
              _c("br"),
              _c("header", [_vm._v(_vm._s(item.name))]),
              _c("br"),
              _c("div", { staticClass: "dietMenu psty" }, [
                _vm._v(
                  " 清单：" + _vm._s(item.content && item.content[0]) + " "
                )
              ]),
              item.content[1]
                ? _c("div", { staticClass: "dietCook psty" }, [
                    _vm._v(
                      " 泡煮方法：" +
                        _vm._s(item.content && item.content[1]) +
                        " "
                    )
                  ])
                : _vm._e()
            ])
          })
        ],
        2
      ),
      _c("div", { staticClass: "massagePress" }, [
        _c("h4", [_vm._v("（三）穴位按压")]),
        _c("br"),
        _c("div", { staticClass: "imgBox" }, [
          _c("img", {
            attrs: {
              src: _vm.solutions.asset_prefix + _vm.massageList.asset[0],
              alt: "穴位按压图片"
            }
          })
        ]),
        _c("header", [_vm._v(_vm._s(_vm.massageList.name))]),
        _c("br"),
        _c("div", { staticClass: "psty" }, [
          _vm._v(
            " " +
              _vm._s(_vm.massageList.content && _vm.massageList.content[0]) +
              " "
          )
        ])
      ]),
      _c("div", { staticClass: "daily" }, [
        _c("h4", [_vm._v("（四）起居调养")]),
        _c("p", { staticClass: "psty" }, [
          _vm._v(" " + _vm._s(_vm.DailyList.name) + " ")
        ])
      ]),
      _c("div", { staticClass: "daily" }, [
        _c("h4", [_vm._v("（五）运动调养")]),
        _vm.SportsList.asset
          ? _c("div", { staticClass: "imgBox" }, [
              _c("img", {
                attrs: {
                  src: _vm.solutions.asset_prefix + _vm.SportsList.asset[0],
                  alt: "运动调养图片"
                }
              })
            ])
          : _vm._e(),
        _c("p", { staticClass: "psty" }, [
          _vm._v(" " + _vm._s(_vm.SportsList.name) + " ")
        ])
      ]),
      _vm.symptomsName !== "平和质"
        ? _c(
            "div",
            { staticClass: "daily" },
            [
              _c("h4", [_vm._v("（六）经典药方")]),
              _vm._l(_vm.MedicineList, function(item, index) {
                return _c("div", { key: index, staticClass: "psty" }, [
                  _c("b", [_vm._v("【" + _vm._s(item.name) + "】")]),
                  _c("p", { staticClass: "tea-title" }, [_vm._v("组方：")]),
                  _c("p", { staticClass: "common-text" }, [
                    _vm._v(_vm._s(item.content[0]))
                  ]),
                  _c("p", { staticClass: "tea-title" }, [_vm._v("功效：")]),
                  _c("p", { staticClass: "common-text" }, [
                    _vm._v(_vm._s(item.content[1]))
                  ])
                ])
              })
            ],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }