var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "visceraChart" }, [
    _vm.marker == 10.1
      ? _c("div", { staticClass: "chart-tip" }, [
          _vm._m(0),
          _vm.physical.substring(0, 3) != "平和质"
            ? _c("div", { staticClass: "tip-content" }, [
                _c("div", { staticClass: "line line-two" }),
                _c("span", [_vm._v(_vm._s(_vm.physical))])
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _vm.marker == 10.1
      ? _c("div", {
          style: { width: "830px", height: "430px", marginTop: "-80px" },
          attrs: { id: "viscera" }
        })
      : _c("div", {
          style: { width: "830px", height: "430px" },
          attrs: { id: "viscera" }
        }),
    _c("div", { staticClass: "text" }, [
      _vm._v("提示：" + _vm._s(_vm.physicalViscera.viscera_attention))
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tip-content" }, [
      _c("div", { staticClass: "line" }),
      _c("span", [_vm._v("平和质")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }