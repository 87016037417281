var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.reportData
    ? _c(
        "div",
        { staticClass: "reportchronic" },
        [
          _vm.type !== "personal"
            ? _c("div", {
                staticClass: "close-btn",
                on: { click: _vm.doclose }
              })
            : _vm._e(),
          _c("reportheader", { attrs: { reportData: _vm.reportData } }),
          _c("chronicchart", {
            attrs: {
              symptoms: _vm.reportData.symptoms,
              symptomStatus: _vm.reportData.symptom_status,
              beforeSymptomStatus: _vm.reportData.before_symptom_status,
              typeAttention: _vm.reportData.type_attention
            }
          }),
          _c("indicator", {
            attrs: {
              health_status: _vm.reportData.health_status,
              symptoms: _vm.reportData.symptoms,
              symptomDefinition:
                _vm.reportData.solutions.chronic_disease_definition,
              tip: _vm.reportData.chronic_disease_attention
            }
          }),
          _c("analyze", { attrs: { reportData: _vm.reportData } }),
          _c("div", { staticClass: "tip" }, [
            _vm._v("此报告仅作参考，如有不适请及时就医")
          ]),
          _c("planbtn", {
            attrs: { reportData: _vm.reportData, isChronic: true }
          }),
          _vm.csr !== "SHBAZE" && _vm.homeFootShow != null
            ? _c(
                "div",
                {
                  class: _vm.marker === "10.1" ? "bottom bottom-ten" : "bottom"
                },
                [
                  _vm.csr !== "CHENSHANG"
                    ? _c("img", {
                        class:
                          _vm.homeFootShow === 0 || _vm.homeFootShow === 2
                            ? ""
                            : "tipImg",
                        attrs: {
                          src:
                            _vm.csr === "BJTRTKH"
                              ? _vm.trt
                              : _vm.csr === "SHWYSLSWKJ"
                              ? _vm.wysl
                              : _vm.homeFootShow === 0 || _vm.homeFootShow === 2
                              ? ""
                              : _vm.logo
                        }
                      })
                    : _vm._e(),
                  _c("span", { staticClass: "tipTxt" }, [
                    _vm._v(
                      _vm._s(
                        _vm.csr === "BJTRTKH"
                          ? "中国北京同仁堂（集团）有限责任公司"
                          : _vm.csr === "CHENSHANG"
                          ? "上海辰尚信息科技有限公司"
                          : _vm.csr === "SHWYSLSWKJ"
                          ? "上海伍月石榴生物科技有限公司"
                          : _vm.homeFootShow === 0
                          ? ""
                          : _vm.homeFootShow === 1
                          ? "上海祉云医疗科技有限公司"
                          : _vm.homeFootShow === 2
                          ? _vm.logoName
                          : "上海祉云医疗科技有限公司 | " + _vm.logoName
                      )
                    )
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      )
    : _c("div", { staticClass: "nonechronic" })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }