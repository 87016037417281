var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wxdoctor-list" }, [
    _c("div", { staticClass: "title" }, [_vm._v("推荐中医")]),
    _c(
      "div",
      { staticClass: "listBox" },
      _vm._l(_vm.doctorData, function(item, index) {
        return _c(
          "ul",
          {
            key: index,
            staticClass: "list-content",
            on: {
              click: function($event) {
                return _vm.gotoDetail(item.id)
              }
            }
          },
          [
            _c("img", { attrs: { src: item.head_image, alt: "" } }),
            _c("div", { staticClass: "info" }, [
              _c("div", { staticClass: "name" }, [
                _vm._v(_vm._s(item.name)),
                _c("span", [
                  _vm._v(_vm._s(item.title) + " | " + _vm._s(item.department))
                ])
              ]),
              _c("div", { staticClass: "address" }, [
                _vm._v(_vm._s(item.medical_institution)),
                _c("span")
              ]),
              _c(
                "div",
                { staticClass: "skills" },
                _vm._l(item.skill, function(info, index) {
                  return _c("span", { key: index }, [_vm._v(_vm._s(info.name))])
                }),
                0
              )
            ])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }