<template>
  <div>
    <h4>(四) 节气风险提示</h4><br/>
    <div style="width: 100%;display: flex;justify-content: space-between">
      <span>当前节气：{{report.physical_term[0].term}}</span>
      <span>患病概率：{{report.physical_term[0].illness_probability>30?'较高':report.physical_term[0].illness_probability<10?'低':'中'}}</span>
    </div><br/>
    <div v-if="is_show_solar === 'yes'" id="solarterm" :style="{width: '615px', height: '350px',margin:'0 auto'}"></div>
    <img v-else :src="solar_img_src" alt="生成后的图" :style="{display:'block',width: '615px', height: '350px',margin:'0 auto'}">
    <div class="text">
      <span>提示：{{report.physical_term[0].term_attention}}</span><br/>
      <span>{{report.physical_term[0].term_add_attention}}</span>
    </div><br/>
    <p :style="report.result.substring(0,3)==='平和质'?{ 'margin-top': '20px' } : { 'margin-top': '40px' }">节气养生原则：<span class="textsty">{{report.physical_term[0].term_principle}}</span></p>
    <p>提示：<span class="textsty">{{report.physical_term[0].term_print_attention}}</span>
<!--      <span class="textsty">{{report.physical_term[0].term_add_attention}}</span>-->
    </p><br/>
  </div>
</template>

<script>
  import * as echarts from "echarts";

  export default {
    name: "solartzbs",
    beforeCreate(){
      this.$store.commit('SET_SOLAR_SHOW', 'yes'); // 初始化store里面的数据
    },
    props: {
      report: Object, // faceResult faceUrl tongueResult tongueUrl handResult handUrl
    },
    data(){
      return{
        seriesArr: [],
        pointArr: [],
      }
    },
    computed:{
      is_show_solar(){
        return this.$store.state.is_show_solar
      },
      solar_img_src(){
        return this.$store.state.solar_img_src
      }
    },
    mounted() {
      this.fixData()
    },
    methods:{
      fixData(){
        if(this.report.result.substring(0,3) === '阳虚质'){
          this.seriesArr = [30,29,27,24,20,15,10,9,8,7,6,5,5,7,10,15,20,27,33,38,43,45,43,40]
        } else if(this.report.result.substring(0,3) === '阴虚质'){
          this.seriesArr = [10,12,15,19,24,29,34,40,43,45,43,42,39,36,20,18,16,14,12,10,8,7,6,5]
        } else if(this.report.result.substring(0,3) === '气虚质'){
          this.seriesArr = [35,40,43,45,43,40,41,43,44,45,44,45,40,35,30,28,30,32,37,42,44,45,44,40]
        } else if(this.report.result.substring(0,3) === '气郁质'){
          this.seriesArr = [25,23,20,17,15,10,11,13,15,18,21,24,27,29,30,31,34,37,41,43,44,45,44,40]
        } else if(this.report.result.substring(0,3) === '血瘀质'){
          this.seriesArr = [35,33,31,28,25,22,19,17,16,15,14,10,12,15,19,24,30,35,39,42,43,45,43,40]
        } else if(this.report.result.substring(0,3) === '痰湿质'){
          this.seriesArr = [20,21,23,26,30,34,37,40,43,44,45,44,43,41,38,35,32,29,28,27,26,25,21,20]
        } else if(this.report.result.substring(0,3) === '湿热质'){
          this.seriesArr = [10,12,14,18,24,28,32,36,40,45,45,45,42,39,36,20,19,17,14,13,12,11,10,8]
        } else if(this.report.result.substring(0,3) === '特禀质'){
          this.seriesArr = [35,40,43,45,43,35,37,35,30,25,30,25,20,18,35,45,42,40,38,36,34,33,32,31]
        } else { //平和质
          this.seriesArr = []
        }
        this.pointData()
      },
      pointData(){
        const seasonArr = ['立春', '雨水', '惊蛰', '春分', '清明', '谷雨', '立夏', '小满', '芒种', '夏至', '小暑', '大暑', '立秋', '处暑', '白露', '秋分', '寒露', '霜降', '立冬', '小雪', '大雪', '冬至', '小寒', '大寒']
        const index = seasonArr.findIndex(item =>  item === this.report.physical_term[0].term);
        let commonDic = { name: '', value: `${this.report.physical_term[0].illness_probability}%`, xAxis: index, yAxis:this.report.physical_term[0].illness_probability}
        this.pointArr.push(commonDic)
        // 绘制
        this.drawChart()
      },
      drawChart(){
        const _this = this;
        const myChart = echarts.init(document.getElementById('solarterm'));
        myChart.setOption({
          // legend: {
          //   data: ['平和质', this.report.result.substring(0,3)],
          //   show: true,
          //   right: "1%",
          //   width: "auto",
          //   orient: 'horizontal',
          //   itemWidth: 40,
          //   itemHeight: 4,
          //   textStyle: {
          //     color: "#666666",
          //     fontSize: 18,
          //   }
          // },
          color:['#069eff','#ffad2c'],
          grid: { //图表距边框的距离,可选值：'百分比'¦ {number}（单位px）
            top: '11%',   // 等价于 y: '16%'
            left: '0%',
            right: '2%',
            bottom: '1%',
            containLabel: true
          },
          tooltip: { // 提示框
            trigger: 'axis'
          },
          // toolbox: { //工具框，可以选择
          //   feature: {
          //     saveAsImage: {} //下载工具
          //   }
          // },
          xAxis: {
            type: 'category',
            axisLine: {
              lineStyle: { // 设置x轴颜色
                color: '#2dac6c'
              }
            },
            // 设置X轴数据旋转倾斜
            axisLabel: {
              color: '#999999',
              rotate: 40, // 旋转角度
              interval: 0  //设置X轴数据间隔几个显示一个，为0表示都显示
            },
            boundaryGap: false, // boundaryGap值为false的时候，折线第一个点在y轴上
            data: ['立春', '雨水', '惊蛰', '春分', '清明', '谷雨', '立夏', '小满', '芒种', '夏至', '小暑', '大暑', '立秋', '处暑', '白露', '秋分', '寒露', '霜降', '立冬', '小雪', '大雪', '冬至', '小寒', '大寒']
          },
          yAxis: {
            name: '患病概率',
            type: 'value',
            axisLabel: {
              formatter: '{value}%'
            },
            min:0, // 设置y轴刻度的最小值
            max:50,  // 设置y轴刻度的最大值
            splitLine:{
              lineStyle:{
                color:' #004537'
              }
              // show: false, //去除网格线
            },
            splitNumber: 10,  // 设置y轴刻度间隔个数
            axisLine: {
              lineStyle: { // 设置y轴颜色
                color: '#666666'
              }
            },
          },
          series: [
            {
              name: '平和质',
              data: [10,8,6,5,6,8,10,8,5,8,10,8,5,8,10,8,5,8,10,9,8,7,6,5],
              type: 'line',
              symbol: 'none',
              smooth: 0.5,
              // symbolSize:8, // 设置折线上圆点大小
              itemStyle:{
                normal:{
                  label : { // 拐点上显示数值
                    show: false
                  },
                  // borderColor:'red',  // 拐点边框颜色
                  lineStyle:{
                    width: 2,  // 设置线宽
                    type:'dotted'  //'dotted'虚线 'solid'实线
                  }
                }
              }
            },
            {
              name: this.report.result.substring(0,3),
              data: this.seriesArr,
              type: 'line',
              markPoint: {
                data: this.pointArr
              },
              // 注意：设置symbol: 'none'以后，拐点不存在了，设置拐点上显示数值无效
              symbol: 'none',
              smooth: 0.5, // 设置折线弧度，取值：0-1之间
              // 设置折线上圆点大小
              symbolSize: 0,
              // 设置拐点为实心圆
              // symbol:'circle',
              itemStyle: {
                normal: {
                  // 拐点上显示数值
                  label : {
                    show: false
                  },
                  lineStyle:{
                    // 使用rgba设置折线透明度为0，可以视觉上隐藏折线
                    // color: 'rgba(0,0,0,0)'
                  }
                }
              }
            }
          ],
        });
        window.addEventListener("resize",function () {
          myChart.resize();
        })
        myChart.on('finished',function(){
          let img = myChart.getDataURL();
          _this.$store.commit('SET_SOLAR_IMG', img); // 提交雷达图生成的图片
          _this.$store.commit('SET_SOLAR_SHOW', 'no'); // 隐藏echarts雷达图
        });
      },
    }
  }
</script>

<style scoped>
  .text {
    font-size: 22px;
    color: #ff942c;
    font-weight: bold;
    margin-top: 14px;
    text-align: center;
    line-height: 40px;
    background: #f5f5f5;
  }
  p{
    font-weight: bold;
    text-indent: 32px;
    font-family: 黑体;
    line-height: 30px;
  }
  .textsty{
    font-weight: normal;
  }

</style>
