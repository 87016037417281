<template>
  <div class="attentions">
    <h3>二、{{type_attention}}养生方案</h3><br/>
    <header style="font-weight: bold">根据您最具倾向性的体质推荐以下个性化养生方案。</header>
    <h4>（一）饮食调养</h4><br/>
    <p>1、宜食
      <span>{{foodList.good.content[0]}}</span>
    </p>
    <p v-if="foodList.bad.content.length > 0">2、忌食<span>{{foodList.bad.content[0] || '暂无'}}</span></p>
    <h3 class="title">推荐药膳</h3><br/>
    <div v-for="item in dietList" :key="item.name" >
      <div class="name">【{{ item.name }}】</div>
      <img class="image" :src="`${solutions.asset_prefix}${item.asset[0]}`"/>
      <p class="title2">烹煮方法：</p>
      <p class="title3" v-for="(step,index) in item.content" :key="index">{{ step }}</p>
    </div>

    <div  class="tea" v-if="teaList && teaList.length > 0">
      <h4>（二）茶饮推荐</h4><br/>
      <div v-for="item in teaList" :key="item.name">
        <div class="name">【{{item.name}}】</div>
        <img class="image" :src="report.solutions.asset_prefix + item.asset" alt="茶饮图片" />
        <p v-if="item.content.length > 0" class="tip-one">泡煮方法：</p>
        <p class="content-text">{{item.content[0]}}</p>
        <p v-if="item.content.length > 1" class="tip-one">功效：</p>
        <p class="content-text">{{item.content[1]}}</p>
      </div>
    </div>


    <div class="massagePress">
      <h4>（三）穴位按压</h4><br/>
      <!-- 穴位按压 糖尿病 -->
      <div v-if="report.type_attention === '糖尿病' ">
        <p class="content-text">{{massageList[0].content[0]}}</p>
        <p class="content-text">{{massageList[0].content[1]}}</p>
        <p class="content-text">{{massageList[0].content[2]}}</p>
      </div>
      <!-- 穴位按压 高血压 -->
      <div v-if="report.type_attention === '高血压' ">
        <div v-for="(item, index) in massageList" :key="index" :title="item.name">
          <img class="image" :src="report.solutions.asset_prefix + item.asset[0]" alt="加载失败">
          <p class="tip-one">定位：</p>
          <p class="content-text">{{item.content[0]}}</p>
          <p class="tip-one">按摩方法：</p>
          <p class="content-text">{{item.content[1]}}</p>
        </div>
      </div>

    </div>

    <!--艾灸-->
    <div v-if="moxibustion && report.type_attention === '糖尿病'">
      <h4>（四）艾灸</h4><br/>
      <div v-for="(item, index) in moxibustion" :key="index" :style="{marginBottom: '50px'}">
        <div class="name">【{{ item.name }}】</div>
        <img class="image" :src="report.solutions.asset_prefix + item.asset[0]" alt="加载失败">
        <p class="content-text">{{item.content[0]}}</p>
      </div>
    </div>

    <!--足浴疗法 高血压 糖尿病-->
    <div v-for="(item, index) in footBath" :key="index">
      <h4>（{{bathNum}}）足浴疗法</h4><br/>
      <p class="name" > 【{{ item.name }}】</p>
      <img class="image" :src="report.solutions.asset_prefix + item.asset[0]" alt="加载失败">
      <p class="tip-one">{{report.type_attention === '糖尿病' ? '方药组成：' : '足浴配方：'}}</p>
      <p class="content-text">{{item.content[0]}}</p>
      <p class="tip-one">{{report.type_attention === '糖尿病' ? '适应症：' : '操作：'}}</p>
      <p class="content-text">{{item.content[1]}}</p>
      <p class="tip-one" v-if="report.type_attention === '糖尿病'">用法用量：</p>
      <p class="content-text" v-if="report.type_attention === '糖尿病'">{{item.content[2]}}</p>
    </div>

    <div>
      <h4>（{{mentalNum}}）精神调养</h4>
      <p class="content-text">{{mental.content[0]}}</p>
    </div>

   <!--运动健身-->
    <div v-if="sportHealth && report.type_attention === '高血压'">
      <h4>（六）运动健身</h4>
      <p class="content-text">{{sportHealth.content[0]}}</p>
    </div>

    <!--预防保健操-->
    <div v-if="exercises && report.type_attention === '糖尿病'">
      <h4>（七）预防保健操</h4>
      <p v-for="(item, index) in exercises.content" :key="index" class="content-text">{{item}}</p>
    </div>

    <!-- 经典药方-->
    <div v-if="report.classic_disease.length !== 0">
      <h4>（八）经典药方</h4>
      <div v-if="PrescriptionContent" v-for="(item , index) in PrescriptionContent" :key="index" class="prescription">
        <p class="name">【{{item.name}}】</p>
        <p class="medicinal">药物组成：</p>
        <p class="medicinal-explain">{{item.form}}</p>
        <p class="medicinal">功效：</p>
        <p class="medicinal-explain">{{item.effect}}</p>
      </div>
      <p v-if="PrescriptionContent" class="tip">
        *请在医生、药师指导下购买和服用
      </p>
    </div>


  </div>
</template>

<script>
  export default {
    name: "method",
    methods: {
      num(index){
        const dict = ['四','五','六','七','八'];
        return dict[index];
      },
    },
    props:{
      symptoms: Array,
      solutions: Object,
    },
    data(){
      return{
        report: this.$store.state.report,
        type_attention: this.$store.state.report.type_attention,
        bathNum: '',
        mentalNum: '',
      }
    },
    beforeMount(){
      // const dict = ['四','五','六','七','八'];
      if(this.report.type_attention === '高血压'){
        this.bathNum = this.num(0);
        this.mentalNum = this.num(1)
      }
      if(this.report.type_attention === '糖尿病'){
        this.bathNum = this.num(1);
        this.mentalNum = this.num(2)
      }
    },
    computed:{
      symptomsName(){
        return this.symptoms[0] ? this.symptoms[0].name:'健康';
      },
      foodList(){
        const foodList = {};
        foodList.good = this.solutions['chronic_disease_good_food'] && this.solutions['chronic_disease_good_food'][this.symptomsName]; // 宜食
        foodList.bad = this.solutions['chronic_disease_bad_food'] && this.solutions['chronic_disease_bad_food'][this.symptomsName]; // 忌食
        console.log(foodList)
        return foodList
      },
      dietList(){ // 推荐药膳
        const diet = this.solutions['chronic_disease_diet_therapy'];
        return diet && diet[this.symptomsName]
      },
      teaList(){ // 茶饮养生
        const tea = this.solutions['chronic_disease_tea'];
        return tea && tea[this.symptomsName];
      },
      massageList(){ // 穴位按压
        const massage = this.solutions['chronic_disease_massage'];
        return massage && massage[this.symptomsName]
      },
      moxibustion(){ // 艾灸
        const mox = this.solutions['chronic_disease_moxibustion'];
        return mox && mox[this.symptomsName]
      },
      footBath(){ // 足浴疗法
        const bath =  this.solutions['chronic_disease_foot_bath'];
        return  bath && bath[this.symptomsName]
      },
      mental(){ // 精神调养
        const mental =  this.solutions['chronic_disease_mental_recuperation'];
        return  mental && mental[this.symptomsName]
      },
      exercises(){ // 预防保健操
        const exe = this.solutions['chronic_disease_exercises'];
        return exe && exe[this.symptomsName]
      },
      PrescriptionContent(){ // 经典药方
        return this.report.classic_disease;
      },
      sportHealth(){
        return this.report.type_attention === '高血压' ? this.solutions.chronic_disease_sport_health[this.symptomsName] : null
      },

    },

  }
</script>

<style scoped>
  .attentions {
    margin-top: 50px;
  }

  header{
    text-align: center;
  }
  p{
    text-indent: 2em;
    line-height: 30px;
  }

  h4 {
    margin-top: 20px;
  }
  .psty{
    text-indent: 2em;
    line-height: 30px;
  }
  .title{
    margin-top: 20px;
  }
  .title::before {
    content: '';
    width: 21px;
    height: 21px;
    display: inline-block;
    background-size: 100% 100%;
    vertical-align: middle;
    background-image: url("../../../assets/img/print/sz.png");
  }
  .dietName{
    /*color: rgb(0, 160, 233);*/
    font-size: 16px;
    border-left: 2px solid black;
    padding-left: 6px;
    margin-top: 16px;
    line-height: 17px;
    height: 17px;
  }
  .dietCook {
    font-size: 16px;
    color: rgb(51, 51, 51);
    vertical-align: top;
    line-height: 21px;
    margin-top: 10px;
  }
  .dietCook::before {
    content: '';
    width: 18px;
    height: 21px;
    display: inline-block;
    background-size: 100% 100%;
    vertical-align: middle;
  }
  .dietCook::before {
    background-image: url("../../../assets/img/print/pr.png");
  }

  .tea,
  .massagePress,
  .daily{
    margin-bottom: 20px;
  }

  .tea .imgBox,
  .massagePress .imgBox{
    background-color: rgb(245, 245, 245);
    padding: 13px 0;
  }

  .tea img,
  .massagePress img{
    width: 263px;
    height: auto;
    border-radius: 10px;
    margin: 0 auto;
    display: block;
  }
  .preserveHealth .dietMenu,
  .preserveHealth .dietCook {
    font-size: 16px;
    color: rgb(51, 51, 51);
    vertical-align: top;
    line-height: 21px;
    margin-top: 10px;
  }
  .dietMenu::before,
  .dietCook::before {
    content: '';
    width: 18px;
    height: 21px;
    display: inline-block;
    background-size: 100% 100%;
    vertical-align: middle;
  }
  .dietMenu::before {
    background-image: url("../../../assets/img/print/rwqd.png");
  }

  .image {
    height: 200px;
    width: auto;
    margin: 0 auto;
    display: block;
  }

  .name {
    /*text-align: center;*/
  }

  .tip {
    text-align: right;
  }

</style>
