var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wxhealthyscheme" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("健康方案")]),
      _vm._l(_vm.buttontype, function(item, index) {
        return _c("div", { key: index }, [
          _c("div", { staticClass: "header" }, [
            _c("img", {
              attrs: {
                src: require("../../../assets/img/wx/wx_jkfabt_jcjg.png")
              }
            }),
            _c("span", [_vm._v(_vm._s(item))]),
            _c("img", {
              attrs: {
                src: require("../../../assets/img/wx/wx_jkfabt1_jcjg.png")
              }
            })
          ]),
          item === "饮食调养"
            ? _c(
                "div",
                { staticClass: "zero" },
                [
                  _c("div", { staticClass: "goodfood" }, [
                    _vm._m(0, true),
                    _c(
                      "div",
                      { staticClass: "right" },
                      _vm._l(_vm.goodfood, function(e, i) {
                        return _c("span", { key: i }, [
                          _vm._v(_vm._s(e.name) + " ")
                        ])
                      }),
                      0
                    )
                  ]),
                  _c("div", { staticClass: "goodfood badfood" }, [
                    _vm._m(1, true),
                    _c(
                      "div",
                      { staticClass: "right" },
                      _vm._l(_vm.badfood, function(e, i) {
                        return _c("span", { key: i }, [
                          _vm._v(_vm._s(e.name) + " ")
                        ])
                      }),
                      0
                    )
                  ]),
                  _c(
                    "van-tabs",
                    {
                      attrs: {
                        ellipsis: false,
                        swipeable: "",
                        "title-active-color": "#30c3fa",
                        color: "#30c3fa",
                        "title-inactive-color": "#333333"
                      },
                      model: {
                        value: _vm.active,
                        callback: function($$v) {
                          _vm.active = $$v
                        },
                        expression: "active"
                      }
                    },
                    _vm._l(_vm.dietTherapy, function(item, index) {
                      return _c(
                        "van-tab",
                        { key: index, attrs: { title: item.name } },
                        [
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("配料：")
                          ]),
                          _c(
                            "div",
                            { staticClass: "food-pics" },
                            _vm._l(item.asset, function(items, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  class:
                                    (index + 1) % 4 !== 0
                                      ? "pics pics-4"
                                      : "pics"
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.reportData.solutions.asset_prefix +
                                        items[1],
                                      alt: "加载失败"
                                    }
                                  }),
                                  _c("span", [_vm._v(_vm._s(items[0]))])
                                ]
                              )
                            }),
                            0
                          ),
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("清单：")
                          ]),
                          _c("span", { staticClass: "item-span" }, [
                            _vm._v(_vm._s(item.content[0]))
                          ]),
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("烹煮方法：")
                          ]),
                          _c("span", { staticClass: "item-span" }, [
                            _vm._v(_vm._s(item.content[1]))
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          item === "茶饮养生"
            ? _c("div", [
                _c("img", {
                  staticClass: "commom-img",
                  attrs: {
                    src: _vm.reportData.solutions.asset_prefix + _vm.tea.asset,
                    alt: "加载失败"
                  }
                }),
                _c("div", { staticClass: "item-title" }, [_vm._v("配料：")]),
                _c("span", { staticClass: "item-span" }, [
                  _vm._v(_vm._s(_vm.tea.content && _vm.tea.content[0]))
                ]),
                _c("div", { staticClass: "item-title" }, [
                  _vm._v("泡煮方法：")
                ]),
                _c("span", { staticClass: "item-span" }, [
                  _vm._v(_vm._s(_vm.tea.content && _vm.tea.content[1]))
                ])
              ])
            : _vm._e(),
          item === "经典药方"
            ? _c(
                "div",
                [
                  _c(
                    "van-tabs",
                    {
                      attrs: {
                        ellipsis: false,
                        swipeable: "",
                        "title-active-color": "#30c3fa",
                        color: "#30c3fa",
                        "title-inactive-color": "#333333"
                      },
                      model: {
                        value: _vm.active2,
                        callback: function($$v) {
                          _vm.active2 = $$v
                        },
                        expression: "active2"
                      }
                    },
                    _vm._l(_vm.jdyf, function(item, index) {
                      return _c(
                        "van-tab",
                        { key: index, attrs: { title: item.name } },
                        [
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("药物组成：")
                          ]),
                          _c("span", { staticClass: "item-span" }, [
                            _vm._v(_vm._s(item.content[0]))
                          ]),
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("功效：")
                          ]),
                          _c("span", { staticClass: "item-span" }, [
                            _vm._v(_vm._s(item.content[1]))
                          ]),
                          _vm.csr === "HUASHI" && item.content[2]
                            ? _c("div", { staticClass: "item-title" }, [
                                _vm._v("推荐用药：")
                              ])
                            : _vm._e(),
                          _vm.csr === "HUASHI" && item.content[2]
                            ? _c("span", { staticClass: "item-span" }, [
                                _vm._v(
                                  _vm._s(
                                    item.content[2] ? item.content[2] : ""
                                  ) +
                                    _vm._s(
                                      item.content[3]
                                        ? "、" + item.content[3]
                                        : ""
                                    ) +
                                    _vm._s(
                                      item.content[4]
                                        ? "、" + item.content[4]
                                        : ""
                                    ) +
                                    _vm._s(
                                      item.content[5]
                                        ? "、" + item.content[5]
                                        : ""
                                    )
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          item === "穴位按压"
            ? _c("div", [
                _c("img", {
                  staticClass: "commom-img",
                  attrs: {
                    src:
                      _vm.reportData.solutions.asset_prefix +
                      _vm.acupoint.asset,
                    alt: "加载失败"
                  }
                }),
                _c("div", { staticClass: "item-title" }, [_vm._v("定位：")]),
                _c("span", { staticClass: "item-span" }, [
                  _vm._v(_vm._s(_vm.acupoint.content[0]))
                ]),
                _c("div", { staticClass: "item-title" }, [
                  _vm._v("按摩方法：")
                ]),
                _c("span", { staticClass: "item-span" }, [
                  _vm._v(_vm._s(_vm.acupoint.content[1]))
                ])
              ])
            : _vm._e(),
          item === "中医功法"
            ? _c(
                "div",
                [
                  _vm.poster
                    ? _c("video-player", {
                        ref: "videoPlayer",
                        refInFor: true,
                        staticClass: "video-player vjs-custom-skin",
                        attrs: { playsinline: true, options: _vm.playerOptions }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          item === "音乐养生"
            ? _c("div", { staticClass: "musics" }, [
                _c(
                  "span",
                  {
                    staticClass: "item-span",
                    staticStyle: { "margin-top": "30px" }
                  },
                  [_vm._v(_vm._s(_vm.music.title))]
                ),
                _c("img", {
                  staticClass: "img",
                  attrs: {
                    src:
                      _vm.reportData.solutions.asset_prefix +
                      "/养生音乐/脾虚/渔舟唱晚.jpg"
                  }
                }),
                _vm.music
                  ? _c(
                      "audio",
                      {
                        staticClass: "audiosty",
                        attrs: { controls: "", controlsList: "nodownload" }
                      },
                      [
                        _c("source", {
                          attrs: { src: _vm.music.url, type: "audio/mpeg" }
                        }),
                        _vm._v(" 您的浏览器不支持 音乐播放 标签。 ")
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          item === "起居指导"
            ? _c(
                "div",
                _vm._l(_vm.hg.content, function(item, index) {
                  return _c("span", { key: index, staticClass: "item-span" }, [
                    _vm._v(_vm._s(item))
                  ])
                }),
                0
              )
            : _vm._e(),
          item === "保健原则"
            ? _c("div", [
                _c("div", { staticClass: "item-title" }, [
                  _vm._v(_vm._s(_vm.principle.content[1]) + "：")
                ]),
                _c("span", { staticClass: "item-span" }, [
                  _vm._v(_vm._s(_vm.principle.content[0]))
                ])
              ])
            : _vm._e(),
          item === "居家艾灸"
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v("配合特色中药熏蒸方法，效果更佳")
                  ]),
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v("取穴：" + _vm._s(_vm.xwname.join("、")))
                  ]),
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v("方法：将艾炷点燃，粘贴在相应的穴位上，每穴2-3炷。")
                  ]),
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v("取穴方法：")
                  ]),
                  _vm._l(_vm.xw, function(item, index) {
                    return _c("div", { key: index }, [
                      _c("div", { staticClass: "item-title" }, [
                        _vm._v(_vm._s(item.name) + ":")
                      ]),
                      _c("span", { staticClass: "item-span" }, [
                        _vm._v(_vm._s(item.content[0]))
                      ]),
                      _c("div", { staticClass: "moxibustion" }, [
                        _c("img", {
                          staticClass: "commom-img",
                          attrs: {
                            src:
                              _vm.reportData.solutions.asset_prefix +
                              item.asset[0],
                            alt: "加载失败"
                          }
                        })
                      ])
                    ])
                  })
                ],
                2
              )
            : _vm._e(),
          item === "五行熏蒸"
            ? _c("div", [
                _c("div", { staticClass: "item-title" }, [
                  _vm._v("【熏蒸法简介】")
                ]),
                _c("span", { staticClass: "item-span" }, [
                  _vm._v(
                    "中药熏蒸疗法源远流长，早在《黄帝内经》中就有记载。在唐代孙思邈所著的《千金方》中已经将熏洗疗法广泛地用到内、外、妇、儿、五官、皮肤等各科的疾病治疗和预防。在元代《御药院方》、清代的《慈禧光绪医方选仪》中有大量运用熏蒸药方治疗各类疾病的记录。熏蒸疗法在宫廷中的运用是受到了高度重视。"
                  )
                ]),
                _vm._m(2, true),
                _c("div", { staticClass: "item-title" }, [
                  _vm._v("【熏蒸的作用】")
                ]),
                _vm._m(3, true),
                _c("div", { staticClass: "item-title" }, [
                  _vm._v("【调养原则】")
                ]),
                _c("span", { staticClass: "item-span" }, [
                  _vm._v(
                    _vm._s(_vm.fumigation.name) +
                      "（配合艾灸，效果更佳，方法见居家艾灸）"
                  )
                ]),
                _c("div", { staticClass: "item-title" }, [
                  _vm._v("【五行对应脏腑】】")
                ]),
                _c(
                  "div",
                  { staticClass: "fumigation-content" },
                  _vm._l(_vm.fumigation.content, function(item, index) {
                    return _c("div", { key: index }, [
                      index % 2 !== 0
                        ? _c("img", { attrs: { src: _vm.wximg[item] } })
                        : _vm._e(),
                      index % 2 === 0
                        ? _c("span", { staticClass: "item-span" }, [
                            _vm._v(_vm._s(_vm.fumigation.content[index]))
                          ])
                        : _vm._e()
                    ])
                  }),
                  0
                )
              ])
            : _vm._e()
        ])
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left" }, [
      _c("img", {
        attrs: { src: require("../../../assets/img/wx/wx_ys_jcjg.png") }
      }),
      _c("span", [_vm._v("宜食")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left" }, [
      _c("img", {
        attrs: { src: require("../../../assets/img/wx/wx_js_jcjg.png") }
      }),
      _c("span", { staticStyle: { color: "#f86426" } }, [_vm._v("忌食")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "moxibustion" }, [
      _c("img", {
        staticStyle: { width: "392px" },
        attrs: { src: require("../../../assets/img/report/tu1_jkfa_icon.png") }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "item-span" }, [
      _vm._v(
        "1. 净血排毒：可改善人体新陈代谢，促进血液循环，帮助 排除体内废物及肝肾毒素，对各种水肿有特效。"
      ),
      _c("br"),
      _vm._v(
        " 2. 清毒杀菌：可深入皮下组织，杀菌消毒，清除污垢，帮 助去死皮，使肌肤美白。"
      ),
      _c("br"),
      _vm._v(" 3. 清除疲劳：可使全身放松，缓解压力，心情愉快，恢复 活力。"),
      _c("br"),
      _vm._v(" 4. 活化细胞：可使全身细胞活跃，有效改善体质，增强免 疫能力。"),
      _c("br"),
      _vm._v(" 5. 强化机能：可刺激人体微循环系统，改善人体各种机能。"),
      _c("br"),
      _vm._v(
        " 6. 减肥瘦身：可帮助排汗，消除多余热量，燃烧多余脂肪， 使身体苗条，凹凸有形。"
      ),
      _c("br"),
      _vm._v(" 7. 美容除斑：可调节内分泌，预防妇科病，消除色斑。")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }