<template>
  <div class="container">
    <div class="jkwt">
      <div v-if="health_status" class="cir">
        <img style="width: 245px;margin-top: -10px"
             :src="healthy_status()"
             alt="健康状态"
        >
      </div>
      <div class="HealthInfo">
        <span class="tip">{{tip}}</span>
      </div>
      <span class="symptom" @click="handleOpen" v-for="(item, index) in symptoms" :key="index">
        {{ !item.name.includes('健康') ? item.name : '健康' }}{{ !item.name.includes('健康') && $store.state.report.is_inclination? '(倾向)' : ''}}
      </span>
    </div>
    <div class="explain" v-if="isShowBox && symptomsData.name">
      <div class="explain_box">
        <div v-if="symptomsData">
          <h3>{{symptomsData.name}}</h3>
          <div class="content">
            <div>
              <h4>
                <span>中医解释</span>
              </h4>
              <p>{{symptomsData.content[0]}}</p>
            </div>
            <div>
              <h4>
                <span>可能原因</span>
              </h4>
              <p>{{symptomsData.content[1]}}</p>
            </div>

          </div>
        </div>
        <div v-else>
          暂无相关数据
        </div>
        <p class="closeBtn" @click="handleClose">关 闭</p>

      </div>
    </div>
  </div>

</template>

<script>
  import level1 from '../../../assets/img/chronic/zc_indicator.png';
  import level2 from '../../../assets/img/chronic/jq_indicator.png';
  import level3 from '../../../assets/img/chronic/zd_indicator.png';
  import level4 from '../../../assets/img/chronic/jz_indicator.png';
  import level5 from '../../../assets/img/chronic/yz_indicator.png';

  export default {
    name: "indicator",
    props:{
      health_status:String,
      symptoms:Array, // 症状
      symptomDefinition: Object,
      tip: String

    },
    data(){
      return {
        symptomsData:{},
        isShowBox: false, // 是否展示病症解释
      }
    },
    beforeMount(){


      if(this.symptomDefinition) { // 慢病（糖尿病健康）没有数据
        this.symptomsData = this.symptomDefinition[this.symptoms[0].name];
      }
    },
    mounted() {
      this.healthy_status()
    },
    methods:{
      healthy_status(){
        switch (this.health_status) {
          case '较轻':
            return level2;
            break;
          case '中度':
            return level3;
            break;
          case '较重':
            return level4;
            break;
          case '严重':
            return level5;
            break;
          default:
            return level1;
        }
      },
      handleOpen(){ // 展示具体的病症解释
        this.isShowBox = true;
      },
      handleClose(){
        this.isShowBox = false;
      }
    },
  }
</script>

<style scoped>
  .container{
    width: 94%;
    margin: 40px auto;
    color: #ffffff;
  }
  .jkwt{
    height: 250px;
    width:1000px;
    background:url("../../../assets/img/menopauseovary/jktlbj_cpjg_icon.png");
    background-size:100% 100%;
    padding-top:25px;
    overflow:hidden;
  }
  .cir{
    width:30%;
    height:100%;
    float:left;
    text-align: right;
  }
  .HealthInfo{
    position:relative;
    display:inline-block;
    width:660px;
    margin:15px  0 0 0;
    padding:30px 0 0 25px;
  }
  .tip{
    font-size: 30px;
    color:#00c5fe;
    font-family:SourceHanSansCN-Medium;
    line-height: 42px;
  }
  .symptom{
    display:inline-block;
    width:258px;
    height:75px;
    background:url("../../../assets/img/menopauseovary/zzc_cpjg_icon.png") no-repeat;
    background-size:100% 100%;
    color:#ffffff;
    font-size:30px;
    line-height:71px;
    text-align:center;
    margin:20px 0 0 33px;
  }

  .explain {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 30px;
    background-color: rgba(0,4,8,0.5);
  }

  .explain_box {
    width: 760px;
    height: 940px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    background-image: url("../../../assets/img/chronic/tkbj_jqts_icon.png");
    background-size: 100% 100%;
  }

  .explain_box>div {
    width: 90%;
    margin: 0 auto;
  }

  .explain_box h3 {
    font-size: 48px;
    text-align: center;
    padding: 37px 0;
    width: 85%;
    margin: 0 auto;
    background-size: 100% auto;
    background-image: url("../../../assets/img/chronic/btx_jqts_icon.png");
    background-repeat: no-repeat;
    background-position-y: bottom;
  }

  .content>div {
    margin-top: 80px;
    display: flex;
    align-items: center;
  }

  .content h4 {
    width: 140px;
    height: 140px;
    border: 2px solid #0dd8f0;
    display: inline-block;
    font-size: 36px;
    border-radius: 50%;
    text-align: center;
    font-weight: normal;
  }

  .content span {
    width: 78px;
    margin: 0 auto;
    display: block;
    padding-top: 28px;
  }

  .content p {
    width: calc(100% - 180px);
    margin-left: 40px;
    line-height: 50px;
  }

  .closeBtn {
    width: 140px;
    height: 70px;
    margin: 70px auto 0 auto;
    border-radius: 10px;
    border: solid 2px #00fefb;
    font-size: 30px;
    color: #00fefb;
    text-align: center;
    line-height: 70px;
    cursor: pointer;
  }
</style>
