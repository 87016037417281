var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "knowledge" }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "back-btn", on: { click: _vm.goBack } }, [
        _vm._v("返 回")
      ]),
      _c("div", { staticClass: "header-title" }, [_vm._v("知识库依据")])
    ]),
    _vm.knowledgedata
      ? _c(
          "div",
          { staticClass: "container" },
          [
            _vm._l(_vm.knowledgedata.content, function(item, index) {
              return _c("div", { key: index, staticClass: "text" }, [
                _vm._v(_vm._s(item))
              ])
            }),
            _vm._v(" " + _vm._s(_vm.knowledgedata.bottom) + " ")
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }