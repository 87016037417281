<template>
  <div v-if="marker === '10.1'">
    <div class="tonguereport">
      <div class="face-top" style="margin-top: 80px">
        <span class="title">舌诊结果</span>
        <span class="title-border" style="top: 154px" />
      </div>
      <div class="leftsty" @click="checkfacestate(facestate === 0 ?3:facestate-1)">
        {{facestate === 0?'苔质':facestate === 1?'舌色':facestate === 2?'舌形':facestate === 3?'苔色':''}}
      </div>
      <div class="leftsty2" @click="checkfacestate(facestate === 0 ? 2 : facestate === 1 ? 3 : facestate - 2)">
        {{facestate === 0?'苔色':facestate === 1?'苔质':facestate === 2?'舌色':facestate === 3?'舌形':''}}
      </div>
      <div class="centersty">
        {{facestate === 0?'舌色':facestate === 1?'舌形':facestate === 2?'苔色':facestate === 3?'苔质':''}}
      </div>
      <div class="rightsty" @click="checkfacestate(facestate === 3 ? 0 : facestate + 1)">
        {{facestate === 0?'舌形':facestate === 1?'苔色':facestate === 2?'苔质':facestate === 3?'舌色':''}}
      </div>
      <div class="resultL">
        <div class="imgsty" style="border-bottom: none;height: 352px">
          <img src="../../assets/img/menopauseovary/sxjg.gif" style="height: 314px;margin-top: 25px" alt="面诊图片">
        </div>
        <div class="result-content">
          <div class="imgstyL">
            <div v-if="facestate === 0" class="tongue-ss" style="width: 100%;border: none">
              <div style="height: 100%;width: 96%;margin-left: 2%">
                <div style="height: 39%;margin-top: 10px;">
                  <div class="circular1"><span style="text-align: center;font-size: 34px;line-height: 112px;">{{tonguereport.nature_L}}</span></div>
                  <div class="circular2"><span style="text-align: center;font-size: 34px;line-height: 112px;">{{tonguereport.nature_a}}</span></div>
                  <div class="circular3"><span style="text-align: center;font-size: 34px;line-height: 112px;">{{tonguereport.nature_b}}</span></div>
                </div>
                <div style="margin-top: 6px">
                  <span class="spansty">L值</span>
                  <span class="spansty">A值</span>
                  <span class="spansty">B值</span>
                </div>
                <div style="margin-top: 20px">
                  <span class="spaninfo">L值越大舌色越鲜亮，a值越大越淡，b值越大越紫暗</span>
                </div>
                <div style="margin-top: 20px;text-align: left">
                  <span class="span-tip">结果：{{tonguereport.tongue_nature_color_info.name}}</span><br />
                  <span class="span-tip">提示：{{tonguereport.tongue_nature_color_info.content[0] === '正常' ? '' : '常见于'}}{{tonguereport.tongue_nature_color_info.content[0]}}</span>
                </div>
              </div>
            </div>
            <div v-if="facestate === 1" class="tongue-sx" style="width: 100%;border: none">
              <div style="height: 100%;width: 96%;margin-left: 2%;margin-top: 25px">
                <div style="margin-top: 0;text-align: center;font-size: 36px;">
                  {{tonguereport.tongueFatThin === 0?tonguereport.tongueCrack === 0? '正常' : '有裂痕':tonguereport.tongueCrack === 0?'舌胖' : '舌胖、有裂痕' }}
                </div>
                <div style="margin-top: 0;text-align: left">
                  <span class="span-tip" style="vertical-align: top">结果：</span><span class="span-tip" style="margin-left: 0;width: 600px;">{{tonguereport.tongue_fat_thin_info.name}}，{{tonguereport.tongue_crack_info.name}}</span><br />
                  <span class="span-tip">提示：{{tonguereport.tongue_fat_thin_info.content[0] === '正常' ? '' : '常见于'}}{{tonguereport.tongueFatThin === 1 ? tonguereport.tongue_fat_thin_info.content[0] : ''}}{{tonguereport.tongue_crack_info.content[0]}}</span>
                </div>
              </div>
            </div>
            <div v-if="facestate === 2" class="tongue-ts" style="width: 100%;border: none">
              <div style="height: 100%;width: 96%;margin-left: 2%">
                <div style="height: 39%;margin-top: 10px">
                  <div class="circular1"><span style="text-align: center;font-size: 34px;line-height: 112px;">{{tonguereport.coat_L}}</span></div>
                  <div class="circular2"><span style="text-align: center;font-size: 34px;line-height: 112px;">{{tonguereport.coat_a}}</span></div>
                  <div class="circular3"><span style="text-align: center;font-size: 34px;line-height: 112px;">{{tonguereport.coat_b}}</span></div>
                </div>
                <div style="margin-top: 9px">
                  <span class="spansty">L值</span>
                  <span class="spansty">A值</span>
                  <span class="spansty">B值</span>
                </div>
                <div style="margin-top: 20px">
                  <span class="spaninfo">L值越小苔色越暗，a值越大越白，b值越大越黄</span>
                </div>
                <div style="margin-top: 18px;text-align: left">
                  <span class="span-tip">结果：{{tonguereport.tongue_coat_color_info.name}}</span><br />
                  <span class="span-tip">提示：{{tonguereport.tongue_coat_color_info.content[0] === '正常' ? '' : '常见于'}}{{tonguereport.tongue_coat_color_info.content[0]}}</span>
                </div>
              </div>
            </div>
            <div v-if="facestate === 3" class="tongue-tz" style="width: 100%;border: none">
              <div style="height: 100%;width: 96%;margin-left: 2%;margin-top: 25px">
                <div style="margin-top: 15px;text-align: center;font-size: 36px">
                  {{tonguereport.tongue_coat_thickness_info.symptom}}
                </div>
                <div style="margin-top: 19px;text-align: left">
                  <span class="span-tip">结果：{{tonguereport.tongue_coat_thickness_info.name}}</span><br />
                  <span class="span-tip">提示：{{tonguereport.tongue_coat_thickness_info.content[0] === '正常' ? '' : '常见于'}}{{tonguereport.tongue_coat_thickness_info.content[0]}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="back" @click="returnto" style="margin-top: 38px"></div>
      </div>
      <div class="tongue-lab" @click="ReturnToLab">
        <i>Lab颜色空间及相关数值意义</i>
        <span style="color: #006A95;float: right;margin-right: 15px">点击了解一下</span>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="tonguereport" :style="access === 'web' ? { height: '1920px' } : { height: '100%' }">
      <TongueDetail v-if="tonguereport" :isOnlyTongue="!isFromReportHome"></TongueDetail>
    </div>
  </div>

</template>

<script>
  import { gettongueReport } from '@/api/report.js';
  import { queryObject } from '../../../utils/common.js';
  const { access = '',marker = '' } = queryObject();
  import TongueDetail from './components/tonguedetail.vue';

  export default {
    name: "tonguereport",
    data(){
      return {
        tonguereport: null,
        report:'',
        id:'',
        facestate:0,
        isFromReportHome: this.$route.params.isFromReportHome
      }
    },
    beforeMount(){
      // 如果是从常规报告页面进来，直接从store里面取数据
      const { report } = this.$store.state;
      const { id } = this.$route.params;
      if(report && report.id && report.id === id) {
        this.tonguereport = report.tongue_result;
        this.report = report;
        this.id = report.id
      } else {
        // 如果是单独面诊版块，请求接口，获取数据
        this.getData();
      }
    },
    methods:{
      getData(){ // 单独舌诊，请求数据
        gettongueReport({
          id: this.$route.params.id
        }).then(res => {
          if(res && res.status_code === 10000 && res.data) {
            this.tonguereport = res.data;
            this.$store.commit('SET_REPORT', res.data);
          }
        })
      },

      returnto(){
        this.$router.back();
      },
      ReturnToLab(){
        this.$router.push({
          name:'Labdetail'
        })
      },
      checkfacestate(val){
        this.facestate = val
      },

    },
    computed: {
      access(){
        return access
      },
      marker(){
        return marker
      }
    },

    components: {
      TongueDetail
    }
  }
</script>

<style scoped>
  .tonguereport{
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    overflow:hidden;
    background:url("../../assets/img/report/bj_sznr_icon.png");
  }
  .tongue-top{
    width: 80%;
    display: block;
    height: 110px;
    margin: 10px 0 0 0;
  }
  .title{
    font-size: 60px;
    color:#00fefb;
    font-family:TITLE;
    margin-left:60px;
    line-height: 90px;
  }
  .title-border{
    position:absolute;
    left:0;
    top:90px;
    display:block;
    width:1000px;
    height:16px;
    background:url("../../assets/img/report/xian_jcjg.png") no-repeat;
  }
  .result{
    height:1530px;
    width: 100%;
    color:#FFFFFF;
    font-size:24px;
    background:url("../../assets/img/report/szjg_icon.png") no-repeat;
    background-position-x:60px;
    display:inline-block;
    text-align:center;
    vertical-align:top;
    padding-top:77px;
  }
  .imgsty{
    height:315px;
    width: 790px;
    margin: auto;
    left:0;
    right:0;
    border-bottom:2px solid #00ECFC;
  }
  .tongue-ss{
    height:305px;
    width: 790px;
    margin: auto;
    left:0;
    right:0;
    border-bottom:2px solid #00ECFC;
  }
  .circular1{
    display:inline-block;
    width:33%;
    background:url("../../assets/img/common/Lz_mzjg_icon.png") no-repeat;
    background-position-x:center;
    height:100%;
  }
  .circular2{
    display:inline-block;
    width:33%;
    background:url("../../assets/img/common/Az_mzjg_icon.png") no-repeat;
    background-position-x:center;
    height:100%;
  }
  .circular3{
    display:inline-block;
    width:33%;
    background:url("../../assets/img/common/Bz_mzjg_icon.png") no-repeat;
    background-position-x:center;
    height:100%;
  }
  .spansty{
    width:33%;
    display:inline-block;
    text-align:center;
    color:white;
    font-size:20px;
  }
  .spaninfo{
    text-align:center;
    color:#4BB9FF;
    font-size:20px;
  }
  .span-tip{
    color:#FFFFFF;
    font-size:27px;
    line-height:46px;
    margin-left:30px;
  }
  .tongue-sx{
    height:200px;
    width: 790px;
    margin: auto;
    left:0;
    right:0;
    border-bottom:2px solid #00ECFC;
  }
  .tongue-ts{
    height:297px;
    width: 790px;
    margin: auto;
    left:0;
    right:0px;
    border-bottom:2px solid #00ECFC;
  }
  .tongue-tz{
    height:185px;
    width: 790px;
    margin: auto;
    left:0;
    right:0px;
  }
  .back{
    height:67px;
    width: 224px;
    background:url("../../assets/img/report/fh_srsj_Icon.png") no-repeat;
    margin: auto;
    left:0;
    right:0;
    margin-top:12px;
  }
  .tongue-lab{
    height:45px;
    width: 67%;
    color:#00F0FF;
    font-size:36px;
    border-bottom:3px solid  #00ECFC;
    margin:10px 0 0 160px;
  }
  .resultL{
    height:1230px;
    width: 100%;
    color:#FFFFFF;
    font-size:24px;
    background:url("../../assets/img/report/bj_mzjg_icon10.png") no-repeat;
    background-position-x:60px;
    display:inline-block;
    text-align:center;
    vertical-align:top;
    padding-top:77px;
    margin-top: 20px;
  }
  .result-content{
    overflow:hidden;
    height:638px;
    width: 740px;
    margin: 0 auto;
    left:0;
    right:0px;
  }
  .imgstyL{
    position:relative;
    height:355px;
    width: 740px;
    background:url("../../assets/img/report/s.gif") no-repeat;
    background-size:100% 100%;
    margin: auto;
    left:0;
    right:0px;
    top:-36px;
    padding-top: 312px;
  }
  .leftsty{
    position:absolute;
    left:318px;
    top:662px;
    display:block;
    width:85px;
    height:85px;
    font-size:18px;
    color:#3280bb;
    text-align:center;
    font-family:SourceHanSansCN-Medium;
    padding-top:30px;
    z-index:9;
  }
  .leftsty2{
    position:absolute;
    left:547px;
    top:645px;
    display:block;
    width:85px;
    height:85px;
    font-size:18px;
    color:#3280bb;
    text-align:center;
    font-family:SourceHanSansCN-Medium;
    padding-top:30px;
    z-index:9;
  }
  .centersty{
    position:absolute;
    left:464px;
    top:715px;
    display:block;
    width:150px;
    height:150px;
    font-size:48px;
    color:white;
    text-align:center;
    font-family:SourceHanSansCN-Medium;
    padding-top:46px;
    z-index:9;
  }
  .rightsty{
    position:absolute;
    left:691px;
    top:708px;
    width:85px;
    height:85px;
    font-size:18px;
    color:#3280bb;
    text-align:center;
    font-family:SourceHanSansCN-Medium;
    padding-top:28px;
    z-index:9;
  }

</style>
