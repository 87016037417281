<template>
  <div v-if="reportData" class="wxtzbs">
    <wxtzheader
    :result="reportData.result"
    :attention="reportData.physical_attention"
    :displayId="reportData.display_id"
    :time="reportData.time"
    :healthScore="reportData.health_score"
    ></wxtzheader>
    <div class="content">
      <wxinfo :info="reportData.user_info" :owner="reportData.owner"></wxinfo>
      <!-- 体质解析 -->
      <wxtzparsing :parsing="reportData.solutions.tizhi_definition" :result="reportData.result"></wxtzparsing>
      <!-- 健康风险提示 -->
      <wxhealthytip :risk="reportData.solutions.tizhi_health_risk" :result="reportData.result"></wxhealthytip>
      <!-- 脏腑风险 -->
      <wxviscerachart :viscera="reportData.physical_viscera[0]" :result="reportData.result"></wxviscerachart>
      <!-- 节气风险 -->
      <wxtermschart :terms="reportData.physical_term[0]" :result="reportData.result"></wxtermschart>
      <!-- 面诊结果 -->
      <wxfaceresult :faceData="reportData.face_result" :facePhotoUrl="reportData.face_photo_url"></wxfaceresult>
      <!-- 舌诊结果 -->
      <wxtongueresult :tongueData="reportData.tongue_result" :tonguePhotoUrl="reportData.tongue_photo_url"></wxtongueresult>
      <!-- 脉诊 -->
      <wxpulseresult v-if="reportData.pulse.length !== 0" :pulse="reportData.pulse"></wxpulseresult>
      <!-- 血压 -->
      <wxbloodpressure v-if="reportData.pulse.length !== 0 && reportData.pulse[0].blood_dia !== 0 && reportData.pulse[0].blood_sys !== 0" :pulse="reportData.pulse"></wxbloodpressure>
      <!-- 历史得分记录 -->
      <wxtzscoreschart
      :result="reportData.result"
      :historyReports="reportData.history_reports"
      :attention="reportData.physical_attention"
      ></wxtzscoreschart>
      <!-- 养生方案 -->
      <wxhealthplan :reportData="reportData"></wxhealthplan>
      <!--  -->
      <div class="tip">此报告仅作参考，如有不适请及时就医</div>
      <!-- 推荐中医 -->
      <wxdoctorlist v-if="reportData.doctor.length !== 0" :doctorData="reportData.doctor"></wxdoctorlist>
      <!-- 产品推荐 -->
      <wxproduct v-if="reportData.product.length !== 0" :productData="reportData.product"></wxproduct>
      <div class="tip"></div>
    </div>
  </div>
</template>

<script>
import { getReport } from '@/api/report.js';
import wxtzheader from './components/wxtzheader.vue';
import Wxinfo from './components/wxinfo.vue';
import Wxtzparsing from './components/wxtzparsing.vue';
import Wxhealthytip from './components/wxhealthytip.vue';
import Wxviscerachart from './components/wxviscerachart.vue';
import Wxtermschart from './components/wxtermschart.vue';
import Wxfaceresult from './components/wxfaceresult.vue';
import Wxtongueresult from './components/wxtongueresult.vue';
import Wxpulseresult from './components/wxpulseresult.vue';
import Wxbloodpressure from './components/wxbloodpressure.vue';
import Wxhealthplan from './components/wxhealthplan.vue';
import Wxproduct from './components/wxproduct.vue';
import Wxtzscoreschart from './components/wxtzscoreschart.vue';
import Wxdoctorlist from './components/wxdoctorlist.vue';

export default {
  components: { wxtzheader, Wxinfo, Wxtzparsing, Wxhealthytip, Wxviscerachart, Wxtermschart, Wxfaceresult, Wxtongueresult, Wxpulseresult, Wxbloodpressure,
    Wxhealthplan,
    Wxproduct,
    Wxtzscoreschart,
    Wxdoctorlist },
  data(){
    return {
      reportData: null, 
    }
  },
  created(){
    this.getData();
  },
  methods: {
    getData(){
      getReport({
        id: this.$route.params.id
      }).then(res => {
        if(res && res.status_code === 10000 && res.data) {
          console.log(res.data)
          this.reportData = res.data;
          this.$store.commit('SET_REPORT', res.data)
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.wxtzbs {
  width: 100%;
  background-color: #f5f5f5;
  .content {
    margin: -45px 45px 45px 45px;
  }
  .tip {
    width: 100%;
    text-align: center;
    font-family: MicrosoftYaHei;
	  font-size: 36px;
    color: #cccccc;
    padding: 42px 0;
  }
}
</style>