<template>
  <div class="risk">
    <img :src="imgSrc" alt="疾病风险" />
  </div>
</template>

<script>
  import jiankang from '../../../assets/img/print/jk.png';
  import pixu from '../../../assets/img/print/px.png';
  import qixu from '../../../assets/img/print/qixu_print.png'
  import shenxu1 from '../../../assets/img/print/sx_nan.png'
  import shenxu2 from '../../../assets/img/print/sx_nv.png'
  import tanshi from '../../../assets/img/print/ts.png'
  import yangxu1 from '../../../assets/img/print/yx_nan.png'
  import yangxu2 from '../../../assets/img/print/yx_nv.png'
  import yinxu from '../../../assets/img/print/yinxu_print.png'
  import yuzhi1 from '../../../assets/img/print/zy_nan.png'
  import yuzhi2 from '../../../assets/img/print/zy_nv.png'


  export default {
    name: "risk",
    props: {
      symptoms: Array,
      owner: Object,
    },
    data(){
      return {
        jbfxnan : {
          脾虚: pixu,
          气虚: qixu,
          肾虚: shenxu1,
          痰湿: tanshi,
          阳虚: yangxu1,
          阴虚: yinxu,
          郁滞: yuzhi1,
          健康: jiankang,
        },
        jbfxnv: {
          脾虚: pixu,
          气虚: qixu,
          肾虚: shenxu2,
          痰湿: tanshi,
          阳虚: yangxu2,
          阴虚: yinxu,
          郁滞: yuzhi2,
          健康: jiankang,
        }
      }
    },
    computed: {
      imgSrc(){
        const gender = this.owner ? this.owner.gender : 1 ;
        const symptomName = this.symptoms[0] ? this.symptoms[0].name:'健康';
        return gender === 1 ? this.jbfxnan[symptomName] : this.jbfxnv[symptomName];
      }
    }
  }
</script>

<style scoped>
  .risk img {
    width: 100%;
    margin: 0 auto;
    display: block;
  }
</style>
