<template>
  <div class="content-bg">
    <!--  -->
    <div class="header">
      <div class="back-btn" @click="goBack">返&nbsp;回</div>
      <div class="header-title">养生方案</div>
    </div>
    <!-- header -->
    <header-tab :buttonType=buttonType @changed="changedIndex"></header-tab>
    <!-- 饮食调养 -->
    <header-tab-one v-if="currentIndex === 0"></header-tab-one>
    <!-- 茶饮养生 -->
    <header-tab-two v-if="currentIndex === 1"
    :Img="teaImg"
    :SymptomsName="symptomsName"
    :TextContent="tea"
    ></header-tab-two>
    <!-- 香囊 -->
    <header-tab-two v-if="currentIndex === 2" :XnContent="xnContent" filedName="XnContent"></header-tab-two>
    <!--  经典药方 -->
    <header-tab-two v-if="(currentIndex === 3 && symptomsName !== '平和质')" filedName="PrescriptionContent"
    :PrescriptionContent="yf"
    ></header-tab-two>
    <!--  穴位按压 -->
<!--    <header-tab-two v-if="currentIndex === 3"-->
<!--    :AcupointVoide="xwVoide"-->
<!--    :AcupointVoideImg="xwVoideImg"-->
<!--    :AcupointContent="xw"-->
<!--    ></header-tab-two>-->
    <header-tab-three v-if="currentIndex === 4"></header-tab-three>
    <!-- 精神调养 8 -->
    <header-tab-two v-if="currentIndex === 5"
    TitleName="精神调养"
    :Detail="factorDetail"
    ></header-tab-two>
    <!-- 起居调摄 -->
    <header-tab-two v-if="currentIndex === 6"
    TitleName="起居调摄"
    :Detail="dailyDetail"
    ></header-tab-two>
    <!--  运动保健 -->
    <header-tab-two v-if="currentIndex === 7"
    TitleName="运动保健"
    :Detail="sportDetail"
    :playerOptions="playerOptions"
    ></header-tab-two>
    <header-tab-two v-if="currentIndex === 8"
    :isProduct="true"
    ></header-tab-two>
  </div>
</template>

<script>
import HeaderTab from '@/components/physical/headertab.vue';
import HeaderTabOne from '@/components/physical/headertabone.vue';
import HeaderTabTwo from '@/components/physical/headertabtwo.vue';
import HeaderTabThree from '@/components/physical/headertabthree.vue';

import ysyj2 from '../../assets/img/common/ysty2_ysfa_icon.png';
import cyys2 from '../../assets/img/common/cyys2_jkfa_icon.png';
import xn from '../../assets/img/common/xn_jkfa_icon.png';
import xway2 from '../../assets/img/common/xway2_ysfa_icon.png';
import jdyf2 from '../../assets/img/common/jdyf2_ysfa_icon.png';
import zygf2 from '../../assets/img/common/zygf2_ysfa_icon.png';
import yyys2 from '../../assets/img/common/yyys2_ysfa_icon.png';
import qjzd2 from '../../assets/img/common/qjzd2_ysfa_icon.png';
import bjyz2 from '../../assets/img/common/bjyz2_ysfa_icon.png';
import QZTC from '../../assets/img/common/QZTC.png';
import QJTS from '../../assets/img/common/QJTS.png';
import YDBJ from '../../assets/img/common/YDBJ.png';
import TBTJ from '../../assets/img/common/tbtj_jkfa_icon.png';

import { queryObject } from '../../../utils/common.js';
const { csr = '', marker = '' } = queryObject();

export default {
  components: {
    HeaderTab,
    HeaderTabOne,
    HeaderTabTwo,
    HeaderTabThree
  },
  data() {
    return {
      currentIndex: 0,
      buttontype: [ //0, 1, 3, 8, 9, 10 平和质没有经典药方
        {title: '饮食调养', img: ysyj2 ,id:0,},
        {title: '茶饮养生', img: cyys2,id:1,},
        {title: '香囊', img: xn,id:2,},
        // {title: '经典药方', img: jdyf2,id:2,},
        {title: '穴位按压', img: xway2,id:4,},
        // {title: '中医功法', img: zygf2},
        // {title: '音乐养生', img: yyys2},
        // {title: '起居指导', img: qjzd2},
        // {title: '保健原则', img: bjyz2},
        {title: '精神调养', img: QZTC,id:5,},
        {title: '起居调摄', img: QJTS,id:6,},
        {title: '运动保健', img: YDBJ,id:7,},
      ],
      buttontypes: [ //0, 1, 3, 8, 9, 10 非平和质有经典药方
        {title: '饮食调养', img: ysyj2 ,id:0,},
        {title: '茶饮养生', img: cyys2,id:1,},
        {title: '香囊', img: xn,id:2,},
        {title: '经典药方', img: jdyf2,id:3,},
        {title: '穴位按压', img: xway2,id:4,},
        // {title: '中医功法', img: zygf2},
        // {title: '音乐养生', img: yyys2},
        // {title: '起居指导', img: qjzd2},
        // {title: '保健原则', img: bjyz2},
        {title: '精神调养', img: QZTC,id:5,},
        {title: '起居调摄', img: QJTS,id:6,},
        {title: '运动保健', img: YDBJ,id:7,},
      ],
      symptomsName: null,
      tea: null,
      teaImg: null,

      xnContent: null, // 香囊数据

      xw: null,
      xwVoide: null,
      xwVoideImg:null,

      factorDetail: null,

      dailyDetail: null,
      sportDetail: null,

      playerOptions:{  // 功法视频相关内容
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        autoplay: false,
        muted: false,
        loop: false,
        preload: 'auto',
        language: 'zh-CN',
        aspectRatio: '16:9',
        fluid: true,
        sources: [{
          type: "video/mp4",
          src: '',
        }],
        poster: '',
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true
        }
      },

      yf:null,
      isShowCPTJ: this.$store.state.report.vendor_setting.product_advice_show === 1 &&
        ((this.$store.state.report.product && this.$store.state.report.product.length > 0) || (this.$store.state.report.doctor && this.$store.state.report.doctor.length > 0))
    }
  },
  beforeMount(){
    const symptoms = this.$store.state.report.symptoms;
    const solutions = this.$store.state.report.solutions;
    this.symptomsName = symptoms[0].name;
    this.tea = solutions.tizhi_tea[this.symptomsName];
    // this.teaImg = `${solutions.asset_prefix}${this.tea.asset}`
    // 香囊
    this.xnContent = solutions.tizhi_perfume_bag[this.symptomsName] // 香囊

    // 穴位按压
    this.xw = solutions.tizhi_massage[this.symptomsName]
    this.xwVoide = `${solutions.asset_prefix}${this.xw.asset[1]}`
    this.xwVoideImg = `${solutions.asset_prefix}${this.xw.asset[0]}`
    // 精神调养
    this.factorDetail = solutions.tizhi_modern_factors[this.symptomsName]

    this.dailyDetail = solutions.tizhi_daily_life[this.symptomsName]

    this.sportDetail = solutions.tizhi_sport_health[this.symptomsName]

    this.yf = solutions.tizhi_classic_prescription[this.symptomsName]
    // console.log(this.xwVoideImg,this.xwVoide)

    // 运动保健下的，中医功法视频(待开发：此处取数据的字段待定)
    const { csr } = this;
    const gf = solutions.tizhi_video && solutions.tizhi_video[this.symptomsName];
    //asset格式 ["功法/痰湿1.mp4", "功法/痰湿1.jpg"]
    const url = gf && gf.asset && gf.asset[0].split('/')[1]; // 播放url
    const image = gf && gf.asset && gf.asset[1].split('/')[1]; // 封面
    const baseUrl = solutions.asset_prefix; // 图片存储库的基础地址
    let videoSrc = ''; // 播放链接
    let videoPoster = ''; // 播放封面
    if(csr=== 'CHENSHANG' || csr === 'SHWYSLSWKJ' || csr === 'SXSZYYY') {
      videoSrc = baseUrl+'功法无水印' + url;
      videoPoster = baseUrl +'功法无水印'+ image;
    } else {
      videoSrc = gf && (baseUrl + gf.asset[0]);
      videoPoster = gf && (baseUrl + gf.asset[1]);
    }

    this.playerOptions.sources[0].src = videoSrc;
    this.playerOptions.poster = videoPoster;

  },
  methods: {
    goBack(){
      this.$router.back()
    },
    changedIndex(index,e){
      this.currentIndex = index
    }
  },
  computed:{
    buttonType(){
      if(this.symptomsName !== '平和质') {
        if(this.isShowCPTJ) {
          return [{ title: '特别推荐', img: TBTJ, id:8 }, ...this.buttontypes ]
        }
        return this.buttontypes
      } else {
        if(this.isShowCPTJ) {
          return [{ title: '特别推荐', img: TBTJ, id:8 }, ...this.buttontype ]
        }
        return this.buttontype
      }
    },
    marker(){
      return marker
    },
    csr(){
      return csr
    }
  }
}
</script>

<style lang="scss" scoped>
$bgImg: '../../assets/img/common/bj.png';
$backBtn: '../../assets/img/physical/fh_ty_icon.png';
$headerTitle: '../../assets/img/report/btbj_icon.png';

.content-bg {
  width:100%;
  min-height: 100%;
  background:url($bgImg);
  background-size:cover;
  overflow: hidden;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  margin-top: 90px;
  margin-left: 5%;
  .back-btn {
    background:url($backBtn) no-repeat;
    background-size:100% 100%;
    width: 152px;
    height: 82px;
    color:#fff;
    font-size:36px;
    text-align:center;
    line-height:75px;
  }
  .header-title {
    font-size: 60px;
    color:#00fefb;
    font-family:TITLE;
    width:330px;
    height:120px;
    margin-left: 18%;
    background:url($headerTitle) no-repeat;
    text-align:center;
    line-height:120px;
    background-size:100% 100%;
  }
}

</style>
