<template>
  <div>
    <header>
      <h3>Lab颜色空间及相关数值意义</h3>
      <p>{{titleDesc}}</p>
    </header>
<!--    <div style="page-break-after:always;"></div>-->
    <faceResult :result="result.faceResult" :url="result.faceUrl"></faceResult>
<!--    <div style="page-break-after:always;"></div>-->
    <tongueResult :result="result.tongueResult" :url="result.tongueUrl"></tongueResult>
  </div>
</template>

<script>
  import faceResult from "./faceresult";
  import tongueResult from "./tongueresult";

  export default {
    name: "analysis",
    props: {
      result: Object, // faceResult faceUrl tongueResult tongueUrl handResult handUrl
    },
    computed: {
      titleDesc(){
        return this.result.faceResult && this.result.faceResult.lab_define
      }
    },
    components: {
      faceResult,
      tongueResult
    }
  }
</script>

<style scoped>

  header h3 {
    font-size: 20px;
    color:#ffffff;
    width:300px;
    height:40px;
    text-align:center;
    line-height:40px;
    background-color:#00A2FF;
    border-radius:20px;
    margin: 0 auto;
  }

  header p {
    margin-top:16px;
    color:#333333;
    font-size:16px;
    line-height:33px;
    letter-spacing:1px;
    text-indent: 2em;
  }


</style>
