<template>
  <div class="healthtip">
    <div class="tip-content">
        <img class="jbfximg" :src="reportData.health_status === '亚健康'?ibfx:jiankang">
      <div class="body">
        <div class="line">
          <div class="line-left">
            <div v-for="(item, index) in leftRisk" :key="index"
             :class="item.content[0].includes('头部') ? 'leftc head' :
             item.content[0].includes('眼部') ? 'leftc face' :
             item.content[0].includes('口腔') ? 'leftc oral' :
             item.content[0].includes('全身') ? 'leftc chest' :
             item.content[0].includes('肝胆胰') ? 'leftc lung' :
             item.content[0].includes('肾脏') ? 'leftc darling' :
             item.content[0].includes('下半部') ? 'leftc upperarm' :'leftc' " >
              <span v-if="item.content[0].includes('头部')" style="text-align: right">{{headone.join('、')}}</span>
              <span v-if="item.content[0].includes('眼部')" style="text-align: right">{{eyes.join('、')}}</span>
              <span v-if="item.content[0].includes('口腔')" style="text-align: right">{{oral.join('、')}}</span>
              <span v-if="item.content[0].includes('全身')" style="text-align: right">{{allbody.join('、')}}</span>
              <span v-if="item.content[0].includes('肝胆胰')" style="text-align: right">{{liver.join('、')}}</span>
              <span v-if="item.content[0].includes('肾脏')" style="text-align: right">{{kidney.join('、')}}</span>
              <span v-if="item.content[0].includes('下半部')" style="text-align: right">{{lower.join('、')}}</span>
              <img :src="lineLeft" alt="人体线图"/>
            </div>
          </div>
          <div class="line-right">
            <div v-for="(item, index) in rightRisk" :key="index"
             :class="item.content[0].includes('头部') ? 'rightc head' :
             item.content[0].includes('耳部') ? 'rightc heart' :
             item.content[0].includes('鼻腔') ? 'rightc nose' :
             item.content[0].includes('心脏') ? 'rightc face' :
             item.content[0].includes('胃部') ? 'rightc oral' :
             item.content[0].includes('腹部') ? 'rightc throat' :
             item.content[0].includes('腰部') ? 'rightc chest' :
             item.content[0].includes('骨') ? 'rightc upperarm' : 'rightc'">
              <img :src="lineRight" alt="人体线图"/>
              <span v-if="item.content[0].includes('头部')" style="text-align: left">{{headtwo.join('、')}}</span>
              <span v-if="item.content[0].includes('耳部')" style="text-align: left">{{ear.join('、')}}</span>
              <span v-if="item.content[0].includes('鼻腔')" style="text-align: left">{{nasal.join('、')}}</span>
              <span v-if="item.content[0].includes('心脏')" style="text-align: left">{{heart.join('、')}}</span>
              <span v-if="item.content[0].includes('胃部')" style="text-align: left">{{stomach.join('、')}}</span>
              <span v-if="item.content[0].includes('腹部')" style="text-align: left">{{abdomen.join('、')}}</span>
              <span v-if="item.content[0].includes('腰部')" style="text-align: left">{{waist.join('、')}}</span>
              <span v-if="item.content[0].includes('骨')" style="text-align: left">{{bones.join('、')}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ibfx from '../../../assets/img/menopauseovary/jkfxts_icon.png';
  import jiankang from '../../../assets/img/menopauseovary/health.png';
  import lineLeft from "../../../assets/img/physical/body_line_left.png";
  import lineRight from "../../../assets/img/physical/body_line_right.png";
  import lineOral from "../../../assets/img/physical/body_line_left_oral.png";
  import lineNose from "../../../assets/img/physical/body_line_nose.png";
  import lineRightOral from "../../../assets/img/physical/body_line_right_oral.png";
  export default {
    name: "menopausejbfx",
    data(){
      return{
        ibfx:ibfx,
        jiankang:jiankang,
        leftRisk: [],
        rightRisk: [],
        lineLeft: lineLeft,
        lineRight: lineRight,
        lineOral: lineOral,
        lineNose: lineNose,
        lineRightOral: lineRightOral,
        allbody:[],
        headone:[],
        headtwo:[],
        eyes:[],
        oral:[],
        liver:[],
        kidney:[],
        lower:[],
        ear:[],
        nasal:[],
        heart:[],
        stomach:[],
        abdomen:[],
        waist:[],
        bones:[],
      }
    },
    props: {
      reportData: Object
    },
    beforeMount(){
      this.riskData()
    },
    methods: {
      riskData(){
        const risk = this.reportData.solutions.disease_menopause_ovary
        if (this.reportData.type_attention === '更年期'){
          if (risk['肾虚肝郁型']){
            this.leftRisk.push(...risk['肾虚肝郁型'])
            this.rightRisk.push(...risk['肾虚肝郁型'])
          }else if (risk['心肾不交型']){
            this.leftRisk.push(...risk['心肾不交型'])
            this.rightRisk.push(...risk['心肾不交型'])
          }else if (risk['阴虚火旺型']){
            this.leftRisk.push(...risk['阴虚火旺型'])
            this.rightRisk.push(...risk['阴虚火旺型'])
          }else if (risk['肾阴虚型']){
            this.leftRisk.push(...risk['肾阴虚型'])
            this.rightRisk.push(...risk['肾阴虚型'])
          }else if (risk['肾阳虚型']){
            this.leftRisk.push(...risk['肾阳虚型'])
            this.rightRisk.push(...risk['肾阳虚型'])
          }else if (risk['肾阴阳两虚型']){
            this.leftRisk.push(...risk['肾阴阳两虚型'])
            this.rightRisk.push(...risk['肾阴阳两虚型'])
          }
        }else if (this.reportData.type_attention === '卵巢'){
          if (risk['肾虚肝郁']){
            this.leftRisk.push(...risk['肾虚肝郁'])
            this.rightRisk.push(...risk['肾虚肝郁'])
          }else if (risk['肾虚血瘀']){
            this.leftRisk.push(...risk['肾虚血瘀'])
            this.rightRisk.push(...risk['肾虚血瘀'])
          }else if (risk['肝肾阴虚']){
            this.leftRisk.push(...risk['肝肾阴虚'])
            this.rightRisk.push(...risk['肝肾阴虚'])
          }else if (risk['痰湿凝滞']){
            this.leftRisk.push(...risk['痰湿凝滞'])
            this.rightRisk.push(...risk['痰湿凝滞'])
          }else if (risk['脾肾两虚']){
            this.leftRisk.push(...risk['脾肾两虚'])
            this.rightRisk.push(...risk['脾肾两虚'])
          }else if (risk['气滞血瘀']){
            this.leftRisk.push(...risk['气滞血瘀'])
            this.rightRisk.push(...risk['气滞血瘀'])
          }else if (risk['气血两虚']){
            this.leftRisk.push(...risk['气血两虚'])
            this.rightRisk.push(...risk['气血两虚'])
          }
        }
        this.leftRisk = this.leftRisk.filter(item => item.content[0].includes('左'))
        this.rightRisk = this.rightRisk.filter(item => item.content[0].includes('右'))
        for (let key of this.leftRisk){
          if (key.content[0].includes('头部')){
            this.headone.push(key.name)
          }
          if (key.content[0].includes('眼部')){
            this.eyes.push(key.name)
          }
          if (key.content[0].includes('口腔')){
            this.oral.push(key.name)
          }
          if (key.content[0].includes('全身')){
            this.allbody.push(key.name)
          }
          if (key.content[0].includes('肝胆胰')){
            this.liver.push(key.name)
          }
          if (key.content[0].includes('肾脏')){
            this.kidney.push(key.name)
          }
          if (key.content[0].includes('下半部')){
            this.lower.push(key.name)
          }
        }
        for (let i of this.rightRisk){
          if (i.content[0].includes('头部')){
            this.headtwo.push(i.name)
          }
          if (i.content[0].includes('耳部')){
            this.ear.push(i.name)
          }
          if (i.content[0].includes('鼻腔')){
            this.nasal.push(i.name)
          }
          if (i.content[0].includes('心脏')){
            this.heart.push(i.name)
          }
          if (i.content[0].includes('胃部')){
            this.stomach.push(i.name)
          }
          if (i.content[0].includes('腹部')){
            this.abdomen.push(i.name)
          }
          if (i.content[0].includes('腰部')){
            this.waist.push(i.name)
          }
          if (i.content[0].includes('骨')){
            this.bones.push(i.name)
          }
        }
        console.log(this.leftRisk)
        console.log(this.headtwo)
      }
    }
  }
</script>

<style lang="scss" scoped>
  $contentWidth: 94%;
  $leftContent: 3%;
  $commonHeight: 556px;
  $tenHeight: 470px;
  $tzbg: '../../../assets/img/physical/jbfxbj_jcjg_icon.png';
  $tzbgTen: '../../../assets/img/physical/jbfxbj_jcjg_10_icon.png';
  $btnbg: '../../../assets/img/physical/mzjg_jcjg_icon.png';
  .healthtip {
    margin-top:20px;
    padding: 0 40px;
    .tip-content {
      //margin-left: $leftContent;
      //width: $contentWidth;
      width: 100%;
      background-size:100% 100%;
      height: $commonHeight;
      .body {
        display: flex;
        position: relative;
        width: 100%;
        height: 100%;
        .imgdiv {
          width: 100%;
          text-align: center;
          .health-body {
            height: $commonHeight;
            width: auto;
            margin-top: -60px;
          }
        }
        .line {
          display: flex;
          flex-direction: row;
          position: absolute;
          margin-top: 8%;
          width: 100%;
          height: 85%;
          .common-line {
            width: 50%;
            display: flex;
            flex-direction: column;
            position: relative;
            // background-color: #02ca92;
          }
          .line-left {
            @extend .common-line;
            .leftc {
              display: flex;
              position: absolute;
              align-items: center;
              right: 0;
              color: #ffffff;
              font-size: 26px;
              img {
                height: 7px;
                width: 250px;
                margin-left: 18px
              }
            }
            .head {
              margin-top: 2px;
              right: 30px;
              img {
                width: 218px;
              }
            }
            .fu {
              margin-top: 320px;
              right: 60px;
              img {
                width: 220px;
              }
            }
            .face {
              margin-top: 33px;
              right: 20px;
              img {
                width: 230px;
              }
            }
            .oral {
              margin-top: 76px;
              right: 10px;
              img {
                width: 241px;
                /*height: 15px;*/
              }
            }
            .chest {
              margin-top: 143px;
              right: 100px;
              img {
                width: 150px;
              }
            }
            .lung {
              margin-top: 253px;
              right: 60px;
              img {
                width: 192px;
              }
            }
            .darling {
              margin-top: 273px;
              right: 50px;
              img {
                width: 200px;
              }
            }
            .upperarm {
              margin-top: 403px;
              right: 70px;
              img {
                width: 185px;
              }
            }
            .kidney {
              margin-top: 263px;
              right: 30px;
              img {
                width: 250px;
              }
            }
            .bone {
              margin-top: 403px;
              right: 60px;
              img {
                width: 220px;
              }
            }
            .hand {
              margin-top: 423px;
              right: 180px;
              img {
                width: 100px;
              }
            }
          }
          .line-right {
            @extend .common-line;
            // background-color: #2f84ce;
            .rightc {
              color: #ffffff;
              font-size: 26px;
              display: flex;
              align-items: center;
              position: absolute;
              img {
                height: 7px;
                width: 246px;
                margin-right: 18px;
              }
            }
            .fu {
              margin-top: 332px;
              left: 50px;
              img {
                width: 230px;
              }
            }
            .head {
              margin-top: 0px;
              left: 20px;
              img {
                width: 222px;
              }
            }
            .heart {
              margin-top: 32px;
              left: 20px;
              img {
                width: 222px;
              }
            }
            .nose {
              margin-top: 52px;
              left: 0px;
              img {
                /*height: 33px;*/
                width: 239px;
              }
            }
            .face {
              margin-top: 182px;
              left: 0px;
              img {
                width: 242px;
              }
            }
            .oral {
              margin-top: 238px;
              left: 30px;
              img {
                /*height: 22px;*/
                width: 212px;
              }
            }
            .throat {
              margin-top: 288px;
              left: 40px;
              img {
                /*height: 22px;*/
                width: 200px;
              }
            }
            .chest {
              margin-top: 332px;
              left: 50px;
              img {
                width: 190px;
              }
            }
            .upperarm {
              margin-top: 402px;
              left: 60px;
              img {
                width: 180px;
              }
            }
            .elbow {
              margin-top: 292px;
              left: 130px;
              img {
                width: 150px;
              }
            }
            .wholebody {
              margin-top: 382px;
              left: 170px;
              img {
                width: 110px;
                max-height: 5px;
              }
            }
            .bottomhalf {
              margin-top: 422px;
              left: 85px;
              img {
                width: 195px;
              }
            }
          }
        }
      }

    }
  }
  .jbfximg{
    position: absolute;
    vertical-align:top;
    height: 556px;
    width: 93%;
    background-size: 100% 100%;
  }
</style>

