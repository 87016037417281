import { render, staticRenderFns } from "./labdetail.vue?vue&type=template&id=1668279d&scoped=true&"
import script from "./labdetail.vue?vue&type=script&lang=js&"
export * from "./labdetail.vue?vue&type=script&lang=js&"
import style0 from "./labdetail.vue?vue&type=style&index=0&id=1668279d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1668279d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\work\\zy_frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1668279d')) {
      api.createRecord('1668279d', component.options)
    } else {
      api.reload('1668279d', component.options)
    }
    module.hot.accept("./labdetail.vue?vue&type=template&id=1668279d&scoped=true&", function () {
      api.rerender('1668279d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/report/labdetail.vue"
export default component.exports