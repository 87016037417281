// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/report/bj_sznr_icon.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/report/x_icon.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/img/report/bjk_gywm_icon.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/img/common/bg_button_normal.png");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../assets/img/common/bjk_phsz_icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, "\n.detail[data-v-0bbdadaa]{\n  width:100%;\n  height:100%;\n  position:absolute;\n  top:0px;\n  left:0px;\n  overflow:hidden;\n  background-size:cover;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  /*background-color:#000f21;*/\n}\n.boxsty[data-v-0bbdadaa]{\n  width: 100%;\n  margin: 0px auto;\n}\n.title[data-v-0bbdadaa]{\n  font-size: 60px;\n  color:#ffffff;\n  font-family:TITLE;\n  margin-left:60px;\n}\n.border[data-v-0bbdadaa]{\n  position:absolute;\n  left:0px;\n  top:157px;\n  display:block;\n  width:1000px;\n  height:16px;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")no-repeat;\n}\n.result[data-v-0bbdadaa]{\n  height:1400px;\n  width: 100%;\n  color:#FFFFFF;\n  font-size:24px;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat;\n  background-position-x:54px;\n  display:inline-block;\n  text-align:center;\n  vertical-align:top;\n  margin-top:10px;\n  padding-top:160px;\n  overflow:hidden;\n}\n.result-content[data-v-0bbdadaa]{\n  height:1180px;\n  width: 696px;\n  margin: auto;\n  left:0;\n  right:0px;\n  color:white;\n  padding:0 45px;\n  text-align:left;\n  overflow:auto;\n}\n.back[data-v-0bbdadaa]{\n  height:67px;\n  width: 224px;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") no-repeat;\n  margin: auto;\n  left:0;\n  right:0;\n  margin-top:20px;\n  color:#FFFFFF;\n  font-size:37px;\n  text-align:center;\n  line-height:70px;\n}\n.resultM[data-v-0bbdadaa]{\n  height:1600px;\n  width: 100%;\n  color:#FFFFFF;\n  font-size:24px;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") no-repeat;\n  background-position-x:54px;\n  display:inline-block;\n  text-align:center;\n  vertical-align:top;\n  margin-top:57px;\n  padding-top:160px;\n}\n\n", ""]);
// Exports
module.exports = exports;
