var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.marker === "10.1"
    ? _c("div", [
        _vm.pulse
          ? _c(
              "div",
              {
                staticClass: "tongueresult-pulse",
                style:
                  _vm.printstatus === "1"
                    ? { marginLeft: "130px" }
                    : { marginLeft: "30px" }
              },
              [
                _c("img", {
                  staticStyle: { width: "112px", height: "150px" },
                  attrs: { src: _vm.tongue_photo_url }
                }),
                _c(
                  "div",
                  {
                    staticClass: "tongue-right-pulse",
                    on: { click: _vm.ReturnToTongue }
                  },
                  [
                    _c("span", [_vm._v("查看舌诊")]),
                    _c("br"),
                    _c("span", [_vm._v("分析结果")]),
                    _c("br"),
                    _c("img", { attrs: { src: _vm.ckmzjg } })
                  ]
                )
              ]
            )
          : _c(
              "div",
              {
                staticClass: "tongueresult",
                style:
                  _vm.printstatus === "1"
                    ? { marginLeft: "130px" }
                    : { marginLeft: "30px" }
              },
              [
                _c("img", {
                  staticStyle: { width: "115px", height: "150px" },
                  attrs: { src: _vm.tongue_photo_url }
                }),
                _c(
                  "div",
                  {
                    staticClass: "tongue-right",
                    on: { click: _vm.ReturnToTongue }
                  },
                  [
                    _c("span", [_vm._v("查看舌诊")]),
                    _c("br"),
                    _c("span", [_vm._v("分析结果")]),
                    _c("br"),
                    _c("img", { attrs: { src: _vm.ckmzjg } })
                  ]
                )
              ]
            )
      ])
    : _c("div", [
        _vm.pulse
          ? _c(
              "div",
              {
                staticClass: "tongueresult-pulse",
                style:
                  _vm.printstatus === "1"
                    ? { marginLeft: "130px" }
                    : { marginLeft: "30px" }
              },
              [
                _c("img", {
                  staticStyle: { width: "85px", height: "150px" },
                  attrs: { src: _vm.tongue_photo_url }
                }),
                _c(
                  "div",
                  {
                    staticClass: "tongued-right-pulse",
                    on: { click: _vm.ReturnToTongue }
                  },
                  [
                    _c("span", [_vm._v("查看舌诊")]),
                    _c("br"),
                    _c("span", [_vm._v("分析结果")]),
                    _c("br"),
                    _c("img", { attrs: { src: _vm.ckmzjg } })
                  ]
                )
              ]
            )
          : _c(
              "div",
              {
                staticClass: "tongueresult",
                style:
                  _vm.printstatus === "1"
                    ? { marginLeft: "130px" }
                    : { marginLeft: "30px" }
              },
              [
                _c("img", {
                  staticStyle: { width: "85px", height: "150px" },
                  attrs: { src: _vm.tongue_photo_url }
                }),
                _c(
                  "div",
                  {
                    staticClass: "tongued-right",
                    on: { click: _vm.ReturnToTongue }
                  },
                  [
                    _c("span", [_vm._v("查看舌诊")]),
                    _c("br"),
                    _c("span", [_vm._v("分析结果")]),
                    _c("br"),
                    _c("img", { attrs: { src: _vm.ckmzjg } })
                  ]
                )
              ]
            )
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }