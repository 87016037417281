<template>
  <div>
    <div class="title">血压</div>
    <div class="basic">
      <div>
        <p class="heart">心率</p>
        <p class="zhi">
          {{bloodData.heart_rate || '-'}}
          <span class="unit">次/分</span>
        </p>
      </div>
      <div>
        <p class="blood">舒张压</p>
        <p class="zhi">
          {{bloodData.blood_dia || '-'}}
          <span class="unit">mmHg</span>
        </p>
      </div>
      <div>
        <p class="blood">收缩压</p>
        <p class="zhi">
          {{bloodData.blood_sys || '-'}}
          <span class="unit">mmHg</span>
        </p>
      </div>
    </div>
    <div class="notice">提示：{{ bloodData.blood_attention }}</div>
  </div>
</template>

<script>
  export default {
    name: "blood_pressure",
    props: {
      bloodData: { // 血压具体数据
        type: Object,
        required: true,
        default: {}
      },
    }
  }
</script>

<style scoped>
  .title {
    width: 79%;
    height: 110px;
    margin: 82px auto;
    text-align: center;
    font-size: 48px;
    background-image: url("../../../assets/img/report/pulse/mxbt_jkjx_icon.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    line-height: 110px;
  }

  .basic {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .basic>div {
    width: 30%;
    /*text-align: center;*/
    font-size: 16px;
    height: 185px;
    border: 1px solid #01477d;
    background-color: #001d34;
  }



  .heart,
  .blood{
    font-size: 42px;
    background-repeat: no-repeat;
    background-size: 16px auto;
    padding-left: 24px;
    padding-bottom: 11px;
    text-align: center;
    margin-top: 40px;
  }

  .zhi {
    text-align: center;
    color: #00e4ff;
    font-size: 42px;
  }

  .unit {
    font-size: 30px;
    text-align: center;
  }

  .notice {
    margin-top: 22px;
    /*margin-bottom: 1rem;*/
    padding: 25px 50px;
    font-size: 42px;
    color: #00e4ff;
    background-color: #041937;
    line-height: 60px;
  }

</style>