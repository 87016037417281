var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShowJKFN &&
    _vm.isShowCPTJ &&
    _vm.report.product[0] &&
    _vm.report.product[0]["name"]
    ? _c("div", { staticStyle: { float: "left" } }, [
        _c("div", {
          staticClass: "recommendation",
          on: { click: _vm.returnToProduct }
        })
      ])
    : _vm.isShowCPTJ && !_vm.isShowJKFN
    ? _c("div", { staticStyle: { float: "left" } }, [
        _c("div", {
          staticClass: "recommendation1",
          on: { click: _vm.returnToProduct }
        })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }