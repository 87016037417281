<template>
  <div>
    <h4>(二) 体质辨识</h4>
    <p>根据中医体质辨识评估结果，您最具倾向性的体质是：
      <span v-if="report.before_symptom_status" style="font-weight: bold">{{report.result.substring(0,3)==='平和质'?'平和质，属于健康状态。':!report.result.slice(3)?report.result+'，属于亚健康状态，程度较重。':report.result+'，属于亚健康状态，程度较轻。'}}</span>
      <span v-else style="font-weight: bold">{{report.result.substring(0,3)==='平和质'?'平和质，属于健康状态，请继续保持健康的生活方式。':!report.result.slice(3)?report.result+'，属于亚健康状态，程度较重，建议您咨询专业的中医师调理。':report.result+'，属于亚健康状态，程度较轻，建议您咨询专业的中医师调理。'}}</span>
      <span v-if="report.before_symptom_status" style="font-weight: bold">{{report.physical_attention.attention+'。'}}</span>
    </p>
<!--    <p><span style="font-weight: bold">{{report.result.substring(0,3) !== '平和质'&&!report.result.slice(3)?'建议您1个月后再做1次健康评估。':report.result.substring(0,3) !== '平和质'&&report.result.slice(3)?'建议您3个月后再做1次健康评估。':''}}</span></p>-->
    <div v-if="is_show_radar === 'yes'" id="root" :style="{display:'block',width: '429px', height: '340px',margin:'0 auto', paddingTop: '32px'}"></div>
    <img v-else :src="radar_img_src" alt="生成后的图" :style="{display:'block',width: '429px', height: '340px',margin:'0 auto', paddingTop: '32px'}">
    <h4>(三) 体质解析</h4>
    <p>{{content1}}</p>
    <span v-if="content2" class="symptom" v-for="(item,index) in content2.split('、')" :key="index">{{item}}</span>
    <p v-if="content3">{{content3}}</p>
    <span v-if="content4" class="symptom" v-for="items in content4.split('、')" :key="items">{{items}}</span>
    <p v-if="content5">{{content5}}</p>
    <div class="space" :style="report.result.substring(0,3)==='平和质'?{ height: '200px' } : { height: '100px' }"></div>
    <div class="body">
      <div class="imgdiv">
        <img v-if="report.result.includes('平和质')" class="health-body" src="../../../assets/img/print/health_constitution.png" alt="人体图" />
        <img v-else class="health-ill" src="../../../assets/img/print/ill_constitution.png" alt="人体图" />
      </div>
      <div class="line">
        <div class="line-left">
          <div v-for="(item, index) in leftRisk" :key="index"
               :class="item.content[0].includes('腹部') ? 'leftc fu' :
            item.content[0].includes('头') ? 'leftc head' :
            item.content[0].includes('脸') ? 'leftc face' :
            item.content[0].includes('口腔') ? 'leftc oral' :
            item.content[0].includes('胸') ? 'leftc chest' :
            item.content[0].includes('肺') ? 'leftc lung' :
            item.content[0].includes('心') ? 'leftc darling' :
            item.content[0].includes('上臂') ? 'leftc upperarm' :
            item.content[0].includes('肾脏') ? 'leftc kidney' :
            item.content[0].includes('骨骼') ? 'leftc bone' :
            item.content[0].includes('手掌') ? 'leftc hand' : 'leftc' " >
            <span>{{item.name}}</span>
            <img :src="item.content[0].includes('口腔') ? lineOral : lineLeft" alt="人体线图"/>
          </div>
        </div>
        <div class="line-right">
          <div v-for="(item, index) in rightRisk" :key="index"
               :class="item.content[0].includes('腹部') ? 'rightc fu' :
            item.content[0].includes('头部') ? 'rightc head' :
            item.content[0].includes('心脏') ? 'rightc heart' :
            item.content[0].includes('鼻子') ? 'rightc nose' :
            item.content[0].includes('脸') ? 'rightc face' :
            item.content[0].includes('口腔') ? 'rightc oral' :
            item.content[0].includes('咽') ? 'rightc throat' :
            item.content[0].includes('胸') ? 'rightc chest' :
            item.content[0].includes('上臂') ? 'rightc upperarm' :
            item.content[0].includes('肘关节') ? 'rightc elbow' :
            item.content[0].includes('全身') ? 'rightc wholebody' :
            item.content[0].includes('下半部') ? 'rightc bottomhalf' : 'rightc'">
            <img :src="item.content[0].includes('鼻子') ? lineNose : item.content[0].includes('口腔') || item.content[0].includes('咽') ? lineRightOral : lineRight" alt="人体线图"/>
            <span>{{item.name}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as echarts from "echarts";
  import lineLeft from '../../../assets/img/physical/body_line_left.png';
  import lineRight from '../../../assets/img/physical/body_line_right.png';
  import lineOral from '../../../assets/img/physical/body_line_left_oral.png';
  import lineNose from '../../../assets/img/physical/body_line_nose.png';
  import lineRightOral from '../../../assets/img/physical/body_line_right_oral.png';
  export default {
    name: "constitutiontzbs",
    beforeCreate(){
      this.$store.commit('SET_RADAR_SHOW', 'yes'); // 初始化store里面的数据
    },
    props: {
      report: Object, // faceResult faceUrl tongueResult tongueUrl handResult handUrl
    },
    data(){
      return{
        symptomName:'',
        content1:'',
        content2:'',
        content3:'',
        content4:'',
        content5:'',
        leftRisk: [],
        rightRisk: [],
        lineLeft: lineLeft,
        lineRight: lineRight,
        lineOral: lineOral,
        lineNose: lineNose,
        lineRightOral: lineRightOral,
      }
    },
    computed:{
      is_show_radar(){
        return this.$store.state.is_show_radar
      },
      radar_img_src(){
        return this.$store.state.radar_img_src
      }
    },
    mounted() {
      this.symptomName = this.report.result ? this.report.result.substring(0,3):'健康';
      this.content1 = this.report.solutions.tizhi_definition[this.symptomName].content[0]
      this.content2 = this.report.solutions.tizhi_definition[this.symptomName].content[1]?this.report.solutions.tizhi_definition[this.symptomName].content[1]:''
      this.content3 = this.report.solutions.tizhi_definition[this.symptomName].content[2]
      this.content4 = this.report.solutions.tizhi_definition[this.symptomName].content[3]?this.report.solutions.tizhi_definition[this.symptomName].content[3]:''
      this.content5 = this.report.solutions.tizhi_definition[this.symptomName].content[4]
      this.drawCharttzbs()
      this.riskData()
    },
    methods:{
      drawCharttzbs(){
        const _this = this;
        const scoreArr = [];
        const indicatorArr = [];
        this.report.symptom_status.forEach((e, i) => {
          scoreArr[i] = e.score;
          let indicatorDic = { name: e.name, max: 40 }
          indicatorArr[i] = indicatorDic
        });
        const myChart = echarts.init(document.getElementById('root'));
        myChart.setOption({
          legend: {
            data: ['体质'],
            show: false
          },
          radar: {
            center: ['50%', '50%'],
            startAngle: 90,
            splitNumber: 4,
            axisName: {
              formatter(value) {
                return `{a|${value}}`;
              },
              rich: {
                a: {
                  color: '#999999',
                  fontSize: 24,
                  lineHeight: 14.9,
                }
              },
            },
            axisLine: {
              symbol: ['none', 'none'],
              symbolSize: [5, 10],
              symbolOffset: [40, 40],
              // lineStyle: {
              //   color: '#115545',
              // }
            },
            splitLine: {
              // lineStyle: {
              //   width: 1,
              //   color: '#00382f'
              // }
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: ['#A6C2E7', '#B9CFEC', '#CEDDF2', '#E5EDF8'],
              }
            },
            indicator: indicatorArr
          },
          series: [{
            type: 'radar',
            // symbol: "none",
            data: [
              {
                value: scoreArr,
                name: '本次',
                areaStyle: { // 单项区域填充样式
                  color:'rgba(12,153,116,0.2)',
                },
                itemStyle: {
                  color: 'black',
                  // borderColor: '#00e6ad',
                  // borderWidth: 3,
                  opacity: 0.6,
                },
                lineStyle: {
                  color: 'black',
                  width: 2,
                  opacity: 0.5,
                  type:'solid'
                },
              },
            ]
          }]
        });
        window.addEventListener("resize",function () {
          myChart.resize();
        })
        myChart.on('finished',function(){
          let img = myChart.getDataURL();
          _this.$store.commit('SET_RADAR_IMG', img); // 提交雷达图生成的图片
          _this.$store.commit('SET_RADAR_SHOW', 'no'); // 隐藏echarts雷达图
        });
      },
      riskData(){
        const risk = this.report.solutions.tizhi_health_risk
        if (risk['痰湿质']){
          this.leftRisk.push(...risk['痰湿质'])
          this.rightRisk.push(...risk['痰湿质'])
        }else if (risk['阳虚质']){
          this.leftRisk.push(...risk['阳虚质'])
          this.rightRisk.push(...risk['阳虚质'])
        }else if (risk['阴虚质']){
          this.leftRisk.push(...risk['阴虚质'])
          this.rightRisk.push(...risk['阴虚质'])
        }else if (risk['气虚质']){
          this.leftRisk.push(...risk['气虚质'])
          this.rightRisk.push(...risk['气虚质'])
        }else if (risk['气郁质']){
          this.leftRisk.push(...risk['气郁质'])
          this.rightRisk.push(...risk['气郁质'])
        }else if (risk['血瘀质']){
          this.leftRisk.push(...risk['血瘀质'])
          this.rightRisk.push(...risk['血瘀质'])
        }else if (risk['湿热质']){
          this.leftRisk.push(...risk['湿热质'])
          this.rightRisk.push(...risk['湿热质'])
        }else if (risk['特禀质']){
          this.leftRisk.push(...risk['特禀质'])
          this.rightRisk.push(...risk['特禀质'])
        }
        this.leftRisk = this.leftRisk.filter(item => item.content[0].includes('左'))
        this.rightRisk = this.rightRisk.filter(item => item.content[0].includes('右'))
      }
    }
  }
</script>

<style scoped lang="scss">
  $contentWidth: 94%;
  $leftContent: 3%;
  $commonHeight: 556px;
  $tenHeight: 470px;
  p{
    text-indent: 32px;
    font-family: 黑体;
    line-height: 30px;
  }
  .symptom{
    line-height: 30px;
    background: #DCDCDC;
    padding: 7px;
    margin-left: 10px;
    font-family: 黑体;
  }
  .space{
    display: block;
    width: 100%;
  }
  .body {
    display: flex;
    position: relative;
    /*width: 100%;*/
    height: 610px;
    /*border: 2px solid black;*/
    .imgdiv {
      position: absolute;
      width: 100%;
      text-align: center;
      .health-body {
        height: $commonHeight;
        width: auto;
      }
      .health-ill {
        height: auto;
        width: 378px;
      }
    }
    .line {
      float: left;
      display: flex;
      flex-direction: row;
      position: absolute;
      margin-top: 0;;
      width: 100%;
      height: 100%;
      .common-line {
        width: 50%;
        position: relative;
        // background-color: #02ca92;
      }
      .line-left {
        @extend .common-line;
        .leftc {
          display: flex;
          position: absolute;
          align-items: center;
          right: 0;
          /*color: #ffffff;*/
          font-size: 14px;
          img {
            height: 7px;
            width: 280px;
            margin-left: 18px
          }
        }
        .head {
          margin-top: 32px;
          right: 55px;
          img {
            width: 180px;
          }
        }
        .fu {
          margin-top: 400px;
          right: 60px;
          img {
            width: 180px;
          }
        }
        .face {
          margin-top: 73px;
          right: 41px;
          img {
            width: 200px;
          }
        }
        .oral {
          margin-top: 110px;
          right: 60px;
          img {
            width: 180px;
            height: 35px;
          }
        }
        .chest {
          margin-top: 176px;
          right: 50px;
          img {
            width: 190px;
          }
        }
        .lung {
          margin-top: 208px;
          right: 50px;
          img {
            width: 190px;
          }
        }
        .darling {
          margin-top: 250px;
          right: 30px;
          img {
            width: 210px;
          }
        }
        .upperarm {
          margin-top: 290px;
          right: 110px;
          img {
            width: 130px;
          }
        }
        .kidney {
          margin-top: 375px;
          right: 30px;
          img {
            width: 210px;
          }
        }
        .bone {
          margin-top: 470px;
          right: 60px;
          img {
            width: 180px;
          }
        }
        .hand {
          margin-top: 524px;
          right: 156px;
          img {
            width: 84px;
          }
        }
      }
      .line-right {
        @extend .common-line;
        // background-color: #2f84ce;
        .rightc {
          /*color: #ffffff;*/
          font-size: 14px;
          display: flex;
          align-items: center;
          position: absolute;
          img {
            height: 7px;
            width: 280px;
            margin-right: 18px;
          }
        }
        .fu {
          margin-top: 385px;
          left: 60px;
          img {
            width: 167px;
          }
        }
        .head {
          margin-top: 20px;
          left: 25px;
          img {
            width: 200px;
          }
        }
        .heart {
          margin-top: 240px;
          left: 13px;
          img {
            width: 213px;
          }
        }
        .nose {
          margin-top: 50px;
          left: -70px;
          img {
            height: 33px;
            width: 295px;
          }
        }
        .face {
          margin-top: 90px;
          left: -30px;
          img {
            width: 255px;
          }
        }
        .oral {
          margin-top: 110px;
          left: -54px;
          img {
            height: 22px;
            width: 280px;
          }
        }
        .throat {
          margin-top: 140px;
          left: -10px;
          img {
            height: 22px;
            width: 235px;
          }
        }
        .chest {
          margin-top: 175px;
          left: 50px;
          img {
            width: 175px;
          }
        }
        .upperarm {
          margin-top: 285px;
          left: 90px;
          img {
            width: 135px;
          }
        }
        .elbow {
          margin-top: 350px;
          left: 115px;
          img {
            width: 112px;
          }
        }
        .wholebody {
          margin-top: 450px;
          left: 150px;
          img {
            width: 78px;
            max-height: 5px;
          }
        }
        .bottomhalf {
          margin-top: 500px;
          left: 70px;
          img {
            width: 160px;
          }
        }
      }
    }
  }

</style>
