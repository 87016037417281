// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/physical/kuang_lab_icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".headerTabThree[data-v-0772ac0e] {\n  font-size: 36px;\n  color: #F1F1F1;\n  width: 90%;\n  margin: 30px 0 0 5%;\n  height: 1400px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  line-height: 60px;\n  background-size: 100% 100%;\n}\n.headerTabThree .title[data-v-0772ac0e] {\n    font-size: 42px;\n    height: 36px;\n    color: #ffffff;\n    padding: 80px 0 50px 5%;\n}\n.headerTabThree .voidesty[data-v-0772ac0e] {\n    display: inline-block;\n    width: 90%;\n    line-height: 100px;\n    border: 1px solid transparent;\n    border-radius: 4px;\n    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);\n    margin-left: 5%;\n}\n.headerTabThree .content-text[data-v-0772ac0e] {\n    display: block;\n    margin-left: 5%;\n    margin-right: 5%;\n    font-size: 30px;\n    color: #ffffff;\n    line-height: 45px;\n}\n", ""]);
// Exports
module.exports = exports;
