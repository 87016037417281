<template>
  <div class="qrcode-pulse" v-if="pulse">
    <div class="column" v-if="csr === 'HUASHI' || csr === 'SHBAZE'|| csr === 'CHENSHANG'|| csr === 'SHWYSLSWKJ'|| csr === 'SXSZYYY'">
      <div class="allreport" @click="myreport(report.owner.cellphone,report.id)" v-if="marker === '10.1' && myallreport === '1' && type !== 'personal'"></div>
      <div class="code-wrapper" v-else>
        <img :src="code" v-if="this.shareUrl" style="width: 86px;height: 86px;">
      </div>
      <div class="info-pulse">{{marker === '10.1' && myallreport === '1' && type !== 'personal' ? '历史报告' : '微信扫码带走报告'}}</div>
    </div>
    <div class="column" v-else>
      <div class="allreport" @click="myreport(report.owner.cellphone,report.id)" v-if="marker === '10.1' && myallreport === '1' && type !== 'personal'"></div>
      <div class="code-wrappered" v-else>
        <img @click="showExplain" :src="report.qr_url" v-if="report.vendor_setting.qr_code_show === 2" style="width: 86px;height: 86px;">
        <qrcode-vue @click="showExplain" v-else-if="this.shareUrl&&report.vendor_setting.qr_code_show === 1" :value='url' size='86'></qrcode-vue>
        <img @click="showExplain" :src="code" v-else-if="this.shareUrl&&report.vendor_setting.qr_code_show === 0" style="width: 86px;height: 86px;">
        <div v-else></div>8
      </div>
      <div class="info-pulse">{{marker === '10.1' && myallreport === '1' && type !== 'personal' ? '历史报告' : '微信扫码带走报告'}}</div>
    </div>
  </div>
  <div class="qrcode" v-else>
    <div class="column" v-if="csr === 'HUASHI' || csr === 'SHBAZE'|| csr === 'CHENSHANG'|| csr === 'SHWYSLSWKJ'|| csr === 'SXSZYYY'">
      <div class="allreport" @click="myreport(report.owner.cellphone,report.id)" v-if="marker === '10.1' && myallreport === '1' && type !== 'personal'"></div>
      <div class="code-wrapper" v-else>
        <img :src="code" v-if="this.shareUrl" style="width: 86px;height: 86px;">
      </div>
      <div class="info">{{marker === '10.1' && myallreport === '1' && type !== 'personal' ? '历史报告' : '微信扫码带走报告'}}</div>
    </div>
    <div class="column" v-else>
      <div class="allreport" @click="myreport(report.owner.cellphone,report.id)" v-if="marker === '10.1' && myallreport === '1' && type !== 'personal'"></div>
      <div class="code-wrappered" v-else>
        <img @click="showExplain" :src="report.qr_url" v-if="report.vendor_setting.qr_code_show === 2" style="width: 86px;height: 86px;">
        <qrcode-vue @click="showExplain" v-else-if="this.shareUrl&&report.vendor_setting.qr_code_show === 1" :value='url' size='86'></qrcode-vue>
        <img @click="showExplain" :src="code" v-else-if="this.shareUrl&&report.vendor_setting.qr_code_show === 0" style="width: 86px;height: 86px;">
        <div v-else></div>
      </div>
      <div class="info">{{marker === '10.1' && myallreport === '1' && type !== 'personal' ? '历史报告' : '微信扫码带走报告'}}</div>
    </div>
    <div v-if="flag">
      <div class="frame">
        <div class="spantitle">
          <img :src="report.qr_url" v-if="report.vendor_setting.qr_code_show === 2" style="width: 450px;height: 450px;">
          <qrcode-vue v-else-if="this.shareUrl&&report.vendor_setting.qr_code_show === 1" :value='url' size='450'></qrcode-vue>
          <img :src="code" v-else-if="this.shareUrl&&report.vendor_setting.qr_code_show === 0" style="width: 450px;height: 450px;">
          <div class="fh" @click="showExplain"></div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import {queryObject} from "../../../../utils/common";
  import code from '../../../assets/img/report/wechatcode.png';
  const { csr = '', marker = '',myallreport = '',type = '' } = queryObject();
  import QrcodeVue from 'qrcode.vue'
  export default {
    name: "barcode",
    props: {
      // shareUrl: String,
      // key:String,
      report:Object,
      pulse:Boolean,
    },
    data(){
      return{
        shareUrl:window.location.href,
        code:code,
        value: 'https://example.com',
        size: 86,
        flag: false,
      }
    },
    components: {
      QrcodeVue,
    },
    computed:{
      csr(){
        return csr
      },
      marker(){
        return marker
      },
      myallreport(){
        return myallreport
      },
      type(){
        return type
      },
      url(){
        if (this.report) {
          const url = `https://${window.location.host}/#/wxhealthy/${this.report.id}`;
          return url
        } else {
          return null
        }
      }
    },
    methods:{
      myreport(cellphone, key){
        console.log(cellphone, key)
        if (window.zhiyun) {
          window.zhiyun.myallreport(cellphone, key);
        }
      },
      showExplain(){
        this.flag=!this.flag
      }
    },
    created() {
      console.log(window.location.href)
    }
  }
</script>

<style scoped>
  .qrcode{
    width: 175px;
    height: 150px;
    float: left;
    margin-left: 30px;
    background: url("../../../assets/img/report/smbj_jcjg_icon.png") no-repeat;
    background-size: 100%;
  }
  .qrcode-pulse{
    width: 135px;
    height: 150px;
    float:left;
    margin-left:32px;
    background: url("../../../assets/img/report/smbj_jcjg_icon_pulse.png") no-repeat;
    background-size: 100%;
  }
  .column{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .allreport{
    background:url("../../../assets/img/report/bg10.1_jcjg_icon.png") no-repeat;
    background-size:100% 100%;
    width: 85px;
    height: 85px;
    padding: 2px;
    margin-top:15px;
  }
  .code{
    background: white;
    width: 85px;
    height: 85px;
    margin: 15px auto 0;
    /*margin-top: 10px;*/
  }
  .code-wrapper{
    background: white;
    width: 86px;
    height: 86px;
    margin-top:15px;
    padding: 2px;
  }
  .code-wrappered{
    background: white;
    width: 85px;
    height: 85px;
    margin-top:10px;
  }
  .info{
    color: white;
    font-size: 22px;
    width: 133px;
    text-align: center;
    margin-top: 8px;
  }
  .info-pulse{
    color: white;
    font-size: 20px;
    width: 133px;
    text-align: center;
    margin-top: 8px;
  }
  .frame{
    width:1080px;
    height:100%;
    position:absolute;
    top:0px;
    left:0px;
    z-index:99;
    background:rgba(0,0,0,0.6);
  }
  .spantitle{
    font-size: 42px;
    color:#ffffff;
    font-family:PingFangSC-Medium;
    width:868px;
    height:626px;
    background:url("../../../assets/img/menopauseovary/smk_icon.png") no-repeat;
    text-align:center;
    line-height:42px;
    background-size:100% 100%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    margin-top:615px;
    padding-top:80px;
  }
  .fh{
    height:67px;
    width: 224px;
    background:url("../../../assets/img/menopauseovary/fh_srsj_Icon.png") no-repeat;
    margin: auto;
    left:0;
    right:0;
    margin-top:28px;
  }

</style>
