<template>
  <div class="facereport" v-if="facereport">
    <div class="face-top">
      <span class="title">面象结果</span>
      <span class="title-border" />
    </div>
    <div class="result">
      <div class="imgsty">
        <img src="../../assets/img/menopauseovary/mxjg.gif" style="height: 375px;margin-top: 20px;" alt="面诊图片">
      </div>
      <div class="face-content">
        <div style="padding-top: 29px">
          <span class="title-tip">{{facereport.face_color_info.symptom}}</span>
        </div>
        <div style="width: 86%;margin-left: 14%;margin-top: 20px">
          <div style="text-align: left;">
            <span class="span-tip">提示：{{facereport.face_color_info.name}}</span><br />
            <span class="span-tip">可能原因：{{facereport.face_color_info.content.join(';')}}</span>
          </div>
        </div>
      </div>
      <div class="face-content">
        <div style="padding-top: 29px">
          <span class="title-tip">{{facereport.lip_color_info.symptom}}</span>
        </div>
        <div style="width: 86%;margin-left: 14%;">
          <div style="text-align: left;">
            <span class="span-tip">提示：{{facereport.lip_color_info.name}}</span><br />
            <span class="span-tip">可能原因：{{facereport.lip_color_info.content.join(';')}}</span>
          </div>
        </div>
      </div>
      <div class="face-gz">
        <div style="height: 100%;width: 90%;margin-left: 10%;">
          <div style="height: 30%;margin-top: 35px;">
            <div class="circular4"><span style="text-align: center;font-size: 34px;line-height: 112px;">{{facereport.gloss_indicator.toFixed(2)}}</span></div>
          </div>
          <div style="margin-top: 2px">
            <span class="spansty">面部光泽指数</span>
          </div>
          <div style="margin-top: 20px;text-align: left;margin-left: 3%;">
            <span class="span-tip">结果：{{facereport.face_gloss_info.name}}</span><br />
            <span class="span-tip">提示：{{facereport.face_gloss_info.symptom}}</span><br />
            <span class="span-tip">可能原因：{{facereport.face_gloss_info.content.join(';')}}</span>
          </div>
        </div>
      </div>
      <div class="back" @click="returnto"></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "facial",
    data(){
      return {
        facereport: null,
        id:'',
      }
    },
    methods:{
      returnto(){
        this.$router.push({path:'/menopauseovary/'+this.id});//返回上一层
      }
    },
    beforeMount(){
      this.facereport = this.$route.params.facereport
      this.id = this.$route.params.id
      // console.log(this.facereport)
    }
  }
</script>

<style scoped>
  .facereport{
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    overflow:hidden;
    background:url("../../assets/img/report/bj_sznr_icon.png");
  }
  .face-top{
    width: 80%;
    display: block;
    height: 110px;
    margin: 10px 0 0 0;
  }
  .title{
    font-size: 60px;
    color:#00fefb;
    font-family:TITLE;
    margin-left:60px;
    line-height: 90px;
  }
  .title-border{
    position:absolute;
    left:0;
    top:90px;
    display:block;
    width:1000px;
    height:16px;
    background:url("../../assets/img/report/xian_jcjg.png") no-repeat;
  }
  .result{
    height:1670px;
    width: 100%;
    color:#FFFFFF;
    font-size:24px;
    background:url("../../assets/img/menopauseovary/mxjgk_icon.png") no-repeat;
    background-position-x:60px;
    display:inline-block;
    text-align:center;
    vertical-align:top;
    padding-top:77px;
  }
  .imgsty{
    height:425px;
    width: 790px;
    margin: auto;
    left:0;
    right:0;
    /*border-bottom:2px solid #00ECFC;*/
  }
  .face-content{
    height:283px;
    width: 790px;
    margin: auto;
    left:0;
    right:0;
    /*border-bottom:2px solid #00ECFC;*/
  }
  .spansty{
    width:33%;
    display:inline-block;
    text-align:center;
    color:white;
    font-size:24px;
  }
  .span-tip{
    color:#FFFFFF;
    font-size:30px;
    line-height:46px;
    /*margin-left:30px;*/
  }
  .face-gz{
    height:412px;
    width: 790px;
    margin: auto;
    left:0;
    right:0;
  }
  .circular4{
    display:inline-block;
    width:33%;
    background:url("../../assets/img/common/mbgzzs_mzjg_Icon.png") no-repeat;
    background-position-x:center;
    height:100%;
  }
  .back{
    height:67px;
    width: 224px;
    background:url("../../assets/img/report/fh_srsj_Icon.png") no-repeat;
    margin: auto;
    left:0;
    right:0;
    margin-top:30px;
  }
  .title-tip{
    text-align: center;
    color: rgb(122, 219, 255);
    font-size: 36px;
    line-height:65px;
  }

</style>
