<template>
    <div v-if="isShowJKFN && isShowCPTJ">
        <div v-if="(report.product && report.product.length !== 0 && report.product[0].name)" style="float: left">
            <div class="content" @click="returnToHealth"></div>
        </div>
        <div v-else style="float: left">
            <div class="content1" @click="returnToHealth"></div>
        </div>
    </div>
    <div v-else-if="isShowJKFN && !isShowCPTJ" style="float: left">
      <div class="content1" @click="returnToHealth"></div>
    </div>
</template>

<script>
  export default {
    name: "health",
    props: {
        // shareUrl: String,
        report:Object,
        isShowJKFN:Boolean,
        isShowCPTJ:Boolean,
    },
    methods:{
      returnToHealth(){
        this.$router.push({name:'Healthprogram',params: {report:this.report, id:this.$route.params.id}})
      }
    }
  }
</script>

<style scoped>

  .content{
    width: 422px;
    height: 140px;
    background: url("../../../assets/img/report/jkfa_jcjg_icon.png");
    background-size: 100% 100%;
    margin: 20px 0 0 19px;
  }
  .content1{
    width:562px;
    height:149px;
    background:url("../../../assets/img/report/jkfa_jcjg_icon.png");
    background-size:100% 100%;
    margin:8px 0 0 200px;
  }

</style>
