<template>
  <div style="display: flex;overflow: auto;height: 100%">
    <div class="femaleexplain">
      <div style="height: 270px">
        <img :src="fh1" class="back" @click="returnto">
        <span class="title">{{definition}}</span>
      </div>
      <div class="definition">
        <div class="definition-content" style="height: 42%">
          {{content1}}
        </div>
      </div>
      <div class="risk">
        <div class="definition-content" style="height: 80%">
          {{explaining2[definition].content[0]}}
        </div>
      </div>
      <div class="reason">
        <div class="definition-content" style="height: 85%">
          <div v-for="(item , index) in reason" :key="index" style="margin: 10px 0 0 0">{{item}}</div>
        </div>
      </div>
      <span @click="ReturnToOutline" class="statement"></span>
    </div>
  </div>


</template>

<script>
  import fh1 from '../../assets/img/report/fh_icon.png';
  export default {
    name: "femaleexplain",
    data(){
      return {
        reportData: null,
        definition:'',
        fh1:fh1,
        explaining:[],
        content1:'',
        // content2:'',
        reason:[],
        explaining2:'',
        id:'',
      }
    },
    methods:{
      returnto(){
        this.$router.push({path:'/menopauseovary/'+this.id});//返回上一层
      },
      ReturnToOutline(){
        this.$router.push({name:'Femaleoutline',params: {reportData: this.reportData,definition:this.definition,id:this.id}})
      }
    },
    computed: {
      marker(){
        return marker
      },
      access(){
        return access
      }
    },
    beforeMount(){
      this.reportData = this.$route.params.reportData
      this.definition = this.$route.params.definition
      this.id = this.$route.params.id
      this.explaining = this.reportData.solutions.symptom_definition;
      this.explaining2 = this.reportData.solutions.health_risk;
      [this.content1, ...this.reason] = this.explaining[this.definition].content;
    }
  }
</script>

<style scoped>
  .femaleexplain{
    width:100%;
    height:100%;
    padding: 0 0 0 52px;
    background:url("../../assets/img/report/bj1.png");
    background-size:cover;
    overflow:hidden;
  }
  .title{
    font-size: 60px;
    color:#00FEFB;
    font-family:TITLE;
    width:530px;
    height:110px;
    background:url("../../assets/img/menopauseovary/btbj_icon.png") no-repeat;
    text-align:center;
    line-height:110px;
    background-size:100% 100%;
    position: absolute;
    left: 0;
    top: 83px;
    right: 0;
    margin: 0 auto;
  }
  .back{
    position:relative;
    left:49px;
    top:105px;
    width: 152px;
    height: 82px;
  }
  .definition{
    position:relative;
    width:976px;
    height:355px;
    background:url("../../assets/img/report/dyjs_zzfx_icon.png");
    background-size:100% 100%;
  }
  .definition-content{
    position:absolute;
    height:80%;
    width: 65%;
    margin: auto;
    left:30%;
    top:0;
    bottom:0;
    color:white;
    font-size:30px;
    line-height: 45px;
  }
  .risk{
    position:relative;
    width:976px;
    height:484px;
    background:url("../../assets/img/report/jkfx_zzfx_icon.png");
    background-size:100% 100%;
    margin-top:2%;
  }
  .reason{
    position:relative;
    width:976px;
    height:384px;
    background:url("../../assets/img/report/knyy_zzfx_icon.png");
    background-size:100% 100%;
    margin-top:2%;
  }
  .statement{
    width:600px;
    height:100px;
    background:url("../../assets/img/report/jssm_zzfx_icon.png") no-repeat;
    background-size:100% 100%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    margin-top:65px;
  }

</style>
