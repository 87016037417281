var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.knowledgedata
    ? _c(
        "div",
        { staticStyle: { display: "flex", overflow: "auto", height: "100%" } },
        [
          _c(
            "div",
            {
              staticClass: "knowledgebase",
              style:
                _vm.access === "web" ? { height: "1920px" } : { height: "100%" }
            },
            [
              _c("div", { staticStyle: { height: "314px" } }, [
                _c("img", {
                  staticClass: "back",
                  staticStyle: { left: "-2px" },
                  attrs: { src: _vm.fh },
                  on: { click: _vm.returnto }
                }),
                _c("span", { staticClass: "title" }, [_vm._v("知识库依据")])
              ]),
              _c("div", { staticClass: "knowledgebase-content" }, [
                _vm.marker == 10.1
                  ? _c(
                      "div",
                      [
                        _vm._l(_vm.knowledgedata.content, function(
                          item,
                          index
                        ) {
                          return _c("div", { key: index }, [
                            _vm._v(" " + _vm._s(item) + " ")
                          ])
                        }),
                        _vm._v(" " + _vm._s(_vm.knowledgedata.bottom) + " ")
                      ],
                      2
                    )
                  : _c(
                      "div",
                      _vm._l(_vm.knowledgedata, function(item, index) {
                        return _c("div", { key: index }, [
                          _vm._v(" " + _vm._s(item) + " ")
                        ])
                      }),
                      0
                    )
              ])
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }