<template>
  <div class="headerTabOne">
    <div class="top">
      <div class="left">
        <div class="left-top">
          <img src='../../assets/img/report/ys_jkfa_icon.png'/>
          <span>宜食</span>
        </div>
        <span v-for="(e,i) in goodfood" :key="i" class="text">{{e.name}}</span>
      </div>
      <div class="left right">
        <div class="left-top">
          <img src='../../assets/img/common/js_jkfa_icon.png'/>
          <span>忌食</span>
        </div>
        <span v-for="(e,i) in badfood" :key="i" class="text">{{e.name}}</span>
      </div>
    </div>
    <!-- 推荐药膳 469-->
    <!-- <div class="title">推荐药膳</div> -->
    <!--  -->
    <div class="Divys" v-if="solutions">
      <div class="title">{{ysty ? ysty.name : ''}}</div>
      <img class="image" :src="`${solutions.asset_prefix}${ysty.asset[0]}`"/>
      <span class="title2">烹煮方法：</span>
      <span v-for="(e, i) in ysty.content" :key="i" class="title3">{{e}}</span>
    </div>
  </div>
</template>

<script>

  export default {
    data(){
      return {
        symptoms: null,
        solutions: null,
        goodfood: null,
        badfood: null,
        ysty: null,
      }
    },
    mounted(){
      this.symptoms = this.$store.state.report.symptoms
      this.solutions = this.$store.state.report.solutions
      const symptomsName = this.symptoms[0].name;
      this.goodfood = this.solutions.tizhi_good_food[symptomsName];
      this.badfood = this.solutions.tizhi_bad_food[symptomsName];
      this.ysty = this.solutions.tizhi_diet_therapy[symptomsName];
      // const aaa = ysty.length * 850;
    },
  }
</script>

<style lang="scss" scoped>
  $tjysbj: '../../assets/img/physical/kuang_lab_icon.png';
  $titlebj: '../../assets/img/report/btbj_ysfa_icon.png';

  $commonW: 90%;
  $commonL: 5%;
  .headerTabOne {
    .top{
      margin: 20px 0;
      width: $commonW;
      margin-left: $commonL;
      display: flex;
      flex-direction: row;
      .left {
        width: 50%;
        height: 150px;
        color:white;
        // background-color: #333;
        border-right: 3px solid #2f7284;
        margin-right: 15px;
        .left-top {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;
          img {
            width: 35px;
            height: 35px;
          }
          span {
            font-size:35px;
            line-height: 35px;
            color:#ffffff;
            margin-left: 10px;
          }
        }
        .text {
          font-size:25px;
          line-height: 35px;
          color:#ffffff;
          padding: 0 15px 10px 0;
        }
      }
      .right {
        width: 47%;
        border-right: 0px solid #2f7284;
      }
    }
    // .title {
    //   width: $commonW;
    //   margin-left: $commonL;
    //   color: #fff;
    //   font-size: 36px;
    //   height:40px;
    // }
    .Divys{
      width: $commonW;
      margin-left: $commonL;
      background:url($tjysbj);
      background-size:100% 100%;
      margin-top: 60px;
      padding-bottom: 120px;
      .title {
        background:url($titlebj);
        background-size:100% 100%;
        width: 500px;
        height: 137px;
        margin-left: 24%;
        font-size:40px;
        color:#ffffff;
        line-height:100px;
        text-align:center;
      }
      .image {
        width: auto;
        height: 480px;
        border-radius: 10px;
        margin: 0 auto;
        display: block;
      }
      .title2 {
        display:block;
        margin-left: 5%;
        font-size:36px;
        line-height:80px;
        color:#ffffff;
      }
      .title3 {
        margin: 10px 0 20px 5%;
        width: 90%;
        display:block;
        font-size:30px;
        color:#ffffff;
        line-height:45px;
      }
    }
  }
</style>