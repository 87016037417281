var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShowJKFN && _vm.isShowCPTJ
    ? _c("div", [
        _vm.report.product &&
        _vm.report.product.length !== 0 &&
        _vm.report.product[0].name
          ? _c("div", { staticStyle: { float: "left" } }, [
              _c("div", {
                staticClass: "content",
                on: { click: _vm.returnToHealth }
              })
            ])
          : _c("div", { staticStyle: { float: "left" } }, [
              _c("div", {
                staticClass: "content1",
                on: { click: _vm.returnToHealth }
              })
            ])
      ])
    : _vm.isShowJKFN && !_vm.isShowCPTJ
    ? _c("div", { staticStyle: { float: "left" } }, [
        _c("div", {
          staticClass: "content1",
          on: { click: _vm.returnToHealth }
        })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }