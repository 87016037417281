<template>
  <div v-if="knowledgedata" style="display: flex;overflow: auto;height: 100%">
    <div class="knowledgebase" :style="access === 'web' ? { height: '1920px' } : { height: '100%' }">
      <div style="height: 314px">
        <img :src="fh" @click="returnto" class="back" style="left: -2px">
        <span class="title">知识库依据</span>
      </div>
      <div class="knowledgebase-content">
        <div v-if="marker==10.1">
          <div v-for="(item , index) in knowledgedata.content" :key="index">
            {{item}}
          </div>
          {{knowledgedata.bottom}}
        </div>
        <div v-else>
          <div v-for="(item , index) in knowledgedata" :key="index">
            {{item}}
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
  import fh from '../../assets/img/report/fh_icon.png';
  import { getKnowledge } from '@/api/report.js';
  import { queryObject } from '../../../utils/common.js';
  import { books } from '../konwledge.js';
  const { access = '', marker = '' } = queryObject();


  export default {
    name: "knowledgebase",
    data(){
      return{
        knowledgedata:null,
        fh,
        marker
      }
    },
    created() {
      if(this.marker === '10.1') { // 10.1寸请求知识库数据接口
        this.getKnowledgeData();
      } else { // 非10.1寸用自己的数据
        this.knowledgedata = books[this.$route.params.type];
      }

    },
    computed: {
      access(){
        return access
      }
    },
    methods:{
      getKnowledgeData(){
        getKnowledge().then(res =>{
            if(res && res.status_code === 10000 && res.data) {
              this.knowledgedata = res.data;
            }
          }
        )
      },
      returnto(){
        this.$router.back();//返回上一层
      }
    },
  }
</script>

<style scoped>
  .knowledgebase{
    width:100%;
    height:100%;
    padding: 0 0 0 52px;
    background:url("../../assets/img/report/bj1.png");
    background-size:cover;
    overflow:hidden;
  }
  ::-webkit-scrollbar{
    display: none;
  }
  .back{
    position:relative;
    left:49px;
    top:105px;
    width: 152px;
    height: 82px;
  }
  .title{
    font-size: 48px;
    color:#00FEFB;
    font-family:TITLE;
    width:400px;
    height:111px;
    background:url("../../assets/img/report/jssm_icon.png") no-repeat;
    text-align:center;
    line-height:111px;
    background-size:100% 100%;
    position: absolute;
    left: 0;
    top: 83px;
    right: 0;
    margin: 0 auto;
  }
  .knowledgebase-content{
    position:relative;
    width:896px;
    max-height:1400px;
    background:url("../../assets/img/report/jssmbj_icon.png") no-repeat;
    background-size:100% 100%;
    margin-top:50px;
    padding:60px 10px 60px 69px;
    color:#ffffff;
    font-size:30px;
    line-height:60px;
    overflow: auto;
  }

</style>
