<template>
  <div class="wxhealthyheader">
    <div class="left">
      <h2>{{score <=59 ? "可能有疾病" : score>90 ? "健康" : "亚健康"}}</h2>
      <div class="bottom">
        <span>报告编号：{{displayId}}</span>
        <span>检测时间：{{time}}</span>
      </div>
    </div>
    <div class="right">
      <div class="right-tip">健康指数：</div>
      <div class="right-bottom">
        <span :class="Math.round(score) == 100 ? 'score-100' : '' ">{{Math.round(score)}}</span><span class="score">分</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    score: Number,
    displayId: String,
    time: String
  },
  data(){
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
$headerbg: '../../../assets/img/wx/wx_bj_jcjg.png';
$rightbg: '../../../assets/img/wx/wx_jkzs_jcjg.png';

.wxhealthyheader {
  width: 100%;
  min-height: 540px;
  background:url($headerbg);
  background-size:cover;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding-bottom: 54px;
  .left {
    color: #fff;
    margin: 30px 0 0 45px;
    width: 50%;
    display: flex;
    flex-direction: column;
    position: relative;
    h2 {
      font: {
        size: 72px;
        family: PingFangSC-Medium;
      };
      margin-top: 180px;
    }
    span {
      display: block;
      font-size: 36px;
      font-family: SourceHanSansCN-Regular;
      line-height: 54px;
    }
    .bottom {
      position: absolute;
      bottom: 40px;
    }
  }
  .right {
    margin-left: -10%;
    background-color: yellow;
    width: 60%;
    background:url($rightbg);
    background-size:cover;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .right-tip {
      font-family: PingFangSC-Medium;
	    font-size: 38px;
      color: #ffffff;
      margin: 80px 0 0 65px;
    }
    .right-bottom {
      display: flex;
      flex-direction: row;
      span {
        font-family: PingFangSC-Medium;
        font-size: 135px;
        color: #ffffff;
        margin-left: 60px;
      }
      .score-100 {
        font-size: 100px;
      }
      .score {
        font-size: 36px;
        height: 36px;
        margin: 90px 0 0 10px;
      }
    }
    
  }
}
</style>