var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "jkwt" },
      [
        _vm.health_status
          ? _c("div", { staticClass: "cir" }, [
              _c("img", {
                staticStyle: { width: "245px", "margin-top": "-10px" },
                attrs: { src: _vm.healthy_status(), alt: "健康状态" }
              })
            ])
          : _vm._e(),
        _c("div", { staticClass: "HealthInfo" }, [
          _c("span", { staticClass: "tip" }, [_vm._v(_vm._s(_vm.tip))])
        ]),
        _vm._l(_vm.symptoms, function(item, index) {
          return _c(
            "span",
            {
              key: index,
              staticClass: "symptom",
              on: { click: _vm.handleOpen }
            },
            [
              _vm._v(
                " " +
                  _vm._s(!item.name.includes("健康") ? item.name : "健康") +
                  _vm._s(
                    !item.name.includes("健康") &&
                      _vm.$store.state.report.is_inclination
                      ? "(倾向)"
                      : ""
                  ) +
                  " "
              )
            ]
          )
        })
      ],
      2
    ),
    _vm.isShowBox && _vm.symptomsData.name
      ? _c("div", { staticClass: "explain" }, [
          _c("div", { staticClass: "explain_box" }, [
            _vm.symptomsData
              ? _c("div", [
                  _c("h3", [_vm._v(_vm._s(_vm.symptomsData.name))]),
                  _c("div", { staticClass: "content" }, [
                    _c("div", [
                      _vm._m(0),
                      _c("p", [_vm._v(_vm._s(_vm.symptomsData.content[0]))])
                    ]),
                    _c("div", [
                      _vm._m(1),
                      _c("p", [_vm._v(_vm._s(_vm.symptomsData.content[1]))])
                    ])
                  ])
                ])
              : _c("div", [_vm._v(" 暂无相关数据 ")]),
            _c(
              "p",
              { staticClass: "closeBtn", on: { click: _vm.handleClose } },
              [_vm._v("关 闭")]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", [_c("span", [_vm._v("中医解释")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", [_c("span", [_vm._v("可能原因")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }