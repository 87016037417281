var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.marker === "10.1"
    ? _c("div", [
        _vm.pulse
          ? _c("div", { staticClass: "faceresult-pulse" }, [
              _c("img", {
                staticStyle: { width: "112px", height: "150px" },
                attrs: { src: _vm.face_photo_url }
              }),
              _c(
                "div",
                {
                  staticClass: "face-right-pulse",
                  on: { click: _vm.ReturnToFace }
                },
                [
                  _c("span", [_vm._v("查看面诊")]),
                  _c("br"),
                  _c("span", [_vm._v("分析结果")]),
                  _c("br"),
                  _c("img", { attrs: { src: _vm.ckmzjg } })
                ]
              )
            ])
          : _c("div", { staticClass: "faceresult" }, [
              _c("img", {
                staticStyle: { width: "115px", height: "150px" },
                attrs: { src: _vm.face_photo_url }
              }),
              _c(
                "div",
                { staticClass: "face-right", on: { click: _vm.ReturnToFace } },
                [
                  _c("span", [_vm._v("查看面诊")]),
                  _c("br"),
                  _c("span", [_vm._v("分析结果")]),
                  _c("br"),
                  _c("img", { attrs: { src: _vm.ckmzjg } })
                ]
              )
            ])
      ])
    : _c("div", [
        _vm.pulse
          ? _c("div", { staticClass: "faceresult-pulse" }, [
              _c("img", {
                staticStyle: { width: "85px", height: "150px" },
                attrs: { src: _vm.face_photo_url }
              }),
              _c(
                "div",
                {
                  staticClass: "faced-right-pulse",
                  on: { click: _vm.ReturnToFace }
                },
                [
                  _c("span", [_vm._v("查看面诊")]),
                  _c("br"),
                  _c("span", [_vm._v("分析结果")]),
                  _c("br"),
                  _c("img", { attrs: { src: _vm.ckmzjg } })
                ]
              )
            ])
          : _c("div", { staticClass: "faceresult" }, [
              _c("img", {
                staticStyle: { width: "85px", height: "150px" },
                attrs: { src: _vm.face_photo_url }
              }),
              _c(
                "div",
                { staticClass: "faced-right", on: { click: _vm.ReturnToFace } },
                [
                  _c("span", [_vm._v("查看面诊")]),
                  _c("br"),
                  _c("span", [_vm._v("分析结果")]),
                  _c("br"),
                  _c("img", { attrs: { src: _vm.ckmzjg } })
                ]
              )
            ])
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }