<template>
  <div class="container">
    <div v-if="score<90">
      <div class="box">
        <div id="main"></div>
      </div>
      <div style="text-align: center">
        <img :src="qjysimg">
      </div>
    </div>
    <div v-else>
      <div style="text-align: center;padding-top: 20px">
        <img :src="jk" style="margin-top: 150px">
      </div>
    </div>

  </div>
</template>

<script>
  import qjysimg from '../../../assets/img/report/zytz_jcjg_icon.png';
  import jk from '../../../assets/img/report/jk_jcjg_icon.png';
  import wu from '../../../assets/img/report/wu_jcjg_icon.png';
  import green from '../../../assets/img/report/lv_jcjg_icon.png';
  import yellow from '../../../assets/img/report/huang_jcjg_icon.png';
  import red from '../../../assets/img/report/hong_jcjg_icon.png';
  import * as echarts from 'echarts'
  export default {
    name: "summaryhuashi",
    props: {
      score: Number,
      symptoms:Array,
      report:Object,
    },
    data(){
      return{
        qjysimg:qjysimg,
        jk:jk
      }
    },
    methods:{
      ReturnToExplain(val){
        // console.log(val)
        // console.log(this.report.symptoms)
      }
    },
    mounted() {
      const symptoms1 = this.symptoms || this.report.symptoms;
      const scoredata = [];
      let _this = this;
      symptoms1.forEach((e,i)=>{
        scoredata[i] = e.score;
      })
      if (this.score < 90){
        const myChart = echarts.init(document.getElementById('main'));
        myChart.setOption({
          title: { text: '' },
          // tooltip: {},
          // dataZoom: {
          //   height: 242,
          // },
          grid: {
            left: '5%',
            right: '5%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            show: true,
            triggerEvent: true,
            axisLabel: {
              show: true,
              margin: 5,
              formatter(params, index) {
                if (scoredata[index] === 0) {
                  return `{b|${params}}`;
                }
                return `{a|${params}}`;
              },
              rich: {
                a: {
                  color: '#009aee',
                  fontSize: 24,
                  fontFamily: 'SourceHanSansCN-Regular'

                },
                b: {
                  color: '#003a63',
                  fontSize: 24,
                  fontFamily: 'SourceHanSansCN-Regular'

                }
              },
              textStyle: {
                color: '#009aee',
              },
              max: 20
            },
            axisTick: {
              show: false,
              length: 100
            },
            // 底线
            axisLine: {
              lineStyle: {
                color: '#0065a5',
                width: 1
              }
            },
            splitLine: {
              show: false,
            },
            data: ['气虚', '阳虚', '阴虚', '痰湿', '郁滞', '脾虚', '肾虚']
          },
          yAxis: {
            type: 'value',
            max: 100,
            show: false,
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            splitArea: { show: false },
          },
          series:[{
            name: '',
            type: 'bar',
            barWidth: 20,
            data: scoredata,
            barCateGoryGap: '110',
            label:{
              normal:{
                show: true,
                position: 'top',
                formatter(params){
                  if (params.value === 0) {
                    return '{a|}';
                  } else if (params.value < 41) {
                    return `{b|${params.value}}`;
                  } else if (params.value > 89) {
                    return `{d|${params.value}}`;
                  }
                  return `{c|${params.value}}`;
                },
                rich: {
                  a: {
                    color: 'transparent',
                    width: 28,
                    height: 26,
                    backgroundColor: {
                      image: wu
                    }
                  },
                  b: {
                    color: '#fff',
                    width: 50,
                    height: 40,
                    padding: [10, 0, 0, 0],
                    fontFamily: 'AdobeHeitiStd-Regular',
                    align: 'center',
                    fontSize: 24,
                    backgroundColor: {
                      image: green
                    }
                  },
                  c: {
                    color: '#fff',
                    width: 50,
                    height: 40,
                    padding: [10, 0, 0, 0],
                    fontFamily: 'AdobeHeitiStd-Regular',
                    align: 'center',
                    fontSize: 24,
                    backgroundColor: {
                      image: yellow
                    }
                  },
                  d: {
                    color: '#fff',
                    width: 50,
                    height: 40,
                    padding: [10, 0, 0, 0],
                    fontFamily: 'AdobeHeitiStd-Regular',
                    align: 'center',
                    fontSize: 24,
                    backgroundColor: {
                      image: red
                    }
                  }
                }
              }
            },
            itemStyle:{
              normal:{
                barBorderRadius: [5, 5, 0, 0],
                color:new echarts.graphic.LinearGradient(0, 0, 0, 1,[
                  { offset: 0, color: '#1cd7ff' },
                  { offset: 1, color: '#016ec2' }
                ])
              }
            }
          }],
          addDataAnimation:true,
          animationDuration: 5000,
        });
        myChart.on('click',function (val) {
          if ( val.data > 0){
            _this.$router.push({name:'Constitution', params: {reportData: _this.report,definition:val.name,id:_this.$route.params.id}})
          }
        })
      }
    }
  }
</script>

<style scoped>
  .container{
    margin: 28px 30px 0;
    height: 360px;
    position: relative;
    background: url("../../../assets/img/report/zzbj_jcjg_icon.png") no-repeat;
  }
  .column{
    position: absolute;
    font-family: SourceHanSansCN-Regular;
    font-size: 24px;
    top: 60px;
    left: 40px;
    /*z-index: 20;*/
  }
  .classify{
    display: inline-block;
    color: transparent;
    margin-left: 79px;
    height: 70px;
    padding-top: 110px;
  }
  .box{
    width: 100%;
    margin: 0 auto;
    height: 280px;
  }
  #main{
    width: 100%;
    height: 280px;
    cursor: pointer;
    padding-top: 20px;
  }

</style>
