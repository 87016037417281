<template>
  <div class="plan-btn" v-if="reportData.vendor_setting.health_scheme_show === 1 ||reportData.vendor_setting.product_advice_show === 1">
    <!--  -->
    <div class="content-left">
      <div class="tip">根据您最具倾向性的体质推荐的个性化健康方案</div>
      <div class="co-btn">
        <div v-if="reportData.vendor_setting.health_scheme_show === 1" class="btn" @click="gotoHealthyPlan">养生方案</div>
<!--        <div v-if="marker === '10.1' && reportData && reportData.product.length > 0 && reportData.vendor_setting.product_advice_show === 1" class="btn btn-two" @click="gotoProduct">产品推荐</div>-->
      </div>
    </div>
    <!--  -->
    <div class="content-right">
      <div v-if="reportData.is_print === 1" class="print" @click="printEvent"><img class="tip-img" src="../../assets/img/physical/dy_jcjg_icon.png"/>打印报告</div>
      <!-- <vue-qr :size="148" :margin="8" :text="url ? url : ''" colorDark="#000" colorLight="#fff"></vue-qr> -->
<!--      <img class="qrcode-img" src="../../assets/img/common/wechatcode.jpg"/>-->
      <img @click="showExplain" :src="reportData.qr_url" v-if="reportData.vendor_setting.qr_code_show === 2" style="width: 146px;height: 146px;">
      <qrcode-vue @click="showExplain" v-else-if="this.shareUrl&&reportData.vendor_setting.qr_code_show === 1" :value='url' size='146'></qrcode-vue>
      <img @click="showExplain" :src="code" v-else-if="this.shareUrl&&reportData.vendor_setting.qr_code_show === 0" style="width: 146px;height: 146px;">
      <div v-else></div>
      <div class="qrcode">微信扫码获取报告</div>
    </div>
    <div v-if="flag">
      <div class="frame">
        <div class="spantitle">
          <img :src="reportData.qr_url" v-if="reportData.vendor_setting.qr_code_show === 2" style="width: 450px;height: 450px;">
          <qrcode-vue v-else-if="this.shareUrl&&reportData.vendor_setting.qr_code_show === 1" :value='url' size='450'></qrcode-vue>
          <img :src="code" v-else-if="this.shareUrl&&reportData.vendor_setting.qr_code_show === 0" style="width: 450px;height: 450px;">
          <div class="fh" @click="showExplain"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryObject } from '../../../utils/common.js';
import code from '../../assets/img/report/wechatcode.png';
const { csr, marker } = queryObject()
import QrcodeVue from 'qrcode.vue'
// import vueQr from 'vue-qr'

export default {
  name: "planbtn",
  props: {
    reportData: Object,
    isChronic: Boolean // 是否是慢病报告
  },
  components: {
    QrcodeVue
  },
  data(){
    return {
      shareUrl:window.location.href,
      code:code,
      flag: false,
      // wxUrl:'https://m17.zhiyuntcm.com/#/wxtzbs/'
      marker
    }
  },
  computed: {
    url(){
      if (this.reportData) {
        const url = `https://${window.location.host}/?category=2#/wxtzbs/${this.reportData.id}`;
        return url
      } else {
        return null
      }
    },
  },
  methods: {
    gotoHealthyPlan(){
      // 养生方案
      const type = this.reportData.type_attention;
      if(type === '高血压' || type === '糖尿病') {
        // 慢病报告(高血压、糖尿病)
        this.$router.push({name: 'Healthchronic'})
      } else {
        // 体质报告
        this.$router.push({name: 'Healthplantzbs'})
      }

    },
    gotoProduct(){
      this.$router.push({name: 'Producttzbs'})
    },
    printEvent(){
      const key1 = this.reportData.id
      let url = '';
      // 打印报告
      if(this.isChronic) { // 慢病报告
        url = `https://${window.location.host}/#/chronic_print/${key1}`;
      } else {
        url = csr ? `https://${window.location.host}/?csr=${csr}&category=2&is_print=1#/reporttzbs/${key1}` : `https://${window.location.host}/?category=2&is_print=1#/reporttzbs/${key1}`;
      }
      console.log(url)
      if (window.zhiyun) {
        window.zhiyun.print(url);
      } else if (window.print) {
        window.print(url);
      }


    },
    showExplain(){
      this.flag=!this.flag
    }
  }
}
</script>

<style lang="scss" scoped>
$contentWidth: 94%;
$leftContent: 3%;
$leftWidth: 82%;
$ysfa: '../../assets/img/physical/k4_jg_icon_g.png';
$jkfa: '../../assets/img/physical/jkfa_jcjg_btn.png';
.qrcode-img {
  width: 148px;
  height: 148px;
}
.plan-btn {
  width: $contentWidth;
  margin-left: $leftContent;
  display: flex;
  flex-direction: row;
  .content-left {
    width: $leftWidth;
    height: 270px;
    background:url($ysfa);
    background-size:100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tip {
      width: 100%;
      text-align: center;
      font-size: 30px;
      color: #feffff;
      margin-top: 30px;
      line-height: 76px;
    }
    .co-btn {
      width: 90%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .btn {
        width: 334px;
        height: 128px;
        background:url($jkfa) no-repeat;
        font-size: 36px;
        font-weight: normal;
        color: #ffffff;
        text-align: center;
        line-height: 127px;
      }
      .btn-two {
        margin-left: 80px;
      }
    }
  }
  .content-right {
    margin-left: 35px;
    .print {
      color: #ffffff;
      font-size: 24px;
      background-color: #00b3f5;
      padding: 13px 7px 13px 7px;
      display: flex;
      align-items: center;
      margin: 4px 0 20px 0;
      .tip-img {
        width: 31px;
        height: 32px;
        margin-right: 6px;
      }

    }
    .qrcode {
      color: #ffffff;
      font-size: 24px;
      background-color: #333333;
      text-align: center;
      width: 148px;
      line-height: 40px;
    }
  }
}
.frame{
  width:1080px;
  height:100%;
  position:absolute;
  top:0px;
  left:0px;
  z-index:99;
  background:rgba(0,0,0,0.6);
}
.spantitle{
  font-size: 42px;
  color:#ffffff;
  font-family:PingFangSC-Medium;
  width:868px;
  height:626px;
  background:url("../../assets/img/menopauseovary/smk_icon.png") no-repeat;
  text-align:center;
  line-height:42px;
  background-size:100% 100%;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  margin-top:615px;
  padding-top:80px;
}
.fh{
  height:67px;
  width: 224px;
  background:url("../../assets/img/menopauseovary/fh_srsj_Icon.png") no-repeat;
  margin: auto;
  left:0;
  right:0;
  margin-top:28px;
}
</style>
