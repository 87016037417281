var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.marker === "10.1"
    ? _c("div", [
        _c("div", { staticClass: "facereport" }, [
          _vm._m(0),
          _c(
            "div",
            {
              staticClass: "leftsty",
              style:
                _vm.facestate === 0
                  ? { paddingTop: "17px", paddingLeft: "20px" }
                  : { padding: "30px 20px" },
              on: {
                click: function($event) {
                  return _vm.checkfacestate(
                    _vm.facestate === 0 ? 2 : _vm.facestate - 1
                  )
                }
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.facestate === 0
                      ? "光泽指数"
                      : _vm.facestate === 1
                      ? "面色"
                      : _vm.facestate === 2
                      ? "唇色"
                      : ""
                  ) +
                  " "
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "centersty",
              style:
                _vm.facestate === 2
                  ? {
                      paddingTop: "17px",
                      paddingLeft: "20px",
                      fontSize: "42px",
                      lineHeight: "48px"
                    }
                  : { padding: "42px 20px" }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.facestate === 0
                      ? "面色"
                      : _vm.facestate === 1
                      ? "唇色"
                      : _vm.facestate === 2
                      ? "光泽指数"
                      : ""
                  ) +
                  " "
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "rightsty",
              style:
                _vm.facestate === 1
                  ? { paddingTop: "17px", paddingLeft: "20px" }
                  : { padding: "30px 20px" },
              on: {
                click: function($event) {
                  return _vm.checkfacestate(
                    _vm.facestate === 2 ? 0 : _vm.facestate + 1
                  )
                }
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.facestate === 0
                      ? "唇色"
                      : _vm.facestate === 1
                      ? "光泽指数"
                      : _vm.facestate === 2
                      ? "面色"
                      : ""
                  ) +
                  " "
              )
            ]
          ),
          _c("div", { staticClass: "resultL" }, [
            _vm._m(1),
            _c("div", { staticClass: "result-content" }, [
              _c("div", { staticClass: "imgstyL" }, [
                _vm.facestate === 0
                  ? _c(
                      "div",
                      {
                        staticClass: "face-gz",
                        staticStyle: { width: "100%", border: "none" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              height: "100%",
                              width: "96%",
                              "margin-left": "2%"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  height: "37%",
                                  "margin-top": "20px"
                                }
                              },
                              [
                                _c("div", { staticClass: "circular1" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "font-size": "34px",
                                        "line-height": "112px"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.facereport.face_L))]
                                  )
                                ]),
                                _c("div", { staticClass: "circular2" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "font-size": "34px",
                                        "line-height": "112px"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.facereport.face_a))]
                                  )
                                ]),
                                _c("div", { staticClass: "circular3" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "font-size": "34px",
                                        "line-height": "112px"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.facereport.face_b))]
                                  )
                                ])
                              ]
                            ),
                            _vm._m(2),
                            _vm._m(3),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-top": "12px",
                                  "text-align": "left"
                                }
                              },
                              [
                                _c("span", { staticClass: "span-tip" }, [
                                  _vm._v(
                                    "结果：" +
                                      _vm._s(
                                        _vm.facereport.face_color_info.name
                                      )
                                  )
                                ]),
                                _c("br"),
                                _c("span", { staticClass: "span-tip" }, [
                                  _vm._v(
                                    "提示：" +
                                      _vm._s(
                                        _vm.facereport.face_color_info
                                          .content[0] === "正常"
                                          ? ""
                                          : "常见于"
                                      ) +
                                      _vm._s(
                                        _vm.facereport.face_color_info
                                          .content[0]
                                      )
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.facestate === 1
                  ? _c(
                      "div",
                      {
                        staticClass: "face-gz",
                        staticStyle: { width: "100%", border: "none" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              height: "100%",
                              width: "96%",
                              "margin-left": "2%"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  height: "37%",
                                  "margin-top": "20px"
                                }
                              },
                              [
                                _c("div", { staticClass: "circular1" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "font-size": "34px",
                                        "line-height": "112px"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.facereport.lip_L))]
                                  )
                                ]),
                                _c("div", { staticClass: "circular2" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "font-size": "34px",
                                        "line-height": "112px"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.facereport.lip_a))]
                                  )
                                ]),
                                _c("div", { staticClass: "circular3" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "font-size": "34px",
                                        "line-height": "112px"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.facereport.lip_b))]
                                  )
                                ])
                              ]
                            ),
                            _vm._m(4),
                            _vm._m(5),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-top": "12px",
                                  "text-align": "left"
                                }
                              },
                              [
                                _c("span", { staticClass: "span-tip" }, [
                                  _vm._v(
                                    "结果：" +
                                      _vm._s(_vm.facereport.lip_color_info.name)
                                  )
                                ]),
                                _c("br"),
                                _c("span", { staticClass: "span-tip" }, [
                                  _vm._v(
                                    "提示：" +
                                      _vm._s(
                                        _vm.facereport.lip_color_info
                                          .content[0] === "正常"
                                          ? ""
                                          : "常见于"
                                      ) +
                                      _vm._s(
                                        _vm.facereport.lip_color_info.content[0]
                                      )
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.facestate === 2
                  ? _c(
                      "div",
                      {
                        staticClass: "face-gz",
                        staticStyle: { width: "100%", border: "none" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              height: "100%",
                              width: "96%",
                              "margin-left": "2%"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  height: "37%",
                                  "margin-top": "20px"
                                }
                              },
                              [
                                _c("div", { staticClass: "circular4" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "font-size": "34px",
                                        "line-height": "112px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.facereport.gloss_indicator.toFixed(
                                            2
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ])
                              ]
                            ),
                            _vm._m(6),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-top": "37px",
                                  "text-align": "left"
                                }
                              },
                              [
                                _c("span", { staticClass: "span-tip" }, [
                                  _vm._v(
                                    "结果：" +
                                      _vm._s(
                                        _vm.facereport.face_gloss_info.name
                                      )
                                  )
                                ]),
                                _c("br"),
                                _c("span", { staticClass: "span-tip" }, [
                                  _vm._v(
                                    "提示：" +
                                      _vm._s(
                                        _vm.facereport.face_gloss_info
                                          .content[0] === "正常"
                                          ? ""
                                          : "常见于"
                                      ) +
                                      _vm._s(
                                        _vm.facereport.face_gloss_info
                                          .content[0]
                                      )
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ]),
            _c("div", {
              staticClass: "back",
              staticStyle: { "margin-top": "38px" },
              on: { click: _vm.returnto }
            })
          ]),
          _c(
            "div",
            { staticClass: "face-lab", on: { click: _vm.returnToLab } },
            [
              _c("i", [_vm._v("Lab颜色空间及相关数值意义")]),
              _c(
                "span",
                {
                  staticStyle: {
                    color: "#006A95",
                    float: "right",
                    "margin-right": "15px"
                  }
                },
                [_vm._v("点击了解一下")]
              )
            ]
          )
        ])
      ])
    : _c(
        "div",
        {
          staticClass: "facereport",
          style:
            _vm.access === "web" ? { height: "1920px" } : { height: "100%" }
        },
        [
          _vm.facereport
            ? _c("FaceDetail", { attrs: { isOnlyFace: !_vm.isFromReportHome } })
            : _vm._e()
        ],
        1
      )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "face-top", staticStyle: { "margin-top": "80px" } },
      [
        _c("span", { staticClass: "title" }, [_vm._v("面诊结果")]),
        _c("span", {
          staticClass: "title-border",
          staticStyle: { top: "154px" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "imgsty",
        staticStyle: { "border-bottom": "none", height: "352px" }
      },
      [
        _c("img", {
          staticStyle: { height: "314px", "margin-top": "25px" },
          attrs: {
            src: require("../../assets/img/menopauseovary/mxjg.gif"),
            alt: "面诊图片"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "margin-top": "6px" } }, [
      _c("span", { staticClass: "spansty" }, [_vm._v("L值")]),
      _c("span", { staticClass: "spansty" }, [_vm._v("A值")]),
      _c("span", { staticClass: "spansty" }, [_vm._v("B值")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "margin-top": "20px" } }, [
      _c("span", { staticClass: "spaninfo" }, [
        _vm._v("L值越大面色越亮、有光泽，a值越大越红，b值越大越青黑")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "margin-top": "6px" } }, [
      _c("span", { staticClass: "spansty" }, [_vm._v("L值")]),
      _c("span", { staticClass: "spansty" }, [_vm._v("A值")]),
      _c("span", { staticClass: "spansty" }, [_vm._v("B值")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "margin-top": "20px" } }, [
      _c("span", { staticClass: "spaninfo" }, [
        _vm._v("L值越大唇色越亮、有光泽，a值越大越淡，b值越大越紫暗")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "margin-top": "2px" } }, [
      _c("span", { staticClass: "spansty" }, [_vm._v("面部光泽指数")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }