var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wxchronicscheme" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("健康方案")]),
      _vm._l(_vm.buttontype, function(item, index) {
        return _c("div", { key: index }, [
          _c("div", { staticClass: "header" }, [
            _c("img", {
              attrs: {
                src: require("../../../assets/img/wx/wx_jkfabt_jcjg.png")
              }
            }),
            _c("span", [_vm._v(_vm._s(item))]),
            _c("img", {
              attrs: {
                src: require("../../../assets/img/wx/wx_jkfabt1_jcjg.png")
              }
            })
          ]),
          item === "饮食调养"
            ? _c(
                "div",
                { staticClass: "zero" },
                [
                  _c("div", { staticClass: "goodfood" }, [
                    _vm._m(0, true),
                    _c("div", { staticClass: "right" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.goodfood.content[0]) + " ")
                      ])
                    ])
                  ]),
                  _vm.badfood.content.length > 0
                    ? _c("div", { staticClass: "goodfood badfood" }, [
                        _vm._m(1, true),
                        _c("div", { staticClass: "right" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.badfood.content[0]) + " ")
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _c(
                    "van-tabs",
                    {
                      attrs: {
                        ellipsis: false,
                        swipeable: "",
                        "title-active-color": "#30c3fa",
                        color: "#30c3fa",
                        "title-inactive-color": "#333333"
                      },
                      model: {
                        value: _vm.active,
                        callback: function($$v) {
                          _vm.active = $$v
                        },
                        expression: "active"
                      }
                    },
                    _vm._l(_vm.dietTherapy, function(item, index) {
                      return _c(
                        "van-tab",
                        { key: index, attrs: { title: item.name } },
                        [
                          _c("img", {
                            staticClass: "commom-img",
                            attrs: {
                              src:
                                _vm.reportData.solutions.asset_prefix +
                                item.asset[0],
                              alt: "加载失败"
                            }
                          }),
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("烹煮方法：")
                          ]),
                          _vm._l(item.content, function(step, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "item-span" },
                              [_vm._v(_vm._s(step))]
                            )
                          })
                        ],
                        2
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          item === "茶饮养生"
            ? _c(
                "div",
                [
                  _c(
                    "van-tabs",
                    {
                      attrs: {
                        ellipsis: false,
                        swipeable: "",
                        "title-active-color": "#30c3fa",
                        color: "#30c3fa",
                        "title-inactive-color": "#333333"
                      },
                      model: {
                        value: _vm.activeTea,
                        callback: function($$v) {
                          _vm.activeTea = $$v
                        },
                        expression: "activeTea"
                      }
                    },
                    _vm._l(_vm.tea, function(item, index) {
                      return _c(
                        "van-tab",
                        { key: index, attrs: { title: item.name } },
                        [
                          _c("img", {
                            staticClass: "commom-img",
                            attrs: {
                              src:
                                _vm.reportData.solutions.asset_prefix +
                                item.asset[0],
                              alt: "加载失败"
                            }
                          }),
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("泡煮方法：")
                          ]),
                          _c("span", { staticClass: "item-span" }, [
                            _vm._v(_vm._s(item.content && item.content[0]))
                          ]),
                          item.content.length > 1
                            ? _c("div", { staticClass: "item-title" }, [
                                _vm._v("功效：")
                              ])
                            : _vm._e(),
                          _c("span", { staticClass: "item-span" }, [
                            _vm._v(_vm._s(item.content && item.content[1]))
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          item === "经典药方"
            ? _c(
                "div",
                [
                  _c(
                    "van-tabs",
                    {
                      attrs: {
                        ellipsis: false,
                        swipeable: "",
                        "title-active-color": "#30c3fa",
                        color: "#30c3fa",
                        "title-inactive-color": "#333333"
                      },
                      model: {
                        value: _vm.activejdyf,
                        callback: function($$v) {
                          _vm.activejdyf = $$v
                        },
                        expression: "activejdyf"
                      }
                    },
                    _vm._l(_vm.jdyf, function(item, index) {
                      return _c(
                        "van-tab",
                        { key: index, attrs: { title: item.name } },
                        [
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("药物组成：")
                          ]),
                          _c("span", { staticClass: "item-span" }, [
                            _vm._v(_vm._s(item.form))
                          ]),
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("功效：")
                          ]),
                          _c("span", { staticClass: "item-span" }, [
                            _vm._v(_vm._s(item.effect))
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          item === "预防保健操"
            ? _c(
                "div",
                _vm._l(_vm.exercises.content, function(item, index) {
                  return _c("div", { key: index }, [
                    index % 2 === 0
                      ? _c("div", { staticClass: "item-title" }, [
                          _vm._v(_vm._s(item))
                        ])
                      : _c("div", { staticClass: "item-span" }, [
                          _vm._v(_vm._s(item))
                        ])
                  ])
                }),
                0
              )
            : _vm._e(),
          item === "穴位按压" && _vm.reportData.type_attention === "糖尿病"
            ? _c("div", [
                _c("div", { staticClass: "item-span" }, [
                  _vm._v(_vm._s(_vm.acupoint[0].content[0]))
                ]),
                _c("div", { staticClass: "item-span" }, [
                  _vm._v(_vm._s(_vm.acupoint[0].content[1]))
                ]),
                _c("div", { staticClass: "item-span" }, [
                  _vm._v(_vm._s(_vm.acupoint[0].content[2]))
                ])
              ])
            : _vm._e(),
          item === "穴位按压" && _vm.reportData.type_attention === "高血压"
            ? _c(
                "div",
                [
                  _c(
                    "van-tabs",
                    {
                      attrs: {
                        ellipsis: false,
                        swipeable: "",
                        "title-active-color": "#30c3fa",
                        color: "#30c3fa",
                        "title-inactive-color": "#333333"
                      },
                      model: {
                        value: _vm.activeAcupoint,
                        callback: function($$v) {
                          _vm.activeAcupoint = $$v
                        },
                        expression: "activeAcupoint"
                      }
                    },
                    _vm._l(_vm.acupoint, function(item, index) {
                      return _c(
                        "van-tab",
                        { key: index, attrs: { title: item.name } },
                        [
                          _c("img", {
                            staticClass: "commom-img",
                            attrs: {
                              src:
                                _vm.reportData.solutions.asset_prefix +
                                item.asset[0],
                              alt: "加载失败"
                            }
                          }),
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("定位：")
                          ]),
                          _c("span", { staticClass: "item-span" }, [
                            _vm._v(_vm._s(item.content[0]))
                          ]),
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("按摩方法：")
                          ]),
                          _c("span", { staticClass: "item-span" }, [
                            _vm._v(_vm._s(item.content[1]))
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          item === "艾灸"
            ? _c(
                "div",
                [
                  _c(
                    "van-tabs",
                    {
                      attrs: {
                        ellipsis: false,
                        swipeable: "",
                        "title-active-color": "#30c3fa",
                        color: "#30c3fa",
                        "title-inactive-color": "#333333"
                      },
                      model: {
                        value: _vm.activeMoxibustion,
                        callback: function($$v) {
                          _vm.activeMoxibustion = $$v
                        },
                        expression: "activeMoxibustion"
                      }
                    },
                    _vm._l(_vm.moxibustion, function(item, index) {
                      return _c(
                        "van-tab",
                        { key: index, attrs: { title: item.name } },
                        [
                          _c("img", {
                            staticClass: "commom-img",
                            attrs: {
                              src:
                                _vm.reportData.solutions.asset_prefix +
                                item.asset[0],
                              alt: "加载失败"
                            }
                          }),
                          _c("span", { staticClass: "item-span" }, [
                            _vm._v(_vm._s(item.content[0]))
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          item === "足浴疗法"
            ? _c(
                "div",
                [
                  _c(
                    "van-tabs",
                    {
                      attrs: {
                        ellipsis: false,
                        swipeable: "",
                        "title-active-color": "#30c3fa",
                        color: "#30c3fa",
                        "title-inactive-color": "#333333"
                      },
                      model: {
                        value: _vm.activeFootBath,
                        callback: function($$v) {
                          _vm.activeFootBath = $$v
                        },
                        expression: "activeFootBath"
                      }
                    },
                    _vm._l(_vm.footBath, function(item, index) {
                      return _c(
                        "van-tab",
                        { key: index, attrs: { title: item.name } },
                        [
                          _c("img", {
                            staticClass: "commom-img",
                            attrs: {
                              src:
                                _vm.reportData.solutions.asset_prefix +
                                item.asset[0],
                              alt: "加载失败"
                            }
                          }),
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v(
                              _vm._s(
                                _vm.reportData.type_attention === "糖尿病"
                                  ? "方药组成："
                                  : "足浴配方："
                              )
                            )
                          ]),
                          _c("span", { staticClass: "item-span" }, [
                            _vm._v(_vm._s(item.content[0]))
                          ]),
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v(
                              _vm._s(
                                _vm.reportData.type_attention === "糖尿病"
                                  ? "适应症："
                                  : "操作："
                              )
                            )
                          ]),
                          _c("span", { staticClass: "item-span" }, [
                            _vm._v(_vm._s(item.content[1]))
                          ]),
                          _vm.reportData.type_attention === "糖尿病"
                            ? _c("div", { staticClass: "item-title" }, [
                                _vm._v("用法用量：")
                              ])
                            : _vm._e(),
                          _vm.reportData.type_attention === "糖尿病"
                            ? _c("span", { staticClass: "item-span" }, [
                                _vm._v(_vm._s(item.content[2]))
                              ])
                            : _vm._e()
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          item === "精神调养"
            ? _c("div", [
                _c("span", { staticClass: "item-span" }, [
                  _vm._v(_vm._s(_vm.mental.content[0]))
                ])
              ])
            : _vm._e(),
          item === "运动健身"
            ? _c("div", [
                _c("span", { staticClass: "item-span" }, [
                  _vm._v(_vm._s(_vm.sportHealth.content[0]))
                ])
              ])
            : _vm._e()
        ])
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left" }, [
      _c("img", {
        attrs: { src: require("../../../assets/img/wx/wx_ys_jcjg.png") }
      }),
      _c("span", [_vm._v("宜食")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left" }, [
      _c("img", {
        attrs: { src: require("../../../assets/img/wx/wx_js_jcjg.png") }
      }),
      _c("span", { staticStyle: { color: "#f86426" } }, [_vm._v("少食")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }