var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.xw
    ? _c("div", { staticClass: "headerTabThree" }, [
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.xw.name))]),
        _c(
          "div",
          { staticClass: "voidesty" },
          [
            _c("video-player", {
              ref: "videoPlayer",
              staticClass: "video-player vjs-custom-skin",
              attrs: { playsinline: true, options: _vm.playerOptions }
            })
          ],
          1
        ),
        _c("span", { staticClass: "content-text" }, [_vm._v("【位置】：")]),
        _c("span", { staticClass: "content-text" }, [
          _vm._v(_vm._s(_vm.xw.content[0]))
        ]),
        _c("span", { staticClass: "content-text" }, [_vm._v("【操作】：")]),
        _c("span", { staticClass: "content-text" }, [
          _vm._v(_vm._s(_vm.xw.content[1]))
        ]),
        _c("span", { staticClass: "content-text" }, [_vm._v("【功效】：")]),
        _c("span", { staticClass: "content-text" }, [
          _vm._v(_vm._s(_vm.xw.content[2]))
        ]),
        _c("span", { staticClass: "content-text" }, [_vm._v("【主治】：")]),
        _c("span", { staticClass: "content-text" }, [
          _vm._v(_vm._s(_vm.xw.content[3]))
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }