var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.reportData.has_pulse ? "result-btn has_pulse" : "result-btn" },
    [
      _c("div", { staticClass: "btn", on: { click: _vm.lookFaceResult } }, [
        _vm._v("面诊结果")
      ]),
      _c("div", { staticClass: "btn", on: { click: _vm.lookTongueResult } }, [
        _vm._v("舌诊结果")
      ]),
      _vm.reportData.has_pulse
        ? _c(
            "div",
            { staticClass: "btn", on: { click: _vm.lookPulseResult } },
            [_vm._v("脉诊结果")]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }