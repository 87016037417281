// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/report/pulse/mxbt_jkjx_icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.title[data-v-74c5a071] {\n  width: 79%;\n  height: 110px;\n  margin: 82px auto;\n  text-align: center;\n  font-size: 48px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: 100% 100%;\n  line-height: 110px;\n}\n.basic[data-v-74c5a071] {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.basic>div[data-v-74c5a071] {\n  width: 30%;\n  /*text-align: center;*/\n  font-size: 16px;\n  height: 185px;\n  border: 1px solid #01477d;\n  background-color: #001d34;\n}\n.heart[data-v-74c5a071],\n.blood[data-v-74c5a071]{\n  font-size: 42px;\n  background-repeat: no-repeat;\n  background-size: 16px auto;\n  padding-left: 24px;\n  padding-bottom: 11px;\n  text-align: center;\n  margin-top: 40px;\n}\n.zhi[data-v-74c5a071] {\n  text-align: center;\n  color: #00e4ff;\n  font-size: 42px;\n}\n.unit[data-v-74c5a071] {\n  font-size: 30px;\n  text-align: center;\n}\n.notice[data-v-74c5a071] {\n  margin-top: 22px;\n  /*margin-bottom: 1rem;*/\n  padding: 25px 50px;\n  font-size: 42px;\n  color: #00e4ff;\n  background-color: #041937;\n  line-height: 60px;\n}\n\n", ""]);
// Exports
module.exports = exports;
