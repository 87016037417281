<template>
  <div class="knowledge">
    <h3>知识库依据</h3>
    <p>[1]李灿东,吴承玉.中医诊断学 [M].北京:中国中医药出版社,2018.</p>
    <p>[2]万学红,卢雪峰.诊断学[M].北京:人民卫生出版社,2018.</p>
    <p>[3]周仲瑛.中医内科学[M].北京：中国中医药出版社,2017.</p>
    <p>[4]葛均波,徐永健,王辰.内科学[M].北京:人民卫生出版社,2018.</p>
    <p>[5]谢梦洲,朱天民.中医药膳学[M].北京:中国中医药出版社,2016.</p>
    <p>[6]李其中.中医体质养生指南[M].上海:复旦大学出版社,2013.</p>
    <p>[7]匡调元. 辨质论治通识读本——中国式个性化诊疗[M].北京:中国中医药出版社,2016.</p>
    <footer>图像数据库、算法研究来源于国家级、市级等10项课题资助</footer>
  </div>
</template>

<script>
  export default {
    name: "knowledge"
  }
</script>

<style scoped>
  .knowledge {
    margin-top: 50px;
  }

  .knowledge h3 {
    font-size: 20px;
    font-weight: bold;
    line-height: 40px;
    letter-spacing: 0px;
    color: rgb(51, 51, 51);
    margin-top: 13px;
    border-bottom: 2px solid rgb(153, 153, 153);
  }

  .knowledge p {
    font-size: 16px;
    line-height: 24px;
    color: rgb(51, 51, 51);
    margin-top: 8px;
    letter-spacing: 2px;
  }

  .knowledge footer {
    font-size: 16px;
    line-height: 30px;
    color: rgb(51, 51, 51);
    padding: 10px 0 30px 0;
    letter-spacing: 2px;
  }

</style>