<template>
  <div v-if="report" style="display: flex;overflow: auto;height: 100%">
    <div class="healthprogram" :style="access === 'web' ? { height: '1920px' } : { height: '100%' }">
      <div style="height: 170px">
        <img :src="fh" @click="returnto" class="back">
        <span class="title">健康方案</span>
      </div>
      <div style="height: 206px;border-bottom: 3px solid #2F7284;overflow: auto;width: 100%;margin: 0 auto;">
        <div :style="{width:this.widval+'px'}">
          <div class="healthprogram-top" v-for="(item,index) in jkfnsx" :key="index" @click="checkType(item)" :style="{'opacity':item === currentIndex?'1':'0.5'}">
            <img :src="buttonimg[item]" style="height: 70px" alt="logo"><br />
            {{buttontype[item]}}
          </div>
        </div>
      </div>
      <div style="height: 1480px;padding: 0 5%;border-radius: 5px;width: 90%;margin-top: 30px;">
        <div v-if="currentIndex === 0">
          <div style="height: 150px">
            <div class="healthprogram-one" style="border-right: 3px solid #2F7284;padding-right: 4%">
              <div style="text-align: center;height: 35px">
                <img :src="ys" style="width: 35px;height: 35px;margin-right: 10px;vertical-align: top;" alt="logo">
                <span class="eating">宜食</span>
              </div>
              <div style="margin-top: 20px;width: 100%">
                <span class="foodname" v-for="(item , index) in this.goodfood" :key="index">{{item.name}}</span>
              </div>
            </div>
            <div class="healthprogram-one" style="padding-left: 3%">
              <div style="text-align: center">
                <img :src="js" style="width: 35px;height: 35px;margin-right: 10px;vertical-align: top;" alt="logo">
                <span class="eating">忌食</span>
              </div>
              <div style="margin-top: 20px;width: 100%">
                <span class="foodname" v-for="(item , index) in this.badfood" :key="index">{{item.name}}</span>
              </div>
            </div>
          </div>
          <div style="margin-top: 28px;height: 60px">
            <span class="medicinal" style="margin-top: 20px">推荐药膳</span>
          </div>
          <div style="overflow: auto;-webkit-overflow-scrolling: touch">
            <div :style="{width: this.contentwid+'px',height: '1200px',marginTop: '15px'}">
              <div class="dishes" v-for="(item ,index) in this.content" :key="index">
                <div style="height: 98%;overflow: auto">
                  <span class="tip">{{item.name}}</span>
                  <span class="medicinal" style="margin-top: 9px">配料：</span>
                  <div v-for="(items ,index) in item.asset" :key="index" style="width: 33.3%;display: inline-block;text-align: center">
                    <img :src="report.solutions.asset_prefix+items[1]" alt="加载失败" style="width: 221px;height: 161px;margin-top: 30px;border-radius: 10px">
                    <span class="imgtext">{{items[0]}}</span>
                  </div>
                  <span class="medicinal" style="margin-top: 40px">清单：</span>
                  <span class="medicinal-explain">{{item.content[0]}}</span>
                  <span class="medicinal" style="margin-top: 40px">烹煮方法：</span>
                  <span class="medicinal-explain">{{item.content[1]}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="currentIndex === 1" style="height: 100%">
          <div class="cyys" v-if="tea">
            <div style="height: 98%;overflow: auto">
              <span class="teasty">{{tea.name}}</span>
              <div style="text-align: center">
                <img :src="report.solutions.asset_prefix+tea.asset" alt="加载失败" style="height: 481px;width: 861px;margin-top: 50px;border-radius: 20px">
              </div>
              <span class="medicinal" style="margin-top: 30px">配料：</span>
              <span class="medicinal-explain">{{tea.content && tea.content[0]}}</span>
              <span class="medicinal" style="margin-top: 30px">泡煮方法：</span>
              <span class="medicinal-explain">{{tea.content && tea.content[1]}}</span>
            </div>
          </div>
        </div>
        <div v-if="currentIndex === 2">
          <div :style="marker === '10.1' ? { height: '1350px' } : { height: '1440px' }">
            <div class="cyys" style="padding-top: 10px;position: relative;height: 89%">
              <div v-for="(item , index) in jdtf" :key="index" :style="index === jdtf.length-1?{ height: '30.5%', paddingTop: '20px'}:{height: '30.5%', paddingTop: '20px'}">
                <span class="teasty" style="color: #D67206;font-weight: bold;font-size: 36px">{{item.name}}</span>
                <span class="medicinal" style="margin-top: 10px;font-size: 30px">药物组成：</span>
                <span class="medicinal-explain" style="margin-top: 5px;line-height: 40px">{{item.content[0]}}</span>
                <span class="medicinal" style="margin-top: 10px;font-size: 30px">功效：</span>
                <span class="medicinal-explain" style="margin-top: 5px;line-height: 40px">{{item.content[1]}}</span>
                <span class="medicinal" style="margin-top: 10px;font-size: 30px" v-if="csr=== 'HUASHI'&& item.content[2]">推荐用药：{{item.content[2]?item.content[2]:''}}{{item.content[3]?'、'+item.content[3]:''}}{{item.content[4]?'、'+item.content[4]:''}}{{item.content[5]?'、'+item.content[5]:''}}</span>
              </div>
            </div>
            <div :style="marker === '10.1'?{color: 'red', fontSize: '20px', textAlign: 'right', marginTop: '-20px'}:{color: 'red', fontSize: '30px', textAlign: 'right', marginTop: '10px'}">
              *请在医生、药师指导下购买和服用
            </div>
          </div>
        </div>
        <div v-if="currentIndex === 3" style="height: 100%">
          <div class="cyys" style="height: 85%;padding-bottom: 100px;overflow: auto">
            <div style="height: 100%;overflow: auto">
              <span class="teasty">{{acupoint.name}}</span>
              <img :src="report.solutions.asset_prefix+acupoint.asset" alt="加载失败" style="margin-top: 50px;width: 860px;height: 480px;border-radius: 20px">
              <span class="medicinal" style="margin-top: 11px">定位：</span>
              <span class="medicinal-explain" style="margin-top: 30px">{{acupoint.content && acupoint.content[0]}}</span>
              <span class="medicinal" style="margin-top: 30px">按摩方法：</span>
              <span class="medicinal-explain">{{acupoint.content && acupoint.content[1]}}</span>
            </div>
          </div>
        </div>
        <div v-if="currentIndex === 4" style="text-align: center">
          <div class="zygf">
            <video-player
              v-if="this.poster"
              class="video-player vjs-custom-skin"
              ref="videoPlayer"
              :playsinline="true"
              :options="playerOptions"
            >
            </video-player>
          </div>
          <div class="divleft" v-if="marker === '10.1'"></div>
        </div>
        <!-- 音乐养生 -->
        <div v-if="currentIndex === 5" class="music-healthy">
          <!-- <div class="musicsty" :style="{'background': `url(${report.solutions.asset_prefix}/养生音乐/脾虚/渔舟唱晚.jpg) no-repeat`,'background-size':'100% 100%'}">
            <span class="yysty">音乐养生:{{music.title}}</span>
            <audio class="audiosty" :src="music.url" v-if="music" controls="controls"></audio>
          </div> -->
          <div v-if="marker === '10.1'">
            <div class="musicsty" :style="{'background': `url(${report.solutions.asset_prefix}/养生音乐/脾虚/渔舟唱晚.jpg) no-repeat`,'background-size':'100% 100%'}">
              <span class="yysty">音乐养生:{{music.title}}</span>
              <audio class="audiosty" :src="music.url" v-if="music" controls="controls"></audio>
            </div>
          </div>
          <div v-else>
            <div class="music-icon">
              <!-- add by cll -->
              <img :class="isPlay ? 'circle ' : ''" src='../../assets/img/common/music_jkfa_icon.png' alt=""/>
              <img class="music-btn" @click="startPlay()" :src="!isPlay ? require('../../assets/img/common/bf_jkfa_icon.png') : require('../../assets/img/common/zt_jkfa_icon.png') "/>
              <audio v-if="music" class="audio" :src="music.url" controls="controls" id="player"></audio>
            </div>
            <div class="music-tip">
              <!-- add by cll -->
              <img :class="isPlay ? 'active' : ''" src='../../assets/img/common/music1_jkfa_icon.png' alt=""/>
            </div>
            <div class="music-name">{{music.title}}</div>
          </div>

        </div>

        <div v-if="currentIndex === 6" style="height: 100%">
          <div class="cyys" style="height: 70%">
            <span v-for="(item,index) in hg.content" :key="index">{{item}}</span>
          </div>
        </div>
        <div v-if="currentIndex === 7" style="height: 100%">
          <div class="cyys" style="height: 88%">
            <div class="bjyztop">
              <span class="bjyzspan" style="position: relative;top: 53px;left: 141px">{{principle.content[1][0]}}</span>
              <span class="bjyzspan" style="position: relative;top: 162px;left: -34px">{{principle.content[1][1]}}</span>
              <span class="bjyzspan" style="position: relative;top: 225px;left: 28px">{{principle.content[1][2]}}</span>
              <span class="bjyzspan" style="position: relative;top: 327px;left: -151px">{{principle.content[1][3]}}</span>
            </div>
            <div style="margin-top: 30px;padding: 0 150px;letter-spacing: 4px">
              {{principle.content[0]}}
            </div>
          </div>
        </div>
        <div v-if="currentIndex === 8" style="height: 100%">
          <div class="cyys" style="height: 82%;padding-bottom: 100px;overflow: auto">
            <div style="height: 100%;overflow: auto">
              <div style="color: #fa6060;line-height: 100px;font-size: 30px;border-bottom: 1px solid #2f7284">
                配合特色中药熏蒸方法，效果更佳
              </div>
              <div style="color: #ffffff;margin-top: 60px;line-height: 36px;font-size: 36px">
                取穴：<span style="color: #00fefb">{{xwname.join('、')}}</span>
              </div>
              <div style="color: #ffffff;margin-top: 60px;line-height: 36px;font-size: 36px">
                方法：<span style="font-size: 30px">将艾炷点燃，粘贴在相应的穴位上，每穴2-3炷。</span>
              </div>
              <div style="color: #ffffff;margin-top: 60px;line-height: 36px;font-size: 36px">
                取穴方法：
              </div>
              <div v-for="(item, index) in xw" :key="index">
                <div style="margin-top: 40px;line-height: 40px;color: #00fefb;font-size: 30px">
                  {{item.name}}:<span style="color: #ffffff">{{item.content[0]}}</span>
                </div>
                <div>
                  <img :src="report.solutions.asset_prefix+item.asset[0]" alt="加载失败" style="margin-top: 10px;width: 860px;border-radius: 20px">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="currentIndex === 9" style="height: 100%">
          <div class="cyys" style="height: 89%;padding: 40px 40px">
            <div style="height: 96%;overflow: auto">
              <div style="color: #ffffff;line-height: 80px;font-size: 36px;border-bottom: 1px solid #2f7284">【熏蒸法简介】</div>
              <div style="padding-top: 30px;width: 100%">
                <div style="color: #ffffff;line-height: 50px;font-size: 30px;float: left;width: 49%;text-indent: 2em">
                  中药熏蒸疗法源远流长，早在《黄帝内经》中就有记载。在唐代孙思邈所著的《千金方》中已经将熏洗疗法广泛地用到内、外、妇、儿、五官、皮肤等各科的疾病治疗和预防。在元代《御药院方》、清代的《慈禧光绪医方选仪》中有大量运用熏蒸药方治疗各类疾病的记录。熏蒸疗法在宫廷中的运用是受到了高度重视。
                </div>
                <div style="color: #ffffff;line-height: 50px;text-align: right;font-size: 30px;float: left;width: 51%;padding-top: 14px">
                  <img :src="tu" style="width: 392px">
                </div>
              </div>
              <div style="clear: both"/>
              <div style="color: #ffffff;line-height: 80px;font-size: 36px;border-bottom: 1px solid #2f7284">【熏蒸的作用】</div>
              <div style="padding: 30px 0;width: 100%;font-size: 30px">
                <div>1. 净血排毒：可改善人体新陈代谢，促进血液循环，帮助 排除体内废物及肝肾毒素，对各种水肿有特效。</div>
                <div>2. 清毒杀菌：可深入皮下组织，杀菌消毒，清除污垢，帮 助去死皮，使肌肤美白。</div>
                <div>3. 清除疲劳：可使全身放松，缓解压力，心情愉快，恢复 活力。</div>
                <div>4. 活化细胞：可使全身细胞活跃，有效改善体质，增强免 疫能力。</div>
                <div>5. 强化机能：可刺激人体微循环系统，改善人体各种机能。 </div>
                <div>6. 减肥瘦身：可帮助排汗，消除多余热量，燃烧多余脂肪， 使身体苗条，凹凸有形。 </div>
                <div>7. 美容除斑：可调节内分泌，预防妇科病，消除色斑。</div>
              </div>
              <div style="color: #ffffff;line-height: 80px;font-size: 36px;border-bottom: 1px solid #2f7284">【调养原则】</div>
              <div style="padding: 30px 0;width: 100%">
                <span style="color: #00fefb;line-height: 54px;font-size: 30px">{{fumigation.name}}</span>
                <span style="color: #fa6060;line-height: 54px;font-size: 24px">（配合艾灸，效果更佳，方法见居家艾灸）</span>
              </div>
              <div style="color: #ffffff;line-height: 80px;font-size: 36px;border-bottom: 1px solid #2f7284">【五行对应脏腑】</div>
              <div style="padding: 30px 0;width: 100%">
                <div v-for="(item , index) in fumigation['content']" :key="index">
                  <img v-if="item%2!==0" :src="wximg[item]" style="float: left;width: 170px;margin-right: 10px">
                </div>
                <div style="clear: both"/>
                <div style="color: #ffffff;line-height: 40px;font-size: 26px">
                  <div v-for="(item , index) in fumigation['content']" :key="index">
                    <div v-if="index%2 === 0" style="float: left;width: 170px;margin-right: 10px;text-align: center">
                      {{fumigation['content'][index]}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="currentIndex === 10" style="height: 100%">
          <ProductTzbs />
          <ProductDetail v-if="isShowProDetail" />
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import { queryObject } from '../../../utils/common.js';
  import fh from '../../assets/img/report/fh_icon.png';
  import tu from '../../assets/img/report/tu1_jkfa_icon.png';
  import ysyj2 from '../../assets/img/common/ysty2_ysfa_icon.png';
  import cyys2 from '../../assets/img/common/cyys2_jkfa_icon.png';
  import jdyf2 from '../../assets/img/common/jdyf2_ysfa_icon.png';
  import xway2 from '../../assets/img/common/xway2_ysfa_icon.png';
  import zygf2 from '../../assets/img/common/zygf2_ysfa_icon.png';
  import yyys2 from '../../assets/img/common/yyys2_ysfa_icon.png';
  import qjzd2 from '../../assets/img/common/qjzd2_ysfa_icon.png';
  import bjyz2 from '../../assets/img/common/bjyz2_ysfa_icon.png';
  import jjaj2 from '../../assets/img/common/aj1_jkfa_icon.png';
  import wxxz from '../../assets/img/common/wxwz1_jkfa_icon.png';
  import tbtj from '../../assets/img/common/tbtj_jkfa_icon.png';

  import ys from '../../assets/img/report/ys_jkfa_icon.png';
  import js from '../../assets/img/report/js_jkfa_icon.png';
  import jin from '../../assets/img/report/jin_jkfa_icon.png';
  import mu from '../../assets/img/report/mu_jkfa_icon.png';
  import shui from '../../assets/img/report/shui_jkfa_icon.png';
  import huo from '../../assets/img/report/huo_jkfa_icon.png';
  import tu1 from '../../assets/img/report/tu_jkfa_icon.png';

  const { access = '', csr = '',marker = '' } = queryObject();
  import 'video.js/dist/video-js.css';
  import 'vue-video-player/src/custom-theme.css';
  import 'videojs-contrib-hls';
  import ProductTzbs from "../physical/producttzbs";
  import ProductDetail from '../physical/productdetailtzbs.vue';
  export default {
    name: "healthprogram",
    data(){
      return{
        id:'',
        report:'',
        fh:fh,
        tu:tu,
        ysyj2,
        cyys2,
        jdyf2,
        xway2,
        zygf2,
        yyys2,
        qjzd2,
        bjyz2,
        jjaj2,
        wxxz,
        tbtj, // 特别推荐
        ys,
        js,
        jin,
        mu,
        shui,
        huo,
        tu1,
        jkfnsx:'',
        widval:'',
        wximg:{
          金:jin,
          木:mu,
          水:shui,
          火:huo,
          土:tu1
        },
        buttonimg:[
          ysyj2,
          cyys2,
          jdyf2,
          xway2,
          zygf2,
          yyys2,
          qjzd2,
          bjyz2,
          jjaj2,
          wxxz,
          tbtj // 特别推荐 === 产品推荐
        ],
        buttontype:[
          '饮食调养', // 0
          '茶饮养生', // 1
          '经典药方', // 2
          '穴位按压', // 3
          '中医功法', // 4
          '音乐养生', // 5
          '起居指导', // 6
          '保健原则', // 7
          '居家艾灸', // 8
          '五行熏蒸', // 9
          '特别推荐' // 10
        ],
        flag:false,
        currentIndex:0,
        goodfood:'',
        badfood:'',
        symptomsName:'',
        content:'',
        contentwid:'',
        tea:'',
        jdtf:'',
        acupoint:'',
        gf:'',
        image:'',
        csvideo:'',
        sourcessrc:'',
        poster:'',
        music:'',
        hg:'',
        principle:'',
        xw:'',
        xwname:[],
        fumigation:'',
        playerOptions:{
          playbackRates: [0.5, 1.0, 1.5, 2.0],
          autoplay: false,
          muted: false,
          loop: false,
          preload: 'auto',
          language: 'zh-CN',
          aspectRatio: '16:9',
          fluid: true,
          sources: [{
            type: "video/mp4",
            src: '',
          }],
          poster: '',
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true
          }
        },
        isPlay: false, //音乐是否播放 // add by cll
      }
    },
    mounted() {
      const isShowCPTJ = this.$store.state.report.vendor_setting.product_advice_show === 1 &&
      ((this.$store.state.report.product && this.$store.state.report.product.length > 0) ||
        (this.$store.state.report.doctor && this.$store.state.report.doctor.length > 0)
      ) // 是否展示产品推荐
      if (this.report.health_score < 90){
        if (csr === 'SHBAIHUI'){
          this.jkfnsx = [0, 1, 2, 3, 4, 5, 6, 7,8,9 ];
        }else {
          // 判断有无产品推荐

          if(isShowCPTJ) {
            this.jkfnsx = [10, 0, 1, 2, 3, 4, 5, 6, 7];
          } else {
            this.jkfnsx = [0, 1, 2, 3, 4, 5, 6, 7];
          }

        }
      }else if (csr === 'SHBAIHUI'){
        this.jkfnsx = [0, 1,3, 4, 5, 6, 7,8,9];
      }else {
        if(isShowCPTJ) {
          this.jkfnsx = [10, 0, 1, 3, 4, 5, 6, 7];
        } else {
          this.jkfnsx = [0, 1, 3, 4, 5, 6, 7];
        }

      }
      this.widval = this.jkfnsx.length * 174;
      this.symptomsName = this.report.symptoms[0] ? this.report.symptoms[0].name:'健康';
      this.goodfood = this.report.solutions.good_food && this.report.solutions.good_food[this.symptomsName];
      this.badfood = this.report.solutions.bad_food && this.report.solutions.bad_food[this.symptomsName];
      this.content = this.report.solutions.diet_therapy && this.report.solutions.diet_therapy[this.symptomsName];
      this.contentwid = this.content && this.content.length * 850;
      this.tea = this.report.solutions.herb_tea && this.report.solutions.herb_tea[this.symptomsName];
      this.jdtf = this.report.solutions.prescription &&this.report.solutions.prescription[this.symptomsName];
      this.acupoint = this.report.solutions.massage && this.report.solutions.massage[this.symptomsName];
      this.gf = this.report.solutions.video && this.report.solutions.video[this.symptomsName];
      this.image = this.gf && this.gf.asset && this.gf.asset[1].split('/')[1];
      this.csvideo = this.gf && this.gf.asset && this.gf.asset[0].split('/')[1];
      this.sourcessrc = csr=== 'CHENSHANG' || csr === 'SHWYSLSWKJ' || csr === 'SXSZYYY'?this.report.solutions.asset_prefix+'功法无水印'+this.image:this.report.solutions.asset_prefix+this.gf.asset[1];
      this.poster = csr=== 'CHENSHANG' || csr === 'SHWYSLSWKJ' || csr === 'SXSZYYY'?this.report.solutions.asset_prefix+'功法无水印'+this.csvideo:this.report.solutions.asset_prefix+this.gf.asset[0];
      this.playerOptions.sources[0].src = this.poster;
      this.playerOptions.poster = this.sourcessrc;
      this.music = this.getMusic(this.report.solutions,this.symptomsName)
      this.hg = this.report.solutions.health_guidance && this.report.solutions.health_guidance[this.symptomsName];
      this.principle = this.report.solutions.health_principle && this.report.solutions.health_principle[this.symptomsName];
      this.xw = this.report.solutions.moxibustion && this.report.solutions.moxibustion[this.symptomsName];
      this.xw.forEach((e, i) => (
        this.xwname[i] = e.name
      ));
      this.fumigation = this.report.solutions.moxibustion_wuxing && this.report.solutions.moxibustion_wuxing[this.symptomsName];
      // console.log(this.sourcessrc)
      // console.log(this.poster)
    },
    // created() {
    //   this.sourcessrc = csr=== 'CHENSHANG' || csr === 'SHWYSLSWKJ' || csr === 'SXSZYYY'?this.report.solutions.asset_prefix+'功法无水印'+this.image:this.report.solutions.asset_prefix+this.gf.asset[1];
    //   this.poster = csr=== 'CHENSHANG' || csr === 'SHWYSLSWKJ' || csr === 'SXSZYYY'?this.report.solutions.asset_prefix+'功法无水印'+this.csvideo:this.report.solutions.asset_prefix+this.gf.asset[0];
    //   console.log(this.sourcessrc,this.poster)
    // },
    computed: {
      access(){
        return access
      },
      csr(){
        return csr
      },
      marker(){
        return marker
      },
      isShowProDetail() {
        return this.$store.state.is_show_detail // 是否展示详情，点击boxItem 把对应的内容展示
      },
    },
    methods:{
      returnto(){
        this.$router.push({path:'/report/'+this.id});//返回上一层
      },
      checkType(item){
        this.currentIndex = item
      },
      getMusic( solutions, symptoms ){
        const symptom = symptoms? symptoms: '健康';
        const music = solutions && solutions.music && solutions.music[symptom];
        if (!music) return null;
        return {
          title: music.name.replace(/\..*$/, ''),
          url: solutions.asset_prefix+music.asset[0],
        };
      },   
      startPlay() { // add by cll
        let player = document.querySelector('#player')
        if (!this.isPlay) {
          player.play()
          this.isPlay = true
        } else {
          player.pause()
          this.isPlay = false
        }
      },
    },
    beforeMount(){
      this.id = this.$route.params.id
      this.report = this.$route.params.report
    },
    components: {
      ProductTzbs, // 特别推荐
      ProductDetail
    }
  }
</script>

<style lang="scss" scoped>
  .healthprogram{
    width:100%;
    height:100%;
    /*padding: 0 0 0 52px;*/
    background:url("../../assets/img/report/bj1.png")no-repeat;
    background-size:cover;
    overflow:hidden;
  }
  ::-webkit-scrollbar{
    display: none;
  }
  .back{
    position:relative;
    left:49px;
    top:105px;
    width: 152px;
    height: 82px;
  }
  .title{
    font-size: 60px;
    color:#00FEFB;
    font-family:TITLE;
    width:300px;
    height:111px;
    background:url("../../assets/img/report/jssm_icon.png") no-repeat;
    text-align:center;
    line-height:111px;
    background-size:100% 100%;
    position: absolute;
    left: 0;
    top: 83px;
    right: 0;
    margin: 0 auto;
  }
  .healthprogram-top{
    margin-top:50px;
    display:inline-block;
    width:172px;
    height:140px;
    text-align:center;
    vertical-align:middle;
    color:white;
    font-size:30px;
  }
  .healthprogram-one{
    display:inline-block;
    width:46%;
    line-height: 37px;
    height:150px;
    color:white;
    float:left;
  }
  .eating{
    display:inline-block;
    font-size:35px;
    color:#ffffff;
    vertical-align:top;
    line-height:35px;
  }
  .foodname{
    font-size:25px;
    color:#ffffff;
    margin-left:10px;
  }
  .medicinal{
    display:block;
    font-size:36px;
    height:40px;
    color:#ffffff;
  }
  .medicinal-explain{
    margin-top:20px;
    display:block;
    font-size:30px;
    color:#ffffff;
    line-height:45px;
  }
  .dishes{
    display:inline-block;
    width:715px;
    height:75%;
    background:url("../../assets/img/report/tjysbj_ysfa_icon.png");
    background-size:100% 100%;
    margin-left:30px;
    padding:0px 49px 50px 49px;
    float:left;
  }
  .tip{
    display:block;
    font-size:40px;
    height:121px;
    color:#ffffff;
    line-height:93px;
    text-align:center;
    background:url("../../assets/img/report/btbj_ysfa_icon.png")  no-repeat center;
    background-size:500px;
  }
  .imgtext{
    margin-top:11px;
    display:block;
    font-size:24px;
    color:#ffffff;
  }
  .cyys{
    font-size: 36px;
    color:#F1F1F1;
    font-family:SourceHanSansCN-Regular;
    /*width:852px;*/
    height:92%;
    background:url("../../assets/img/report/bk_ysfa_icon.png") no-repeat;
    line-height:60px;
    padding:80px 60px 0 60px;
    background-size:100% 100%;
    margin-top: 32px;
    /*position: absolute;*/
    /*margin: auto;*/
    /*left: 0;*/
    /*right: 0;*/
  }
  .teasty{
    margin-top:10px;
    display:block;
    font-size:42px;
    color:#ffffff;
  }
  .zygf{
    display: inline-block;
    width: 90%;
    height: 500px;
    text-align: center;
    line-height: 100px;
    border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    /*background: #fff;*/
    position: relative;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    margin-top: 50px;
    /*margin: 0 auto;*/
  }
  .divleft{
    position:relative;
    left:841px;
    top:-44px;
    display:block;
    width:30px;
    height:30px;
    background:#15181b;
  }
  // 音乐养生  add by cll
  .music-healthy {
    width: 100%;
    position: relative;
    padding-top: 120px;
    margin-top: 80px;
    font-size: 40px;
    color: #fff;
    .music-icon{
      width: 78%;
      margin-left: 11%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      img {
        width: 100%;
      }
      .music-btn {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 46%;
      }
      .audio {
        position: absolute;
        top: 46%;
        /*width: 0;*/
        /*height: 0;*/
        visibility: hidden;
      }
    }
    .circle {
      animation:rotate 16s linear infinite 0.1s;
    }
    @-webkit-keyframes rotate{
      0%{
        transform: rotate(0)
      }
      50%{
        transform: rotate(180deg)
      }
      100%{
        transform: rotate(360deg)
      }
    }
    .music-tip {
      position:absolute;
      top: 0;
      right: 120px;
      transform: rotate(-60deg);
    }

    .music-tip .active {
      animation:playing 0.8s linear;
      animation-fill-mode: forwards;
    }

    @-webkit-keyframes playing{
      0%{
        transform: rotate(0deg)
      }
      100%{
        transform: rotate(60deg)
      }
    }

    .music-name {
      width: 100%;
      text-align: center;
      margin-top: 40px;
    }
  }

  .musicsty{
    width: 84%;
    padding: 0 40px;
    height: 602px;
    margin: 0 auto;
  }
  .yysty{
    font-weight: normal;
    color: white;
    font-size: 29px;
    line-height: 82px;
    margin: 0px;
  }
  .audiosty{
    width: 100%;
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin-top: 400px;
  }
  .bjyztop{
    width:600px;
    height:577px;
    background:url("../../assets/img/report/bjyz_jkfa_icon.png") no-repeat;
    text-align:center;
    background-size:100% 100%;
    margin: auto;
    margin-top:40px;
    left: 0;
    right: 0;
  }
  .bjyzspan{
    font-size: 80px;
    color:white;
    font-family:fzytk;
    height:100px;
  }


</style>
