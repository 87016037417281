var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.reportData
    ? _c(
        "div",
        {
          class:
            _vm.marker === "10.1" ? "reportParsing-ten-one" : "reportParsing"
        },
        [
          _c("div", { staticClass: "left" }, [
            _c("div", { staticClass: "scoreBox" }, [
              _c(
                "p",
                {
                  staticClass: "scoreNum",
                  style: { color: _vm.scoreColor, left: _vm.leftDistance }
                },
                [
                  _vm._v(_vm._s(_vm.reportData.health_score)),
                  _c("span", [_vm._v("分")])
                ]
              ),
              _c("img", {
                attrs: {
                  src: require("../../assets/img/report/audio/animation" +
                    _vm.partNum +
                    ".gif"),
                  alt: "testtest"
                }
              }),
              _c("p", { staticClass: "text" }, [_vm._v(_vm._s(_vm.healthText))])
            ]),
            _c("div", { staticClass: "tip" }, [
              _vm._v(_vm._s(_vm.reportData.physical_attention.attention))
            ])
          ]),
          _c("div", { staticClass: "right" }, [
            _vm.marker == 10.1
              ? _c("div", { staticClass: "chart-tip" }, [
                  _vm._m(0),
                  _vm.reportData.before_symptom_status
                    ? _c("div", { staticClass: "tip-content" }, [
                        _c("div", { staticClass: "line line-two" }),
                        _c("span", [_vm._v("上次")])
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm.marker === "10.1"
              ? _c("div", {
                  style: {
                    width: "373px",
                    height: "278px",
                    margin: "23px 0 2px 0"
                  },
                  attrs: { id: "parsing" }
                })
              : _c("div", {
                  style: {
                    width: "429px",
                    height: "340px",
                    margin: "32px 0 4px 0"
                  },
                  attrs: { id: "parsing" }
                }),
            _c(
              "div",
              { staticClass: "btn", on: { click: _vm.gotoParsingTzbs } },
              [_vm._v(_vm._s(_vm.reportData.result))]
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tip-content" }, [
      _c("div", { staticClass: "line" }),
      _c("span", [_vm._v("本次")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }