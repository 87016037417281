<template>
  <div>
    <header>
      <h1>祉云中医健康检测报告</h1>
      <h3>基于上海中医药大学研究专利开发</h3>
    </header>
    <div class="basic">
      <p>检测时间：<span>{{ date }}</span></p>
      <p>报告编号：<span>{{ reportId }}</span></p>
    </div>
    <div class="personal">
      <p>性别：<span>{{ gender }}</span></p>
      <p>BMI指数：<span>{{ bmi }}</span></p>
      <p>手机尾号：<span>{{ phone }}</span></p>
    </div>
  </div>
</template>

<script>
  import {calculateBMI} from '../../../../utils/common';


  export default {
    name: "personal",
    props: {
      reportId: String,
      time: String,
      userInfo: Object,
      cellphone: Object
    },
    computed: {
      date(){
        const [date, time] = this.time.split(' ');
        return `${date} ${time}`
      },
      gender(){ // 性别
        if (this.userInfo.gender === 1) {
          return '男'
        } else if (this.userInfo.gender === 2) {
          return '女'
        }
      },
      bmi(){
        const {bmi, text} = calculateBMI(this.userInfo.height, this.userInfo.weight);
        if(bmi) {
          return `${bmi}(${text})`;
        } else {
          return `0(${text})`
        }

      },
      phone(){
        return this.cellphone.cellphone? this.cellphone.cellphone.substr(-4) : '无';
      }
    }
  }
</script>

<style scoped>
  * {
    padding: 0;
    margin: 0;
  }

  header {
    margin-bottom: 19px;
  }

  header h1{
    text-align: center;
    padding-top: 81px;
    color: rgb(51, 51, 51);
    font-weight: bold;
    letter-spacing: 3px;
    font-size: 33px;

  }

  header h3 {
    font-size: 13px;
    color: rgb(51, 51, 51);
    text-align: center;
    padding-top: 10px;
    font-weight: normal;
  }

  .basic,
  .personal{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgb(153, 153, 153);
    padding: 5px 0;
  }


  .basic p,
  .personal p{
    flex: 0 0 1;
    font-size: 16px;
  }


</style>
