<template>
  <div v-if="(isShowJKFN && isShowCPTJ && report.product[0] && report.product[0]['name'])" style="float: left">
    <div class="recommendation"@click="returnToProduct"></div>
  </div>
  <div v-else-if="(isShowCPTJ && !isShowJKFN)" style="float: left">
    <div class="recommendation1" @click="returnToProduct"></div>
  </div>
</template>

<script>
  export default {
    name: "recommendation",
    props: {
      report:Object,
      isShowJKFN:Boolean,
      isShowCPTJ:Boolean,
    },
    methods:{
      returnToProduct(){
        this.$router.push({name:'Product',params: {report:this.report, id:this.$route.params.id}})
      }
    }
  }
</script>

<style scoped>
  .recommendation{
    width: 422px;
    height: 140px;
    background: url("../../../assets/img/report/cptj_jcjg_icon.png");
    background-size: 100% 100%;
    margin: 20px 0 0 110px;
  }
  .recommendation1{
    width:562px;
    height:149px;
    background:url("../../../assets/img/report/cptj_jcjg_icon.png");
    background-size:100% 100%;
    margin:13px 0 0 200px;
  }

</style>
