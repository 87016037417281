<template>
  <div :class="marker === '10.1' ? 'reportParsing-ten-one' : 'reportParsing'" v-if="reportData">
    <div class="left">
<!--      <div class="text1">您的体质为：<span class="text2">{{reportData.result}}</span></div>-->
      <div class="scoreBox">
        <p class="scoreNum" :style="{color : scoreColor, left: leftDistance}">{{reportData.health_score}}<span>分</span></p>
        <img :src="require(`../../assets/img/report/audio/animation${partNum}.gif`)" alt="testtest">
        <p class="text">{{healthText}}</p>
      </div>

      <!-- 建议找中医专家调理 -->
      <div class="tip">{{reportData.physical_attention.attention}}</div>
    </div>
    <div class="right">
      <div v-if="marker == 10.1" class="chart-tip">
        <div class="tip-content">
          <div class="line"></div>
          <span>本次</span>
        </div>
        <div v-if="reportData.before_symptom_status" class="tip-content">
          <div class="line line-two"></div>
          <span>上次</span>
        </div>
      </div>
      <div v-if="marker === '10.1'" id="parsing" :style="{width: '373px', height: '278px', margin: '23px 0 2px 0'}"></div>
      <div v-else id="parsing" :style="{width: '429px', height: '340px', margin: '32px 0 4px 0'}"></div>
      <div class="btn" @click="gotoParsingTzbs">{{reportData.result}}</div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { queryObject } from '../../../utils/common.js';
const { csr = '', marker = '' } = queryObject()
import JK from '../../assets/img/menopauseovary/group28_32.gif'
import YJK from '../../assets/img/menopauseovary/group28_17.gif'
import Ill from '../../assets/img/menopauseovary/group28_3.gif'

export default {
  name: "reportparsing",
  props: {
    reportData: Object
  },
  data(){
    return {
      partNum: Math.round(this.reportData.health_score / 3) + 1,
    }
  },
  computed: {
    marker(){
      return marker
    },
    healthText(){ // 仪表盘图片

      if(this.reportData.result.includes('平和质')) {
        return  '健康';
      } else {
        const notHealthArr = this.reportData.symptom_status.filter( val => {
          return val.name !== '平和质'
        });
        // 当某个偏颇体质最高得分大于等于35
        const scoreTop = notHealthArr.filter( val => {
          return val.score >= 35;
        });
        // 偏颇体质得分大于等于20的超过2个
        const numTop = notHealthArr.filter( val => {
          return val.score >= 20;
        });

        if( scoreTop.length > 0 || numTop.length > 2) {
          return '可能有疾病';
          // return Ill
        } else {
          return '亚健康';
          // return YJK;
        }

      }
    },
    scoreColor(){
      const score = this.reportData.health_score
      if(score> 89) {
        return '#00d73d'
      }
      if(score > 59) {
        return '#FE822B'
      }
      return '#ff0000';
    },
    leftDistance(){
      const score = this.reportData.health_score;
      if(score === 100) { // 兼容100分的情况
        if(this.marker === '10.1') {
          return '135px'
        }
        return '190px'
      } else {
        if(this.marker === '10.1') {
          return '165px'
        }
        return '220px'
      }
    }
  },
  mounted(){
    this.drawChart()
  },
  methods: {
    drawChart(){
      const scoreArr = [];
      const beforeScoreArr = [];
      const indicatorArr = [];
      const that = this;
      // 本次结果
      this.reportData.symptom_status.forEach((e, i) => {
        scoreArr[i] = e.score;
        let indicatorDic = null
        if(e.name == that.reportData.result.substring(0,3)){
          indicatorDic = { name: e.name, max: 40 ,color: '#ffa525' }
        } else {
          indicatorDic = { name: e.name, max: 40 }
        }
        indicatorArr[i] = indicatorDic
      });
      //本次结果
      const data = [{
        value: scoreArr,
        name: '本次',
        itemStyle: {
          color: '#ffb45b',
          opacity: 0.6,
        },
        lineStyle: {
          color: '#ffb45b',
          width: 2,
          opacity: 0.5,
          type:'solid'
        },
      }];

      // 若存在上一次的结果，则展示出来
      if(this.reportData.before_symptom_status) {
        this.reportData.before_symptom_status.forEach((e, i) => {
          beforeScoreArr[i] = e.score;
        });

        data.push({
          value: beforeScoreArr,
          name: '上次',
          itemStyle: {
            color: '#20e2ff',
            opacity: 0.6,
          },
          lineStyle: {
            color: '#20e2ff',
            width: 2,
            opacity: 0.5,
            type:'dashed'
          },
        })
      }

      const options = {
        radar: {
          center: ['50%', '50%'],
          startAngle: 90,
          splitNumber: 4,
          axisName: {
            color: '#216b98',
            formatter(value) {
              return `{a|${value}}`;
            },
            rich: {
              a: {
                fontSize: 24,
                lineHeight: 14.9,
              }
            },
          },
          axisLine: {
            lineStyle: {
              color: '#216b98',
            }
          },
          radius: '63%',
          splitLine: {
            lineStyle: {
              width: 1,
              color: '#00466b'
            }
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ['rgba(33,107,152,0.36)', 'rgba(33,107,152,0.28)', 'rgba(33,107,152,0.2)', 'rgba(33,107,152,0.1)'],
            }
          },
          indicator: indicatorArr,

        },
        series: [{
          type: 'radar',
          symbol:'circle',//拐点设置为实心
          symbolSize:4,//拐点大小
          z: 1,
          data,
          areaStyle: {
            color: new echarts.graphic.LinearGradient(         // 设置渐变色
              0, 0, 0, 1,
              [
                { offset: 0, color: 'rgba(33,107,152,0.1)' },
                { offset: 1, color: 'rgba(33,107,152,0.9)' }
              ]
            )
          },
        }]
      }
      if(this.marker !== '10.1') { // 10.1寸的镜子不能设置legend，否则图形将绘制不出来，图例也出不来
        options.legend = {
          data: this.reportData.before_symptom_status? ['本次', '上次'] : ['本次'] ,
          show: true,
          right: "0",
          width: "auto",
          // itemWidth: 50,
          itemHeight: 6,
          orient: "vertical",
          selectedMode: false,
          textStyle: {
            color: "#fff",
            fontSize: 20,
          }
        }
      }

      const myChart = echarts.init(document.getElementById('parsing'));
      myChart.setOption(options);
    },
    gotoParsingTzbs(){
      this.$router.push({name:'Parsingtzbs'})
    },
  }
}
</script>

<style lang="scss" scoped>
$bg: '../../assets/img/physical/bjk1_jcjg_icon.png';
$bgTen: '../../assets/img/physical/bjk1_jcjg_10_icon.png';
$btn: '../../assets/img/physical/tzjx_jcjg_icon.png';
$contentWidth: 94%;
$leftContent: 3%;
$itemWidth: 49.2%;
// 非0.1寸
.reportParsing {
  width: $contentWidth;
  margin-left: $leftContent;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  .left-right {
    width: $itemWidth;
    height: 486px;
    background:url($bg);
    background-size:100% 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .left {
   @extend .left-right;
    .scoreBox {
      width: 512px;
      height: 367px;
      margin: 10px auto 0 auto;
      background-image: url("../../assets/img/report/jkzs_jcjg_icon_1.gif");
      background-size: 100% 100%;

      .scoreNum {
        font-size: 80px;
        font-weight:bold;
        position: relative;
        top: 130px;
        left: 220px;
        display: inline-block;

        span {
          font-size: 30px;
        }
      }

      .text {
        text-align: center;
        font-size: 28px;
        color: #00fefb;
        top: 130px;
        position: relative;
        left: 10px;
      }
    }


    .text1 {
      font-size: 32px;
      margin-top: 47px;
    }
    .text2 {
      font-size: 48px;
    }
    img {
      position: absolute;
      width: 250px;
      height: 250px;
      top: 375px;
      left: 175px;
      display: block;
    }
    .tip {
      font-size: 24px;
      color: #00eaff;
      line-height: 36px;
      margin: 8px 30px 0 30px;
    }
  }
  .right {
    @extend .left-right;
    .btn {
      width: 220px;
      height: 71px;
      background: url($btn);
      background-size:100% 100%;
      font-size: 30px;
      color: #fff;
      text-align: center;
      line-height: 71px;
    }
  }
}
// 10.1寸
.reportParsing-ten-one {
  width: $contentWidth;
  margin-left: $leftContent;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  .left-right {
    .scoreBox {
      width: 420px;
      height: 300px;
      margin: 0 auto;
      background-image: url("../../assets/img/report/jkzs_jcjg_icon_1.gif");
      background-size: 100% 100%;

      .scoreNum {
        font-size: 80px;
        font-weight:bold;
        position: relative;
        top: 95px;
        left: 220px;

        span {
          font-size: 30px;
        }
      }

      .text {
        text-align: center;
        font-size: 28px;
        color: #00fefb;
        top: 90px;
        position: relative;
        left: 10px;
      }
    }



    width: $itemWidth;
    height: 386px;
    background:url($bgTen);
    background-size:100% 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .left {
   @extend .left-right;
    .text1 {
      font-size: 32px;
      margin-top: 10px;
    }
    .text2 {
      font-size: 48px;
    }
    img {
      position: absolute;
      width: 190px;
      height: auto;
      top: 339px;
      left: 195px;
      display: block;
    }
    .tip {
      font-size: 21px;
      color: #00eaff;
      line-height: 24px;
      margin: 5px 30px 0 30px;
    }
  }
  .right {
    @extend .left-right;
    .btn {
      width: 220px;
      height: 61px;
      background: url($btn);
      background-size:100% 100%;
      font-size: 30px;
      color: #fff;
      text-align: center;
      line-height: 61px;
    }

    .chart-tip {
      position: absolute;
      right: 0;
      .tip-content {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 20px;
        .line {
          height: 4px;
          width: 40px;
          background-color: #069eff;
          margin-right: 10px;
        }
        .line-two {
          background-color: #ffad2c;
        }
        span {
          width: 120px;
          text-align: left;
        }
      }
    }
  }
}

</style>