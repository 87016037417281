var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.reportData.vendor_setting.health_scheme_show === 1 ||
    _vm.reportData.vendor_setting.product_advice_show === 1
    ? _c("div", { staticClass: "plan-btn" }, [
        _c("div", { staticClass: "content-left" }, [
          _c("div", { staticClass: "tip" }, [
            _vm._v("根据您最具倾向性的体质推荐的个性化健康方案")
          ]),
          _c("div", { staticClass: "co-btn" }, [
            _vm.reportData.vendor_setting.health_scheme_show === 1
              ? _c(
                  "div",
                  { staticClass: "btn", on: { click: _vm.gotoHealthyPlan } },
                  [_vm._v("养生方案")]
                )
              : _vm._e()
          ])
        ]),
        _c(
          "div",
          { staticClass: "content-right" },
          [
            _vm.reportData.is_print === 1
              ? _c(
                  "div",
                  { staticClass: "print", on: { click: _vm.printEvent } },
                  [
                    _c("img", {
                      staticClass: "tip-img",
                      attrs: {
                        src: require("../../assets/img/physical/dy_jcjg_icon.png")
                      }
                    }),
                    _vm._v("打印报告")
                  ]
                )
              : _vm._e(),
            _vm.reportData.vendor_setting.qr_code_show === 2
              ? _c("img", {
                  staticStyle: { width: "146px", height: "146px" },
                  attrs: { src: _vm.reportData.qr_url },
                  on: { click: _vm.showExplain }
                })
              : this.shareUrl &&
                _vm.reportData.vendor_setting.qr_code_show === 1
              ? _c("qrcode-vue", {
                  attrs: { value: _vm.url, size: "146" },
                  on: { click: _vm.showExplain }
                })
              : this.shareUrl &&
                _vm.reportData.vendor_setting.qr_code_show === 0
              ? _c("img", {
                  staticStyle: { width: "146px", height: "146px" },
                  attrs: { src: _vm.code },
                  on: { click: _vm.showExplain }
                })
              : _c("div"),
            _c("div", { staticClass: "qrcode" }, [_vm._v("微信扫码获取报告")])
          ],
          1
        ),
        _vm.flag
          ? _c("div", [
              _c("div", { staticClass: "frame" }, [
                _c(
                  "div",
                  { staticClass: "spantitle" },
                  [
                    _vm.reportData.vendor_setting.qr_code_show === 2
                      ? _c("img", {
                          staticStyle: { width: "450px", height: "450px" },
                          attrs: { src: _vm.reportData.qr_url }
                        })
                      : this.shareUrl &&
                        _vm.reportData.vendor_setting.qr_code_show === 1
                      ? _c("qrcode-vue", {
                          attrs: { value: _vm.url, size: "450" }
                        })
                      : this.shareUrl &&
                        _vm.reportData.vendor_setting.qr_code_show === 0
                      ? _c("img", {
                          staticStyle: { width: "450px", height: "450px" },
                          attrs: { src: _vm.code }
                        })
                      : _vm._e(),
                    _c("div", {
                      staticClass: "fh",
                      on: { click: _vm.showExplain }
                    })
                  ],
                  1
                )
              ])
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }