var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.report
    ? _c("div", { staticClass: "physique" }, [
        _c("div", { staticClass: "healthprogram" }, [
          _c("div", { staticStyle: { height: "170px" } }, [
            _c("img", {
              staticClass: "back",
              attrs: { src: _vm.fh },
              on: { click: _vm.returnto }
            }),
            _c("span", { staticClass: "title" }, [_vm._v("健康方案")])
          ]),
          _c("div", { staticClass: "topBtn" }, [
            _c(
              "div",
              { style: { width: this.widval + "px" } },
              _vm._l(_vm.jkfnsx, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "healthprogram-top",
                    style: { opacity: item === _vm.currentIndex ? "1" : "0.5" },
                    on: {
                      click: function($event) {
                        return _vm.checkType($event, item)
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: _vm.buttonimg[item], alt: "logo" }
                    }),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.buttontype[item]) + " ")
                  ]
                )
              }),
              0
            )
          ]),
          _c("div", { staticClass: "tabContent" }, [
            _vm.currentIndex === 0
              ? _c("div", [
                  _vm.principle
                    ? _c("div", { staticClass: "cyys" }, [
                        _c("div", { staticClass: "bjyztop" }, [
                          _c(
                            "span",
                            {
                              staticClass: "bjyzspan",
                              staticStyle: {
                                position: "relative",
                                top: "53px",
                                left: "141px"
                              }
                            },
                            [_vm._v(_vm._s(_vm.principle.content[1][0]))]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "bjyzspan",
                              staticStyle: {
                                position: "relative",
                                top: "162px",
                                left: "-34px"
                              }
                            },
                            [_vm._v(_vm._s(_vm.principle.content[1][1]))]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "bjyzspan",
                              staticStyle: {
                                position: "relative",
                                top: "225px",
                                left: "28px"
                              }
                            },
                            [_vm._v(_vm._s(_vm.principle.content[1][2]))]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "bjyzspan",
                              staticStyle: {
                                position: "relative",
                                top: "327px",
                                left: "-151px"
                              }
                            },
                            [_vm._v(_vm._s(_vm.principle.content[1][3]))]
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-top": "30px",
                              "padding-left": "127px",
                              "padding-right": "100px",
                              "letter-spacing": "4px"
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.principle.content[0]) + " ")]
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm.currentIndex === 1
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "cyys", staticStyle: { height: "70%" } },
                    _vm._l(_vm.hg.content, function(item, index) {
                      return _c("span", { key: index }, [_vm._v(_vm._s(item))])
                    }),
                    0
                  )
                ])
              : _vm._e(),
            _vm.currentIndex === 2
              ? _c("div", [
                  _vm.tea
                    ? _c("div", { staticClass: "cyys" }, [
                        _c(
                          "div",
                          { staticStyle: { height: "98%", overflow: "auto" } },
                          [
                            _c("span", { staticClass: "teasty" }, [
                              _vm._v(_vm._s(_vm.tea.name))
                            ]),
                            _c(
                              "div",
                              { staticStyle: { "text-align": "center" } },
                              [
                                _c("img", {
                                  staticStyle: {
                                    height: "481px",
                                    width: "861px",
                                    "margin-top": "50px",
                                    "border-radius": "20px"
                                  },
                                  attrs: {
                                    src:
                                      _vm.report.solutions.asset_prefix +
                                      _vm.tea.asset,
                                    alt: "加载失败"
                                  }
                                })
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "medicinal",
                                staticStyle: { "margin-top": "30px" }
                              },
                              [_vm._v("配料：")]
                            ),
                            _c("span", { staticClass: "medicinal-explain" }, [
                              _vm._v(
                                _vm._s(_vm.tea.content && _vm.tea.content[0])
                              )
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "medicinal",
                                staticStyle: { "margin-top": "30px" }
                              },
                              [_vm._v("泡煮方法：")]
                            ),
                            _c("span", { staticClass: "medicinal-explain" }, [
                              _vm._v(
                                _vm._s(_vm.tea.content && _vm.tea.content[1])
                              )
                            ])
                          ]
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm.currentIndex === 3
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "cyys",
                      staticStyle: {
                        "padding-top": "10px",
                        position: "relative",
                        height: "89%"
                      }
                    },
                    _vm._l(_vm.jdtf, function(item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          style:
                            index === _vm.jdtf.length - 1
                              ? { height: "30.5%", paddingTop: "20px" }
                              : { height: "30.5%", paddingTop: "20px" }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "teasty",
                              staticStyle: {
                                color: "#D67206",
                                "font-weight": "bold",
                                "font-size": "36px"
                              }
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "medicinal",
                              staticStyle: {
                                "margin-top": "10px",
                                "font-size": "30px"
                              }
                            },
                            [_vm._v("药物组成：")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "medicinal-explain",
                              staticStyle: {
                                "margin-top": "5px",
                                "line-height": "40px"
                              }
                            },
                            [_vm._v(_vm._s(item.content[0]))]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "medicinal",
                              staticStyle: {
                                "margin-top": "10px",
                                "font-size": "30px"
                              }
                            },
                            [_vm._v("功效：")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "medicinal-explain",
                              staticStyle: {
                                "margin-top": "5px",
                                "line-height": "40px"
                              }
                            },
                            [_vm._v(_vm._s(item.content[1]))]
                          )
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        color: "red",
                        "font-size": "30px",
                        "text-align": "right",
                        "margin-top": "10px"
                      }
                    },
                    [_vm._v(" *请在医生、药师指导下购买和服用 ")]
                  )
                ])
              : _vm._e(),
            _vm.currentIndex === 4
              ? _c("div", [
                  _c("div", { staticStyle: { height: "150px" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "healthprogram-one",
                        staticStyle: {
                          "border-right": "3px solid #2F7284",
                          "padding-right": "4%"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "center",
                              height: "35px"
                            }
                          },
                          [
                            _c("img", {
                              staticStyle: {
                                width: "35px",
                                height: "35px",
                                "margin-right": "10px",
                                "vertical-align": "top"
                              },
                              attrs: { src: _vm.ys, alt: "logo" }
                            }),
                            _c("span", { staticClass: "eating" }, [
                              _vm._v("宜食")
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: { "margin-top": "20px", width: "100%" }
                          },
                          _vm._l(this.goodfood, function(item, index) {
                            return _c(
                              "span",
                              { key: index, staticClass: "foodname" },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          0
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "healthprogram-one",
                        staticStyle: { "padding-left": "3%" }
                      },
                      [
                        _c("div", { staticStyle: { "text-align": "center" } }, [
                          _c("img", {
                            staticStyle: {
                              width: "35px",
                              height: "35px",
                              "margin-right": "10px",
                              "vertical-align": "top"
                            },
                            attrs: { src: _vm.js, alt: "logo" }
                          }),
                          _c("span", { staticClass: "eating" }, [
                            _vm._v("忌食")
                          ])
                        ]),
                        _c(
                          "div",
                          {
                            staticStyle: { "margin-top": "20px", width: "100%" }
                          },
                          _vm._l(this.badfood, function(item, index) {
                            return _c(
                              "span",
                              { key: index, staticClass: "foodname" },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          0
                        )
                      ]
                    )
                  ]),
                  _vm._m(0),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        overflow: "auto",
                        "-webkit-overflow-scrolling": "touch"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          style: {
                            width: this.contentwid + "px",
                            height: "1200px",
                            marginTop: "15px"
                          }
                        },
                        _vm._l(this.content, function(item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "dishes" },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "98%",
                                    overflow: "auto"
                                  }
                                },
                                [
                                  _c("span", { staticClass: "tip" }, [
                                    _vm._v(_vm._s(item.name))
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "medicinal",
                                      staticStyle: { "margin-top": "9px" }
                                    },
                                    [_vm._v("配料：")]
                                  ),
                                  _vm._l(item.asset, function(items, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticStyle: {
                                          width: "33.3%",
                                          display: "inline-block",
                                          "text-align": "center"
                                        }
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: {
                                            width: "221px",
                                            height: "161px",
                                            "margin-top": "30px",
                                            "border-radius": "10px"
                                          },
                                          attrs: {
                                            src:
                                              _vm.report.solutions
                                                .asset_prefix + items[1],
                                            alt: "加载失败"
                                          }
                                        }),
                                        _c("span", { staticClass: "imgtext" }, [
                                          _vm._v(_vm._s(items[0]))
                                        ])
                                      ]
                                    )
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "medicinal",
                                      staticStyle: { "margin-top": "40px" }
                                    },
                                    [_vm._v("清单：")]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "medicinal-explain" },
                                    [_vm._v(_vm._s(item.content[0]))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "medicinal",
                                      staticStyle: { "margin-top": "40px" }
                                    },
                                    [_vm._v("烹煮方法：")]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "medicinal-explain" },
                                    [_vm._v(_vm._s(item.content[1]))]
                                  )
                                ],
                                2
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm.currentIndex === 5
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "cyys",
                      staticStyle: {
                        height: "85%",
                        "padding-bottom": "100px",
                        overflow: "auto"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { height: "100%", overflow: "auto" } },
                        [
                          _c("span", { staticClass: "teasty" }, [
                            _vm._v(_vm._s(_vm.acupoint.name))
                          ]),
                          _c("img", {
                            staticStyle: {
                              "margin-top": "50px",
                              width: "860px",
                              height: "480px",
                              "border-radius": "20px"
                            },
                            attrs: {
                              src:
                                _vm.report.solutions.asset_prefix +
                                _vm.acupoint.asset,
                              alt: "加载失败"
                            }
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "medicinal",
                              staticStyle: { "margin-top": "11px" }
                            },
                            [_vm._v("定位：")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "medicinal-explain",
                              staticStyle: { "margin-top": "30px" }
                            },
                            [_vm._v(_vm._s(_vm.acupoint.content[0]))]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "medicinal",
                              staticStyle: { "margin-top": "30px" }
                            },
                            [_vm._v("按摩方法：")]
                          ),
                          _c("span", { staticClass: "medicinal-explain" }, [
                            _vm._v(_vm._s(_vm.acupoint.content[1]))
                          ])
                        ]
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm.currentIndex === 6
              ? _c("div", { staticStyle: { "text-align": "center" } }, [
                  _c(
                    "div",
                    { staticClass: "zygf" },
                    [
                      this.poster
                        ? _c("video-player", {
                            ref: "videoPlayer",
                            staticClass: "video-player vjs-custom-skin",
                            attrs: {
                              playsinline: true,
                              options: _vm.playerOptions
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm.currentIndex === 7
              ? _c("div", { staticClass: "music-healthy" }, [
                  _c("div", { staticClass: "music-icon" }, [
                    _c("img", {
                      class: _vm.isPlay ? "circle " : "",
                      attrs: {
                        src: require("../../assets/img/common/music_jkfa_icon.png"),
                        alt: ""
                      }
                    }),
                    _c("img", {
                      staticClass: "music-btn",
                      attrs: {
                        src: !_vm.isPlay
                          ? require("../../assets/img/common/bf_jkfa_icon.png")
                          : require("../../assets/img/common/zt_jkfa_icon.png")
                      },
                      on: {
                        click: function($event) {
                          return _vm.startPlay()
                        }
                      }
                    }),
                    _vm.music
                      ? _c("audio", {
                          staticClass: "audio",
                          attrs: {
                            src: _vm.music.url,
                            controls: "controls",
                            id: "player"
                          }
                        })
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "music-tip" }, [
                    _c("img", {
                      class: _vm.isPlay ? "active" : "",
                      attrs: {
                        src: require("../../assets/img/common/music1_jkfa_icon.png"),
                        alt: ""
                      }
                    })
                  ]),
                  _c("div", { staticClass: "music-name" }, [
                    _vm._v(_vm._s(_vm.music.title))
                  ])
                ])
              : _vm._e(),
            _vm.currentIndex === 8
              ? _c(
                  "div",
                  [
                    _c("ProductTzbs"),
                    _vm.isShowProDetail ? _c("ProductDetail") : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { "margin-top": "28px", height: "60px" } },
      [
        _c(
          "span",
          { staticClass: "medicinal", staticStyle: { "margin-top": "20px" } },
          [_vm._v("推荐药膳")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }