<template>
  <div class="reportfooter">
    <div v-if="isShowLogo&&(!csr)" class="logo">上海中药创新成果转化中心有限公司</div>
    <div v-if="(isShowFoot === 2 || isShowFoot === 3)" class="logo">{{setting.logo}}</div>
    <div v-if="csr !== 'SHBAZE' && (isShowFoot === 1 ||isShowFoot === 3)" class="logo">
      <img v-if="!(csr === 'CHENSHANG' || csr === 'SXSZYYY' || !isShowFoot)" :src="csr === 'BJTRTKH' ? trt : csr === 'SHWYSLSWKJ' ? wysl : logo" alt="logo" style="height: 35px;margin-right: 0.5em">
      {{tipName()}}
    </div>
  </div>

</template>

<script>
  import {queryObject} from "../../../../utils/common";
  const { csr = '' } = queryObject();
  import logo from '../../../assets/img/report/company-logo-color2.png';
  import trt from '../../../assets/img/report/trt_sy_icon.png';
  import wysl from '../../../assets/img/report/wysllogo.png';
  export default {
    name: "reportfooter",
    props: {
      report:Object,
      setting:Object,
    },
    data(){
      return{
        logo:logo,
        trt:trt,
        wysl:wysl,
        isShowFoot:false,
        isShowLogo:false,
      }
    },
    mounted() {
      if (this.setting){
        this.isShowFoot = this.setting.home_foot_show;
      }
      if (this.setting.other_company_logo_show === 1){
        this.isShowLogo = true
      }else {
        this.isShowLogo = false
      }
      // console.log(!this.csr)
      // console.log(csr === 'CHENSHANG' || csr === 'SXSZYYY'|| csr === 'BJTRTKH'|| csr === 'SHWYSLSWKJ')
    },
    methods:{
      tipName(){
        if (csr=== 'BJTRTKH'){
          return '中国北京同仁堂（集团）有限责任公司'
        }else if (csr ===  'CHENSHANG'){
          return '上海辰尚信息科技有限公司'
        }else if (csr === 'SHWYSLSWKJ'){
          return '上海伍月石榴生物科技有限公司'
        }else if (csr === 'SXSZYYY'){
          return '陕西省中医医院'
        }else if (this.isShowFoot === 1 || this.isShowFoot === 3){
          return '上海祉云医疗科技有限公司'
        }else {
          return ''
        }
      }
    },
    computed:{
      csr(){
        return csr
      },
    },
  }
</script>

<style scoped>
  .reportfooter{
    width: 100%;
    /*margin: 0 auto;*/
    margin-top: 3%;
  }
  .logo{
    padding: 18px 42px 0 42px;
    font-size: 30px;
    color: white;
    line-height: 35px;
    text-align: center;
  }

</style>
