<template>
  <div class="wxviscerachart">
    <div class="title">脏腑风险</div>
    <div id="viscera" :style="{width: '100%', height: '590px'}"></div>
    <div class="text">提示：{{viscera.viscera_attention}}</div>
  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: "wxviscerachart",
  props: {
    viscera: Object,
    result: String
  },
  data(){
    return {

    }
  },
  mounted(){
    this.drawChart()
  },
  methods: {
    drawChart(){
      const myChart = echarts.init(document.getElementById('viscera'));
      myChart.setOption({
        legend: {
          data: this.result.substring(0,3) === '平和质' ? ['平和质'] : ['平和质', this.result],
          show: true,
          right: "1%",
          width: "auto",
          orient: "vertical",
          itemWidth: 50,
          itemHeight: 6,
          selectedMode: false,
          textStyle: {
            color: "#333",
            fontSize: 30,
          }
        },
        color:this.result.substring(0,3) == '平和质' ? ['#069eff'] : ['#069eff','#ffad2c'],  
        radar: {
          center: ['50%', '50%'], 
          startAngle: 90,
          radius: 245,
          splitNumber: 5,
          axisName: {
            formatter(value) {
              return `{a|${value}}`;
            },
            rich: {
              a: {
                color: '#666',
                fontSize: 36,
                lineHeight: 40,
              }
            },
          },
          axisLine: { //坐标轴
            show: false
          },
          splitLine: {
            lineStyle: {
              width: 1,
              color: '#fff'
            }
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ['rgba(7, 158, 255, 0.5)','rgba(7, 158, 255, 0.4)','rgba(7, 158, 255, 0.3)','rgba(7, 158, 255, 0.2)','rgba(7, 158, 255, 0.1)'],
            }
          },
          indicator: [{name: '心', max: 100}, {name: '肾', max: 100}, {name: '肺', max: 100}, {name: '脾', max: 100}, {name: '肝', max: 100}]
        },
        series: [{
          type: 'radar',
          symbol: "none", // 去掉图表中各个图区域的边框线拐点
          data: [
            {
              name: '平和质',
              value: [20, 20, 20, 20, 20],
              lineStyle: {
                color: '#069eff',
                width: 3,
                type:'solid'
              },
            },
            {
              name: this.result.substring(0,3) == '平和质' ? null : this.result,
              value: this.result.substring(0,3) == '平和质' ? null : [this.viscera.heart_percent, this.viscera.kidney_percent, this.viscera.lung_percent, this.viscera.spleen_percent, this.viscera.liver_percent],
              lineStyle: {
                color: '#ffad2c',
                width: 3,
                type:'solid'
              },
            },
          ]
        }]
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.wxviscerachart {
  background-color: #fff;
	border-radius: 30px;
  padding: 57px 46px 60px 51px;
  margin-top: 45px;
  .title {
    color: #333;
    font: {
      size: 48px;
      family: PingFangSC-Medium;
    };
    width: 260px;
    height: 50px;
    border-left: 6px solid #1288eb;
    text-align: center;
    margin-bottom: 30px;
  }
  .text {
    color: #0096ff;
    font-size: 45px;
    font-family: SourceHanSansCN-Medium;
    width: 100%;
    text-align: center;
  }
}
</style>