<template>
  <div v-if="marker === '10.1'">
    <div class="facereport">
      <div class="face-top" style="margin-top: 80px">
        <span class="title">面诊结果</span>
        <span class="title-border" style="top: 154px" />
      </div>
      <div class="leftsty" :style="facestate === 0?{paddingTop: '17px',paddingLeft:'20px'}:{padding: '30px 20px'}" @click="checkfacestate(facestate === 0 ?2:facestate-1)">
        {{facestate === 0?'光泽指数':facestate === 1?'面色':facestate === 2?'唇色':''}}
      </div>
      <div class="centersty" :style="facestate === 2?{paddingTop: '17px',paddingLeft:'20px',fontSize:'42px',lineHeight:'48px'}:{padding: '42px 20px'}">
        {{facestate === 0?'面色':facestate === 1?'唇色':facestate === 2?'光泽指数':''}}
      </div>
      <div class="rightsty" :style="facestate === 1?{paddingTop: '17px',paddingLeft:'20px'}:{padding: '30px 20px'}" @click="checkfacestate(facestate === 2 ?0:facestate+1)">
        {{facestate === 0?'唇色':facestate === 1?'光泽指数':facestate === 2?'面色':''}}
      </div>
      <div class="resultL">
        <div class="imgsty" style="border-bottom: none;height: 352px">
          <img src="../../assets/img/menopauseovary/mxjg.gif" style="height: 314px;margin-top: 25px" alt="面诊图片">
        </div>
        <div class="result-content">
          <div class="imgstyL">
            <div v-if="facestate === 0" class="face-gz" style="width: 100%;border: none">
              <div style="height: 100%;width: 96%;margin-left: 2%">
                <div style="height: 37%;margin-top: 20px">
                  <div class="circular1"><span style="text-align: center;font-size: 34px;line-height: 112px;">{{facereport.face_L}}</span></div>
                  <div class="circular2"><span style="text-align: center;font-size: 34px;line-height: 112px;">{{facereport.face_a}}</span></div>
                  <div class="circular3"><span style="text-align: center;font-size: 34px;line-height: 112px;">{{facereport.face_b}}</span></div>
                </div>
                <div style="margin-top: 6px">
                  <span class="spansty">L值</span>
                  <span class="spansty">A值</span>
                  <span class="spansty">B值</span>
                </div>
                <div style="margin-top: 20px">
                  <span class="spaninfo">L值越大面色越亮、有光泽，a值越大越红，b值越大越青黑</span>
                </div>
                <div style="margin-top: 12px;text-align: left;">
                  <span class="span-tip">结果：{{facereport.face_color_info.name}}</span><br />
                  <span class="span-tip">提示：{{facereport.face_color_info.content[0] === '正常' ? '' : '常见于'}}{{facereport.face_color_info.content[0]}}</span>
                </div>
              </div>
            </div>
            <div v-if="facestate === 1" class="face-gz" style="width: 100%;border: none">
              <div style="height: 100%;width: 96%;margin-left: 2%">
                <div style="height: 37%;margin-top: 20px">
                  <div class="circular1"><span style="text-align: center;font-size: 34px;line-height: 112px;">{{facereport.lip_L}}</span></div>
                  <div class="circular2"><span style="text-align: center;font-size: 34px;line-height: 112px;">{{facereport.lip_a}}</span></div>
                  <div class="circular3"><span style="text-align: center;font-size: 34px;line-height: 112px;">{{facereport.lip_b}}</span></div>
                </div>
                <div style="margin-top: 6px">
                  <span class="spansty">L值</span>
                  <span class="spansty">A值</span>
                  <span class="spansty">B值</span>
                </div>
                <div style="margin-top: 20px">
                  <span class="spaninfo">L值越大唇色越亮、有光泽，a值越大越淡，b值越大越紫暗</span>
                </div>
                <div style="margin-top: 12px;text-align: left;">
                  <span class="span-tip">结果：{{facereport.lip_color_info.name}}</span><br />
                  <span class="span-tip">提示：{{facereport.lip_color_info.content[0] === '正常' ? '' : '常见于'}}{{facereport.lip_color_info.content[0]}}</span>
                </div>
              </div>
            </div>
            <div v-if="facestate === 2" class="face-gz" style="width: 100%;border: none">
              <div style="height: 100%;width: 96%;margin-left: 2%">
                <div style="height: 37%;margin-top: 20px">
                  <div class="circular4"><span style="text-align: center;font-size: 34px;line-height: 112px;">{{facereport.gloss_indicator.toFixed(2)}}</span></div>
                </div>
                <div style="margin-top: 2px">
                  <span class="spansty">面部光泽指数</span>
                </div>
                <div style="margin-top: 37px;text-align: left;">
                  <span class="span-tip">结果：{{facereport.face_gloss_info.name}}</span><br />
                  <span class="span-tip">提示：{{facereport.face_gloss_info.content[0] === '正常' ? '' : '常见于'}}{{facereport.face_gloss_info.content[0]}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="back" @click="returnto" style="margin-top: 38px"></div>
      </div>
      <div class="face-lab" @click="returnToLab">
        <i>Lab颜色空间及相关数值意义</i>
        <span style="color: #006A95;float: right;margin-right: 15px">点击了解一下</span>
      </div>
    </div>
  </div>
  <div v-else :style="access === 'web' ? { height: '1920px' } : { height: '100%' }" class="facereport">
    <FaceDetail v-if="facereport" :isOnlyFace="!isFromReportHome"></FaceDetail>
  </div>

</template>

<script>
  import { getfaceReport } from '@/api/report.js';
  import { queryObject } from '../../../utils/common.js';
  import FaceDetail from './components/facedetail';
  const { access = '',marker = '' } = queryObject();
  export default {
    name: "facereport",
    data(){
      return {
        facereport: null,
        face_photo_url:'',
        report:'',
        id:'',
        facestate:0,
        isFromReportHome: this.$route.params.isFromReportHome // 是否来自于报告首页，决定是否展示
      }
    },
    beforeMount(){ // 取数据
      // 如果是从常规报告页面进来，直接从store里面取数据
      const { report } = this.$store.state;
      const { id } = this.$route.params;
      if(report && report.id && report.id === id) {
        this.facereport = report.face_result;
        this.face_photo_url = report.face_photo_url;
        this.report = report;
        this.id = report.id
      } else {
        // 如果是单独面诊版块，请求接口，获取数据
        this.getData();
      }
    },
    methods:{
      returnto(){ // (10.1寸)返回至 '报告页面'首页
        this.$router.back();
      },
      returnToLab(){ // (10.1寸)跳转到'Lab颜色空间及相关数值意义'页面
        this.$router.push({
          name:'Labdetail',
        })
      },
      checkfacestate(val){ // (10.1寸)切换选中的面色分析版块
        this.facestate = val
      },
      getData(){ // 单独面诊，请求数据
        getfaceReport({
          id: this.$route.params.id
        }).then(res => {
          if(res && res.status_code === 10000 && res.data) {
            this.facereport = res.data;
            this.$store.commit('SET_REPORT', res.data);
          }
        })
      },
    },
    computed: {
      access(){
        return access
      },
      marker(){
        return marker
      }
    },

    components: {
      FaceDetail
    }
  }
</script>

<style scoped>
  .facereport{
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    overflow:hidden;
    background:url("../../assets/img/report/bj_sznr_icon.png");
  }
  .face-top{
    width: 80%;
    display: block;
    height: 110px;
    margin: 10px 0 0 0;
  }
  .title{
    font-size: 60px;
    color:#00fefb;
    font-family:TITLE;
    margin-left:60px;
    line-height: 90px;
  }
  .title-border{
    position:absolute;
    left:0;
    top:90px;
    display:block;
    width:1000px;
    height:16px;
    background:url("../../assets/img/report/xian_jcjg.png") no-repeat;
  }
  .result{
    height:1530px;
    width: 100%;
    color:#FFFFFF;
    font-size:24px;
    background:url("../../assets/img/report/mzjg_icon.png") no-repeat;
    background-position-x:60px;
    display:inline-block;
    text-align:center;
    vertical-align:top;
    padding-top:77px;
  }
  .imgsty{
    height:315px;
    width: 790px;
    margin: auto;
    left:0;
    right:0;
    border-bottom:2px solid #00ECFC;
  }
  .face-content{
    height:315px;
    width: 790px;
    margin: auto;
    left:0;
    right:0;
    border-bottom:2px solid #00ECFC;
  }
  .circular1{
    display:inline-block;
    width:33%;
    background:url("../../assets/img/common/Lz_mzjg_icon.png") no-repeat;
    background-position-x:center;
    height:100%;
  }
  .circular2{
    display:inline-block;
    width:33%;
    background:url("../../assets/img/common/Az_mzjg_icon.png") no-repeat;
    background-position-x:center;
    height:100%;
  }
  .circular3{
    display:inline-block;
    width:33%;
    background:url("../../assets/img/common/Bz_mzjg_icon.png") no-repeat;
    background-position-x:center;
    height:100%;
  }
  .spansty{
    width:33%;
    display:inline-block;
    text-align:center;
    color:white;
    font-size:20px;
  }
  .spaninfo{
    text-align:center;
    color:#4BB9FF;
    font-size:20px;
  }
  .span-tip{
    color:#FFFFFF;
    font-size:27px;
    line-height:46px;
    margin-left:30px;
  }
  .face-gz{
    height:315px;
    width: 790px;
    margin: auto;
    left:0;
    right:0;
  }
  .circular4{
    display:inline-block;
    width:33%;
    background:url("../../assets/img/common/mbgzzs_mzjg_Icon.png") no-repeat;
    background-position-x:center;
    height:100%;
  }
  .back{
    height:67px;
    width: 224px;
    background:url("../../assets/img/report/fh_srsj_Icon.png") no-repeat;
    margin: auto;
    left:0;
    right:0;
    margin-top:20px;
  }
  .face-lab{
    height:45px;
    width: 67%;
    color:#00F0FF;
    font-size:36px;
    border-bottom:3px solid  #00ECFC;
    margin:10px 0 0 160px;
  }
  .resultL{
    height:1230px;
    width: 100%;
    color:#FFFFFF;
    font-size:24px;
    background:url("../../assets/img/report/bj_mzjg_icon10.png") no-repeat;
    background-position-x:60px;
    display:inline-block;
    text-align:center;
    vertical-align:top;
    padding-top:77px;
  }
  .result-content{
    overflow:hidden;
    height:638px;
    width: 740px;
    margin: 0 auto;
    left:0;
    right:0px;
  }
  .imgstyL{
    position:relative;
    height:355px;
    width: 740px;
    background:url("../../assets/img/report/m.gif") no-repeat;
    background-size:100% 100%;
    margin: auto;
    left:0;
    right:0px;
    top:-36px;
    padding-top: 312px;
  }
  .leftsty{
    position:absolute;
    left:332px;
    top:666px;
    display:block;
    width:50px;
    height:68px;
    font-size:18px;
    color:#3280bb;
    text-align:center;
    font-family:SourceHanSansCN-Medium;
    z-index: 66;
    line-height: 27px;
  }
  .centersty{
    position:absolute;
    left:468px;
    top:715px;
    display:block;
    width:100px;
    height:150px;
    font-size:48px;
    color:white;
    text-align:center;
    font-family:SourceHanSansCN-Medium;
    z-index: 66;
  }
  .rightsty{
    position:absolute;
    left:687px;
    top:703px;
    width:50px;
    height:68px;
    font-size:18px;
    color:#3280bb;
    text-align:center;
    font-family:SourceHanSansCN-Medium;
    z-index: 66;
    line-height: 27px;
  }


</style>
