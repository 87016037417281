var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "jkwt" },
      [
        _vm.health_status
          ? _c("div", { staticClass: "cir" }, [
              _c("img", {
                staticStyle: { width: "245px", "margin-top": "-10px" },
                attrs: { src: _vm.healthy_status(), alt: "健康状态" }
              })
            ])
          : _vm._e(),
        _c("div", { staticClass: "HealthInfo" }, [
          _c("span", { staticClass: "tip" }, [
            _vm._v(
              _vm._s(
                this.health_status === "亚健康"
                  ? "建议咨询专业人士进行健康调理"
                  : "恭喜，您的评分无异常，建议合理饮食，适度运动"
              )
            )
          ])
        ]),
        _vm._l(_vm.report.symptoms, function(item, index) {
          return _c(
            "span",
            {
              key: index,
              staticClass: "symptom",
              on: {
                click: function($event) {
                  return _vm.ReturnToExplain(item.name)
                }
              }
            },
            [_vm._v(_vm._s(item.name))]
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }