// 知识库依据

const books = {
  healthStatus: [ // 健康状态
    "[1]李灿东,吴承玉.中医诊断学[M].北京:中国中医药出版社.2018.",
    "[2]万学红,卢雪峰.诊断学[M].北京:人民卫生出版社,2018.",
    "[3]周仲瑛.中医内科学[M].北京:中国中医药出版社,2017.",
    "[4]葛均波,徐永健,王辰.内科学[M].北京:人民卫生出版社,2018.",
    "[5]谢梦洲,朱天民.中医药膳学[M].北京:中国中医药出版社,2016.",
    "[6]李其中.中医体质养生指南[M].上海:复旦大学出版社,2013.",
    "[7]匡调元.辨质论治通识读本——中国式个性化诊疗[M].北京:中国中医药出版社,2016.",
    "[8]图像数据库、算法研究来源于国家级、市级等10项课题资助"
  ],
  physical: [ // 体质辨识
    "[1]中华中医药学会发布.中医体质分类与判定[M].北京:中国中医药出版社,2009:1-7.",
    "[2]王琦.中医体质学:2008[M].北京:人民卫生出版社,2009:414.",
    "[3]王琦.中医体质辨识在公共卫生服务中的应用[J].福建中医药大学学报,2011,21(2):1-4.",
    "[4]周仲芳,杨思进,徐厚平,等.中医体质辨识在健康体检中的作用及其意义[J].中国医药指南,2014,12(28):274-275.",
    "[5]金梅红.2029名农村老年人体检结果分析及健康管理探讨[J].上海医药,2015,36(8):54-56.",
    "[6]杨青,王红云.6646例社区居民中医体质辨识资料分析[J].中国城乡企业卫生,2016,31(5):115-117.",
    "[7]梁蔚莉,何采辉.1000例佛山市顺德地区居民中医体质状况调查分析[J].中医药导报,2016,22(19):47-49.",
    "[8]陈燕丽,曹建平,李跃平,等.中医体质辨识在社区老年高血压患者健康管理中的应用分析[J].中国初级卫生保健,2015,29(9):26-28.",
    "[9]胡艳,王济,李玲孺,等.中医体质学的发展及其在治未病领域的实践[J].中国医药导刊,2019,21(7):437-441.",
    "[10]范琳,邢敏.中医体质辨识在医院体检中心亚健康人群护理中的而应用效果[J].中国初级卫生保健,2019,33(7):84-86.",
    "[11]图像数据库、算法研究来源于国家级、市级等10项课题资助"
  ],
  menopause: [ // 女性更年期
    "[1]王传珍.女性更年期综合征中医证候的文献研究[D].郑州:河南中医学院.2008:1-47.",
    "[2]陈荣玲.舌面脉体质辨识对更年期女性健康管理的指导和运用效果分析[J].中外女性健康研究,2017,10(21):13-19.",
    "[3]国家中医药管理局.绝经前后诸证(更年期综合征)诊疗方案(2017).",
    "[4]贾曼,刘慧聪,徐莲薇.浅述中医对更年期综合征的认识[J].陕西中医,2014,35(8):1033-1036.",
    "[5]中医病证诊断疗效标准[M].北京:中国医药科技出版社,2012,11:241.",
    "[6]任婕,王天芳,李力,等.更年期综合征常见中医证候及其症状分布特点的文献分析[J].江苏中医药,2008(04):67-70.",
    "[7]曾惠娣,邱碧芳.女性更年期综合征的相关因素分析[J].黑龙江医药,2017,30(01):24-26.",
    "[8]欧阳小妹.中医辨证施治女性更年期综合征的效果评价[J].中国处方药,2020,18(05),140-141.",
    "[9]叶燕萍.女性更年期综合征病机及辨证分型的研究—附106例临床资料[J].江苏中医,2000(08):18-19.",
    "[10]苑海民,哈虹.女性更年期综合征中医研究进展[J].国医论坛,2016,31(04),68-70.",
    "[11]图像数据库、算法研究来源于国家级、市级等10项课题资助"
  ],
  ovary: [ // 卵巢
    "[1]马雯雯,徐莲薇.卵巢储备功能下降中医证型分布研究[J].中医药临床匝值,2018,30(6):1068-1071.",
    "[2]张珊珊,兰丽琴.再生育妇女卵巢储备功能下降152例中医辨证分型初步研究[J].深圳中西医结合杂志,2016,26(24):39-41.",
    "[3]国家中医药管理局.卵巢早衰中医诊疗方案(2017).",
    "[4]栾雪薇.卵巢储备功能下降发病的相关因素及中医证候分布规律研究[D].兰州:甘肃中医药大学,2018.",
    "[5]滕秀香,陶立新,郭秀花,等.卵巢早衰中医证候潜变量模型分析[J].中国临床医生,2013,41(09):61-63.",
    "[6]梁昕.补肾调周法治疗肾阴虚型卵巢储备功能下降的临床研究[D].南京中医药大学,2017.",
    "[7]陈子江,田秦杰,乔杰,等.早发性卵巢功能不全的临床诊疗中国专家共识[J].中华妇产科杂志,2017,52(9):577-581.",
    "[8]向罗珺,夏天,马瑞红,等.中药调周法治疗卵巢储备功能下降疗效的Meta分析[J].临床合理用药杂志,2020,13(2):86-90.",
    "[9]金莎莎,王晓滨,刘歌,等.月经过少的中西医治疗进展[J].黑龙江科学,2016,7(17):15-17.",
    "[10]图像数据库、算法研究来源于国家级、市级等10项课题资助"
  ],
  hypertension: [ // 高血压
    "[1]国家中医药管理局.高血压中医健康管理技术规范(2011).",
    "[2]张荣珍,陈志祥,胡素颖,等.3578例高血压中医证型构成临床调查研究[J].中医药临床杂志,2007,19(04):360-364.",
    "[3]杨晓忱,熊兴江,王阶.高血压病辩证分型及证候客观化研究概况与展望[J].中国中药杂志,2014,39(02):157-161.",
    "[4]郑峰,胡世云,郭进建,等.高血压病中医辨证分析[J].河北中医,2000,22(09):651-653.",
    "[5]秦立明,刘健,樊小农.高血压中医辩证分型相关性研究进展[J].辽宁中医杂志,2015,42(06):1373-1375.",
    "[6]壅苏南,谭元生.高血压病中医证型分布规律及合并病的相关性研究[J].辽宁中医杂志,2018,45(05):914-916.",
    "[7]戴霞,姜婷,于杰,等.基于现代文献的高血压病证候多元统计分析[J].中西医结合心脑血管病杂志,2009,7(11):1339-1340.",
    "[8]章赛月,程志清.老年高血压病证型调查分析[J].实用中医药杂志,2003,19(10):546.",
    "[9]弓永莉,王利然,刘海红,等.老年高血压病中医证型调查分析,2014,6(10):132-133.",
    "[10]侯丕华,陈改玲,谷万里,等.老年高血压病中医证型分布规律及相关因素分析,2014,34(05):536-540.",
    "[11]图像数据库、算法研究来源于国家级、市级等10项课题资助"
  ],
  glycuresis: [ // 糖尿病
    "[1]国家中医药管理局.2型糖尿病患者中医健康管理技术规范(2011).",
    "[2]高景芝,王月芹.中医辩证治疗2型糖尿病疗效观察[J].中国现代药物应用,2010,4(14):147-148.",
    "[3]韩秀庆.中医辩证治疗糖尿病的临床疗效分析[J].世界最新医学信息文摘,2018,18(02):133-134.",
    "[4]祝谌予,郭赛珊,梁晓春.对糖尿病中医辨证指标及施治方药的探讨[J].上海中医药杂志,1982,(06):5-6.",
    "[5]冷雪,谷丽艳,朱芳.2型糖尿病中医证型流行病学调查及其中医病因病机初探[J].中华中医药杂志,2015,30(03):732-735.",
    "[6]欧阳雪琴,翟颖,沙树伟.从中医标本缓急辨证观点论治2型糖尿病[J].吉林中医药,2021,41(09):1149-1152.",
    "[7]代培,谢培凤,刘铜华.2型糖尿病患者中医证型的分布特点及主要证型中患者体重指数与兼证的相关性[J].中医杂志,2021,62(15):1338-1342.",
    "[8]中国中西医结合学会糖尿病专业委员会.中西医结合糖尿病诊疗标准(草案)[J].中国中西医结合杂志,2005,25(1):94-95.",
    "[9]史雅琼,马建伟.2型糖尿病患者中医证型分布及其并发症证型特点[J].河南中医,2017,37(10):1789-1791.",
    "[10]薛哲哲,孙卫卫,王艺.1569例2型糖尿病患者中医证型与临床指标相关性研究[J].中医药导报,2021,27(08):66-70.",
    "[11]图像数据库、算法研究来源于国家级、市级等10项课题资助",
  ]
}

export { books };