var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.report
    ? _c(
        "div",
        { staticStyle: { display: "flex", overflow: "auto", height: "100%" } },
        [
          _vm.report.baze
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "product" },
                  [
                    _c("div", { staticStyle: { height: "170px" } }, [
                      _c("img", {
                        staticClass: "prc-back",
                        attrs: { src: _vm.fh },
                        on: { click: _vm.returnto }
                      }),
                      _c("span", { staticClass: "title" }, [_vm._v("特别推荐")])
                    ]),
                    _vm._l(_vm.report.product, function(item, index) {
                      return _c("div", { key: index }, [
                        index % 2 === 0
                          ? _c(
                              "div",
                              {
                                staticClass: "producL",
                                style:
                                  _vm.marker === "10.1"
                                    ? { marginTop: "5px" }
                                    : { marginTop: "25px" }
                              },
                              [
                                _c("div", { staticClass: "product-img" }, [
                                  _c("img", {
                                    staticStyle: {
                                      width: "470px",
                                      height: "400px",
                                      "vertical-align": "middle"
                                    },
                                    attrs: {
                                      src: item.image ? item.image : _vm.logoty
                                    }
                                  })
                                ]),
                                _c("span", { staticClass: "product-span" }, [
                                  _vm._v(
                                    _vm._s(item.name ? item.name : "暂无商品")
                                  )
                                ])
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "producR",
                                style:
                                  _vm.marker === "10.1"
                                    ? { marginTop: "5px" }
                                    : { marginTop: "25px" }
                              },
                              [
                                _c("div", { staticClass: "product-img" }, [
                                  _c("img", {
                                    staticStyle: {
                                      width: "470px",
                                      height: "400px",
                                      "vertical-align": "middle"
                                    },
                                    attrs: {
                                      src: item.image ? item.image : _vm.logoty
                                    }
                                  })
                                ]),
                                _c("span", { staticClass: "product-span" }, [
                                  _vm._v(
                                    _vm._s(item.name ? item.name : "暂无商品")
                                  )
                                ])
                              ]
                            )
                      ])
                    })
                  ],
                  2
                )
              ])
            : _c("div", { style: { width: "100%" } }, [
                _c(
                  "div",
                  {
                    staticClass: "product_main",
                    style: _vm.access === "web" ? { height: "1920px" } : {}
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "div",
                          { staticClass: "menu" },
                          _vm._l(_vm.product, function(item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "menuopt",
                                style: {
                                  color:
                                    index === _vm.currentIndex
                                      ? "#00fefb"
                                      : "#ffffff",
                                  "border-bottom":
                                    index === _vm.currentIndex
                                      ? "4px solid #00fefb"
                                      : null
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.checkType($event, index)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            )
                          }),
                          0
                        ),
                        _vm._l(this.product, function(item, index) {
                          return _vm.currentIndex === index
                            ? _c(
                                "div",
                                { key: index, staticClass: "boxcontent" },
                                _vm._l(item.products, function(items, num) {
                                  return _c(
                                    "div",
                                    {
                                      key: num,
                                      staticClass: "boxitem",
                                      style:
                                        num % 2 === 0
                                          ? { marginRight: "25px" }
                                          : { marginLeft: "25px" },
                                      on: {
                                        click: function($event) {
                                          return _vm.openWaresContent(items)
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "boxImg" }, [
                                        _c("img", {
                                          attrs: { src: items.image }
                                        })
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { padding: "26px 10px" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                overflow: "auto",
                                                margin: "0"
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "boxname" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      items.name
                                                        ? items.name
                                                        : "未知"
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "boxprice" },
                                                [
                                                  _vm._v(
                                                    "¥ " +
                                                      _vm._s(
                                                        items.price
                                                          ? items.price
                                                          : "未知"
                                                      )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "boxdesc" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  items.desc
                                                    ? items.desc
                                                    : "暂无相关描述"
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        })
                      ],
                      2
                    ),
                    _vm.isShowProDetail ? _c("div", [_c("Wares")], 1) : _vm._e()
                  ]
                )
              ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }