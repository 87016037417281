// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/common/bj.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/report/gb_jcjg_icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".reporttzbs[data-v-f7456d98] {\n  width: 100%;\n  min-height: 100%;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  overflow: hidden;\n  background-color: #00090a;\n}\n.reporttzbs .close-btn[data-v-f7456d98] {\n    position: absolute;\n    right: 30px;\n    top: 30px;\n    width: 120px;\n    height: 77px;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.reporttzbs .tip[data-v-f7456d98] {\n    width: 94%;\n    margin-left: 3%;\n    font-size: 24px;\n    line-height: 48px;\n    color: #678e9f;\n    text-align: right;\n    margin-bottom: 4px;\n}\n.reporttzbs .bottom[data-v-f7456d98] {\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n    height: 100px;\n}\n.reporttzbs .bottom .tipImg[data-v-f7456d98] {\n      width: 50px;\n      height: 50px;\n}\n.reporttzbs .bottom .tipTxt[data-v-f7456d98] {\n      height: 42px;\n      font-size: 30px;\n      font-weight: bold;\n      color: #ffffff;\n      margin-left: 20px;\n}\n.reporttzbs .bottom-ten[data-v-f7456d98] {\n    height: 60px;\n}\n.nonetzbs[data-v-f7456d98] {\n  width: 100%;\n  min-height: 100%;\n  background-color: #00090a;\n}\n", ""]);
// Exports
module.exports = exports;
