<template>
  <div class="wxchronicchart">
    <div class="title">
      <span>中医辩证分型</span>
      <!-- <span class="tip">{{symptoms[0].name.includes('健康') ? '健康' : symptoms[0].name}}</span> -->
    </div>
    <div id="chronicchart" :style="{width: '100%', height: '590px'}"></div>
    <div class="subtitle">症状程度：
      <span>{{healthStatus}}</span>
      <img v-if="healthStatus.includes('轻')" src="../../../assets/img/wx/wx_row_down.png"/>
      <img v-if="healthStatus.includes('重')" src="../../../assets/img/wx/wx_row_up.png"/>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: "wxchronicchart",
  props: {
    symptoms: Array,
    symptomStatus: Array,
    beforeSymptomStatus: Array,
    healthStatus: String,
    typeAttention: String
  },
  data(){
    return {
      indicatorArray: [],
      scoreArray: [],
      beforeIndicatorArray: [],
      beforeScoreArray:[],
      maxScoreDic: null
    }
  },
  beforeMount(){
    // chart最高分数据处理 高血压最高分是80 糖尿病最高分70
    // 之前不同症状设置了不同的最高分 目前分数比较适合统一设置  先这样设置
    if(this.typeAttention === '高血压') {
      this.maxScoreDic = {阴虚阳亢: 80, 气血两虚: 80, 痰瘀互结: 80, 肾精不足: 80, 肾阳亏虚: 80, 冲任失调: 80}
    } else {
      this.maxScoreDic = {阴虚热盛: 70, 湿热困脾: 70, 气阴两虚: 70, 阴阳两虚: 70, 血瘀脉络: 70}
    }
    var that = this
    // 本次
    this.symptomStatus.forEach(function(element){
      const nameString = that.symptoms.length > 0 ? that.symptoms[0].name : ''
      if(element.name === nameString) {
        that.indicatorArray.push({
          name: element.name, max: that.maxScoreDic[element.name], color: '#ffa525'
        })
      } else {
        that.indicatorArray.push({
          name: element.name, max: that.maxScoreDic[element.name]
        })
      }
      // 存在负分的情况  女性报告有 慢病暂未知
      if(element.score >=0 ) {
        that.scoreArray.push(element.score)
      }else {
        that.scoreArray.push(0)
      }
    })
    //上次
    if(this.beforeSymptomStatus) {
      this.beforeSymptomStatus.forEach(function(element){
        that.beforeIndicatorArray.push({
          name: element.name, max: that.maxScoreDic[element.name]
        })
        if(element.score >=0 ) {
          that.beforeScoreArray.push(element.score)
        }else {
          that.beforeScoreArray.push(0)
        }
      })
    }else {
      this.symptomStatus.forEach(function(element){
        that.beforeScoreArray.push(0)
      })
    }
  },
  mounted(){
    this.drawChart()
  },
  methods: {
    drawChart(){
      const myChart = echarts.init(document.getElementById('chronicchart'));
      myChart.setOption({
        legend: {
          data: this.beforeSymptomStatus === null ? ['本次'] : ['本次', '上次'],
          show: true,
          right: "1%",
          width: "auto",
          orient: "vertical",
          itemWidth: 50,
          itemHeight: 6,
          selectedMode: false,
          textStyle: {
            color: "#333",
            fontSize: 30,
          }
        },
        color:this.beforeSymptomStatus === null ? ['#ffad2c'] : ['#ffad2c','#069eff'],
        radar: {
          center: ['50%', '50%'],
          startAngle: 90,
          radius: 235,
          splitNumber: 5,
          indicator: this.indicatorArray,
          axisName: {
            color:'#999',
            formatter(value) {
              return `{a|${value}}`;
            },
            rich: {
              a: {
                fontSize: 36,
                lineHeight: 40,
              }
            },
          },
          axisLine: { //坐标轴
            show: false
          },
          splitLine: {
            lineStyle: {
              width: 1,
              color: '#fff'
            }
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ['rgba(7, 158, 255, 0.5)','rgba(7, 158, 255, 0.4)','rgba(7, 158, 255, 0.3)','rgba(7, 158, 255, 0.2)','rgba(7, 158, 255, 0.1)'],
            }
          },
        },
        series: [{
          type: 'radar',
          symbol: "none", // 去掉图表中各个图区域的边框线拐点
          data: [
            {
              name: '本次',
              value: this.scoreArray,
              lineStyle: {
                color: '#ffad2c',
                width: 3,
                type:'solid'
              },
            },
            {
              name: '上次',
              value: this.beforeScoreArray,
              lineStyle: {
                color: '#069eff',
                width: this.beforeSymptomStatus === null ? 0 : 2,
                type:'solid' //dotted
              },
            },
          ]
        }]
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.wxchronicchart {
  background-color: #fff;
	border-radius: 30px;
  padding: 57px 46px 30px 51px;
  margin-top: 45px;
  .title {
    color: #333;
    font: {
      size: 48px;
      family: PingFangSC-Medium;
    };
    width: 100%;
    height: 50px;
    border-left: 6px solid #1288eb;
    text-align: left;
    margin-bottom: 30px;
    span {
      margin-left: 20px;
    }
    .tip {
      color: #1288eb;
    }
  }
  .subtitle {
    color: #333;
    display: flex;
    align-items: center;
    padding-top: 10px;
    font: {
      size: 36px;
      family: PingFangSC-Medium;
    };
    margin: 0 0 40px 30px;
    span {
      color: #1288eb;
    }
    img {
      width: 18px;
      margin-left: 10px;
    }
  }
}
</style>
