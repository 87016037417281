// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/report/gb_jcjg_icon.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/img/report/xian_jcjg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n.headerd[data-v-4fe6b059] {\n  height: 155px;\n  padding-top: 2px;\n  overflow: hidden;\n}\n.header[data-v-4fe6b059] {\n  height: 150px;\n  padding-top: 62px;\n  overflow: hidden;\n}\n.header-top[data-v-4fe6b059]{\n  /*width: 100%;*/\n  /*margin: 0 auto;*/\n  display: block;\n  line-height: 1.2;\n  margin-top: 10px;\n  width: 80%;\n}\n.result[data-v-4fe6b059]{\n  font-size: 65px;\n  color: #00fefb;\n  font-family: TITLE;\n  margin-left: 40px;\n}\n.title[data-v-4fe6b059]{\n  font-size: 25px;\n  color: #72c0d6;\n  font-family: SourceHanSansCN-Regular;\n  margin-left: 18px;\n  display: inline-block;\n}\n.title span[data-v-4fe6b059] {\n  display: inline-block;\n  font-size: 25px;\n  width: 27px;\n  height: 27px;\n  border: 1px solid #72c0d6;\n  border-radius: 50%;\n  text-align: center;\n  position: relative;\n  top: -20px;\n  left: 20px;\n}\n.return[data-v-4fe6b059]{\n  position: absolute;\n  left: 922px;\n  top: 48px;\n  display: block;\n  width: 120px;\n  height: 80px;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n}\n.borderd[data-v-4fe6b059]{\n  position: absolute;\n  left: 0;\n  top: 80px;\n  display: block;\n  width: 1000px;\n  height: 16px;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;\n}\n.border[data-v-4fe6b059]{\n  position: absolute;\n  left: 0;\n  top: 137px;\n  display: block;\n  width: 1000px;\n  height: 16px;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;\n}\n.header-bottom[data-v-4fe6b059]{\n  width: 100%;\n  display: block;\n  margin-top: 5px;\n}\n.time[data-v-4fe6b059],\n.count[data-v-4fe6b059]{\n  font-size: 30px;\n  color: #ffffff;\n  margin-left: 40px;\n  font-family: SourceHanSansCN-Regular;\n}\n.count[data-v-4fe6b059] {\n  margin-left: 10px;\n}\n.number[data-v-4fe6b059]{\n  font-size: 30px;\n  color: #ffffff;\n  margin-left: 40px;\n  font-family: SourceHanSansCN-Regular;\n}\n\n", ""]);
// Exports
module.exports = exports;
