var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.category !== "2" && _vm.report
    ? _c(
        "div",
        { staticClass: "main" },
        [
          _c("reportheader", {
            attrs: {
              reportId: _vm.report.display_id,
              time: _vm.report.display_time,
              count: _vm.report.detect_count
            }
          }),
          _c("personal", {
            attrs: {
              userInfo: _vm.report.user_info,
              cellphone: _vm.report.owner || _vm.report.agency_info
            }
          }),
          _vm.csr !== "HUASHI"
            ? _c("indicator", {
                attrs: { score: _vm.report.health_score, report: _vm.report }
              })
            : _c("summaryhuashi", {
                attrs: {
                  score: _vm.report.health_score,
                  symptoms: _vm.report.symptom_status,
                  report: _vm.report
                }
              }),
          _c("illness", {
            attrs: {
              health_status_text: _vm.report.health_status_text,
              owner: _vm.report.owner,
              symptoms: _vm.report.symptoms
            }
          }),
          _c(
            "div",
            { staticClass: "result-report" },
            [
              _c("faceresult", {
                attrs: {
                  report: _vm.report,
                  result: _vm.report.face_result,
                  face_photo_url: _vm.report.face_photo_url,
                  pulse: _vm.report.has_pulse
                }
              }),
              _c("tongueresult", {
                attrs: {
                  report: _vm.report,
                  result: _vm.report.tongue_result,
                  tongue_photo_url: _vm.report.tongue_photo_url,
                  pulse: _vm.report.has_pulse
                }
              }),
              _vm.report.has_pulse
                ? _c("pulseresult", {
                    attrs: { url: _vm.pulse_url, isSelfSkip: _vm.isSelfSkip }
                  })
                : _vm._e(),
              _c("qrcode", {
                attrs: { pulse: _vm.report.has_pulse, report: _vm.report }
              }),
              _c("knowledg", {
                attrs: {
                  report: _vm.report,
                  is_print: _vm.report.is_print,
                  pulse: _vm.report.has_pulse
                }
              })
            ],
            1
          ),
          _vm._m(0),
          _vm.isShowJKFN || _vm.isShowCPTJ
            ? _c("div", { staticClass: "report-bottom" }, [
                _c("div", { staticClass: "health" }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "36px",
                        color: "#feffff",
                        "text-align": "center"
                      }
                    },
                    [_vm._v(" 根据您最具倾向性的体质推荐的个性化健康方案 ")]
                  ),
                  _c(
                    "div",
                    [
                      _c("health", {
                        attrs: {
                          report: _vm.report,
                          isShowJKFN: _vm.isShowJKFN,
                          isShowCPTJ: _vm.isShowCPTJ
                        }
                      }),
                      _c("recommendation", {
                        attrs: {
                          report: _vm.report,
                          isShowJKFN: _vm.isShowJKFN,
                          isShowCPTJ: _vm.isShowCPTJ
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _c("reportfooter", {
            attrs: { report: _vm.report, setting: _vm.report.vendor_setting }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: { padding: "0 40px", "margin-top": "17px", height: "50px" }
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              display: "block",
              color: "#678e9f",
              "font-size": "30px",
              "text-align": "right"
            }
          },
          [_vm._v(" 此报告仅作参考，如有不适请及时就医 ")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }