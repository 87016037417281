// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/report/pulse/mxbt_jkjx_icon.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/img/report/pulse/line_jkjx_icon.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../assets/img/report/pulse/guang_jkjx_icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "\n.box[data-v-266dca36] {\n  padding-bottom: 12px;\n  /*border-bottom: 1px solid #01477d;*/\n}\n.hand .title[data-v-266dca36] {\n  width: 79%;\n  height: 110px;\n  margin: 82px auto;\n  text-align: center;\n  font-size: 48px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: 100% 100%;\n  line-height: 110px;\n}\n.hand .handImg[data-v-266dca36] {\n  background-color: #fff;\n  height: 486px;\n}\n.hand img[data-v-266dca36] {\n  width: auto;\n  height: 100%;\n  display: block;\n  margin: 0 auto;\n}\n.total[data-v-266dca36] {\n  font-size: 38px;\n}\n.part[data-v-266dca36] {\n  display: flex;\n  align-items: center;\n  margin-top: 80px;\n}\n.part>div[data-v-266dca36] {\n  flex: 1;\n  text-align: center;\n  font-size: 45px;\n}\n.part>div[data-v-266dca36]:nth-child(2){\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-size: auto 100%;\n  background-repeat: no-repeat;\n}\n.pulseName>span[data-v-266dca36]{\n  color: #00e4ff;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  display: inline-block;\n  background-repeat: no-repeat;\n  background-size: 100% 100%;\n  padding: 8px 18px;\n  font-weight: bold;\n  font-size: 48px;\n}\n.detail p[data-v-266dca36] {\n  width: 80px;\n  height: 80px;\n  border: 1px solid #fff;\n  border-radius: 50%;\n  position: relative;\n  font-size: 42px;\n  margin: 0 auto;\n}\n.detail p>span[data-v-266dca36] {\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: calc(50% - 21px);\n}\n.detail>span[data-v-266dca36] {\n  display: block;\n  padding-top: 10px;\n  font-size: 35px;\n}\n.tips[data-v-266dca36] {\n  margin-top: 22px;\n  /*margin-bottom: 1rem;*/\n  padding: 25px 50px;\n  font-size: 35px;\n  color: #00e4ff;\n  background-color: #041937;\n  line-height: 60px;\n}\n.notice[data-v-266dca36]{\n  font-size: 30px;\n  color: #295b81;\n  font-weight: normal;\n  margin-top: 20px;\n}\n\n", ""]);
// Exports
module.exports = exports;
