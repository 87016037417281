<template>
  <div class="parsing">
    <div class="header">
      <div class="back-btn" @click="goBack">返&nbsp;回</div>
      <div class="header-title">{{reportData.result}}</div>
    </div>
    <!--  -->
    <div :class="marker === '10.1' ? 'container container-ten' : 'container'">
      <div :class="definition.content.length === 1 ? 'result result-healthy' : 'result'" v-for="(item, index) in definition.content" :key="index">
        <p v-if="index%2 == 0">{{item}}</p>
        <div v-else class="btn-content">
          <div class="btn" v-for="(e, i) in item.split('、')" :key="i">{{e}}</div>
        </div>
      </div>
      <!--  脏腑  节气 -->
      <div :class="marker === '10.1' ? 'item-btn item-btn-10' : 'item-btn'">
        <div @click="visceraEvent" :class="isViscera ? 'btn-left btn-select' : 'btn-left'">脏腑风险</div>
        <div @click="termsEvent" :class="!isViscera ? 'btn-right btn-select' : 'btn-right'">节气风险</div>
      </div>
      <!--  -->
      <div v-if="reportData" class="viscera-chart">
        <viscera-chart v-if="isViscera" :physicalViscera="reportData.physical_viscera[0]" :physical="reportData.result"></viscera-chart>
        <terms-chart v-else :physicalTerm="reportData.physical_term[0]" :physical="reportData.result"></terms-chart>
      </div>
      <!--  -->
      <div v-if="isShowBtn" class="healthy-btn" @click="gotoHomePage">节气提示</div>
    </div>
  </div>
</template>

<script>
import VisceraChart from '@/components/physical/viscerachart.vue';
import TermsChart from '@/components/physical/termschart.vue';
import { queryObject } from '../../../utils/common.js';
const { marker = '' } = queryObject();

export default {
  components: { VisceraChart, TermsChart },
  data(){
    return {
      reportData: null,
      definition: null,
      isViscera: true, // true是脏腑  false为节气
    }
  },
  computed: {
    marker(){
      return marker
    },
    isShowBtn(){
      // 是否展示节气提示-----非10.1寸 + 节气风险版块 + 非后台管理系统上，非版本低的）报告才展示,）
      return marker !== '10.1' && !this.isViscera && window.zhiyun && this.reportData.term_show;
    }
  },
  beforeMount() {
    this.reportData = this.$store.state.report;
    const tipString = this.reportData.result.substring(0,3)
    this.definition = this.$store.state.report.solutions.tizhi_definition[tipString];
  },
  methods: {
    goBack(){
      this.$router.back()
    },
    visceraEvent(){
      this.isViscera = true
    },
    termsEvent() {
      this.isViscera = false
    },
    gotoHomePage(){
      // 调用安卓的方法，跳转到节气风险页面
      if(window.zhiyun) {
        window.zhiyun.openWeatherTip();
      }
    },
  }
}
</script>

<style lang="scss" scoped>
$bgImg: '../../assets/img/common/bj.png';
$backBtn: '../../assets/img/physical/fh_ty_icon.png';
$headerTitle: '../../assets/img/report/btbj_icon.png';
$jssmbj: '../../assets/img/physical/kuang_lab_icon.png';
$btnselect: '../../assets/img/physical/an1_tzjx_icon.png';
$btn: '../../assets/img/physical/an_tzjx_icon.png';
$jkfa: '../../assets/img/physical/jkfa_jcjg_btn.png';

.parsing {
  width:100%;
  min-height: 100%;
  background:url($bgImg);
  background-size:cover;
  overflow: hidden;
    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 90%;
      margin: 90px 0 0 5%;
      .back-btn {
        background:url($backBtn) no-repeat;
        background-size:100% 100%;
        width: 152px;
        height: 82px;
        color:#fff;
        font-size:36px;
        text-align:center;
        line-height:75px;
      }
      .header-title {
        font-size: 48px;
        color:#00fefb;
        font-family:TITLE;
        width:366px;
        height:111px;
        margin-left: 16%;
        background:url($headerTitle) no-repeat;
        text-align:center;
        line-height:111px;
        background-size:100% 100%;
      }
    }
    .container {
      width: 90%;
      margin-left: 5%;
      max-height: 1530px;
      padding-top: 100px;
      background:url($jssmbj);
      background-size:100% 100%;
      margin-top: 92px;
      color:#ffffff;
      font-size:30px;
      line-height: 40px;
      .result {
        padding: 10px 37px 0 37px;
        .btn-content {
          display: flex;
          flex-direction: row;
          flex-flow: wrap;
          padding: 0 49px;
        }
        .btn {
          border: 1px solid #00eaff;
          padding: 2px 10px;
          margin: 10px 10px;
        }
        p {
          text-indent:60px;
        }
      }
      // 平和质
      .result-healthy {
        padding: 10px 37px 40px 37px;
        p {
          text-indent:60px;
          line-height: 50px;
        }
      }
      .item-btn {
        display: flex;
        flex-direction: row;
        margin: 50px 38px 20px 38px;
        .btn {
          color: #fff;
          font-size: 36px;
          width: 50%;
          height: 82px;
          line-height: 82px;
          text-align: center
        }
        .btn-left {
          @extend .btn;
          background:url($btn);
          background-size:cover;
        }
        .btn-right {
          @extend .btn;
          background:url($btn);
          background-size:cover;
        }
        .btn-select {
          color: #00f4fd;
          background:url($btnselect);
        }
      }
      .item-btn-10 {
        margin: 35px 38px 20px 38px;
      }
      //
      .viscera-chart {
        /*height: 620px;*/
        margin: -10px 38px 30px 38px;
        border: 1px solid #164970;
        display: flex;
        justify-content: center;
      }
      .healthy-btn {
        width: 334px;
        height: 128px;
        background:url($jkfa) no-repeat;
        font-size: 36px;
        font-weight: normal;
        color: #ffffff;
        text-align: center;
        line-height: 127px;
        margin: auto;
      }
    }
    .container-ten {
      height: 1242px;
      padding-top: 80px;
    }
}
</style>
