<template>
  <div class="header">
    <div class="header-top">
      <span class="result">检测结果</span>
      <p class="title">基于上海中医药大学研究专利开发<span v-if="marker !== '10.1'" @click="checkBooks">?</span></p>
      <div @click="doclose" class="return"></div>
      <div class="border"></div>
    </div>
    <div class="header-bottom">
      <span class="time">检测时间：{{ date }}</span>
      <span class="count" v-if="marker !== '10.1'">(第{{count}}次)</span>
      <span class="number">报告编号：{{ reportId }}</span>
    </div>
  </div>
</template>

<script>
  import { queryObject } from '../../../../utils/common.js';
  const { marker = '' } = queryObject();


  export default {
    name: "menopauseheader",
    props: {
      reportId: String,
      time: String,
      type_attention: String,
      count: Number
    },
    computed: {
      date(){
        const [date, time] = this.time.split(' ');
        return `${date} ${time}`
      },
      marker(){
        return marker
      }
    },
    methods:{
      doclose(){
        if (window.zhiyun) {
          window.zhiyun.closereport();
        }
      },
      checkBooks(){ // 查看知识库依据
        const dict = { // 报告类型 《==》字段区分
          "更年期": "menopause",
          "卵巢": "ovary"
        };
        const type = dict[this.type_attention];
        this.$router.push({
          name:'Knowledgebase',
          params: {
            type // type代表报告的类型，从而获取不同的知识库依据
          }
        })
      }
    }
  }
</script>

<style scoped>
  .header {
    height: 210px;
    padding-top: 2px;
    overflow: hidden;
  }
  .header-top{
    display: block;
    line-height: 1.2;
    margin-top: 28px;
    width: 80%;
  }
  .result{
    font-size: 60px;
    color: #00fefb;
    font-family: TITLE;
    margin-left: 40px;
  }
  .title{
    font-size: 25px;
    color: #99ffef;
    font-family: SourceHanSansCN-Regular;
    margin-left: 18px;
    display: inline-block;
  }

  .title span {
    display: inline-block;
    font-size: 25px;
    width: 27px;
    height: 27px;
    border: 1px solid #72c0d6;
    border-radius: 50%;
    text-align: center;
    position: relative;
    top: -20px;
    left: 20px;
  }

  .return{
    position: absolute;
    left: 922px;
    top: 25px;
    display: block;
    width: 120px;
    height: 80px;
    background:url("../../../assets/img/report/gb_jcjg_icon.png") no-repeat;
  }
  .border{
    position: absolute;
    left: 0;
    top: 100px;
    display: block;
    width: 1000px;
    height: 16px;
    background:url("../../../assets/img/report/xian_jcjg.png") no-repeat;
  }
  .header-bottom{
    width: 100%;
    display: block;
    margin-top: 27px;
  }
  .time,
  .count{
    font-size: 30px;
    color: #ffffff;
    margin-left: 40px;
    margin-top: 10px;
    line-height: 67px;
    font-family: SourceHanSansCN-Regular;
  }

  .count {
    margin-left: 10px;
  }

  .change{
    display: inline-block;
    font-size: 30px;
    text-align: center;
    line-height: 67px;
    color: #ffffff;
    float: right;
    margin-right: 40px;
    padding-bottom: 10px;
    width: 200px;
    height: 50px;
    background: url("../../../assets/img/\menopauseovary/scbg_icon.png");
    background-size: 100% 100%;
  }
  .number{
    font-size: 30px;
    color: #ffffff;
    margin-left: 40px;
    font-family: SourceHanSansCN-Regular;
  }
</style>
