<template>
  <div class="attentions">
    <h3>二、个性化养生方案</h3><br/>
    <header style="font-weight: bold">根据您最具倾向性的体质推荐以下个性化养生方案。</header>
    <h4>（一）饮食调养</h4><br/>
    <p>1、宜食<span v-for="(item,index) in foodList.good" :key="index">{{ item.name }}{{ index === foodList.good.length - 1 ? '。' : '、' }}</span></p>
    <p>2、忌食<span v-for="(item,index) in foodList.bad" :key="index">{{ item.name }}{{ index === foodList.bad.length - 1 ? '。' : '、' }}</span></p>
    <h3 class="title">推荐药膳</h3><br/>
    <h3 class="dietName">{{ dietList.name }}</h3><br/>
    <div class="tea">
      <div class="imgBox">
        <img :src="solutions.asset_prefix + dietList.asset" alt="药膳图片" />
      </div>
    </div>
    <div class="dietCook">
      烹煮方法：
    </div>
    <div class="psty" v-for="(item,index) in dietList.content" :key="index">
      {{ item }}
    </div>
    <div class="tea">
      <h4>（二）茶饮推荐</h4><br/>
      <div v-for="(item,index) in teaList" :key="index">
        <div class="imgBox">
          <img :src="solutions.asset_prefix + item.asset" alt="茶饮图片" />
        </div><br/>
        <header>{{ item.name }}</header><br/>
        <div class="dietMenu psty">
          清单：{{ item.content && item.content[0] }}
        </div>
        <div v-if="item.content[1]" class="dietCook psty">
          泡煮方法：{{ item.content && item.content[1] }}
        </div>
      </div>

    </div>
    <div class="massagePress">
      <h4>（三）穴位按压</h4><br/>
      <div class="imgBox">
        <img :src="solutions.asset_prefix + massageList.asset[0]" alt="穴位按压图片" />
      </div>
      <header>{{ massageList.name }}</header><br/>
      <div class="psty">
        {{ massageList.content && massageList.content[0] }}
      </div>
    </div>
    <div class="daily">
      <h4>（四）起居调养</h4>
      <p class="psty">
        {{ DailyList.name }}
      </p>
    </div>
    <div class="daily">
      <h4>（五）运动调养</h4>
      <div class="imgBox" v-if="SportsList.asset">
        <img :src="solutions.asset_prefix + SportsList.asset[0]" alt="运动调养图片" />
      </div>
      <p class="psty">
        {{ SportsList.name }}
      </p>
    </div>
    <div class="daily" v-if="symptomsName !== '平和质'">
      <h4>（六）经典药方</h4>
      <div v-for="(item, index) in MedicineList" :key="index" class="psty">
        <b>【{{item.name}}】</b>
        <p class="tea-title">组方：</p>
        <p class="common-text">{{item.content[0]}}</p>
        <p class="tea-title">功效：</p>
        <p class="common-text">{{item.content[1]}}</p>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: "methodtzbs",
    props:{
      symptoms: Array,
      solutions: Object,
    },
    data(){
      return{

      }
    },
    computed:{
      symptomsName(){
        return this.symptoms[0].name;
      },
      foodList(){
        const foodList = {};
        foodList.good = this.solutions['tizhi_good_food'] && this.solutions['tizhi_good_food'][this.symptomsName]; // 宜食
        foodList.bad = this.solutions['tizhi_bad_food'] && this.solutions['tizhi_bad_food'][this.symptomsName]; // 忌食
        return foodList
      },
      dietList(){ // 推荐药膳
        const diet = this.solutions['tizhi_diet_therapy'];
        return diet && diet[this.symptomsName]
      },
      teaList(){ // 茶饮养生
        const tea = this.solutions['tizhi_tea'];
        return tea && tea[this.symptomsName];
      },
      massageList(){ // 穴位按压
        const massage = this.solutions['tizhi_massage'];
        return massage && massage[this.symptomsName]
      },
      DailyList(){ // 起居调养
        return this.solutions.tizhi_daily_life[this.symptomsName]
      },
      SportsList(){ // 运动调养
        return this.solutions.tizhi_sport_health[this.symptomsName]
      },
      MedicineList(){ // 经典药方
        return this.solutions.tizhi_classic_prescription && this.solutions.tizhi_classic_prescription[this.symptomsName];
      }
    }
  }
</script>

<style scoped>
  .attentions {
    margin-top: 30px;
  }

  header{
    text-align: center;
  }
  p{
    text-indent: 2em;
    line-height: 30px;
  }
  .psty{
    text-indent: 2em;
    line-height: 30px;
  }
  .title{
    margin-top: 20px;
  }
  .title::before {
    content: '';
    width: 21px;
    height: 21px;
    display: inline-block;
    background-size: 100% 100%;
    vertical-align: middle;
    background-image: url("../../../assets/img/print/sz.png");
  }
  .dietName{
    /*color: rgb(0, 160, 233);*/
    font-size: 16px;
    border-left: 2px solid black;
    padding-left: 6px;
    margin-top: 16px;
    line-height: 17px;
    height: 17px;
  }
  .dietCook {
    font-size: 16px;
    color: rgb(51, 51, 51);
    vertical-align: top;
    line-height: 21px;
    margin-top: 10px;
  }
  .dietCook::before {
    content: '';
    width: 18px;
    height: 21px;
    display: inline-block;
    background-size: 100% 100%;
    vertical-align: middle;
  }
  .dietCook::before {
    background-image: url("../../../assets/img/print/pr.png");
  }

  .tea,
  .massagePress,
  .daily{
    margin-bottom: 20px;
  }



  .tea .imgBox,
  .massagePress .imgBox{
    background-color: rgb(245, 245, 245);
    padding: 13px 0;
  }

  .tea img,
  .massagePress img,
  .daily img{
    width: 263px;
    height: auto;
    border-radius: 10px;
    margin: 0 auto;
    display: block;
  }
  .daily img {
    width: 363px;
  }

  .preserveHealth .dietMenu,
  .preserveHealth .dietCook {
    font-size: 16px;
    color: rgb(51, 51, 51);
    vertical-align: top;
    line-height: 21px;
    margin-top: 10px;
  }
  .dietMenu::before,
  .dietCook::before {
    content: '';
    width: 18px;
    height: 21px;
    display: inline-block;
    background-size: 100% 100%;
    vertical-align: middle;
  }
  .dietMenu::before {
    background-image: url("../../../assets/img/print/rwqd.png");
  }

</style>
