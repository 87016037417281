var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isTotal
    ? _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "hand" }, [
          _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
          _c("div", { staticClass: "handImg" }, [
            _c("img", {
              attrs: { src: _vm.pulseData.image, alt: "脉象图" },
              on: { error: _vm.handleError }
            })
          ])
        ]),
        _c("div", { staticClass: "part" }, [
          _vm._m(0),
          _c("div", { staticClass: "detail" }, [
            _vm._m(1),
            _c("span", [_vm._v(_vm._s(_vm.getStr("cun")))])
          ]),
          _c("div", { staticClass: "detail" }, [
            _vm._m(2),
            _c("span", [_vm._v(_vm._s(_vm.getStr("guan")))])
          ]),
          _c("div", { staticClass: "detail" }, [
            _vm._m(3),
            _c("span", [_vm._v(_vm._s(_vm.getStr("chi")))])
          ])
        ])
      ])
    : _c("div", [
        _c("div", { staticClass: "hand" }, [
          _c("div", { staticClass: "title" }, [_vm._v("总脉")]),
          _c("div", { staticClass: "total" }, [
            _vm._v(_vm._s(_vm.pulseData.final_pulse))
          ]),
          _c("div", { staticClass: "tips" }, [
            _vm._v(" 提示: " + _vm._s(_vm.pulseData.desc) + " ")
          ]),
          _c("h3", { staticClass: "notice" }, [
            _vm._v("注：左右脉象医理结果不一致属正常现象。")
          ])
        ])
      ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pulseName" }, [
      _c("span", [_vm._v("分脉")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", [_vm._v("寸")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", [_vm._v("关")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", [_vm._v("尺")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }