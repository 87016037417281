var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.report && !_vm.isTzbsReport
    ? _c(
        "div",
        { staticClass: "print_report" },
        [
          _c("Personal", {
            attrs: {
              reportId: _vm.report.display_id,
              time: _vm.isTzbsReport
                ? _vm.report.time
                : _vm.report.display_time,
              userInfo: _vm.report.user_info,
              cellphone: _vm.report.owner || _vm.report.agency_info
            }
          }),
          _c("Agreement", { attrs: { url: _vm.report.sign_name_url } }),
          _c("Score", {
            attrs: {
              isTzbsReport: _vm.isTzbsReport,
              score: _vm.isTzbsReport ? 0 : _vm.report.health_score,
              tzbsResult: _vm.isTzbsReport
                ? _vm.report.result.substring(0, 3)
                : "",
              symptoms: _vm.report.symptoms,
              solutions: _vm.report.solutions
            }
          }),
          !_vm.isTzbsReport
            ? _c("Risk", {
                attrs: {
                  symptoms: _vm.report.symptoms,
                  owner: _vm.report.owner
                }
              })
            : _vm._e(),
          _c("Analysis", { attrs: { result: _vm.result } }),
          _c("Method", {
            attrs: {
              isTzbsReport: _vm.isTzbsReport,
              symptoms: _vm.report.symptoms,
              solutions: _vm.report.solutions
            }
          }),
          _vm.report.product && _vm.report.product.length > 0
            ? _c("Product", { attrs: { product: _vm.report.product } })
            : _vm._e(),
          !_vm.isTzbsReport ? _c("Knowledge") : _vm._e(),
          _vm.csr !== "SHBAZE" ? _c("Company") : _vm._e()
        ],
        1
      )
    : _c("div", { staticClass: "print_reporttzbs" }, [
        _vm.report
          ? _c(
              "div",
              [
                _c("Personaltzbs", {
                  attrs: {
                    reportId: _vm.report.display_id,
                    time: _vm.isTzbsReport
                      ? _vm.report.time
                      : _vm.report.display_time,
                    owner: _vm.report.owner,
                    user_info: _vm.report.user_info,
                    cellphone: _vm.report.owner || _vm.report.agency_info
                  }
                }),
                _c("Constitutiontzbs", { attrs: { report: _vm.report } }),
                _c("Solartzbs", { attrs: { report: _vm.report } }),
                _c("Analysistzbs", { attrs: { report: _vm.report } }),
                _vm.report.pulse.length !== 0
                  ? _c("Pulsetzbs", { attrs: { pulse: _vm.report.pulse } })
                  : _vm._e(),
                _c("Methodtzbs", {
                  attrs: {
                    symptoms: _vm.report.symptoms,
                    solutions: _vm.report.solutions
                  }
                }),
                _c("br"),
                _c("br"),
                _c("br"),
                _c("br"),
                _c("br"),
                _c("Footertzbs")
              ],
              1
            )
          : _vm._e()
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }