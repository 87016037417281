// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/menopauseovary/jktlbj_cpjg_icon.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/img/menopauseovary/zzc_cpjg_icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n.container[data-v-65b2f94c]{\n  margin-top:10px;\n  width: 100%;\n}\n.jkwt[data-v-65b2f94c]{\n  height: 250px;\n  margin-left:40px;\n  width:1000px;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size:100% 100%;\n  padding-top:25px;\n  overflow:hidden;\n}\n.cir[data-v-65b2f94c]{\n  width:30%;\n  height:100%;\n  float:left;\n  text-align: right;\n}\n.HealthInfo[data-v-65b2f94c]{\n  position:relative;\n  display:inline-block;\n  width:660px;\n  margin:15px  0 0 0;\n  padding:30px 0 0 25px;\n}\n.tip[data-v-65b2f94c]{\n  font-size: 30px;\n  color:#00c5fe;\n  font-family:SourceHanSansCN-Medium;\n  line-height: 42px;\n}\n.symptom[data-v-65b2f94c]{\n  display:inline-block;\n  width:238px;\n  height:75px;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;\n  background-size:100% 100%;\n  /*margin-right:20px;*/\n  color:#ffffff;\n  font-size:30px;\n  line-height:71px;\n  text-align:center;\n  margin:20px 0 0 33px;\n}\n\n", ""]);
// Exports
module.exports = exports;
