<template>
  <div>
    <div v-if="!isTzbsReport">
      <div class="score">
        <div class="circleBox">
          <van-circle v-model="score" :rate="score" :speed="speed"  :stroke-width="strokeWidth"
                      :size="size" :color="color"  :layer-color="layerColor" :text-font-size="20">
            <div class="text">
              <p :style="{ color: score > 89 ? 'green' : score > 59 ? '#FE822B' : 'red'}">{{ Math.round(score) }}</p>
              <p>健康指数(分)</p>
            </div>
          </van-circle>
        </div>
        <div class="symptom">
          <div class="conclusion" :style="{color: score > 89 ? 'green' : score > 59 ? '#FE822B' : 'red'}">{{scoreToStatusText}}</div>
          <p class="doctor" v-if="score <= 89">建议找中医专家调治</p>
          <div class="detailSymptom">
        <span v-for="item in symptoms" :key="item.name">
          {{item.name}}
        </span>
          </div>
          <div class="desc">
            <p>健康：90-100分</p>
            <p>亚健康：60-89分</p>
            <p>可能有疾病：0-59分</p>
          </div>
        </div>
      </div>
      <div class="explain">
        <h3>健康状态辨识总提纲解释说明</h3>
        <p>中医在“未病先防，既病防变，瘥后防复”方面有着独特价值和先进性。中医学在人体健康或疾病本质状态的认识上，早已形成了较为完整的
          系统认识论和方法学。它是从整体的状态把握人体健康或疾病的本质，从状态上调理与治疗。
        </p>
        <p>舌、面诊是中医诊病辨证的重要方法。《灵枢》云:“十二经脉，三百六十五络，其血气皆上于面而走空窍。”《辨舌指南•绪言》云：
          “舌为心之外候，苔乃胃之明征，察舌可占正之盛衰，验苔以识邪之出入。”说明通过观察舌、面诊的外在征象可以了解人体的健康状态和病情变化。
        </p>
        <p>本系统通过采集您的面象、舌象，结合10余个问题，实时监测您的身体健康状态，并给出健康报告和个性化的起居养生、饮食药膳、
          穴位按压、中医功法、音乐养生等健康保健方案。
        </p>
      </div>

    </div>
    <div v-else>
      <p class="tzbsResult">您的体质：<span>{{tzbsResult}}</span></p>
    </div>
    <div class="symptomDesc">
      <div v-for="item in symptoms" :key="item.name" :style="{margin: isTzbsReport? '25px 0 25px 0' : '80px 0 0 0' }">
        <div :style="illNameStyle">{{item.name}}</div>
        <h3>定义解释 ：</h3>
        <div>{{symptomName(item).definition}}</div>
        <h3>健康风险提示 ：</h3>
        <div>{{symptomName(item).notice}}</div>
        <h3>可能原因 ：</h3>
        <p v-for="(inner,index) in symptomName(item).reason" :key="index">
          {{inner}}
        </p>
      </div>
    </div>


  </div>

</template>



<script>
  export default {
    name: "score",
    props: {
      score: Number, // 打分（满分都是100）
      symptoms: Array,
      solutions: Object,
      isTzbsReport: Boolean, // 是否是体质辨识报告
      tzbsResult: String, // 体质辨识报告独有的体质
    },
    data(){
      return {
        speed: '100', // 动画速度（单位为 rate/s）
        strokeWidth: '60', // 进度条宽度
        size: '200px', // 表示动画过程中的实时进度
        color: '#fe822b', // 进度条颜色
        layerColor: '#bcbcbc', // 轨道颜色
      }
    },
    methods: {
      symptomName(item){
        // 加上一些判空的条件，保证取数的时候，不会报错
        const symptomObj = {};
        const fieldName = this.isTzbsReport? 'tizhi_definition' : 'symptom_definition'; // 体质辨识、健康状态报告,需要区分取不同的字段名
        const [ definition = '', health_jkfx = '', ...reason] = this.solutions && this.solutions[fieldName] && this.solutions[fieldName][item.name] && this.solutions[fieldName][item.name].content;

        symptomObj.definition = this.isTzbsReport? `${definition}` : `${definition}${health_jkfx}`; // 定义解释
        symptomObj.notice = this.isTzbsReport? `${health_jkfx}` : this.solutions && this.solutions.health_risk && this.solutions.health_risk[item.name] && `${item.name}${this.solutions.health_risk[item.name].content}` // 健康风险提示
        symptomObj.reason = reason; // 可能原因

        return symptomObj
      }
    },
    computed: {
      scoreToStatusText() { // 圈内的文字
        if(this.score) { // 只有健康状态报告才有分数，体质辨识没有
          if (this.score < 60) {
            return '可能有疾病';
          } else if (this.score < 90) {
            return '亚健康';
          }
          return '健康';
        } else {
          return null;
        }
      },
      illNameStyle(){
        // 区分健康状态、体质辨识报告
        if(this.isTzbsReport) {
          return {
            fontSize: '20px',
            color: 'rgb(254, 130, 43)',
            fontWeight: 'bold'
          }
        } else {
          return {
            fontSize: '16px',
            color: '#048AD7',
            fontWeight: 'bold'
          }
        }
      }

    },
  }
</script>

<style scoped>
  .score {
    display: flex;
    justify-content: center;
  }



  .text {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 200px
  }


  .text p:nth-child(1) {
    font-size: 63px;
    margin-top: 40%;
    font-weight: bold;
  }

  .text p:nth-child(2) {
    font-size: 24px;
    color: #333333;
  }

  .symptom {
    padding-left: 10px;
  }

  .conclusion {
    font-size: 43px;
    color:#FE822B;
    font-weight:bold;
  }

  .doctor {
    font-size: 20px;
    color:#333333;
    margin: 10px 0 15px 0;
  }

  .detailSymptom>span {
    display:inline-block;
    width:80px;
    height:43px;
    background-color:#00A2FF;
    margin-right:16px;
    color:#ffffff;
    font-size:20px;
    line-height:43px;
    text-align:center;
  }

  .desc{
    margin-top: 17px;
  }

  .desc>p {
    margin-bottom:6px;
    font-size: 15px;
    color:#666666;
  }

  .explain h3 {
    font-size: 20px;
    color:#ffffff;
    font-weight:bold;
    width:300px;
    height:40px;
    text-align:center;
    line-height:40px;
    background-color:#00A2FF;
    border-radius:20px;
    margin: 20px auto;
  }

  .explain p {
    text-indent: 2em;
    color:#333333;
    font-size:16px;
    line-height:25px;
    letter-spacing:1px;
  }

  .tzbsResult {
    font-size: 16px;
  }

  .tzbsResult span {
    font-size: 43px;
    color: rgb(254, 130, 43);
    font-weight: bold;
  }

  /*.symptomDesc .descBox {*/
  /*  margin-top: 80px;*/
  /*}*/

  /*.symptomDesc .name {*/
  /*  font-size: 16px;*/
  /*  color: #048AD7;*/
  /*  font-weight: bold;*/
  /*}*/

  .symptomDesc h3,
  .symptomDesc p{
    font-size: 16px;
    color: #333333;
    margin-top: 16px;
    font-weight: normal;
  }

  .symptomDesc h3 {
    margin-top: 16px;
    margin-bottom: 6px;
  }

  .symptomDesc p {
  }

  .symptomDesc div {
    line-height: 21px;
  }

  .symptomDesc p {
    margin: 3px 0 0 0;
  }

</style>
