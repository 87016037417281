<template>
  <div class="wxtermschart">
    <div class="title">节气风险</div>
    <!--  -->
    <div class="current">
      <div class="illness">当前节气：{{terms.term}}</div>
      <div class="probability">
        <span class="illness">患病概率：{{terms.illness_probability>30 ? '较高' : terms.illness_probability<10 ? '低' : '中'}}</span>
        <img v-if="terms.illness_probability>30" src="../../../assets/img/wx/wx_row_up.png"/>
        <img v-if="terms.illness_probability<10" src="../../../assets/img/wx/wx_row_down.png"/>
      </div>
    </div>
    <!--  -->
    <div id="terms" :style="{width: '100%', height: '606px', backgroundColor: '#f5f5f5'}"></div>
    <div class="text">提示：{{terms.term_attention}}{{terms.term_add_attention}}</div>
  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  props: {
    terms: Object,
    result: String
  },
  data(){
    return {
      seriesArr: [],
      pointArr: [],
      healthyPhysical: '平和质',
    }
  },
  mounted(){
    const termString = this.result.substring(0,3)
    this.seriesArr = this.utils.seriesData(termString)
    this.pointData()
  },
  methods: {
    pointData(){
      const seasonArr = ['立春', '雨水', '惊蛰', '春分', '清明', '谷雨', '立夏', '小满', '芒种', '夏至', '小暑', '大暑', '立秋', '处暑', '白露', '秋分', '寒露', '霜降', '立冬', '小雪', '大雪', '冬至', '小寒', '大寒']
      const index = seasonArr.findIndex(item =>  item === this.terms.term);
      let commonDic = { name: '', value: `${this.terms.illness_probability}%`, xAxis: index, yAxis:this.terms.illness_probability}
      this.pointArr.push(commonDic)
      // 绘制
      this.drawChart()
    },
    drawChart(){
      const myChart = echarts.init(document.getElementById('terms'));
      myChart.setOption({
        legend: {
          data: this.result.substring(0,3) == '平和质' ? [this.healthyPhysical] : [this.healthyPhysical, this.result],
          show: true,
          right: "1%",
          width: "auto",
          orient: 'horizontal',
          selectedMode: false,
          itemWidth: 50,
          itemHeight: 6,
          textStyle: {
            color: "#666",
            fontSize: 30,
          }
        },
        color:this.result.substring(0,3) == '平和质' ? ['#069eff'] : ['#ff942c','#069eff'],  
        grid: { //图表距边框的距离,可选值：'百分比'¦ {number}（单位px）
          top: '11%',   // 等价于 y: '16%'
          left: '2%', 
          right: '2%',
          bottom: '1%',
          containLabel: true
        },
        tooltip: { // 提示框
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          axisLine: {
            lineStyle: { // 设置x轴颜色
              color: '#2dac6c',
            }
          },
          // 设置X轴数据旋转倾斜
          axisLabel: {
            color: '#666666',
            interval: 0,  //设置X轴数据间隔几个显示一个，为0表示都显示
            fontSize: 28,
            formatter:function(value){
              return value.split("").join("\n");
            }
          },
          boundaryGap: false, // boundaryGap值为false的时候，折线第一个点在y轴上
          data: ['立春', '雨水', '惊蛰', '春分', '清明', '谷雨', '立夏', '小满', '芒种', '夏至', '小暑', '大暑', '立秋', '处暑', '白露', '秋分', '寒露', '霜降', '立冬', '小雪', '大雪', '冬至', '小寒', '大寒']
        },
        yAxis: {
          name: '患病概率',
          nameGap: 30,
          nameTextStyle: {
            fontSize: 33,
          },
          type: 'value',
          axisLabel: {
            formatter: '{value}%',
            fontSize: 30
          },
          min:0, // 设置y轴刻度的最小值
          max:50,  // 设置y轴刻度的最大值
          splitLine:{
            lineStyle:{
              color:' #dcdcdc'
            }
            // show: false, //去除网格线
          },
          splitNumber: 10,  // 设置y轴刻度间隔个数
          axisLine: {
            lineStyle: { // 设置y轴颜色
              color: '#999999',
            }
          },
        },
        series: [
          {
            name: this.result.substring(0,3) == '平和质' ? null : this.result,
            data: this.result.substring(0,3) == '平和质' ? null : this.seriesArr,
            type: 'line',
            markPoint: {
              data: this.pointArr,
              symbolSize: 80,
              label:{  
                color: '#fff',
                fontSize: 18
             } 
            },
            // 注意：设置symbol: 'none'以后，拐点不存在了，设置拐点上显示数值无效
            symbol: 'none',
            smooth: 0.5, // 设置折线弧度，取值：0-1之间
            // 设置折线上圆点大小
            symbolSize: 0,
            // 设置拐点为实心圆
            // symbol:'circle',            
            label : {
              show: false
            },
          },
          {
            name: this.healthyPhysical,
            data: [10,8,6,5,6,8,10,8,5,8,10,8,5,8,10,8,5,8,10,9,8,7,6,5],
            type: 'line',
            symbol: 'none',
            smooth: 0.5,
            lineStyle:{                 
              width: 2,  // 设置线宽
              type:'dashed'  //'dotted'虚线 'solid'实线
            },
            label : { // 拐点上显示数值
              show: false
            },
            // symbolSize:8, // 设置折线上圆点大小
          }
        ], 
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.wxtermschart {
  background-color: #fff;
	border-radius: 30px;
  padding: 57px 46px 60px 51px;
  margin-top: 45px;
  .title {
    color: #333;
    font: {
      size: 48px;
      family: PingFangSC-Medium;
    };
    width: 260px;
    height: 50px;
    border-left: 6px solid #1288eb;
    text-align: center;
    margin-bottom: 30px;
  }
  .current {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 45px;
    margin-bottom: 30px;
    .illness {
      font: {
        size: 42px;
        family: SourceHanSansCN-Regular;
      };
      color: #333333;
    }
    .probability {
      display: flex;
      align-items: center;
      img {
        width: 15px;
        margin-left: 20px;
      }
    }
  }
  .text {
    color: #0096ff;
    font-size: 36px;
    font-family: SourceHanSansCN-Medium;
    width: 100%;
    text-align: left;
    margin-top: 45px;
  }
}
</style>