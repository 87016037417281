<template>
  <div>
    <header><br/>
      <img :src="coverImg" width="100%">
      <div class="personal">
        <p>报告编号：<span>{{ reportId }}</span></p>
        <p>检测时间：<span>{{ date.split(' ')[0] }}</span></p>
      </div>
    </header><br/><br/>
    <div class="basic">
      <p style="font-weight: bold">{{gender === '男'?'先生':'女士' }}，您好：</p>
      <p>欢迎您体验{{reportType}}中医健康状态评估，感谢您的信任与支持。</p>
      <p>以下为您{{this.time.split(' ')[0].split('-')[0]+'年'+this.time.split(' ')[0].split('-')[1]+'月'+this.time.split(' ')[0].split('-')[2]+'日'}}的中医检测报告，主要由{{partType}}部分组成，请您认真阅读，希望能给您带来帮助。</p>
      <p class="explain">说明：此检测报告具有一定的时效性和局限性，受客观技术条件和数据采集环境影响，报告结果可能不完全准确，仅可作为参考。如有任何疾病或感到不适，请咨询专业医生。
      </p>
    </div>
    <div>
      <h3>一、个人健康档案</h3><br/>
      <h4>(一) 基本情况</h4><br/>
      <table>
        <tr>
          <td>姓名</td>
          <td>{{user_info.name || '-'}}</td>
          <td>性别</td>
          <td>{{gender}}</td>
          <td>年龄</td>
          <td>{{user_info.ages === 0?'-':user_info.ages}}</td>
        </tr>
        <tr v-if="!chronicType">
          <td>身高(cm)</td>
          <td>{{user_info.height}}</td>
          <td>体重(kg)</td>
          <td>{{user_info.weight}}</td>
          <td>BMI</td>
          <td>{{ bmi }}</td>
        </tr>
        <tr>
          <td>手机号</td>
          <td colspan="2">{{phone}}</td>
          <td>身份证号</td>
          <td colspan="2">{{user_info.idcard || '-'}}</td>
        </tr>
        <tr>
          <td>既往病史</td>
          <td colspan="5" style="text-align: left">{{user_info.medical_history.length<=0?'无':user_info.medical_history.join('、')}}</td>
        </tr>
        <tr v-if="chronicType === '糖尿病'">
          <td>并发症</td>
          <td colspan="5" style="text-align: left">{{ $store.state.report.complication && $store.state.report.complication.join('、')}}</td>
        </tr>
      </table><br/>
    </div>
  </div>
</template>

<script>
  import cover from '../../../assets/img/print/cover.jpg'
  import cover_gxy from '../../../assets/img/print/cover_gxy.jpg';
  import cover_tnb from '../../../assets/img/print/cover_tnb.jpg';

  import {calculateBMI} from "../../../../utils/common";
  export default {
    name: "personaltzbs",
    props:{
      reportId: String,
      time: String,
      cellphone: Object,
      owner:Object,
      user_info:Object,
      chronicType: String
    },
    data(){
      return {
        coverImg: null,// 封面图片
        reportType: '', // 欢迎语：报告类型名称
        partType: '' , // 欢迎语：报告组成部分
      }
    },
    beforeMount(){
      switch (this.chronicType) {
        case '高血压':
          this.coverImg = cover_gxy;
          this.reportType = '高血压';
          this.partType = '基本信息、中医辩证分型、舌、面、脉诊分析、健康方案等';
          break;
        case '糖尿病':
          this.coverImg = cover_tnb;
          this.reportType = '2型糖尿病'
          this.partType = '基本信息、中医辩证分型、舌、脉诊分析、健康方案等';
          break;
        default: // 体质辨识
          this.coverImg = cover;
          this.reportType = ''
          this.partType = '体质辨识、体质解析、健康风险提示、节气风险提示、舌、面、脉诊分析、个性化养生方案等'
      }
    },

    computed: {
      date(){
        const [date, time] = this.time.split(' ');
        return `${date} ${time}`
      },
      gender(){ // 性别
        if (this.user_info.gender === 1) {
          return '男'
        } else if (this.user_info.gender === 2) {
          return '女'
        }
      },
      bmi(){
        const {bmi, text} = calculateBMI(this.user_info.height, this.user_info.weight);
        if(bmi) {
          return `${bmi}(${text})`;
        } else {
          return `0(${text})`
        }

      },
      phone(){
        return this.cellphone.cellphone? this.cellphone.cellphone : '无';
      },
      cover(){ // 封面图片

      },
    },
  }
</script>

<style scoped>
  header{
    position: relative;
  }
  .personal{
    width: 250px;
    border-left: 3px solid black;
    position: absolute;
    bottom: 50px;
    left: 60px;
  }
  .personal p{
    text-indent: 10px;
  }
  .basic{

  }
  .basic p{
    text-indent: 32px;
    font-family: 楷体;
    line-height: 25px;
  }
  .explain{
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    /*padding-top: 10px;*/
    /*padding-bottom: 10px;*/
    padding: 10px;
    height: 50px;
    display: block;
    background: #f5f5f5;
  }
  table{
    width: 100%;
    border-collapse:collapse;
  }
  table tr td{
    text-align: center;
    width: 16.6%;
    /*border: 1px solid black;*/
  }
  table,
  tr,
  td {
    border: 2px solid rgb(204, 204, 204);
    text-align: center;
    padding: 10px;
  }

</style>
