var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h4", [_vm._v("(二) 体质辨识")]),
      _c("p", [
        _vm._v("根据中医体质辨识评估结果，您最具倾向性的体质是： "),
        _vm.report.before_symptom_status
          ? _c("span", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v(
                _vm._s(
                  _vm.report.result.substring(0, 3) === "平和质"
                    ? "平和质，属于健康状态。"
                    : !_vm.report.result.slice(3)
                    ? _vm.report.result + "，属于亚健康状态，程度较重。"
                    : _vm.report.result + "，属于亚健康状态，程度较轻。"
                )
              )
            ])
          : _c("span", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v(
                _vm._s(
                  _vm.report.result.substring(0, 3) === "平和质"
                    ? "平和质，属于健康状态，请继续保持健康的生活方式。"
                    : !_vm.report.result.slice(3)
                    ? _vm.report.result +
                      "，属于亚健康状态，程度较重，建议您咨询专业的中医师调理。"
                    : _vm.report.result +
                      "，属于亚健康状态，程度较轻，建议您咨询专业的中医师调理。"
                )
              )
            ]),
        _vm.report.before_symptom_status
          ? _c("span", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v(_vm._s(_vm.report.physical_attention.attention + "。"))
            ])
          : _vm._e()
      ]),
      _vm.is_show_radar === "yes"
        ? _c("div", {
            style: {
              display: "block",
              width: "429px",
              height: "340px",
              margin: "0 auto",
              paddingTop: "32px"
            },
            attrs: { id: "root" }
          })
        : _c("img", {
            style: {
              display: "block",
              width: "429px",
              height: "340px",
              margin: "0 auto",
              paddingTop: "32px"
            },
            attrs: { src: _vm.radar_img_src, alt: "生成后的图" }
          }),
      _c("h4", [_vm._v("(三) 体质解析")]),
      _c("p", [_vm._v(_vm._s(_vm.content1))]),
      _vm._l(_vm.content2.split("、"), function(item, index) {
        return _vm.content2
          ? _c("span", { key: index, staticClass: "symptom" }, [
              _vm._v(_vm._s(item))
            ])
          : _vm._e()
      }),
      _vm.content3 ? _c("p", [_vm._v(_vm._s(_vm.content3))]) : _vm._e(),
      _vm._l(_vm.content4.split("、"), function(items) {
        return _vm.content4
          ? _c("span", { key: items, staticClass: "symptom" }, [
              _vm._v(_vm._s(items))
            ])
          : _vm._e()
      }),
      _vm.content5 ? _c("p", [_vm._v(_vm._s(_vm.content5))]) : _vm._e(),
      _c("div", {
        staticClass: "space",
        style:
          _vm.report.result.substring(0, 3) === "平和质"
            ? { height: "200px" }
            : { height: "100px" }
      }),
      _c("div", { staticClass: "body" }, [
        _c("div", { staticClass: "imgdiv" }, [
          _vm.report.result.includes("平和质")
            ? _c("img", {
                staticClass: "health-body",
                attrs: {
                  src: require("../../../assets/img/print/health_constitution.png"),
                  alt: "人体图"
                }
              })
            : _c("img", {
                staticClass: "health-ill",
                attrs: {
                  src: require("../../../assets/img/print/ill_constitution.png"),
                  alt: "人体图"
                }
              })
        ]),
        _c("div", { staticClass: "line" }, [
          _c(
            "div",
            { staticClass: "line-left" },
            _vm._l(_vm.leftRisk, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  class: item.content[0].includes("腹部")
                    ? "leftc fu"
                    : item.content[0].includes("头")
                    ? "leftc head"
                    : item.content[0].includes("脸")
                    ? "leftc face"
                    : item.content[0].includes("口腔")
                    ? "leftc oral"
                    : item.content[0].includes("胸")
                    ? "leftc chest"
                    : item.content[0].includes("肺")
                    ? "leftc lung"
                    : item.content[0].includes("心")
                    ? "leftc darling"
                    : item.content[0].includes("上臂")
                    ? "leftc upperarm"
                    : item.content[0].includes("肾脏")
                    ? "leftc kidney"
                    : item.content[0].includes("骨骼")
                    ? "leftc bone"
                    : item.content[0].includes("手掌")
                    ? "leftc hand"
                    : "leftc"
                },
                [
                  _c("span", [_vm._v(_vm._s(item.name))]),
                  _c("img", {
                    attrs: {
                      src: item.content[0].includes("口腔")
                        ? _vm.lineOral
                        : _vm.lineLeft,
                      alt: "人体线图"
                    }
                  })
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "line-right" },
            _vm._l(_vm.rightRisk, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  class: item.content[0].includes("腹部")
                    ? "rightc fu"
                    : item.content[0].includes("头部")
                    ? "rightc head"
                    : item.content[0].includes("心脏")
                    ? "rightc heart"
                    : item.content[0].includes("鼻子")
                    ? "rightc nose"
                    : item.content[0].includes("脸")
                    ? "rightc face"
                    : item.content[0].includes("口腔")
                    ? "rightc oral"
                    : item.content[0].includes("咽")
                    ? "rightc throat"
                    : item.content[0].includes("胸")
                    ? "rightc chest"
                    : item.content[0].includes("上臂")
                    ? "rightc upperarm"
                    : item.content[0].includes("肘关节")
                    ? "rightc elbow"
                    : item.content[0].includes("全身")
                    ? "rightc wholebody"
                    : item.content[0].includes("下半部")
                    ? "rightc bottomhalf"
                    : "rightc"
                },
                [
                  _c("img", {
                    attrs: {
                      src: item.content[0].includes("鼻子")
                        ? _vm.lineNose
                        : item.content[0].includes("口腔") ||
                          item.content[0].includes("咽")
                        ? _vm.lineRightOral
                        : _vm.lineRight,
                      alt: "人体线图"
                    }
                  }),
                  _c("span", [_vm._v(_vm._s(item.name))])
                ]
              )
            }),
            0
          )
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }