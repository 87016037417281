// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/report/zzbj_jcjg_icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.container[data-v-53b9607e]{\n  margin: 28px 30px 0;\n  height: 360px;\n  position: relative;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n}\n.column[data-v-53b9607e]{\n  position: absolute;\n  font-family: SourceHanSansCN-Regular;\n  font-size: 24px;\n  top: 60px;\n  left: 40px;\n  /*z-index: 20;*/\n}\n.classify[data-v-53b9607e]{\n  display: inline-block;\n  color: transparent;\n  margin-left: 79px;\n  height: 70px;\n  padding-top: 110px;\n}\n.box[data-v-53b9607e]{\n  width: 100%;\n  margin: 0 auto;\n  height: 280px;\n}\n#main[data-v-53b9607e]{\n  width: 100%;\n  height: 280px;\n  cursor: pointer;\n  padding-top: 20px;\n}\n\n", ""]);
// Exports
module.exports = exports;
