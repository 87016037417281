<template>
  <div class="headerTabTwo">
    <!--    茶饮养生-->
    <div v-if="TextContent && TextContent.length > 0" v-for="item in TextContent" :key="item.name">
      <div class="title">{{item.name}}</div>
      <img class="image" :src="report.solutions.asset_prefix + item.asset" alt="茶饮图片" />
      <span v-if="SymptomsName && SymptomsName !== '平和质'" class="tip-one">配料：</span>
      <span class="content-text">{{item.content[0]}}</span>
      <span v-if="item.content.length > 1" class="tip-one">泡煮方法：</span>
      <span class="content-text">{{item.content[1]}}</span>
      <span v-if="item.content.length > 2" class="tip-one">功效：</span>
      <span class="content-text">{{item.content[2]}}</span>
    </div>

<!--    <div v-if="TextContent" class="title">{{TextContent.name}}</div>-->
    <div v-if="AcupointContent" class="title">{{AcupointContent.name}}</div>
    <div v-if="TitleName && TitleName !== '精神调养'" class="title">{{TitleName}}</div>
    <div v-if="TitleName && TitleName === '精神调养' && marker !== '10.1'" class="music-healthy">
      <!--  此处增加音乐养生的相关内容-->
      <div class="music-icon">
        <!-- add by cll -->
        <img :class="isPlay ? 'circle ' : ''" src='../../assets/img/common/music_jkfa_icon.png' alt="" class="musicBg"/>
        <img class="music-btn" @click="startPlay()" :src="!isPlay ? require('../../assets/img/common/bf_jkfa_icon.png') : require('../../assets/img/common/zt_jkfa_icon.png') "/>
        <audio v-if="music" class="audio" :src="music.url" controls="controls" id="player"></audio>
      </div>
      <div class="music-tip">
        <!-- add by cll -->
        <img :class="isPlay ? 'active' : ''" src='../../assets/img/common/music1_jkfa_icon.png' alt=""/>
      </div>
      <div class="music-name" v-if="music">{{music.title}}</div>
    </div>

    <div v-if="TitleName && TitleName === '运动保健' && marker !== '10.1' && playerOptions && playerOptions.poster" class="music-healthy">
      <!-- 此处添加中医功法视频-->
      <div class="zygf">
        <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
        >
        </video-player>
      </div>
    </div>

    <span v-if="Detail" class="content-text">{{Detail.name}}</span>


    <!-- 香囊/经典药方-->
    <div v-if="PrescriptionContent || XnContent" v-for="(item , index) in filedContent" :key="index" class="prescription">
      <span class="teasty">{{item.name}}</span>
      <img  v-if="XnContent" class="image" :src="`${assets + item.asset[0]}`"/>
      <span class="medicinal">{{PrescriptionContent? '药物' : ''}}组成：</span>
      <span class="medicinal-explain">{{item.content[0]}}</span>
      <span class="medicinal">功效：</span>
      <span class="medicinal-explain">{{item.content[1]}}</span>
      <div v-if="XnContent">
        <span class="medicinal">适应症：</span>
        <span class="medicinal-explain">{{item.content[2]}}</span>
        <span class="medicinal">用法：</span>
        <span class="medicinal-explain">{{item.content[3]}}</span>
        <span class="medicinal">禁忌：</span>
        <span class="medicinal-explain">{{item.content[4]}}</span>
        <span class="medicinal-explain">{{item.content[5]}}</span>
      </div>
    </div>
    <span v-if="PrescriptionContent" class="tip">
      *请在医生、药师指导下购买和服用
    </span>
    <div v-if="isProduct">
      <ProductTzbs />
      <ProductDetail v-if="isShowProDetail" />
    </div>
  </div>
</template>

<script>
  import 'video.js/dist/video-js.css';
  import 'vue-video-player/src/custom-theme.css';
  import 'videojs-contrib-hls';
  import { queryObject } from '../../../utils/common.js';
  import ProductTzbs from '../../views/physical/producttzbs.vue'
  import ProductDetail from '../../views/physical/productdetailtzbs.vue';
  const { marker = '' } = queryObject();

  export default {
    name: "headertabtwo",
    props: {
      Img: String,
      SymptomsName: String,
      TextContent: Array,
      TitleName: String,
      Detail: Object,
      AcupointContent:Object,
      PrescriptionContent:Array,
      isProduct: Boolean, // 是否是产品推荐
      XnContent: Array,
      playerOptions: Object, // 运动保健下，视频播放相关配置
    },
    data(){
      return {
        report: this.$store.state.report,
        isPlay: false, //音乐是否播放 // add by cll
        music:'', // 音乐链接

      }
    },
    mounted() {
      if(this.TitleName === '精神调养' && this.marker !== '10.1') { // 精神调养-下面的--音乐养生
        const symptomsName = this.report.symptoms[0].name;
        console.log('mounted')
        this.music = this.getMusic(this.report.solutions,symptomsName)
      }
    },
    components: {
      ProductTzbs,
      ProductDetail
    },
    computed: {
      isShowProDetail() {
        return this.$store.state.is_show_detail // 是否展示详情，点击boxItem 把对应的内容展示
      },
      assets(){
        return this.$store.state.report.solutions && this.$store.state.report.solutions.asset_prefix;
      },
      filedContent(){
        // 返回香囊/经典药方的相关数据
        if(this.PrescriptionContent) {
          return this.PrescriptionContent
        }
        if(this.XnContent) {
          return this.XnContent
        }
        return []; // 确保不报错
      },
      marker(){
        return marker
      },
      csr(){
        return csr
      }
    },
    methods: {
      startPlay() { // add by cll 播放/暂停音乐
        let player = document.querySelector('#player')
        if (!this.isPlay) {
          player.play()
          this.isPlay = true
        } else {
          player.pause()
          this.isPlay = false
        }
      },
      getMusic( solutions, symptoms ){ // 获取音乐标题、播放url
        console.log(solutions, symptoms)
        const music = solutions && solutions.tizhi_music && solutions.tizhi_music[symptoms];
        if (!music) return null;
        return {
          title: music.name.replace(/\..*$/, ''),
          url: solutions.asset_prefix+music.asset[0],
        };
      },
    }
  }
</script>

<style lang="scss" scoped>
  $bk: '../../assets/img/physical/kuang_lab_icon.png';
  $commonW: 90%;
  $commonL: 5%;
  .headerTabTwo {
    font-size: 36px;
    color:#F1F1F1;
    width: $commonW;
    margin: 30px 0 0 $commonL;
    height: 1300px;
    line-height:60px;
    overflow: auto;
    .title {
      font-size: 42px;
      height: 36px;
      color:#ffffff;
      padding: 80px 0 50px 5%;
    }
    .image {
      width: auto;
      height: 480px;
      border-radius: 10px;
      margin: 0 auto;
      display: block;
    }
    .tip-one {
      display: block;
      margin-left: 5%;
    }
    .content-text {
      display:block;
      margin-left: 5%;
      margin-right: 5%;
      font-size:30px;
      color:#ffffff;
      line-height:45px;
    }
    .voidesty{
      display: inline-block;
      width: 90%;
      line-height: 100px;
      border: 1px solid transparent;
      border-radius: 4px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
      margin-left: 5%;
    }
    .prescription{
      /*height: 33.3%;*/
      /*padding-top: 20px;*/
      margin-left: 5%;
      margin-right: 5%;
      margin-bottom: 80px;
    }
    .teasty{
      margin-top:10px;
      display:block;
      font-size:36px;
      color:#D67206;
      font-weight: bold;
    }
    .medicinal{
      display:block;
      font-size:30px;
      height:40px;
      color:#ffffff;
      margin-top: 10px;
    }
    .medicinal-explain{
      margin-top:5px;
      display:block;
      font-size:30px;
      color:#ffffff;
      line-height:40px;
    }
    .tip{
      position: absolute;
      right: 55px;
      bottom: 0px;
      color: red;
      font-size: 30px;
    }
    .music-healthy {
      width: 100%;
      position: relative;
      padding-top: 120px;
      font-size: 40px;
      color: #fff;
      margin-bottom: 50px;
      .music-icon{
        width: 78%;
        margin-left: 11%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
          width: 100%;
        }

        img.musicBg {
          width: 80%;
        }

        .music-btn {
          width: 60px;
          height: 60px;
          position: absolute;
          top: 46%;
        }
        .audio {
          position: absolute;
          top: 46%;
          /*width: 0;*/
          /*height: 0;*/
          visibility: hidden;
        }
      }
      .circle {
        animation:rotate 16s linear infinite 0.1s;
      }
      @-webkit-keyframes rotate{
        0%{
          transform: rotate(0)
        }
        50%{
          transform: rotate(180deg)
        }
        100%{
          transform: rotate(360deg)
        }
      }
      .music-tip {
        position:absolute;
        top: 0;
        right: 120px;
        transform: rotate(-60deg);

        img {
          width: 80%;
          display: block;
        }
      }

      .music-tip .active {
        animation:playing 0.8s linear;
        animation-fill-mode: forwards;
      }

      @-webkit-keyframes playing{
        0%{
          transform: rotate(0deg)
        }
        100%{
          transform: rotate(60deg)
        }
      }

      .music-name {
        width: 100%;
        text-align: center;
        margin-top: 40px;
      }
    }
  }
</style>
