var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "reportfooter" }, [
    _vm.isShowLogo && !_vm.csr
      ? _c("div", { staticClass: "logo" }, [
          _vm._v("上海中药创新成果转化中心有限公司")
        ])
      : _vm._e(),
    _vm.isShowFoot === 2 || _vm.isShowFoot === 3
      ? _c("div", { staticClass: "logo" }, [_vm._v(_vm._s(_vm.setting.logo))])
      : _vm._e(),
    _vm.csr !== "SHBAZE" && (_vm.isShowFoot === 1 || _vm.isShowFoot === 3)
      ? _c("div", { staticClass: "logo" }, [
          !(_vm.csr === "CHENSHANG" || _vm.csr === "SXSZYYY" || !_vm.isShowFoot)
            ? _c("img", {
                staticStyle: { height: "35px", "margin-right": "0.5em" },
                attrs: {
                  src:
                    _vm.csr === "BJTRTKH"
                      ? _vm.trt
                      : _vm.csr === "SHWYSLSWKJ"
                      ? _vm.wysl
                      : _vm.logo,
                  alt: "logo"
                }
              })
            : _vm._e(),
          _vm._v(" " + _vm._s(_vm.tipName()) + " ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }