<template>
  <div class="wxtongueresult">
    <div class="top">
      <div class="title">舌诊结果</div>
      <div class="right" @click="lookPhoto(tonguePhotoUrl)">
        <img src="../../../assets/img/wx/wx_cktp_jcjg.png"/>
        <span>查看照片</span>
      </div>
    </div>
    <!--  -->
    <div class="result-btn">
      <div :class="item.id === btnId ? 'btn btn-selected' : 'btn' " v-for="(item, index) in btnArr" :key="index" @click="btnEvent(item.id)">
        <span style="width: 100px">{{item.name}}</span>
      </div>
    </div>
    <div v-for="item in btnArr" v-show="btnId === item.id" class="detailBox">
      <template v-if="btnId === 0">
        <div class="faceName">
          <p>{{tongueData[item.fieldName] && tongueData[item.fieldName].symptom}}</p>
          <p class="line">|</p>
          <span>参考值: {{item.standard}}</span>
        </div>
        <div><p class="symptom"> {{ tongueData[item.fieldName] && tongueData[item.fieldName].name }}</p></div>
        <p class="reality"><img src="../../../assets/img/wx/reality.png" alt="临床意义" />临床意义：</p>
        <p class="real"> {{ tongueData[item.fieldName] && tongueData[item.fieldName].content[0] }}</p>
      </template>
      <template v-else v-for="(innerPart, innerIndex) in item.totalFieldPart">
        <template v-if="tongueData[innerPart]">
          <div class="faceName">
            <p>{{tongueData[innerPart] && tongueData[innerPart].symptom}}</p>
            <p class="line">|</p>
            <span>参考值: {{item.standard[innerIndex]}}</span>
          </div>
          <div><p class="symptom"> {{ tongueData[innerPart] && tongueData[innerPart].name }}</p></div>
          <p class="reality"><img src="../../../assets/img/wx/reality.png" alt="临床意义" />临床意义：</p>
          <p class="real"> {{ tongueData[innerPart] && tongueData[innerPart].content[0] }}</p>
        </template>
      </template>
    </div>





    <!-- 高血压id SCF0glDx4z5CHnHw tongue_nature_color_info.symptom 为null -->
<!--    <div v-if="tongueData.tongue_nature_color_info" class="status">{{btnId === 0 ? tongueData.tongue_nature_color_info.symptom : btnId === 1 ? tongueData.tongue_fat_thin_info.symptom + '，' + tongueData.tongue_crack_info.symptom : tongueData.tongue_coat_color_info.symptom + '，' + tongueData.tongue_coat_thickness_info.symptom}}</div>-->
    <!--  -->
<!--    <div v-if="tongueData.tongue_nature_color_info" class="result">结果：{{btnId === 0 ? tongueData.tongue_nature_color_info.name : btnId === 1 ? `${tongueData.tongue_fat_thin_info.name}，${tongueData.tongue_crack_info.name}` : `${tongueData.tongue_coat_color_info.name}，${tongueData.tongue_coat_thickness_info.name}`}}</div>-->
    <!-- 健康提示 -->
<!--    <div class="healthy-tip">-->
<!--      <img src="../../../assets/img/wx/wx_healthy_tip.png"/>-->
<!--      <span>健康提示</span>-->
<!--    </div>-->
<!--    <div class="status">{{ tongueData.tongue_attention && tongueData.tongue_attention[0] && tongueData.tongue_attention[0].significance}}</div>-->
<!--    <span class="result">{{ tongueData.tongue_attention && tongueData.tongue_attention[0] && tongueData.tongue_attention[0].attention}}</span>-->
  </div>
</template>

<script>
import { ImagePreview } from 'vant'

export default {
  props:{
    tongueData: Object, 
    tonguePhotoUrl: String
  },
  data(){
    return {
      btnArr:[
        {name:'舌色', id: 0, standard: '舌淡红', fieldName: 'tongue_nature_color_info'},
        {name:'舌形', id: 1, standard: ['正常','无裂纹','无齿痕'], totalFieldPart: ['tongue_fat_thin_info','tongue_crack_info','tongue_indentation_info']},
        {name:'舌苔', id: 2, standard: ['苔白','苔薄'], totalFieldPart: ['tongue_coat_color_info','tongue_coat_thickness_info'] }
        ],
      btnId: 0,
    }
  },
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component
  },
  methods: {
    lookPhoto(image){
      var imageArray = []
      imageArray.push(image)
      ImagePreview({
        images: imageArray,
        closeable: true,
      })
    },
    btnEvent(id){
      this.btnId = id
    }
  },
}
</script>

<style lang="scss" scoped>
.wxtongueresult {
  background-color: #fff;
	border-radius: 30px;
  padding: 57px 46px 50px 51px;
  margin-top: 45px;
  .top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .title {
      color: #333;
      font: {
        size: 48px;
        family: PingFangSC-Medium;
      };
      width: 260px;
      height: 50px;
      border-left: 6px solid #1288eb;
      text-align: center;
      margin-bottom: 30px;
    }
    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 54px;
      }
      span {
        font-size: 36px;
        font-family: PingFangSC-Regular;
        color: #30c3fa;
        margin-left: 20px;
      }
    }
  }
  .common {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .result-btn {
    @extend .common;
    flex-direction: row;
    justify-content: space-around;
    margin: 100px 0;
    .btn {
      width: 150px;
      height: 150px;
      border: solid 1px #cccccc;
      border-radius: 75px;
      @extend .common;
      font: {
        size: 40px;
        family: SourceHanSansCN-Regular;
        color: #666666;
      }
    }
    .btn-selected {
      background-color: #f5b303;
      box-shadow: 0.4px 11px 12.6px 0.4px rgba(245, 179, 3, 0.32);
      border: solid 0px #cccccc;
      color: #ffffff;
    }
  }

  .detailBox {
    font-size: 42px;
    .faceName {
      font-size: 45px;
      text-align: center;
      position: relative;
      margin-top: 22px;

    }

    .faceName:not(:first-child){
      padding-top: 22px;
      border-top: 1px solid rgba(147,147,147,0.42);
    }

    .faceName>p {
      display: inline-block;
      /*font-weight: bold;*/
      color: #B9452B;
    }

    .faceName>p.line {
      position: absolute;
      left: calc(50% + 156px);
      color: #FCA626;
    }

    .faceName>span {
      position: absolute;
      left: calc(50% + 202px);
      font-size: 36px;
      line-height: 67px;
      color: #4A4A4A;
    }

    .symptom {
      padding: 20px 11px 20px 56px;
      color: #4A4A4A;
    }

    .reality{
      padding-top: 5px;
    }


    .reality>img {
      display: inline-block;
      vertical-align: middle;
      width: 45px;
      height: auto;
      margin-right: 11px;
    }

    .real {
      padding: 20px 11px 20px 56px;
    }

  }

  .status {
    width: 100%;
    text-align: center;
    font-family: SourceHanSansCN-Medium;
	  font-size: 45px;
    color: #333333;
    margin-bottom: 25px;
  }
  .healthy-tip {
    @extend .common;
    flex-direction: row;
    font-size: 45px;
    color: #30c3fa;
    width: 100%;
    text-align: center;
    margin-top: 40px;
    padding: 30px 0;
    border-top: 1px solid #30c3fa;
    img {
      width: 66px;
      height: 66px;
      margin-right: 10px;
    }
  }
  .result {
    color: #666666;
    font-family: SourceHanSansCN-Regular;
	  font-size: 42px;
    line-height: 69px;
  }
}
</style>