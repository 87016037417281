var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wxhealthyheader" }, [
    _c("div", { staticClass: "left" }, [
      _c("h2", [
        _vm._v(
          _vm._s(
            !_vm.symptoms[0].name.includes("健康") ? _vm.symptomString : ""
          )
        )
      ]),
      _c("span", [_vm._v(_vm._s(_vm.tipString))]),
      _c("div", { staticClass: "bottom" }, [
        _c("span", [_vm._v("报告编号：" + _vm._s(_vm.displayId))]),
        _c("span", [_vm._v("检测时间：" + _vm._s(_vm.time))])
      ])
    ]),
    _c("div", { staticClass: "right" }, [
      _c("div", [_vm._v("健康状态：")]),
      _c("span", [
        _vm._v(
          _vm._s(!_vm.symptoms[0].name.includes("健康") ? "亚健康" : "健康")
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }