<template>
  <!-- 体质历史记录得分 -->
  <div class="scoreschart">
    <div class="title">体质历史记录</div>
    <div id="scores" :style="{width: '100%', height: '606px', backgroundColor: '#fff'}" ></div>
    <div v-if="attention.attention" class="tip">提示：{{attention.attention}}</div>
  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  props:{
    historyReports:Array,
    attention: Object,
    result: String,
  },
  data(){
    return {
      dateArr: [],
      legendArr: [],
      seriesArr: []
    }
  },
  mounted(){
    this.initChartData()
  },
  methods:{
    initChartData(){
      var that = this
      this.historyReports.forEach(function(element){
        let time = element.time.slice(0,10)
        that.dateArr.push(time)
      })
      this.historyReports[0].symptoms.forEach(function(element){
        that.legendArr.push(element.name)
      })
      for(let i=0; i<this.historyReports.length; i++){
        for(let j=0; j<this.historyReports[i].symptoms.length; j++){
          if(i === 0) {
            let seriesDic = {}
            seriesDic.name = this.historyReports[i].symptoms[j].name
            seriesDic.type = 'line'
            let scoreArr = []
            scoreArr.push(this.historyReports[i].symptoms[j].score)
            seriesDic.data = scoreArr
            that.seriesArr.push(seriesDic)
          } else {
            that.seriesArr[j].data.push(this.historyReports[i].symptoms[j].score)
          }
        }
      }
      this.drawChart()
    },
    drawChart(){
      const myChart = echarts.init(document.getElementById('scores'));
      myChart.setOption({
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: this.legendArr,
          textStyle: {
            fontSize: 19
          },
        },
        grid: { //图表距边框的距离
          left: '5%',
          right: '10%',
          top: '20%',
          bottom: '2%',
          containLabel: true, //一般都带上这个，否则x,y轴的刻度值会被截取掉
        },
        // toolbox: {
          // left: 'center',
          // itemSize: 50,
          // top: 40,
          // feature: {
            // dataZoom: {
            //   yAxisIndex: 'none'
            // },
          //   restore: {}
          // }
        // },
        xAxis: {
          type: 'category',
          axisLabel: {
            color: '#666666',
            // interval: 2,
            interval: this.dateArr.length < 5 ? this.dateArr.length : this.dateArr.length/3,  //设置X轴数据间隔几个显示一个，为0表示都显示
            fontSize: 26,
            // rotate: 60, // 旋转角度
          },
          boundaryGap: true,
          data: this.dateArr,
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true
          },
          axisLabel: {
            formatter: '{value}',
            fontSize: 28
          },
        },
        dataZoom: [{
          type: 'inside',
          throttle: 50
        }],
        series: this.seriesArr
      })
      this.dbUnSelectExpectMyself(myChart, this.legendArr);
    },
    dbUnSelectExpectMyself(chart, legendData) {
      for (var i = 0; i < legendData.length; i++) {
        //显示当前legent 关闭非当前legent
        if (this.result.substring(0,3) !== legendData[i]) {
          chart.dispatchAction({
            type: 'legendUnSelect',
            name: legendData[i]
          });
        }            
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.scoreschart {
  background-color: #fff;
	border-radius: 30px;
  padding: 57px 46px 44px 51px;
  margin-top: 45px;
  .title {
    color: #333;
    font: {
      size: 48px;
      family: PingFangSC-Medium;
    };
    width: 340px;
    height: 50px;
    border-left: 6px solid #1288eb;
    text-align: center;
    margin-bottom: 30px;
  }

  .tip {
    margin-top: 20px;
    color: #0096ff;
    font-size: 38px;
    font-family: SourceHanSansCN-Medium;
    width: 100%;
    text-align: center;
  }
}
</style>