var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wxtzheader" }, [
    _c("div", { staticClass: "left" }, [
      _c("h2", [_vm._v(_vm._s(_vm.result))]),
      _c("span", [_vm._v("提示：" + _vm._s(_vm.attention.attention) + "。")]),
      _c("div", { staticClass: "bottom" }, [
        _c("span", [_vm._v("报告编号：" + _vm._s(_vm.displayId))]),
        _c("span", [_vm._v("检测时间：" + _vm._s(_vm.time))])
      ])
    ]),
    _c(
      "div",
      { staticClass: "right" },
      [
        _c(
          "van-circle",
          {
            attrs: {
              rate: _vm.healthScore,
              "layer-color": "#8c9fe7",
              color: _vm.gradientColor,
              "stroke-width": 110,
              size: 320
            },
            model: {
              value: _vm.currentRate,
              callback: function($$v) {
                _vm.currentRate = $$v
              },
              expression: "currentRate"
            }
          },
          [
            _c("div", { staticClass: "circle-score" }, [
              _c("div", { staticClass: "score" }, [
                _vm._v(_vm._s(_vm.healthScore))
              ]),
              _c("div", { staticClass: "result" }, [
                _vm._v(_vm._s(_vm.resultScore))
              ])
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }