<template>
  <div class="wxdoctor-list">
    <div class="title">推荐中医</div>
    <div class="listBox">
      <ul v-for="(item, index) in doctorData" :key="index" class="list-content" @click="gotoDetail(item.id)">
        <img :src="item.head_image" alt=""/>
        <div class="info">
          <div class="name">{{item.name}}<span>{{item.title}} | {{item.department}}</span></div>
          <div class="address">{{item.medical_institution}}<span></span></div>
          <div class="skills">
            <span v-for="(info, index) in item.skill" :key="index">{{info.name}}</span>
          </div>
        </div>
      </ul>
    </div>

  </div>
</template>

<script>
  import { recordClick } from '../../../api/report'

export default {
  name: 'wxdoctorlist',
  props:{
    doctorData: Array
  },
  data(){
    return {
      
    }
  },
  mounted(){
    console.log(this.doctorData)
  },
  methods: {
    gotoDetail(id){
      window.location = `https://m.zhiyuntcm.com/doctordetail/${id}`
    }
  }
}
</script>

<style lang="scss" scoped>
.wxdoctor-list {
  background-color: #fff;
	border-radius: 30px;
  padding: 57px 46px 30px 51px;
  margin-bottom: 45px;
  font-family: PingFangSC-Medium;
  .title {
    color: #333;
    font-size: 48px;
    width: 260px;
    height: 50px;
    margin-bottom: 30px;
    border-left: 6px solid #1288eb;
    padding-left: 30px;
  }
  .listBox{
    max-height: 1400px;
    overflow: auto;
  }

  .list-content {
    width: 100%;
    // background-color: #f5f5f5;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    img {
      width: 180px;
      height: 180px;
      border-radius: 50%;
      background-color: #ccc;
      margin: 39px 34px 0 0;
    }
    .info {
      margin: 39px 0 0 0;
      width: 66%;
      // background-color: rebeccapurple;
      .name {
        font-size: 42px;
        color: #333;
        max-height: 110px;
        overflow-y: hidden;
        span {
          font-size: 36px;
          color: #666;
          margin-left: 23px;
        }
      }
      .address {
        margin: 18px 0 0 0;
        font-size: 35px;
        color: #999;
        max-height: 100px;
        overflow-y: hidden;
        span {
          margin-left: 19px;
        }
      }
      .skills {
        font-size: 32px;
        color: #666;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 36px 0 20px 0;
        max-height: 220px;
        overflow: hidden;
        span {
          padding: 10px 16px;
          border-radius: 10px;
	        border: solid 1px #cccccc;
          margin: 0 20px 20px 0;
          word-break: break-all;
          background-color: #F5F5F5;
        }
      }
    }
  }
}
</style>