var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.marker === "10.1" ? "healthtip-ten-one" : "healthtip" },
    [
      _c("div", { staticClass: "tip-content" }, [
        _c("div", { staticClass: "tip-top" }, [
          _vm.reportData.result.includes("平和质")
            ? _c("img", {
                staticClass: "tip-img-health",
                attrs: {
                  src: require("../../assets/img/common/wjkfx_icon.png")
                }
              })
            : _c("img", {
                staticClass: "tip-img",
                attrs: {
                  src: require("../../assets/img/common/jbfx_jcbg_icon.png")
                }
              }),
          _c(
            "span",
            {
              class: _vm.reportData.result.includes("平和质")
                ? "tip-title tip-title-health"
                : "tip-title"
            },
            [
              _vm._v(
                _vm._s(
                  _vm.reportData.result.includes("平和质")
                    ? "无疾病风险"
                    : "未来疾病风险"
                )
              )
            ]
          )
        ]),
        _c("div", { staticClass: "body" }, [
          _c("div", { staticClass: "line" }, [
            _c(
              "div",
              { staticClass: "line-left" },
              _vm._l(_vm.leftRisk, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    class: item.content[0].includes("腹部")
                      ? "leftc fu"
                      : item.content[0].includes("头")
                      ? "leftc head"
                      : item.content[0].includes("脸")
                      ? "leftc face"
                      : item.content[0].includes("口腔")
                      ? "leftc oral"
                      : item.content[0].includes("胸")
                      ? "leftc chest"
                      : item.content[0].includes("肺")
                      ? "leftc lung"
                      : item.content[0].includes("心")
                      ? "leftc darling"
                      : item.content[0].includes("上臂")
                      ? "leftc upperarm"
                      : item.content[0].includes("肾脏")
                      ? "leftc kidney"
                      : item.content[0].includes("骨骼")
                      ? "leftc bone"
                      : item.content[0].includes("手掌")
                      ? "leftc hand"
                      : "leftc"
                  },
                  [
                    _c("span", [_vm._v(_vm._s(item.name))]),
                    _c("img", {
                      attrs: {
                        src: item.content[0].includes("口腔")
                          ? _vm.lineOral
                          : _vm.lineLeft,
                        alt: "人体线图"
                      }
                    })
                  ]
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "line-right" },
              _vm._l(_vm.rightRisk, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    class: item.content[0].includes("腹部")
                      ? "rightc fu"
                      : item.content[0].includes("头部")
                      ? "rightc head"
                      : item.content[0].includes("心脏")
                      ? "rightc heart"
                      : item.content[0].includes("鼻子")
                      ? "rightc nose"
                      : item.content[0].includes("脸")
                      ? "rightc face"
                      : item.content[0].includes("口腔")
                      ? "rightc oral"
                      : item.content[0].includes("咽")
                      ? "rightc throat"
                      : item.content[0].includes("胸")
                      ? "rightc chest"
                      : item.content[0].includes("上臂")
                      ? "rightc upperarm"
                      : item.content[0].includes("肘关节")
                      ? "rightc elbow"
                      : item.content[0].includes("全身")
                      ? "rightc wholebody"
                      : item.content[0].includes("下半部")
                      ? "rightc bottomhalf"
                      : "rightc"
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: item.content[0].includes("鼻子")
                          ? _vm.lineNose
                          : item.content[0].includes("口腔") ||
                            item.content[0].includes("咽")
                          ? _vm.lineRightOral
                          : _vm.lineRight,
                        alt: "人体线图"
                      }
                    }),
                    _c("span", [_vm._v(_vm._s(item.name))])
                  ]
                )
              }),
              0
            )
          ]),
          _c("div", { staticClass: "imgdiv" }, [
            _vm.reportData.result.includes("平和质")
              ? _c("img", {
                  staticClass: "health-body",
                  attrs: {
                    src: require("../../assets/img/physical/health_body.png"),
                    alt: "人体图"
                  }
                })
              : _c("img", {
                  staticClass: "health-ill",
                  attrs: {
                    src: require("../../assets/img/physical/ill_body.png"),
                    alt: "人体图"
                  }
                }),
            _vm.rightarr
              ? _c(
                  "div",
                  _vm._l(_vm.rightarr, function(item, index) {
                    return _c("div", {
                      key: index,
                      class: item.includes("腹部")
                        ? "abdomen-img"
                        : item.includes("头")
                        ? "head-img"
                        : item.includes("脸部")
                        ? "face-img"
                        : item.includes("口腔")
                        ? "nose-img"
                        : item.includes("胸")
                        ? "thoracic-img"
                        : item.includes("肺")
                        ? "lung-img"
                        : item.includes("心")
                        ? "heart-img"
                        : item.includes("上臂")
                        ? "uparm-img"
                        : item.includes("肾脏")
                        ? "kidney-img"
                        : item.includes("骨骼")
                        ? ""
                        : item.includes("手掌")
                        ? "lpalm-img"
                        : item.includes("鼻子")
                        ? "nose-img"
                        : item.includes("脸")
                        ? "rface-img"
                        : item.includes("咽")
                        ? "pharynx-img"
                        : item.includes("上臂")
                        ? "uparmr-img"
                        : item.includes("肘关节")
                        ? "elbow-img"
                        : item.includes("全身")
                        ? ""
                        : item.includes("下半部")
                        ? "bladder-img"
                        : ""
                    })
                  }),
                  0
                )
              : _vm._e()
          ])
        ])
      ]),
      _c(
        "div",
        {
          class: _vm.reportData.has_pulse
            ? "result-btn has_pulse"
            : "result-btn"
        },
        [
          _c("div", { staticClass: "btn", on: { click: _vm.lookFaceResult } }, [
            _vm._v("面诊" + _vm._s(_vm.isSpecialVersion ? "图片" : "结果"))
          ]),
          _c(
            "div",
            { staticClass: "btn", on: { click: _vm.lookTongueResult } },
            [_vm._v("舌诊" + _vm._s(_vm.isSpecialVersion ? "图片" : "结果"))]
          ),
          _vm.reportData.has_pulse
            ? _c(
                "div",
                { staticClass: "btn", on: { click: _vm.lookPulseResult } },
                [_vm._v("脉诊结果")]
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }