<template>
  <div class="chronicchart">
    <div class="bc"></div>
    <div v-if="beforeSymptomStatus" class="sc"></div>
    <div id="chronicchart" :style="{width: '100%', height: '600px'}"></div>
  </div>
</template>

<script>
  import * as echarts from 'echarts'

  export default {
    name: "chronicchart",
    props: {
      symptoms: Array,
      symptomStatus: Array,
      beforeSymptomStatus: Array,
      healthStatus: String,
      typeAttention: String
    },
    data(){
      return {
        indicatorArray: [],
        scoreArray: [],
        beforeIndicatorArray: [],
        beforeScoreArray:[],
        maxScoreDic: null
      }
    },
    beforeMount(){
      // chart最高分数据处理 高血压最高分是80 糖尿病最高分70
      // 之前不同症状设置了不同的最高分 目前分数比较适合统一设置  先这样设置
      if(this.typeAttention === '高血压') {
        this.maxScoreDic = {阴虚阳亢: 80, 气血两虚: 80, 痰瘀互结: 80, 肾精不足: 80, 肾阳亏虚: 80, 冲任失调: 80}
      } else {
        this.maxScoreDic = {阴虚热盛: 70, 湿热困脾: 70, 气阴两虚: 70, 阴阳两虚: 70, 血瘀脉络: 70}
      }
      const that = this;
      // 本次
      this.symptomStatus.forEach(function(element){
        const nameString = that.symptoms.length > 0 ? that.symptoms[0].name : ''
        if(element.name === nameString) {
          that.indicatorArray.push({
            name: element.name, max: that.maxScoreDic[element.name], color: '#ffa525'
          })
        } else {
          that.indicatorArray.push({
            name: element.name, max: that.maxScoreDic[element.name]
          })
        }
        // 存在负分的情况  女性报告有 慢病暂未知
        if(element.score >=0 ) {
          that.scoreArray.push(element.score)
        }else {
          that.scoreArray.push(0)
        }
      })

      //上次
      if(this.beforeSymptomStatus) {
        this.beforeSymptomStatus.forEach(function(element){
          that.beforeIndicatorArray.push({
            name: element.name, max: that.maxScoreDic[element.name]
          })
          if(element.score >=0 ) {
            that.beforeScoreArray.push(element.score)
          }else {
            that.beforeScoreArray.push(0)
          }
        })
      }else {
        this.symptomStatus.forEach(function(element){
          that.beforeScoreArray.push(0)
        })
      }
    },
    mounted(){
      this.drawChart()
    },
    methods: {
      drawChart(){
        const myChart = echarts.init(document.getElementById('chronicchart'));
        myChart.setOption({
          legend: {
            data: this.beforeSymptomStatus === null ? ['本次'] : ['本次', '上次'],
            show: false,
            left: "0",
            width: "auto",
            orient: "vertical",
            itemWidth: 50,
            itemHeight: 6,
            selectedMode: false,
            textStyle: {
              color: "#fff",
              fontSize: 24,
            }
          },
          color:this.beforeSymptomStatus === null ? ['#ffad2c'] : ['#ffad2c','#20e2ff'],
          radar: {
            center: ['50%', '50%'],
            startAngle: 90,
            radius: '80%',
            splitNumber: 4,
            indicator: this.indicatorArray,
            axisName: {
              color:'#216b98',
              formatter(value) {
                return `{a|${value}}`;
              },
              rich: {
                a: {
                  fontSize: 30,
                  lineHeight: 14.9,
                }
              },
            },
            axisLine: { //坐标轴
              lineStyle: {
                color: '#216b98',
              }
            },
            splitLine: {
              lineStyle: {
                width: 1,
                color: '#00466b'
              }
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: ['rgba(33,107,152,0.36)', 'rgba(33,107,152,0.28)', 'rgba(33,107,152,0.2)', 'rgba(33,107,152,0.1)'],
              }
            },
          },
          series: [{
            type: 'radar',
            symbol: 'circle',//拐点设置为实心
            symbolSize:15,//拐点大小
            z: 1,
            data: [
              {
                name: '本次',
                value: this.scoreArray,
                lineStyle: {
                  color: '#ffad2c',
                  width: 3,
                  type:'solid'
                },
              },
              {
                name: '上次',
                value: this.beforeScoreArray,
                lineStyle: {
                  color: '#20e2ff',
                  width: this.beforeSymptomStatus === null ? 0 : 2,
                  type:'dotted' //dotted
                },
              },
            ],
            areaStyle: {
              color: new echarts.graphic.LinearGradient(         // 设置渐变色
                0, 0, 0, 1,
                [
                  { offset: 0, color: 'rgba(33,107,152,0.1)' },
                  { offset: 1, color: 'rgba(33,107,152,0.9)' }
                ]
              )
            },
          }]
        });
      },
    }
  }
</script>

<style lang="scss" scoped>
  .chronicchart {
    position: relative;
    width: calc(94% - 40px);
    height: 628px;
    margin: 0 auto;
    background:url("../../../assets/img/chronic/mbbj_jcjg_icon.png");
    background-size:100% 100%;
    padding: 20px;
    .title {
      color: #333;
      font: {
        size: 48px;
        family: PingFangSC-Medium;
      };
      width: 100%;
      height: 50px;
      border-left: 6px solid #1288eb;
      text-align: left;
      margin-bottom: 20px;
      span {
        margin-left: 20px;
      }
      .tip {
        color: #1288eb;
      }
    }
    .subtitle {
      color: #333;
      font: {
        size: 48px;
        family: PingFangSC-Medium;
      };
      margin: 0 0 40px 30px;
      span {
        color: #1288eb;
      }
    }

    .bc{
      position:absolute;
      width:120px;
      height:23px;
      background:url("../../../assets/img/menopauseovary/bc_icon.png");
      background-size:100% 100%;
      top:50px;
      left:43px;
    }
    .sc{
      position:absolute;
      width:120px;
      height:23px;
      background:url("../../../assets/img/menopauseovary/sc_icon.png");
      background-size:100% 100%;
      top:90px;
      left:43px;
    }
  }
</style>
