<template>
  <div class="wxtzheader">
    <div class="left">
      <h2>{{result}}</h2>
      <!-- 提示：您的气虚状态较上次有所改善，请继续保持健康生活方式。建议您1个月后再做一次健康评估。 -->
      <span>提示：{{attention.attention}}。</span>
      <div class="bottom">
        <span>报告编号：{{displayId}}</span>
        <span>检测时间：{{time}}</span>
      </div>
    </div>
    <div class="right">
      <!-- 【中医体质辨识】检测结果页，“健康-亚健康-可能有疾病”。规则为“1、当结果为平和质，则指向健康；2、当某个偏颇体质最高得分大于等于35，或者偏颇体质得分大于等于20的超过2个（不包含2个），则指向可能有疾病；3、其余情况为亚健康。 -->
      <van-circle
        v-model="currentRate"
        :rate="healthScore"
        layer-color="#8c9fe7"
        :color="gradientColor"
        :stroke-width="110"
        :size="320"
      >
        <div class="circle-score">
          <div class="score">{{healthScore}}</div>
          <div class="result">{{resultScore}}</div>
        </div>
      </van-circle>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    result: String,
    attention: Object,
    displayId: String,
    time: String,
    healthScore: Number
  },
  data(){
    return {
      arr1:[],
      arr2: [],

      currentRate: 0,
      gradientColor: {
        '0%': '#ffb974',
        '25%': '#ffe57e',
        '100%': '#62fdae',
      },
    }
  },
  computed: {
    resultScore (){
      if(this.result.includes('平和质')){
        return '健康'
      } else {
        if(this.arr1.length >= 1 || this.arr2.length > 2){ // 左上角仪表盘改为“健康-亚健康-可能有疾病”。规则为“1、当结果为平和质，则指向健康；2、当某个偏颇体质最高得分大于等于35，或者偏颇体质得分大于等于20的超过2个（不包含2个），则指向可能有疾病；3、其余情况为亚健康。
          return '可能有疾病'
        } else {
          return '亚健康'
        }
      }
    },
  },
  created(){
    let symptomStatus = this.$store.state.report.symptom_status
    symptomStatus = symptomStatus.filter(item => {
      return item.name != '平和质'
    })
    this.arr1 = symptomStatus.filter(item => {
      return item.score >= 35
    })
    this.arr2 = symptomStatus.filter(item => {
      return item.score >= 20
    })
  }
}
</script>

<style lang="scss" scoped>
$headerbg: '../../../assets/img/wx/wx_bj_tzjg.png';

.wxtzheader {
  width: 100%;
  min-height: 540px;
  background:url($headerbg);
  background-size:cover;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  .left {
    color: #fff;
    margin: 30px 0 0 45px;
    width: 56%;
    display: flex;
    flex-direction: column;
    position: relative;
    h2 {
      font: {
        size: 72px;
        family: PingFangSC-Medium;
      };
      line-height: 120px;
    }
    span {
      display: block;
      font-size: 36px;
      font-family: SourceHanSansCN-Regular;
      line-height: 54px;
    }
    .bottom {
      position: absolute;
      bottom: 76px;
    }
  }
  .right {
    width: 40%;
    display: flex;
    align-items: center;
    margin-left: 80px;
    margin-top: 20px;
    // background-color: yellow;
    .circle-score {
      height: 80%;
      color: #fff;
      // background-color: yellow;
      .score {
        margin-top: 76px;
        font-size: 93px;
      }
      .score::after {
        content: '分';
        font-size: 36px;
      }
      .result {
        font-size: 36px;
      }
    }
  }
}

</style>