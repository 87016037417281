var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wxfaceresult" }, [
    _c("div", { staticClass: "top" }, [
      _c("div", { staticClass: "title" }, [_vm._v("面诊结果")]),
      _c(
        "div",
        {
          staticClass: "right",
          on: {
            click: function($event) {
              return _vm.lookPhoto(_vm.facePhotoUrl)
            }
          }
        },
        [
          _c("img", {
            attrs: { src: require("../../../assets/img/wx/wx_cktp_jcjg.png") }
          }),
          _c("span", [_vm._v("查看照片")])
        ]
      )
    ]),
    _c(
      "div",
      { staticClass: "result-btn" },
      _vm._l(_vm.btnArr, function(item, index) {
        return _c(
          "div",
          {
            key: index,
            class: item.id === _vm.btnId ? "btn btn-selected" : "btn",
            on: {
              click: function($event) {
                return _vm.btnEvent(item.id)
              }
            }
          },
          [_c("span", [_vm._v(_vm._s(item.name))])]
        )
      }),
      0
    ),
    _c("div", { staticClass: "detailBox" }, [
      _c("div", { staticClass: "faceName" }, [
        _c("p", [
          _vm._v(
            _vm._s(
              _vm.btnId === 0
                ? _vm.faceData.face_color_info.symptom
                : _vm.btnId === 1
                ? _vm.faceData.lip_color_info.symptom
                : _vm.faceData.face_gloss_info.symptom
            )
          )
        ]),
        _c("p", { staticClass: "line" }, [_vm._v("|")]),
        _c("span", [_vm._v("参考值: 正常")])
      ]),
      _c("div", [
        _c("p", { staticClass: "symptom" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.btnId === 0
                  ? _vm.faceData.face_color_info.name
                  : _vm.btnId === 1
                  ? _vm.faceData.lip_color_info.name
                  : _vm.faceData.face_gloss_info.name
              )
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }