var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wxtzparsing" }, [
    _c("div", { staticClass: "title" }, [_vm._v("体质解析")]),
    _vm.parsingDate.content.length === 1
      ? _c("div", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.parsingDate.content[0]))
        ])
      : _vm._e(),
    _vm.parsingDate.content.length >= 2
      ? _c("div", { staticClass: "content" }, [
          _vm._v(
            _vm._s(_vm.parsingDate.content[0]) +
              _vm._s(_vm.parsingDate.content[1]) +
              "。"
          )
        ])
      : _vm._e(),
    _vm.parsingDate.content.length >= 3
      ? _c("div", { staticClass: "content" }, [
          _vm._v(
            _vm._s(_vm.parsingDate.content[2]) +
              _vm._s(_vm.parsingDate.content[3]) +
              "。"
          )
        ])
      : _vm._e(),
    _vm.parsingDate.content.length >= 4
      ? _c("div", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.parsingDate.content[4]))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }