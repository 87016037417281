<template>
  <div class="visceraChart">
    <div v-if="marker == 10.1" class="chart-tip">
      <div class="tip-content"><div class="line"></div><span>平和质</span></div>
      <div v-if="physical.substring(0,3) != '平和质'" class="tip-content"><div class="line line-two"></div><span>{{physical}}</span></div>
    </div>
    <div style="width: 100%;display: flex;justify-content: space-between;font-size: 30px;">
      <p>当前节气：{{physicalTerm.term}}</p>
      <p class="illness">患病概率：
        <span :style="{color: textColor}">
          {{physicalTerm.illness_probability>30?'较高':physicalTerm.illness_probability<10?'低':'中'}}
        </span>
        <img v-if="physicalTerm.illness_probability>30" src="../../assets/img/wx/wx_row_up.png"/>
        <img v-if="physicalTerm.illness_probability<10" src="../../assets/img/wx/wx_row_down.png"/>
      </p>
    </div><br/>

    <div v-if="marker == 10.1" id="viscera" :style="{width: '850px', height: '320px', marginTop:'-40px'}"></div>
    <div v-else id="viscera" :style="{width: '850px', height: '430px'}"></div>
    <div class="text">提示：{{physicalTerm.term_attention}}</div>
    <div class="text">{{physicalTerm.term_add_attention}}</div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { queryObject } from '../../../utils/common.js';
const { marker = '' } = queryObject()

export default {
  name: "termschart",
  props: {
    physicalTerm: Object,
    physical: String,
  },
  data(){
    return {
      seriesArr: [],
      pointArr: [],
      healthyPhysical: '平和质',
      showLegend: true,
    }
  },
  computed: {
    marker(){
      return marker
    },
    textColor(){
      const probability = this.physicalTerm.illness_probability;
      return probability>30? '#fb8a1c' : probability < 10? '#22c46a': '#ffffff';
    }
  },
  mounted(){
    if(this.marker == 10.1) this.showLegend = false
    else this.showLegend = true
    this.fixData()
  },
  methods: {
    fixData(){
      const physicalString = this.physical.substring(0,3)
      this.seriesArr = this.utils.seriesData(physicalString)
      this.pointData()
    },
    pointData(){
      const seasonArr = ['立春', '雨水', '惊蛰', '春分', '清明', '谷雨', '立夏', '小满', '芒种', '夏至', '小暑', '大暑', '立秋', '处暑', '白露', '秋分', '寒露', '霜降', '立冬', '小雪', '大雪', '冬至', '小寒', '大寒']
      const index = seasonArr.findIndex(item =>  item === this.physicalTerm.term);
      let commonDic = { name: '', value: `${this.physicalTerm.illness_probability}%`, xAxis: index, yAxis:this.physicalTerm.illness_probability}
      this.pointArr.push(commonDic)
      // 绘制
      this.drawChart()
    },
    drawChart(){
      const myChart = echarts.init(document.getElementById('viscera'));
      console.log(this.physical,this.healthyPhysical)
      myChart.setOption({
        legend: {
          data: this.physical.substring(0,3) == '平和质' ? [this.physical] : [this.physical, this.healthyPhysical],
          show: this.showLegend,
          right: "1%",
          width: "auto",
          orient: 'horizontal',
          itemWidth: 40,
          itemHeight: 4,
          textStyle: {
            color: "#fff",
            fontSize: 24,
          }
        },
        color:this.physical.substring(0,3) == '平和质' ? ['#069eff'] : ['#069eff','#ffad2c'],
        grid: { //图表距边框的距离,可选值：'百分比'¦ {number}（单位px）
          top: '11%',   // 等价于 y: '16%'
          left: '2%',
          right: '2%',
          bottom: '1%',
          containLabel: true
        },
        tooltip: { // 提示框
          trigger: 'axis'
        },
        // toolbox: { //工具框，可以选择
        //   feature: {
        //     saveAsImage: {} //下载工具
        //   }
        // },
        xAxis: {
          type: 'category',
          axisLine: {
            lineStyle: { // 设置x轴颜色
              color: '#00fefb',
            }
          },
          // 设置X轴数据旋转倾斜
          axisLabel: {
            color: '#fff',
            rotate: 40, // 旋转角度
            interval: 0,  //设置X轴数据间隔几个显示一个，为0表示都显示
            fontSize: 16
          },
          boundaryGap: false, // boundaryGap值为false的时候，折线第一个点在y轴上
          data: ['立春', '雨水', '惊蛰', '春分', '清明', '谷雨', '立夏', '小满', '芒种', '夏至', '小暑', '大暑', '立秋', '处暑', '白露', '秋分', '寒露', '霜降', '立冬', '小雪', '大雪', '冬至', '小寒', '大寒']
        },
        yAxis: {
          name: '患病概率',
          nameTextStyle: {
            fontSize: 24
          },
          type: 'value',
          axisLabel: {
            formatter: '{value}%',
            fontSize: 16
          },
          min:0, // 设置y轴刻度的最小值
          max:50,  // 设置y轴刻度的最大值
          splitLine:{
            lineStyle:{
              color:' #004537'
            }
            // show: false, //去除网格线
          },
          splitNumber: 10,  // 设置y轴刻度间隔个数
          axisLine: {
            lineStyle: { // 设置y轴颜色
              color: '#fff'
            }
          },
        },
        series: [
          {
            name: this.physical.substring(0,3) == '平和质' ? null : this.physical,
            data: this.physical.substring(0,3) == '平和质' ? null : this.seriesArr,
            type: 'line',
            markPoint: {
                data: this.pointArr
            },
            // 注意：设置symbol: 'none'以后，拐点不存在了，设置拐点上显示数值无效
            symbol: 'none',
            smooth: 0.5, // 设置折线弧度，取值：0-1之间
            // 设置折线上圆点大小
            symbolSize: 0,
            // 设置拐点为实心圆
            // symbol:'circle',
            label : {
              show: false
            },
          },
          {
            name: this.healthyPhysical,
            data: [10,8,6,5,6,8,10,8,5,8,10,8,5,8,10,8,5,8,10,9,8,7,6,5],
            type: 'line',
            symbol: 'none',
            smooth: 0.5,
            lineStyle:{
              width: 2,  // 设置线宽
              type:'dashed'  //'dotted'虚线 'solid'实线
            },
            label : { // 拐点上显示数值
              show: false
            },
            // symbolSize:8, // 设置折线上圆点大小
          }
        ],
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.visceraChart {
  text-align: center;
  margin-top: 4px;
  .illness {
    img {
      width: 15px;
      height: 30px;
    }
  }
  .chart-tip {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 40px;
    margin: 0 15px;
    .tip-content {
      display: flex;
      align-items: center;
      font-size: 16px;
      .line {
        height: 4px;
        width: 40px;
        border-top: #069eff 4px dotted;
        margin-right: 10px;
      }
      .line-two {
        border-top: #ffad2c 4px solid;
        margin-left: 40px;
      }
    }
  }
  .text {
    font-size: 30px;
    color: #00fefb;
    margin-top: 10px;
    text-align: center;
  }
}
</style>
