<template>
  <div class="knowledge">
    <div class="header">
      <div class="back-btn" @click="goBack">返&nbsp;回</div>
      <div class="header-title">知识库依据</div>
    </div>
    <!--  -->
    <div v-if="knowledgedata" class="container">
      <div v-for="(item , index) in knowledgedata.content" :key="index" class="text">{{item}}</div>
      {{knowledgedata.bottom}}
    </div>
  </div>
</template>

<script>
import { getKnowledge } from '@/api/report.js';

export default {
  data(){
    return {
      knowledgedata: null
    }
  },
  beforeMount(){
    this.getKnowledgeData()
  },
  methods: {
    goBack(){
      this.$router.back()
    },
    getKnowledgeData(){
      getKnowledge().then(res =>{
        if(res && res.status_code === 10000 && res.data) {
          this.knowledgedata = res.data;
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$bgImg: '../../assets/img/common/bj.png';
$backBtn: '../../assets/img/physical/fh_ty_icon.png';
$headerTitle: '../../assets/img/report/btbj_icon.png';
$jssmbj: '../../assets/img/physical/kuang_lab_icon.png';
.knowledge {
  width:100%;
  min-height: 100%;
  background:url($bgImg);
  background-size:cover;
  overflow: hidden;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    margin: 90px 0 0 5%;
    .back-btn {
      background:url($backBtn) no-repeat;
      background-size:100% 100%;
      width: 152px;
      height: 82px;
      color:#fff;
      font-size:36px;
      text-align:center;
      line-height:75px;
    }
    .header-title {
      font-size: 48px;
      color:#00ffa2;
      font-family:TITLE;
      width:366px;
      height:111px;
      margin-left: 16%;
      background:url($headerTitle) no-repeat;
      text-align:center;
      line-height:111px;
      background-size:100% 100%;
    }
  }

  .container {
    width: 80%;
    margin-left: 5%;
    height: 720px;
    background:url($jssmbj);
    background-size:100% 100%;
    margin-top: 160px;
    color:#ffffff;
    font-size:30px;
    line-height: 66px;
    padding: 140px 5% 0 5%;
  }
}
</style>