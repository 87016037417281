<template>
  <div class="wxtzparsing">
    <div class="title">体质解析</div>
    <div class="content" v-if="parsingDate.content.length === 1">{{parsingDate.content[0]}}</div>
    <div class="content" v-if="parsingDate.content.length >= 2">{{parsingDate.content[0]}}{{parsingDate.content[1]}}。</div>
    <div class="content" v-if="parsingDate.content.length >= 3">{{parsingDate.content[2]}}{{parsingDate.content[3]}}。</div>
    <div class="content" v-if="parsingDate.content.length >= 4">{{parsingDate.content[4]}}</div>
  </div>
</template>

<script>
export default {
  props: {
    parsing: Object,
    result: String
  },
  data(){
    return {
      parsingDate: null
    }
  },
  beforeMount(){
    const tipString = this.result.substring(0,3)
    this.parsingDate = this.parsing[tipString]
  }
}
</script>

<style lang="scss" scoped>
.wxtzparsing {
  background-color: #fff;
	border-radius: 30px;
  padding: 60px 51px;
  margin-top: 45px;
  .title {
    color: #333;
    font: {
      size: 48px;
      family: PingFangSC-Medium;
    };
    width: 260px;
    height: 50px;
    border-left: 6px solid #1288eb;
    text-align: center;
    margin-bottom: 30px;
  }
  .content {
    font: {
      size: 39px;
      family: PingFangSC-Regular;
    };
    color: #666666;
    line-height: 60px;
    text-indent: 80px;
  }
}
</style>