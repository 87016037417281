var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c("h4", [
        _vm._v(
          "(" + _vm._s(_vm.isChronic ? _vm.num(0) : _vm.num(2)) + ")面诊分析"
        )
      ]),
      _c("br"),
      _c("div", { staticClass: "faceTable" }, [
        _c("table", [
          _c("tr", [
            _c("td", { staticClass: "faceImg", attrs: { rowspan: "4" } }, [
              _c("img", {
                attrs: { src: _vm.report.face_photo_url, alt: "面诊图片" }
              })
            ]),
            _vm._m(0),
            _vm._m(1),
            _vm._m(2)
          ]),
          _c("tr", [
            _c("td", { staticClass: "faceTitle" }, [_vm._v("面色")]),
            _c("td", { staticClass: "faceTitle" }, [
              _vm._v(_vm._s(_vm.report.face_result["face_color_info"].symptom))
            ]),
            _c("td", { staticClass: "faceTitle" }, [_vm._v("正常")])
          ]),
          _c("tr", [
            _c("td", { staticClass: "faceTitle" }, [_vm._v("唇色")]),
            _c("td", { staticClass: "faceTitle" }, [
              _vm._v(_vm._s(_vm.report.face_result["lip_color_info"].symptom))
            ]),
            _c("td", { staticClass: "faceTitle" }, [_vm._v("唇红")])
          ]),
          _c("tr", [
            _c("td", { staticClass: "faceTitle" }, [_vm._v("面部光泽")]),
            _c("td", { staticClass: "faceTitle" }, [
              _vm._v(_vm._s(_vm.report.face_result["face_gloss_info"].symptom))
            ]),
            _c("td", { staticClass: "faceTitle" }, [_vm._v("有光泽")])
          ])
        ])
      ])
    ]),
    _c("h4", [
      _vm._v(
        "(" + _vm._s(_vm.isChronic ? _vm.num(1) : _vm.num(3)) + ")舌诊分析"
      )
    ]),
    _c("br"),
    _c("div", { staticClass: "faceTable" }, [
      _c("table", [
        _c("tr", [
          _c("td", { staticClass: "tongueImg", attrs: { rowspan: "5" } }, [
            _c("img", {
              attrs: { src: _vm.report.tongue_photo_url, alt: "舌诊图片" }
            })
          ]),
          _vm._m(3),
          _vm._m(4),
          _vm._m(5)
        ]),
        _c("tr", [
          _c("td", { staticClass: "tongueTitle" }, [_vm._v("舌色")]),
          _c("td", { staticClass: "tongueTitle" }, [
            _vm._v(
              _vm._s(
                _vm.report.tongue_result["tongue_nature_color_info"].symptom
              )
            )
          ]),
          _c("td", { staticClass: "tongueTitle" }, [_vm._v("舌淡红")])
        ]),
        _c("tr", [
          _c("td", { staticClass: "tongueTitle" }, [_vm._v("舌形")]),
          _c("td", { staticClass: "tongueTitle" }, [
            _vm._v(
              _vm._s(
                _vm.report.tongue_result.tongueFatThin === 0
                  ? _vm.report.tongue_result.tongueCrack === 0
                    ? "正常"
                    : "有裂痕"
                  : _vm.report.tongue_result.tongueCrack === 0
                  ? "舌胖"
                  : "舌胖、有裂痕"
              )
            )
          ]),
          _c("td", { staticClass: "tongueTitle" }, [_vm._v("正常，无裂纹")])
        ]),
        _c("tr", [
          _c("td", { staticClass: "tongueTitle" }, [_vm._v("苔色")]),
          _c("td", { staticClass: "tongueTitle" }, [
            _vm._v(
              _vm._s(
                _vm.report.tongue_result["tongue_coat_color_info"]
                  ? _vm.report.tongue_result["tongue_coat_color_info"].symptom
                  : "未识别到"
              )
            )
          ]),
          _c("td", { staticClass: "tongueTitle" }, [_vm._v("苔白")])
        ]),
        _c("tr", [
          _c("td", { staticClass: "tongueTitle" }, [_vm._v("苔质")]),
          _c("td", { staticClass: "tongueTitle" }, [
            _vm._v(
              _vm._s(
                _vm.report.tongue_result.tongue_coat_thickness_info.symptom
              )
            )
          ]),
          _c("td", { staticClass: "tongueTitle" }, [_vm._v("薄苔")])
        ])
      ])
    ]),
    _vm.isChronic && _vm.pulseResult && _vm.pulseResult.length > 0
      ? _c("div", { staticClass: "pulseResult" }, [
          _c("h4", [_vm._v("(" + _vm._s(_vm.num(2)) + ")脉诊分析")]),
          _c("br"),
          _c("img", {
            attrs: { src: _vm.pulseResult[0].pulse_image, alt: "脉诊图片" }
          }),
          _c("p", [
            _vm._v(
              "总脉： " + _vm._s(_vm.pulseResult[0].summary.replace(/,/g, "、"))
            )
          ]),
          _c("p", [
            _vm._v("提示：" + _vm._s(_vm.pulseResult[0].summary_attention))
          ]),
          _vm.pulseResult[0].blood_dia || _vm.pulseResult[0].blood_sys
            ? _c("div", [
                _c("h4", { style: { marginTop: "20px" } }, [_vm._v("血压：")]),
                _c("table", [
                  _c("tr", [
                    _c("td", [_vm._v("舒张压")]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.pulseResult[0].blood_dia) + "mmHg")
                    ]),
                    _c("td", [_vm._v("收缩压")]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.pulseResult[0].blood_sys) + "mmHg")
                    ]),
                    _c("td", [_vm._v("心率")]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.pulseResult[0].heart_rate) + "次/分")
                    ])
                  ])
                ]),
                _c("p", [
                  _vm._v("提示：" + _vm._s(_vm.pulseResult[0].blood_attention))
                ])
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "faceTitle" }, [_c("b", [_vm._v("类型")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "faceTitle" }, [_c("b", [_vm._v("结果")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "faceTitle" }, [
      _c("b", [_vm._v("参考指标")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "faceTitle" }, [_c("b", [_vm._v("类型")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "faceTitle" }, [_c("b", [_vm._v("结果")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "faceTitle" }, [
      _c("b", [_vm._v("参考指标")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }