<template>
  <div class="personal">
    <div class="personal-content">
      <span class="lable">性别：{{ gender }}</span>
      <span class="lable">BMI指数:{{ bmi }}</span>
      <span class="lable">手机尾号:{{ phone }}</span>
    </div>
  </div>
</template>

<script>
  import {calculateBMI} from '../../../../utils/common';
  export default {
    name: "personal",
    props: {
      userInfo: Object,
      cellphone: Object
    },
    computed: {
      gender(){ // 性别
        if (this.userInfo.gender === 1) {
          return '男'
        } else if (this.userInfo.gender === 2) {
          return '女'
        }
      },
      bmi(){
        const {bmi, text} = calculateBMI(this.userInfo.height, this.userInfo.weight);
        if(bmi) {
          return `${bmi} (${text})`;
        } else {
          return `0(${text})`
        }

      },
      phone(){
        return this.cellphone.cellphone? this.cellphone.cellphone.substr(-4) : '无';
      }
    }
  }
</script>

<style scoped>
  .personal{
    padding: 0px 40px;
    height: 81px;
  }
  .personal-content{
    height: 81px;
    background:url("../../../assets/img/report/xxbj_jcjg_icon.png") no-repeat;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }
  .lable{
    font-size: 36px;
    color: #ffffff;
    font-family: SourceHanSansCN-Regular;
    line-height: 81px;
  }

</style>
