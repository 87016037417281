<template>
  <div class="headerTabThree" v-if="xw">
    <div class="title">{{xw.name}}</div>
    <div class="voidesty">
      <video-player
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
      >
      </video-player>
    </div>
    <span class="content-text">【位置】：</span>
    <span class="content-text">{{xw.content[0]}}</span>
    <span class="content-text">【操作】：</span>
    <span class="content-text">{{xw.content[1]}}</span>
    <span class="content-text">【功效】：</span>
    <span class="content-text">{{xw.content[2]}}</span>
    <span class="content-text">【主治】：</span>
    <span class="content-text">{{xw.content[3]}}</span>
  </div>
</template>

<script>
  import 'video.js/dist/video-js.css';
  import 'vue-video-player/src/custom-theme.css';
  import 'videojs-contrib-hls';

  export default {
    data(){
      return {
        xw: null,
        sourcessrc:'',
        poster:'',
        xwVoide: null,
        xwVoideImg:null,
        playerOptions:{
          playbackRates: [0.5, 1.0, 1.5, 2.0],
          autoplay: false,
          muted: false,
          loop: false,
          preload: 'auto',
          language: 'zh-CN',
          aspectRatio: '16:9',
          fluid: true,
          sources: [{
            type: "video/mp4",
            src: '',
          }],
          poster: '',
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true
          }
        }
      }
    },
    mounted(){
      const symptoms = this.$store.state.report.symptoms
      const solutions = this.$store.state.report.solutions
      const symptomsName = symptoms[0].name;
      this.xw = solutions.tizhi_massage[symptomsName]
      this.xwVoide = `${solutions.asset_prefix}${this.xw.asset[1]}`
      this.xwVoideImg = `${solutions.asset_prefix}${this.xw.asset[0]}`
      this.playerOptions.sources[0].src = this.xwVoide;
      this.playerOptions.poster = this.xwVoideImg;
    },
  }
</script>

<style lang="scss" scoped>
  $bk: '../../assets/img/physical/kuang_lab_icon.png';
  $commonW: 90%;
  $commonL: 5%;
  .headerTabThree {
    font-size: 36px;
    color:#F1F1F1;
    width: $commonW;
    margin: 30px 0 0 $commonL;
    height: 1400px;
    background:url($bk) no-repeat;
    line-height:60px;
    background-size:100% 100%;
    .title {
      font-size: 42px;
      height: 36px;
      color:#ffffff;
      padding: 80px 0 50px 5%;
    }
    .voidesty{
      display: inline-block;
      width: 90%;
      line-height: 100px;
      border: 1px solid transparent;
      border-radius: 4px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
      margin-left: 5%;
    }
    .content-text {
      display:block;
      margin-left: 5%;
      margin-right: 5%;
      font-size:30px;
      color:#ffffff;
      line-height:45px;
    }
  }
</style>
