<template>
  <div class="print_report" v-if="report&&!isTzbsReport">
    <Personal :reportId="report.display_id"
              :time="isTzbsReport ? report.time : report.display_time"
              :userInfo="report.user_info"
              :cellphone="report.owner || report.agency_info"
    ></Personal>
    <Agreement :url="report.sign_name_url"></Agreement>
    <Score :isTzbsReport="isTzbsReport"
           :score="isTzbsReport? 0 : report.health_score"
           :tzbsResult="isTzbsReport ? report.result.substring(0,3) : ''"
           :symptoms="report.symptoms" :solutions="report.solutions"
    ></Score>
    <Risk v-if="!isTzbsReport" :symptoms="report.symptoms" :owner="report.owner"></Risk>
    <Analysis :result="result"></Analysis>
    <Method :isTzbsReport="isTzbsReport" :symptoms="report.symptoms" :solutions="report.solutions"></Method>
    <Product v-if="report.product && report.product.length > 0" :product="report.product"></Product>
    <Knowledge v-if="!isTzbsReport"></Knowledge>
    <Company v-if="csr !== 'SHBAZE'"></Company>
  </div>
  <div class="print_reporttzbs" v-else >
    <div v-if="report">
      <Personaltzbs
        :reportId="report.display_id"
        :time="isTzbsReport ? report.time : report.display_time"
        :owner="report.owner"
        :user_info="report.user_info"
        :cellphone="report.owner || report.agency_info"
      ></Personaltzbs>
      <Constitutiontzbs
        :report="report"
      ></Constitutiontzbs>
      <Solartzbs
        :report="report"
      ></Solartzbs>
      <Analysistzbs
        :report="report"
      ></Analysistzbs>
      <Pulsetzbs
        v-if="report.pulse.length !== 0"
        :pulse="report.pulse"
      ></Pulsetzbs>
<!--      <div style="page-break-after:always;"></div>-->
      <Methodtzbs
        :symptoms="report.symptoms"
        :solutions="report.solutions"
      ></Methodtzbs><br/><br/><br/><br/><br/>
      <Footertzbs></Footertzbs>
    </div>

  </div>
</template>

<script>
  import { getReport } from '@/api/report.js';
  import Personal from './components/personal.vue';
  import Agreement from './components/agreement.vue';
  import Score from './components/score.vue';
  import Risk from './components/risk.vue';
  import Analysis from './components/analysis.vue'
  import Method from './components/method.vue'
  import Product from './components/product.vue'
  import Knowledge from './components/knowledge.vue'
  import Company from './components/company.vue'
  import Personaltzbs from './components/personaltzbs.vue';
  import Constitutiontzbs from './components/constitutiontzbs.vue';
  import Solartzbs from './components/solartzbs.vue';
  import Analysistzbs from './components/analysistzbs.vue';
  import Pulsetzbs from './components/pulsetzbs.vue';
  import Methodtzbs from './components/methodtzbs.vue';
  import Footertzbs from './components/footertzbs.vue';

  import { queryObject } from '../../../utils/common.js';
  const { csr = '' } = queryObject();

  export default {
    name: "index",
    data () {
      return {
        report: null,
        isTzbsReport: queryObject().category === '2'||  sessionStorage.getItem('routerParams') === 'isTzbsReport' , // 是否是体质辨识报告
        csr,
      }
    },
    created() {
      // 请求报告数据
      this.getData();
      // console.log(sessionStorage.getItem('routerParams'))
    },
    methods: {
      getData(){
        getReport({
          id: this.$route.params.id
        }).then(res => {
          if(res && res.status_code === 10000 && res.data) {
            // console.log(res.data)
            this.report = res.data;
            // 将其更新到store里面对应的report字段
            this.$store.commit('SET_REPORT', res.data)
          }
        })
      }
    },
    computed:{
      result(){
        const result = {};
        result.faceResult = this.report.face_result; // 面诊结果
        result.faceUrl = this.report.face_photo_url; // 面诊图片
        result.tongueResult = this.report.tongue_result; // 舌诊结果
        result.tongueUrl = this.report.tongue_photo_url; // 舌诊图片
        result.handResult = this.report.hand_result; // 手诊结果
        result.handUrl = this.report.hand_photo_url; // 手诊图片
        return result
      }
    },
    watch:{
      '$route': 'getData' // 路由一旦切换，就去重新请求一下对应报告的数据
    },
    components: {
      Personal,
      Agreement,
      Score,
      Risk,
      Analysis,
      Method,
      Product,
      Knowledge,
      Company,
      Personaltzbs,
      Constitutiontzbs,
      Solartzbs,
      Analysistzbs,
      Pulsetzbs,
      Methodtzbs,
      Footertzbs
    }
  }
</script>

<style scoped>
  .print_report {
    width: 82%;
    margin: 0 auto;
  }
  .print_reporttzbs {
    width: 100%;
    margin: 0 auto;
  }
  /*@media print {*/
  /*  @page {*/
  /*    !* 纵向 *!*/
  /*    !* 边距 上右下左 *!*/
  /*    margin: 1cm 2cm 1cm 2cm;*/
  /*  }*/

  /*  .print_reporttzbs {*/
  /*    width: 100%;*/
  /*    margin: 0 auto;*/
  /*    text-align: left;*/
  /*  }*/
  /*}*/
</style>
