<template>
  <div class="tonguereport">
    <div class="tongue-top">
      <span class="title">舌象结果</span>
      <span class="title-border" />
    </div>
    <div class="result">
      <div class="imgsty">
        <img src="../../assets/img/menopauseovary/sxjg.gif" style="height: 375px;margin-top: 20px;" alt="面诊图片">
      </div>
      <div class="tongue-ss">
        <div style="margin-top: 8px">
          <span class="title-tip">{{tonguereport.tongue_nature_color_info.symptom}}</span>
        </div>
        <div style="margin-left: 10%">
          <div style="margin-top: 5px;text-align: left">
            <span class="span-tip">提示：{{tonguereport.tongue_nature_color_info.name}}</span><br />
            <span class="span-tip">可能原因：{{tonguereport.tongue_nature_color_info.content[0]}}</span>
          </div>
        </div>
      </div>
      <div class="tongue-sx">
        <div style="margin-top: 0;text-align: center;font-size: 36px;">
          <span class="title-tip">{{tonguereport.tongueFatThin === 0?tonguereport.tongueCrack === 0? '正常' : '有裂痕':tonguereport.tongueCrack === 0?'舌胖' : '舌胖、有裂痕' }}</span>
        </div>
        <div style="width: 85%;margin-left: 15%">
          <div style="margin-top: 0;text-align: left">
            <span class="span-tip" style="vertical-align: top;margin-left:0">提示：</span><span class="span-tip" style="margin-left: 0;width: 600px;">{{tonguereport.tongue_fat_thin_info.name}}，{{tonguereport.tongue_crack_info.name}}</span><br />
            <span class="span-tip" style="margin-left:0">可能原因：{{tonguereport.tongueFatThin === 1 ? tonguereport.tongue_fat_thin_info.content[0] : ''}}{{tonguereport.tongue_crack_info.content[0]}}</span>
          </div>
        </div>
      </div>
      <div class="tongue-ts">
        <div style="margin-top: 0;text-align: center;font-size: 36px;">
          <span class="title-tip">{{tonguereport.tongue_coat_color_info.symptom}}</span>
        </div>
        <div style="margin-left: 10%">
          <div style="margin-top: 5px;text-align: left">
            <span class="span-tip">提示：{{tonguereport.tongue_coat_color_info.name}}</span><br />
            <span class="span-tip">可能原因：{{tonguereport.tongue_coat_color_info.content[0]}}</span>
          </div>
        </div>
      </div>
      <div class="tongue-tz">
        <div style="margin-top: 0;text-align: center;font-size: 36px;">
          <span class="title-tip">{{tonguereport.tongue_coat_thickness_info.symptom}}</span>
        </div>
        <div style="margin-left: 10%">
          <div style="margin-top: 19px;text-align: left">
            <span class="span-tip">结果：{{tonguereport.tongue_coat_thickness_info.name}}</span><br />
            <span class="span-tip">提示：{{tonguereport.tongue_coat_thickness_info.content[0]}}</span>
          </div>
        </div>
      </div>
      <div class="back" @click="returnto"></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "diagnosis",
    data(){
      return {
        tonguereport: null,
        tongue_photo_url:'',
        report:'',
        id:'',
        facestate:0,
      }
    },
    methods:{
      returnto(){
        this.$router.push({path:'/menopauseovary/'+this.id});//返回上一层
      }
    },
    beforeMount(){
      this.tonguereport = this.$route.params.tonguereport
      this.id = this.$route.params.id
    }
  }
</script>

<style scoped>
  .tonguereport{
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    overflow:hidden;
    background:url("../../assets/img/report/bj_sznr_icon.png");
  }
  .tongue-top{
    width: 80%;
    display: block;
    height: 110px;
    margin: 10px 0 0 0;
  }
  .title{
    font-size: 60px;
    color:#00fefb;
    font-family:TITLE;
    margin-left:60px;
    line-height: 90px;
  }
  .title-border{
    position:absolute;
    left:0;
    top:90px;
    display:block;
    width:1000px;
    height:16px;
    background:url("../../assets/img/report/xian_jcjg.png") no-repeat;
  }
  .result{
    height:1530px;
    width: 100%;
    color:#FFFFFF;
    font-size:24px;
    background:url("../../assets/img/menopauseovary/sxjgk_icon.png") no-repeat;
    background-position-x:60px;
    display:inline-block;
    text-align:center;
    vertical-align:top;
    padding-top:77px;
  }
  .imgsty{
    height:415px;
    width: 790px;
    margin: auto;
    left:0;
    right:0;
    /*border-bottom:2px solid #00ECFC;*/
  }
  .tongue-ss{
    height:195px;
    width: 790px;
    margin: auto;
    left:0;
    right:0;
    /*border-bottom:2px solid #00ECFC;*/
  }
  .circular1{
    display:inline-block;
    width:33%;
    background:url("../../assets/img/common/Lz_mzjg_icon.png") no-repeat;
    background-position-x:center;
    height:100%;
  }
  .circular2{
    display:inline-block;
    width:33%;
    background:url("../../assets/img/common/Az_mzjg_icon.png") no-repeat;
    background-position-x:center;
    height:100%;
  }
  .circular3{
    display:inline-block;
    width:33%;
    background:url("../../assets/img/common/Bz_mzjg_icon.png") no-repeat;
    background-position-x:center;
    height:100%;
  }
  .spansty{
    width:33%;
    display:inline-block;
    text-align:center;
    color:white;
    font-size:20px;
  }
  .spaninfo{
    text-align:center;
    color:#4BB9FF;
    font-size:20px;
  }
  .span-tip{
    color:#FFFFFF;
    font-size:28px;
    line-height:50px;
    margin-left:30px;
  }
  .tongue-sx{
    height:270px;
    width: 790px;
    margin: auto;
    left:0;
    right:0;
    /*border-bottom:2px solid #00ECFC;*/
  }
  .tongue-ts{
    height:230px;
    width: 790px;
    margin: auto;
    left:0;
    right:0px;
    /*border-bottom:2px solid #00ECFC;*/
  }
  .tongue-tz{
    height:227px;
    width: 790px;
    margin: auto;
    left:0;
    right:0px;
  }
  .back{
    height:67px;
    width: 224px;
    background:url("../../assets/img/report/fh_srsj_Icon.png") no-repeat;
    margin: auto;
    left:0;
    right:0;
    margin-top:20px;
  }
  .resultL{
    height:1230px;
    width: 100%;
    color:#FFFFFF;
    font-size:24px;
    background:url("../../assets/img/report/bj_mzjg_icon10.png") no-repeat;
    background-position-x:60px;
    display:inline-block;
    text-align:center;
    vertical-align:top;
    padding-top:77px;
    margin-top: 20px;
  }
  .result-content{
    overflow:hidden;
    height:638px;
    width: 740px;
    margin: 0 auto;
    left:0;
    right:0px;
  }
  .imgstyL{
    position:relative;
    height:355px;
    width: 740px;
    background:url("../../assets/img/report/s.gif") no-repeat;
    background-size:100% 100%;
    margin: auto;
    left:0;
    right:0px;
    top:-36px;
    padding-top: 312px;
  }
  .leftsty{
    position:absolute;
    left:318px;
    top:662px;
    display:block;
    width:85px;
    height:85px;
    font-size:18px;
    color:#3280bb;
    text-align:center;
    font-family:SourceHanSansCN-Medium;
    padding-top:30px;
    z-index:9;
  }
  .leftsty2{
    position:absolute;
    left:547px;
    top:645px;
    display:block;
    width:85px;
    height:85px;
    font-size:18px;
    color:#3280bb;
    text-align:center;
    font-family:SourceHanSansCN-Medium;
    padding-top:30px;
    z-index:9;
  }
  .centersty{
    position:absolute;
    left:464px;
    top:715px;
    display:block;
    width:150px;
    height:150px;
    font-size:48px;
    color:white;
    text-align:center;
    font-family:SourceHanSansCN-Medium;
    padding-top:46px;
    z-index:9;
  }
  .rightsty{
    position:absolute;
    left:691px;
    top:708px;
    width:85px;
    height:85px;
    font-size:18px;
    color:#3280bb;
    text-align:center;
    font-family:SourceHanSansCN-Medium;
    padding-top:28px;
    z-index:9;
  }
  .title-tip{
    text-align: center;
    color: rgb(122, 219, 255);
    font-size: 36px;
    line-height:65px;
  }

</style>
