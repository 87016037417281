var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "center",
          width: "100%"
        }
      },
      [
        _vm.reportData.vendor_setting.qr_code_show === 2
          ? _c("img", {
              attrs: { src: _vm.reportData.qr_url, alt: "二维码图片" }
            })
          : this.shareUrl && _vm.reportData.vendor_setting.qr_code_show === 1
          ? _c("qrcode-vue", { attrs: { value: _vm.url, size: "146" } })
          : this.shareUrl && _vm.reportData.vendor_setting.qr_code_show === 0
          ? _c("img", { attrs: { src: _vm.code, alt: "二维码图片" } })
          : _vm._e()
      ],
      1
    ),
    _c("span", { staticClass: "tipTxt" }, [
      _vm._v(
        _vm._s(
          _vm.csr === "BJTRTKH"
            ? "中国北京同仁堂（集团）有限责任公司"
            : _vm.csr === "CHENSHANG"
            ? "上海辰尚信息科技有限公司"
            : _vm.csr === "SHWYSLSWKJ"
            ? "上海伍月石榴生物科技有限公司"
            : _vm.homeFootShow === 0
            ? ""
            : _vm.homeFootShow === 1
            ? "关注祉云健康，了解更多养生知识"
            : _vm.homeFootShow === 2
            ? _vm.logoName
            : _vm.logoName
        )
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }