var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detail" }, [
    _c("div", { staticClass: "faceHeader" }, [
      _c("span", { staticClass: "back", on: { click: _vm.goReport } }, [
        _vm._v("返 回")
      ]),
      _c("span", { staticClass: "title" }, [_vm._v("脉诊结果")])
    ]),
    _vm.isFinish && !_vm.isNoRecord
      ? _c(
          "div",
          { staticClass: "handContent" },
          [
            _vm.isFinish
              ? _c("Hand", {
                  attrs: { pulseData: _vm.leftResult, title: _vm.leftHand }
                })
              : _vm._e(),
            _vm.isFinish
              ? _c("Hand", {
                  attrs: { pulseData: _vm.rightResult, title: _vm.rightHand }
                })
              : _vm._e(),
            _vm.isShowEcharts
              ? _c("AnalyseChart", { attrs: { data: _vm.chartData } })
              : _vm._e(),
            _vm.isFinish
              ? _c("Hand", {
                  attrs: { isTotal: true, pulseData: _vm.totalPulse }
                })
              : _vm._e(),
            _vm.isFinish && _vm.hasBlood
              ? _c("Blood", { attrs: { bloodData: _vm.bloodResult } })
              : _vm._e(),
            _c(
              "div",
              { staticClass: "back-btn", on: { click: _vm.goReport } },
              [_vm._v("确定")]
            )
          ],
          1
        )
      : _vm._e(),
    !_vm.isFinish
      ? _c("div", { staticClass: "waiting" }, [_vm._v(" 正在为您查询... ")])
      : _vm._e(),
    _vm.isFinish && _vm.isNoRecord
      ? _c("div", { staticClass: "waiting" }, [_vm._v(" 暂无数据... ")])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }