// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/report/xxbj_jcjg_icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.personal[data-v-5e7d8350]{\n  padding: 0px 40px;\n  height: 81px;\n}\n.personal-content[data-v-5e7d8350]{\n  height: 81px;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  display: flex;\n  justify-content: space-between;\n  padding: 0 10px;\n}\n.lable[data-v-5e7d8350]{\n  font-size: 36px;\n  color: #ffffff;\n  font-family: SourceHanSansCN-Regular;\n  line-height: 81px;\n}\n\n", ""]);
// Exports
module.exports = exports;
