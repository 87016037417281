<template>
  <div class="main" v-if="category !== '2' && report">
    <reportheader :reportId="report.display_id" :time="report.display_time" :count="report.detect_count"></reportheader>
    <personal :userInfo="report.user_info" :cellphone="report.owner || report.agency_info"></personal>
    <indicator v-if="csr !== 'HUASHI'" :score="report.health_score" :report="report"></indicator>
    <summaryhuashi v-else :score="report.health_score" :symptoms="report.symptom_status" :report="report"></summaryhuashi>
    <illness :health_status_text="report.health_status_text" :owner="report.owner" :symptoms="report.symptoms"></illness>
    <div class="result-report">
      <faceresult :report="report" :result="report.face_result" :face_photo_url="report.face_photo_url" :pulse="report.has_pulse"></faceresult>
      <tongueresult :report="report"  :result="report.tongue_result" :tongue_photo_url="report.tongue_photo_url" :pulse="report.has_pulse"></tongueresult>
      <pulseresult v-if="report.has_pulse" :url="pulse_url" :isSelfSkip="isSelfSkip"></pulseresult>
      <qrcode :pulse="report.has_pulse" :report="report"></qrcode>
      <knowledg :report="report" :is_print="report.is_print" :pulse="report.has_pulse"></knowledg>
    </div>
    <div style="padding: 0 40px;margin-top: 17px;height: 50px">
      <span style="display: block;color: #678e9f;font-size: 30px;text-align: right;">
        此报告仅作参考，如有不适请及时就医
      </span>
    </div>
    <div v-if="isShowJKFN || isShowCPTJ" class="report-bottom">
      <div class="health">
        <div style="font-size: 36px;color: #feffff;text-align: center;">
          根据您最具倾向性的体质推荐的个性化健康方案
        </div>
        <div>
          <health :report="report" :isShowJKFN="isShowJKFN" :isShowCPTJ="isShowCPTJ"></health>
          <recommendation :report="report" :isShowJKFN="isShowJKFN" :isShowCPTJ="isShowCPTJ"></recommendation>
        </div>
      </div>
    </div>
    <reportfooter :report="report" :setting="report.vendor_setting"></reportfooter>
  </div>
</template>

<script>
  import { getReport } from '@/api/report.js';
  import { queryObject ,calculateBMI } from '../../../utils/common.js';
  import reportheader from './components/reportheader.vue';
  import personal from './components/personal.vue';
  import indicator from './components/indicator.vue';
  import illness from './components/illness.vue';
  import faceresult from './components/faceresult.vue';
  import tongueresult from './components/tongueresult.vue';
  import qrcode from './components/barcode.vue';
  import knowledg from './components/knowledg.vue';
  import health from './components/health.vue';
  import recommendation from './components/recommendation.vue';
  import reportfooter from './components/reportfooter.vue';
  import summaryhuashi from './components/summaryhuashi.vue';
  import pulseresult from './components/pulseresult.vue';
  const { is_print = '', csr = '', marker = '', category = '' } = queryObject();

  export default {
    name: "index",
    components: {
      reportheader,
      personal,
      indicator,
      illness,
      faceresult,
      tongueresult,
      qrcode,
      knowledg,
      health,
      recommendation,
      reportfooter,
      summaryhuashi,
      pulseresult
    },
    data () {
      return {
        report: null,
        // csr:csr,
        pulse_url:'',
        isShowJKFN:false, // 是否展示健康方案
        isShowCPTJ:false, // 默认不展示产品推荐
        isSelfSkip:1,
      }
    },
    created() {
      // 请求报告数据
      this.getData();
      // console.log(this.category)
    },
    mounted() {
      if (window.zhiyun && window.zhiyun.isJsSkip) { // 镜子上面，调用安卓的方法，返回值是0
        this.isSelfSkip = window.zhiyun.isJsSkip()
      }
    },
    computed: {
      bmi(){
        if (this.report.user_info){
          const {bmi, text} = calculateBMI(this.report.user_info.height, this.report.user_info.weight);
          if(bmi) return `${bmi}(${text})`;
          else return `0(${text})`
        }
      },
      marker(){
        return marker
      },
      is_print(){
        return is_print
      },
      csr(){
        return csr
      },
      category(){
        return category
      }
    },
    methods: {
      getData(){
        getReport({
          id: this.$route.params.id
        }).then(res => {
          if(res && res.status_code === 10000 && res.data) {
            this.report = res.data;
            // this.report.has_pulse = true
            this.pulse_url = this.report.has_pulse?this.report.pulse_url:undefined;
            // 将其更新到store里面对应的report字段
            this.$store.commit('SET_REPORT', res.data);
            if(this.report.vendor_setting) {
              this.isShowJKFN = this.report.vendor_setting.health_scheme_show === 1; // 是否展示健康方案
              // this.isShowCPTJ = this.report.vendor_setting.product_advice_show === 1; // 是否展示产品推荐
              this.isShowCPTJ = this.report.baze; // 是否展示产品推荐(baze是拜姿，定制客户用自己的产品推荐)
            }
          }
        })
      }
    },
    watch:{
      // '$route': 'getData' // 路由一旦切换，就去重新请求一下对应报告的数据
    },
    beforeRouteEnter(to, from, next){ // 组件内导航守卫
      // 路由进来之前，判断是普通报告/打印版本
      const is_print_page =  is_print === '1';
      if (is_print_page) {
        sessionStorage.setItem('routerParams','notTzbsReport');
        const id = to.path.split('/report/')[1]
        next(`/print/${id}`); // 将路由重定向到打印报告页面
      } else {
        if (category === '2'){
          const id = to.path.split('/report/')[1]
          next(`/reporttzbs/${id}`);
        }else {
          next();
        }
      }
      // if (category === '2'){
      //   const id = to.path.split('/report/')[1]
      //   next(`/reportTzbs/${id}`);
      // }else {
      //   const id = to.path.split('/report/')[1]
      //   next(`/report/${id}`);
      // }
    }

  }
</script>

<style scoped>
  .main{
    height:100%;
    width:100%;
    background:url("../../assets/img/report/bj_sznr_icon.png");
    background-size:cover;
    overflow: hidden;
  }
  .result-report{
    width: 100%;
    /*margin: 0 auto;*/
    padding: 0 40px;
    margin-top: 10px;
    height: 150px;
    /*border: 3px solid red;*/
  }
  .report-bottom{
    width: 100%;
    /*margin: 0 auto;*/
    padding: 0 40px;
    margin-top: 3px;
    height: 248px;
  }
  .health{
    height: 207px;
    width: 1000px;
    background: url("../../assets/img/report/ysfabj_jcjg_icon.png") no-repeat;
    background-size: 100% 100%;
    padding-top: 40px;
  }

</style>
