var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isSpecialVersion
    ? _c("div", { staticClass: "tonguedetial" }, [
        _c("div", { staticClass: "tongueHeader" }, [
          !_vm.isOnlyTongue
            ? _c("span", { staticClass: "back", on: { click: _vm.goReport } }, [
                _vm._v("返 回")
              ])
            : _vm._e(),
          _c("span", { staticClass: "title" }, [_vm._v("舌诊结果")]),
          _vm.isOnlyTongue
            ? _c("span", { staticClass: "close", on: { click: _vm.goApp } }, [
                _vm._v("关 闭")
              ])
            : _vm._e()
        ]),
        _vm.tongueData
          ? _c("div", { staticClass: "tongueContent" }, [
              _c("div", { staticClass: "tongueBox" }, [
                _c("div", { staticClass: "imgBox" }, [
                  _c(
                    "div",
                    { staticClass: "imagePics" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../../../assets/img/menopauseovary/sxjg.gif"),
                          alt: "舌诊gif图"
                        }
                      }),
                      _vm._l(_vm.riskClass, function(item) {
                        return _c("div", {
                          key: item,
                          staticClass: "riskImg",
                          class: item
                        })
                      })
                    ],
                    2
                  )
                ]),
                _c("div", { staticClass: "tonguePart" }, [
                  _c(
                    "p",
                    {
                      class: _vm.activeClass("healthTab"),
                      style: _vm.styleObj,
                      on: {
                        click: function($event) {
                          return _vm.doChoose("healthTab")
                        }
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.isSpecialVersion ? "结果" : "健康提示")
                        )
                      ])
                    ]
                  ),
                  !_vm.hasNoRisk
                    ? _c(
                        "p",
                        {
                          class: _vm.activeClass("organsTab"),
                          style: _vm.styleObj,
                          on: {
                            click: function($event) {
                              return _vm.doChoose("organsTab")
                            }
                          }
                        },
                        [_c("span", [_vm._v("脏腑风险")])]
                      )
                    : _vm._e()
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeTab === "healthTab",
                        expression: "activeTab === 'healthTab'"
                      }
                    ],
                    staticClass: "healthTip"
                  },
                  [
                    _c("div", { staticClass: "partDetail" }, [
                      _c("div", { staticClass: "partTitle" }, [_vm._v("舌色")]),
                      _c("div", { staticClass: "partExplain" }, [
                        _c("p", { staticClass: "symptom" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.tongueData.tongue_nature_color_info &&
                                  _vm.tongueData.tongue_nature_color_info
                                    .symptom
                              )
                          )
                        ]),
                        !_vm.isSpecialVersion
                          ? _c("p", { staticClass: "explain" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.tongueData.tongue_nature_color_info &&
                                      _vm.tongueData.tongue_nature_color_info
                                        .name
                                  )
                              )
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _c("div", { staticClass: "partDetail" }, [
                      _c("div", { staticClass: "partTitle" }, [_vm._v("舌形")]),
                      _c("div", { staticClass: "partExplain" }, [
                        _c("p", { staticClass: "symptom" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.tongueData.tongue_fat_thin_info &&
                                  _vm.tongueData.tongue_fat_thin_info.symptom
                              ) +
                              "，" +
                              _vm._s(
                                _vm.tongueData.tongue_crack_info &&
                                  _vm.tongueData.tongue_crack_info.symptom
                              ) +
                              "， " +
                              _vm._s(
                                _vm.tongueData.tongue_indentation_info &&
                                  _vm.tongueData.tongue_indentation_info.symptom
                              ) +
                              " "
                          )
                        ]),
                        !_vm.isSpecialVersion
                          ? _c("p", { staticClass: "explain" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.tongueData.tongue_fat_thin_info &&
                                      _vm.tongueData.tongue_fat_thin_info.name
                                  )
                              )
                            ])
                          : _vm._e(),
                        !_vm.isSpecialVersion
                          ? _c("p", { staticClass: "explain" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.tongueData.tongue_crack_info &&
                                      _vm.tongueData.tongue_crack_info.name
                                  )
                              )
                            ])
                          : _vm._e(),
                        !_vm.isSpecialVersion
                          ? _c("p", { staticClass: "explain" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.tongueData.tongue_indentation_info &&
                                      _vm.tongueData.tongue_indentation_info
                                        .name
                                  )
                              )
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _c("div", { staticClass: "partDetail" }, [
                      _c("div", { staticClass: "partTitle" }, [_vm._v("舌苔")]),
                      _c("div", { staticClass: "partExplain" }, [
                        _c("p", { staticClass: "symptom" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.tongueData.tongue_coat_color_info &&
                                  _vm.tongueData.tongue_coat_color_info.symptom
                              ) +
                              "，" +
                              _vm._s(
                                _vm.tongueData.tongue_coat_thickness_info &&
                                  _vm.tongueData.tongue_coat_thickness_info
                                    .symptom
                              )
                          )
                        ]),
                        !_vm.isSpecialVersion
                          ? _c("p", { staticClass: "explain" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.tongueData.tongue_coat_color_info &&
                                      _vm.tongueData.tongue_coat_color_info.name
                                  )
                              )
                            ])
                          : _vm._e(),
                        !_vm.isSpecialVersion
                          ? _c("p", { staticClass: "explain" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.tongueData.tongue_coat_thickness_info &&
                                      _vm.tongueData.tongue_coat_thickness_info
                                        .name
                                  )
                              )
                            ])
                          : _vm._e()
                      ])
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeTab === "organsTab",
                        expression: "activeTab === 'organsTab'"
                      }
                    ],
                    staticClass: "organsTip"
                  },
                  [
                    _vm.visceralRisk && _vm.visceralRisk.length > 0
                      ? _vm._l(_vm.visceralRiskList, function(item, index) {
                          return _c(
                            "div",
                            { key: item.part, staticClass: "organsPart" },
                            [
                              _vm.visceralRisk[index] &&
                              _vm.visceralRisk[index][item.field] &&
                              _vm.visceralRisk[index][item.field].attention1 &&
                              _vm.visceralRisk[index][item.field].attention2
                                ? [
                                    _c("p", [
                                      _c("img", {
                                        attrs: { src: item.img, alt: item.part }
                                      }),
                                      _vm._v(_vm._s(item.part))
                                    ]),
                                    _c("div", [
                                      _c("p", { staticClass: "maybe1" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.visceralRisk[index][item.field]
                                              .attention1
                                          )
                                        )
                                      ]),
                                      _c("p", { staticClass: "maybe2" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.visceralRisk[index][item.field]
                                              .attention2
                                          )
                                        )
                                      ]),
                                      _c("p", { staticClass: "tips" }, [
                                        _vm._v(
                                          "提示：" +
                                            _vm._s(
                                              _vm.visceralRisk[index][
                                                item.field
                                              ].diagnose
                                            )
                                        )
                                      ])
                                    ])
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        })
                      : _vm._e()
                  ],
                  2
                )
              ])
            ])
          : _vm._e()
      ])
    : _c("div", { staticClass: "tonguedetial" }, [
        _c("div", { staticClass: "tongueHeader headerSpecial" }, [
          _c("span", { staticClass: "back", on: { click: _vm.goReport } }, [
            _vm._v("返 回")
          ]),
          _c("span", { staticClass: "title" }, [_vm._v("舌部图片")])
        ]),
        _c("div", { staticClass: "specialBg" }),
        _c("img", {
          staticClass: "photo",
          attrs: { src: _vm.tonguePhoto, alt: "舌诊照片" }
        })
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }