<template>
  <div class="company">
    <img src="../../../assets/img/common/company-logo-color.png" alt="祉云logo" />
    <span>上海祉云医疗科技有限公司</span>
  </div>
</template>

<script>
  export default {
    name: "company"
  }
</script>

<style scoped>
  .company {
    text-align: center;
    padding-bottom: 30px;
  }

  .company img {
    height: 38px;
    vertical-align: middle;
  }
</style>