<template>
  <div class="wxchronicscheme">
    <div class="title">健康方案</div>
    <div v-for="(item, index) in buttontype" :key="index">
      <div class="header">
        <img src="../../../assets/img/wx/wx_jkfabt_jcjg.png"/>
        <span>{{item}}</span>
        <img src="../../../assets/img/wx/wx_jkfabt1_jcjg.png"/>
      </div>
      <!-- 饮食调养 高血压 糖尿病 -->
      <div v-if="item === '饮食调养'" class="zero">
        <div class="goodfood">
          <div class="left">
            <img src='../../../assets/img/wx/wx_ys_jcjg.png'/>
            <span>宜食</span>
          </div>
          <div class="right">
            <span>{{goodfood.content[0]}} </span>
          </div>
        </div>
        <!-- 高血压没有少食 -->
        <div v-if="badfood.content.length > 0" class="goodfood badfood">
          <div class="left">
            <img src='../../../assets/img/wx/wx_js_jcjg.png'/>
            <span style="color:#f86426">少食</span>
          </div>
          <div class="right">
            <span>{{badfood.content[0]}} </span>
          </div>
        </div>
        <van-tabs v-model="active" :ellipsis="false" swipeable title-active-color="#30c3fa" color="#30c3fa" title-inactive-color="#333333">
          <van-tab v-for="(item, index) in dietTherapy" :key="index" :title="item.name">
            <img class="commom-img" :src="reportData.solutions.asset_prefix + item.asset[0]" alt="加载失败">
            <!-- <div class="food-pics">
              <div :class="(index+1)%4 !== 0 ? 'pics pics-4' : 'pics'" v-for="(items ,index) in item.asset" :key="index">
                <img :src="reportData.solutions.asset_prefix + items" alt="加载失败" />
              </div>
            </div> -->
            <div class="item-title">烹煮方法：</div>
            <div class="item-span" v-for="(step,index) in item.content" :key="index">{{ step }}</div>
          </van-tab>
        </van-tabs>
      </div>
      <!-- 茶饮养生 高血压 糖尿病-->
      <div v-if="item === '茶饮养生'">
        <van-tabs v-model="activeTea" :ellipsis="false" swipeable title-active-color="#30c3fa" color="#30c3fa" title-inactive-color="#333333">
          <van-tab v-for="(item, index) in tea" :key="index" :title="item.name">
            <img class="commom-img" :src="reportData.solutions.asset_prefix + item.asset[0]" alt="加载失败">
            <div class="item-title">泡煮方法：</div>
            <span class="item-span">{{item.content && item.content[0]}}</span>
            <!-- 糖尿病 健康有功效 -->
            <div v-if="item.content.length > 1" class="item-title">功效：</div>
            <span class="item-span">{{item.content && item.content[1]}}</span>
          </van-tab>
        </van-tabs>
      </div>
      <!-- 经典药方 糖尿病-->
      <div v-if="item === '经典药方'">
        <van-tabs v-model="activejdyf" :ellipsis="false" swipeable title-active-color="#30c3fa" color="#30c3fa" title-inactive-color="#333333">
          <van-tab v-for="(item, index) in jdyf" :key="index" :title="item.name">
            <div class="item-title">药物组成：</div>
            <span class="item-span">{{item.form}}</span>
            <div class="item-title">功效：</div>
            <span class="item-span">{{item.effect}}</span>
          </van-tab>
        </van-tabs>
      </div>
      <!-- 预防保健操 糖尿病 -->
      <div v-if="item === '预防保健操' ">
        <div v-for="(item, index) in exercises.content" :key="index">
          <div v-if="index%2 === 0" class="item-title">{{item}}</div>
          <div v-else class="item-span">{{item}}</div>
        </div>
      </div>
      <!-- 穴位按压 糖尿病 -->
      <div v-if="item === '穴位按压' && reportData.type_attention === '糖尿病' ">
        <div class="item-span">{{acupoint[0].content[0]}}</div>
        <div class="item-span">{{acupoint[0].content[1]}}</div>
        <div class="item-span">{{acupoint[0].content[2]}}</div>
      </div>
      <!-- 穴位按压 高血压 -->
      <div v-if="item === '穴位按压' && reportData.type_attention === '高血压' ">
        <van-tabs v-model="activeAcupoint" :ellipsis="false" swipeable title-active-color="#30c3fa" color="#30c3fa" title-inactive-color="#333333">
          <van-tab v-for="(item, index) in acupoint" :key="index" :title="item.name">
            <img class="commom-img" :src="reportData.solutions.asset_prefix + item.asset[0]" alt="加载失败">
            <div class="item-title">定位：</div>
            <span class="item-span">{{item.content[0]}}</span>
            <div class="item-title">按摩方法：</div>
            <span class="item-span">{{item.content[1]}}</span>
          </van-tab>
        </van-tabs>
      </div>
      <!-- 艾灸 糖尿病 -->
      <div v-if="item === '艾灸'">
        <van-tabs v-model="activeMoxibustion" :ellipsis="false" swipeable title-active-color="#30c3fa" color="#30c3fa" title-inactive-color="#333333">
          <van-tab v-for="(item, index) in moxibustion" :key="index" :title="item.name">
            <img class="commom-img" :src="reportData.solutions.asset_prefix + item.asset[0]" alt="加载失败">
            <span class="item-span">{{item.content[0]}}</span>
          </van-tab>
        </van-tabs>
      </div>
      <!-- 足浴疗法 高血压 糖尿病 -->
      <div v-if="item === '足浴疗法'">
        <van-tabs v-model="activeFootBath" :ellipsis="false" swipeable title-active-color="#30c3fa" color="#30c3fa" title-inactive-color="#333333">
          <van-tab v-for="(item, index) in footBath" :key="index" :title="item.name">
            <img class="commom-img" :src="reportData.solutions.asset_prefix + item.asset[0]" alt="加载失败">
            <div class="item-title">{{reportData.type_attention === '糖尿病' ? '方药组成：' : '足浴配方：'}}</div>
            <span class="item-span">{{item.content[0]}}</span>
            <div class="item-title">{{reportData.type_attention === '糖尿病' ? '适应症：' : '操作：'}}</div>
            <span class="item-span">{{item.content[1]}}</span>
            <div class="item-title" v-if="reportData.type_attention === '糖尿病'">用法用量：</div>
            <span class="item-span" v-if="reportData.type_attention === '糖尿病'">{{item.content[2]}}</span>
          </van-tab>
        </van-tabs>
      </div>
      <!-- 精神调养 高血压 糖尿病-->
      <div v-if="item === '精神调养'">
        <span class="item-span">{{mental.content[0]}}</span>
      </div>
      <!-- 运动健身 高血压-->
      <div v-if="item === '运动健身'">
        <span class="item-span">{{sportHealth.content[0]}}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    reportData: Object,
  },
  data(){
    return {
      buttontype: [],
      goodfood: null,
      badfood: null,
      active: 0,
      dietTherapy: null,
      activeTea: 0,
      tea: null,
      activejdyf: 0,
      jdyf: null,
      activeAcupoint: 0,
      acupoint: null, //穴位按压
      activeFootBath: 0,
      footBath: null, //足浴疗法
      mental: null, //精神调养
      sportHealth: null, //运动健身
      exercises: null, // 预防保健操
      activeMoxibustion: 0,
      moxibustion: null, //艾灸
    }
  },
  created(){
    if(this.reportData.type_attention === '高血压'){
      this.buttontype = ['饮食调养', '茶饮养生', '穴位按压', '足浴疗法', '精神调养', '运动健身']
    } else {
      // 糖尿病 健康状态有些没有经典药方
      this.buttontype = this.reportData.classic_disease.length === 0 ?
      ['饮食调养', '茶饮养生', '预防保健操', '穴位按压', '艾灸', '足浴疗法', '精神调养'] :
      ['饮食调养', '茶饮养生', '经典药方', '预防保健操', '穴位按压', '艾灸', '足浴疗法', '精神调养']
    }
    const symptomsName = this.reportData.symptoms[0].name
    const solutionsDic = this.reportData.solutions
    this.goodfood = solutionsDic.chronic_disease_good_food[symptomsName]
    this.badfood = solutionsDic.chronic_disease_bad_food[symptomsName]
    this.dietTherapy = solutionsDic.chronic_disease_diet_therapy[symptomsName]
    this.tea = solutionsDic.chronic_disease_tea[symptomsName]
    // 穴位按压
    this.acupoint = solutionsDic.chronic_disease_massage[symptomsName]
    // 足浴疗法
    this.footBath = solutionsDic.chronic_disease_foot_bath[symptomsName]
    // 精神调养
    this.mental = solutionsDic.chronic_disease_mental_recuperation[symptomsName]
    // 运动健身
    this.sportHealth = this.reportData.type_attention === '高血压' ? solutionsDic.chronic_disease_sport_health[symptomsName] : null
    // 预防保健操
    this.exercises = solutionsDic.chronic_disease_exercises ? solutionsDic.chronic_disease_exercises[symptomsName] : null
    // 艾灸
    this.moxibustion = solutionsDic.chronic_disease_moxibustion ? solutionsDic.chronic_disease_moxibustion[symptomsName] : null
    // 经典药方
    this.jdyf = this.reportData.classic_disease ? this.reportData.classic_disease : null

  },
}
</script>

<style lang="scss" scoped>
.wxchronicscheme {
  background-color: #fff;
	border-radius: 30px;
  padding: 57px 46px 30px 51px;
  margin-top: 45px;
  .title {
    color: #333;
    font: {
      size: 48px;
      family: PingFangSC-Medium;
    };
    width: 260px;
    height: 50px;
    border-left: 6px solid #1288eb;
    text-align: center;
    margin-bottom: 30px;
  }
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 62%;
    margin: 75px 0 60px 19%;
    img {
      width: 150px;
      height: 29px;
    }
    span {
      font-family: PingFangSC-Regular;
	    font-size: 45px;
      color: #30c3fa;
      width: 260px;
      text-align: center;
    }
  }
  .common-one {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .commom-img {
    display: block;
    width: 100%;
    border-radius: 30px;
	  border: solid 1px #fff;
    margin: 20px 0;
  }
  .common-text {
    font-family: SourceHanSansCN-Regular;
    font-size: 39px;
    color: #666666;
    line-height: 62px;
  }
  .zero {
    .goodfood{
      @extend .common-one;
      border-radius: 30px;
      border: solid 1px #dcdcdc;
      padding: 33px 44px 33px 0;
      .left {
        @extend .common-one;
        flex-direction: column;
        justify-content: center;
        width: 20%;
        img {
          width: 45px;
          height: 45px;
        }
        span {
          font-size: 42px;
          color: #35c8e3;
          font-family: SourceHanSansCN-Regular;
          margin-top: 10px;
        }
      }
      .right {
        width: 80%;
        font-family: PingFangSC-Regular;
        font-size: 36px;
        color: #666666;
        line-height: 52px;
      }
    }
    .badfood {
      margin: 30px 0 20px 0;
    }
    .food-pics {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .pics {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      font-family: SourceHanSansCN-Regular;
      line-height: 72px;
      color: #666666;
      img {
        width: 210px;
      }
    }
    .pics-4 {
      margin-right: 17px;
    }
  }
  .item-title {
    font-family: PingFangSC-Regular;
	  font-size: 42px;
    color: #333333;
    margin-top: 42px;
    margin-bottom: 20px;
  }
  .item-span{
    font-family: SourceHanSansCN-Regular;
	  font-size: 39px;
    color: #666666;
    line-height: 52px;
  }
}
</style>

<style>
  .wxchronicscheme .van-tab {
    font-size: 42px;
    color: #333;
    line-height: 88px;
  }

  .wxchronicscheme .van-tabs--line .van-tabs__wrap {
    height: 88px;
  }

  .wxchronicscheme .van-tabs__line {
    width: 140px;
  }
</style>