var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "method" }, [
    _vm._m(0),
    _c("div", { staticClass: "preserveHealth" }, [
      !_vm.isTzbsReport ? _c("p", [_vm._v("1、保健原则")]) : _vm._e(),
      !_vm.isTzbsReport ? _c("div", [_vm._v(_vm._s(_vm.principle))]) : _vm._e(),
      _c("p", [_vm._v(_vm._s(_vm.isTzbsReport ? 1 : 2) + "、饮食调养")]),
      _c("div", [
        _c(
          "div",
          { staticClass: "good" },
          [
            _vm._v(" 宜食："),
            _vm._l(_vm.foodList.good, function(item, index) {
              return _c("span", { key: index }, [
                _vm._v(
                  _vm._s(item.name) +
                    _vm._s(index === _vm.foodList.good.length - 1 ? "。" : "、")
                )
              ])
            })
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "bad" },
          [
            _vm._v(" 忌食："),
            _vm._l(_vm.foodList.bad, function(item, index) {
              return _c("span", { key: index }, [
                _vm._v(
                  _vm._s(item.name) +
                    _vm._s(index === _vm.foodList.bad.length - 1 ? "。" : "、")
                )
              ])
            })
          ],
          2
        ),
        !_vm.isTzbsReport
          ? _c(
              "div",
              { staticClass: "diet" },
              [
                _c("h3", { staticClass: "title" }, [_vm._v("推荐药膳")]),
                _vm._l(_vm.dietList, function(item, index) {
                  return _c("div", { key: index }, [
                    _c("h3", { staticClass: "dietName" }, [
                      _vm._v(_vm._s(item.name))
                    ]),
                    _c(
                      "div",
                      { staticClass: "imageBox" },
                      _vm._l(item.asset, function(img, imgIndex) {
                        return _c(
                          "div",
                          { key: imgIndex, staticClass: "imageUnit" },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.solutions.asset_prefix + img[1],
                                alt: "药膳图片"
                              }
                            }),
                            _c("p", [_vm._v(_vm._s(img[0]) + " ")])
                          ]
                        )
                      }),
                      0
                    ),
                    _c("div", { staticClass: "dietMenu" }, [
                      _vm._v(" 清单：" + _vm._s(item.content[0]) + " ")
                    ]),
                    _c("div", { staticClass: "dietCook" }, [
                      _vm._v(" 烹煮方法：" + _vm._s(item.content[1]) + " ")
                    ])
                  ])
                })
              ],
              2
            )
          : _c(
              "div",
              { staticClass: "diet" },
              [
                _c("h3", { staticClass: "title" }, [_vm._v("推荐药膳")]),
                _c("h3", { staticClass: "dietName" }, [
                  _vm._v(_vm._s(_vm.dietList.name))
                ]),
                _c("div", { staticClass: "imageBoxTzbs" }, [
                  _c("img", {
                    attrs: {
                      src: _vm.solutions.asset_prefix + _vm.dietList.asset,
                      alt: "药膳图片"
                    }
                  })
                ]),
                _c("div", { staticClass: "dietCook" }, [
                  _vm._v(" 烹煮方法： ")
                ]),
                _vm._l(_vm.dietList.content, function(item, index) {
                  return _c("div", { key: index }, [
                    _vm._v(" " + _vm._s(item) + " ")
                  ])
                })
              ],
              2
            )
      ]),
      _c("p", [_vm._v(_vm._s(_vm.isTzbsReport ? 2 : 3) + "、茶饮养生")]),
      _c("div", { staticClass: "tea" }, [
        _c("h3", { staticClass: "teaName" }, [
          _vm._v(_vm._s(_vm.teaList.name))
        ]),
        _c("div", { staticClass: "imgBox" }, [
          _c("img", {
            attrs: {
              src: _vm.solutions.asset_prefix + _vm.teaList.asset,
              alt: "茶饮图片"
            }
          })
        ]),
        _c("div", { staticClass: "dietMenu" }, [
          _vm._v(
            " " +
              _vm._s(_vm.isTzbsReport ? "配料" : "清单") +
              "：" +
              _vm._s(_vm.teaList.content && _vm.teaList.content[0]) +
              " "
          )
        ]),
        _c("div", { staticClass: "dietCook" }, [
          _vm._v(
            " " +
              _vm._s(_vm.isTzbsReport ? "泡煮" : "烹煮") +
              "方法：" +
              _vm._s(_vm.teaList.content && _vm.teaList.content[1]) +
              " "
          )
        ])
      ]),
      !_vm.isTzbsReport
        ? _c("div", [
            _c("p", [_vm._v("4、其他养生")]),
            _c("div", { staticClass: "otherPreserve" }, [
              _c("table", [
                _c("tr", [
                  _c("td", [_vm._v("起居指导")]),
                  _c(
                    "td",
                    { staticClass: "healthGuide" },
                    _vm._l(_vm.sleepGuide.content, function(item, index) {
                      return _c("span", { key: index }, [_vm._v(_vm._s(item))])
                    }),
                    0
                  )
                ]),
                _c("tr", [
                  _c("td", [_vm._v("穴位按压")]),
                  _c("td", { staticClass: "massage" }, [
                    _c("p", [
                      _vm._v(
                        "1、定位：" +
                          _vm._s(
                            _vm.massageList.content &&
                              _vm.massageList.content[0]
                          )
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "2、按摩方法：" +
                          _vm._s(
                            _vm.massageList.content &&
                              _vm.massageList.content[1]
                          )
                      )
                    ]),
                    _c("img", {
                      attrs: {
                        src: _vm.solutions.asset_prefix + _vm.massageList.asset,
                        alt: "穴位图片"
                      }
                    })
                  ])
                ]),
                _c("tr", [
                  _c("td", [_vm._v("音乐养生")]),
                  _c("td", [_vm._v("《" + _vm._s(_vm.musicList) + "》")])
                ]),
                _c("tr", [
                  _c("td", [_vm._v("经典药方")]),
                  _c(
                    "td",
                    { staticClass: "classic" },
                    _vm._l(_vm.classicMedicine, function(item) {
                      return _c("div", { key: item.name }, [
                        _c("h3", [_vm._v(_vm._s(item.name))]),
                        _c("p", [
                          _vm._v(
                            "药物组成：" +
                              _vm._s(item.content && item.content[0])
                          )
                        ]),
                        _c("p", [
                          _vm._v(
                            "功效：" + _vm._s(item.content && item.content[1])
                          )
                        ])
                      ])
                    }),
                    0
                  )
                ])
              ])
            ])
          ])
        : _c("div", [
            _c("p", { staticClass: "pressTitle" }, [_vm._v("3、穴位按压")]),
            _c("div", { staticClass: "massagePress" }, [
              _c("h3", { staticClass: "massageName" }, [
                _vm._v(_vm._s(_vm.massageList.name))
              ]),
              _c("div", { staticClass: "imgBox" }, [
                _c("img", {
                  attrs: {
                    src: _vm.solutions.asset_prefix + _vm.massageList.asset,
                    alt: "穴位按压图片"
                  }
                })
              ]),
              _c("div", { staticClass: "massageMenu" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.massageList.content && _vm.massageList.content[0]
                    ) +
                    " "
                )
              ])
            ])
          ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [
      _c("p", { staticClass: "tips" }, [
        _vm._v("*此报告仅作参考，如有不适请及时就医")
      ]),
      _c("h3", { staticClass: "title" }, [
        _vm._v("根据您当前的健康状态定制了个性化养生方案")
      ]),
      _c("h3", { staticClass: "logo" }, [_vm._v("养生方案")]),
      _c("p", { staticClass: "angle" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }