var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wxinfo" }, [
    _c("div", { staticClass: "info" }, [
      _c("span", [_vm._v(_vm._s(_vm.info.gender === 1 ? "男" : "女"))]),
      _vm._m(0)
    ]),
    _vm.type === "female"
      ? _c("div", { staticClass: "info" }, [
          _c("span", [_vm._v(_vm._s(_vm.age))]),
          _vm._m(1)
        ])
      : _c("div", { staticClass: "info" }, [
          _c("span", [_vm._v(_vm._s(_vm.bmi))]),
          _vm._m(2)
        ]),
    _c("div", { staticClass: "info" }, [
      _c("span", [
        _vm._v(
          _vm._s(
            _vm.type === "female"
              ? _vm.info.cellphone.substr(-4)
              : _vm.owner.cellphone.substr(-4)
          )
        )
      ]),
      _vm._m(3)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _c("img", {
        attrs: {
          src: require("../../../assets/img/wx/wx_xb_jcjg.png"),
          alt: "性别图"
        }
      }),
      _c("span", [_vm._v("性别")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _c("img", {
        attrs: {
          src: require("../../../assets/img/wx/wx_nl_icon.png"),
          alt: "年龄"
        }
      }),
      _c("span", [_vm._v("年龄")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _c("img", {
        staticClass: "bmi",
        attrs: {
          src: require("../../../assets/img/wx/wx_bmi.png"),
          alt: "BMI图"
        }
      }),
      _c("span", [_vm._v("BMI指数")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _c("img", {
        staticClass: "phone",
        attrs: {
          src: require("../../../assets/img/wx/wx_sjwh_jcjg.png"),
          alt: "手机尾号图"
        }
      }),
      _c("span", [_vm._v("手机尾号")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }