var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "title" }, [_vm._v("血压")]),
    _c("div", { staticClass: "basic" }, [
      _c("div", [
        _c("p", { staticClass: "heart" }, [_vm._v("心率")]),
        _c("p", { staticClass: "zhi" }, [
          _vm._v(" " + _vm._s(_vm.bloodData.heart_rate || "-") + " "),
          _c("span", { staticClass: "unit" }, [_vm._v("次/分")])
        ])
      ]),
      _c("div", [
        _c("p", { staticClass: "blood" }, [_vm._v("舒张压")]),
        _c("p", { staticClass: "zhi" }, [
          _vm._v(" " + _vm._s(_vm.bloodData.blood_dia || "-") + " "),
          _c("span", { staticClass: "unit" }, [_vm._v("mmHg")])
        ])
      ]),
      _c("div", [
        _c("p", { staticClass: "blood" }, [_vm._v("收缩压")]),
        _c("p", { staticClass: "zhi" }, [
          _vm._v(" " + _vm._s(_vm.bloodData.blood_sys || "-") + " "),
          _c("span", { staticClass: "unit" }, [_vm._v("mmHg")])
        ])
      ])
    ]),
    _c("div", { staticClass: "notice" }, [
      _vm._v("提示：" + _vm._s(_vm.bloodData.blood_attention))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }