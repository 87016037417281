<template>
  <div v-if="marker ==='10.1'" class="detail">
    <div class="boxsty" style="display: block;height: 110px;margin: 93px 0 0 0;width: 80%;overflow: auto;">
      <span class="title">关于我们</span>
      <span class="border"></span>
    </div>
    <div class="result">
      <div class="result-content" style="height: 980px">
        <p style="font-size: 30px;line-height: 54px;text-indent: 65px">
          上海祉云医疗科技有限公司是一家专注于互联网+智慧中医健康服务的高科技型企业。上海祉云以中医+云计算为基础，不断应用新技术提升中医健康服务水平。汇聚上海中医药大学、复旦大学等国内优质资源，专注于中医智能化健康服务产品的研发 。
        </p>
        <div style="margin-top: 20px">
          <div style="width: 50%;float: left;position: relative">
            <div style="height: 299px">
              <img :src="patent3" style="float: right;margin-right: 40px;width: 214px">
            </div>
            <div style="font-size: 14px;width: 180px;margin-top: 10px;margin-left: 120px">
              中医面诊分析与诊断系统
            </div>
          </div>
          <div style="width: 50%;float: left;position: relative">
            <div style="height: 299px">
              <img :src="patent2" style="margin-left: 40px;width: 214px">
            </div>
            <div style="font-size: 14px;width: 180px;margin-top: 10px;margin-left: 54px;text-align: center">
              一种基于中医望诊的“神”的特征提取与分类方法及系统
            </div>
          </div>
        </div>
        <p style="font-size: 30px;line-height: 54px;text-indent: 65px">公司基于上海中医药大学专利技术----云中医APP和祉云中医智能镜（基于中医智慧健康状态监测与管理系统开发的应用产品），致力于利用大数据、云计算、人工智能等现代信息技术，打造中医智能健康管理平台，发挥中医药的独特优势，在健康管理、慢病管理、孕产妇保健、儿童调制等方面拓展中医药服务，为健康中国建设提供服务。可大大提升中医服务的公平性、易用性和可及性。</p>
        <div style="margin-top: 20px">
          <div style="width: 50%;float: left;position: relative">
            <div style="height: 299px">
              <img :src="patent1" style="float: right;margin-right: 40px;width: 214px">
            </div>
            <div style="font-size: 14px;width: 180px;margin-top: 10px;margin-left: 110px;text-align: center">
              一种健康状态监测管理方法及智能镜
            </div>
          </div>
          <div style="width: 50%;float: left;position: relative">
            <div style="height: 299px">
              <img :src="software" style="margin-left: 40px;width: 214px">
            </div>
            <div style="font-size: 14px;width: 180px;margin-top: 10px;margin-left: 54px;text-align: center">
              云中医健康管理系统1.0(简称云中医)
            </div>
          </div>
        </div>
        <span style="font-size: 30px;line-height: 54px">
          发明专利和软件著作权<br />
          ●    一种基于中医望诊的“神”的特征提取与分类方法及系统，申请号201510010231.5<br />
          ●     一种健康状态监测管理智能镜，已授权，专利号：ZL201520303463.5<br />
          ●     中医面诊分析与诊断系统，已授权，专利号ZL201210147003.9<br />
          ●     云中医健康管理系统1.0(简称云中医)，著作权号：2016SR079255<br />
            <br />
          联系方式<br />
          公司名称：上海祉云医疗科技有限公司<br />
          公司地址：上海市浦东新区张衡路666弄2号楼101室<br />
          邮件地址：zhiyun@zhiyuntcm.com<br />
            <br />
        </span>
      </div>
      <div style="margin-top: 20px">
        <div class="back" @click="continue1">
          继&nbsp;续
        </div>
      </div>
    </div>
  </div>
  <div class="detail" v-else>
    <div class="boxsty" style="display: block;height: 110px;margin: 95px 0 0 0;width: 80%;overflow: auto;">
      <span class="title">关于我们</span>
      <span class="border"></span>
    </div>
    <div class="resultM">
      <div class="result-content">
        <p style="font-size: 30px;line-height: 54px;text-indent: 65px">
          上海祉云医疗科技有限公司是一家专注于互联网+智慧中医健康服务的高科技型企业。上海祉云以中医+云计算为基础，不断应用新技术提升中医健康服务水平。汇聚上海中医药大学、复旦大学等国内优质资源，专注于中医智能化健康服务产品的研发 。
        </p>
        <div style="margin-top: 20px">
          <div style="width: 50%;float: left;position: relative">
            <div style="height: 299px">
              <img :src="patent3" style="float: right;margin-right: 40px;width: 214px">
            </div>
            <div style="font-size: 14px;width: 180px;margin-top: 10px;margin-left: 120px">
              中医面诊分析与诊断系统
            </div>
          </div>
          <div style="width: 50%;float: left;position: relative">
            <div style="height: 299px">
              <img :src="patent2" style="margin-left: 40px;width: 214px">
            </div>
            <div style="font-size: 14px;width: 180px;margin-top: 10px;margin-left: 54px;text-align: center">
              一种基于中医望诊的“神”的特征提取与分类方法及系统
            </div>
          </div>
        </div>
        <p style="font-size: 30px;line-height: 54px;text-indent: 65px">公司基于上海中医药大学专利技术----云中医APP和祉云中医智能镜（基于中医智慧健康状态监测与管理系统开发的应用产品），致力于利用大数据、云计算、人工智能等现代信息技术，打造中医智能健康管理平台，发挥中医药的独特优势，在健康管理、慢病管理、孕产妇保健、儿童调制等方面拓展中医药服务，为健康中国建设提供服务。可大大提升中医服务的公平性、易用性和可及性。</p>
        <div style="margin-top: 20px">
          <div style="width: 50%;float: left;position: relative">
            <div style="height: 299px">
              <img :src="patent1" style="float: right;margin-right: 40px;width: 214px">
            </div>
            <div style="font-size: 14px;width: 180px;margin-top: 10px;margin-left: 110px;text-align: center">
              一种健康状态监测管理方法及智能镜
            </div>
          </div>
          <div style="width: 50%;float: left;position: relative">
            <div style="height: 299px">
              <img :src="software" style="margin-left: 40px;width: 214px">
            </div>
            <div style="font-size: 14px;width: 180px;margin-top: 10px;margin-left: 54px;text-align: center">
              云中医健康管理系统1.0(简称云中医)
            </div>
          </div>
        </div>
        <span style="font-size: 30px;line-height: 54px">
          发明专利和软件著作权<br />
          ●一种基于中医望诊的“神”的特征提取与分类方法及系统，申请号201510010231.5<br />
          ●一种健康状态监测管理智能镜，已授权，专利号：ZL201520303463.5<br />
          ●中医面诊分析与诊断系统，已授权，专利号ZL201210147003.9<br />
          ●云中医健康管理系统1.0(简称云中医)，著作权号：2016SR079255<br />
          <br />
          联系方式<br />
          公司名称：上海祉云医疗科技有限公司<br />
          公司地址：上海市浦东新区张衡路666弄2号楼101室<br />
          邮件地址：zhiyun@zhiyuntcm.com<br />
          <br />
        </span>
      </div>
      <div style="margin-top: 20px">
        <div class="back" @click="continue1">
          继&nbsp;续
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import {queryObject} from "../../../utils/common";
  const { marker = '' } = queryObject();
  import patent3 from '../../assets/img/common/patent3.jpg';
  import patent2 from '../../assets/img/common/patent2.jpg';
  import patent1 from '../../assets/img/common/patent1.jpg';
  import software from '../../assets/img/common/software.jpg';
  export default {
    name: "aboutus",
    data(){
      return{
        patent3:patent3,
        patent2:patent2,
        patent1:patent1,
        software:software
      }
    },
    created() {
      this.$store.state.isShowLoading =false
    },
    computed:{
      marker(){
        return marker
      },
    },
    methods: {
      continue1(){
        if (window.zhiyun) {
          window.zhiyun.closeabouts();
        }
      }
    },
  }
</script>

<style scoped>
  .detail{
    width:100%;
    height:100%;
    position:absolute;
    top:0px;
    left:0px;
    overflow:hidden;
    background-size:cover;
    background:url("../../assets/img/report/bj_sznr_icon.png");
    /*background-color:#000f21;*/
  }
  .boxsty{
    width: 100%;
    margin: 0px auto;
  }
  .title{
    font-size: 60px;
    color:#ffffff;
    font-family:TITLE;
    margin-left:60px;
  }
  .border{
    position:absolute;
    left:0px;
    top:157px;
    display:block;
    width:1000px;
    height:16px;
    background:url("../../assets/img/report/x_icon.png")no-repeat;
  }
  .result{
    height:1400px;
    width: 100%;
    color:#FFFFFF;
    font-size:24px;
    background:url("../../assets/img/report/bjk_gywm_icon.png") no-repeat;
    background-position-x:54px;
    display:inline-block;
    text-align:center;
    vertical-align:top;
    margin-top:10px;
    padding-top:160px;
    overflow:hidden;
  }
  .result-content{
    height:1180px;
    width: 696px;
    margin: auto;
    left:0;
    right:0px;
    color:white;
    padding:0 45px;
    text-align:left;
    overflow:auto;
  }
  .back{
    height:67px;
    width: 224px;
    background:url("../../assets/img/common/bg_button_normal.png") no-repeat;
    margin: auto;
    left:0;
    right:0;
    margin-top:20px;
    color:#FFFFFF;
    font-size:37px;
    text-align:center;
    line-height:70px;
  }
  .resultM{
    height:1600px;
    width: 100%;
    color:#FFFFFF;
    font-size:24px;
    background:url("../../assets/img/common/bjk_phsz_icon.png") no-repeat;
    background-position-x:54px;
    display:inline-block;
    text-align:center;
    vertical-align:top;
    margin-top:57px;
    padding-top:160px;
  }

</style>
