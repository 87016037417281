<template>
  <div v-if="marker === '10.1'">
    <div class="tongueresult-pulse" :style="printstatus === '1'?{'marginLeft':'130px'}:{'marginLeft':'30px'}" v-if="pulse">
      <img :src="tongue_photo_url" style="width: 112px;height: 150px;">
      <div @click="ReturnToTongue" class="tongue-right-pulse">
        <span>查看舌诊</span><br />
        <span>分析结果</span><br />
        <img :src="ckmzjg">
      </div>
    </div>
    <div class="tongueresult" :style="printstatus === '1'?{'marginLeft':'130px'}:{'marginLeft':'30px'}" v-else>
      <img :src="tongue_photo_url" style="width: 115px;height: 150px;">
      <div @click="ReturnToTongue" class="tongue-right">
        <span>查看舌诊</span><br />
        <span>分析结果</span><br />
        <img :src="ckmzjg">
      </div>
    </div>
  </div>
  <div v-else>
    <div class="tongueresult-pulse" :style="printstatus === '1'?{'marginLeft':'130px'}:{'marginLeft':'30px'}" v-if="pulse">
      <img :src="tongue_photo_url" style="width: 85px;height: 150px;">
      <div @click="ReturnToTongue" class="tongued-right-pulse">
        <span>查看舌诊</span><br />
        <span>分析结果</span><br />
        <img :src="ckmzjg">
      </div>
    </div>
    <div class="tongueresult" :style="printstatus === '1'?{'marginLeft':'130px'}:{'marginLeft':'30px'}" v-else>
      <img :src="tongue_photo_url" style="width: 85px;height: 150px;">
      <div @click="ReturnToTongue" class="tongued-right">
        <span>查看舌诊</span><br />
        <span>分析结果</span><br />
        <img :src="ckmzjg">
      </div>
    </div>
  </div>
</template>

<script>
  import {queryObject} from "../../../../utils/common";
  import ckmzjg from '../../../assets/img/report/ckmzjg_jcjg_icon.png';

  const { printstatus = '', marker = '' } = queryObject();
  export default {
    name: "tongueresult",
    props: {
      tongue_photo_url: String,
      result:Object,
      report:Object,
      pulse:Boolean,
    },
    data(){
      return{
        ckmzjg:ckmzjg,
      }
    },
    computed:{
      marker(){
        return marker
      },
      printstatus(){
        return printstatus
      }
    },
    methods:{
      ReturnToTongue(){
        this.$router.push({
          name:'Tonguereport',
          params: {
            tonguereport: this.result,
            tongue_photo_url:this.tongue_photo_url,
            id:this.$route.params.id,
            report:this.report,
            isFromReportHome: true
          }
        })
      }
    }
  }
</script>

<style scoped>
  .tongueresult{
    width: 262px;
    height: 150px;
    float: left;
    margin-left: 30px;
  }
  .tongueresult-pulse{
    width: 214px;
    height: 150px;
    float:left;
    margin-left:31px;
  }
  .tongue-right{
    height: 127px;
    width: 147px;
    color: #ffffff;
    font-size: 24px;
    background: url("../../../assets/img/report/mzjgbj_jcjg_icon.png");
    display: inline-block;
    text-align: center;
    vertical-align: top;
    padding: 12px 0;
    background-size: 100% 100%;
    line-height: 35px;
  }
  .tongued-right{
    height: 108px;
    width: 172px;
    color: #ffffff;
    font-size: 24px;
    background: url("../../../assets/img/report/mzjgbj_jcjg_icon.png");
    display: inline-block;
    text-align: center;
    vertical-align: top;
    padding: 21px 0;
    background-size: 100% 100%;
    line-height: 35px;
  }
  .tongue-right-pulse{
    height: 108px;
    width: 102px;
    color: #ffffff;
    font-size: 22px;
    background: url("../../../assets/img/report/mzjgbj10.1_jcjg_icon.png");
    display: inline-block;
    text-align: center;
    vertical-align: top;
    padding: 21px 0;
    background-size: 100% 100%;
    line-height: 35px;
  }
  .tongued-right-pulse{
    height: 127px;
    width: 129px;
    color: #ffffff;
    font-size: 22px;
    background: url("../../../assets/img/report/mzjgbj10.1_jcjg_icon.png");
    display: inline-block;
    text-align: center;
    vertical-align: top;
    padding: 12px 0;
    background-size: 100% 100%;
    line-height: 35px;
  }

</style>
