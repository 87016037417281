<template>
  <div class="face">
    <header>一、面诊分析结果</header>
    <div class="faceTable">
      <table>
        <tr>
          <td rowspan="6" class="faceImg">
            <img :src="url" alt="面诊图片" />
          </td>
          <td rowspan="2" class="faceTitle">面色</td>
          <td>
            <div class="circle">
              <div>
                <p class="circleL" :style="{backgroundImage: `url(${l_bg})`}">{{faceColor.L}}</p>
                <p class="circleName">L值</p>
              </div>
              <div>
                <p class="circleA" :style="{backgroundImage: `url(${a_bg})`}">{{faceColor.A}}</p>
                <p class="circleName">A值</p>
              </div>
              <div>
                <p class="circleB" :style="{backgroundImage: `url(${b_bg})`}">{{faceColor.B}}</p>
                <p class="circleName">B值</p>
              </div>
            </div>
            <p class="guide"> L值越大面色越亮、有光泽，a值越大越红，b值越大越青黑</p>
          </td>
        </tr>
        <tr>
          <td class="conclusion">
            <p>结果 ：{{result['face_color_info'].name}}</p>
            <p>提示 ：{{result['face_color_info'].content[0] === '正常' ? '' : '常见于'}}{{result['face_color_info'].content[0]}}</p>
          </td>
        </tr>
        <tr>
          <td rowspan="2" class="faceTitle">唇色</td>
          <td>
            <div class="circle">
              <div>
                <p class="circleL" :style="{backgroundImage: `url(${l_bg})`}">{{faceColor.lipL}}</p>
                <p class="circleName">L值</p>
              </div>
              <div>
                <p class="circleA" :style="{backgroundImage: `url(${a_bg})`}">{{faceColor.lipA}}</p>
                <p class="circleName">A值</p>
              </div>
              <div>
                <p class="circleB" :style="{backgroundImage: `url(${b_bg})`}">{{faceColor.lipB}}</p>
                <p class="circleName">B值</p>
              </div>
            </div>
            <p class="guide">L值越大面色越亮、有光泽，a值越大越淡，b值越大越紫暗</p>
          </td>
        </tr>
        <tr>
          <td class="conclusion">
            <p>结果 ：{{result['lip_color_info'] ? result['lip_color_info'].name : '未识别到唇'}}</p>
            <p>提示 ：{{result['lip_color_info'] ? result['lip_color_info'].content[0] === '正常' ? '' : '常见于' : ''}}{{result['lip_color_info'] ? result['lip_color_info'].content[0] : ''}}</p>
          </td>
        </tr>
        <tr>
          <td rowspan="2" class="faceTitle">面部光泽</td>
          <td>
            <div class="circle">
              <div>
                <p class="circleG" :style="{backgroundImage: `url(${g_bg})`}">{{faceColor.Gzzs}}</p>
                <p class="circleName">面部光泽指数</p>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="conclusion">
            <p>结果 ：{{result['face_gloss_info'].name}}</p>
            <p>提示 ：{{result['face_gloss_info'].content[0] === '正常' ? '' : '常见于'}}{{result['face_gloss_info'].content[0]}}</p>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
  import l_bg from '@/assets/img/print/L.png';
  import a_bg from '@/assets/img/print/A.png';
  import b_bg from '@/assets/img/print/B.png';
  import g_bg from '@/assets/img/print/mbgz.png';

  export default {
    name: "faceResult",
    props: {
      result: Object,
      url: String
    },
    data(){
      return {
        l_bg,
        a_bg,
        b_bg,
        g_bg
      }
    },
    computed:{
      faceColor(){
        const faceObj = {};
        faceObj.L = (this.result.face_L / 255).toFixed(2);
        faceObj.A = (this.result.face_a / 255).toFixed(2);
        faceObj.B = (this.result.face_b / 255).toFixed(2);
        faceObj.lipL = (this.result.lip_L / 255).toFixed(2);
        faceObj.lipA = (this.result.lip_a / 255).toFixed(2);
        faceObj.lipB = (this.result.lip_b / 255).toFixed(2);
        faceObj.Gzzs = this.result['gloss_indicator'].toFixed(2);
        return faceObj;
      }
    }
  }
</script>

<style scoped>

  header {
    font-size: 16px;
    line-height: 33px;
    color: #048AD7;
    font-weight: bold;
    margin: 33px 0;
  }

  .faceTable {
    padding-bottom: 50px;
  }

  table {
    border-collapse:collapse;
    /*color: rgb(204, 204, 204);*/
    font-size: 20px;
    margin-bottom: 50px;
  }

  table,
  tr,
  td {
    border: 2px solid rgb(204, 204, 204);
  }

  .faceImg {
    width: 32%;
    text-align: center;
  }

  .faceTitle {
    width: 18%;
    text-align: center;
  }

  .faceImg img {
    display: block;
    width: 70%;
    margin: 0 auto;
  }

  .circle {
    display: flex;
    justify-content: space-evenly;
    margin-top: 3px;
  }

  .circle>div {
    width: 100%;
  }

  .circle p {
    text-align: center;
  }

  .guide {
    text-align:center;
    color:#4BB9FF;
    font-size:10px;
    line-height:10px;
    padding: 10px 0 20px 0;
  }

  .circleL,
  .circleA,
  .circleB,
  .circleG {
    height: 46px;
    width: 100%;
    font-size: 12px;
    color: #666666;
    line-height: 46px;
    background-size: auto 46px;
    background-repeat: no-repeat;
    background-position-x: center;
  }

  .circleName {
    color:#666666;
    font-size:8px;
    line-height: 40px;
    height: 40px;
  }

  .conclusion {
    color: rgb(51, 51, 51);
    font-size: 12px;
    line-height: 15px;
  }

  .conclusion>p {
    padding: 10px 0 10px 10px;
  }


</style>