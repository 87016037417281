var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.marker === "10.1"
    ? _c("div", { staticClass: "detail" }, [
        _vm._m(0),
        _c("div", { staticClass: "result" }, [
          _c(
            "div",
            { staticClass: "result-content", staticStyle: { height: "980px" } },
            [
              _c(
                "p",
                {
                  staticStyle: {
                    "font-size": "30px",
                    "line-height": "54px",
                    "text-indent": "65px"
                  }
                },
                [
                  _vm._v(
                    " 上海祉云医疗科技有限公司是一家专注于互联网+智慧中医健康服务的高科技型企业。上海祉云以中医+云计算为基础，不断应用新技术提升中医健康服务水平。汇聚上海中医药大学、复旦大学等国内优质资源，专注于中医智能化健康服务产品的研发 。 "
                  )
                ]
              ),
              _c("div", { staticStyle: { "margin-top": "20px" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "50%",
                      float: "left",
                      position: "relative"
                    }
                  },
                  [
                    _c("div", { staticStyle: { height: "299px" } }, [
                      _c("img", {
                        staticStyle: {
                          float: "right",
                          "margin-right": "40px",
                          width: "214px"
                        },
                        attrs: { src: _vm.patent3 }
                      })
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "14px",
                          width: "180px",
                          "margin-top": "10px",
                          "margin-left": "120px"
                        }
                      },
                      [_vm._v(" 中医面诊分析与诊断系统 ")]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "50%",
                      float: "left",
                      position: "relative"
                    }
                  },
                  [
                    _c("div", { staticStyle: { height: "299px" } }, [
                      _c("img", {
                        staticStyle: { "margin-left": "40px", width: "214px" },
                        attrs: { src: _vm.patent2 }
                      })
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "14px",
                          width: "180px",
                          "margin-top": "10px",
                          "margin-left": "54px",
                          "text-align": "center"
                        }
                      },
                      [
                        _vm._v(
                          " 一种基于中医望诊的“神”的特征提取与分类方法及系统 "
                        )
                      ]
                    )
                  ]
                )
              ]),
              _c(
                "p",
                {
                  staticStyle: {
                    "font-size": "30px",
                    "line-height": "54px",
                    "text-indent": "65px"
                  }
                },
                [
                  _vm._v(
                    "公司基于上海中医药大学专利技术----云中医APP和祉云中医智能镜（基于中医智慧健康状态监测与管理系统开发的应用产品），致力于利用大数据、云计算、人工智能等现代信息技术，打造中医智能健康管理平台，发挥中医药的独特优势，在健康管理、慢病管理、孕产妇保健、儿童调制等方面拓展中医药服务，为健康中国建设提供服务。可大大提升中医服务的公平性、易用性和可及性。"
                  )
                ]
              ),
              _c("div", { staticStyle: { "margin-top": "20px" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "50%",
                      float: "left",
                      position: "relative"
                    }
                  },
                  [
                    _c("div", { staticStyle: { height: "299px" } }, [
                      _c("img", {
                        staticStyle: {
                          float: "right",
                          "margin-right": "40px",
                          width: "214px"
                        },
                        attrs: { src: _vm.patent1 }
                      })
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "14px",
                          width: "180px",
                          "margin-top": "10px",
                          "margin-left": "110px",
                          "text-align": "center"
                        }
                      },
                      [_vm._v(" 一种健康状态监测管理方法及智能镜 ")]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "50%",
                      float: "left",
                      position: "relative"
                    }
                  },
                  [
                    _c("div", { staticStyle: { height: "299px" } }, [
                      _c("img", {
                        staticStyle: { "margin-left": "40px", width: "214px" },
                        attrs: { src: _vm.software }
                      })
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "14px",
                          width: "180px",
                          "margin-top": "10px",
                          "margin-left": "54px",
                          "text-align": "center"
                        }
                      },
                      [_vm._v(" 云中医健康管理系统1.0(简称云中医) ")]
                    )
                  ]
                )
              ]),
              _vm._m(1)
            ]
          ),
          _c("div", { staticStyle: { "margin-top": "20px" } }, [
            _c("div", { staticClass: "back", on: { click: _vm.continue1 } }, [
              _vm._v(" 继 续 ")
            ])
          ])
        ])
      ])
    : _c("div", { staticClass: "detail" }, [
        _vm._m(2),
        _c("div", { staticClass: "resultM" }, [
          _c("div", { staticClass: "result-content" }, [
            _c(
              "p",
              {
                staticStyle: {
                  "font-size": "30px",
                  "line-height": "54px",
                  "text-indent": "65px"
                }
              },
              [
                _vm._v(
                  " 上海祉云医疗科技有限公司是一家专注于互联网+智慧中医健康服务的高科技型企业。上海祉云以中医+云计算为基础，不断应用新技术提升中医健康服务水平。汇聚上海中医药大学、复旦大学等国内优质资源，专注于中医智能化健康服务产品的研发 。 "
                )
              ]
            ),
            _c("div", { staticStyle: { "margin-top": "20px" } }, [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "50%",
                    float: "left",
                    position: "relative"
                  }
                },
                [
                  _c("div", { staticStyle: { height: "299px" } }, [
                    _c("img", {
                      staticStyle: {
                        float: "right",
                        "margin-right": "40px",
                        width: "214px"
                      },
                      attrs: { src: _vm.patent3 }
                    })
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        width: "180px",
                        "margin-top": "10px",
                        "margin-left": "120px"
                      }
                    },
                    [_vm._v(" 中医面诊分析与诊断系统 ")]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "50%",
                    float: "left",
                    position: "relative"
                  }
                },
                [
                  _c("div", { staticStyle: { height: "299px" } }, [
                    _c("img", {
                      staticStyle: { "margin-left": "40px", width: "214px" },
                      attrs: { src: _vm.patent2 }
                    })
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        width: "180px",
                        "margin-top": "10px",
                        "margin-left": "54px",
                        "text-align": "center"
                      }
                    },
                    [
                      _vm._v(
                        " 一种基于中医望诊的“神”的特征提取与分类方法及系统 "
                      )
                    ]
                  )
                ]
              )
            ]),
            _c(
              "p",
              {
                staticStyle: {
                  "font-size": "30px",
                  "line-height": "54px",
                  "text-indent": "65px"
                }
              },
              [
                _vm._v(
                  "公司基于上海中医药大学专利技术----云中医APP和祉云中医智能镜（基于中医智慧健康状态监测与管理系统开发的应用产品），致力于利用大数据、云计算、人工智能等现代信息技术，打造中医智能健康管理平台，发挥中医药的独特优势，在健康管理、慢病管理、孕产妇保健、儿童调制等方面拓展中医药服务，为健康中国建设提供服务。可大大提升中医服务的公平性、易用性和可及性。"
                )
              ]
            ),
            _c("div", { staticStyle: { "margin-top": "20px" } }, [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "50%",
                    float: "left",
                    position: "relative"
                  }
                },
                [
                  _c("div", { staticStyle: { height: "299px" } }, [
                    _c("img", {
                      staticStyle: {
                        float: "right",
                        "margin-right": "40px",
                        width: "214px"
                      },
                      attrs: { src: _vm.patent1 }
                    })
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        width: "180px",
                        "margin-top": "10px",
                        "margin-left": "110px",
                        "text-align": "center"
                      }
                    },
                    [_vm._v(" 一种健康状态监测管理方法及智能镜 ")]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "50%",
                    float: "left",
                    position: "relative"
                  }
                },
                [
                  _c("div", { staticStyle: { height: "299px" } }, [
                    _c("img", {
                      staticStyle: { "margin-left": "40px", width: "214px" },
                      attrs: { src: _vm.software }
                    })
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        width: "180px",
                        "margin-top": "10px",
                        "margin-left": "54px",
                        "text-align": "center"
                      }
                    },
                    [_vm._v(" 云中医健康管理系统1.0(简称云中医) ")]
                  )
                ]
              )
            ]),
            _vm._m(3)
          ]),
          _c("div", { staticStyle: { "margin-top": "20px" } }, [
            _c("div", { staticClass: "back", on: { click: _vm.continue1 } }, [
              _vm._v(" 继 续 ")
            ])
          ])
        ])
      ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "boxsty",
        staticStyle: {
          display: "block",
          height: "110px",
          margin: "93px 0 0 0",
          width: "80%",
          overflow: "auto"
        }
      },
      [
        _c("span", { staticClass: "title" }, [_vm._v("关于我们")]),
        _c("span", { staticClass: "border" })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticStyle: { "font-size": "30px", "line-height": "54px" } },
      [
        _vm._v(" 发明专利和软件著作权"),
        _c("br"),
        _vm._v(
          " ● 一种基于中医望诊的“神”的特征提取与分类方法及系统，申请号201510010231.5"
        ),
        _c("br"),
        _vm._v(
          " ● 一种健康状态监测管理智能镜，已授权，专利号：ZL201520303463.5"
        ),
        _c("br"),
        _vm._v(" ● 中医面诊分析与诊断系统，已授权，专利号ZL201210147003.9"),
        _c("br"),
        _vm._v(" ● 云中医健康管理系统1.0(简称云中医)，著作权号：2016SR079255"),
        _c("br"),
        _c("br"),
        _vm._v(" 联系方式"),
        _c("br"),
        _vm._v(" 公司名称：上海祉云医疗科技有限公司"),
        _c("br"),
        _vm._v(" 公司地址：上海市浦东新区张衡路666弄2号楼101室"),
        _c("br"),
        _vm._v(" 邮件地址：zhiyun@zhiyuntcm.com"),
        _c("br"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "boxsty",
        staticStyle: {
          display: "block",
          height: "110px",
          margin: "95px 0 0 0",
          width: "80%",
          overflow: "auto"
        }
      },
      [
        _c("span", { staticClass: "title" }, [_vm._v("关于我们")]),
        _c("span", { staticClass: "border" })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticStyle: { "font-size": "30px", "line-height": "54px" } },
      [
        _vm._v(" 发明专利和软件著作权"),
        _c("br"),
        _vm._v(
          " ●一种基于中医望诊的“神”的特征提取与分类方法及系统，申请号201510010231.5"
        ),
        _c("br"),
        _vm._v(
          " ●一种健康状态监测管理智能镜，已授权，专利号：ZL201520303463.5"
        ),
        _c("br"),
        _vm._v(" ●中医面诊分析与诊断系统，已授权，专利号ZL201210147003.9"),
        _c("br"),
        _vm._v(" ●云中医健康管理系统1.0(简称云中医)，著作权号：2016SR079255"),
        _c("br"),
        _c("br"),
        _vm._v(" 联系方式"),
        _c("br"),
        _vm._v(" 公司名称：上海祉云医疗科技有限公司"),
        _c("br"),
        _vm._v(" 公司地址：上海市浦东新区张衡路666弄2号楼101室"),
        _c("br"),
        _vm._v(" 邮件地址：zhiyun@zhiyuntcm.com"),
        _c("br"),
        _c("br")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }