var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.reportData
    ? _c(
        "div",
        { staticClass: "wxtzbs" },
        [
          _c("wxtzheader", {
            attrs: {
              result: _vm.reportData.result,
              attention: _vm.reportData.physical_attention,
              displayId: _vm.reportData.display_id,
              time: _vm.reportData.time,
              healthScore: _vm.reportData.health_score
            }
          }),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("wxinfo", {
                attrs: {
                  info: _vm.reportData.user_info,
                  owner: _vm.reportData.owner
                }
              }),
              _c("wxtzparsing", {
                attrs: {
                  parsing: _vm.reportData.solutions.tizhi_definition,
                  result: _vm.reportData.result
                }
              }),
              _c("wxhealthytip", {
                attrs: {
                  risk: _vm.reportData.solutions.tizhi_health_risk,
                  result: _vm.reportData.result
                }
              }),
              _c("wxviscerachart", {
                attrs: {
                  viscera: _vm.reportData.physical_viscera[0],
                  result: _vm.reportData.result
                }
              }),
              _c("wxtermschart", {
                attrs: {
                  terms: _vm.reportData.physical_term[0],
                  result: _vm.reportData.result
                }
              }),
              _c("wxfaceresult", {
                attrs: {
                  faceData: _vm.reportData.face_result,
                  facePhotoUrl: _vm.reportData.face_photo_url
                }
              }),
              _c("wxtongueresult", {
                attrs: {
                  tongueData: _vm.reportData.tongue_result,
                  tonguePhotoUrl: _vm.reportData.tongue_photo_url
                }
              }),
              _vm.reportData.pulse.length !== 0
                ? _c("wxpulseresult", {
                    attrs: { pulse: _vm.reportData.pulse }
                  })
                : _vm._e(),
              _vm.reportData.pulse.length !== 0 &&
              _vm.reportData.pulse[0].blood_dia !== 0 &&
              _vm.reportData.pulse[0].blood_sys !== 0
                ? _c("wxbloodpressure", {
                    attrs: { pulse: _vm.reportData.pulse }
                  })
                : _vm._e(),
              _c("wxtzscoreschart", {
                attrs: {
                  result: _vm.reportData.result,
                  historyReports: _vm.reportData.history_reports,
                  attention: _vm.reportData.physical_attention
                }
              }),
              _c("wxhealthplan", { attrs: { reportData: _vm.reportData } }),
              _c("div", { staticClass: "tip" }, [
                _vm._v("此报告仅作参考，如有不适请及时就医")
              ]),
              _vm.reportData.doctor.length !== 0
                ? _c("wxdoctorlist", {
                    attrs: { doctorData: _vm.reportData.doctor }
                  })
                : _vm._e(),
              _vm.reportData.product.length !== 0
                ? _c("wxproduct", {
                    attrs: { productData: _vm.reportData.product }
                  })
                : _vm._e(),
              _c("div", { staticClass: "tip" })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }