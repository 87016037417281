var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex", overflow: "auto", height: "100%" } },
    [
      _c(
        "div",
        {
          staticClass: "labdetail",
          style:
            _vm.access === "web"
              ? { height: "1920px", paddingLeft: "52px" }
              : { paddingLeft: "52px" }
        },
        [
          _c("div", { staticStyle: { height: "314px" } }, [
            _c("img", {
              staticClass: "back",
              staticStyle: { left: "-2px" },
              attrs: { src: _vm.fh },
              on: { click: _vm.returnto }
            }),
            _c("span", { staticClass: "title" }, [
              _vm._v("Lab颜色空间及相关数值意义")
            ])
          ]),
          _c("div", { staticClass: "labdetail-content" }, [
            _vm._v(" " + _vm._s(_vm.report.tongue_result.lab_define) + " ")
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }