var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-bg" },
    [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "back-btn", on: { click: _vm.goBack } }, [
          _vm._v("返 回")
        ]),
        _c("div", { staticClass: "header-title" }, [_vm._v("养生方案")])
      ]),
      _c("header-tab", {
        attrs: { buttonType: _vm.buttonType },
        on: { changed: _vm.changedIndex }
      }),
      _vm.currentIndex === 0 ? _c("header-tab-one") : _vm._e(),
      _vm.currentIndex === 1
        ? _c("header-tab-two", {
            attrs: {
              Img: _vm.teaImg,
              SymptomsName: _vm.symptomsName,
              TextContent: _vm.tea
            }
          })
        : _vm._e(),
      _vm.currentIndex === 2
        ? _c("header-tab-two", {
            attrs: { XnContent: _vm.xnContent, filedName: "XnContent" }
          })
        : _vm._e(),
      _vm.currentIndex === 3 && _vm.symptomsName !== "平和质"
        ? _c("header-tab-two", {
            attrs: {
              filedName: "PrescriptionContent",
              PrescriptionContent: _vm.yf
            }
          })
        : _vm._e(),
      _vm.currentIndex === 4 ? _c("header-tab-three") : _vm._e(),
      _vm.currentIndex === 5
        ? _c("header-tab-two", {
            attrs: { TitleName: "精神调养", Detail: _vm.factorDetail }
          })
        : _vm._e(),
      _vm.currentIndex === 6
        ? _c("header-tab-two", {
            attrs: { TitleName: "起居调摄", Detail: _vm.dailyDetail }
          })
        : _vm._e(),
      _vm.currentIndex === 7
        ? _c("header-tab-two", {
            attrs: {
              TitleName: "运动保健",
              Detail: _vm.sportDetail,
              playerOptions: _vm.playerOptions
            }
          })
        : _vm._e(),
      _vm.currentIndex === 8
        ? _c("header-tab-two", { attrs: { isProduct: true } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }