<template>
<!--  含脉诊 -->
  <div class="knowledg-pulse" :style="printstatus === '1'?{'marginLeft':'130px'}:{'marginLeft':'33px'}" v-if="pulse">
    <div v-if="is_print === 1">
      <div v-if="marker === '10.1'">
        <div class="zskyj-pulse" @click="returnToKnow"></div>
        <div class="dybg-pulse" @click="doPrint"></div>
      </div>
      <div v-else>
        <div class="dybg-pulse" @click="doPrint"></div>
      </div>
    </div>
    <div class="noprint"v-if="is_print !== 1 && marker === '10.1'" @click="returnToKnow">
      <img src="../../../assets/img/report/bg10.1_jcjg_icon.png" alt="知识库依据" style="margin-top: 2px;width: 90px;height: 90px;border-radius: 20px;">
      <div style="color: white;font-size: 24px;">知识库依据</div>
    </div>
  </div>
<!--  不含脉诊 -->
  <div class="knowledg" :style="printstatus === '1'?{'marginLeft':'130px'}:{'marginLeft':'30px'}" v-else>
    <div v-if="is_print === 1">
      <div v-if="marker === '10.1'">
        <div class="zskyj" @click="returnToKnow"></div>
        <div class="dybg" @click="doPrint"></div>
      </div>
      <div v-else>
        <div class="dybg" @click="doPrint"></div>
      </div>
    </div>
    <div v-if="is_print !== 1 && marker === '10.1'" class="noprint" @click="returnToKnow">
        <img src="../../../assets/img/report/bg10.1_jcjg_icon.png" alt="知识库依据" style="margin-top: 2px;width: 106px;height: 106px;border-radius: 20px;">
        <div style="color: white;font-size: 24px;">知识库依据</div>
    </div>
  </div>

</template>

<script>
  import {queryObject} from "../../../../utils/common";
  const { printstatus = '',csr = '', marker = ''  } = queryObject();
  export default {
    name: "knowledg",
    props: {
      // shareUrl: String,
      // key:String,
      report:Object,
      is_print:Number,
      // csr:String,
      pulse:Boolean,
    },
    data(){
      return {
        marker
      }
    },
    computed:{
      printstatus(){
        return printstatus
      },
      csr(){
        return csr
      }
    },
    methods:{
      returnToKnow(){
        this.$router.push({name:'Knowledgebase',params: {id:this.$route.params.id}})
      },
      doPrint(){
        const url = csr ? 'https://'+window.location.host+'/?csr='+csr+'&is_print=1#/report/'+this.report.id : 'https://'+window.location.host+'/?is_print=1#/report/'+this.report.id;
        console.log(url)
        if (window.zhiyun) {
          window.zhiyun.print(url);
        } else if (window.print) {
          window.print(url);
        }
      }
    }
  }
</script>

<style scoped>
  .knowledg-pulse{
    width: 162px;
    height: 150px;
    margin-left:30px;
    float:left;
  }
  .knowledg{
    width: 211px;
    height: 150px;
    margin-left: 30px;
    float: left;
  }
  .zskyj{
    width: 100%;
    height: 61px;
    background: url("../../../assets/img/report/zskyj_jcjg_icon.png") no-repeat;
    background-size: 100%;
  }
  .dybg{
    width: 100%;
    height: 61px;
    background: url("../../../assets/img/report/dybg_jcjg_icon.png") no-repeat;
    background-size: 100%;
    margin-top: 27px;
  }
  .zskyj-pulse{
    width:100%;
    height:61px;
    background:url("../../../assets/img/report/zskyj_jcjg_icon_pulse.png") no-repeat;
    background-size:100%;
  }
  .dybg-pulse{
    width:100%;
    height:61px;
    background:url("../../../assets/img/report/dybg_jcjg_icon_pulse.png") no-repeat;
    background-size:100%;
    margin-top:27px;
  }
  .noprint{
    width:100%;
    height:100%;
    background:url("../../../assets/img/report/zjkbj_jcjg_icon.png") no-repeat;
    background-size:100% 100%;
    text-align:center;
  }

</style>
