var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.pulse
    ? _c("div", { staticClass: "qrcode-pulse" }, [
        _vm.csr === "HUASHI" ||
        _vm.csr === "SHBAZE" ||
        _vm.csr === "CHENSHANG" ||
        _vm.csr === "SHWYSLSWKJ" ||
        _vm.csr === "SXSZYYY"
          ? _c("div", { staticClass: "column" }, [
              _vm.marker === "10.1" &&
              _vm.myallreport === "1" &&
              _vm.type !== "personal"
                ? _c("div", {
                    staticClass: "allreport",
                    on: {
                      click: function($event) {
                        return _vm.myreport(
                          _vm.report.owner.cellphone,
                          _vm.report.id
                        )
                      }
                    }
                  })
                : _c("div", { staticClass: "code-wrapper" }, [
                    this.shareUrl
                      ? _c("img", {
                          staticStyle: { width: "86px", height: "86px" },
                          attrs: { src: _vm.code }
                        })
                      : _vm._e()
                  ]),
              _c("div", { staticClass: "info-pulse" }, [
                _vm._v(
                  _vm._s(
                    _vm.marker === "10.1" &&
                      _vm.myallreport === "1" &&
                      _vm.type !== "personal"
                      ? "历史报告"
                      : "微信扫码带走报告"
                  )
                )
              ])
            ])
          : _c("div", { staticClass: "column" }, [
              _vm.marker === "10.1" &&
              _vm.myallreport === "1" &&
              _vm.type !== "personal"
                ? _c("div", {
                    staticClass: "allreport",
                    on: {
                      click: function($event) {
                        return _vm.myreport(
                          _vm.report.owner.cellphone,
                          _vm.report.id
                        )
                      }
                    }
                  })
                : _c(
                    "div",
                    { staticClass: "code-wrappered" },
                    [
                      _vm.report.vendor_setting.qr_code_show === 2
                        ? _c("img", {
                            staticStyle: { width: "86px", height: "86px" },
                            attrs: { src: _vm.report.qr_url },
                            on: { click: _vm.showExplain }
                          })
                        : this.shareUrl &&
                          _vm.report.vendor_setting.qr_code_show === 1
                        ? _c("qrcode-vue", {
                            attrs: { value: _vm.url, size: "86" },
                            on: { click: _vm.showExplain }
                          })
                        : this.shareUrl &&
                          _vm.report.vendor_setting.qr_code_show === 0
                        ? _c("img", {
                            staticStyle: { width: "86px", height: "86px" },
                            attrs: { src: _vm.code },
                            on: { click: _vm.showExplain }
                          })
                        : _c("div"),
                      _vm._v("8 ")
                    ],
                    1
                  ),
              _c("div", { staticClass: "info-pulse" }, [
                _vm._v(
                  _vm._s(
                    _vm.marker === "10.1" &&
                      _vm.myallreport === "1" &&
                      _vm.type !== "personal"
                      ? "历史报告"
                      : "微信扫码带走报告"
                  )
                )
              ])
            ])
      ])
    : _c("div", { staticClass: "qrcode" }, [
        _vm.csr === "HUASHI" ||
        _vm.csr === "SHBAZE" ||
        _vm.csr === "CHENSHANG" ||
        _vm.csr === "SHWYSLSWKJ" ||
        _vm.csr === "SXSZYYY"
          ? _c("div", { staticClass: "column" }, [
              _vm.marker === "10.1" &&
              _vm.myallreport === "1" &&
              _vm.type !== "personal"
                ? _c("div", {
                    staticClass: "allreport",
                    on: {
                      click: function($event) {
                        return _vm.myreport(
                          _vm.report.owner.cellphone,
                          _vm.report.id
                        )
                      }
                    }
                  })
                : _c("div", { staticClass: "code-wrapper" }, [
                    this.shareUrl
                      ? _c("img", {
                          staticStyle: { width: "86px", height: "86px" },
                          attrs: { src: _vm.code }
                        })
                      : _vm._e()
                  ]),
              _c("div", { staticClass: "info" }, [
                _vm._v(
                  _vm._s(
                    _vm.marker === "10.1" &&
                      _vm.myallreport === "1" &&
                      _vm.type !== "personal"
                      ? "历史报告"
                      : "微信扫码带走报告"
                  )
                )
              ])
            ])
          : _c("div", { staticClass: "column" }, [
              _vm.marker === "10.1" &&
              _vm.myallreport === "1" &&
              _vm.type !== "personal"
                ? _c("div", {
                    staticClass: "allreport",
                    on: {
                      click: function($event) {
                        return _vm.myreport(
                          _vm.report.owner.cellphone,
                          _vm.report.id
                        )
                      }
                    }
                  })
                : _c(
                    "div",
                    { staticClass: "code-wrappered" },
                    [
                      _vm.report.vendor_setting.qr_code_show === 2
                        ? _c("img", {
                            staticStyle: { width: "86px", height: "86px" },
                            attrs: { src: _vm.report.qr_url },
                            on: { click: _vm.showExplain }
                          })
                        : this.shareUrl &&
                          _vm.report.vendor_setting.qr_code_show === 1
                        ? _c("qrcode-vue", {
                            attrs: { value: _vm.url, size: "86" },
                            on: { click: _vm.showExplain }
                          })
                        : this.shareUrl &&
                          _vm.report.vendor_setting.qr_code_show === 0
                        ? _c("img", {
                            staticStyle: { width: "86px", height: "86px" },
                            attrs: { src: _vm.code },
                            on: { click: _vm.showExplain }
                          })
                        : _c("div")
                    ],
                    1
                  ),
              _c("div", { staticClass: "info" }, [
                _vm._v(
                  _vm._s(
                    _vm.marker === "10.1" &&
                      _vm.myallreport === "1" &&
                      _vm.type !== "personal"
                      ? "历史报告"
                      : "微信扫码带走报告"
                  )
                )
              ])
            ]),
        _vm.flag
          ? _c("div", [
              _c("div", { staticClass: "frame" }, [
                _c(
                  "div",
                  { staticClass: "spantitle" },
                  [
                    _vm.report.vendor_setting.qr_code_show === 2
                      ? _c("img", {
                          staticStyle: { width: "450px", height: "450px" },
                          attrs: { src: _vm.report.qr_url }
                        })
                      : this.shareUrl &&
                        _vm.report.vendor_setting.qr_code_show === 1
                      ? _c("qrcode-vue", {
                          attrs: { value: _vm.url, size: "450" }
                        })
                      : this.shareUrl &&
                        _vm.report.vendor_setting.qr_code_show === 0
                      ? _c("img", {
                          staticStyle: { width: "450px", height: "450px" },
                          attrs: { src: _vm.code }
                        })
                      : _vm._e(),
                    _c("div", {
                      staticClass: "fh",
                      on: { click: _vm.showExplain }
                    })
                  ],
                  1
                )
              ])
            ])
          : _vm._e()
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }