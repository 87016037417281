<template>
  <div style="display: flex;overflow: auto;height: 100%">
    <div class="labdetail" :style="access === 'web' ? { height: '1920px', paddingLeft: '52px' } : { paddingLeft: '52px' }">
      <div style="height: 314px">
        <img :src="fh" @click="returnto" class="back" style="left: -2px">
        <span class="title">Lab颜色空间及相关数值意义</span>
      </div>
      <div class="labdetail-content">
        {{report.tongue_result.lab_define}}
      </div>
    </div>
  </div>


</template>

<script>
  import fh from '../../assets/img/report/fh_icon.png';
  import { queryObject } from '../../../utils/common.js';
  const { access = '' } = queryObject();
  export default {
    name: "labdetail",
    data(){
      return {
        report:'',
        fh,
      }
    },
    computed: {
      access(){
        return access
      }
    },
    methods:{
      returnto(){
        this.$router.back();
      }
    },
    beforeMount(){
      const { report } = this.$store.state;
      this.report = report;
    }
  }
</script>

<style scoped>
  .labdetail{
    width:100%;
    height:100%;
    z-index:90;
    overflow:hidden;
    background:url("../../assets/img/report/bj_sznr_icon.png");
  }
  ::-webkit-scrollbar{
    display: none;
  }
  .back{
    position:relative;
    left:49px;
    top:105px;
    width: 152px;
    height: 82px;
  }
  .title{
    font-size: 48px;
    color:#00FEFB;
    font-family:TITLE;
    width:666px;
    height:111px;
    background:url("../../assets/img/report/jssm_icon.png") no-repeat;
    text-align:center;
    line-height:111px;
    background-size:100% 100%;
    position: absolute;
    left: 0;
    top: 83px;
    right: 0;
    margin: 0 auto;
  }
  .labdetail-content{
    position:relative;
    width:838px;
    height:605px;
    background:url("../../assets/img/report/jssmbj_icon.png") no-repeat;
    background-size:100% 100%;
    margin-top:50px;
    margin-right:50px;
    padding:130px 69px;
    color:#ffffff;
    font-size:30px;
    line-height:52px;
  }

</style>
