var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isSpecialVersion
    ? _c("div", { staticClass: "facedetial" }, [
        _c("div", { staticClass: "faceHeader" }, [
          !_vm.isOnlyFace
            ? _c("span", { staticClass: "back", on: { click: _vm.goReport } }, [
                _vm._v("返 回")
              ])
            : _vm._e(),
          _c("span", { staticClass: "title" }, [_vm._v("面诊结果")]),
          _vm.isOnlyFace
            ? _c("span", { staticClass: "close", on: { click: _vm.goApp } }, [
                _vm._v("关 闭")
              ])
            : _vm._e()
        ]),
        _vm.faceData
          ? _c("div", { staticClass: "faceContent" }, [
              _c(
                "div",
                { staticClass: "faceBox" },
                [
                  _c("div", { staticClass: "imgBox" }, [
                    _c(
                      "div",
                      { staticClass: "imagePics" },
                      [
                        _c("img", {
                          attrs: {
                            src: require("../../../assets/img/menopauseovary/mxjg.gif"),
                            alt: "面诊gif图"
                          }
                        }),
                        _vm._l(_vm.riskClass, function(item) {
                          return _c("div", {
                            key: item,
                            staticClass: "riskImg",
                            class: item
                          })
                        })
                      ],
                      2
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "facePart" },
                    [
                      _c(
                        "p",
                        {
                          class: _vm.activeClass("healthTab"),
                          style: _vm.styleObj,
                          on: {
                            click: function($event) {
                              return _vm.doChoose("healthTab")
                            }
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.isSpecialVersion ? "结果" : "健康提示")
                            )
                          ])
                        ]
                      ),
                      !_vm.hasNoRisk
                        ? _c(
                            "p",
                            {
                              class: _vm.activeClass("organsTab"),
                              style: _vm.styleObj,
                              on: {
                                click: function($event) {
                                  return _vm.doChoose("organsTab")
                                }
                              }
                            },
                            [_c("span", [_vm._v("脏腑风险")])]
                          )
                        : _vm._e(),
                      _vm.isOnlyFace && _vm.faceData.face_character
                        ? [
                            _c(
                              "p",
                              {
                                class: _vm.activeClass("characterTab"),
                                style: _vm.styleObj,
                                on: {
                                  click: function($event) {
                                    return _vm.doChoose("characterTab")
                                  }
                                }
                              },
                              [_c("span", [_vm._v("性格")])]
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeTab === "healthTab",
                          expression: "activeTab === 'healthTab'"
                        }
                      ],
                      staticClass: "healthTip"
                    },
                    _vm._l(_vm.faceTitle, function(item) {
                      return _c(
                        "div",
                        { key: item.name, staticClass: "partDetail" },
                        [
                          _c("div", { staticClass: "partTitle" }, [
                            _vm._v(_vm._s(item.name))
                          ]),
                          _c("div", { staticClass: "partExplain" }, [
                            _c("p", { staticClass: "symptom" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.faceData[item.fieldName] &&
                                      _vm.faceData[item.fieldName].symptom
                                  )
                              )
                            ]),
                            !_vm.isSpecialVersion
                              ? _c("p", { staticClass: "explain" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.faceData[item.fieldName] &&
                                          _vm.faceData[item.fieldName].name
                                      )
                                  )
                                ])
                              : _vm._e()
                          ])
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeTab === "organsTab",
                          expression: "activeTab === 'organsTab'"
                        }
                      ],
                      staticClass: "character"
                    },
                    [
                      _vm.visceralRisk && _vm.visceralRisk.length > 0
                        ? _vm._l(_vm.visceralRiskList, function(item, index) {
                            return _c(
                              "div",
                              { key: item.part, staticClass: "organsPart" },
                              [
                                _vm.visceralRisk[index] &&
                                _vm.visceralRisk[index][item.field] &&
                                _vm.visceralRisk[index][item.field].attention
                                  ? [
                                      _c("p", [
                                        _c("img", {
                                          attrs: {
                                            src: item.img,
                                            alt: item.part
                                          }
                                        }),
                                        _vm._v(_vm._s(item.part))
                                      ]),
                                      _c("div", [
                                        _c("p", { staticClass: "maybe" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.visceralRisk[index][
                                                item.field
                                              ].diagnose
                                            )
                                          )
                                        ]),
                                        _c("p", { staticClass: "tips" }, [
                                          _vm._v(
                                            "提示：" +
                                              _vm._s(
                                                _vm.visceralRisk[index][
                                                  item.field
                                                ].attention
                                              )
                                          )
                                        ])
                                      ])
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          })
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm.faceData.face_character
                    ? [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.activeTab === "characterTab",
                                expression: "activeTab === 'characterTab'"
                              }
                            ],
                            staticClass: "character"
                          },
                          [
                            _c("div", {
                              style: {
                                width: "500px",
                                height: "500px",
                                margin: "0 auto"
                              },
                              attrs: { id: "characterChart" }
                            }),
                            _c(
                              "div",
                              { staticClass: "point" },
                              _vm._l(
                                _vm.faceData.face_character.keyword,
                                function(item, index) {
                                  return _c("div", { key: index }, [
                                    _vm._v(" " + _vm._s(item) + " ")
                                  ])
                                }
                              ),
                              0
                            ),
                            _c("p", { staticClass: "desc" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.faceData &&
                                      _vm.faceData.face_character &&
                                      _vm.faceData.face_character.character
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ])
          : _vm._e()
      ])
    : _c("div", { staticClass: "facedetial" }, [
        _c("div", { staticClass: "faceHeader headerSpecial" }, [
          _c("span", { staticClass: "back", on: { click: _vm.goReport } }, [
            _vm._v("返 回")
          ]),
          _c("span", { staticClass: "title" }, [_vm._v("面部图片")])
        ]),
        _c("div", { staticClass: "specialBg" }),
        _c("img", {
          staticClass: "photo",
          attrs: { src: _vm.facePhoto, alt: "面诊照片" }
        })
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }