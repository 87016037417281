// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/chronic/mbbj_jcjg_icon.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/img/menopauseovary/bc_icon.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../assets/img/menopauseovary/sc_icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".chronicchart[data-v-5741f44a] {\n  position: relative;\n  width: calc(94% - 40px);\n  height: 628px;\n  margin: 0 auto;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 100% 100%;\n  padding: 20px;\n}\n.chronicchart .title[data-v-5741f44a] {\n    color: #333;\n    font-size: 48px;\n    font-family: PingFangSC-Medium;\n    width: 100%;\n    height: 50px;\n    border-left: 6px solid #1288eb;\n    text-align: left;\n    margin-bottom: 20px;\n}\n.chronicchart .title span[data-v-5741f44a] {\n      margin-left: 20px;\n}\n.chronicchart .title .tip[data-v-5741f44a] {\n      color: #1288eb;\n}\n.chronicchart .subtitle[data-v-5741f44a] {\n    color: #333;\n    font-size: 48px;\n    font-family: PingFangSC-Medium;\n    margin: 0 0 40px 30px;\n}\n.chronicchart .subtitle span[data-v-5741f44a] {\n      color: #1288eb;\n}\n.chronicchart .bc[data-v-5741f44a] {\n    position: absolute;\n    width: 120px;\n    height: 23px;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n    background-size: 100% 100%;\n    top: 50px;\n    left: 43px;\n}\n.chronicchart .sc[data-v-5741f44a] {\n    position: absolute;\n    width: 120px;\n    height: 23px;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n    background-size: 100% 100%;\n    top: 90px;\n    left: 43px;\n}\n", ""]);
// Exports
module.exports = exports;
