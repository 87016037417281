var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.pulse
    ? _c(
        "div",
        {
          staticClass: "knowledg-pulse",
          style:
            _vm.printstatus === "1"
              ? { marginLeft: "130px" }
              : { marginLeft: "33px" }
        },
        [
          _vm.is_print === 1
            ? _c("div", [
                _vm.marker === "10.1"
                  ? _c("div", [
                      _c("div", {
                        staticClass: "zskyj-pulse",
                        on: { click: _vm.returnToKnow }
                      }),
                      _c("div", {
                        staticClass: "dybg-pulse",
                        on: { click: _vm.doPrint }
                      })
                    ])
                  : _c("div", [
                      _c("div", {
                        staticClass: "dybg-pulse",
                        on: { click: _vm.doPrint }
                      })
                    ])
              ])
            : _vm._e(),
          _vm.is_print !== 1 && _vm.marker === "10.1"
            ? _c(
                "div",
                { staticClass: "noprint", on: { click: _vm.returnToKnow } },
                [
                  _c("img", {
                    staticStyle: {
                      "margin-top": "2px",
                      width: "90px",
                      height: "90px",
                      "border-radius": "20px"
                    },
                    attrs: {
                      src: require("../../../assets/img/report/bg10.1_jcjg_icon.png"),
                      alt: "知识库依据"
                    }
                  }),
                  _c(
                    "div",
                    { staticStyle: { color: "white", "font-size": "24px" } },
                    [_vm._v("知识库依据")]
                  )
                ]
              )
            : _vm._e()
        ]
      )
    : _c(
        "div",
        {
          staticClass: "knowledg",
          style:
            _vm.printstatus === "1"
              ? { marginLeft: "130px" }
              : { marginLeft: "30px" }
        },
        [
          _vm.is_print === 1
            ? _c("div", [
                _vm.marker === "10.1"
                  ? _c("div", [
                      _c("div", {
                        staticClass: "zskyj",
                        on: { click: _vm.returnToKnow }
                      }),
                      _c("div", {
                        staticClass: "dybg",
                        on: { click: _vm.doPrint }
                      })
                    ])
                  : _c("div", [
                      _c("div", {
                        staticClass: "dybg",
                        on: { click: _vm.doPrint }
                      })
                    ])
              ])
            : _vm._e(),
          _vm.is_print !== 1 && _vm.marker === "10.1"
            ? _c(
                "div",
                { staticClass: "noprint", on: { click: _vm.returnToKnow } },
                [
                  _c("img", {
                    staticStyle: {
                      "margin-top": "2px",
                      width: "106px",
                      height: "106px",
                      "border-radius": "20px"
                    },
                    attrs: {
                      src: require("../../../assets/img/report/bg10.1_jcjg_icon.png"),
                      alt: "知识库依据"
                    }
                  }),
                  _c(
                    "div",
                    { staticStyle: { color: "white", "font-size": "24px" } },
                    [_vm._v("知识库依据")]
                  )
                ]
              )
            : _vm._e()
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }