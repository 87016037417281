var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "company" }, [
      _c("img", {
        attrs: {
          src: require("../../../assets/img/common/company-logo-color.png"),
          alt: "祉云logo"
        }
      }),
      _c("span", [_vm._v("上海祉云医疗科技有限公司")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }