<template>
  <div class="tonguedetial" v-if="!isSpecialVersion">
    <div class="tongueHeader">
      <span @click="goReport" class="back" v-if="!isOnlyTongue">返 回</span>
      <span class="title">舌诊结果</span>
      <span @click="goApp" class="close" v-if="isOnlyTongue">关 闭</span>
    </div>
    <div class="tongueContent" v-if="tongueData">
      <div class="tongueBox">
        <div class="imgBox">
          <div class="imagePics">
            <img src="../../../assets/img/menopauseovary/sxjg.gif" alt="舌诊gif图" />
            <div class="riskImg" v-for="item in riskClass" :class="item" :key="item"></div>
          </div>

        </div>
        <div class="tonguePart">
          <p @click="doChoose('healthTab')" :class="activeClass('healthTab')" :style="styleObj">
            <span>{{isSpecialVersion? '结果': '健康提示'}}</span>
          </p>
          <p @click="doChoose('organsTab')" :class="activeClass('organsTab')" :style="styleObj" v-if="!hasNoRisk">
            <span>脏腑风险</span>
          </p>

        </div>
        <div class="healthTip" v-show="activeTab === 'healthTab'">
          <div class="partDetail">
            <div class="partTitle">舌色</div>
            <div class="partExplain">
              <p class="symptom"> {{ tongueData.tongue_nature_color_info && tongueData.tongue_nature_color_info.symptom }}</p>
              <p class="explain" v-if="!isSpecialVersion"> {{ tongueData.tongue_nature_color_info && tongueData.tongue_nature_color_info.name }}</p>
            </div>
          </div>
          <div class="partDetail">
            <div class="partTitle">舌形</div>
            <div class="partExplain">
              <p class="symptom"> {{ tongueData.tongue_fat_thin_info && tongueData.tongue_fat_thin_info.symptom }}，{{ tongueData.tongue_crack_info && tongueData.tongue_crack_info.symptom }}，
              {{ tongueData.tongue_indentation_info && tongueData.tongue_indentation_info.symptom }}
              </p>
              <p class="explain" v-if="!isSpecialVersion"> {{ tongueData.tongue_fat_thin_info && tongueData.tongue_fat_thin_info.name }}</p>
              <p class="explain" v-if="!isSpecialVersion"> {{tongueData.tongue_crack_info && tongueData.tongue_crack_info.name}}</p>
              <p class="explain" v-if="!isSpecialVersion"> {{tongueData.tongue_indentation_info && tongueData.tongue_indentation_info.name}}</p>
            </div>
          </div>
          <div class="partDetail">
            <div class="partTitle">舌苔</div>
            <div class="partExplain">
              <p class="symptom"> {{ tongueData.tongue_coat_color_info && tongueData.tongue_coat_color_info.symptom }}，{{tongueData.tongue_coat_thickness_info && tongueData.tongue_coat_thickness_info.symptom}}</p>
              <p class="explain" v-if="!isSpecialVersion"> {{ tongueData.tongue_coat_color_info && tongueData.tongue_coat_color_info.name }}</p>
              <p class="explain" v-if="!isSpecialVersion"> {{ tongueData.tongue_coat_thickness_info && tongueData.tongue_coat_thickness_info.name }}</p>
            </div>
          </div>
<!--          <div class="tongueTip" v-if="!isSpecialVersion">-->
<!--            <div class="tipTitle">-->
<!--              <img src="../../../assets/img/common/jkts_mzjg_icon.png" alt="健康提示" />-->
<!--              <span>健康提示</span>-->
<!--            </div>-->
<!--            <div class="tipSign">{{ tongueData.tongue_attention && tongueData.tongue_attention[0] && tongueData.tongue_attention[0].significance}}</div>-->
<!--            <div class="tipAttention">{{ tongueData.tongue_attention && tongueData.tongue_attention[0] && tongueData.tongue_attention[0].attention}}</div>-->
<!--          </div>-->
        </div>
        <div v-show="activeTab === 'organsTab'" class="organsTip">
          <template v-if="visceralRisk && visceralRisk.length > 0">
            <div class="organsPart" v-for="(item,index) in visceralRiskList" :key="item.part">
              <template v-if=" visceralRisk[index] && visceralRisk[index][item.field] && visceralRisk[index][item.field].attention1  && visceralRisk[index][item.field].attention2">
                <p><img :src="item.img" :alt="item.part" />{{item.part}}</p>
                <div>
                  <p class="maybe1">{{ visceralRisk[index][item.field].attention1}}</p>
                  <p class="maybe2">{{ visceralRisk[index][item.field].attention2}}</p>
                  <p class="tips">提示：{{ visceralRisk[index][item.field].diagnose}}</p>
                </div>
              </template>

            </div>
          </template>
        </div>

      </div>

    </div>
  </div>
  <div class="tonguedetial" v-else>
    <div class="tongueHeader headerSpecial">
      <span @click="goReport" class="back">返 回</span>
      <span class="title">舌部图片</span>
    </div>
    <div class="specialBg"></div>
    <img class="photo" :src="tonguePhoto" alt="舌诊照片">

  </div>
</template>

<script>
  import fh from '../../../assets/img/report/fh_icon.png';
  import close from '../../../assets/img/report/gb_jcjg_icon.png';
  import liver_gall from '../../../assets/img/common/f_mzjg_icon.png';
  import cardiopulmonary from '../../../assets/img/common/g_mzjg_icon.png';
  import spleen_stomach from '../../../assets/img/common/wei.png';
  import { deepClone } from '../../../../utils/deepClone.js'

  export default {
    name: "tonguedetail",
    props: {
      isOnlyTongue: { // 是否是单独的舌诊报告页面
        type: Boolean,
      },
    },
    mounted(){
      const { report } = this.$store.state;
      const isTzbsReport = window.location.search && window.location.search.indexOf('category=2') !== -1;
      if(report.attention_display && isTzbsReport) {
        this.isSpecialVersion = true;
      }
    },
    data(){
      return {
        fh,
        close,
        tonguePhoto: this.$store.state.report && this.$store.state.report.tongue_photo_url,
        tongueData: this.$store.state.report.tongue_result || this.$store.state.report, // 舌诊数据
        isSpecialVersion: false, // 是否是特殊版本（申请医疗器械证需要）
        activeTab: 'healthTab', // 激活tab,默认为健康
        visceralRisk: this.$store.state.report && (this.isOnlyTongue? this.$store.state.report.visceral_risk :
          (this.$store.state.report.tongue_result && this.$store.state.report.tongue_result.visceral_risk)),
        visceralRiskList: [
          {part: '心肺', field: 'cardiopulmonary', img: cardiopulmonary },
          {part: '脾胃', field: 'spleen_stomach', img: spleen_stomach },
          {part: '肝胆', field: 'liver_gall', img: liver_gall },
        ],
      }
    },
    computed: {
      styleObj(){
        return { flex: this.hasNoRisk ? '0 0 100%' : '0 0 50%' }
      },
      hasNoRisk(){
        // 判断是不是有脏腑风险内容
        const dataOrigin = this.visceralRisk; // 数据来源
        const noneRiskArr = dataOrigin.filter(val => {
          let propName = Object.getOwnPropertyNames(val)[0];
          return !(val[propName] && val[propName].attention1 && val[propName].attention2)
        })
        return noneRiskArr.length === dataOrigin.length
      },
      riskClass(){
        const cloneArr = deepClone(this.visceralRisk); // 先深拷贝一份数据
        // 1.当心肺有风险时，舌尖区域需要明暗变化；
        // 2.当肝胆有风险时，舌边区域需要明暗变化；
        // 3.当脾胃有风险时，舌中区域需要明暗变化；
        // 4.当没有脏腑风险时，则不需要有明暗变化。
        const standarArr = [
          {filed:'cardiopulmonary', className: 'risk_bottom'},
          {filed:'spleen_stomach', className: 'risk_center'},
          {filed:'liver_gall', className: 'risk_left risk_right'},
          ];
        let riskArr = [];
        for(let item of cloneArr){
          for(let inner of standarArr){
            if(Object.getOwnPropertyNames(item)[0] === inner.filed && item[inner.filed] && item[inner.filed].attention1){
              if(inner.className.indexOf(' ') !== -1){ // 如果是多个，当肝胆有风险时，舌边区域需要明暗变化
                riskArr = [...riskArr, ...inner.className.split(' ')]
              } else {
                riskArr.push(inner.className)
              }

            }
          }
        }
        return riskArr
      }

    },
    methods: {
      goReport(){ // 返回报告页面主界面
        this.$router.back();
      },
      goApp(){ // 返回App主界面
        if (window.zhiyun) {
          window.zhiyun.closereport();
        }
      },
      doChoose(tab){ // 选中tab
        this.activeTab = tab;
      },
      activeClass(tab){ // 选中样式
        return {
          active: this.activeTab === tab
        }
      },


    }
  }
</script>

<style lang="scss" scoped>

  $tongue_left: '../../../assets/img/shake/tongue_left.png';
  $tongue_center: '../../../assets/img/shake/tongue_center.png';
  $tongue_bottom: '../../../assets/img/shake/tongue_bottom.png';

  .tongueHeader {
    height: 200px;
  }

  .headerSpecial {
    position: relative;
    z-index: 2;
  }

  .tonguedetial{
    width:100%;
    height:100%;
    /*padding: 0 0 0 52px;*/
    background:url("../../../assets/img/report/bj1.png")no-repeat;
    background-size:cover;
    overflow:hidden;
    color: #ffffff;
    font-size: 36px;
  }

  .specialBg{
    width:100%;
    height:100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background:url("../../../assets/img/physical/zpbj_icon.png") no-repeat;
    background-size:cover;
  }

  .photo {
    position: absolute;
    top: 588px;
    left: calc(50% - 250px);
    /*图片是800*800 只要保证是个正方形就可以*/
    width: 500px;
    height: 500px;
    z-index: 2;
    border-radius: 50px;
  }


  ::-webkit-scrollbar{
    display: none;
  }

  .back,
  .close {
    top:105px;
    width: 152px;
    height: 82px;
    background:url("../../../assets/img/common/mzjg_back.png") no-repeat;
    color: #ffffff;
    text-align: center;
    font-weight: bold;
    font-size: 35px;
    line-height: 82px;
  }

  .back {
    position:relative;
    left:49px;
    display: block;
  }

  .close {
    position:absolute;
    right:49px;
  }

  .title{
    font-size: 60px;
    color:#00FEFB;
    font-family:TITLE;
    width:300px;
    height:111px;
    background:url("../../../assets/img/report/jssm_icon.png") no-repeat;
    text-align:center;
    line-height:111px;
    background-size:100% 100%;
    position: absolute;
    left: 0;
    top: 83px;
    right: 0;
    margin: 0 auto;
  }

  .tongueContent {
    width: calc(100% - 98px);
    margin: 30px auto 0 auto;
    background: url("../../../assets/img/physical/kuang_lab_icon.png") no-repeat;
    background-size:100% 100%;
    overflow: auto;

    .tongueBox {
      width: calc(100% - 150px);
      margin: 0 auto;

      .imgBox {
        height: 325px;
        margin-top: 50px;

        .imagePics {
          height: 322px;
          width: 241px;
          margin: 0 auto;
          position: relative;

          img {
            height: 322px;
            width: auto;
            display: block;
            margin: 0 auto;
          }

          .riskImg {
            position: absolute;
            opacity: 0;
            animation: tongue-left-risk 5s infinite;
          }

          .risk_left,
          .risk_right {
            background:url($tongue_left) 100% no-repeat;
            width: 36px;
            height: 74px;
            top:50%;

          }

          .risk_left {
            left: 12%;
          }

          .risk_right {
            right: 12%;
          }

          .risk_center{
            background:url($tongue_center) 100% no-repeat;
            width: 53px;
            height: 44px;
            top:calc(50% + 22px);
            left: calc(50% - 23px);
          }

          .risk_bottom{
            background:url($tongue_bottom) 100% no-repeat;
            width: 47px;
            height: 51px;
            bottom: 0;
            left: calc(50% - 20px);
          }




          @keyframes tongue-left-risk {
            from {opacity: 0;}
            to {opacity: 1;}
          }
        }


      }

      .tonguePart {
        display: flex;
        align-items: center;

        p {
          margin-top: 35px;
          margin-bottom: 35px;
          /*flex: 0 0 50%;*/
          text-align: center;
          font-size: 40px;
          font-weight: bold;
          line-height: 40px;


          span {
            display: inline-block;
          }
        }

        p:nth-child(2),
        p:nth-child(3){
          position: relative;
        }

        p:nth-child(2)::before,
        p:nth-child(3)::before {
          content: '';
          display: block;
          width: 1px;
          height: 100%;
          border: 2px solid #006f9a;
          position: absolute;
        }

        p.active {
          span {
            color: #00fefb;
            position: relative;
          }

          span::after {
            content: '';
            display: block;
            width: 100%;
            height: 36px;
            background: url("../../../assets/img/common/xzbj_mzjg_icon.png") no-repeat;
            background-size: 100% 100%;
            margin-top: -12px;
            position: absolute;
          }
        }
      }

      .organsTip {
        width: 100%;
        max-height: 1000px;
        overflow: auto;
        margin-top: 50px;
        padding-top: 10px;
        border-top: 2px solid #005281;


        .organsPart {
          display: flex;
          align-items: center;
          font-size: 34px;
          line-height: 44px;


          &>p {
            width: 70px;
            margin-right: 55px;
            text-align: center;
          }

          &>div {
            padding: 50px 0;
          }

          .middlePart {
            border-top: 2px solid #005281;
            border-bottom: 2px solid #005281;

          }


          .tips {
            color: #999999;
            font-size: 30px;
          }

          .maybe1 {
            font-size: 34px;
            text-align: center;
            font-weight: bold;
          }

          .maybe2 {
            font-size: 30px;
          }

        }
      }



      .healthTip {
        margin-top: 50px;
        padding-top: 50px;
        border-top: 2px solid #005281;
      }



      .partDetail {
        margin-bottom: 50px;
        display: flex;
        align-items: center;

        .partTitle {
          text-align: center;
          width: 111px;
          height: 121px;
          margin-right: 60px;
          line-height: 121px;
          background: url("../../../assets/img/common/ms_mzjg_icon.png") no-repeat;
          background-size: 100% 100%;
        }

        .partExplain {
          width: calc(100% - 180px);
        }

        .symptom {
          font-size: 34px;
          font-weight: bold;
          text-align: center;
          width: calc(100% + 170px);
          position: relative;
          left: -170px;
        }

        .explain {
          margin-top: 22px;
          font-size: 30px;
        }
      }

      .tongueTip {
        margin-top: 45px;
        border-top: 2px solid #005281;
        padding-top: 30px;

        .tipTitle {
          font-size: 38px;

          img {
            height: 58px;
            vertical-align: middle;
            margin-right: 30px;
          }
        }

        .tipSign {
          text-align: center;
          font-size: 38px;
          font-weight: bold;
          margin: 40px 0;
        }

        .tipAttention {
          font-size: 30px;
          padding-bottom: 150px;
        }
      }

      .character {
        width: 100%;

        .point {
          display: flex;
          flex-wrap: wrap;
          border-bottom: 2px dashed #777d7c;
          padding-bottom: 20px;

          div {
            width: 25%;
            font-size: 36px;
            padding-bottom: 20px;

          }

          div::before {
            content: '';
            display: inline-block;
            position: relative;
            width: 34px;
            height: 31px;
            background: url("../../../assets/img/report/gou_mzjg_icon.png") no-repeat;
            vertical-align: middle;
          }
        }

        .desc {
          font-size: 30px;
          text-indent: 2em;
          margin: 20px 0 60px 0;
          line-height: 50px;
          max-height: 30vh;
          overflow: auto;
        }
      }

    }


  }

</style>