var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product" }, [
    _vm._m(0),
    _c("div", [
      _c(
        "table",
        [
          _vm._m(1),
          _vm._l(_vm.productList, function(item) {
            return _c("tr", { key: item.id }, [
              _c("td", [
                _c("img", { attrs: { src: item.image, alt: "产品图" } })
              ]),
              _c("td", [_vm._v(_vm._s(item.name))])
            ])
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [
      _c("h3", { staticClass: "logo" }, [_vm._v("特别推荐")]),
      _c("p", { staticClass: "angle" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("产品图片")]),
      _c("td", [_vm._v("产品名称")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }