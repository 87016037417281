var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "0 40px", "margin-top": "10px" } },
    [
      _c("div", { staticClass: "jkfa" }, [
        _c(
          "div",
          {
            staticStyle: {
              "font-size": "30px",
              color: "#feffff",
              "margin-left": "70px"
            }
          },
          [_vm._v(" 根据您当前的健康状态定制了个性化健康方案 ")]
        ),
        _c("div", { staticClass: "jkfn" }, [
          _c(
            "div",
            { staticClass: "container", on: { click: _vm.returnToHealth } },
            [_vm._v(" 健康方案 ")]
          )
        ]),
        _c("div", { staticClass: "jkfn" }, [
          _c(
            "div",
            { staticClass: "container", on: { click: _vm.returnToFacial } },
            [_vm._v(" 面象分析结果 ")]
          )
        ]),
        _c("div", { staticClass: "jkfn" }, [
          _c(
            "div",
            { staticClass: "container", on: { click: _vm.returnToDiagnosis } },
            [_vm._v(" 舌象分析结果 ")]
          )
        ])
      ]),
      _c("div", { staticClass: "footer" }, [
        _c(
          "span",
          {
            staticStyle: {
              display: "block",
              "font-family": "SourceHanSansCN-Normal",
              color: "#ffa536",
              "font-size": "24px",
              "text-align": "left"
            }
          },
          [_vm._v(" 此报告仅作参考，如有不适请及时就医 ")]
        ),
        _c("div", { staticClass: "dzbg", on: { click: _vm.showExplain } }),
        _vm.csr !== "SHBAZE" && _vm.homeFootShow != null
          ? _c(
              "div",
              { class: _vm.marker == 10.1 ? "bottom bottom-ten" : "bottom" },
              [
                _vm.csr !== "CHENSHANG"
                  ? _c("img", {
                      class:
                        _vm.homeFootShow == 0 || _vm.homeFootShow == 2
                          ? ""
                          : "tipImg",
                      attrs: {
                        src:
                          _vm.csr === "BJTRTKH"
                            ? _vm.trt
                            : _vm.csr === "SHWYSLSWKJ"
                            ? _vm.wysl
                            : _vm.homeFootShow == 0 || _vm.homeFootShow == 2
                            ? ""
                            : _vm.logo
                      }
                    })
                  : _vm._e(),
                _c("span", { staticClass: "tipTxt" }, [
                  _vm._v(
                    _vm._s(
                      _vm.csr === "BJTRTKH"
                        ? "中国北京同仁堂（集团）有限责任公司"
                        : _vm.csr === "CHENSHANG"
                        ? "上海辰尚信息科技有限公司"
                        : _vm.csr === "SHWYSLSWKJ"
                        ? "上海伍月石榴生物科技有限公司"
                        : _vm.homeFootShow == 0
                        ? ""
                        : _vm.homeFootShow == 1
                        ? "上海祉云医疗科技有限公司"
                        : _vm.homeFootShow == 2
                        ? _vm.logoName
                        : "上海祉云医疗科技有限公司 | " + _vm.logoName
                    )
                  )
                ])
              ]
            )
          : _vm._e()
      ]),
      _vm.flag
        ? _c("div", [
            _c("div", { staticClass: "frame" }, [
              _c(
                "div",
                { staticClass: "spantitle" },
                [
                  _vm.reportData.vendor_setting.qr_code_show === 2
                    ? _c("img", {
                        staticStyle: { width: "450px", height: "auto" },
                        attrs: { src: _vm.reportData.qr_url }
                      })
                    : this.shareUrl &&
                      _vm.reportData.vendor_setting.qr_code_show === 1
                    ? _c("qrcode-vue", {
                        attrs: { value: _vm.url, size: "450" }
                      })
                    : this.shareUrl &&
                      _vm.reportData.vendor_setting.qr_code_show === 0
                    ? _c("img", {
                        staticStyle: { width: "450px", height: "450px" },
                        attrs: { src: _vm.code }
                      })
                    : _c("div", [_vm._v("暂无数据")]),
                  _c("div", {
                    staticClass: "fh",
                    on: { click: _vm.showExplain }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }