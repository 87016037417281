var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.reportData
    ? _c(
        "div",
        { staticStyle: { display: "flex", overflow: "auto", height: "100%" } },
        [
          _c(
            "div",
            {
              staticClass: "constitution",
              style:
                _vm.access === "web" ? { height: "1920px" } : { height: "100%" }
            },
            [
              _c("div", { staticStyle: { height: "270px" } }, [
                _c("img", {
                  staticClass: "back",
                  attrs: { src: _vm.fh1 },
                  on: { click: _vm.returnto }
                }),
                _c("span", { staticClass: "title" }, [
                  _vm._v(
                    _vm._s(
                      _vm.definition.split("").join("\xa0\xa0\xa0\xa0\xa0")
                    )
                  )
                ])
              ]),
              _c("div", { staticClass: "definition" }, [
                _c(
                  "div",
                  {
                    staticClass: "definition-content",
                    staticStyle: { height: "42%" }
                  },
                  [
                    _c("span", { staticStyle: { "font-size": "45px" } }, [
                      _vm._v(_vm._s(_vm.definition))
                    ]),
                    _vm._v(
                      " " + _vm._s(_vm.content1) + _vm._s(_vm.content2) + " "
                    )
                  ]
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "risk",
                  style:
                    _vm.marker === "10.1"
                      ? { height: "450px" }
                      : { height: "484px" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "definition-content",
                      staticStyle: { height: "80%" }
                    },
                    [
                      _c("span", { staticStyle: { "font-size": "45px" } }, [
                        _vm._v(_vm._s(_vm.definition))
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.explaining2[_vm.definition].content[0]) +
                          " "
                      )
                    ]
                  )
                ]
              ),
              _c("div", { staticClass: "reason" }, [
                _c(
                  "div",
                  {
                    staticClass: "definition-content",
                    staticStyle: { height: "85%" }
                  },
                  _vm._l(_vm.reason, function(item, index) {
                    return _c(
                      "div",
                      { key: index, staticStyle: { margin: "10px 0 0 0" } },
                      [_vm._v(_vm._s(item))]
                    )
                  }),
                  0
                )
              ]),
              _c("span", {
                staticClass: "statement",
                style:
                  _vm.marker === "10.1"
                    ? { marginTop: "3%" }
                    : { marginTop: "15%" },
                on: { click: _vm.ReturnToOutline }
              })
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }