<template>
  <div v-if="report" style="display: flex;overflow: auto;height: 100%">
    <div v-if="report.baze">
      <div class="product">
        <div style="height: 170px">
          <img :src="fh" @click="returnto" class="prc-back">
          <span class="title">特别推荐</span>
        </div>
        <div v-for="(item ,index) in report.product" :key="index">
          <div v-if="(index%2)===0" class="producL" :style="marker === '10.1' ? { marginTop: '5px' } : { marginTop: '25px' }">
            <div class="product-img">
              <img :src="item.image?item.image:logoty" style="width: 470px;height: 400px;vertical-align: middle">
            </div>
            <span class="product-span">{{item.name?item.name:'暂无商品'}}</span>
          </div>
          <div v-else class="producR" :style="marker === '10.1' ? { marginTop: '5px' } : { marginTop: '25px' }">
            <div class="product-img">
              <img :src="item.image?item.image:logoty" style="width: 470px;height: 400px;vertical-align: middle">
            </div>
            <span class="product-span">{{item.name?item.name:'暂无商品'}}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else :style="{width: '100%'}">
      <div class="product_main" :style="access === 'web' ? { height:'1920px' } : {}">
        <div>
          <div class="menu">
            <div class="menuopt" v-for="(item,index) in product" :key="index" @click="checkType($event,index)" :style="{'color':index === currentIndex?'#00fefb':'#ffffff','border-bottom':index === currentIndex?'4px solid #00fefb':null}">
              {{item.name}}
            </div>
          </div>
          <div
            v-for="(item , index) in this.product"
            :key="index"
            v-if="currentIndex === index"
            class="boxcontent"
          >
            <div
              class="boxitem"
              v-for="(items , num) in item.products"
              :key="num"
              :style="num%2 === 0?{marginRight: '25px'} : {marginLeft: '25px'}"
              @click="openWaresContent(items)"
            >
              <div class="boxImg">
                <img :src="items.image">
              </div>
              <div style="padding: 26px 10px">
                <div style="overflow: auto;margin: 0">
                  <span class="boxname">{{items.name? items.name : '未知'}}</span>
                  <span class="boxprice">&yen; {{items.price? items.price : '未知'}}</span>
                </div>
                <span class="boxdesc">{{items.desc? items.desc : '暂无相关描述'}}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isShowProDetail">
          <Wares />
        </div>


      </div>
    </div>
  </div>
</template>

<script>
  import fh from '../../assets/img/report/fh_icon.png';
  import logoty from '../../assets/img/report/logo_ty_icon.png';
  import { queryObject } from '../../../utils/common.js';
  import Wares from './wares.vue'
  const { access = '',marker = '' } = queryObject();


  export default {
    name: "product",
    data(){
      return{
        report:'',
        product:[],
        producttip:[],
        fh:fh,
        logoty:logoty,
        currentIndex:0,
      }
    },
    mounted() {
      // 把proudct 按照id来排序
      this.product = this.report.product;
      this.product.sort(function(a,b){
        return a.id-b.id
      });



      // for(let inner of this.report.product) {
      //   this.producttip.push(inner.id);
      // }
      // this.producttip = this.producttip.sort()
      // for (let i = 0; i < this.producttip.length; i++) {
      //   for (let j = 0; j < this.report.product.length; j++){
      //     if (this.producttip[i] === this.report.product[j].id){
      //       this.product.push(this.report.product[j])
      //     }
      //   }
      // }
    },
    methods:{
      returnto(){
        this.$router.back();//返回上一层
      },
      checkType(e,index){
        this.currentIndex = index
      },
      openWaresContent(val){
        this.$store.commit('SET_CURRENT_PRODUCT',val); // 产品详情
        this.$store.commit('SET_DETAIL_SHOW',true); // 展示详情页
      }
    },
    computed: {
      access(){
        return access
      },
      marker(){
        return marker
      },
      isShowProDetail(){
        return this.$store.state.is_show_detail // 是否展示详情，点击boxItem 把对应的内容展示在这里
      }
    },
    beforeMount(){
      this.report = this.$route.params.report
    },
    components: {
      Wares
    },
  }
</script>

<style scoped>
  .product{
    height:100%;
    width:100%;
    background:url("../../assets/img/report/bj_sznr_icon.png");
    background-size:cover;
    overflow: hidden;
    position:fixed;
    top:0px;
    left:0px;
  }
  ::-webkit-scrollbar{
    display: none;
  }
  .back{
    position:relative;
    /*left:49px;*/
    top:105px;
    width: 152px;
    height: 82px;
  }
  .title{
    font-size: 48px;
    color:#00FEFB;
    font-family:TITLE;
    width:300px;
    height:111px;
    background:url("../../assets/img/report/jssm_icon.png") no-repeat;
    text-align:center;
    line-height:111px;
    background-size:100% 100%;
    position: absolute;
    left: 0;
    top: 83px;
    right: 0;
    margin: 0 auto;
  }
  .producL{
    display:inline-block;
    width:470px;
    height:500px;
    margin:25px 0 0 50px;
  }
  .product-img{
    display:inline-block;
    width:470px;
    height:400px;
    text-align:center;
    line-height:400px;
  }
  .product-span{
    display:inline-block;
    font-size: 36px;
    color:#ffffff;
    width:100%;
    font-family:SourceHanSansCN-regular;
    text-align:center;
    filter:Alpha(opacity=0.1);
    background:RGBa(0,162,255,0.1);
    line-height:2;
    margin-top:8px;
  }
  .producR{
    display:inline-block;
    width:470px;
    height:441px;
    margin:25px 0 0 25px;
  }
  .prc-back{
    position:absolute;
    left:49px;
    top:105px;
    width: 152px;
    height: 82px;
  }
  .menu{
    display: flex;
    color: #fff;
    padding: 0;
    overflow-x: auto;
    width: calc(100% - 100px);
    margin: 0 auto;
    height: 178px
  }
  .menuopt{
    display: block;
    flex: 0 0 auto;
    font-size: 48px;
    color: #fff;
    margin: 50px 100px 50px 0;
    padding-bottom: 15px;
  }
  .boxcontent{
    width: calc(100% - 50px);
    height: calc(100% - 450px);
    margin: 0 auto;
    overflow: auto;
    padding: 0;
  }
  .boxitem{
    display: block;
    width: calc(50% - 25px);
    float: left;
    font-size: 36px;
    color: #333333;
    list-style-type: none;
    background-color: #fff;
    margin-bottom: 50px;
    margin-top: 10px;
  }

  .boxImg {
    height: 300px;
    text-align: center;
    padding: 15px;
    overflow: hidden;
  }

  .boxImg img {
    max-width: 100%;
    height: 90%;
    display: block;
    margin: 0 auto;
  }


  .boxname{
    width: 60%;
    font-size: 36px;
    color: #333333;
    font-weight: bold;
    display: block;
    float: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .boxprice{
    width: 40%;
    font-size: 36px;
    color: #00adfe;
    font-weight: bold;
    display: block;
    float: left;
    text-align:right;
  }
  .boxdesc{
    color: #666666;
    font-size: 32px;
    line-height: 50px;
    margin: 0;
    overflow: hidden;
    height: 100px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

</style>
