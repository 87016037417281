var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h4", [_vm._v("(四) 节气风险提示")]),
    _c("br"),
    _c(
      "div",
      {
        staticStyle: {
          width: "100%",
          display: "flex",
          "justify-content": "space-between"
        }
      },
      [
        _c("span", [
          _vm._v("当前节气：" + _vm._s(_vm.report.physical_term[0].term))
        ]),
        _c("span", [
          _vm._v(
            "患病概率：" +
              _vm._s(
                _vm.report.physical_term[0].illness_probability > 30
                  ? "较高"
                  : _vm.report.physical_term[0].illness_probability < 10
                  ? "低"
                  : "中"
              )
          )
        ])
      ]
    ),
    _c("br"),
    _vm.is_show_solar === "yes"
      ? _c("div", {
          style: { width: "615px", height: "350px", margin: "0 auto" },
          attrs: { id: "solarterm" }
        })
      : _c("img", {
          style: {
            display: "block",
            width: "615px",
            height: "350px",
            margin: "0 auto"
          },
          attrs: { src: _vm.solar_img_src, alt: "生成后的图" }
        }),
    _c("div", { staticClass: "text" }, [
      _c("span", [
        _vm._v("提示：" + _vm._s(_vm.report.physical_term[0].term_attention))
      ]),
      _c("br"),
      _c("span", [
        _vm._v(_vm._s(_vm.report.physical_term[0].term_add_attention))
      ])
    ]),
    _c("br"),
    _c(
      "p",
      {
        style:
          _vm.report.result.substring(0, 3) === "平和质"
            ? { "margin-top": "20px" }
            : { "margin-top": "40px" }
      },
      [
        _vm._v("节气养生原则："),
        _c("span", { staticClass: "textsty" }, [
          _vm._v(_vm._s(_vm.report.physical_term[0].term_principle))
        ])
      ]
    ),
    _c("p", [
      _vm._v("提示："),
      _c("span", { staticClass: "textsty" }, [
        _vm._v(_vm._s(_vm.report.physical_term[0].term_print_attention))
      ])
    ]),
    _c("br")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }