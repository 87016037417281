<template>
  <div v-if="marker === '10.1'">
    <div class="faceresult-pulse" v-if="pulse">
      <img :src="face_photo_url" style="width: 112px;height: 150px">
      <div @click="ReturnToFace" class="face-right-pulse">
        <span>查看面诊</span><br />
        <span>分析结果</span><br />
        <img :src="ckmzjg">
      </div>
    </div>
    <div v-else class="faceresult">
      <img :src="face_photo_url" style="width: 115px;height: 150px">
      <div @click="ReturnToFace" class="face-right">
        <span>查看面诊</span><br />
        <span>分析结果</span><br />
        <img :src="ckmzjg">
      </div>
    </div>
  </div>
  <div v-else>
    <div class="faceresult-pulse" v-if="pulse">
      <img :src="face_photo_url" style="width: 85px;height: 150px">
      <div @click="ReturnToFace" class="faced-right-pulse">
        <span>查看面诊</span><br />
        <span>分析结果</span><br />
        <img :src="ckmzjg">
      </div>
    </div>
    <div v-else class="faceresult">
      <img :src="face_photo_url" style="width: 85px;height: 150px">
      <div @click="ReturnToFace" class="faced-right">
        <span>查看面诊</span><br />
        <span>分析结果</span><br />
        <img :src="ckmzjg">
      </div>
    </div>
  </div>

</template>

<script>
  import ckmzjg from '../../../assets/img/report/ckmzjg_jcjg_icon.png';
  import {queryObject} from "../../../../utils/common";
  const { marker = '' } = queryObject();
  export default {
    name: "faceresult",
    props: {
      face_photo_url: String,
      report:Object,
      result:Object,
      pulse:Boolean,
    },
    data(){
      return{
        ckmzjg:ckmzjg
      }
    },
    computed:{
      marker(){
        return marker
      }
    },
    methods:{
      ReturnToFace(){
        this.$router.push({
          name:'Facereport',
          params: {
            facereport: this.result,
            id:this.$route.params.id,
            report:this.report,
            isFromReportHome: true
          }
        })
      }
    }
  }
</script>

<style scoped>
  .faceresult{
    width: 262px;
    height: 150px;
    float: left;
  }
  .faceresult-pulse{
    width: 214px;
    height: 150px;
    float: left;
  }
  .face-right{
    height: 127px;
    width: 147px;
    color: #ffffff;
    font-size: 24px;
    background: url("../../../assets/img/report/mzjgbj_jcjg_icon.png");
    display: inline-block;
    text-align: center;
    vertical-align: top;
    padding: 12px 0;
    background-size: 100% 100%;
    line-height: 35px;
  }
  .faced-right{
    height: 108px;
    width: 172px;
    color: #ffffff;
    font-size: 24px;
    background: url("../../../assets/img/report/mzjgbj_jcjg_icon.png");
    display: inline-block;
    text-align: center;
    vertical-align: top;
    padding: 21px 0;
    background-size: 100% 100%;
    line-height: 35px;
  }
  .face-right-pulse{
    height: 108px;
    width: 102px;
    color: #ffffff;
    font-size: 22px;
    background: url("../../../assets/img/report/mzjgbj10.1_jcjg_icon.png");
    display: inline-block;
    text-align: center;
    vertical-align:top;
    padding: 21px 0;
    background-size: 100% 100%;
    line-height: 35px;
  }
  .faced-right-pulse{
    height: 127px;
    width: 129px;
    color: #ffffff;
    font-size: 22px;
    background: url("../../../assets/img/report/mzjgbj10.1_jcjg_icon.png");
    display: inline-block;
    text-align: center;
    vertical-align: top;
    padding: 12px 0;
    background-size: 100% 100%;
    line-height: 35px;
  }

</style>
