// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/common/bj.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/physical/fh_ty_icon.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/img/report/btbj_icon.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/img/physical/kuang_lab_icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, ".knowledge[data-v-729887b7] {\n  width: 100%;\n  min-height: 100%;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  overflow: hidden;\n}\n.knowledge .header[data-v-729887b7] {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    width: 90%;\n    margin: 90px 0 0 5%;\n}\n.knowledge .header .back-btn[data-v-729887b7] {\n      background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;\n      background-size: 100% 100%;\n      width: 152px;\n      height: 82px;\n      color: #fff;\n      font-size: 36px;\n      text-align: center;\n      line-height: 75px;\n}\n.knowledge .header .header-title[data-v-729887b7] {\n      font-size: 48px;\n      color: #00ffa2;\n      font-family: TITLE;\n      width: 366px;\n      height: 111px;\n      margin-left: 16%;\n      background: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat;\n      text-align: center;\n      line-height: 111px;\n      background-size: 100% 100%;\n}\n.knowledge .container[data-v-729887b7] {\n    width: 80%;\n    margin-left: 5%;\n    height: 720px;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n    background-size: 100% 100%;\n    margin-top: 160px;\n    color: #ffffff;\n    font-size: 30px;\n    line-height: 66px;\n    padding: 140px 5% 0 5%;\n}\n", ""]);
// Exports
module.exports = exports;
