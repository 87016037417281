<template>
  <div class="wxhealthplan">
    <div class="title">养生方案</div>
    <!--  -->
    <div v-for="(item, index) in buttontype" :key="index">
      <div class="header">
        <img src="../../../assets/img/wx/wx_jkfabt_jcjg.png"/>
        <span>{{item}}</span>
        <img src="../../../assets/img/wx/wx_jkfabt1_jcjg.png"/>
      </div>
      <!-- 饮食调养 -->
      <div v-if="item === '饮食调养'">
        <div class="goodfood">
          <div class="left">
            <img src='../../../assets/img/wx/wx_ys_jcjg.png'/>
            <span>宜食</span>
          </div>
          <div class="right">
            <span v-for="(e,i) in goodfood" :key="i">{{e.name}} </span>
          </div>
        </div>
        <div class="goodfood badfood">
          <div class="left">
            <img src='../../../assets/img/wx/wx_js_jcjg.png'/>
            <span style="color:#f86426">忌食</span>
          </div>
          <div class="right">
            <span v-for="(e,i) in badfood" :key="i">{{e.name}} </span>
          </div>
        </div>
        <!--  -->
        <div class="recommond" v-if="reportData.solutions">
          <span class="food-title">推荐药膳：{{ysty ? ysty.name : ''}}</span>
          <img class="commom-img" :src="`${reportData.solutions.asset_prefix}${ysty.asset[0]}`"/>
          <span style="color: #333333;">烹煮方法：</span>
          <span v-for="(e, i) in ysty.content" :key="i" class="title3">{{e}}</span>
        </div>
      </div>
      <!-- 茶饮养生 -->
      <div v-if="item === '茶饮养生'">
        <van-tabs v-model="activeTea" :ellipsis="false" swipeable title-active-color="#30c3fa" color="#30c3fa" title-inactive-color="#333333">
          <van-tab v-for="(item, index) in tea" :key="index" :title="item.name">
            <img class="commom-img" :src="reportData.solutions.asset_prefix + item.asset[0]"/>
            <div v-if="reportData.symptoms[0].name !== '平和质'" class="tea-tip-title">配料：</div>
            <div class="common-text">{{item.content[0]}}</div>
            <div v-if="item.content.length > 1" class="tea-tip-title">泡煮方法：</div>
            <span class="common-text">{{item.content[1]}}</span>
            <div v-if="item.content.length > 2" class="tea-tip-title">功效：</div>
            <span class="common-text">{{item.content[2]}}</span>
          </van-tab>
        </van-tabs>
      </div>
      <!-- 香囊 -->
      <div v-if="item === '香囊'">
        <van-tabs v-model="activeXn" :ellipsis="false" swipeable title-active-color="#30c3fa" color="#30c3fa" title-inactive-color="#333333">
          <van-tab v-for="(item, index) in xnContent" :key="index" :title="item.name">
            <img class="commom-img" :src="`${reportData.solutions.asset_prefix + item.asset[0]}`"/>
            <div class="tea-title jdyf">组成：</div>
            <span class="common-text">{{item.content[0]}}</span>
             <div class="tea-title jdyf">功效：</div>
            <span class="common-text">{{item.content[1]}}</span>
            <div class="tea-title jdyf">适应症：</div>
            <span class="common-text">{{item.content[2]}}</span>
            <div class="tea-title jdyf">用法：</div>
            <span class="common-text">{{item.content[3]}}</span>
            <div class="tea-title jdyf">禁忌：</div>
            <span class="common-text">{{item.content[4]}}</span>
            <span class="common-text">{{item.content[5]}}</span>
          </van-tab>
        </van-tabs>
      </div>
      <!-- 经典药方 -->
      <div v-if="item === '经典药方'">
        <van-tabs v-model="active" :ellipsis="false" swipeable title-active-color="#30c3fa" color="#30c3fa" title-inactive-color="#333333">
          <van-tab v-for="(item, index) in jdyf" :key="index" :title="item.name">
            <div class="tea-title jdyf">药物组成：</div>
            <span class="common-text">{{item.content[0]}}</span>
            <div class="tea-title jdyf">功效：</div>
            <span class="common-text">{{item.content[1]}}</span>
          </van-tab>
        </van-tabs>
      </div>
      <!--  穴位按压 -->
      <div v-if="item === '穴位按压'">
        <div class="tea-title">{{xw.name}}</div>
        <!-- <img class="commom-img" :src="xwImg" /> -->
        <div class="commom-img">
        <video-player
          v-if="poster"
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
          >
        </video-player>
        </div>
        <span v-if="xw" class="tea-title">【位置】：</span>
        <div class="common-text">{{xw.content[0]}}</div>
        <span v-if="xw" class="tea-title">【操作】：</span>
        <div class="common-text">{{xw.content[1]}}</div>
        <span v-if="xw" class="tea-title">【功效】：</span>
        <div class="common-text">{{xw.content[2]}}</div>
        <span v-if="xw" class="tea-title">【主治】：</span>
        <div class="common-text">{{xw.content[3]}}</div>
      </div>
      <!--  精神调养 -->
      <div v-if="item === '精神调养'">
        <!-- <div class="tea-title">情志调畅</div> -->
        <div class="common-text">{{factorDetail.name}}</div>
      </div>
      <!-- '起居调摄' -->
      <div v-if="item === '起居调摄'">
        <div class="common-text">{{dailyDetail.name}}</div>
      </div>
      <!-- '运动保健' -->
      <div v-if="item === '运动保健'">
        <div class="common-text">{{sportDetail.name}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue-video-player/src/custom-theme.css';
import 'videojs-contrib-hls';

export default {
  props: {
    reportData: Object
  },
  data(){
    return {
      buttontype: null,
      goodfood: null,
      badfood: null,
      ysty: null,

      active: 0,
      jdyf: null,
      activeXn: 0,
      xnContent: null,

      activeTea: 0,
      tea: null,

      xw: null,
      xwImg: null,

      factorDetail: null,
      dailyDetail: null,
      sportDetail: null,

      poster:'',
      playerOptions:{
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        autoplay: false,
        muted: false,
        loop: false,
        preload: 'auto',
        language: 'zh-CN',
        aspectRatio: '16:9',
        fluid: true,
        sources: [{
          type: "video/mp4",
          src: '',
        }],
        poster: '',
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true
        }
      }
    }
  },
  beforeMount(){
    const symptomsName = this.reportData.symptoms[0].name;
    if(symptomsName === '平和质'){
      this.buttontype = ['饮食调养', '茶饮养生', '香囊', '穴位按压', '精神调养', '起居调摄', '运动保健']
    } else {
      this.buttontype = ['饮食调养', '茶饮养生', '香囊', '经典药方', '穴位按压', '精神调养', '起居调摄', '运动保健']
    }
    this.goodfood = this.reportData.solutions.tizhi_good_food[symptomsName];
    this.badfood = this.reportData.solutions.tizhi_bad_food[symptomsName];
    this.ysty = this.reportData.solutions.tizhi_diet_therapy[symptomsName];

    this.tea = this.reportData.solutions.tizhi_tea[symptomsName];
    // this.teaTitle = this.reportData.solutions.tizhi_tea[symptomsName];
    // this.teaImg = `${this.reportData.solutions.asset_prefix}${this.teaTitle.asset}`

    this.jdyf = this.reportData.solutions.tizhi_classic_prescription && this.reportData.solutions.tizhi_classic_prescription[symptomsName];
    this.xnContent = this.reportData.solutions.tizhi_perfume_bag[symptomsName]
    // 穴位按压
    this.xw = this.reportData.solutions.tizhi_massage[symptomsName]
    this.xwImg = `${this.reportData.solutions.asset_prefix}${this.xw.asset}`
    this.gf = this.reportData.solutions.tizhi_massage && this.reportData.solutions.tizhi_massage[symptomsName];
    this.poster = this.reportData.solutions.asset_prefix + this.gf.asset[1];
    this.playerOptions.sources[0].src = this.poster;
    this.playerOptions.poster = this.reportData.solutions.asset_prefix + this.gf.asset[0];

    this.factorDetail = this.reportData.solutions.tizhi_modern_factors[symptomsName]
    this.dailyDetail = this.reportData.solutions.tizhi_daily_life[symptomsName]
    this.sportDetail = this.reportData.solutions.tizhi_sport_health[symptomsName]

  },
}
</script>

<style lang="scss" scoped>
.wxhealthplan {
  background-color: #fff;
	border-radius: 30px;
  padding: 57px 46px 30px 51px;
  margin-top: 45px;
  .title {
    color: #333;
    font: {
      size: 48px;
      family: PingFangSC-Medium;
    };
    width: 260px;
    height: 50px;
    border-left: 6px solid #1288eb;
    text-align: center;
    margin-bottom: 30px;
  }
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 62%;
    margin: 75px 0 60px 19%;
    img {
      width: 150px;
      height: 29px;
    }
    span {
      font-family: PingFangSC-Regular;
	    font-size: 45px;
      color: #30c3fa;
      width: 260px;
      text-align: center;
    }
  }
  .common-one {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .commom-img {
    border-radius: 30px;
	  border: solid 1px #dcdcdc;
    margin: 30px 0;
    width: 100%;
  }
  .common-text {
    font-family: SourceHanSansCN-Regular;
    font-size: 39px;
    color: #666666;
    line-height: 62px;
  }

  .goodfood{
    @extend .common-one;
    border-radius: 30px;
	  border: solid 1px #dcdcdc;
    padding: 33px 44px 33px 0;
    .left {
      @extend .common-one;
      flex-direction: column;
      justify-content: center;
      width: 20%;
      img {
        width: 45px;
        height: 45px;
      }
      span {
        font-size: 42px;
        color: #35c8e3;
        font-family: SourceHanSansCN-Regular;
        margin-top: 10px;
      }
    }
    .right {
      width: 80%;
      font-family: PingFangSC-Regular;
	    font-size: 36px;
      color: #666666;
      line-height: 52px;
    }
  }
  .badfood {
    margin-top: 30px;
  }
  .recommond {
    display: flex;
    flex-direction: column;
    .food-title {
      font-size: 45px;
      font-family: PingFangSC-Regular;
      color: #333333;
      margin-top: 30px;
      width: 100%;
      text-align: center;
    }
    span {
      @extend .common-text;
    }
  }
  //
  .tea-title {
    font-size: 42px;
    font-family: SourceHanSansCN-Regular;
    color: #333333;
    line-height: 48px;
  }
  .tea-tip-title {
    font-size: 42px;
    font-family: PingFangSC-Regular;
    color: #333333;
    line-height: 48px;
    margin-bottom: 10px;
  }
  .jdyf {
    margin-top: 10px;
  }
}
</style>

<style>
  .wxhealthplan .van-tab {
    font-size: 42px;
    color: #333;
    line-height: 88px;
  }

  .wxhealthplan .van-tabs--line .van-tabs__wrap {
    height: 88px;
  }

  .wxhealthplan .van-tabs__line {
    width: 140px;
  }
</style>
