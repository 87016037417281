<template>
  <div v-if="report" class="print_report">
<!--    基本情况-->
    <Personaltzbs
      :reportId="report.display_id"
      :time="report.time"
      :owner="report.owner"
      :user_info="report.user_info"
      :cellphone="report.owner || report.agency_info"
      :chronicType="report.type_attention"
    ></Personaltzbs>
<!--    中医辩证分型 -->
    <Conclude :chronicType="report.type_attention" :symptoms="report.symptoms"></Conclude>
<!--    面舌脉诊-->
    <Analysistzbs
      :report="report"
    ></Analysistzbs>
    <div style="page-break-after:always;"></div>
<!--健康方案-->
    <Method :symptoms="report.symptoms"
            :solutions="report.solutions"></Method>
    <Footertzbs></Footertzbs>
  </div>
</template>

<script>
  import { getChronicReport } from '@/api/report.js';
  import Personaltzbs from './components/personaltzbs.vue';
  import Conclude from './chronic/conclude';
  import Analysistzbs from './components/analysistzbs.vue';
  import Method from './chronic/method.vue';
  import Footertzbs from './components/footertzbs.vue';

  export default {
    name: "chronic",
    data(){
      return {
        report: null,
        logoName: null,
        homeFootShow: null, // 0什么都不显示 1 显示祉云logo  2 显示其他公司logo  3 祉云和其他公司logo共存
      }
    },
    created() {
      this.getData();
    },
    methods:{
      getData(){
        getChronicReport({
          id: this.$route.params.id
        }).then(res => {
          if(res && res.status_code === 10000 && res.data) {
            // console.log(res.data)
            this.report = res.data;
            this.homeFootShow = res.data.vendor_setting.home_foot_show;
            this.logoName = res.data.vendor_setting.logo;
            this.$store.commit('SET_REPORT', res.data) // 将其更新到store里面对应的report字段
            document.title = res.data.type_attention + '打印报告'
          }
        })
      }
    },
    watch:{
      '$route': 'getData' // 路由一旦切换，就去重新请求一下对应报告的数据
    },
    components: {
      Personaltzbs,
      Conclude,
      Analysistzbs,
      Method,
      Footertzbs
    }
  }
</script>

<style scoped>
  .print_report {
    width: 100%;
    margin: 0 auto;
  }
</style>