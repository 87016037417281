// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/common/bj.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/physical/fh_ty_icon.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/img/report/btbj_icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".content-bg[data-v-6847712c] {\n  width: 100%;\n  min-height: 100%;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  overflow: hidden;\n}\n.header[data-v-6847712c] {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  width: 90%;\n  margin-top: 90px;\n  margin-left: 5%;\n}\n.header .back-btn[data-v-6847712c] {\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;\n    background-size: 100% 100%;\n    width: 152px;\n    height: 82px;\n    color: #fff;\n    font-size: 36px;\n    text-align: center;\n    line-height: 75px;\n}\n.header .header-title[data-v-6847712c] {\n    font-size: 60px;\n    color: #00fefb;\n    font-family: TITLE;\n    width: 330px;\n    height: 120px;\n    margin-left: 18%;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat;\n    text-align: center;\n    line-height: 120px;\n    background-size: 100% 100%;\n}\n", ""]);
// Exports
module.exports = exports;
