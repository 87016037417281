var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wxhealthyheader" }, [
    _c("div", { staticClass: "left" }, [
      _c("h2", [
        _vm._v(
          _vm._s(
            _vm.score <= 59 ? "可能有疾病" : _vm.score > 90 ? "健康" : "亚健康"
          )
        )
      ]),
      _c("div", { staticClass: "bottom" }, [
        _c("span", [_vm._v("报告编号：" + _vm._s(_vm.displayId))]),
        _c("span", [_vm._v("检测时间：" + _vm._s(_vm.time))])
      ])
    ]),
    _c("div", { staticClass: "right" }, [
      _c("div", { staticClass: "right-tip" }, [_vm._v("健康指数：")]),
      _c("div", { staticClass: "right-bottom" }, [
        _c("span", { class: Math.round(_vm.score) == 100 ? "score-100" : "" }, [
          _vm._v(_vm._s(Math.round(_vm.score)))
        ]),
        _c("span", { staticClass: "score" }, [_vm._v("分")])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }