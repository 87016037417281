var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "productTzbs" },
    [
      _c("product-tab", {
        attrs: { buttonType: _vm.tabList },
        on: { changed: _vm.changedIndex }
      }),
      _vm.currentIndex === 0 && _vm.productList && _vm.productList.length > 0
        ? _c(
            "div",
            { staticClass: "product" },
            _vm._l(_vm.productList, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "item",
                  on: {
                    click: function($event) {
                      return _vm.gotoDetail(item)
                    }
                  }
                },
                [
                  _c("img", { attrs: { src: item.image } }),
                  _c("div", { staticClass: "title" }, [
                    _c("span", { staticClass: "names" }, [
                      _vm._v(_vm._s(item.name))
                    ]),
                    item.price
                      ? _c("span", { staticClass: "price" }, [
                          _vm._v("¥ " + _vm._s(item.price))
                        ])
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "desc" }, [
                    _vm._v(_vm._s(item.desc))
                  ])
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm.currentIndex === 1 && _vm.doctorList && _vm.doctorList.length > 0
        ? _c(
            "div",
            [
              _c("doctor", {
                attrs: { doctorList: _vm.doctorList },
                on: { gotoDoctor: _vm.gotoDoctor }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }