<template>
  <div :class="reportData.has_pulse ? 'result-btn has_pulse' : 'result-btn' ">
    <div class="btn" @click="lookFaceResult">面诊结果</div>
    <div class="btn" @click="lookTongueResult">舌诊结果</div>
    <div class="btn" v-if="reportData.has_pulse" @click="lookPulseResult">脉诊结果</div>
  </div>
</template>

<script>
  export default {
    name: "analyze",
    props: {
      reportData: Object
    },
    methods: {
      lookFaceResult() {
        // 跳转到面诊
        // this.$router.push({name: 'Facetzbs'})

        const { report } = this.$store.state;
        this.$router.push({
          name:'Facereport',
          params: {
            facereport: report.face_result,
            id:report.id,
            report,
            isFromReportHome: true
          }
        })
      },
      lookTongueResult() {
        // 跳转到舌诊页面
        // this.$router.push({name: 'Tonguetzbs'})
        const { report } = this.$store.state;
        this.$router.push({
          name:'Tonguereport',
          params: {
            tonguereport: report.tongue_result,
            tongue_photo_url:report.tongue_photo_url,
            id:report.id,
            report,
            isFromReportHome: true
          }
        })
      },
      lookPulseResult() {
        const { report } = this.$store.state;
        const url = report.pulse_url;
        if(url) {
          // 太医脉诊页面
          if(window.zhiyun){
            window.zhiyun.gotopulse(url);
          } else {
            window.location = url;
          }
        } else {
          // 祉云脉诊页面
          this.$router.push({
            name: 'Pulse',
            params: {
              id: report && report.pulse[0] && report.pulse[0].mai_id
            }
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  $btnbg: '../../../assets/img/physical/mzjg_jcjg_icon.png';

  // 面舌诊按钮
  .result-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 30px;
    width: 80%;
    margin-left: 10%;
  .btn {
    width: 300px;
    height: 72px;
    line-height: 66px;
    background:url($btnbg) no-repeat;
    font-size: 28.6px;
    color: #fefefe;
    text-align: center;
  }
  }
  .has_pulse {
    width: 99%;
    margin-left: 0.5%;
  }
</style>