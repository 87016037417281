<template>
  <div class="facedetial" v-if="!isSpecialVersion">
    <div class="faceHeader">
      <span @click="goReport" class="back" v-if="!isOnlyFace">返 回</span>
      <span class="title">面诊结果</span>
      <span @click="goApp" class="close" v-if="isOnlyFace">关 闭</span>
    </div>
    <div class="faceContent" v-if="faceData">
      <div class="faceBox">
        <div class="imgBox">
          <div class="imagePics">
            <img src="../../../assets/img/menopauseovary/mxjg.gif" alt="面诊gif图" />
            <div class="riskImg" v-for="item in riskClass" :class="item" :key="item"></div>
          </div>
        </div>
        <div class="facePart">
          <p @click="doChoose('healthTab')" :class="activeClass('healthTab')" :style="styleObj">
            <span>{{isSpecialVersion? '结果': '健康提示'}}</span>
          </p>
          <p @click="doChoose('organsTab')" :class="activeClass('organsTab')" :style="styleObj" v-if="!hasNoRisk">
            <span>脏腑风险</span>
          </p>
          <template v-if="isOnlyFace && faceData.face_character">
<!--            <p @click="doChoose('organsTab')" :class="activeClass('organsTab')" :style="styleObj">-->
<!--              <span>脏腑风险</span>-->
<!--            </p>-->
            <p @click="doChoose('characterTab')" :class="activeClass('characterTab')" :style="styleObj">
              <span>性格</span>
            </p>
          </template>

        </div>
        <div class="healthTip" v-show="activeTab === 'healthTab'">
          <div class="partDetail" v-for="item in faceTitle" :key="item.name">
            <div class="partTitle">{{ item.name }}</div>
            <div class="partExplain">
              <p class="symptom"> {{ faceData[item.fieldName] && faceData[item.fieldName].symptom }}</p>
              <p class="explain" v-if="!isSpecialVersion"> {{ faceData[item.fieldName] && faceData[item.fieldName].name }}</p>
            </div>
          </div>
<!--          <div class="faceTip" v-if="!isSpecialVersion">-->
<!--            <div class="tipTitle">-->
<!--              <img src="../../../assets/img/common/jkts_mzjg_icon.png" alt="健康提示" />-->
<!--              <span>健康提示</span>-->
<!--            </div>-->
<!--            <div class="tipSign">{{ faceData.face_attention && faceData.face_attention[0].significance}}</div>-->
<!--            <div class="tipAttention">{{ faceData.face_attention && faceData.face_attention[0].attention}}</div>-->
<!--          </div>-->
        </div>
        <div v-show="activeTab === 'organsTab'" class="character">
          <template v-if="visceralRisk && visceralRisk.length > 0">
            <div class="organsPart" v-for="(item,index) in visceralRiskList" :key="item.part">
              <template v-if=" visceralRisk[index] && visceralRisk[index][item.field] && visceralRisk[index][item.field].attention">
                <p><img :src="item.img" :alt="item.part" />{{item.part}}</p>
                <div>
                  <p class="maybe">{{ visceralRisk[index][item.field].diagnose}}</p>
                  <p class="tips">提示：{{ visceralRisk[index][item.field].attention }}</p>
                </div>
              </template>

            </div>
          </template>

<!--          <div class="organsPart">-->
<!--            <p><img src="../../../assets/img/common/g_mzjg_icon.png" alt="肝" />肝</p>-->
<!--            <div class="middlePart">-->
<!--              <p class="maybe">可能肝火旺盛。</p>-->
<!--              <p class="tips">提示：鼻梁处有青春痘；鼻梁处有痣，且眼球发黄，面色非常黄</p>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="organsPart">-->
<!--            <p><img src="../../../assets/img/common/d_mzjg_icon.png" alt="胆" />胆</p>-->
<!--            <div>-->
<!--              <p class="maybe">可能胆部有了轻微炎症，早晨起床后嘴里发苦</p>-->
<!--              <p class="tips">提示：眉鼻的边缘两侧处有红血丝状、青春痘，或早晨起床后嘴里发苦</p>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <!--   以下为单独面诊报告，才有的版块-->
        <template v-if="faceData.face_character">
          <div class="character" v-show="activeTab === 'characterTab'" >
            <div id="characterChart" :style="{width: '500px', height: '500px', margin: '0 auto'}"></div>
            <div class="point">
              <div v-for="(item,index) in faceData.face_character.keyword" :key="index">
                {{ item }}
              </div>
            </div>
            <p class="desc">
              {{ faceData && faceData.face_character && faceData.face_character.character }}
            </p>
          </div>

        </template>
      </div>

    </div>
  </div>
  <div class="facedetial" v-else>
    <div class="faceHeader headerSpecial">
      <span @click="goReport" class="back">返 回</span>
      <span class="title">面部图片</span>
    </div>
    <div class="specialBg"></div>
    <img class="photo" :src="facePhoto" alt="面诊照片">
  </div>
</template>

<script>

  import * as echarts from 'echarts'
  import fh from '../../../assets/img/report/fh_icon.png';
  import close from '../../../assets/img/report/gb_jcjg_icon.png';
  import lung from '../../../assets/img/common/g_mzjg_icon.png';
  import liver from '../../../assets/img/common/f_mzjg_icon.png';
  import gallbladder from '../../../assets/img/common/d_mzjg_icon.png';
  import spleen from '../../../assets/img/common/pi.png';
  import bladder from '../../../assets/img/common/pg.png';
  import stomach from '../../../assets/img/common/wei.png';
  import smallIntestine from '../../../assets/img/common/xc.png';
  import largeIntestine from '../../../assets/img/common/dc.png';
  import breast from '../../../assets/img/common/xiong.png';
  import { deepClone } from '../../../../utils/deepClone.js'



  export default {
    name: "facedetail",
    props: {
      isOnlyFace: { // 是否是单独的面诊报告页面
        type: Boolean,
        required: false
      },
    },
    mounted(){
      const { report } = this.$store.state;
      const isTzbsReport = window.location.search && window.location.search.indexOf('category=2') !== -1;
      if(report.attention_display && isTzbsReport) {
        this.isSpecialVersion = true;
      }
      if(this.isOnlyFace && this.faceData.face_character) {
        const { forthright, tolerant, lazy, melancholy,optimistic,impulse, impatience,absorbed, self_discipline,soft_heart} = report.face_character;
        this.characterData.push(forthright, tolerant, lazy, melancholy,optimistic,impulse, impatience,absorbed, self_discipline,soft_heart);
        this.drawChart()
      }

    },
    data(){
      return {
        fh,
        close,
        faceTitle: [
          {name: '面色', fieldName: 'face_color_info'},
          {name: '唇色', fieldName: 'lip_color_info'},
          {name: '面部', fieldName: 'face_gloss_info'}
        ],
        facePhoto: this.$store.state.report && this.$store.state.report.face_photo_url,
        faceData: this.$store.state.report.face_result || this.$store.state.report, // 面诊数据（前者是完整报告、后者是单独面诊请求接口返回的数据）
        activeTab: 'healthTab', // 激活tab,默认为健康
        characterData: [], // 性格雷达图数据
        chartWidth: 0,
        isSpecialVersion: false, // 是否是特殊版本（申请医疗器械证需要）
        visceralRisk: this.$store.state.report && (this.isOnlyFace? this.$store.state.report.visceral_risk :
          (this.$store.state.report.face_result && this.$store.state.report.face_result.visceral_risk)),
        visceralRiskList: [
          {part: '肺', field: 'lung', img: lung },
          {part: '胸乳', field: 'breast', img: breast },
          {part: '膀胱', field: 'bladder', img: bladder },
          {part: '脾', field: 'spleen', img: spleen },
          {part: '肝', field: 'liver', img: liver},
          {part: '胃', field: 'stomach', img: stomach},
          {part: '胆', field: 'gallbladder', img: gallbladder,},
          {part: '小肠', field: 'smallIntestine', img: smallIntestine },
          {part: '大肠', field: 'largeIntestine', img: largeIntestine },

        ]
      }
    },
    computed: {
      styleObj(){
        // return { flex: this.isOnlyFace && this.faceData.face_character ? '0 0 33.3%' : '0 0 100%' }  // 增加了脏腑之后再修改
        return { flex: this.isOnlyFace && this.faceData.face_character ?
            (this.hasNoRisk? '0 0 50%' : '0 0 33.3%') :
            (this.hasNoRisk? '0 0 100%' : '0 0 50%') }
      },
      hasNoRisk(){
        // 判断是不是有脏腑风险内容
        const dataOrigin = this.visceralRisk; // 数据来源
        const noneRiskArr = dataOrigin.filter(val => {
          let propName = Object.getOwnPropertyNames(val)[0];
          return !(val[propName] && val[propName].attention)
        })
        return noneRiskArr.length === dataOrigin.length
      },
      riskClass(){
        const cloneArr = deepClone(this.visceralRisk); // 先深拷贝一份数据
        // 1.当肺区、胸乳区有风险时，额头部区域需要明暗变化；
        // 2.当肝区、胆区、脾区、胃区有风险时，鼻子区域需要明暗变化；
        // 3.当小肠区、大肠区有风险时，两边脸颊区域需要明暗变化；
        // 4.当膀胱区有风险时，鼻下人中区域需要明暗变化；
        // 5.当没有脏腑风险时，则不需要有明暗变化。
        const standarArr = [
          {filed:'lung', className: 'risk_top'},
          {filed:'breast', className: 'risk_top'},
          {filed:'liver', className: 'risk_center'},
          {filed:'gallbladder', className: 'risk_center'},
          {filed:'spleen', className: 'risk_center'},
          {filed:'stomach', className: 'risk_center'},

          {filed:'smallIntestine', className: 'risk_left risk_right'},
          {filed:'largeIntestine', className: 'risk_left risk_right'},
          {filed:'bladder', className: 'risk_bottom'},
        ];
        let riskArr = [];
        for(let item of cloneArr){
          for(let inner of standarArr){
            if(Object.getOwnPropertyNames(item)[0] === inner.filed && item[inner.filed] && item[inner.filed].attention){
              if(inner.className.indexOf(' ') !== -1){ // 如果是多个，当小肠区、大肠区有风险时，两边脸颊区域需要明暗变化；
                riskArr = [...riskArr, ...inner.className.split(' ')]
              } else {
                // 防止重复添加
                if(riskArr.indexOf(inner.className) === -1) {
                  riskArr.push(inner.className)
                }

              }

            }
          }
        }
        return riskArr
      }
    },
    methods: {
      goReport(){ // 返回报告页面主界面
        this.$router.back();
      },
      goApp(){ // 返回App主界面
        if (window.zhiyun) {
          window.zhiyun.closereport();
        }
      },
      doChoose(tab){ // 选中tab
        this.activeTab = tab;
      },
      activeClass(tab){ // 选中样式
        return {
          active: this.activeTab === tab
        }
      },
      drawChart(){
        const myChart = echarts.init(document.getElementById('characterChart'));
        myChart.setOption({
          color: ['#069eff'],
          radar: {
            center: ['50%', '50%'],
            startAngle: 90,
            radius: 180,
            splitNumber: 4, // 划分为几圈
            axisLine: { //坐标轴
              show: false
            },
            axisName: {
              formatter(value) {
                return `{a|${value}}`;
              },
              rich: {
                a: {
                  color: '#fff',
                  fontSize: 28,
                  lineHeight: 40,
                }
              },
            },
            splitLine: {
              lineStyle: {
                width: 1,
                color: '#00648a'
              }
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: 'transparent'
              }
            },
            indicator: [
              { name: '直率', max: 100 },
              { name: '宽容', max: 100 },
              { name: '懒惰', max: 100 },
              { name: '忧郁', max: 100 },
              { name: '乐观', max: 100 },
              { name: '冲动', max: 100 },
              { name: '急躁', max: 100 },
              { name: '专注', max: 100 },
              { name: '自律', max: 100 },
              { name: '心软', max: 100 },
            ]
          },
          series: [{
            type: 'radar',
            symbol: 'circle', // 拐点形状'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
            // 单个数据标记的图形。
            symbolSize: 20, // 拐点大小
            itemStyle: { // 拐点填充颜色
              normal: {
                color: "#00fefb",
              }
            },
            data: [
              {
                value: this.characterData,
                lineStyle: {
                  color: '#00fefb',
                  width: 3,
                  type:'solid'
                },
                areaStyle: { // 单项区域填充样式
                  normal: {
                    color: 'rgba(0,254,251,0.4)' // 填充的颜色。[ default: "#000" ]
                  }
                },
              },

            ]
          }]
        });

      },
    }
  }
</script>

<style lang="scss" scoped>
  $face_top: '../../../assets/img/shake/face_top.png';
  $face_left: '../../../assets/img/shake/face_left.png';
  $face_center: '../../../assets/img/shake/face_center.png';
  $face_bottom: '../../../assets/img/shake/face_bottom.png';


  .faceHeader {
    height: 200px;
  }
  .headerSpecial {
    position: relative;
    z-index: 2;
  }

  .facedetial{
    width:100%;
    height:100%;
    /*padding: 0 0 0 52px;*/
    background:url("../../../assets/img/report/bj1.png")no-repeat;
    background-size:cover;
    overflow:hidden;
    color: #ffffff;
    font-size: 36px;
  }

  .specialBg{
    width:100%;
    height:100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background:url("../../../assets/img/physical/zpbj_icon.png") no-repeat;
    background-size:cover;
  }

  .photo {
    position: absolute;
    top: 588px;
    left: calc(50% - 250px);
    /*图片是800*800 只要保证是个正方形就可以*/
    width: 500px;
    height: 500px;
    z-index: 2;
    border-radius: 50px;
  }



  ::-webkit-scrollbar{
    display: none;
  }

  .back,
  .close {
    top:105px;
    width: 152px;
    height: 82px;
    background:url("../../../assets/img/common/mzjg_back.png") no-repeat;
    color: #ffffff;
    text-align: center;
    font-weight: bold;
    font-size: 35px;
    line-height: 82px;
  }

  .back {
    position:relative;
    left:49px;
    display: block;
  }

  .close {
    position:absolute;
    right:49px;
  }

  .title{
    font-size: 60px;
    color:#00FEFB;
    font-family:TITLE;
    width:300px;
    height:111px;
    background:url("../../../assets/img/report/jssm_icon.png") no-repeat;
    text-align:center;
    line-height:111px;
    background-size:100% 100%;
    position: absolute;
    left: 0;
    top: 83px;
    right: 0;
    margin: 0 auto;
  }

  .faceContent {
    width: calc(100% - 98px);
    margin: 30px auto 0 auto;
    background: url("../../../assets/img/physical/kuang_lab_icon.png") no-repeat;
    background-size:100% 100%;
    overflow: auto;
    padding-bottom: 50px;

    .faceBox {
      width: calc(100% - 150px);
      margin: 0 auto;

      .imgBox {
        height: 325px;
        margin-top: 50px;

        .imagePics {
          height: 322px;
          width: 241px;
          margin: 0 auto;
          position: relative;

          img {
            height: 322px;
            width: auto;
            display: block;
            margin: 0 auto;
          }

          .riskImg {
            position: absolute;
            opacity: 0;
            animation: tongue-left-risk 5s infinite;
          }

          .risk_left,
          .risk_right {
            background:url($face_left) 100% no-repeat;
            width: 46px;
            height: 78px;
            top:48%;

          }

          .risk_left {
            left: 9%;
          }

          .risk_right {
            right: 9%;
          }

          .risk_center{
            background:url($face_center) 100% no-repeat;
            width: 39px;
            height: 54px;
            top:calc(50% - 22px);
            left: calc(50% - 23px);
          }

          .risk_top{
            background:url($face_top) 100% no-repeat;
            width: 139px;
            height: 49px;
            top: 74px;
            left: calc(50% - 70px);
          }

          .risk_bottom{
            background:url($face_bottom) 100% no-repeat;
            width: 37px;
            height: 35px;
            top: 198px;
            left: calc(50% - 20px);
          }




          @keyframes tongue-left-risk {
            from {opacity: 0;}
            to {opacity: 1;}
          }
        }

      }

      .facePart {
        display: flex;
        align-items: center;

        p {
          margin-top: 35px;
          margin-bottom: 35px;
          /*flex: 0 0 50%;*/
          text-align: center;
          font-size: 40px;
          font-weight: bold;
          line-height: 40px;


          span {
            display: inline-block;
          }
        }

        p:nth-child(2),
        p:nth-child(3){
          position: relative;
        }

        p:nth-child(2)::before,
        p:nth-child(3)::before {
          content: '';
          display: block;
          width: 1px;
          height: 100%;
          border: 2px solid #006f9a;
          position: absolute;
        }

        p.active {
          span {
            color: #00fefb;
            position: relative;
          }

          span::after {
            content: '';
            display: block;
            width: 100%;
            height: 36px;
            background: url("../../../assets/img/common/xzbj_mzjg_icon.png") no-repeat;
            background-size: 100% 100%;
            margin-top: -12px;
            position: absolute;
          }
        }
      }

      .healthTip {
        margin-top: 50px;
        padding-top: 50px;
        border-top: 2px solid #005281;
      }

      .partDetail {
        margin-bottom: 50px;
        display: flex;
        align-items: center;

        .partTitle {
          text-align: center;
          width: 111px;
          height: 121px;
          margin-right: 60px;
          line-height: 121px;
          background: url("../../../assets/img/common/ms_mzjg_icon.png") no-repeat;
          background-size: 100% 100%;
        }

        .partExplain {
          width: calc(100% - 180px);
        }

        .symptom {
          font-size: 34px;
          font-weight: bold;
          text-align: center;
          width: calc(100% + 170px);
          position: relative;
          left: -170px;
        }

        .explain {
          margin-top: 22px;
          font-size: 30px;
        }
      }

      .faceTip {
        margin-top: 45px;
        border-top: 2px solid #005281;
        padding-top: 30px;

        .tipTitle {
          font-size: 38px;

          img {
            height: 58px;
            vertical-align: middle;
            margin-right: 30px;
          }
        }

        .tipSign {
          text-align: center;
          font-size: 38px;
          font-weight: bold;
          margin: 40px 0;
        }

        .tipAttention {
          font-size: 30px;
          padding-bottom: 150px;
        }
      }

      .character {
        width: 100%;
        max-height: 1000px;
        overflow: auto;
        margin-top: 50px;
        padding-top: 10px;
        border-top: 2px solid #005281;

        .point {
          display: flex;
          flex-wrap: wrap;
          border-bottom: 2px dashed #777d7c;
          padding-bottom: 20px;

          div {
            width: 33.3%;
            font-size: 36px;
            padding-bottom: 20px;

          }

          div::before {
            content: '';
            display: inline-block;
            position: relative;
            width: 34px;
            height: 31px;
            background: url("../../../assets/img/report/gou_mzjg_icon.png") no-repeat;
            vertical-align: middle;
          }
        }

        .desc {
          font-size: 30px;
          text-indent: 2em;
          margin: 20px 0 60px 0;
          line-height: 50px;
          overflow: auto;
        }
      }

      .organsPart {
        display: flex;
        align-items: center;
        font-size: 34px;
        line-height: 44px;


        &>p {
          width: 70px;
          margin-right: 55px;
          text-align: center;
        }

        &>div {
          padding: 50px 0;
        }

        .middlePart {
          border-top: 2px solid #005281;
          border-bottom: 2px solid #005281;

        }


        .tips {
          color: #999999;
          font-size: 30px;
        }

        .maybe {
          font-size: 34px;
        }

      }

    }


  }

</style>