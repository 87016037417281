import { render, staticRenderFns } from "./constitutiontzbs.vue?vue&type=template&id=55bd47c2&scoped=true&"
import script from "./constitutiontzbs.vue?vue&type=script&lang=js&"
export * from "./constitutiontzbs.vue?vue&type=script&lang=js&"
import style0 from "./constitutiontzbs.vue?vue&type=style&index=0&id=55bd47c2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55bd47c2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\work\\zy_frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('55bd47c2')) {
      api.createRecord('55bd47c2', component.options)
    } else {
      api.reload('55bd47c2', component.options)
    }
    module.hot.accept("./constitutiontzbs.vue?vue&type=template&id=55bd47c2&scoped=true&", function () {
      api.rerender('55bd47c2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/print/components/constitutiontzbs.vue"
export default component.exports