// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/report/bj_sznr_icon.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/img/common/mzjg_back.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../assets/img/report/jssm_icon.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../../assets/img/physical/jkfa_jcjg_btn.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, "\n.detail[data-v-58701aff] {\n  width:100%;\n  height: 100%;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size:cover;\n  color: white;\n  overflow:auto;\n  position:absolute;\n  top:0;\n  left:0;\n}\n.detail[data-v-58701aff]::-webkit-scrollbar {\n  display: none;\n}\n.handContent[data-v-58701aff] {\n  width: 88%;\n  margin: 0 auto;\n}\n.basic[data-v-58701aff] {\n   display: flex;\n   justify-content: space-between;\n   align-items: center;\n}\n.basic>div[data-v-58701aff] {\n   width: 45%;\n   /*text-align: center;*/\n   font-size: 16px;\n   height: 73px;\n   border: 1px solid #01477d;\n}\n.faceHeader[data-v-58701aff] {\n  height: 200px;\n}\n.back[data-v-58701aff]{\n  top:105px;\n  width: 152px;\n  height: 82px;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;\n  color: #ffffff;\n  text-align: center;\n  font-weight: bold;\n  font-size: 35px;\n  line-height: 82px;\n  position:relative;\n  left:49px;\n  display: block;\n}\n.title[data-v-58701aff]{\n  font-size: 60px;\n  color:#00FEFB;\n  font-family:TITLE;\n  width:300px;\n  height:111px;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat;\n  text-align:center;\n  line-height:111px;\n  background-size:100% 100%;\n  position: absolute;\n  left: 0;\n  top: 83px;\n  right: 0;\n  margin: 0 auto;\n}\n.back-btn[data-v-58701aff] {\n   width: 334px;\n   height: 128px;\n   background:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") no-repeat;\n   font-size: 36px;\n   font-weight: normal;\n   color: #ffffff;\n   text-align: center;\n   line-height: 127px;\n   margin: 90px auto 0 auto;\n   padding-bottom: 100px;\n}\n.waiting[data-v-58701aff] {\n   font-size: 36px;\n   color: #fff;\n   width: 88%;\n   margin: 100px auto;\n}\n\n", ""]);
// Exports
module.exports = exports;
