var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("header", [
      _c("br"),
      _c("img", { attrs: { src: _vm.coverImg, width: "100%" } }),
      _c("div", { staticClass: "personal" }, [
        _c("p", [
          _vm._v("报告编号："),
          _c("span", [_vm._v(_vm._s(_vm.reportId))])
        ]),
        _c("p", [
          _vm._v("检测时间："),
          _c("span", [_vm._v(_vm._s(_vm.date.split(" ")[0]))])
        ])
      ])
    ]),
    _c("br"),
    _c("br"),
    _c("div", { staticClass: "basic" }, [
      _c("p", { staticStyle: { "font-weight": "bold" } }, [
        _vm._v(_vm._s(_vm.gender === "男" ? "先生" : "女士") + "，您好：")
      ]),
      _c("p", [
        _vm._v(
          "欢迎您体验" +
            _vm._s(_vm.reportType) +
            "中医健康状态评估，感谢您的信任与支持。"
        )
      ]),
      _c("p", [
        _vm._v(
          "以下为您" +
            _vm._s(
              this.time.split(" ")[0].split("-")[0] +
                "年" +
                this.time.split(" ")[0].split("-")[1] +
                "月" +
                this.time.split(" ")[0].split("-")[2] +
                "日"
            ) +
            "的中医检测报告，主要由" +
            _vm._s(_vm.partType) +
            "部分组成，请您认真阅读，希望能给您带来帮助。"
        )
      ]),
      _c("p", { staticClass: "explain" }, [
        _vm._v(
          "说明：此检测报告具有一定的时效性和局限性，受客观技术条件和数据采集环境影响，报告结果可能不完全准确，仅可作为参考。如有任何疾病或感到不适，请咨询专业医生。 "
        )
      ])
    ]),
    _c("div", [
      _c("h3", [_vm._v("一、个人健康档案")]),
      _c("br"),
      _c("h4", [_vm._v("(一) 基本情况")]),
      _c("br"),
      _c("table", [
        _c("tr", [
          _c("td", [_vm._v("姓名")]),
          _c("td", [_vm._v(_vm._s(_vm.user_info.name || "-"))]),
          _c("td", [_vm._v("性别")]),
          _c("td", [_vm._v(_vm._s(_vm.gender))]),
          _c("td", [_vm._v("年龄")]),
          _c("td", [
            _vm._v(_vm._s(_vm.user_info.ages === 0 ? "-" : _vm.user_info.ages))
          ])
        ]),
        !_vm.chronicType
          ? _c("tr", [
              _c("td", [_vm._v("身高(cm)")]),
              _c("td", [_vm._v(_vm._s(_vm.user_info.height))]),
              _c("td", [_vm._v("体重(kg)")]),
              _c("td", [_vm._v(_vm._s(_vm.user_info.weight))]),
              _c("td", [_vm._v("BMI")]),
              _c("td", [_vm._v(_vm._s(_vm.bmi))])
            ])
          : _vm._e(),
        _c("tr", [
          _c("td", [_vm._v("手机号")]),
          _c("td", { attrs: { colspan: "2" } }, [_vm._v(_vm._s(_vm.phone))]),
          _c("td", [_vm._v("身份证号")]),
          _c("td", { attrs: { colspan: "2" } }, [
            _vm._v(_vm._s(_vm.user_info.idcard || "-"))
          ])
        ]),
        _c("tr", [
          _c("td", [_vm._v("既往病史")]),
          _c(
            "td",
            { staticStyle: { "text-align": "left" }, attrs: { colspan: "5" } },
            [
              _vm._v(
                _vm._s(
                  _vm.user_info.medical_history.length <= 0
                    ? "无"
                    : _vm.user_info.medical_history.join("、")
                )
              )
            ]
          )
        ]),
        _vm.chronicType === "糖尿病"
          ? _c("tr", [
              _c("td", [_vm._v("并发症")]),
              _c(
                "td",
                {
                  staticStyle: { "text-align": "left" },
                  attrs: { colspan: "5" }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$store.state.report.complication &&
                        _vm.$store.state.report.complication.join("、")
                    )
                  )
                ]
              )
            ])
          : _vm._e()
      ]),
      _c("br")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }