<template>
  <div class="detail" >
    <div class="faceHeader">
      <span @click="goReport" class="back">返 回</span>
      <span class="title">脉诊结果</span>
    </div>
    <div class="handContent" v-if="isFinish && !isNoRecord">
      <Hand v-if="isFinish" :pulseData="leftResult" :title="leftHand"></Hand>
      <Hand v-if="isFinish" :pulseData="rightResult" :title="rightHand"></Hand>
      <AnalyseChart v-if="isShowEcharts" :data="chartData"></AnalyseChart>
      <Hand v-if="isFinish" :isTotal="true" :pulseData="totalPulse"></Hand>
      <Blood v-if="isFinish && hasBlood" :bloodData="bloodResult" ></Blood>
      <div class="back-btn" @click="goReport">确定</div>
    </div>
    <div v-if="!isFinish" class="waiting">
      正在为您查询...
    </div>
    <div v-if="isFinish && isNoRecord" class="waiting">
      暂无数据...
    </div>
  </div>
</template>

<script>
  import Hand from './hand.vue'
  import Blood from './blood_pressure.vue'
  import { getPulseReport } from '@/api/report';
  import AnalyseChart from "./AnalyseChart";

  export default {
    name: "pulseresult",
    components: {
      Hand,
      Blood,
      AnalyseChart
    },
    data(){
      return {
        pulse: {}, // 脉诊数据
        leftHand: '左手脉象',
        leftResult: {},
        rightHand: '右手脉象',
        rightResult: {},
        bloodData: {},  // 血压的数据
        bloodResult: {},
        isFinish: false, // 异步加载数据是否已经完成
        isNoRecord: false, // 默认没有脉诊数据
        totalPulse: {}, // 总脉
        hasBlood: false, // 是否有血压版块
        chartData: {}, // 脏腑气血柱状图数据
        isShowEcharts: true, // 是否展示脏腑气血板块
      }
    },
    mounted() {
      this.getData();
    },
    methods: {
      getData(){
        const reportId = this.$route.params.id;
        getPulseReport({reportId}).then( (res)=> {
          if(res && res.status_code === 10000) {
            const data = res.data;
            this.pulse = data;
            this.leftResult = {
              image: data.l_pulse_img, // 图片地址
              cun: data.l_cun, // 寸
              guan: data.l_guan, // 关
              chi: data.l_chi, // 尺
              tips: this.pulse.l_pulse_desc // 提示
            };
            this.rightResult = {
              image: data.r_pulse_img, // 图片地址
              cun: data.r_cun, // 寸
              guan: data.r_guan, // 关
              chi: data.r_chi, // 尺
              tips: data.r_pulse_desc // 提示
            };
            this.bloodResult = {
              blood_dia: data.blood_dia, // 舒张压
              blood_sys: data.blood_sys, // 收缩压
              heart_rate: data.heart_rate, // 心率
              blood_attention:data.blood_attention // 血压提示
            };
            this.totalPulse = { // 总脉
              final_pulse: data.final_pulse && data.final_pulse.replace(/,/g,'、'), // 总脉结论
              desc: data.final_pulse_desc // 总脉提示
            };

            if(!data.blood_dia || !data.blood_sys){ // 可能存在没有血压的结果
              this.hasBlood = false;
            } else {
              this.bloodData = { // 血压相关数据
                heart_rate: data.heart_rate, // 心率
                diastolic_pressure: data.blood_dia, // 舒张压
                systolic_pressure: data.blood_sys // 收缩压
              };
              this.hasBlood = true;
            }


            // 注意:右手的寸、关、尺数据可能为null，此时不展示脏腑气血板块

            const { heart_gas, liver_gas,  kidney_gas , lungs_gas,  spleen_gas, life_gate_gas,
              heart_blood, liver_blood, kidney_blood, lungs_blood, spleen_blood, life_gate_blood
            } = data;
            if(!lungs_gas) {
              this.isShowEcharts = false;
            }

            // 脏腑气血柱状图数据
            // 气[心，肝，肾，肺，脾，命门]    血[心，肝，肾，肺，脾，命门]
            //
            this.chartData = {
              gas: [heart_gas, liver_gas,  kidney_gas , lungs_gas,  spleen_gas , life_gate_gas],
              blood: [heart_blood, liver_blood, kidney_blood, lungs_blood, spleen_blood, life_gate_blood]
            }


          } else {
            throw Error('请求报告页面数据失败了');
          }
          this.isFinish = true;
        }).catch( () => {
          this.isNoRecord = true;
          this.isFinish = true;
        })
      },
      goReport(){ // 返回报告页面主界面
        // 区分首页的单独脉诊《===》报告详情页的脉诊
        if(window.zhiyun && window.zhiyun.pulseHome) {
          window.zhiyun.pulseHome();
        } else {
          this.$router.back();

        }

      }
    },
  }
</script>

<style scoped>
 .detail {
   width:100%;
   height: 100%;
   background:url("../../../assets/img/report/bj_sznr_icon.png");
   background-size:cover;
   color: white;
   overflow:auto;
   position:absolute;
   top:0;
   left:0;
 }

 .detail::-webkit-scrollbar {
   display: none;
 }

 .handContent {
   width: 88%;
   margin: 0 auto;
 }

  .basic {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .basic>div {
    width: 45%;
    /*text-align: center;*/
    font-size: 16px;
    height: 73px;
    border: 1px solid #01477d;
  }

 .faceHeader {
   height: 200px;
 }

 .back{
   top:105px;
   width: 152px;
   height: 82px;
   background:url("../../../assets/img/common/mzjg_back.png") no-repeat;
   color: #ffffff;
   text-align: center;
   font-weight: bold;
   font-size: 35px;
   line-height: 82px;
   position:relative;
   left:49px;
   display: block;
 }

 .title{
   font-size: 60px;
   color:#00FEFB;
   font-family:TITLE;
   width:300px;
   height:111px;
   background:url("../../../assets/img/report/jssm_icon.png") no-repeat;
   text-align:center;
   line-height:111px;
   background-size:100% 100%;
   position: absolute;
   left: 0;
   top: 83px;
   right: 0;
   margin: 0 auto;
 }

  .back-btn {
    width: 334px;
    height: 128px;
    background:url('../../../assets/img/physical/jkfa_jcjg_btn.png') no-repeat;
    font-size: 36px;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
    line-height: 127px;
    margin: 90px auto 0 auto;
    padding-bottom: 100px;
  }

  .waiting {
    font-size: 36px;
    color: #fff;
    width: 88%;
    margin: 100px auto;
  }

</style>