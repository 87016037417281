var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tab" }, [
    _c("div", { ref: "headertab", staticClass: "header_tab" }, [
      _c(
        "ul",
        { ref: "tabitem" },
        _vm._l(_vm.buttonType, function(item, index) {
          return _c(
            "li",
            {
              key: index,
              class: item.id === _vm.currentIndex ? "activeheader" : "",
              on: {
                click: function($event) {
                  return _vm.getTab(item.id, $event)
                }
              }
            },
            [
              _c("div", { staticClass: "tab-content" }, [
                _c("img", { attrs: { src: item.img, alt: item.title } }),
                _c("span", [_vm._v(_vm._s(item.title))])
              ])
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }