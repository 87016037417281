<template>
  <div v-if="reportData" class="reportchronic">
    <!-- 关闭按钮 -->
    <div v-if="type !== 'personal'" class="close-btn" @click="doclose"></div>
    <!-- 顶部个人信息 -->
    <reportheader :reportData="reportData"></reportheader>
    <!-- 中医辩证分型 -->
    <chronicchart
      :symptoms="reportData.symptoms"
      :symptomStatus="reportData.symptom_status"
      :beforeSymptomStatus="reportData.before_symptom_status"
      :typeAttention="reportData.type_attention"
    ></chronicchart>
    <!-- 打分仪表盘-->
    <indicator :health_status="reportData.health_status"
               :symptoms="reportData.symptoms"
               :symptomDefinition="reportData.solutions.chronic_disease_definition"
               :tip="reportData.chronic_disease_attention"
    ></indicator>
    <!--  面舌脉诊分析-->
    <analyze :reportData="reportData"></analyze>
    <div class="tip">此报告仅作参考，如有不适请及时就医</div>
    <!-- 养生方案 -->
    <planbtn :reportData="reportData" :isChronic="true"></planbtn>
    <!--  底部 -->
    <div v-if="csr !== 'SHBAZE' && homeFootShow != null" :class="marker === '10.1' ? 'bottom bottom-ten' : 'bottom'">
      <img v-if="csr !== 'CHENSHANG'" :class="homeFootShow === 0 || homeFootShow === 2 ? '' : 'tipImg'" :src="csr === 'BJTRTKH' ? trt : csr === 'SHWYSLSWKJ' ? wysl : (homeFootShow === 0 || homeFootShow === 2 ? '' : logo )"/>
      <span class="tipTxt">{{csr === 'BJTRTKH' ? '中国北京同仁堂（集团）有限责任公司' : (csr === 'CHENSHANG' ? '上海辰尚信息科技有限公司' : (csr === 'SHWYSLSWKJ' ? '上海伍月石榴生物科技有限公司' :
        (homeFootShow === 0 ? '' : homeFootShow === 1 ? '上海祉云医疗科技有限公司' : homeFootShow === 2 ? logoName : `上海祉云医疗科技有限公司     |     ${logoName}` ) ))}}</span>
    </div>
  </div>
  <div v-else class="nonechronic"></div>
</template>

<script>
  import { queryObject } from '../../../utils/common.js';
  import { getChronicReport } from '@/api/report.js';
  import reportheader from '@/components/physical/reportheader.vue';
  import chronicchart from './components/charts';
  import indicator from "./components/indicator";
  import analyze from "./components/analyze";
  import planbtn from '@/components/physical/planbtn.vue';

  import logo from '../../assets/img/common/company-logo-color.png';
  import trt from '../../assets/img/common/trt_sy_icon.png';
  import wysl from '../../assets/img/common/wysllogo.png';


  const { csr = '',  marker = '' } = queryObject();

  export default {
    name: "index",
    data(){
      return {
        reportData: null,
        type: '',
        logo: logo,
        trt: trt,
        wysl: wysl,
        logoName: null,
        homeFootShow: null, // 0什么都不显示 1 显示祉云logo  2 显示其他公司logo  3 祉云和其他公司logo共存
      }
    },
    created() {
      this.getData();
    },
    methods:{
      getData(){
        getChronicReport({
          id: this.$route.params.id
        }).then(res => {
          if(res && res.status_code === 10000 && res.data) {
            // console.log(res.data)
            this.reportData = res.data;
            this.homeFootShow = res.data.vendor_setting.home_foot_show;
            this.logoName = res.data.vendor_setting.logo;
            this.$store.commit('SET_REPORT', res.data) // 将其更新到store里面对应的report字段
            document.title = res.data.type_attention + '健康管理'
          }
        })
      },
      doclose() {
        if (window.zhiyun) {
          window.zhiyun.closereport();
        }
      },
    },
    computed: {
      csr(){
        return csr
      },
      marker(){
        return marker
      }
    },
    components: {
      reportheader,
      chronicchart,
      indicator,
      analyze,
      planbtn
    }
  }
</script>

<style lang="scss" scoped>
  $contentWidth: 94%;
  $leftContent: 3%;
  $bgImg: '../../assets/img/common/bj.png';
  $clostimg: '../../assets/img/report/gb_jcjg_icon.png';

  .reportchronic {
    width:100%;
    min-height: 100%;
    background:url($bgImg);
    background-size:cover;
    overflow: hidden;
    background-color: #00090a;
    .close-btn {
      position: absolute;
      right: 30px;
      top: 30px;
      width: 120px;
      height: 77px;
      background:url($clostimg);
    }

    .tip {
      width: $contentWidth;
      margin-left: $leftContent;
      font-size: 24px;
      line-height: 48px;
      color: #678e9f;
      text-align: right;
      margin-bottom: 4px;
    }

    .bottom {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 100px;
      .tipImg {
        width: 50px;
        height: 50px;
      }
      .tipTxt {
        height: 42px;
        font-size: 30px;
        font-weight: bold;
        color: #ffffff;
        margin-left: 20px;
      }
    }
    .bottom-ten {
      height: 60px;
    }
  }

  .nonechronic {
    width:100%;
    min-height: 100%;
    background-color: #00090a;
  }
</style>