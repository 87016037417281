var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm.score < 90
      ? _c("div", [
          _vm._m(0),
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c("img", { attrs: { src: _vm.qjysimg } })
          ])
        ])
      : _c("div", [
          _c(
            "div",
            { staticStyle: { "text-align": "center", "padding-top": "20px" } },
            [
              _c("img", {
                staticStyle: { "margin-top": "150px" },
                attrs: { src: _vm.jk }
              })
            ]
          )
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box" }, [
      _c("div", { attrs: { id: "main" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }