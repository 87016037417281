var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "scoreschart" }, [
    _c("div", { staticClass: "title" }, [_vm._v("体质历史记录")]),
    _c("div", {
      style: { width: "100%", height: "606px", backgroundColor: "#fff" },
      attrs: { id: "scores" }
    }),
    _vm.attention.attention
      ? _c("div", { staticClass: "tip" }, [
          _vm._v("提示：" + _vm._s(_vm.attention.attention))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }