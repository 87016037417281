<template>
  <div class="tab">
    <div class="header_tab" ref="headertab">
      <ul ref="tabitem">
        <li
          v-for="item in buttonType"
          :key="item.index"
          :class="item.index === currentIndex ? 'activeheader' : ''"
          @click="getTab(item.index, $event)"
        >
         {{ item.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'headerTab',
  props: {
    buttonType: Array,
  },
  data(){
    return {
      currentIndex: this.$store.state.report.product && this.$store.state.report.product.length > 0 ? 0 : 1,
    }
  },
  methods: {
    getTab(index, e) {
      this.currentIndex = index
      this.$emit('changed',index)
    },
  }
}
</script>

<style lang="scss" scoped>
.tab {
  width: 90%;
  margin-left: 5%;
  height: 100px;
  display: flex;
  align-items: center;
  // background-color: #ccc;
}

.header_tab {
  display: flex;
  flex-wrap: nowrap;
  overflow: scroll;
}

.header_tab::-webkit-scrollbar {
  display: none;
}

ul {
  display: inline-block;
  white-space: nowrap;
}

li {
  display: inline-block;
  margin: 0 100px 0 0;
  font-size: 48px;
  line-height: 80px;
  color: #fff;
}

.activeheader {
  font-size: 48px;
  line-height: 80px;
  color: #00fefb;
  position: relative;
}

.activeheader:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 4px;
  bottom: 0px;
  left: 1px;
  background-color: #00fefb;
  border-radius: 2px;
}
</style>
