var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wxviscerachart" }, [
    _c("div", { staticClass: "title" }, [_vm._v("脏腑风险")]),
    _c("div", {
      style: { width: "100%", height: "590px" },
      attrs: { id: "viscera" }
    }),
    _c("div", { staticClass: "text" }, [
      _vm._v("提示：" + _vm._s(_vm.viscera.viscera_attention))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }