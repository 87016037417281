<template>
  <div v-if="marker === '10.1'" class="indicator" style="height: 295px">
    <div class="circular" style="background-size: 86% 100%;width: 468px;height: 295px;margin-top: 0;">
      <div class="circular-content">
        <span class="fraction" :style="{'color':score> 89 ? '#00d73d' :score > 59 ? '#FE822B' : 'red','fontSize':'60px','margin':'57px 0 0 43px'}">{{Math.round(score)}}</span>
        <span class="text" style="margin: 12px 0 0 24px;font-size: 19px;">健康指数(分)</span>
      </div>
    </div>
    <div class="circular-picture" :style="{'background': `url(${require(`../../../assets/img/report/audio/animation${Math.round(this.score / 3) + 1}.gif`)}) no-repeat`,'backgroundSize':'100% 100%'}"></div>
    <div class="health10">
      <span class="health-name" :style="{'color':score> 89  ? '#00d73d' :score > 59 ? '#FE822B' : 'red'}">{{score<60?"可能有疾病":score<90?"亚健康":"健康"}}</span>
      <span class="health-proposal">{{score> 89?null:"建议找中医专家调治"}}</span>
      <div style="margin-top: 17px">
        <span class="symptom" @click="ReturnToExplain(item.name)" v-for="(item, index) in report.symptoms" :key="index">{{report.symptoms?item.name:'健康'}}</span>
      </div>
      <div style="margin-top: 7px">
        <span style="display: inline-block" class="describe">健康：90-100分</span>
        <span style="display: inline-block" class="describe">亚健康：60-89分</span>
        <span style="display: inline-block" class="describe">可能有疾病：0-59分</span>
      </div>
      <div v-if="myallreport === '1' && type !== 'personal'">
        <div class="qrcode" v-if="csr === 'HUASHI' || csr === 'SHBAZE'|| csr === 'CHENSHANG'|| csr === 'SHWYSLSWKJ'|| csr === 'SXSZYYY'">
          <qrcode-vue :value='this.url' size='86'></qrcode-vue>
        </div>
        <div v-else class="qrcode-zy">
<!--          <img :src="code" style="width: 86px;height: 86px;">-->
          <img :src="report.qr_url" v-if="report.vendor_setting.qr_code_show === 2" style="width: 86px;height: 86px;">
          <qrcode-vue v-else-if="this.shareUrl&&report.vendor_setting.qr_code_show === 1" :value='report.report_url' size='86'></qrcode-vue>
          <img :src="code" v-else-if="this.shareUrl&&report.vendor_setting.qr_code_show === 0" style="width: 86px;height: 86px;">
          <div v-else></div>
        </div>
      </div>
      <div class="scancode" v-if="myallreport === '1' && type !== 'personal'">
        微信扫码带走报告
      </div>
    </div>
  </div>
  <div v-else class="indicator">
    <div class="circular">
      <div class="circular-content">
        <span class="fraction" :style="{'color':score> 89 ? '#00d73d' :score > 59 ? '#FE822B' : 'red'}">{{Math.round(score)}}</span>
        <span class="text">健康指数(分)</span>
      </div>
    </div>
    <div class="circular-pic" :style="{'background': `url(${require(`../../../assets/img/report/audio/animation${Math.round(this.score / 3) + 1}.gif`)}) no-repeat`}"></div>
    <div class="health">
      <span class="health-name" :style="{'color':score> 89  ? '#00d73d' :score > 59 ? '#FE822B' : 'red'}">{{score<60?"可能有疾病":score<90?"亚健康":"健康"}}</span>
      <span class="health-proposal">{{score> 89?null:"建议找中医专家调治"}}</span>
      <div style="margin-top: 17px">
        <span class="symptom" @click="ReturnToExplain(item.name)" v-for="(item, index) in report.symptoms" :key="index">{{report.symptoms?item.name:'健康'}}</span>
      </div>
      <div style="margin-top: 7px">
        <span class="describe">健康：90-100分</span>
        <span class="describe">亚健康：60-89分</span>
        <span class="describe">可能有疾病：0-59分</span>
      </div>
      <div class="allreport" v-if="myallreport === '1' && type !== 'personal'" @click="myreport">
        历史报告
      </div>
    </div>
  </div>
</template>

<script>
  import {queryObject} from "../../../../utils/common";
  const { myallreport = '' , type = '' , csr = '' , marker = '' } = queryObject();
  import code from '../../../assets/img/report/wechatcode.png';
  import QrcodeVue from 'qrcode.vue'
  export default {
    name: "indicator",
    props: {
      score: Number,
      report:Object,
    },
    components: {
      QrcodeVue,
    },
    data(){
      return{
        shareUrl:window.location.href,
        url:window.location.href,
        code:code,
      }
    },
    created() {
    },
    mounted() {
      const csr2 = csr || '';
      this.url = `https://${window.location.host}/?type=personal&csr=${csr2}#/report/${this.report.id}`;
    },
    methods:{
      ReturnToExplain( val ){
        this.$router.push({name:'Constitution', params: {reportData: this.report,definition:val,id:this.$route.params.id}})
      },
      myreport(){
        if (window.zhiyun) {
          window.zhiyun.myallreport(this.report.owner.cellphone, this.report.id);
        }
      }
    },
    computed:{
      myallreport(){
        return myallreport
      },
      type(){
        return type
      },
      csr(){
        return csr
      },
      marker(){
        return marker
      }
    }
  }
</script>

<style scoped>
  .indicator{
    width: 100%;
    height: 394px;
    /*border: 2px solid red;*/
  }
  .circular{
    position: relative;
    display: inline-block;
    float: left;
    width: 512px;
    height: 367px;
    margin-top: 13px;
    background:url("../../../assets/img/report/jkzs_jcjg_icon_1.gif") no-repeat;
    background-size:100% 100%;
  }
  .circular-content{
    display: inline-block;
    width: 202px;
    height: 202px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .fraction{
    display: inline-block;
    font-size: 76px;
    font-family: SourceHanSansCN-Regular;
    font-weight: bold;
    color: #ffffff;
    margin:31px 0 0 66px;
  }
  .text{
    display: inline-block;
    font-size: 23px;
    font-family: SourceHanSansCN-Regular;
    color: #75B0D9;
    margin:17px 0 0 49px;
  }
  .circular-pic{
    position: absolute;
    width: 285px;
    height: 285px;
    top: 372px;
    left: 146px;
  }
  .circular-picture{
    position:absolute;
    width:187px;
    height:187px;
    top:296px;
    left:116px;
  }
  .health10{
    position: relative;
    display: inline-block;
    float: right;
    width: 537px;
    height: 243px;
    background: url("../../../assets/img/report/jkztbj_jcjg_icon10.1.png");
    background-size: 100% 100%;
    margin: 15px 40px 0 0;
    padding:25px 0 0 33px;
  }
  .health{
    position: relative;
    display: inline-block;
    float: right;
    width: 472px;
    height: 305px;
    background: url("../../../assets/img/report/jkztbj_jcjg_icon10.1.png");
    background-size: 100% 100%;
    margin: 15px 40px 0 0;
    padding:25px 0 0 33px;
  }
  .health-name{
    display: block;
    font-size: 60px;
    color:#FE822B;
    font-family:AdobeHeitiStd-Bole;
    font-weight:bold;
  }
  .health-proposal{
    display:inline-block;
    font-size: 30px;
    color:#72C0D6;
    font-family:AdobeHeitiStd-Regular;
    margin-top:15px;
  }
  .symptom{
    display: inline-block;
    width: 92px;
    height: 65px;
    background: url("../../../assets/img/report/qx_jcjg_icon.png");
    background-size: 100% 100%;
    margin-right: 20px;
    color: #ffffff;
    font-size: 30px;
    line-height: 65px;
    padding:0 0 0 24px;
  }
  .describe{
    display: block;
    margin-bottom: 12px;
    font-size: 20px;
    color: #678E9F;
    font-family: SourceHanSansCN-Regular;
  }
  .allreport{
    position: absolute;
    width: 226px;
    height: 77px;
    background: url("../../../assets/img/report/bg_jcjg_icon.png");
    background-size: 100% 100%;
    margin-right: 20px;
    left: 240px;
    top: 235px;
    color: #ffffff;
    font-size: 30px;
    text-align: center;
    line-height: 77px;
  }
  .scancode{
    position: absolute;
    left: 440px;
    top: 108px;
    font-size: 16px;
    color: white;
  }
  .qrcode{
    position: absolute;
    background: white;
    left: 446px;
    top: 20px;
    width: 86px;
    height: 86px;
    padding: 2px;
  }
  .qrcode-zy{
    position: absolute;
    background: white;
    left: 446px;
    top: 20px;
    width: 84px;
    height: 84px;
  }
</style>
