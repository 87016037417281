<template>
  <div>
    <div>
      <h4>({{ isChronic? num(0) : num(2) }})面诊分析</h4><br/>
      <div class="faceTable">
        <table>
          <tr>
            <td rowspan="4" class="faceImg">
              <img :src="report.face_photo_url" alt="面诊图片" />
            </td>
            <td class="faceTitle"><b>类型</b></td>
            <td class="faceTitle"><b>结果</b></td>
            <td class="faceTitle"><b>参考指标</b></td>
          </tr>
          <tr>
            <td class="faceTitle">面色</td>
            <td class="faceTitle">{{report.face_result['face_color_info'].symptom}}</td>
            <td class="faceTitle">正常</td>
          </tr>
          <tr>
            <td class="faceTitle">唇色</td>
            <td class="faceTitle">{{report.face_result['lip_color_info'].symptom}}</td>
            <td class="faceTitle">唇红</td>
          </tr>
          <tr>
            <td class="faceTitle">面部光泽</td>
            <td class="faceTitle">{{report.face_result['face_gloss_info'].symptom}}</td>
            <td class="faceTitle">有光泽</td>
          </tr>
<!--          <tr>-->
<!--            <td colspan="3" class="faceTotal">-->
<!--              <p>【健康提示】：</p>-->
<!--              <p class="tipAttention">{{ report.face_result.face_attention[0].attention}}</p>-->
<!--            </td>-->
<!--          </tr>-->
        </table>
      </div>
    </div>

    <h4>({{ isChronic? num(1) : num(3) }})舌诊分析</h4><br/>
    <div class="faceTable">
      <table>
        <tr>
          <td rowspan="5" class="tongueImg">
            <img :src="report.tongue_photo_url" alt="舌诊图片" />
          </td>
          <td class="faceTitle"><b>类型</b></td>
          <td class="faceTitle"><b>结果</b></td>
          <td class="faceTitle"><b>参考指标</b></td>
        </tr>
        <tr>
          <td class="tongueTitle">舌色</td>
          <td class="tongueTitle">{{report.tongue_result['tongue_nature_color_info'].symptom}}</td>
          <td class="tongueTitle">舌淡红</td>
        </tr>
        <tr>
          <td class="tongueTitle">舌形</td>
          <td class="tongueTitle">{{report.tongue_result.tongueFatThin === 0 ? (report.tongue_result.tongueCrack === 0 ? '正常' : '有裂痕') : (report.tongue_result.tongueCrack === 0 ? '舌胖' : '舌胖、有裂痕')}}</td>
          <td class="tongueTitle">正常，无裂纹</td>
        </tr>
        <tr>
          <td class="tongueTitle">苔色</td>
          <td class="tongueTitle">{{report.tongue_result['tongue_coat_color_info'] ? report.tongue_result['tongue_coat_color_info'].symptom : '未识别到'}}</td>
          <td class="tongueTitle">苔白</td>
        </tr>
        <tr>
          <td class="tongueTitle">苔质</td>
          <td class="tongueTitle">{{report.tongue_result.tongue_coat_thickness_info.symptom}}</td>
          <td class="tongueTitle">薄苔</td>
        </tr>
<!--        <tr>-->
<!--          <td colspan="3" class="tongueTotal">-->
<!--            <p>【健康提示】：</p>-->
<!--            <div class="tipAttention">{{ report.tongue_result.tongue_attention && report.tongue_result.tongue_attention[0].attention}}</div>-->
<!--          </td>-->
<!--        </tr>-->
      </table>
    </div>

    <div v-if="isChronic && pulseResult && pulseResult.length > 0" class="pulseResult">
      <h4>({{ num(2) }})脉诊分析</h4><br/>
      <img :src="pulseResult[0].pulse_image" alt="脉诊图片" />
      <p>总脉： {{ pulseResult[0].summary.replace(/,/g, '、') }}</p>
      <p>提示：{{ pulseResult[0].summary_attention }}</p>
      <div v-if="pulseResult[0].blood_dia || pulseResult[0].blood_sys">
        <h4 :style="{marginTop: '20px'}">血压：</h4>
        <table>
          <tr>
            <td>舒张压</td>
            <td>{{pulseResult[0].blood_dia}}mmHg</td>
            <td>收缩压</td>
            <td>{{pulseResult[0].blood_sys}}mmHg</td>
            <td>心率</td>
            <td>{{pulseResult[0].heart_rate}}次/分</td>
          </tr>
        </table>
        <p>提示：{{ pulseResult[0].blood_attention }}</p>
      </div>

    </div>

  </div>

</template>

<script>
  export default {
    name: "analysistzbs",
    props: {
      report: Object, // faceResult faceUrl tongueResult tongueUrl handResult handUrl
    },
    methods: {
      num(index){
        const dict = ['三','四','五','六'];
        return dict[index];
      },
    },
    computed: {
      isChronic(){
        return this.report.type_attention === '高血压' || this.report.type_attention === '糖尿病'
      },
      pulseResult(){
        return this.report.pulse;
      }
    }

  }
</script>

<style scoped>
  .faceTable {
    padding-bottom: 0px;
  }

  table {
    border-collapse:collapse;
    /*color: rgb(204, 204, 204);*/
    font-size: 17px;
    margin-bottom: 10px;
  }

  table,
  tr,
  td {
    border: 2px solid rgb(204, 204, 204);
  }

  td {
    padding: 10px 0;
  }

  .faceTitle,
  .tongueTitle{
    width: 18%;
    text-align: center;
  }

  .faceTotal,
  .tongueTotal{
    width: 350px;
  }

  .faceImg,
  .tongueImg{
    width: 32%;
    text-align: center;
  }

  .faceImg img,
  .tongueImg img
  {
    display: block;
    width: 70%;
    margin: 0 auto;
  }


  .circle>div {
    width: 100%;
  }

  .circle p {
    text-align: center;
  }

  .tipAttention {
    /*text-indent: 2em;*/
  }

  .circle>div {
    width: 100%;
  }

  .circle p {
    text-align: center;
  }

  .pulseResult img {
    height: 300px;
    width: auto;
    margin: 0 auto;
    display: block;
  }

  .pulseResult td:nth-child(odd){
    width: 100px;
  }

  .pulseResult td:nth-child(even){
    width: 200px;
  }

</style>
