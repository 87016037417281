// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/report/bj1.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/report/btbj_icon.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/img/report/dyjs_zzfx_icon.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/img/report/jkfx_zzfx_icon.png");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../assets/img/report/knyy_zzfx_icon.png");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../assets/img/report/jssm_zzfx_icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, "\n.constitution[data-v-d712dc68]{\n  width:100%;\n  height:100%;\n  padding: 0 0 0 52px;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size:cover;\n  overflow:hidden;\n}\n[data-v-d712dc68]::-webkit-scrollbar{\n  display: none;\n}\n.title[data-v-d712dc68]{\n  font-size: 60px;\n  color:#00FEFB;\n  font-family:TITLE;\n  width:330px;\n  height:110px;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;\n  text-align:center;\n  line-height:110px;\n  background-size:100% 100%;\n  position: absolute;\n  left: 0;\n  top: 83px;\n  right: 0;\n  margin: 0 auto;\n}\n.back[data-v-d712dc68]{\n  position:relative;\n  left:49px;\n  top:105px;\n  width: 152px;\n  height: 82px;\n}\n.definition[data-v-d712dc68]{\n  position:relative;\n  width:976px;\n  height:355px;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  background-size:100% 100%;\n}\n.definition-content[data-v-d712dc68]{\n  position:absolute;\n  height:80%;\n  width: 65%;\n  margin: auto;\n  left:30%;\n  top:0;\n  bottom:0;\n  color:white;\n  font-size:30px;\n  line-height: 45px;\n}\n.risk[data-v-d712dc68]{\n  position:relative;\n  width:976px;\n  height:484px;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n  background-size:100% 100%;\n  margin-top:2%;\n}\n.reason[data-v-d712dc68]{\n  position:relative;\n  width:976px;\n  height:384px;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n  background-size:100% 100%;\n  margin-top:2%;\n}\n.statement[data-v-d712dc68]{\n  width:600px;\n  height:100px;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") no-repeat;\n  background-size:100% 100%;\n  position: absolute;\n  margin: auto;\n  left: 0;\n  right: 0;\n  margin-top:65px;\n}\n\n", ""]);
// Exports
module.exports = exports;
