// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/physical/mzjg_jcjg_icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".result-btn[data-v-20fd5394] {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n  margin-top: 30px;\n  width: 80%;\n  margin-left: 10%;\n}\n.result-btn .btn[data-v-20fd5394] {\n    width: 300px;\n    height: 72px;\n    line-height: 66px;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n    font-size: 28.6px;\n    color: #fefefe;\n    text-align: center;\n}\n.has_pulse[data-v-20fd5394] {\n  width: 99%;\n  margin-left: 0.5%;\n}\n", ""]);
// Exports
module.exports = exports;
