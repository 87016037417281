var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "headerTabTwo" },
    [
      _vm._l(_vm.TextContent, function(item) {
        return _vm.TextContent && _vm.TextContent.length > 0
          ? _c("div", { key: item.name }, [
              _c("div", { staticClass: "title" }, [_vm._v(_vm._s(item.name))]),
              _c("img", {
                staticClass: "image",
                attrs: {
                  src: _vm.report.solutions.asset_prefix + item.asset,
                  alt: "茶饮图片"
                }
              }),
              _vm.SymptomsName && _vm.SymptomsName !== "平和质"
                ? _c("span", { staticClass: "tip-one" }, [_vm._v("配料：")])
                : _vm._e(),
              _c("span", { staticClass: "content-text" }, [
                _vm._v(_vm._s(item.content[0]))
              ]),
              item.content.length > 1
                ? _c("span", { staticClass: "tip-one" }, [_vm._v("泡煮方法：")])
                : _vm._e(),
              _c("span", { staticClass: "content-text" }, [
                _vm._v(_vm._s(item.content[1]))
              ]),
              item.content.length > 2
                ? _c("span", { staticClass: "tip-one" }, [_vm._v("功效：")])
                : _vm._e(),
              _c("span", { staticClass: "content-text" }, [
                _vm._v(_vm._s(item.content[2]))
              ])
            ])
          : _vm._e()
      }),
      _vm.AcupointContent
        ? _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.AcupointContent.name))
          ])
        : _vm._e(),
      _vm.TitleName && _vm.TitleName !== "精神调养"
        ? _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.TitleName))])
        : _vm._e(),
      _vm.TitleName && _vm.TitleName === "精神调养" && _vm.marker !== "10.1"
        ? _c("div", { staticClass: "music-healthy" }, [
            _c("div", { staticClass: "music-icon" }, [
              _c("img", {
                staticClass: "musicBg",
                class: _vm.isPlay ? "circle " : "",
                attrs: {
                  src: require("../../assets/img/common/music_jkfa_icon.png"),
                  alt: ""
                }
              }),
              _c("img", {
                staticClass: "music-btn",
                attrs: {
                  src: !_vm.isPlay
                    ? require("../../assets/img/common/bf_jkfa_icon.png")
                    : require("../../assets/img/common/zt_jkfa_icon.png")
                },
                on: {
                  click: function($event) {
                    return _vm.startPlay()
                  }
                }
              }),
              _vm.music
                ? _c("audio", {
                    staticClass: "audio",
                    attrs: {
                      src: _vm.music.url,
                      controls: "controls",
                      id: "player"
                    }
                  })
                : _vm._e()
            ]),
            _c("div", { staticClass: "music-tip" }, [
              _c("img", {
                class: _vm.isPlay ? "active" : "",
                attrs: {
                  src: require("../../assets/img/common/music1_jkfa_icon.png"),
                  alt: ""
                }
              })
            ]),
            _vm.music
              ? _c("div", { staticClass: "music-name" }, [
                  _vm._v(_vm._s(_vm.music.title))
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm.TitleName &&
      _vm.TitleName === "运动保健" &&
      _vm.marker !== "10.1" &&
      _vm.playerOptions &&
      _vm.playerOptions.poster
        ? _c("div", { staticClass: "music-healthy" }, [
            _c(
              "div",
              { staticClass: "zygf" },
              [
                _c("video-player", {
                  ref: "videoPlayer",
                  staticClass: "video-player vjs-custom-skin",
                  attrs: { playsinline: true, options: _vm.playerOptions }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.Detail
        ? _c("span", { staticClass: "content-text" }, [
            _vm._v(_vm._s(_vm.Detail.name))
          ])
        : _vm._e(),
      _vm._l(_vm.filedContent, function(item, index) {
        return _vm.PrescriptionContent || _vm.XnContent
          ? _c("div", { key: index, staticClass: "prescription" }, [
              _c("span", { staticClass: "teasty" }, [
                _vm._v(_vm._s(item.name))
              ]),
              _vm.XnContent
                ? _c("img", {
                    staticClass: "image",
                    attrs: { src: "" + (_vm.assets + item.asset[0]) }
                  })
                : _vm._e(),
              _c("span", { staticClass: "medicinal" }, [
                _vm._v(_vm._s(_vm.PrescriptionContent ? "药物" : "") + "组成：")
              ]),
              _c("span", { staticClass: "medicinal-explain" }, [
                _vm._v(_vm._s(item.content[0]))
              ]),
              _c("span", { staticClass: "medicinal" }, [_vm._v("功效：")]),
              _c("span", { staticClass: "medicinal-explain" }, [
                _vm._v(_vm._s(item.content[1]))
              ]),
              _vm.XnContent
                ? _c("div", [
                    _c("span", { staticClass: "medicinal" }, [
                      _vm._v("适应症：")
                    ]),
                    _c("span", { staticClass: "medicinal-explain" }, [
                      _vm._v(_vm._s(item.content[2]))
                    ]),
                    _c("span", { staticClass: "medicinal" }, [
                      _vm._v("用法：")
                    ]),
                    _c("span", { staticClass: "medicinal-explain" }, [
                      _vm._v(_vm._s(item.content[3]))
                    ]),
                    _c("span", { staticClass: "medicinal" }, [
                      _vm._v("禁忌：")
                    ]),
                    _c("span", { staticClass: "medicinal-explain" }, [
                      _vm._v(_vm._s(item.content[4]))
                    ]),
                    _c("span", { staticClass: "medicinal-explain" }, [
                      _vm._v(_vm._s(item.content[5]))
                    ])
                  ])
                : _vm._e()
            ])
          : _vm._e()
      }),
      _vm.PrescriptionContent
        ? _c("span", { staticClass: "tip" }, [
            _vm._v(" *请在医生、药师指导下购买和服用 ")
          ])
        : _vm._e(),
      _vm.isProduct
        ? _c(
            "div",
            [
              _c("ProductTzbs"),
              _vm.isShowProDetail ? _c("ProductDetail") : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }