<template>
  <div>
    <div style="display: flex; justify-content: center;width: 100%">
      <!-- 0祉云 1 报告 2 其他-->
      <img :src="reportData.qr_url" v-if="reportData.vendor_setting.qr_code_show === 2" alt="二维码图片" />
      <qrcode-vue  v-else-if="this.shareUrl&&reportData.vendor_setting.qr_code_show === 1" :value='url' size='146'></qrcode-vue>
      <img :src="code" v-else-if="this.shareUrl&&reportData.vendor_setting.qr_code_show === 0" alt="二维码图片" />
    </div>
    <span class="tipTxt">{{csr === 'BJTRTKH' ? '中国北京同仁堂（集团）有限责任公司' : (csr === 'CHENSHANG' ? '上海辰尚信息科技有限公司' : (csr === 'SHWYSLSWKJ' ? '上海伍月石榴生物科技有限公司' :
        (homeFootShow === 0 ? '' : homeFootShow === 1 ? '关注祉云健康，了解更多养生知识' : homeFootShow === 2 ? logoName : logoName ) ))}}</span>
  </div>
</template>

<script>
  import { queryObject } from '../../../../utils/common.js';
  const { csr = '' } = queryObject();
  import QrcodeVue from 'qrcode.vue'

  import wechatcode from '../../../assets/img/common/wechatcode.jpg'
  import code from '../../../assets/img/report/wechatcode.png';

  export default {
    name: "footertzbs",
    data(){
      return{
        wechatcode,
        code,
        shareUrl:window.location.href,
        csr
      }
    },
    computed: {
      reportData(){
        return this.$store.state.report
      },
      url(){
        if (this.reportData) {
          return `https://${window.location.host}/?category=2#/wxtzbs/${this.reportData.id}`;
        } else {
          return null
        }
      },
      homeFootShow(){
        return this.reportData.vendor_setting.home_foot_show   // 0什么都不显示 1 显示祉云logo  2 显示其他公司logo  3 祉云和其他公司logo共存
      },
      logoName(){
        return this.reportData.vendor_setting.logo;
      }

    },
    components: {
      QrcodeVue
    },
  }
</script>

<style scoped>
  /*img{*/
  /*  margin: 0 auto;*/
  /*}*/
  p{
    text-align: center;
    padding-bottom: 40px;
  }

  img {
    width: 146px;
    height: 146px;
  }

  .tipTxt {
    text-align: center;
    display: block;
    padding-bottom: 50px;
  }

</style>
