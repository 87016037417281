var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tonguereport" }, [
    _vm._m(0),
    _c("div", { staticClass: "result" }, [
      _vm._m(1),
      _c("div", { staticClass: "tongue-ss" }, [
        _c("div", { staticStyle: { "margin-top": "8px" } }, [
          _c("span", { staticClass: "title-tip" }, [
            _vm._v(_vm._s(_vm.tonguereport.tongue_nature_color_info.symptom))
          ])
        ]),
        _c("div", { staticStyle: { "margin-left": "10%" } }, [
          _c(
            "div",
            { staticStyle: { "margin-top": "5px", "text-align": "left" } },
            [
              _c("span", { staticClass: "span-tip" }, [
                _vm._v(
                  "提示：" +
                    _vm._s(_vm.tonguereport.tongue_nature_color_info.name)
                )
              ]),
              _c("br"),
              _c("span", { staticClass: "span-tip" }, [
                _vm._v(
                  "可能原因：" +
                    _vm._s(_vm.tonguereport.tongue_nature_color_info.content[0])
                )
              ])
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "tongue-sx" }, [
        _c(
          "div",
          {
            staticStyle: {
              "margin-top": "0",
              "text-align": "center",
              "font-size": "36px"
            }
          },
          [
            _c("span", { staticClass: "title-tip" }, [
              _vm._v(
                _vm._s(
                  _vm.tonguereport.tongueFatThin === 0
                    ? _vm.tonguereport.tongueCrack === 0
                      ? "正常"
                      : "有裂痕"
                    : _vm.tonguereport.tongueCrack === 0
                    ? "舌胖"
                    : "舌胖、有裂痕"
                )
              )
            ])
          ]
        ),
        _c("div", { staticStyle: { width: "85%", "margin-left": "15%" } }, [
          _c(
            "div",
            { staticStyle: { "margin-top": "0", "text-align": "left" } },
            [
              _c(
                "span",
                {
                  staticClass: "span-tip",
                  staticStyle: { "vertical-align": "top", "margin-left": "0" }
                },
                [_vm._v("提示：")]
              ),
              _c(
                "span",
                {
                  staticClass: "span-tip",
                  staticStyle: { "margin-left": "0", width: "600px" }
                },
                [
                  _vm._v(
                    _vm._s(_vm.tonguereport.tongue_fat_thin_info.name) +
                      "，" +
                      _vm._s(_vm.tonguereport.tongue_crack_info.name)
                  )
                ]
              ),
              _c("br"),
              _c(
                "span",
                {
                  staticClass: "span-tip",
                  staticStyle: { "margin-left": "0" }
                },
                [
                  _vm._v(
                    "可能原因：" +
                      _vm._s(
                        _vm.tonguereport.tongueFatThin === 1
                          ? _vm.tonguereport.tongue_fat_thin_info.content[0]
                          : ""
                      ) +
                      _vm._s(_vm.tonguereport.tongue_crack_info.content[0])
                  )
                ]
              )
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "tongue-ts" }, [
        _c(
          "div",
          {
            staticStyle: {
              "margin-top": "0",
              "text-align": "center",
              "font-size": "36px"
            }
          },
          [
            _c("span", { staticClass: "title-tip" }, [
              _vm._v(_vm._s(_vm.tonguereport.tongue_coat_color_info.symptom))
            ])
          ]
        ),
        _c("div", { staticStyle: { "margin-left": "10%" } }, [
          _c(
            "div",
            { staticStyle: { "margin-top": "5px", "text-align": "left" } },
            [
              _c("span", { staticClass: "span-tip" }, [
                _vm._v(
                  "提示：" +
                    _vm._s(_vm.tonguereport.tongue_coat_color_info.name)
                )
              ]),
              _c("br"),
              _c("span", { staticClass: "span-tip" }, [
                _vm._v(
                  "可能原因：" +
                    _vm._s(_vm.tonguereport.tongue_coat_color_info.content[0])
                )
              ])
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "tongue-tz" }, [
        _c(
          "div",
          {
            staticStyle: {
              "margin-top": "0",
              "text-align": "center",
              "font-size": "36px"
            }
          },
          [
            _c("span", { staticClass: "title-tip" }, [
              _vm._v(
                _vm._s(_vm.tonguereport.tongue_coat_thickness_info.symptom)
              )
            ])
          ]
        ),
        _c("div", { staticStyle: { "margin-left": "10%" } }, [
          _c(
            "div",
            { staticStyle: { "margin-top": "19px", "text-align": "left" } },
            [
              _c("span", { staticClass: "span-tip" }, [
                _vm._v(
                  "结果：" +
                    _vm._s(_vm.tonguereport.tongue_coat_thickness_info.name)
                )
              ]),
              _c("br"),
              _c("span", { staticClass: "span-tip" }, [
                _vm._v(
                  "提示：" +
                    _vm._s(
                      _vm.tonguereport.tongue_coat_thickness_info.content[0]
                    )
                )
              ])
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "back", on: { click: _vm.returnto } })
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tongue-top" }, [
      _c("span", { staticClass: "title" }, [_vm._v("舌象结果")]),
      _c("span", { staticClass: "title-border" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgsty" }, [
      _c("img", {
        staticStyle: { height: "375px", "margin-top": "20px" },
        attrs: {
          src: require("../../assets/img/menopauseovary/sxjg.gif"),
          alt: "面诊图片"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }