<template>
  <div>
    <div class="title">脏腑气血</div>
    <div class="pulseDetail">

      <div id="pulseChart" style="width:950px;height:600px;"></div>
    </div>
  </div>


</template>

<script>
  import * as echarts from 'echarts'

  export default {
    name: "AnalyseChart",
    props: {
      data: Object
    },
    mounted() {
      this.$nextTick( () => {
        this.drawChart();
      })
    },
    methods: {
      drawChart(){
        const myChart = echarts.init(document.getElementById('pulseChart'));
        myChart.setOption({

          tooltip: {
            show: false ,// 手指放上去，不展示数值
          },
          legend: {
            bottom: '0',
            textStyle: {
              fontSize: 22
            },
            data: [
              {
                name: '气',  // 这个name需要和 series 里面的 name 对应起来
                textStyle: {
                  color: '#A8A2EB' // 单独设置某一个图列的颜色
                }
              },
              {
                name: '血',  // 这个name需要和 series 里面的 name 对应起来
                textStyle: {
                  color: '#FFA6A6' // 单独设置某一个图列的颜色
                }
              }
            ]
          },
          xAxis: {
            data: ['心', '肝', '肾', '肺', '脾', '命门'],
            axisLabel: {
              fontSize: 22 // x轴刻度字体大小
            }
          },
          yAxis: {
            name: '强',
            nameTextStyle: {
              fontSize: 20,
            },
            nameGap: 6, // 坐标轴名称与轴线之间的距离
            min:0,
            max: 100,
            axisTick:{
              show: false //不显示坐标轴刻度线
            },
            axisLine: { // 坐标轴轴线相关设置。
              show: true, //显示坐标轴线
              symbol: ['none','arrow'], // 轴线两边的箭头(只在末端显示箭头)
              symbolSize: [20, 20]

            },
            axisLabel: {
              show: false, //不显示坐标轴上的文字
            },
            splitLine: {     //网格线
              "show": false
            }
          },
          // 标签适用的标注
          label: {
            show: true,
            position: 'top',
            distance: 10,
            color: '#b1b1b1',
            fontSize: 20,
            formatter:function({value}){ // 根据数值，决定展示格式化之后的文字
              if(value < 15) {
                return '不及'
              } else if(15 <= value && value <= 75) {
                return '平和'
              } else {
                return '太过'
              }
            }
          },
          series: [
            {
              name: '气',
              type: 'bar',
              data: this.data.gas,
              // 设置单个柱子的样式
              itemStyle: {
                color: '#A8A2EB',
                shadowColor: '#A8A2EB',
                borderType: 'dashed',
              },
              barGap: "0%"
              // x轴数据标注

            },
            {
              name: '血',
              type: 'bar',
              data: this.data.blood,
              // 设置单个柱子的样式
              itemStyle: {
                color: '#FFA6A6 ',
                shadowColor: '#FFA6A6 ',
                borderType: 'dashed',
              },
              markLine: {
                silent: true,
                symbol: "none",
                lineStyle: {
                  normal: {
                    color: 'red' // 这儿设置安全基线颜色
                  }
                },
                data: [
                  {yAxis: 15},
                  {yAxis: 75}
                ],
                label: {
                  normal: {
                    formatter: '' // 这儿设置安全基线文字提示
                  }
                },
              },
            },


          ]
        });
      }
    }
  }
</script>

<style scoped>
  .pulseDetail {
    border-radius: 10px;
    padding: 1rem 0;
    margin-top: 80px;
    margin-bottom: 1rem;
    box-shadow: 0 0 1.1rem 0 rgba(153, 153, 153, 0.17);
    background-color: white;
  }

  .title {
    width: 79%;
    height: 110px;
    margin: 82px auto;
    text-align: center;
    font-size: 48px;
    background-image: url("../../../assets/img/report/pulse/mxbt_jkjx_icon.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    line-height: 110px;
  }

</style>