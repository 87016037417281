var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "healthtip" }, [
    _c("div", { staticClass: "tip-content" }, [
      _c("img", {
        staticClass: "jbfximg",
        attrs: {
          src:
            _vm.reportData.health_status === "亚健康" ? _vm.ibfx : _vm.jiankang
        }
      }),
      _c("div", { staticClass: "body" }, [
        _c("div", { staticClass: "line" }, [
          _c(
            "div",
            { staticClass: "line-left" },
            _vm._l(_vm.leftRisk, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  class: item.content[0].includes("头部")
                    ? "leftc head"
                    : item.content[0].includes("眼部")
                    ? "leftc face"
                    : item.content[0].includes("口腔")
                    ? "leftc oral"
                    : item.content[0].includes("全身")
                    ? "leftc chest"
                    : item.content[0].includes("肝胆胰")
                    ? "leftc lung"
                    : item.content[0].includes("肾脏")
                    ? "leftc darling"
                    : item.content[0].includes("下半部")
                    ? "leftc upperarm"
                    : "leftc"
                },
                [
                  item.content[0].includes("头部")
                    ? _c("span", { staticStyle: { "text-align": "right" } }, [
                        _vm._v(_vm._s(_vm.headone.join("、")))
                      ])
                    : _vm._e(),
                  item.content[0].includes("眼部")
                    ? _c("span", { staticStyle: { "text-align": "right" } }, [
                        _vm._v(_vm._s(_vm.eyes.join("、")))
                      ])
                    : _vm._e(),
                  item.content[0].includes("口腔")
                    ? _c("span", { staticStyle: { "text-align": "right" } }, [
                        _vm._v(_vm._s(_vm.oral.join("、")))
                      ])
                    : _vm._e(),
                  item.content[0].includes("全身")
                    ? _c("span", { staticStyle: { "text-align": "right" } }, [
                        _vm._v(_vm._s(_vm.allbody.join("、")))
                      ])
                    : _vm._e(),
                  item.content[0].includes("肝胆胰")
                    ? _c("span", { staticStyle: { "text-align": "right" } }, [
                        _vm._v(_vm._s(_vm.liver.join("、")))
                      ])
                    : _vm._e(),
                  item.content[0].includes("肾脏")
                    ? _c("span", { staticStyle: { "text-align": "right" } }, [
                        _vm._v(_vm._s(_vm.kidney.join("、")))
                      ])
                    : _vm._e(),
                  item.content[0].includes("下半部")
                    ? _c("span", { staticStyle: { "text-align": "right" } }, [
                        _vm._v(_vm._s(_vm.lower.join("、")))
                      ])
                    : _vm._e(),
                  _c("img", { attrs: { src: _vm.lineLeft, alt: "人体线图" } })
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "line-right" },
            _vm._l(_vm.rightRisk, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  class: item.content[0].includes("头部")
                    ? "rightc head"
                    : item.content[0].includes("耳部")
                    ? "rightc heart"
                    : item.content[0].includes("鼻腔")
                    ? "rightc nose"
                    : item.content[0].includes("心脏")
                    ? "rightc face"
                    : item.content[0].includes("胃部")
                    ? "rightc oral"
                    : item.content[0].includes("腹部")
                    ? "rightc throat"
                    : item.content[0].includes("腰部")
                    ? "rightc chest"
                    : item.content[0].includes("骨")
                    ? "rightc upperarm"
                    : "rightc"
                },
                [
                  _c("img", { attrs: { src: _vm.lineRight, alt: "人体线图" } }),
                  item.content[0].includes("头部")
                    ? _c("span", { staticStyle: { "text-align": "left" } }, [
                        _vm._v(_vm._s(_vm.headtwo.join("、")))
                      ])
                    : _vm._e(),
                  item.content[0].includes("耳部")
                    ? _c("span", { staticStyle: { "text-align": "left" } }, [
                        _vm._v(_vm._s(_vm.ear.join("、")))
                      ])
                    : _vm._e(),
                  item.content[0].includes("鼻腔")
                    ? _c("span", { staticStyle: { "text-align": "left" } }, [
                        _vm._v(_vm._s(_vm.nasal.join("、")))
                      ])
                    : _vm._e(),
                  item.content[0].includes("心脏")
                    ? _c("span", { staticStyle: { "text-align": "left" } }, [
                        _vm._v(_vm._s(_vm.heart.join("、")))
                      ])
                    : _vm._e(),
                  item.content[0].includes("胃部")
                    ? _c("span", { staticStyle: { "text-align": "left" } }, [
                        _vm._v(_vm._s(_vm.stomach.join("、")))
                      ])
                    : _vm._e(),
                  item.content[0].includes("腹部")
                    ? _c("span", { staticStyle: { "text-align": "left" } }, [
                        _vm._v(_vm._s(_vm.abdomen.join("、")))
                      ])
                    : _vm._e(),
                  item.content[0].includes("腰部")
                    ? _c("span", { staticStyle: { "text-align": "left" } }, [
                        _vm._v(_vm._s(_vm.waist.join("、")))
                      ])
                    : _vm._e(),
                  item.content[0].includes("骨")
                    ? _c("span", { staticStyle: { "text-align": "left" } }, [
                        _vm._v(_vm._s(_vm.bones.join("、")))
                      ])
                    : _vm._e()
                ]
              )
            }),
            0
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }